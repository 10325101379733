<template>
    <div class="d-flex flex-column">
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row class="mt-1">
            <v-col :md="2">
                <date-picker v-model="startDate" outlined dense :label="`${$t('transfers.departure_date')}`" />
            </v-col>
            <v-col :md="2">
                <time-field v-model="startTime" outlined dense :label="`${$t('transfers.pickup_time')}`" />
            </v-col>
        </v-row>
        <span
            v-if="infoByServiceType.transfer.departure.flightInfo || infoByServiceType.transfer.departure.trainInfo"
            class="text-subtitle-1 font-weight-medium"
        >
            {{ $t('flights.departure') }}
        </span>
        <v-row v-if="infoByServiceType.transfer.departure.flightInfo" class="mt-1">
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.departure.flightInfo.otherAirportCode"
                    outlined
                    dense
                    :label="`${$t('transfers.airport_code')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.departure.flightInfo.flightNumber"
                    :label="`${$t('transfers.flight_number')}`"
                    hide-details
                    outlined
                    dense
                />
            </v-col>
            <v-col :md="2">
                <date-picker
                    v-model="infoByServiceType.transfer.departure.flightInfo.dateTime"
                    outlined
                    dense
                    :label="`${$t('flights.date')}`"
                />
            </v-col>
        </v-row>
        <v-row v-if="infoByServiceType.transfer.departure.trainInfo">
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.departure.trainInfo.trainNumber"
                    outlined
                    dense
                    :label="`${$t('transfers.train_number')}`"
                    hide-details
                />
            </v-col>
        </v-row>
        <span
            v-if="infoByServiceType.transfer.arrival.flightInfo || infoByServiceType.transfer.arrival.trainInfo"
            class="text-subtitle-1 font-weight-medium"
        >
            {{ $t('flights.arrival') }}
        </span>
        <v-row v-if="infoByServiceType.transfer.arrival.flightInfo" class="mt-1">
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.arrival.flightInfo.otherAirportCode"
                    outlined
                    dense
                    :label="`${$t('transfers.airport_code')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.arrival.flightInfo.flightNumber"
                    outlined
                    dense
                    :label="`${$t('transfers.flight_number')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="2">
                <date-picker
                    v-model="infoByServiceType.transfer.arrival.flightInfo.dateTime"
                    outlined
                    dense
                    :label="`${$t('flights.date')}`"
                />
            </v-col>
        </v-row>
        <v-row v-if="infoByServiceType.transfer.arrival.trainInfo" class="mt-1">
            <v-col :md="2">
                <v-text-field
                    v-model="infoByServiceType.transfer.arrival.trainInfo.trainNumber"
                    outlined
                    dense
                    :label="`${$t('transfers.train_number')}`"
                    hide-details
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class ModifyManualTransfer extends Vue {
        @VModel() infoByServiceType

        get startDate() {
            return this.infoByServiceType.transfer.startDateTime.split('T')[0]
        }

        set startDate(date) {
            this.infoByServiceType.transfer.startDateTime = `${date}T${
                this.infoByServiceType.transfer.startDateTime.split('T')[1]
            }`
        }

        get startTime() {
            return this.infoByServiceType.transfer.startDateTime.split('T')[1]
        }

        set startTime(time) {
            this.infoByServiceType.transfer.startDateTime = `${
                this.infoByServiceType.transfer.startDateTime.split('T')[0]
            }T${time}`
        }
    }
</script>
