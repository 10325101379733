<template>
    <div ref="header" class="header">
        <div>
            <v-app-bar v-show="visible" dense flat class="header" height="70" :dark="dark">
                <header-block />
                <contacts-block v-if="!$breakpoint.smAndDown" />
                <div class="flex-grow-1" />
                <client-only>
                    <div v-if="$breakpoint.mdAndUp" class="d-md-flex d-lg-flex align-center">
                        <template
                            v-if="
                                !['authorization', 'b2b-auth', 'restore-password', 'hotelDirectory'].includes(
                                    $route.name
                                ) ||
                                ($route.name === 'hotelDirectory' && person)
                            "
                        >
                            <account-menu />
                            <currency-switcher />
                        </template>
                        <language-switcher />
                    </div>
                </client-only>
                <v-app-bar-nav-icon v-if="$breakpoint.smAndDown" @click="drawer = !drawer" />
            </v-app-bar>
        </div>
        <portal-target name="under-header" />
    </div>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import CurrencySwitcher from '~/components/CurrencySwitcher'
    import AccountMenu from '~src/components/account/accountMenu.src'
    import {EventBus, FULL_SCREEN_MODE} from '@/utils/event-bus'
    import NavigationDrawerMobile from '~src/components/snippets/navigationDrawerMobile.src'
    import LanguageSwitcher from '~/components/LanguageSwitcher'
    import HeaderBlock from '~src/components/snippets/headerBlock.src'
    import ContactsBlock from '~src/components/snippets/contactsBlock.src'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {
            ContactsBlock,
            HeaderBlock,
            AccountMenu,
            LanguageSwitcher,
            CurrencySwitcher,
            NavigationDrawerMobile,
        },
    })
    export default class HeaderBar extends Vue {
        @Prop({default: false, type: Boolean}) dark
        @VModel() drawer

        visible = true

        created() {
            EventBus.$on(FULL_SCREEN_MODE, this.toggle)
        }

        mounted() {
            if (this.isMobile) {
                this.setPageOnScrollListener()
            }
        }

        setPageOnScrollListener() {
            let prevScrollPos = window.pageYOffset

            document.addEventListener('scroll', () => {
                const currentScrollPos = window.pageYOffset
                if (this.$refs.header) {
                    if (prevScrollPos <= currentScrollPos) {
                        this.$refs.header.classList.add('hide')
                        document.querySelector('.show-scroll-up-mobile')?.classList.add('hide')
                    } else {
                        this.$refs.header.classList.remove('hide')
                        document.querySelector('.show-scroll-up-mobile')?.classList.remove('hide')
                    }
                }

                prevScrollPos = currentScrollPos
            })
        }

        beforeDestroy() {
            EventBus.$off(FULL_SCREEN_MODE, this.toggle)
        }

        toggle(fullScreenMode) {
            this.visible = !fullScreenMode
        }

        get person() {
            return authStore.person
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .theme--light.v-app-bar.v-toolbar.v-sheet.header {
        background-color: #ffffff;
        border-bottom: 1px #eeeeee solid;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .header {
            transition: all 0.3s ease-in-out;
            position: sticky;
            top: 0;
            z-index: 10;
        }

        .header.hide {
            top: -70px;
        }
    }
</style>
