<template>
    <div>
        <v-img max-height="400px" src="/profile-bg.jpg" />
        <v-container class="wrapper">
            <div class="avatar">
                <v-img :src="(user && user.avatar) || '/profile-avatar.png'" />
            </div>
            <v-row class="form-wrapper">
                <v-col cols="12" md="8">
                    <v-form ref="user-form">
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-select
                                    v-model="userData.prefix"
                                    outlined
                                    dense
                                    :items="['Mr', 'Ms', 'Mrs']"
                                    :label="`${$t('salutation')}*`"
                                    :rules="[requiredRule('salutation')]"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    v-model="firstName"
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    dense
                                    :label="$t('first_name')"
                                    :rules="validationRules('first_name', [latinRule, requiredRule])"
                                    :disabled="lockEditPrivateClientName"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    v-model="lastName"
                                    prepend-inner-icon="mdi-account"
                                    outlined
                                    dense
                                    :label="$t('last_name')"
                                    :rules="validationRules('last_name', [latinRule, requiredRule])"
                                    :disabled="lockEditPrivateClientName"
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <phone-field
                                    v-model="userData.contactPhone"
                                    prepend-inner-icon="mdi-phone"
                                    outlined
                                    dense
                                    :label="$t('phone')"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="userData.email"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    dense
                                    :label="$t('email')"
                                    :rules="validationRules('email', [emailRule, requiredRule])"
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-autocomplete
                                    v-model="userData.citizenshipId"
                                    prepend-inner-icon="mdi-earth"
                                    outlined
                                    :label="$t('citizenship')"
                                    dense=""
                                    :items="countries"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[requiredRule('citizenship')]"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-btn color="primary" block :loading="loading" @click="save('settings')">
                                    {{ $t('save_changes') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col cols="12" md="4" :class="{'password-form': !$breakpoint.smAndDown}">
                    <v-form ref="password-form">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="password"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    dense
                                    :label="$t('password')"
                                    :rules="[requiredRule('password')]"
                                    required
                                    type="password"
                                    autocomplete="new-password"
                                    @input="passwordConfirm = null"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="passwordConfirm"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    dense
                                    :label="$t('password_confirm')"
                                    :rules="[
                                        requiredRule('password_confirm'),
                                        v => v === password || `${$t('password_confirm')} ${$t('validation.match')}`,
                                    ]"
                                    required
                                    validate-on-blur
                                    type="password"
                                    autocomplete="new-password"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn color="primary" block :loading="loadingPassword" @click="save('password')">
                                    {{ $t('save_new_password') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col v-if="isB2C && totalLoyaltyPoints !== undefined" cols="12">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div class="text-h5 mb-4">
                                    {{ $t('loyalty') }}
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="d-flex align-start">
                                    <div class="text-subtitle-2 mb-4">
                                        {{ $t('loyalty_count') }}: {{ totalLoyaltyPoints }}
                                    </div>
                                    <v-dialog v-model="dialog" width="500">
                                        <template v-slot:activator="{on, attrs}">
                                            <v-icon v-bind="attrs" color="primary" class="ml-2" v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>

                                        <v-card>
                                            <v-card-title class="headline grey lighten-2">
                                                {{ $t('loyalty') }}
                                            </v-card-title>

                                            <v-card-text>
                                                <div v-html="$t('about_loyalty')" />
                                                <div class="red--text" v-html="$t('violation_loyalty')" />
                                            </v-card-text>

                                            <v-divider />

                                            <v-card-actions>
                                                <v-spacer />
                                                <v-btn color="primary" @click="dialog = false">
                                                    {{ $t('ok') }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {Component, Watch, mixins} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import LanguageSwitcher from '@/components/LanguageSwitcher'
    import PhoneField from '~src/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {PhoneField},
    })
    export default class UserSettings extends mixins(FormValidationRulesMixin) {
        userData = {}
        loading = false
        loadingPassword = false
        password = null
        passwordConfirm = null
        dialog = false

        @Watch('user')
        onUserChange() {
            if (this.user) {
                this.userData = Object.assign({}, this.userData, this.user)
            }
        }

        @Watch('userLocale')
        onUserLocaleChange() {
            LanguageSwitcher.options.methods.switchLanguage.call(this, this.userLocale)
        }

        async mounted() {
            await this.$store.restored
            this.userData = Object.assign({}, this.userData, this.user)
            if (this.userLocale && this.userLocale !== this.$i18n.locale) {
                LanguageSwitcher.options.methods.switchLanguage.call(this, this.userLocale)
            }
        }

        beforeDestroy() {
            this.userData = {}
        }

        async save(type) {
            const form = type === 'password' ? this.$refs['password-form'] : this.$refs['user-form']
            if (form.validate()) {
                try {
                    this.loading = true
                    const data = type === 'password' ? {password: this.password} : this.prepareData()
                    if (authStore.isAgency || authStore.isCorporate || authStore.isTourOperator) {
                        await this.$api.persons.put(this.userData.personId, data)
                    } else {
                        await this.$api.updateCrmPerson.put(this.userData.personId, data)
                    }
                    if (type !== 'password') {
                        authStore.updatePerson(this.userData)
                    }
                    this.$toast.success(this.$t('data_updated'))
                } catch (error) {
                    console.error(error)
                } finally {
                    this.loading = false
                }
            }
        }

        prepareData() {
            const {prefix, firstName, lastName, citizenshipId, contactPhone, email} = this.userData
            return {
                prefix,
                firstName,
                lastName,
                citizenshipId,
                contactPhone,
                email,
            }
        }

        get user() {
            return authStore.person || {}
        }

        get isB2C() {
            return authStore.isB2C
        }

        get firstName() {
            if (this.userData.personId) {
                return this.userData.firstName ? this.userData.firstName : this.userData.firstNameLocalized
            }
            return null
        }

        set firstName(value) {
            if (this.userData.firstName) {
                this.userData.firstName = value
            } else {
                this.userData.firstNameLocalized = value
            }
        }

        get lastName() {
            if (this.userData.personId) {
                return this.userData.lastName ? this.userData.lastName : this.userData.lastNameLocalized
            }
            return null
        }

        set lastName(value) {
            if (this.userData.lastName) {
                this.userData.lastName = value
            } else {
                this.userData.lastNameLocalized = value
            }
        }

        get countries() {
            return runtimeStore.countries
        }

        get userLocale() {
            return this.user ? this.user.language : null
        }

        get lockEditPrivateClientName() {
            return this.$config.lockEditPrivateClientName
        }

        get totalLoyaltyPoints() {
            return this.user?.totalLoyaltyPoints
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    .shadow {
        opacity: 0.2;
    }

    .wrapper {
        position: relative;
        padding-top: 0;
        margin-top: 0;
    }

    .form-wrapper {
        padding-top: 60px;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .form-wrapper {
            padding-top: 30px;
        }
    }

    .avatar {
        max-width: 5vw;
        min-width: 80px;
        max-height: 5vw;
        min-height: 80px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        overflow: hidden;
        top: 0;
    }

    .password-form {
        border-left: 1px solid gray;
        border-left: outset;
    }
</style>
