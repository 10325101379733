<template>
    <div class="full-width d-flex justify-center" style="background: #f5f8f8">
        <div v-if="!$fetchState.pending" class="full-width d-flex flex-column align-center overflow-hidden">
            <div :style="{width: isMobile ? '100%' : '80%'}">
                <main-page-form-wrapper />
            </div>
            <reasons-to-book style="z-index: 1" />
            <vue-markdown v-if="homePageSettings.topBlock" :source="homePageSettings.topBlock" style="z-index: 1" />
            <v-container fluid style="background: white; z-index: 1" class="d-flex align-center flex-column">
                <div :style="{width: isMobile ? '100%' : '80%'}">
                    <popular-destinations
                        v-if="popularDestinations.length"
                        :destinations="popularDestinations"
                        style="margin-top: 120px"
                    />
                    <popular-hotels v-if="popularHotels.length" :hotels="popularHotels" style="margin-top: 90px" />
                    <about-us style="margin-top: 90px" />
                </div>
                <div v-if="reviews.length" style="margin-top: 90px" />
            </v-container>
            <div :style="{width: isMobile ? '100%' : '80%'}" style="z-index: 1">
                <reviews v-if="reviews.length" :reviews="reviews" />
            </div>
            <v-container fluid style="background: white; z-index: 1" class="d-flex align-center flex-column">
                <div :style="{width: isMobile ? '100%' : '80%'}">
                    <blog v-if="blogItems && blogItems.length" :items="blogItems" style="margin-top: 120px" />
                    <subscribe-us-block style="margin-top: 120px" />
                    <partners :key="partners" style="background: white; margin-top: 120px" />
                </div>
                <div style="margin-top: 120px" />
            </v-container>
            <questions-block />
            <vue-markdown v-if="homePageSettings.bottomBlock" :source="homePageSettings.bottomBlock" />
            <new-order-request-modal v-model="newOrderRequestModal" />
        </div>
        <v-btn
            v-if="$config.account.newReservationRequest"
            class="d-block reservation-btn"
            :color="'warning'"
            :elevation="'0'"
            @click="newOrderRequestModal = true"
        >
            <span>
                {{ $t('new_reservation_request') }}
            </span>
        </v-btn>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import IndexPage from '~/pages/IndexPage'
    import AboutUs from '~~/mods/ajbooking/components/parts/AboutUs'
    import Reviews from '~~/mods/ajbooking/components/parts/Reviews'
    import SubscribeUsBlock from '~~/mods/ajbooking/components/parts/SubscribeUsBlock'
    import Partners from '~~/mods/ajbooking/components/parts/Partners'
    import QuestionsBlock from '~~/mods/ajbooking/components/parts/QuestionsBlock'
    import ReasonsToBook from '~~/mods/ajbooking/components/parts/ReasonsToBook'
    import {cmsStore} from '@/utils/store-accessor'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {ReasonsToBook, QuestionsBlock, Partners, SubscribeUsBlock, Reviews, AboutUs},
    })
    export default class IndexPageMod extends IndexPage {
        partners = 0

        async fetch() {
            await Promise.all([
                cmsStore.loadPopularDestinations(),
                cmsStore.loadPopularHotels(),
                cmsStore.loadHomePageSettings(),
                cmsStore.loadBlogItems(),
                cmsStore.loadReviews(),
            ])
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadPopularHotels)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadReviews)
            EventBus.$on(CHANGE_LOCALE_EVENT, () => {
                this.partners++
            })
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadPopularHotels)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadReviews)
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get reviews() {
            return cmsStore.reviews
        }
    }
</script>
