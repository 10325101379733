<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class ConditionsMixin extends Vue {
        get isLoading() {
            return runtimeStore.isConditionsLoading(this.offerKey)
        }

        get hasErrors() {
            return (
                !this.conditionsInfo.cancellationItems ||
                !this.conditionsInfo.cancellationItems.length ||
                !this.conditionsInfo.cancellationItems[0].price
            )
        }

        get isFree() {
            return !this.conditionsInfo.cancellationItems.length
        }

        get isNonRefundable() {
            const firstCondition = this.conditionsInfo.cancellationItems[0]
            return (
                firstCondition.dateFrom &&
                firstCondition.price &&
                new Date() >= this.$dateFns.parseISO(firstCondition.dateFrom) &&
                this.$options.filters.convertPrice(this.price).amount <=
                    this.$options.filters.convertPrice(firstCondition.price).amount
            )
        }

        get freeDate() {
            const firstCondition = this.conditionsInfo.cancellationItems[0]
            if (!firstCondition.dateFrom || new Date() >= this.$dateFns.parseISO(firstCondition.dateFrom)) {
                return undefined
            }
            return this.$dateFns.parse(firstCondition.dateFrom, 'yyyy-MM-dd HH:mm', new Date())
        }

        get status() {
            if (this.isLoading) {
                return 'isLoading'
            } else if (this.hasErrors) {
                return 'hasErrors'
            } else if (this.isFree) {
                return 'isFree'
            } else if (this.isNonRefundable) {
                return 'isNonRefundable'
            } else if (this.freeDate) {
                return 'freeDate'
            } else {
                return 'special'
            }
        }

        get color() {
            switch (this.status) {
                case 'isLoading':
                    return 'black--text'
                case 'hasErrors':
                    return 'black--text'
                case 'isNonRefundable':
                    return 'red--text'
                case 'isFree':
                case 'freeDate':
                    return 'green--text'
                default:
                    return 'black--text'
            }
        }

        get label() {
            switch (this.status) {
                case 'isLoading':
                case 'hasErrors':
                    return this.$t('cancellation_policy')
                case 'isNonRefundable':
                    return this.$t('non_refundable')
                case 'isFree':
                    return this.isFreeLabel
                case 'freeDate':
                    return this.$t('free_cancellation_before_date', {
                        date: this.$options.filters.dateFormat(this.freeDate),
                    })
                default:
                    return this.$t('cancellation_policy')
            }
        }

        get isFreeLabel() {
            return this.$t('cancellation_free')
        }
    }
</script>
