<template>
    <v-sheet
        :dark="dark"
        color="transparent"
        class="footer-menu ml-auto ml-md-5 d-flex flex-column flex-wrap flex-md-row justify-space-between"
    >
        <v-btn
            nuxt
            :to="'/#'"
            text
            :rounded="false"
            class="v-btn--router footer-link me-4 py-4 px-4"
            :class="{'v-btn--active': isHomePage, 'justify-start': isMobile}"
        >
            {{ $t('home') }}
        </v-btn>
        <v-btn
            v-for="(item, index) in footerMenu"
            :key="index"
            nuxt
            :to="!item.externalLink ? {name: 'infoPage', params: {link: item.link}} : null"
            text
            :rounded="false"
            :href="item.externalLink ? item.link : null"
            :target="item.externalLink ? '_blank' : null"
            :class="{'justify-start': isMobile}"
            class="v-btn--router footer-link me-4 py-4 px-4"
        >
            {{ item.name }}
        </v-btn>
    </v-sheet>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import FooterMenu from '@/components/snippets/FooterMenu'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class FooterMenuMod extends FooterMenu {
        @Prop({default: false, type: Boolean}) dark

        get isHomePage() {
            return this.$route.name === 'home'
        }

        get isStaysPage() {
            return this.$route.name === 'hotels' || this.$route.name === 'hotel' || this.$route.name === 'hotelBooking'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 3px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }
    }
</style>
