<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('cancellation') }}
            </v-card-title>
            <v-card-text class="text-center">
                <v-icon v-if="!cancellationAllowed" color="error">mdi-exclamation-thick</v-icon>
                <div v-if="error || !cancelPrice" class="error--text">
                    {{ $t('cancelInfoMessage.error') }}
                </div>
                <cancel-service-status-label
                    v-else
                    :error="error"
                    :customer-payment-status="service.customerPaymentStatus"
                    :cancel-price="cancelPrice"
                    :service-price="servicePrice"
                    :is-not-paid="isNotPaid"
                    :company="companyTO1"
                />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn v-if="cancellationAllowed" class="warning" @click="cancel">
                    {{ $t('confirm_cancellation') }}
                </v-btn>
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import CancelServiceStatusLabel from '@/components/account/modals/CancelServiceStatusLabel'

    @Component({
        components: {CancelServiceStatusLabel},
    })
    export default class CancelServiceModal extends Vue {
        modal = false
        isLoading = true
        error = false
        service = {}
        cancelPrice = null
        order = null
        companyTO1 = {
            phones: [],
            emails: [],
        }

        async mounted() {
            await this.$store.restored
        }

        async show(service, order) {
            this.service = service
            this.order = order
            this.error = false
            this.isLoading = true
            this.cancelPrice = null
            this.modal = true
            try {
                this.companyTO1 = (
                    await this.$api.companies.get({
                        id: this.service.parentTO1Id,
                        limit: 1,
                    })
                ).object?.[0]
                const rs = await this.$api.currentCancelInfo.get({processId: service.processId})
                this.cancelPrice = rs.cancelPrice
            } catch (e) {
                this.error = true
            } finally {
                this.isLoading = false
            }
        }

        async cancel() {
            this.isLoading = true
            try {
                const rq = this.service.serviceContainerId
                    ? {containerId: this.service.serviceContainerId}
                    : {processId: this.service.processId}
                const status = (await this.$api.cancelService.post(rq))[0].status
                let freeAddOns
                switch (status) {
                    case 'CANCELLATION_PENDING':
                        this.$toast.info(
                            `${this.$t('cancelInfoMessage.cancellation_pending')}<br />${this.$t(
                                'cancelInfoMessage.expect_cancel_from_supplier'
                            )}`
                        )
                        break
                    case 'CANCELED':
                        freeAddOns = this.order.services.filter(
                            service =>
                                service.addOnServiceId === this.service.serviceId &&
                                service.salesTerms.find(({type}) => type === 'CLIENT').price.amount === 0
                        )
                        if (freeAddOns.length) {
                            try {
                                const cancelServicePromises = freeAddOns.map(({processId}) =>
                                    this.$api.cancelService.post({processId})
                                )
                                await Promise.all(cancelServicePromises)
                                // eslint-disable-next-line no-empty
                            } catch (e) {}
                        }
                        if (this.isNotPaid) {
                            this.$toast.success(
                                `${this.$t('cancelInfoMessage.cancel_success')}<br />${this.$t(
                                    'cancelInfoMessage.status_changed_to_cancelled'
                                )}`
                            )
                        } else {
                            this.$toast.success(
                                `${this.$t('cancelInfoMessage.cancel_success')}<br />${this.$t(
                                    'cancelInfoMessage.refund_pending'
                                )}`
                            )
                        }
                        break
                    case 'CONFIRMED':
                    case 'PRECONFIRMED':
                    case 'UNDEFINED':
                        this.$toast.error(
                            `${this.$t('cancelInfoMessage.error')}<br />${this.$t(
                                'cancelInfoMessage.contact_to_agency',
                                {email: this.companyTO1.emails.join(','), phone: this.companyTO1.phones.join(',')}
                            )}`
                        )
                        break
                }
                this.$emit('cancelService')
            } catch (e) {
                this.$toast.error(
                    `${this.$t('cancelInfoMessage.error')}<br />${this.$t('cancelInfoMessage.contact_to_agency', {
                        email: this.companyTO1.emails.join(','),
                        phone: this.companyTO1.phones.join(','),
                    })}`
                )
            } finally {
                this.modal = false
            }
        }

        get servicePrice() {
            return this.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price
        }

        get isNotPaid() {
            return ![
                'PAID',
                'PARTIAL_PAID',
                'request.service.payment_status.in_transit',
                'request.service.payment_status.invoice_waiting',
                'request.service.payment_status.overdue',
            ].includes(this.service.customerPaymentStatus)
        }

        get cancellationAllowed() {
            return (
                !this.error &&
                this.cancelPrice &&
                this.service.customerPaymentStatus !== 'PARTIAL_PAID' &&
                (this.cancelPrice.amount === 0 ||
                    (this.cancelPrice.amount < this.servicePrice.amount && !this.isNotPaid))
            )
        }
    }
</script>
