var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-start justify-start text-left"},[_vm._t("title"),_vm._v(" "),(!_vm.cancelPrice)?_c('div',{staticClass:"error--text"},[(_vm.isB2B && _vm.$t('cancellation_order_text_b2b').length)?_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('cancellation_order_text_b2b'))+"\n        ")]):(_vm.$t('cancellation_order_text_b2c').length)?_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('cancellation_order_text_b2c'))+"\n        ")]):_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('cancelInfoMessage.error'))+"\n        ")])]):(_vm.customerPaymentStatus === 'PARTIAL_PAID')?_c('div',{staticClass:"error--text"},[_vm._v("\n        "+_vm._s(_vm.$t('cancelInfoMessage.contact_to_agency', {
                email: _vm.company.emails.join(','),
                phone: _vm.company.phones.join(','),
            }))+"\n    ")]):(_vm.cancelPrice.amount >= (_vm.servicePrice && _vm.servicePrice.amount))?_c('div',{staticClass:"error--text"},[_vm._v("\n        "+_vm._s(_vm.$t('cancelInfoMessage.not_refundable'))+"\n    ")]):(_vm.cancelPrice.amount === 0)?[_c('div',{staticClass:"success--text"},[_vm._v("\n            "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_free'))+"\n        ")]),_vm._v(" "),(!_vm.isNotPaid)?_c('div',[_vm._v("\n            "+_vm._s(_vm.$t('cancelInfoMessage.amount_paid', {
                    amount: _vm.$options.filters.priceFormat(_vm.servicePrice.amount, _vm.servicePrice.currency),
                }))+"\n        ")]):_vm._e()]:[(_vm.isNotPaid)?[_c('div',{staticClass:"error--text"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_fee', {
                        fee: _vm.$options.filters.priceFormat(_vm.cancelPrice.amount, _vm.cancelPrice.currency),
                    }))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-left"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.contact_to_agency', {
                        email: _vm.company.emails.join(','),
                        phone: _vm.company.phones.join(','),
                    }))+"\n            ")])]:[_c('div',{staticClass:"error--text"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_fee', {
                        fee: _vm.$options.filters.priceFormat(_vm.cancelPrice.amount, _vm.cancelPrice.currency),
                    }))+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.amount_paid', {
                        amount: _vm.$options.filters.priceFormat(_vm.servicePrice.amount, _vm.servicePrice.currency),
                    }))+"\n            ")]),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.refund_difference', {
                        difference: _vm.$options.filters.priceFormat(
                            _vm.servicePrice.amount - _vm.cancelPrice.amount,
                            _vm.servicePrice.currency
                        ),
                    }))+"\n            ")])]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }