<template>
    <div v-if="offer">
        <v-expansion-panels v-if="isMultiRoom" v-model="roomPanel" multiple flat focusable accordion tile class="mb-4">
            <v-expansion-panel v-for="roomGroupIndex in searchRoomsCount" :key="roomGroupIndex">
                <v-expansion-panel-header
                    class="text-nobreak"
                    :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                    color="blue-grey lighten-5"
                >
                    <div class="d-flex justify-start">
                        <div class="text-uppercase">{{ $tc('room', 1) }} {{ roomGroupIndex }}</div>
                        &nbsp;({{ roomGuestsLabel(roomGroupIndex) }})&nbsp;
                        <v-icon
                            v-for="adult in searchRequestTourists[roomGroupIndex - 1].adults"
                            :key="`adult_${adult}`"
                            small
                        >
                            mdi-account
                        </v-icon>
                        <v-icon
                            v-for="(child, childIndex) in searchRequestTourists[roomGroupIndex - 1].childrenAges"
                            :key="`child_${childIndex}`"
                            x-small
                        >
                            mdi-account
                        </v-icon>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content :class="{'px-4': selectedGroupBy !== 'none' && !$breakpoint.smAndDown}">
                    <v-radio-group
                        v-model="selectedRooms[roomGroupIndex - 1]"
                        hide-details
                        @change="selectRoom(roomGroupIndex)"
                    >
                        <template v-if="selectedGroupBy === 'none'">
                            <div
                                v-for="room in findGroupInRoomOffers(roomGroupIndex).slice(
                                    0,
                                    expanded[roomGroupIndex - 1]
                                )"
                                :key="room.groupedOffers[0].offerKey"
                                :class="{caption: $breakpoint.smAndDown}"
                            >
                                <div class="d-flex">
                                    <div class="room-select-col me-4 py-2 ps-2">
                                        <v-radio :value="room" :disabled="!canSelectRoom(room, roomGroupIndex)" />
                                    </div>
                                    <hotel-room
                                        :group-by="selectedGroupBy"
                                        :search-request="searchRequest"
                                        :room="room"
                                        :hotel="offer"
                                        :hotel-info="hotelInfo"
                                        :hdi="hdi"
                                        :rph="roomGroupIndex"
                                        :compare-mode="compareMode"
                                        :is-package="isPackage"
                                        class="py-2"
                                    >
                                        <template v-slot:price>
                                            <slot name="price" :room="room" />
                                        </template>
                                    </hotel-room>
                                </div>
                                <v-divider />
                            </div>
                            <div
                                v-if="roomsCount(roomGroupIndex) > expanded[roomGroupIndex - 1]"
                                class="d-flex my-4 justify-center"
                            >
                                <v-btn depressed small color="primary" @click.stop="moreRooms(roomGroupIndex - 1)">
                                    {{ $t('show_more_rooms') }}
                                </v-btn>
                            </div>
                        </template>
                        <hotel-grouped-room-offers
                            v-else
                            :rooms="findGroupInRoomOffers(roomGroupIndex)"
                            :offer="offer"
                            :_selected-offer-keys.sync="selectedOfferKeys"
                            :search-request="searchRequest"
                            :hotel-info="hotelInfo"
                            :compare-mode="compareMode"
                            class="my-2"
                            @groupSelected="selectedGroupBy = $event"
                            @sortSelected="selectedSortBy = $event"
                        >
                            <template v-slot:price="{room}">
                                <slot name="price" :room="room" />
                            </template>
                            <template v-slot:radio="{room}">
                                <div class="room-select-col me-4 py-2 ps-2">
                                    <v-radio :value="room" :disabled="!canSelectRoom(room, roomGroupIndex)" />
                                </div>
                            </template>
                        </hotel-grouped-room-offers>
                    </v-radio-group>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <template v-else>
            <template v-if="selectedGroupBy === 'none'">
                <div
                    v-for="(room, roomIndex) in findGroupInRoomOffers(1).slice(0, expanded[0])"
                    :key="room.groupedOffers[0].offerKey"
                >
                    <hotel-room
                        :group-by="selectedGroupBy"
                        :search-request="searchRequest"
                        :room="room"
                        :hotel="offer"
                        :hdi="hdi"
                        :hotel-info="hotelInfo"
                        :rph="1"
                        :compare-mode="compareMode"
                        :is-package="isPackage"
                        :data-cy="`room-offer-card-${roomIndex}`"
                        class="pa-4"
                    >
                        <template v-slot:price>
                            <slot name="price" :room="room" />
                        </template>
                    </hotel-room>
                    <v-divider v-if="roomIndex + 1 < offer.rooms.slice(0, expanded[0]).length" />
                </div>
                <div v-if="roomsCount(1) > expanded[0]" class="d-flex my-4 justify-center">
                    <v-btn depressed small color="primary" :block="false" @click.stop="moreRooms(0)">
                        {{ $t('show_more_rooms') }}
                    </v-btn>
                </div>
            </template>
            <hotel-grouped-room-offers
                v-else
                :rooms="offer.rooms"
                :offer="offer"
                :_selected-offer-keys.sync="selectedOfferKeys"
                :search-request="searchRequest"
                :hotel-info="hotelInfo"
                :compare-mode="compareMode"
                @groupSelected="selectedGroupBy = $event"
                @sortSelected="selectedSortBy = $event"
            >
                <template v-slot:price="{room}">
                    <slot name="price" :room="room" />
                </template>
            </hotel-grouped-room-offers>
        </template>
    </div>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelGroupedRoomOffers from '~src/components/search/offers/hotelGroupedRoomOffers.src'
    import HotelRoom from '~src/components/hotels/search/offers/hotelRoom.src'

    @Component({
        components: {
            HotelRoom,
            HotelGroupedRoomOffers,
        },
    })
    export default class HotelRoomOffers extends Vue {
        @Prop() offer
        @Prop({default: false, type: Boolean}) compareMode
        @Prop({default: false, type: Boolean}) hdi
        @PropSync('_selectedOfferKeys', {default: () => []}) selectedOfferKeys
        @Prop({required: true}) hotelInfo
        @Prop({required: true}) searchRequest
        @Prop({default: false, type: Boolean}) isPackage

        selectedOffers = []
        selectedSortBy = 'price_asc'
        roomGroupIds = []
        firstChosenRoom = null
        selectedRooms = []
        roomPanel = [0]
        expanded = []

        created() {
            this.expanded = new Array(this.searchRoomsCount).fill(5)
        }

        beforeMount() {
            if (this.isMultiRoom) {
                this.selectedRooms = new Array(this.searchRoomsCount)
            }
        }

        mounted() {
            this.selectedRooms = this.selectedOfferKeys.map(offerKey =>
                this.offer.rooms.find(({groupedOffers}) => groupedOffers[0].offerKey === offerKey)
            )
        }

        moreRooms(roomIndex) {
            this.$set(this.expanded, roomIndex, this.expanded[roomIndex] + 5)
        }

        selectRoom(rph) {
            const selectedRooms =
                rph === this.firstSelectedGroup?.rph
                    ? this.selectedRooms.map((room, groupIndex) =>
                          room &&
                          (this.firstSelectedGroup?.rph === groupIndex + 1 || this.canSelectRoom(room, groupIndex + 1))
                              ? room
                              : undefined
                      )
                    : this.selectedRooms
            const selectedOfferKeys = selectedRooms.reduce((selectedOfferKeys, room, groupIndex) => {
                if (room) {
                    const {offerKey} = room.groupedOffers.find(groupedOffer => groupedOffer.rph === groupIndex + 1)
                    selectedOfferKeys.push(offerKey)
                }
                return selectedOfferKeys
            }, [])
            this.selectedRooms = selectedRooms
            this.selectedOfferKeys = selectedOfferKeys
        }

        findGroupInRoomOffers(groupIndex) {
            return this.offer.rooms.filter(
                room => room.groupedOffers.findIndex(groupedOffer => groupedOffer.rph === groupIndex) !== -1
            )
        }

        roomsCount(rph) {
            return this.offer ? this.findGroupInRoomOffers(rph).length : 0
        }

        canSelectRoom(room, rph) {
            if (this.firstSelectedGroup?.roomGroupIds && rph !== this.firstSelectedGroup.rph) {
                const groupedOffer = room.groupedOffers.find(
                    groupedOffer => groupedOffer.rph === rph && groupedOffer.roomGroupIds
                )
                if (groupedOffer) {
                    const idsIntersect = this.firstSelectedGroup.roomGroupIds.filter(
                        id => groupedOffer.roomGroupIds.indexOf(id) !== -1
                    )
                    return idsIntersect.length > 0
                }
            }
            return true
        }

        adultsCount(rph) {
            return this.searchRequestTourists[rph - 1].adults
        }

        childrenCount(rph) {
            return this.searchRequestTourists[rph - 1].childrenAges.length
        }

        roomGuestsLabel(rph) {
            return (
                this.adultsCount(rph) +
                ' ' +
                this.$tc('adult', this.adultsCount(rph)) +
                (this.childrenCount(rph)
                    ? '+ ' + this.childrenCount(rph) + ' ' + this.$tc('child', this.childrenCount(rph))
                    : '')
            )
        }

        get isMultiRoom() {
            return this.searchRoomsCount > 1
        }

        get selectedGroupBy() {
            return hotelsStore.groupOffersBy
        }

        set selectedGroupBy(val) {
            hotelsStore.SET_GROUP_OFFERS_BY(val)
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms?.length || 1
        }

        get searchRequestTourists() {
            return hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
        }

        get firstSelectedGroup() {
            let firstSelectedGroup
            this.selectedRooms.find((room, groupIndex) => {
                if (room) {
                    firstSelectedGroup = room.groupedOffers.find(
                        ({rph, roomGroupIds}) => rph === groupIndex + 1 && roomGroupIds
                    )
                    if (firstSelectedGroup) return true
                }
                return false
            })
            return firstSelectedGroup
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .wrap-words {
        white-space: normal;
    }

    .title {
        text-transform: capitalize;
    }

    .on-request-icon {
        line-height: 17px;
    }

    .room-select-col {
        border-right: 1px solid rgba(map-get($shades, 'black'), 0.12);
    }

    ::v-deep {
        .v-expansion-panel-content__wrap {
            padding: 0;
        }

        .v-input--radio-group--column {
            margin-top: 0;
            padding-top: 0;
        }
    }
</style>
