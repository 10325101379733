<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import SimilarHotels from '@/components/search/offers/SimilarHotels'
    import {hotelsStore} from '@/utils/store-accessor'

    @Component
    export default class RecentViewHotels extends SimilarHotels {
        @Prop({required: false, type: Object}) offer

        beforeDestroy() {
            if (this.offer) {
                hotelsStore.SET_RECENT_HOTELS({...this.offer, cityId: this.searchRequest.cityId})
            }
        }

        filterSimilar() {}

        loadOffers() {}

        getHotelPageLink(link, hotel) {
            const hotelPageLink = link
            hotelPageLink.query.cityId = hotel.cityId

            return hotelPageLink
        }

        get hotels() {
            if (this.offer?.hotelCode) {
                const hotels = hotelsStore.recentHotels
                    .filter(hotel => hotel.hotelCode !== this.offer.hotelCode)
                    .slice(0, 4)

                return hotels
            }

            return []
        }

        get title() {
            return this.$t('your_recent_views')
        }
    }
</script>
