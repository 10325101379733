<template>
    <div>
        <selected-filters :selected="filterValues" :translatable="[]" @delete-filter="clearFilter" />
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            :label="$t('price')"
            :filters="filters"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            filter="name"
            :placeholder="$t('cruises.filter_cruise_name')"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.availability && filters.availability.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="availability"
            :translate-labels="true"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.mealTypeName && filters.mealTypeName.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="mealTypeName"
            :translate-labels="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.cabinType && filters.cabinType.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="cabinType"
            :translate-labels="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.cancellationPolicy && filters.cancellationPolicy.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            filter="cancellationPolicy"
            @change="change($event)"
        />
        <range-filter
            v-if="filters.duration && filters.duration[1] !== Infinity && filters.duration[1]"
            v-model="filterValues"
            :filters="filters"
            filter="duration"
            :pluralization="true"
            :label="$t('duration')"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.dates && filters.dates.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="dates"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.cruiseLine && filters.cruiseLine.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="cruiseLine"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.shipName && filters.shipName.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="shipName"
            @change="change($event)"
        />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import CheckboxFilter from '~/components/search/filters/CheckboxFilter'
    import FilteredMobileButton from '~/components/snippets/FilteredMobileButton'
    import NameFilter from '~src/components/filters/nameFilter.src'
    import SelectedFilters from '~/components/snippets/SelectedFilters'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import FiltersBase from '@/components/search/FiltersBase'
    import RangeFilter from '@/components/search/filters/RangeFilter'

    @Component({
        components: {
            RangeFilter,
            PriceFilter,
            CheckboxFilter,
            FilteredMobileButton,
            NameFilter,
            SelectedFilters,
        },
    })
    export default class CruisesFilters extends FiltersBase {}
</script>
