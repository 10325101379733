<template>
    <flight-booking-page-layout
        v-if="loaded"
        :offer="offer"
        :search-request="searchRequest"
        :search-request-country-id="searchRequestCountryId"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:tourists="{tourists, index, disabled, personSelected}">
            <flight-tourist-form
                v-model="tourists[index]"
                :disabled="disabled"
                :index="index"
                :offer="offer"
                :hide-index="tourists.length === 1"
                :selected-tourists="tourists"
                :start-date="startDate"
                :data-cy="`tourist-${index}`"
                @person-selected="personSelected"
            />
            <extra-baggage-form
                v-model="prepareBookRequest"
                :index="index"
                :disabled="disabled"
                :offer="offer"
                :extra-baggage-options="extraBaggageOptions"
            />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
        </template>
        <template v-slot:forms="{bookingKey}">
            <flight-seats-map
                v-model="prepareBookRequest"
                :offer="offer"
                :disabled="!!bookingKey"
                :search-request="searchRequest"
                :tourists="tourists"
                @map-loaded="flightSeatsMap = $event"
            />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <flight-order-summary
                :offer="offer"
                :prebooked="!!bookingKey"
                :flight-seats-map="flightSeatsMap"
                :extra-baggage-options="extraBaggageOptions"
            >
                <template v-slot:summary>
                    <v-divider class="my-2 mb-3" />
                    <v-card flat tile class="mb-1 pa-0">
                        <v-card-text class="pa-0">
                            <search-summary-content :search-request="searchRequest" show-tourists />
                        </v-card-text>
                    </v-card>
                </template>
            </flight-order-summary>
        </template>
    </flight-booking-page-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {flightsRuntimeStore, flightsStore, persistentStore, runtimeStore} from '@/store'
    import FlightBookingPageLayout from '~/components/flights/booking/FlightBookingPageLayout'
    import FlightTouristForm from '~src/components/booking/forms/flightTouristForm.src'
    import FlightOrderSummary from '~/components/flights/booking/FlightOrderSummary'
    import FlightBreadcrumbs from '~/components/flights/FlightBreadcrumbs'
    import FlightPageMixin from '~/components/flights/mixins/FlightPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import FlightSeatsMap from '@/components/flights/booking/FlightSeatsMap'
    import BookingCommentsForm from '~src/components/booking/forms/bookingCommentsForm.src'
    import SearchSummaryContent from '@/components/parts/SearchSummaryContent'
    import ExtraBaggageForm from '@/components/flights/booking/ExtraBaggageForm.vue'

    @Component({
        components: {
            ExtraBaggageForm,
            FlightSeatsMap,
            ExpiredOffersSnackbar,
            FlightBookingPageLayout,
            FlightTouristForm,
            FlightOrderSummary,
            FlightBreadcrumbs,
            BookingCommentsForm,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class FlightBookingPage extends mixins(FlightPageMixin) {
        loaded = false
        flightSeatsMap = {}
        extraBaggageOptions = []

        validate({query}) {
            return query.offerKey
        }

        @Watch('locale')
        loadFlightPreferences() {
            const {offerKey} = this.$route.query
            flightsStore.loadFlightPreferences(offerKey)
        }

        async mounted() {
            const {offerKey} = this.$route.query
            await this.$store.restored
            await Promise.all([
                this.loadRuntimeData(),
                runtimeStore.loadTouristBookingFields({offerKey: offerKey}),
                this.loadAdditionalFlightOptions(offerKey),
                flightsStore.clearBookingAdditionalOptions(),
                flightsStore.loadFlightPreferences(offerKey),
            ])
            this.loaded = true
        }

        async loadAdditionalFlightOptions(offerKey) {
            try {
                const {extraBaggageOptions} = await this.$api.additionalFlightOptions.get({offerKey})
                if (extraBaggageOptions) {
                    this.extraBaggageOptions = extraBaggageOptions
                } else {
                    this.extraBaggageOptions = []
                }
            } catch (e) {
                this.extraBaggageOptions = []
            }
        }

        refreshExpiredOffers() {
            this.$router.push(flightsRuntimeStore.flightsPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !flightsStore.prepareBookResponse.bookingKey
            )
        }

        get basketItem() {
            return flightsStore.basketItem(this.$route.query.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get locale() {
            return this.$i18n.locale
        }

        get productStore() {
            return flightsStore
        }

        get searchRequestCountryId() {
            return flightsRuntimeStore.routes[0]?.arrival?.countryId
        }

        get startDate() {
            return flightsRuntimeStore.routes[0].date
        }

        get prepareBookRequest() {
            return new Proxy(flightsStore.prepareBookRequest, {
                set: (target, prop, val) => {
                    flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({prop, val})
                    return true
                },
                get: (target, prop) => {
                    return target[prop]
                },
            })
        }

        get tourists() {
            return persistentStore.tourists
        }
    }
</script>
