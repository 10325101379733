<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {CHANGE_PRICE_EVENT, EventBus, SHOW_NOTIFICATIONS} from '@/utils/event-bus'
    import {persistentStore} from '@/utils/store-accessor'
    import {loadStripe} from '@stripe/stripe-js'
    import AdyenCheckout from '@adyen/adyen-web'

    @Component
    export default class BookingMixin extends Vue {
        hyperPayShopperResultUrl = null
        hyperPaycheckoutId = null

        //TODO Remove this and VendorMessagesModal and replace them to more advanced PrepareBookMessagesModal with PrepareBookAdapter
        prepareBookResponseProcessing(prepareBookResponse, offerKey, prepareBookRequest, changePrice) {
            if (parseInt(prepareBookResponse.status, 10) > 2 && +prepareBookResponse.status !== 15) {
                //TODO Setup messages UI
                if (prepareBookResponse.errors) {
                    prepareBookResponse.errors.forEach(e => {
                        if (e.message) {
                            if (e.errorType === 'external') {
                                if (e.token === 'external_150' || e.token === 'external_23') {
                                    EventBus.$emit(
                                        'SHOW_OFFER_NOT_AVAILABLE_CITIZENSHIP',
                                        prepareBookRequest.tourists[0].citizenshipId
                                    )
                                } else {
                                    this.$toast.error(this.$t('error_message.something_went_wrong'), {queueable: true})
                                }
                            } else {
                                this.$toast.error(e.message, {queueable: true})
                            }
                        } else {
                            this.$toast.error(this.$t('error_message.prepare_book_unknown_error'), {
                                queueable: true,
                            })
                        }
                    })
                } else if (prepareBookResponse.warnings) {
                    prepareBookResponse.warnings.forEach(warning =>
                        this.$toast.warning(warning.message, {queueable: true})
                    )
                } else {
                    this.$toast.error(this.$t('error_message.prepare_book_unknown_error'), {
                        queueable: true,
                    })
                }
                return false
            } else {
                if (prepareBookResponse.warnings?.length) {
                    prepareBookResponse.warnings.forEach(warning => {
                        if (warning.token === 'external_price_has_been_changed') {
                            if (offerKey) {
                                if (changePrice) {
                                    changePrice(offerKey, prepareBookResponse)
                                } else {
                                    EventBus.$emit(CHANGE_PRICE_EVENT, {offerKey, prepareBookResponse})
                                }
                            }
                            this.$toast.warning(this.$t(`warning_message.${warning.token}`))
                        }
                    })
                }
                if (prepareBookRequest?.adjustedPriceAmount) {
                    if (offerKey) {
                        if (changePrice) {
                            changePrice(offerKey, prepareBookResponse)
                        } else {
                            EventBus.$emit(CHANGE_PRICE_EVENT, {offerKey, prepareBookResponse})
                        }
                    }
                }
                if (prepareBookResponse.vendorMessages && prepareBookResponse.vendorMessages.length) {
                    //TODO Setup messages UI
                    //TODO Remove <p> in api response
                    /*prepareBookResponse.vendorMessages.forEach(msg =>
                        this.$toast.warning(msg, {
                            icon: 'mdi-information',
                            closeIcon: 'mdi-close',
                            showClose: true,
                            timeout: 0,
                        })
                    )*/
                }
                if (prepareBookResponse.dueToConfirmDate) {
                    this.dueToConfirmDate = prepareBookResponse.dueToConfirmDate
                }
            }
            return true
        }

        prepareBookErrorProcessing(e) {
            //TODO Check other API errors
            if (e.status === 404) {
                //TODO Show offers expired
            } else {
                //TODO Is need to show all API messages?
                let hasValidationMessages = false
                if (e.errors) {
                    e.errors.forEach(e => {
                        if (e.errorType === 'validation') {
                            hasValidationMessages = true
                            let errorMsg = e.message
                            if (e.token === 'api.please.specify.serviceRPH.and.quantity') {
                                errorMsg = this.$t('extraServices.errors.empty_qty')
                            }
                            this.$toast.error(errorMsg, {queueable: true})
                        }
                    })
                }
                if (!hasValidationMessages) {
                    this.$toast.error(this.$t('error_message.prepare_book_unknown_error'))
                }
            }
            return false
        }

        async makeOnlinePayment() {
            const findParameter = (name, array) => {
                return array.find(e => e.name === name)?.value
            }

            try {
                const paymentInitiateRs = await persistentStore.paymentInitiate(this.getPaymentInitiateRq())
                if (paymentInitiateRs.simpleRedirect) {
                    if (paymentInitiateRs.parameters.length) {
                        const query = new URLSearchParams(
                            paymentInitiateRs.parameters.map(({name, value}) => [name, value])
                        )
                        window.location = paymentInitiateRs.url + '?' + query
                    } else {
                        window.location = paymentInitiateRs.url
                    }
                } else {
                    const paymentMethod = persistentStore.paymentMethods.find(
                        paymentMethod => paymentMethod.id === this.paymentMethodId
                    )
                    if (paymentMethod.pspCode === 'webpay' && this.$config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        await this.$refs.paymentRedirectForm.submit({
                            url: `${this.$config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else if (paymentMethod.pspCode === 'stripe') {
                        const {APIPublicKey, sessionId} = paymentInitiateRs.parameters.reduce(
                            (parameters, {name, value}) => {
                                parameters[name] = value
                                return parameters
                            },
                            {}
                        )
                        const stripe = await loadStripe(APIPublicKey)
                        await stripe.redirectToCheckout({sessionId})
                    } else if (paymentMethod.pspCode === 'adyen') {
                        const checkout = await AdyenCheckout({
                            session: {
                                id: findParameter('SESSION_ID', persistentStore.paymentInitiateResponse.parameters),
                                sessionData: findParameter(
                                    'SESSION_DATA',
                                    persistentStore.paymentInitiateResponse.parameters
                                ),
                            },
                            clientKey: findParameter(
                                'CLIENT_API_KEY',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            environment: findParameter(
                                'ENVIRONMENT',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            onPaymentCompleted: res => {
                                if (res.resultCode.toLowerCase() === 'authorised') {
                                    const params = {
                                        paymentNumber: paymentInitiateRs.paymentNumber,
                                        ...res,
                                    }
                                    window.location = `${this.getPaymentSuccessUrl()}&${new URLSearchParams(
                                        params
                                    ).toString()}`
                                } else {
                                    window.location = `${this.getPaymentInitiateRq().failureUrl}`
                                }
                            },
                            onError: () => {},
                        })

                        checkout.create('dropin').mount('#dropin-container')
                    } else if (paymentMethod.pspCode === 'h_pay_visa' || paymentMethod.pspCode === 'h_pay_mada') {
                        this.hyperPaycheckoutId = findParameter(
                            'checkoutId',
                            persistentStore.paymentInitiateResponse.parameters
                        )

                        this.hyperPayShopperResultUrl = findParameter(
                            'shopperResultUrl',
                            persistentStore.paymentInitiateResponse.parameters
                        )

                        sessionStorage.setItem('hyperPaycheckoutId', this.hyperPaycheckoutId)
                        sessionStorage.setItem('hyperPayShopperResultUrl', this.hyperPayShopperResultUrl)
                    } else {
                        await this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
                return true
            } catch (e) {
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('error_message.payment_initiate_error')],
                    color: 'error',
                })
                return false
            }
        }

        getPaymentInitiateRq() {
            return {
                ...(this.bookingKey && {bookingKey: this.bookingKey}),
                ...(this.basketKey && {basketKey: this.basketKey}),
                paymentMethodId: this.paymentMethodId,
                successUrl: this.getPaymentSuccessUrl(),
                failureUrl: this.getPaymentCancelUrl('failure'),
                cancelUrl: this.getPaymentCancelUrl('cancel'),
                ...(this.numberOfPayments && {splitPaymentQuantity: this.numberOfPayments}),
            }
        }

        getPaymentSuccessUrl() {
            return (
                window.location.origin +
                this.$router.resolve({
                    name: 'paymentComplete',
                    query: {
                        paymentMethodId: this.paymentMethodId,
                        ...(this.bookingKey && {bookingKey: this.bookingKey}),
                        ...(this.basketKey && {basketKey: this.basketKey}),
                        product: this.productName,
                        ...(this.corporatePolicyViolationCodeId && {
                            corporatePolicyViolationCodeId: this.corporatePolicyViolationCodeId,
                        }),
                        ...(this.dueToConfirmDateActive && {dueToConfirmDate: this.dueToConfirmDate}),
                    },
                }).href
            )
        }
    }
</script>
