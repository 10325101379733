<template>
    <v-tabs v-if="isMobile" v-model="mobileTabs" icons-and-text fixed-tabs class="sticky-bar">
        <v-tab v-for="tab in tabs" :key="tab.label">
            {{ isMobile ? $tc(`${tab.label}`) : '' }}
            <v-icon>{{ tab.icon }}</v-icon>
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, VModel} from 'nuxt-property-decorator'

    @Component
    export default class HotelInfoMobileTabs extends Vue {
        @VModel() mobileTabs

        defaultTabs = [
            {icon: 'mdi-office-building', label: 'hotel'},
            {icon: 'mdi-bed', label: 'rooms'},
            {icon: 'mdi-map-marker', label: 'map'},
        ]

        get tabs() {
            return this.defaultTabs
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
