<template>
    <div class="d-flex flex-column align-start">
        <download-custom-document-button
            v-for="(template, index) in templates"
            :key="index"
            :order="order"
            :checkbox-services-selected="checkboxServicesSelected"
            :template="template"
            class="px-0"
        />
    </div>
</template>
<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import DownloadCustomDocumentButton from '@/components/account/snippets/DownloadCustomDocumentButton'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'

    @Component({
        components: {DownloadCustomDocumentButton},
    })
    export default class OrderCustomDocuments extends mixins(DocumentTemplatesMixin) {
        @Prop({required: true}) order
        @Prop() checkboxServicesSelected

        async mounted() {
            await this.loadDocumentTemplates()
        }

        get templates() {
            return this.documentTemplates.filter(template => {
                if (this.order.clientType === 'DIRECT_SALES') {
                    return template.documentType.toLowerCase() !== 'agent invoice'
                }

                return true
            })
        }
    }
</script>
