<template>
    <div v-if="selectedGroupDetails" class="d-flex flex-column text-subtitle-2 font-weight-regular">
        <div class="d-flex">
            <v-icon small class="mr-1">{{ serviceTypeIcon }}</v-icon>
            <span class="font-weight-medium">
                {{ selectedGroupDetails.serviceName }}
            </span>
        </div>
        <span class="mb-2 text--secondary">
            {{ `${formattedDate}` }}
        </span>
        <div v-if="guide">
            <span class="font-weight-medium" v-text="`${$t('roles.guide')}: `" />
            <span v-text="`${guide.firstName} ${guide.lastName}`" />
        </div>
        <div v-if="driver">
            <span class="font-weight-medium" v-text="`${$t('roles.driver')}: `" />
            <span v-text="`${driver.firstName} ${driver.lastName}`" />
        </div>
        <div v-if="selectedGroupDetails.comment" class="d-flex">
            <span class="font-weight-medium" v-text="`${$t('comments')}:`" /> &nbsp;
            <span v-html="selectedGroupDetails.comment" />
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {getServiceTypeIcon} from '@/utils/helpers'

    @Component
    export default class GroupDetailsInfo extends Vue {
        @Prop() selectedGroupDetails

        get serviceTypeIcon() {
            return getServiceTypeIcon(this.selectedGroupDetails.serviceType)
        }

        get formattedDate() {
            return this.$dateFns.format(new Date(this.selectedGroupDetails.dateTime), 'dd MMM yyyy (eee) p')
        }

        get guide() {
            return this.selectedGroupDetails?.guide
        }

        get driver() {
            return this.selectedGroupDetails?.driver
        }
    }
</script>
