<template>
    <div>
        <company-autocomplete
            v-model="selectedCompany"
            :label="`${$t('choose.company_long')}*`"
            class="mb-8"
            :types="isTO1 ? ['TOUR_OPERATOR', 'TOUR_AGENCY'] : isTO2 ? ['TOUR_AGENCY'] : []"
        />
        <div class="d-flex">
            <client-autocomplete
                v-if="!newAgentMode"
                ref="clients"
                v-model="selectedClient"
                :label="`${$t('choose.agent_long')}*`"
                :company-id="selectedCompany && selectedCompany.id"
                :is-ta="true"
                :disabled="!selectedCompany"
            >
                <v-list-item ripple @mousedown.prevent @click="onNewAgentClick">
                    <v-list-item-title class="primary--text font-weight-medium">
                        {{ $t('create_new_agent') }}
                    </v-list-item-title>
                </v-list-item>
            </client-autocomplete>
            <div class="d-flex flex-column ms-2">
                <v-select
                    v-model="newClient.prefix"
                    :items="['Mr', 'Ms', 'Mrs']"
                    persistent-placeholder
                    outlined
                    :label="`${$t('salutation')}*`"
                    :rules="[requiredRule('salutation')]"
                />
                <v-text-field
                    v-model="newClient.firstName"
                    :label="`${$t('first_name')}*`"
                    persistent-placeholder
                    outlined
                    :rules="[requiredRule('first_name')]"
                />
                <v-text-field
                    v-model="newClient.lastName"
                    :label="`${$t('last_name')}*`"
                    persistent-placeholder
                    outlined
                    :rules="[requiredRule('last_name')]"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, PropSync, Watch} from 'nuxt-property-decorator'
    import CompanyAutocomplete from '@/components/account/modals/newOrderRequest/CompanyAutocomplete'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import {Mixins} from 'vue-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import PersonForm from '~src/components/account/forms/personForm.src'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {PersonForm, ClientAutocomplete, CompanyAutocomplete},
    })
    export default class TourAgency extends Mixins(FormValidationRulesMixin) {
        @PropSync('_selectedCompany') selectedCompany
        @PropSync('_selectedClient') selectedClient
        @PropSync('_newClient') newClient
        @PropSync('_newAgentMode') newAgentMode

        firstName = null
        lastName = null

        @Watch('newAgentMode')
        async onNewAgentModeChange() {
            if (!this.newAgentMode) {
                await this.$nextTick(() => {})
                this.$refs.clients?.onInput('')
            }
        }

        onNewAgentClick() {
            this.newAgentMode = true
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }
    }
</script>
