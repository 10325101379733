<template>
    <v-card>
        <v-card-text>
            <v-row dense>
                <v-col cols="6">
                    <v-select v-model="hours" :items="hourItems" label="HH" hide-details />
                </v-col>
                <v-col cols="6">
                    <v-select v-model="minutes" :items="minuteItems" label="mm" hide-details />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Model, Vue} from 'vue-property-decorator'

    @Component
    export default class TimePicker extends Vue {
        @Model('change') value

        selections = 0

        get hourItems() {
            return [...Array(24).keys()].map(hour => (hour < 10 ? '0' + hour : hour.toString()))
        }

        get minuteItems() {
            return [...Array(60).keys()].map(hour => (hour < 10 ? '0' + hour : hour.toString()))
        }

        get hours() {
            return this.value ? this.value.split(':')[0] : ''
        }

        set hours(val) {
            let minutes = this.minutes
            if (!minutes) {
                minutes = '00'
            }
            this.$emit('change', `${val}:${minutes}`)
            this.selections++
            if (this.selections === 2) {
                this.selections = 0
                this.$emit('update')
            }
        }

        get minutes() {
            return this.value ? this.value.split(':')[1] : ''
        }

        set minutes(val) {
            let hours = this.hours
            if (!hours) {
                hours = '00'
            }
            this.$emit('change', `${hours}:${val}`)
            this.selections++
            if (this.selections === 2) {
                this.selections = 0
                this.$emit('update')
            }
        }
    }
</script>
