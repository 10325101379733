<script>
    import {Component} from 'nuxt-property-decorator'
    import MapLocation from '~/components/info/MapLocation'

    @Component
    export default class MapLocationMod extends MapLocation {}
</script>

<style scoped lang="scss">
    .map {
        min-height: 220px;
        z-index: 1;
    }
</style>
