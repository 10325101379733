<template>
    <order-summary-layout :total-price="totalPrice" :product-store="productStore">
        <template v-slot>
            <div class="d-flex">
                <v-img
                    max-width="150"
                    class="me-3"
                    tile
                    :src="image | imageUrl(600)"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <div>
                    <div :title="info.name" class="primary--text font-weight-medium text-subtitle-1">
                        {{ info.name }}
                    </div>
                    <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" class="mb-2" />
                    <div v-if="info.shortDescription" class="mb-4 text-body-2" v-html="info.shortDescription" />
                </div>
            </div>
            <slot name="summary" />
            <v-divider class="mb-2" />
            <div>
                <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                    <v-icon small left>mdi-information-outline</v-icon>
                    <span>
                        {{ offer.serviceName }}
                    </span>
                </div>
                <div v-if="offer.available" class="d-flex align-center caption">
                    <v-icon :title="$t('available')" left small class="success--text">
                        mdi-checkbox-marked-circle
                    </v-icon>
                    <span class="success--text" v-text="$t('available')" />
                </div>
                <div v-else class="caption d-flex align-center">
                    <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                    <span class="warning--text" v-text="$t('on_request')" />
                </div>
                <cruise-meal-type-label v-if="offer.mealType" :offer="offer" />
                <conditions-label
                    :offer-key="offer.offerKey"
                    :price="offer.price"
                    class="text-caption"
                    @conditions="showConditionsInfo"
                />
                <div class="mt-1 font-weight-bold">
                    <span>
                        {{ offerPrice | price }}
                    </span>
                </div>
                <extra-services-summary :services="bookingExtraServices" />
                <add-ons-summary-list :selected-add-ons="selectedAddOns" />
            </div>
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, cruisesStore} from '@/store'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import {EventBus, SHOW_CRUISE_CONDITIONS_INFO, SHOW_CRUISE_OFFER_INFO_MODAL} from '@/utils/event-bus'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import CruiseMealTypeLabel from '@/components/hotels/snippets/CruiseMealTypeLabel'
    import AddOnsSummaryList from '@/components/booking/addOns/AddOnsSummaryList.vue'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            CruiseMealTypeLabel,
            AddOnsSummaryList,
            SupplierNameLabel,
            ExtraServicesSummary,
            OrderSummaryLayout,
            ConditionsModal,
            ConditionsLabel,
        },
    })
    export default class CruiseOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({default: true}) prebooked
        @Prop({required: true, type: Array}) selectedAddOns

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_CRUISE_CONDITIONS_INFO, this.offer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_CRUISE_OFFER_INFO_MODAL, {offer: this.offer})
        }

        get offerPrice() {
            const price = clone(this.offer.price)
            const mandatory =
                cruisesStore.bookingAdditionalOptions?.extraServices?.filter(extraService => extraService.mandatory) ||
                []

            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }

            return price
        }

        get bookingExtraServices() {
            const mandatory =
                cruisesStore.bookingAdditionalOptions?.extraServices?.filter(extraService => extraService.mandatory) ||
                []

            mandatory.forEach(item => {
                const price = item.salesTerms.find(el => el.type === 'CLIENT')

                item.price = {
                    ...price.price,
                    ...price,
                }
                item.quantity = 1
            })

            return [...mandatory, ...cruisesStore.bookingExtraServices]
        }

        get productStore() {
            return cruisesStore
        }

        get image() {
            if (!this.info.images) return null
            let main = this.info.images.find(image => image.mainImage)
            if (!main && this.info.images.length > 0) {
                main = this.info.images[0]
            }
            return main && main.url ? main.url : null
        }

        get isTO1() {
            return authStore.isTO1
        }

        get totalPrice() {
            return cruisesStore.orderTotalPrice(this.offer, this.prebooked, this.selectedAddOns)
        }

        get basketItem() {
            return this.productStore.basketItem(this.$route.query.offerKey)
        }

        get product() {
            return this.basketItem.product
        }
    }
</script>
