<template>
    <v-card hover dark rounded="xl" height="426px" width="387px" @click="$emit('selected', destination.id)">
        <v-hover v-slot="{hover}">
            <v-img
                :src="destination.img | srcHost"
                :lazy-src="'/placeholder.png' | srcHost"
                eager
                :gradient="
                    hover
                        ? 'to bottom,rgba(98, 183, 229, 0.5), rgba(98, 183, 229, 0.5)'
                        : 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)'
                "
                class="white--text align-end full-width full-height"
            >
                <v-card-title class="text-h5 font-weight-medium" v-text="destination.name" />
            </v-img>
        </v-hover>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import PopularDestination from '@/components/parts/PopularDestination'

    @Component
    export default class PopularDestinationMod extends PopularDestination {}
</script>
