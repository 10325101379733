<template>
    <a
        v-if="showPrice"
        v-intersect.once="onIntersect"
        :class="color"
        class="d-flex align-center"
        @click.prevent="showConditions"
    >
        <v-icon small left class="info--text" :color="iconColor">mdi-bell-alert-outline</v-icon>
        {{ label }}
    </a>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/store'
    import {EventBus, SHOW_CONDITIONS_MODAL_EVENT} from '@/utils/event-bus'

    @Component
    export default class ConditionsLabel extends Vue {
        @Prop() offerKey
        @Prop({required: true}) price
        @Prop({default: true, type: Boolean}) xSmall
        @Prop({default: false, type: Boolean}) small
        @Prop() offer
        @Prop({default: 'primary'}) iconColor

        @Emit()
        conditions() {}

        beforeDestroy() {
            if (runtimeStore.conditionsLoading[this.offerKey]) {
                runtimeStore.conditionsLoading[this.offerKey].cancel()
            }
        }

        async showConditions() {
            EventBus.$emit(SHOW_CONDITIONS_MODAL_EVENT, this.offerKey)
            await this.$nextTick()
            this.conditions()
        }

        async onIntersect(entries, observer, isIntersecting) {
            if (
                isIntersecting &&
                !persistentStore.conditions[this.offerKey] &&
                !runtimeStore.conditionsLoading[this.offerKey]
            ) {
                await persistentStore.loadConditions({offerKey: this.offerKey})
            }
        }

        get isLoading() {
            return runtimeStore.isConditionsLoading(this.offerKey)
        }

        get hasErrors() {
            return (
                !this.conditionsInfo.cancellationItems ||
                !this.conditionsInfo.cancellationItems.length ||
                !this.conditionsInfo.cancellationItems[0].price
            )
        }

        get isFree() {
            return !this.conditionsInfo.cancellationItems.length
        }

        get isNonRefundable() {
            const firstCondition = this.conditionsInfo.cancellationItems[0]
            return (
                firstCondition.dateFrom &&
                firstCondition.price &&
                new Date() >= this.$dateFns.parseISO(firstCondition.dateFrom) &&
                this.$options.filters.convertPrice(this.price).amount <=
                    this.$options.filters.convertPrice(firstCondition.price).amount
            )
        }

        get freeDate() {
            const firstCondition = this.conditionsInfo.cancellationItems[0]
            if (!firstCondition.dateFrom || new Date() >= this.$dateFns.parseISO(firstCondition.dateFrom)) {
                return undefined
            }
            return this.$dateFns.parse(firstCondition.dateFrom, 'yyyy-MM-dd HH:mm', new Date())
        }

        get conditionsInfo() {
            const offerKey = this.offerKey
            return persistentStore.conditions[offerKey] || {cancellationItems: []}
        }

        get status() {
            if (this.isLoading) {
                return 'isLoading'
            } else if (this.hasErrors) {
                return 'hasErrors'
            } else if (this.isFree) {
                return 'isFree'
            } else if (this.isNonRefundable) {
                return 'isNonRefundable'
            } else if (this.freeDate) {
                return 'freeDate'
            } else {
                return 'special'
            }
        }

        get color() {
            switch (this.status) {
                case 'isLoading':
                    return 'black--text'
                case 'hasErrors':
                    return 'black--text'
                case 'isNonRefundable':
                    return 'red--text'
                case 'isFree':
                case 'freeDate':
                    return 'green--text'
                default:
                    return 'black--text'
            }
        }

        get label() {
            switch (this.status) {
                case 'isLoading':
                case 'hasErrors':
                    return this.$t('cancellation_policy')
                case 'isNonRefundable':
                    return this.$t('non_refundable')
                case 'isFree':
                    return this.isFreeLabel
                case 'freeDate':
                    return this.$t('free_cancellation_before_date', {
                        date: this.$options.filters.dateFormat(this.freeDate),
                    })
                default:
                    return this.$t('cancellation_policy')
            }
        }

        get isFreeLabel() {
            return this.$t('cancellation_free')
        }

        get showPrice() {
            return true
        }
    }
</script>
