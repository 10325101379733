<template>
    <order-summary-layout :product-store="productStore" :total-price="totalPriceAllOrders">
        <div class="d-flex">
            <v-img
                :aspect-ratio="4 / 3"
                :src="mainImage.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                max-width="150"
                max-height="112.5"
                tile
                class="me-3"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && mainImage.url" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                <div
                    :title="product.info.name"
                    class="me-2 primary--text font-weight-medium text-subtitle-1 text-truncate"
                >
                    {{ product.info.name }}
                </div>
                <v-chip v-if="offer.transferType" label x-small>
                    {{ $t(`filters_transferType.${offer.transferType}`) }}
                </v-chip>
                <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" class="mb-2" />
                <div v-if="info.shortDescription" class="mb-4 text-body-2 text-wrap">
                    {{ info.shortDescription }}
                </div>
            </div>
        </div>
        <slot name="summary" />
        <v-divider class="mb-2" />
        <div>
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                <v-icon small left>mdi-information-outline</v-icon>
                <span>
                    {{ offer.info.description }}
                </span>
            </div>
            <div v-if="offer.available" class="caption d-flex align-center">
                <v-icon :title="$t('available')" left small class="success--text"> mdi-checkbox-marked-circle </v-icon>
                <span class="success--text" v-text="$t('available')" />
            </div>
            <div v-else class="caption d-flex align-center">
                <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                <span class="warning--text" v-text="$t('on_request')" />
            </div>
            <conditions-label
                :offer-key="offer.offerKey"
                :price="offer.price"
                class="text-caption"
                @conditions="showConditionsInfo"
            />
            <div v-if="offer.info.tariffName" class="caption d-flex align-center">
                <v-icon left small class="info--text">mdi-tag-text</v-icon>
                <span>
                    {{ offer.info.tariffName }}
                </span>
            </div>
            <transfer-driver-languages :lang-codes="offer.langCodes" />
            <div class="mt-1 font-weight-bold">
                <span>
                    {{ offerPrice | price }}
                </span>
                <commission-amount :commission="offer.price.commission" />
                <net-amount :price="offer.price" />
            </div>
        </div>
        <extra-services-summary :services="bookingExtraServices" />
        <template v-if="!!returnTransferOffer">
            <v-divider class="my-8 d-flex" />
            <transfer-return-order-summary-card
                :info-return-transfer="infoReturnTransfer"
                :return-transfer-offer="returnTransferOffer"
                :search-request-return-transfer="searchRequestReturnTransfer"
                :total-price-return-transfer="totalPriceReturnTransfer"
            />
        </template>
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'
    import {authStore, transfersRuntimeStore, transfersStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import TransferReturnOrderSummaryCard from '@/components/transfers/booking/TransferReturnOrderSummaryCard'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            TransferReturnOrderSummaryCard,
            TransferDriverLanguages,
            ExtraServicesSummary,
            OrderSummaryLayout,
            TransferConditionsModalInfo,
            ConditionsModal,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class TransferOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({required: true}) product
        @Prop({required: true}) searchRequest
        @Prop({default: true}) prebooked

        @Prop() returnTransferOffer
        @Prop() infoReturnTransfer
        @Prop() searchRequestReturnTransfer

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequest, this.offer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        get productStore() {
            return transfersStore
        }

        get image() {
            if (!this.offer.images) return null
            let main = this.offer.images.find(image => image.mainImage)
            if (!main && this.offer.images.length > 0) {
                main = this.offer.images[0]
            }
            return main && main.url ? main.url : null
        }

        get offerPrice() {
            const price = clone(this.offer.price)
            const mandatory =
                transfersStore.bookingAdditionalOptions?.extraServices?.filter(
                    extraService => extraService.mandatory
                ) || []

            if (mandatory.length) {
                mandatory.forEach(item => {
                    price.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount
                    price.originalAmount -= item.salesTerms.find(el => el.type === 'CLIENT').originalAmount
                })
            }

            return price
        }

        get totalPrice() {
            return transfersStore.orderTotalPrice(this.offer, this.prebooked, false)
        }

        get totalPriceReturnTransfer() {
            return transfersStore.orderTotalPrice(this.returnTransferOffer, this.prebooked, true)
        }

        get totalPriceAllOrders() {
            const total = {...this.totalPrice}

            if (this.returnTransferOffer) {
                total.amount += this.totalPriceReturnTransfer.amount
                total.originalAmount += this.totalPriceReturnTransfer.originalAmount
            }

            return total
        }

        get mainImage() {
            return transfersRuntimeStore.offerMainImage(this.offer, this.info) || null
        }

        get bookingExtraServices() {
            const mandatory =
                transfersStore.bookingAdditionalOptions?.extraServices?.filter(
                    extraService => extraService.mandatory
                ) || []

            mandatory.forEach(item => {
                const price = item.salesTerms.find(el => el.type === 'CLIENT')

                item.price = {
                    ...price.price,
                    ...price,
                }
                item.quantity = 1
            })

            return [...mandatory, ...transfersStore.bookingExtraServices]
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
