<template>
    <div v-if="hasPrice || hotelPageLink">
        <div v-if="hasPrice" class="d-flex flex-column align-center">
            <div class="d-flex flex-nowrap justify-center">
                <div
                    class="font-weight-bold text-subtitle-1 text-center"
                    :class="{title: mobile, 'text--primary': mobile}"
                >
                    {{ $t('from') }} {{ minPrice }}
                </div>
                <corporate-policy-violation
                    v-if="minPriceRoomOffer.corporatePolicyViolation && searchRoomsCount === 1"
                    :policies="minPriceRoomOffer.corporatePolicy"
                />
            </div>
            <discount-label
                v-if="searchRoomsCount === 1"
                :price="minPriceRoomOffer.price"
                class="d-flex flex-column align-center"
            />
            <commission-amount v-if="searchRoomsCount === 1" :commission="minPriceRoomOffer.price.commission" />
            <rate-note :room="minPriceRoomOffer" />
        </div>
        <v-btn
            v-if="hotelPageLink"
            depressed
            color="primary"
            :class="{'my-2': !mobile}"
            class="px-8 font-weight-regular"
            nuxt
            :to="hotelPageLink"
        >
            {{ $t('see_details') }}
        </v-btn>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferPriceBlock from '@/components/search/offers/HotelOfferPriceBlock'

    @Component
    export default class HotelOfferPriceBlockMod extends HotelOfferPriceBlock {}
</script>
