<template>
    <v-card
        :min-height="minHeight"
        tile
        flat
        :img="background"
        class="main-page-form-wrapper d-flex align-center justify-center"
    >
        <template v-if="!img">
            <img v-for="(src, index) in images" :key="index" alt="" :src="src" class="d-none" />
        </template>
        <search-form-tabs :city-id="cityId" choose-agent class="my-7 my-md-0" @change="changeImg" />
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import SearchFormTabs from '~src/components/searchFormTabs.src'
    import {cmsStore} from '@/utils/store-accessor'

    @Component({
        components: {SearchFormTabs},
    })
    export default class MainPageFormWrapper extends Vue {
        @Prop() img
        @Prop({default: 500}) minHeight
        @Prop() cityId

        background = null

        created() {
            this.background = this.img
        }

        changeImg(product) {
            if (!this.img) this.background = this.backgrounds[product]
        }

        get backgrounds() {
            const bg = (caption, defaultBackground) =>
                this.$options.filters.srcHost(
                    cmsStore.homePageSettings.searchFormBackgrounds[caption] || defaultBackground
                )
            return {
                hotels: bg('hotel', '/home-bg.jpg'),
                transfers: bg('transfer', '/home-bg-transfers.jpg'),
                activities: bg('activity', '/home-bg-activities.jpg'),
                flights: bg('flight', '/home-bg-flights.jpg'),
                packages: bg('package', '/packages-bg.jpg'),
                //TODO - background image
                tours: bg('tour', '/packages-bg.jpg'),
                carsRent: bg('car', '/home-bg-car.jpg'),
                insurance: bg('insurance', '/home-bg-insurance.jpg'),
                extraServices: bg('extraService', '/home-bg-extra-services.jpg'),
                conferenceHall: bg('conference_hall', '/home-bg-conference-hall.jpg'),
                catering: bg('catering', '/home-bg-catering.jpg'),
                cruises: bg('cruises', '/home-bg-cruises.jpg'),
            }
        }

        get images() {
            return [...new Set(Object.values(this.$config.activeProducts).map(product => this.backgrounds[product]))]
        }
    }
</script>

<style scoped lang="scss">
    .main-page-form-wrapper {
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;

        ::v-deep > div {
            width: 80%;
        }
    }
</style>
