<template>
    <div v-if="!person">
        <v-alert v-if="memberLevel === 'GUEST'" border="left" colored-border color="accent">
            <span class="text-subtitle-1 me-2">
                {{ $t('notes.logged_unregistered') }}
            </span>
            <v-btn depressed :disabled="disabled" color="primary" class="me-2" @click="memberLevel = 'AUTH'">
                {{ $t('sign_in') }}
            </v-btn>
            <v-btn
                outlined
                :disabled="disabled"
                color="primary"
                class="text-capitalize"
                @click="memberLevel = 'REGISTERED'"
            >
                {{ $t('register_new_account') }}?
            </v-btn>
        </v-alert>
        <v-card v-else outlined class="mb-5" :disabled="disabled">
            <v-card-title class="title font-weight-light">
                {{ memberLevel === 'REGISTERED' ? $t('register') : $t('sign_in') }} {{ $t('or') }}&nbsp;
                <a href="#" class="font-weight-medium text-decoration-none" @click.prevent="memberLevel = 'GUEST'">
                    {{ $t('continue_as_guest') }}
                </a>
            </v-card-title>
            <v-card-text>
                <registration-form v-if="memberLevel === 'REGISTERED'" hide-btn />
                <template v-if="memberLevel === 'AUTH'">
                    <authorization-form v-if="!forgotPassword" @switch-forgot-password="forgotPassword = true" />
                    <template v-if="forgotPassword">
                        <div class="title mt-4">{{ $t('forgot_your_password') }}</div>
                        <forgot-password-form
                            @switch-login="forgotPassword = false"
                            @forgot-password-success="forgotPassword = false"
                        />
                    </template>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import RegistrationForm from '~src/components/account/forms/registrationForm.src'
    import AuthorizationForm from '~src/components/account/forms/authorizationForm.src'
    import {authStore} from '@/store'
    import ForgotPasswordForm from '~src/components/account/forms/forgotPasswordForm.src'

    @Component({
        components: {ForgotPasswordForm, AuthorizationForm, RegistrationForm},
    })
    export default class AccountBookingInfo extends Vue {
        @Prop({default: false, type: Boolean}) disabled

        forgotPassword = false

        get person() {
            return authStore.person
        }

        get memberLevel() {
            return authStore.registeredPerson.memberLevel
        }

        set memberLevel(memberLevel) {
            authStore.SET_REGISTERED_PERSON_PROP({prop: 'memberLevel', val: memberLevel})
        }
    }
</script>

<style lang="scss" scoped>
    .v-alert {
        background-color: rgba(0, 0, 0, 0.08);
    }
</style>
