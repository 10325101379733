<template>
    <div class="text-center">
        <supplier-price v-if="!isSupplierPriceTooltip" :supplier-price="room.supplierPrice" />
        <v-tooltip v-if="searchRoomsCount === 1" top>
            <template v-slot:activator="{on}">
                <div
                    class="d-flex flex-md-column"
                    :class="{
                        'full-width': isMobile,
                        'justify-space-between': isMobile,
                        'align-start': isMobile,
                        'align-center': !isMobile,
                    }"
                >
                    <div class="d-flex flex-column" :class="{'mb-3': hdi}">
                        <span
                            class="font-weight-medium"
                            :class="{
                                'mt-2': isMobile,
                                'text-h6': hdi,
                                'font-weight-bold': hdi && isMobile,
                                'text-subtitle-2': !hdi,
                                'mb-1': !hdi,
                            }"
                        >
                            {{ btnText }}
                        </span>
                        <template v-if="hdi && isMobile">
                            <hotel-price-note
                                style="width: max-content"
                                :loyalty-points="room.loyaltyPoints"
                                :search-request="searchRequest"
                                :rph="rph"
                            />
                            <discount-label :price="room.price" class="d-inline-block mt-1" />
                            <corporate-policy-violation
                                v-if="room.corporatePolicyViolation && $breakpoint.smAndDown"
                                :policies="room.corporatePolicy"
                            />
                            <commission-amount :commission="room.price.commission" />
                            <rate-note :room="room" />
                        </template>
                    </div>
                    <div class="d-flex flex-column align-center">
                        <v-btn
                            color="primary"
                            depressed
                            max-height="36px"
                            :block="hdi && isMobile"
                            class="px-8 mb-1"
                            :class="{'mt-4': hdi && isMobile}"
                            :style="{width: hdi && isMobile ? 'calc(100vw - 248px)' : ''}"
                            @click="selectForBooking"
                            v-on="isSupplierPriceTooltip && isSupplierPriceVisible ? on : null"
                        >
                            {{ $t('book') }}
                            <corporate-policy-violation
                                v-if="room.corporatePolicyViolation && !$breakpoint.smAndDown"
                                :policies="room.corporatePolicy"
                            />
                        </v-btn>
                        <template v-if="!(hdi && isMobile)">
                            <hotel-price-note
                                style="width: max-content"
                                :loyalty-points="room.loyaltyPoints"
                                :search-request="searchRequest"
                                :rph="rph"
                            />
                            <discount-label :price="room.price" class="d-inline-block mt-1" />
                            <corporate-policy-violation
                                v-if="room.corporatePolicyViolation && $breakpoint.smAndDown"
                                :policies="room.corporatePolicy"
                            />
                            <commission-amount :commission="room.price.commission" />
                            <rate-note :room="room" />
                        </template>
                    </div>
                </div>
            </template>
            <supplier-price v-if="isSupplierPriceTooltip" class="white--text" :supplier-price="room.supplierPrice" />
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import HotelRoomOfferPriceBlock from '@/components/search/offers/HotelRoomOfferPriceBlock'

    @Component
    export default class HotelRoomOfferPriceBlockMod extends HotelRoomOfferPriceBlock {
        @Prop({default: false, type: Boolean}) hdi

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
