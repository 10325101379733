<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-lightning-bolt-outline"
        :is-first="isFirst"
        :extra-service-name="info.serviceName"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service-check-in="service.startDateTime | dateTimeFormat"
                :service-check-out="
                    service.startDateTime !== service.endDateTime
                        ? $options.filters.dateTimeFormat(service.endDateTime)
                        : null
                "
                :service="service"
                :service-image="mainImage"
                :is-package="isPackage"
                :order-end-date="orderEndDate"
                :service-label="
                    serviceDetails && `${serviceDetails.cabinCategoryName} / ${serviceDetails.cabinTypeName}`
                "
                :order="order"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="mb-4" />
                <supplier-name-label
                    v-if="isTO1 && service.supplierCompanyName"
                    :supplier-name="service.supplierCompanyName"
                />
                <div v-if="departureLocation" class="d-flex align-top">
                    <v-icon left small> mdi-city </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('from') }}
                    </span>
                    <span>
                        {{ `${departureLocation.name}, ${departureLocation.countryName}` }}
                    </span>
                </div>
                <div v-if="arrivalLocation" class="d-flex align-top">
                    <v-icon left small> mdi-city </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('to') }}
                    </span>
                    <span>
                        {{ `${arrivalLocation.name}, ${arrivalLocation.countryName}` }}
                    </span>
                </div>
                <div v-if="serviceDetails.shipName" class="d-flex align-top">
                    <v-icon left small> mdi-ship-wheel </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('cruises.ship_name') }}
                    </span>
                    <span>
                        {{ `${serviceDetails.shipName}` }}
                    </span>
                </div>
                <div v-if="serviceDetails.cruiseLine" class="d-flex align-top">
                    <v-icon left small> mdi-ferry </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('cruises.cruise_line') }}
                    </span>
                    <span>
                        {{ `${serviceDetails.cruiseLine}` }}
                    </span>
                </div>
                <div v-if="serviceDetails.cabinNumber" class="d-flex align-top">
                    <v-icon left small> mdi-ship-wheel </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('cruises.cabin_number') }}
                    </span>
                    <span>
                        {{ `${serviceDetails.cabinNumber}` }}
                    </span>
                </div>
                <div v-if="serviceDetails.deckNumber" class="d-flex align-top">
                    <v-icon left small> mdi-ship-wheel </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('cruises.deck_number') }}
                    </span>
                    <span>
                        {{ `${serviceDetails.deckNumber}` }}
                    </span>
                </div>
                <div v-if="serviceDetails.deckName" class="d-flex align-top">
                    <v-icon left small> mdi-ship-wheel </v-icon>
                    <span class="me-1 font-weight-bold">
                        {{ $t('cruises.deck_name') }}
                    </span>
                    <span>
                        {{ `${serviceDetails.deckName}` }}
                    </span>
                </div>
                <template v-slot:extraTime>
                    <div v-if="serviceDetails.duration" class="d-flex align-center">
                        <v-icon left small>mdi-timer-outline</v-icon>
                        <strong class="black--text me-1">
                            {{ `${serviceDetails.duration} ${$tc('day', serviceDetails.duration)}` }}
                        </strong>
                    </div>
                </template>
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillCruiseBookingDataModal = true">mdi-pencil</v-icon>
                    <span class="text-caption cursor-pointer primary--text" @click="fillCruiseBookingDataModal = true">
                        {{ $t('enter_details') }}
                    </span>
                    <fill-cruise-booking-data-modal
                        v-model="fillCruiseBookingDataModal"
                        :service="service"
                        :order="order"
                    />
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <cruise-info-modal />
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import CruiseInfoModal from '@/components/cruises/info/CruiseInfoModal'
    import {EventBus, SHOW_CRUISE_INFO_MODAL} from '@/utils/event-bus'
    import FillCruiseBookingDataModal from '@/components/account/modals/newOrderRequest/FillCruiseBookingDataModal'
    import {authStore} from '@/utils/store-accessor'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel.vue'

    @Component({
        components: {
            SupplierNameLabel,
            FillCruiseBookingDataModal,
            CruiseInfoModal,
            ServiceInfoBase,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceCard,
        },
    })
    export default class CruiseService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        fillCruiseBookingDataModal = false
        info = {}

        departureLocation = null
        arrivalLocation = null

        showInfo() {
            EventBus.$emit(SHOW_CRUISE_INFO_MODAL, {info: this.info})
        }

        async mounted() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            try {
                this.info = await this.$api.cruiseInfo.get({processId})

                const departureCityId = this.serviceDetails?.departureLocation?.cityId
                const departureCountryIdId = this.serviceDetails?.departureLocation?.countryId
                const arrivalCityId = this.serviceDetails?.returnLocation?.cityId
                const arrivalCountryIdId = this.serviceDetails?.returnLocation?.countryId

                const {
                    cities: [dCity],
                } = await this.$api.locations.get({
                    id: departureCityId,
                    countryId: departureCountryIdId,
                    limitCities: 1,
                })

                const {
                    cities: [aCity],
                } = await this.$api.locations.get({
                    id: arrivalCityId,
                    countryId: arrivalCountryIdId,
                    limitCities: 1,
                })

                this.departureLocation = dCity
                this.arrivalLocation = aCity

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get mainImage() {
            if (this.info.images && this.info.images.length) {
                let image = this.info.images.find(el => el.mainImage)
                if (!image) {
                    image = this.info.images[0]
                }
                return image.url
            }
            return null
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
