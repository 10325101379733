<template>
    <div v-if="order">
        <v-snackbar v-model="snackbar.show" color="error">
            {{ snackbar.message }}
        </v-snackbar>
        <v-row class="show-scroll-up-mobile">
            <v-col cols="12" md="9" class="py-0 d-sm-flex justify-space-between">
                <div class="px-4 d-flex">
                    <cancelled-services-checkbox v-if="showCancelledServicesCheckbox" class="me-4" />
                    <show-commission-and-netto-checkbox
                        v-if="
                            (isAgency || isTourOperator) &&
                            (tabSelectedName === 'info' || tabSelectedName === 'tableView')
                        "
                    />
                </div>
            </v-col>
            <v-col v-if="!isMobile" cols="12" md="3" class="py-0" />
        </v-row>
        <template
            v-if="
                tabSelectedName === 'info' ||
                tabSelectedName === 'itinerary' ||
                tabSelectedName === 'communications' ||
                tabSelectedName === 'internalNotes' ||
                tabSelectedName === 'tableView'
            "
        >
            <v-row>
                <v-col v-if="$breakpoint.smAndDown" cols="12">
                    <div class="order-summary">
                        <order-details-summary
                            :order-travelers="orderTravelers"
                            class="mb-4"
                            @send-request="$emit('modifyService')"
                        />
                    </div>
                </v-col>
                <v-col v-if="tabSelectedName !== 'communications' && tabSelectedName !== 'internalNotes'" md="9">
                    <div :class="{'order-wrapper': order.orderPackage}">
                        <v-alert
                            v-if="order.orderPackage"
                            border="bottom"
                            color="info"
                            class="white--text text-uppercase mb-0"
                            v-text="order.orderPackage.packageName"
                        />
                        <div class="px-2">
                            <div v-if="order.orderPackage">
                                <component
                                    :is="
                                        order.orderPackage.packageType === 'DYNAMIC_PACKAGE'
                                            ? 'package-info'
                                            : 'package-tour-info'
                                    "
                                    :order="order"
                                    @cancelService="showCancelServiceModal($event)"
                                    @cancel-tour="showCancelTourModal"
                                    @book-all-quote="bookAllQuote"
                                    @issueTicket="$refs.issueTicketModal.show($event)"
                                />
                            </div>
                            <template v-if="itineraryView && tabSelectedName !== 'tableView'">
                                <itinerary-info
                                    v-if="orderItinerary.description || orderItinerary.images"
                                    :info="orderItinerary"
                                />
                                <div v-for="(itineraryDay, index) in itineraryDays" :key="index">
                                    <div>
                                        <v-card :id="`day-${itineraryDay.number}`" outlined class="mb-2">
                                            <v-alert color="grey lighten-4" class="black--text mb-0">
                                                <div class="d-flex justify-space-between">
                                                    <div class="d-flex">
                                                        <strong
                                                            class="text-body-1 text-capitalize me-2 font-weight-medium"
                                                        >
                                                            <v-icon>mdi-weather-sunny</v-icon>
                                                            {{ $tc('day', 1) }} {{ itineraryDay.number }}
                                                        </strong>
                                                        <span class="text-body-1 text-capitalize">
                                                            {{ itineraryDay.date | dateFormat }}
                                                        </span>
                                                    </div>
                                                    <weather-service
                                                        v-if="
                                                            itineraryDay.services &&
                                                            itineraryDay.services.times[0].service[0].serviceDetails[0]
                                                                .cityId &&
                                                            itineraryDay.services.times[0].service[0].serviceDetails[0]
                                                                .cityName &&
                                                            itineraryDay.services.date
                                                        "
                                                        :city="{
                                                            id: itineraryDay.services.times[0].service[0]
                                                                .serviceDetails[0].cityId,
                                                            cityName:
                                                                itineraryDay.services.times[0].service[0]
                                                                    .serviceDetails[0].cityName,
                                                        }"
                                                        :start-date="itineraryDay.services.date"
                                                        :show-single-day="true"
                                                    />
                                                </div>
                                            </v-alert>
                                        </v-card>
                                        <v-row class="mb-0">
                                            <v-col
                                                cols="9"
                                                v-html="itineraryDay.description && itineraryDay.description[locale]"
                                            />
                                            <v-col cols="3">
                                                <order-itinerary-day-images
                                                    :images="itineraryDayImages(itineraryDay.number)"
                                                    @show-photo-swipe="showPhotoSwipe($event)"
                                                />
                                            </v-col>
                                        </v-row>
                                        <template v-if="itineraryDay.services">
                                            <div
                                                v-for="(time, timeIndex) in itineraryDay.services.times"
                                                :key="time + timeIndex"
                                                class="d-md-flex"
                                            >
                                                <div
                                                    class="d-flex justify-center mr-4 text-subtitle-1 font-weight-regular"
                                                >
                                                    <span class="time">
                                                        {{ time.type.text ? time.type.text() : '' }}
                                                        {{ time.type.textFlight ? time.type.textFlight() : '' }}&nbsp;
                                                        {{ time.time }}
                                                    </span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <component
                                                        :is="serviceComponent(service.serviceType)"
                                                        v-for="(service, i) in time.service"
                                                        :key="service.serviceId + i"
                                                        :service="service"
                                                        :service-tips="
                                                            tips && tips.filter(t => t.serviceId === service.serviceId)
                                                        "
                                                        :itinerary-service="time"
                                                        :is-package="!!service.serviceGroupId"
                                                        class="mb-5"
                                                        :order="order"
                                                        :order-id="order.orderId"
                                                        :object-id="order.objectId"
                                                        :data-cy="`service-${i}`"
                                                        @cancelService="showCancelServiceModal($event)"
                                                        @issueTicket="$refs.issueTicketModal.show($event)"
                                                        @modifyService="$emit('modifyService')"
                                                        @add-marker="addMarker"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <component
                                    :is="serviceComponent(service.serviceType)"
                                    v-for="(service, index) in packageServices"
                                    :key="service.serviceId"
                                    :service="service"
                                    :is-package="!!order.orderPackage"
                                    class="mb-5"
                                    :service-tips="tips && tips.filter(t => t.serviceId === service.serviceId)"
                                    :order-id="order.orderId"
                                    :object-id="order.objectId"
                                    :is-first="index === 0"
                                    :data-cy="`service-${index}`"
                                    @cancelService="showCancelServiceModal($event)"
                                    @issueTicket="$refs.issueTicketModal.show($event)"
                                    @add-marker="addMarker"
                                    @modifyService="$emit('modifyService')"
                                />
                            </template>
                        </div>
                    </div>
                    <div v-if="!itineraryView && tabSelectedName !== 'tableView'" class="pa-2">
                        <component
                            :is="serviceComponent(service.serviceType)"
                            v-for="(service, index) in nonPackageServices"
                            :key="service.serviceId"
                            :service="service"
                            :service-tips="tips && tips.filter(t => t.serviceId === service.serviceId)"
                            :is-package="!!service.serviceGroupId"
                            class="mb-5"
                            :order="order"
                            :is-first="index === 0 && !packageServices.length"
                            :data-cy="`service-${index}`"
                            @cancelService="showCancelServiceModal($event)"
                            @issueTicket="$refs.issueTicketModal.show($event)"
                            @modifyService="$emit('modifyService')"
                            @add-marker="addMarker"
                        />
                    </div>
                    <div class="full-width d-flex justify-end">
                        <v-btn
                            v-if="servicesCanBeCancelled.length > 0 && sortedServices.length > 1 && !order.orderPackage"
                            depressed
                            class="mb-5"
                            text
                            color="error"
                            @click="showCancelServicesAllModal"
                        >
                            {{ $t('cancel_reservations') }}
                        </v-btn>
                        <v-btn
                            v-if="servicesQuote.length > 0 && sortedServices.length > 1 && !order.orderPackage"
                            depressed
                            color="primary"
                            class="mb-5"
                            @click="bookAllQuote"
                        >
                            {{ $t('book') + ' ' + $t('all') }}
                        </v-btn>
                    </div>
                    <div class="full-width d-flex align-center justify-center">
                        <v-btn text :to="{name: 'orders'}" plain>
                            <v-icon color="primary" left small> mdi-arrow-left </v-icon>
                            <span> {{ $t('back_to_reservations') }} </span>
                        </v-btn>
                    </div>
                </v-col>
                <v-col v-if="!$breakpoint.smAndDown" md="3">
                    <div class="order-summary">
                        <order-details-summary
                            :order-travelers="orderTravelers"
                            class="mb-4"
                            @send-request="$emit('modifyService')"
                        />
                    </div>
                </v-col>
            </v-row>
        </template>
        <conditions-modal />
        <cancel-service-modal ref="cancelServiceModal" @cancelService="$emit('cancelService')" />
        <cancel-services-all-modal ref="cancelServicesAllModal" @cancelService="$emit('cancelService')" />
        <cancel-tour-modal ref="cancelTourModal" @cancelService="$emit('cancelService')" />
        <cancel-service-manual-modal ref="cancelServiceManualModal" />
        <invoices-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <modify-modal @modifyService="$emit('modifyService')" />
        <modify-manual-modal @modifyService="$emit('modifyService')" />
        <invoices-pay-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <issue-ticket-modal ref="issueTicketModal" @issuedTicket="$emit('issuedTicket')" />
        <service-documents-modal />
        <reservations-status-modal />
        <ticket-voiding-modal />
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderDetails from '~/components/account/OrderDetails'

    @Component
    export default class OrderDetailsMod extends OrderDetails {}
</script>
