<template>
    <v-row>
        <v-col cols="12" md="8" class="d-md-flex justify-center justify-md-space-between">
            <city-airport-autocomplete
                ref="firstLocation"
                v-model="from"
                single-line
                filled
                label="departure_location"
                :rules="[v => !!v || `${$t('departure_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
                class="flex-grow-1 location-field"
            />
            <div class="text-center swap-btn">
                <v-btn icon @click="swapLocations">
                    <v-icon color="white">
                        {{ isMobile ? 'mdi-swap-vertical-bold' : 'mdi-swap-horizontal-bold' }}
                    </v-icon>
                </v-btn>
            </div>
            <city-airport-autocomplete
                ref="secondLocation"
                v-model="to"
                single-line
                filled
                label="arrival_location"
                :rules="[v => !!v || `${$t('arrival_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
                class="flex-grow-1 location-field"
            />
        </v-col>
        <v-col cols="12" md="4">
            <v-menu
                v-model="dateTimePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on}">
                    <v-text-field
                        :value="dateTime | dateFormat"
                        :label="$t('transfers.departure_date')"
                        readonly
                        single-line
                        filled
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[v => !!v || `${$t('transfers.departure_date')} ${$t('validation.required')}`]"
                        required
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    ref="birthDatePicker"
                    v-model="dateTime"
                    :locale="locale"
                    :min="minDateTime"
                    :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
                    @change="dateTimePicker = false"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Watch} from 'nuxt-property-decorator'
    import CityAirportAutocomplete from '@/components/flights/search/forms/CityAirportAutocomplete'
    import FlightBaseForm from '@/components/flights/search/forms/FlightBaseForm'
    import {flightsRuntimeStore} from '@/store'

    @Component({
        components: {CityAirportAutocomplete},
    })
    export default class OneWay extends FlightBaseForm {
        dateTimePicker = false
        dateTime = null

        @Watch('to')
        onChangeTo(to) {
            if (to) {
                this.$parent.$emit('setDeparture', this.formIndex + 1, to)
            }
        }

        created() {
            this.$parent.$on('setDeparture', this.setDeparture)
            this.$parent.$on('newRoute', this.newRoute)
        }

        mounted() {
            this.$parent.$emit('newRoute', this.formIndex)
        }

        beforeDestroy() {
            this.$parent.$off('setDeparture', this.setDeparture)
            this.$parent.$off('newRoute', this.newRoute)
        }

        setDeparture(formIndex, departure) {
            if (this.formIndex === formIndex) {
                this.from = departure
            }
        }

        newRoute(formIndex) {
            if (this.formIndex === formIndex - 1 && this.to) {
                this.$parent.$emit('setDeparture', this.formIndex + 1, this.to)
            }
        }

        initDates() {
            if (
                !this.isEmptyRoute &&
                this.$dateFns.isAfter(
                    this.$dateFns.parseISO(flightsRuntimeStore.routes[this.formIndex].date),
                    new Date()
                )
            ) {
                this.dateTime = flightsRuntimeStore.routes[this.formIndex].date
            } else {
                this.dateTime = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
        }

        swapLocations() {
            const first = {...this.$refs.firstLocation.city}
            const second = {...this.$refs.secondLocation.city}
            this.from = Object.keys(second).length ? second : null
            this.to = Object.keys(first).length ? first : null
        }

        getRoutes() {
            return [
                {
                    departure: this.from,
                    arrival: this.to,
                    date: this.dateTime,
                },
            ]
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.locale].weekStartsOn
        }

        get isEmptyRoute() {
            return flightsRuntimeStore.routes.length < this.formIndex + 1
        }

        get locale() {
            return this.$i18n.locale
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .location-field {
            flex-basis: 100%;
        }
        .swap-btn {
            line-height: 56px;
        }
    }
</style>
