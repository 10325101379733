<template>
    <div class="icons-container">
        <v-btn
            v-for="socialLink in socialLinks"
            :key="socialLink.icon"
            color="primary"
            :href="socialLink.url"
            target="_blank"
            width="30"
            height="30"
            class="me-5"
            style="padding: 0; min-width: 0"
        >
            <v-icon color="white">
                {{ socialLink.icon }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import SocialIcons from '@/components/snippets/SocialIcons'

    @Component
    export default class SocialIconsMod extends SocialIcons {}
</script>
