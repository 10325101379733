<template>
    <v-layout :fill-height="!loaded" column class="d-flex flex-column align-center pb-8">
        <hotel-booking-page-layout
            v-if="loaded"
            ref="layout"
            :style="{width: isMobile ? '100%' : '80%'}"
            :search-request="searchRequest"
            :room-offers="roomOffers"
            :offer-keys="offerKeys"
            :offer="offer"
            :search-request-country-id="searchRequestCountryId"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar
                    ref="expiredOffersSnackbar"
                    :is-offers-expired="isOffersExpired"
                    disable-restart
                    @refresh="refreshExpiredOffers"
                />
                <hotel-refresh-offers-dialog
                    ref="refreshOffersDialog"
                    :expired-offer-keys="offerKeys"
                    @refresh="onOffersRefreshed"
                />
                <hotel-breadcrumbs class="py-2" :search-request="searchRequest" :offer="offer" />
                <refresh-offers-citizenship-snackbar :basket-item="basketItem" @refresh="refreshExpiredOffers" />
            </template>
            <template v-slot:touristsCard="{tourists, bookingKey, basketKey}">
                <template v-for="(roomTourists, roomIndex) in tourists">
                    <div :key="`tourist-${roomIndex}`" :data-cy="`room-${roomIndex}`" class="mb-5">
                        <v-card-title v-if="tourists.length > 1" class="text-capitalize">
                            <v-icon left color="primary">mdi-account</v-icon>
                            <template>
                                {{ `${$tc('room', 1)} ${roomIndex + 1}` }}
                            </template>
                        </v-card-title>
                        <template v-for="(tourist, index) in roomTourists">
                            <hotel-tourist-form
                                :key="roomIndex + '_' + index"
                                v-model="roomTourists[index]"
                                class="mb-8"
                                :room-index="roomIndex"
                                :index="index"
                                :disabled="!!bookingKey || !!basketKey"
                                :show-meal-types="ownProduct"
                                :standard-meal-name="getMealName(roomIndex)"
                                :corporate-policy-violation="roomOffers[roomIndex].corporatePolicyViolation"
                                :start-date="startDate"
                                :hotel="offer"
                                :data-cy="`tourist-${index}`"
                            />
                        </template>
                        <early-check-in-late-check-out
                            :room-index="roomIndex"
                            :disabled="!!bookingKey || !!basketKey"
                        />
                    </div>
                    <hotel-extra-services-form
                        v-if="
                            showExtraServices &&
                            productStore.extraServices[roomIndex] &&
                            productStore.extraServices[roomIndex].length
                        "
                        :key="`extra-services-${roomIndex}`"
                        :room-index="roomIndex"
                        :is-disabled="!!bookingKey || !!basketKey"
                        class="mb-5"
                        @updateExtraServices="
                            bookingKey = null
                            basketKey = null
                        "
                    />
                    <hotel-comments-form
                        :key="`comments-${roomIndex}`"
                        :index="roomIndex"
                        :disabled="!!bookingKey || !!basketKey"
                        :product-store="productStore"
                        filled
                    />
                </template>
            </template>
            <template v-slot:sidebar="{bookingKey, basketKey, selectedAddOns}">
                <hotel-order-summary
                    class="sticky-bar"
                    :offer="offer"
                    :room-offers="roomOffers"
                    :search-request="searchRequest"
                    :prebooked="!!bookingKey || !!basketKey"
                    :selected-add-ons="selectedAddOns"
                >
                    <template v-slot:summary>
                        <div class="mb-8 pa-0">
                            <div class="d-flex flex-column pa-4" style="background-color: #f5f8f8">
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <span class="body-2">
                                            <span class="font-weight-bold me-2 text-no-wrap">
                                                {{ $t('earlyCheckInTitle') }}:
                                            </span>
                                            <span>
                                                {{ dateStart }}
                                            </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <span class="body-2">
                                            <span class="font-weight-bold me-2 text-no-wrap">
                                                {{ $t('lateCheckOutTitle') }}:
                                            </span>
                                            <span>
                                                {{ dateEnd }}
                                            </span>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-divider class="my-4" />
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <div class="d-flex body-2">
                                            <span class="font-weight-bold me-2 text-capitalize">
                                                {{ $tc('night', nights) }}
                                            </span>
                                            <span>
                                                {{ nights }}
                                            </span>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <div class="d-flex body-2">
                                            <span class="font-weight-bold me-2 text-capitalize">
                                                {{ $tc('guest', touristsTotal) }}
                                            </span>
                                            <span>
                                                {{ touristsTotal }}
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </template>
                </hotel-order-summary>
            </template>
        </hotel-booking-page-layout>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelBookingPage from '@/pages/hotels/HotelBookingPage'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class HotelBookingPageMod extends HotelBookingPage {
        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get start() {
            return this.searchRequest.startDate || this.searchRequest.startDateFrom
        }

        get end() {
            return this.searchRequest.endDate || this.searchRequest.startDateTo
        }

        get dateStart() {
            return `${this.formattedDate(this.start)}`
        }

        get dateEnd() {
            return `${this.formattedDate(this.end)}`
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get touristsTotal() {
            return hotelsRuntimeStore.searchRequestTouristsTotal(this.searchRequest)
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
        color: var(--v-primary-base);
    }
</style>
