<template>
    <div v-intersect.once="onIntersect" class="mt-5 mb-5">
        <div ref="map" class="map" />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import HotelInfoMap from '~/components/info/HotelInfoMap'

    @Component
    export default class HotelInfoMapMod extends HotelInfoMap {}
</script>

<style scoped lang="scss">
    .map {
        height: 320px;
        z-index: 1;
    }
</style>
