<template>
    <v-row no-gutters>
        <v-col :cols="12" md="12">
            <div class="mb-2 d-flex align-center">
                <strong class="info-text title">
                    {{
                        service.serviceDetails[0].transferName
                            ? service.serviceDetails[0].transferName
                            : service.serviceName
                    }}
                </strong>
                <v-chip v-if="serviceType" label x-small class="ms-2">
                    {{ serviceType }}
                </v-chip>
                <hotel-stars
                    v-if="service.serviceDetails[0].category || service.serviceDetails[0].ratings"
                    :hotel="service.serviceDetails[0]"
                />
            </div>
            <v-img
                v-if="image"
                tile
                class="thumb mb-2"
                :src="image | imageUrl(800)"
                :lazy-src="'/placeholder.png' | srcHost"
                height="250"
                :eager="true"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <slot name="serviceInfo" />
        </v-col>
        <slot name="extraInfo" />
    </v-row>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'

    @Component({
        components: {HotelStars},
    })
    export default class ServiceInfoBase extends Vue {
        @Prop() service
        @Prop() serviceType
        @Prop() info
        @Prop({default: null}) image

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }
    }
</script>
