<template>
    <div v-if="travelers.length" class="travelers">
        <edit-travelers-dialog
            v-if="isB2B && isOwnProduct && service.serviceType === 'ACCOMMODATION'"
            :travelers="travelers"
            :service="service"
        />
        <div v-for="(traveler, travelerIndex) in travelersSliced" :key="traveler.personId">
            <v-divider class="my-3" />
            <v-row>
                <v-col
                    v-if="traveler.prefix || traveler.name[0].firstName || traveler.name[0].lastName"
                    cols="12"
                    md="2"
                >
                    <small class="d-block traveler-label">{{ traveler.prefix || '&nbsp;' }}</small>
                    <span class="text-uppercase black--text font-weight-bold" v-text="getTravelerName(traveler)" />
                    <div
                        v-if="service.serviceDetails[0].seats && service.serviceDetails[0].seats[travelerIndex]"
                        class="caption black--text"
                    >
                        {{
                            `${$t('flights.seat_number')}: ${service.serviceDetails[0].seats[travelerIndex].seatNumber}`
                        }}
                    </div>
                </v-col>
                <v-col v-else cols="12" md="2" class="d-flex align-center flex-column justify-center">
                    <div class="text-center text-caption text--secondary">
                        {{ $t('no_data') }}
                    </div>
                    <div
                        v-if="service.serviceDetails[0].seats && service.serviceDetails[0].seats[travelerIndex]"
                        class="caption black--text"
                    >
                        {{
                            `${$t('flights.seat_number')}: ${service.serviceDetails[0].seats[travelerIndex].seatNumber}`
                        }}
                    </div>
                </v-col>
                <slot name="mealType" :traveler="traveler" />
                <v-col v-if="traveler.citizenshipName" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('citizenship') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.citizenshipName }}
                    </span>
                </v-col>
                <v-col v-if="getAgeType(traveler)" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('type') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ getAgeType(traveler) }}
                    </span>
                </v-col>
                <v-col v-if="emptyAdditionalFields || traveler.dateOfBirth" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('birthdate') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.dateOfBirth ? $options.filters.dateShortFormat(traveler.dateOfBirth) : '-' }}
                    </span>
                </v-col>
                <v-col v-if="traveler.personalNumber" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('personal_number') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.personalNumber }}
                    </span>
                </v-col>
                <v-col v-if="emptyAdditionalFields || traveler.passports.length" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('document') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.passports.length ? traveler.passports[0].number : '-' }}
                    </span>
                </v-col>
                <v-col
                    v-if="emptyAdditionalFields || (traveler.passports.length && traveler.passports[0].expiryDate)"
                    cols="12"
                    md="2"
                >
                    <small class="d-block traveler-label">{{ $t('expire_date') }}</small>
                    <span class="black--text font-weight-bold">
                        {{
                            traveler.passports.length
                                ? $options.filters.dateShortFormat(traveler.passports[0].expiryDate)
                                : '-'
                        }}
                    </span>
                </v-col>
                <v-col v-if="traveler.knownTravelerNumber" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('known_traveller_number') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.knownTravelerNumber }}
                    </span>
                </v-col>
                <v-col v-if="traveler.bonusCards && traveler.bonusCards.length" cols="12" md="2">
                    <small class="d-block traveler-label">{{ $t('bonus_card') }}</small>
                    <span class="black--text font-weight-bold">
                        {{ traveler.bonusCards.map(({number}) => number).join(', ') }}
                    </span>
                </v-col>
                <v-col
                    v-if="
                        traveler.preferences &&
                        (traveler.preferences.mealTypeName || traveler.preferences.seatAllocationName)
                    "
                    cols="12"
                    md="2"
                >
                    <small class="d-block traveler-label">{{ $t('preferences') }}</small>
                    <div v-if="traveler.preferences.mealTypeName" class="black--text font-weight-bold">
                        {{ traveler.preferences.mealTypeName }}
                    </div>
                    <div v-if="traveler.preferences.seatAllocationName">
                        {{ $t('flights.seat_allocation') }}:
                        <span class="black--text font-weight-bold">{{ traveler.preferences.seatAllocationName }}</span>
                    </div>
                </v-col>
                <v-col v-if="flightSegments(travelerIndex).length" cols="12">
                    <div v-for="(item, index) in flightSegments(travelerIndex)" :key="index" class="mb-3">
                        <div class="black--text font-weight-bold">
                            {{ item.segment.flightSegmentName }}, {{ item.segment.aircraftName }}
                        </div>
                        <div v-if="item.seat">
                            <small class="text-capitalize">{{ $tc('flights.seat', 1) }}</small>
                            <strong class="black--text">{{ item.seat.seatNumber }}{{ item.seat.rowNumber }}</strong>
                            <span :class="flightServiceStatusColor(item.seat.bookingStatus)">
                                {{ item.seat.bookingStatus }}
                            </span>
                        </div>
                        <div v-if="item.baggage.length">
                            <small>{{ $t('flights.baggage') }}</small>
                            <span
                                v-for="(service, serviceIndex) in item.baggage"
                                :key="serviceIndex"
                                class="black--text mr-1"
                            >
                                <strong>
                                    {{ service.name }} {{ service.quantity }}
                                    {{ $tc('flights.baggage_item', service.quantity) }}
                                </strong>
                                <span :class="flightServiceStatusColor(service.status)">{{ service.status }}</span>
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col v-if="traveler.onExtraBed || traveler.onWithoutPlace" cols="12" md="2">
                    <div v-if="traveler.onExtraBed" class="font-weight-bold grey--text">
                        <v-icon color="info" left>mdi-bed-outline</v-icon>
                        {{ $t('extra_bed') }}
                    </div>
                    <div v-if="traveler.onWithoutPlace" class="font-weight-bold grey--text">
                        <v-icon color="warning" left>mdi-bed-empty</v-icon>
                        {{ $t('without_bed') }}
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-btn
            v-if="travelers.length > travelersToShowNumber"
            block
            small
            depressed
            class="my-2"
            @click="isTravelersSliced = !isTravelersSliced"
        >
            <span class="primary--text" v-text="`${isTravelersSliced ? $t('show') : $t('hide')} ${$t('all')}`" />
        </v-btn>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import TouristPassportForm from '~src/components/booking/forms/touristPassportForm.src'
    import EditTravelersDialog from '@/components/account/orderServices/EditTravelersDialog'
    import {authStore, runtimeStore} from '@/utils/store-accessor'

    @Component({
        components: {EditTravelersDialog, TouristPassportForm},
    })
    export default class Travelers extends Vue {
        @Prop({required: true}) travelers
        @Prop() product
        @Prop() orderEndDate
        @Prop({required: true}) service
        @Prop({default: false, type: Boolean}) emptyAdditionalFields
        @Prop({default: () => []}) details

        isTravelersSliced = true
        travelersToShowNumber = 4

        getTravelerName(traveler) {
            return `${traveler.name[0].firstName || ''} ${traveler.name[0].lastName || ''}`
        }

        getAgeType() {
            return null
        }

        getAge(dateOfBirth) {
            return this.$dateFns.differenceInYears(new Date(this.orderEndDate), this.$dateFns.parseISO(dateOfBirth))
        }

        flightSegments(travelerIndex) {
            if (this.product !== 'FLIGHT' || !this.details || !this.details[0]) return []
            const flightBaggage = this.details[0].flightExtraServices.filter(
                flightExtraService =>
                    flightExtraService.type === 'BAGGAGE' && flightExtraService.travelerRPH - 1 === travelerIndex
            )
            return this.details[0].itineraries.reduce((segments, itinerary, itineraryIndex) => {
                itinerary.flightSegments.forEach((segment, segmentIndex) => {
                    const seat = segment.seats
                        ? segment.seats.find(seat => seat.travelerRPH - 1 === travelerIndex)
                        : null
                    const baggage = flightBaggage.filter(flightBaggage =>
                        flightBaggage.segmentRphs.includes(`${itineraryIndex + 1}${segmentIndex + 1}`)
                    )
                    if (seat || baggage.length) {
                        segments.push({
                            segment,
                            seat,
                            baggage,
                        })
                    }
                })
                return segments
            }, [])
        }

        flightServiceStatusColor(status) {
            if (status === 'Rejected' || status === 'Cancelled') {
                return 'error--text'
            } else {
                return 'success--text'
            }
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get travelersSliced() {
            return this.isTravelersSliced ? this.travelers.slice(0, this.travelersToShowNumber) : this.travelers
        }
    }
</script>

<style lang="scss" scoped>
    .travelers {
        position: relative;
    }

    .traveler-label,
    .title {
        line-height: 1;
    }
</style>
