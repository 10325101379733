<template>
    <div class="py-2 d-flex" style="width: calc(100vw - 56px)">
        <div class="d-flex flex-column full-width overflow-hidden">
            <span class="mb-2" :class="`${service.statusColor}--text`">{{ service.status }}</span>
            <span class="d-flex">
                <v-icon small class="mr-1 d-inline-block" style="margin-top: 1px">{{ serviceTypeIcon }}</v-icon>
                <span class="text-subtitle-2 font-weight-medium" v-html="service.pureTitle" />
            </span>
            <div v-html="service.title" />
            <div class="text--secondary" v-html="service.location" />
            <div class="text-no-wrap" v-html="service.date" />
            <span class="mt-2" v-html="service.travellers" />
        </div>
        <div class="d-flex flex-column align-end full-width overflow-hidden">
            <orders-price-label v-if="!service.isEmptyOrder" class="d-flex flex-column align-end" :item="service" />
            <v-btn
                v-if="!service.isEmptyOrder && isVoucherAvailable"
                small
                elevation="0"
                class="cursor-pointer mt-auto mb-1"
                @click="openVoucherDialog"
            >
                {{ $t('voucher') }}
            </v-btn>
            <v-btn
                v-if="!$config.account.hideAddServiceBtn && service.isEmptyOrder"
                :color="'primary'"
                small
                @click="showAddServiceModal"
                v-text="$t('add_service')"
            />
        </div>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import OrdersPriceLabel from '@/components/account/snippets/OrdersPriceLabel'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component({
        components: {OrdersPriceLabel},
    })
    export default class OrdersServiceMobile extends Vue {
        @Prop() service

        @Emit()
        openVoucherDialog() {
            return this.service
        }

        @Emit()
        showAddServiceModal() {
            return this.service.orderId
        }

        get serviceTypeIcon() {
            return getServiceTypeIcon(this.service.serviceType)
        }

        get isVoucherAvailable() {
            return runtimeStore.isServiceVoucherAvailable(this.service)
        }
    }
</script>
