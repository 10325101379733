var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[(_vm.loaded)?_c('cruise-booking-page-layout',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer,"product":_vm.product,"product-store":_vm.productStore},scopedSlots:_vm._u([{key:"tourists",fn:function(ref){
var tourists = ref.tourists;
var index = ref.index;
var disabled = ref.disabled;
var personSelected = ref.personSelected;
return [_c('tourist-form',{attrs:{"disabled":disabled,"index":index,"offer":_vm.offer,"hide-index":tourists.length === 1,"selected-tourists":tourists,"start-date":_vm.startDate,"data-cy":("tourist-" + index)},on:{"person-selected":personSelected},model:{value:(tourists[index]),callback:function ($$v) {_vm.$set(tourists, index, $$v)},expression:"tourists[index]"}}),_vm._v(" "),_c('tourist-passport-form',{attrs:{"index":index,"disabled":disabled,"corporate-policy-violation":_vm.offer.corporatePolicyViolation,"expanded-all":""}})]}},{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('cruises-breadcrumbs',{attrs:{"search-request":_vm.searchRequest,"product":_vm.product}})]},proxy:true},{key:"orderCommentsForm",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('booking-comments-form',{attrs:{"disabled":!!bookingKey,"product-store":_vm.productStore}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
var selectedAddOns = ref.selectedAddOns;
return [_c('cruises-search-summary',{attrs:{"search-disabled":"","search-request":_vm.searchRequest,"dates":_vm.dates}}),_vm._v(" "),_c('cruise-order-summary',{attrs:{"offer":_vm.offer,"info":_vm.basketItem.info,"prebooked":!!bookingKey,"selected-add-ons":selectedAddOns}}),_vm._v(" "),_c('cruise-offer-info-modal')]}}],null,false,3755244991)}):_vm._e(),_vm._v(" "),_c('conditions-modal',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('cruise-conditions-modal-info')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }