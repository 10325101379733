<script>
    import {Component} from 'vue-property-decorator'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm'
    import {transfersStore} from '@/utils/store-accessor'

    @Component
    export default class TransferReturnExtraServicesForm extends ExtraServicesForm {
        setExtraService(data) {
            const type = `extra-service-${data.service.serviceRPH}`
            if (!data.operation) {
                transfersStore.DELETE_EXTRA_SERVICE_RETURN_TRANSFER(type)
            } else {
                transfersStore.SET_BOOKING_EXTRA_SERVICE_RETURN_TRANSFER({
                    type,
                    name: data.service.name,
                    serviceRPH: data.service.serviceRPH,
                    quantity: parseInt(data.qty),
                    price: data.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT'),
                    mandatory: data.service.mandatory,
                })
            }
            this.$emit('updateExtraServices')
        }

        get extraServices() {
            if (!transfersStore.bookingAdditionalOptionsReturnTransfer.extraServices) {
                return []
            }
            return (
                transfersStore.bookingAdditionalOptionsReturnTransfer.extraServices.filter(
                    extraService => !extraService.mandatory
                ) || []
            )
        }

        get mandatoryExtraServices() {
            if (!transfersStore.bookingAdditionalOptionsReturnTransfer.extraServices) {
                return []
            }
            return (
                transfersStore.bookingAdditionalOptionsReturnTransfer.extraServices.filter(
                    extraService => extraService.mandatory
                ) || []
            )
        }
    }
</script>
