<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMap from '@/components/search/offers/HotelMap'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class HotelMapMod extends HotelMap {
        @Prop({default: null}) height

        setMapYPosition() {
            this.$el.style.top = '0px'
            this.$el.style.height = this.height
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: !this.isMobile,
                mapTypeControlOptions: {
                    style: this.google.maps.MapTypeControlStyle.DEFAULT,
                    position: this.google.maps.ControlPosition.LEFT_BOTTOM,
                },
                zoomControl: !this.isMobile,
                fullscreenControl: !this.isMobile,
                zoom: 8,
                restriction: {
                    latLngBounds: {north: 80, south: -70, west: -180, east: 180},
                    strictBounds: true,
                },
            }
        }
    }
</script>
