<template>
    <div>
        <div
            v-for="offer in offers"
            :key="offer.hotelCode"
            class="d-flex align-start full-width"
            :class="{'cursor-pointer': compareMode}"
        >
            <v-checkbox
                v-if="compareMode"
                class="mx-2"
                :value="offersToCompareKeys.indexOf(offer.hotelCode) !== -1"
                style="margin-top: 118px"
                @click="selectToCompare(offer)"
            />
            <component
                :is="!$breakpoint.smAndDown ? 'hotel-offer-card' : 'hotel-offer-card-mobile'"
                :offer="offer"
                :compare-mode="compareMode"
                :search-request="searchRequest"
                :class="compareMode && offersToCompareKeys.indexOf(offer.hotelCode) !== -1 ? 'compare-selected' : ''"
                class="my-2 full-width"
                data-cy="hotel-offer"
            />
        </div>
        <div v-if="isEmptyResults" class="text-center title grey--text text--lighten-1 py-10">
            {{ $t('no_available_offers') }}
        </div>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOffers from '@/components/search/offers/HotelOffers'
    import {Prop} from 'vue-property-decorator'
    import {hotelsStore} from '@/utils/store-accessor'

    @Component
    export default class HotelOffersMod extends HotelOffers {
        @Prop() compareMode

        selectToCompare(offer) {
            if (this.compareMode) {
                hotelsStore.SELECT_TO_COMPARE(offer.hotelCode)
            }
        }
    }
</script>
