<template>
    <div>
        <v-sheet v-if="showDates" class="mb-6 pa-2 cursor-pointer" color="#F5F8F8" rounded>
            <daterange-m
                :start-date.sync="searchRequestCopy.startDate"
                :end-date.sync="searchRequestCopy.endDate"
                dense
            />
        </v-sheet>
        <div v-if="showTourists">
            <span class="font-weight-medium subtitle-1 mb-2 black--text">
                {{ $t('room_for') }}
            </span>
            <br />
            <span class="subtitle-1">
                {{ `${touristsTotal} ${$tc(touristsLabel, touristsTotal)}` }}
            </span>
            <span class="subtitle-1">({{ nights }} {{ $tc('night', nights) }})</span>
        </div>
        <template v-if="rooms">
            <div v-for="(room, i) in rooms" :key="i" class="d-flex flex-column">
                <v-divider class="my-4" />
                <span class="font-weight-medium subtitle-1 mb-2 black--text">
                    {{ `${$t('guests')}` }}
                    <span v-if="rooms.length > 1" class="font-weight-light">
                        {{ `( ${$tc('room', 1)} ${i + 1} ) ` }}
                    </span>
                </span>
                <div class="full-width d-flex align-center my-2">
                    <span class="subtitle-1">
                        {{ $t('adults') }}
                    </span>
                    <v-spacer />
                    <div class="d-flex align-center">
                        <v-btn
                            tile
                            dark
                            x-small
                            icon
                            style="background-color: #62b7e5"
                            elevation="0"
                            @click="decreaseAdults(i)"
                        >
                            <v-icon x-small dark> mdi-minus </v-icon>
                        </v-btn>
                        <v-sheet class="py-1 px-2 mx-2" color="#F5F8F8" rounded>
                            <span> {{ room.adults }} </span>
                        </v-sheet>
                        <v-btn
                            tile
                            dark
                            x-small
                            icon
                            style="background-color: #62b7e5"
                            elevation="0"
                            @click="increaseAdults(i)"
                        >
                            <v-icon x-small dark> mdi-plus </v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="full-width d-flex align-center my-2">
                    <span class="subtitle-1">
                        {{ $t('children') }}
                    </span>
                    <v-spacer />
                    <div class="d-flex align-center">
                        <v-btn
                            tile
                            dark
                            x-small
                            icon
                            style="background-color: #62b7e5"
                            elevation="0"
                            @click="decreaseChildren(i)"
                        >
                            <v-icon x-small dark> mdi-minus </v-icon>
                        </v-btn>
                        <v-sheet class="py-1 px-2 mx-2" color="#F5F8F8" rounded>
                            <span> {{ room.childrenAges.length }} </span>
                        </v-sheet>
                        <v-btn
                            tile
                            dark
                            x-small
                            icon
                            style="background-color: #62b7e5"
                            elevation="0"
                            @click="increaseChildren(i)"
                        >
                            <v-icon x-small dark>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div v-if="rooms[i].childrenAges.length" class="d-flex justify-space-between">
                    <span class="subtitle-1">
                        {{ $t('children_ages') }}
                    </span>
                    <div style="width: 110px" class="align-self-end d-flex flex-wrap justify-space-between">
                        <div
                            v-for="(age, index) in rooms[i].childrenAges"
                            :key="`room-${i}-childrenAges-${index}`"
                            style="width: 50px"
                            class="mb-2"
                        >
                            <v-select
                                :value="age"
                                :items="childrenAgeItems"
                                height="30"
                                dense
                                hide-details
                                data-cy="children-age-selector"
                                @change="refreshDisabled = false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <v-btn elevation="0" color="primary" class="mt-4" block :disabled="refreshDisabled" @click="onRefreshClick">
                {{ $t('refresh') }}
            </v-btn>
        </template>
    </div>
</template>

<script>
    import {Component, Watch} from 'nuxt-property-decorator'
    import SearchSummaryContent from '~/components/parts/SearchSummaryContent'
    import {hotelsStore, hotelsRuntimeStore} from '@/utils/store-accessor'
    import DaterangeM from '@/components/search/forms/daterangeM.src'

    @Component({
        components: {DaterangeM},
    })
    export default class SearchSummaryContentMod extends SearchSummaryContent {
        searchRequestCopy = {}
        refreshDisabled = true
        rooms = [{adults: 1, childrenAges: []}]

        @Watch('searchRequestCopy.startDate')
        onStartDateChange() {
            if (this.searchRequest.startDate !== this.searchRequestCopy.startDate) {
                this.refreshDisabled = false
            }
        }

        @Watch('searchRequestCopy.endDate')
        onEndDateChange() {
            if (this.searchRequest.endDate !== this.searchRequestCopy.endDate) {
                this.refreshDisabled = false
            }
        }

        @Watch('searchRequest', {deep: true, immediate: true})
        onSearchRequestChange() {
            if (this.searchRequest) {
                this.searchRequestCopy = {...this.searchRequest, rooms: [...this.searchRequest.rooms]}
            }
        }

        async mounted() {
            await this.$store.restored
            this.rooms = hotelsStore.searchRequestTourists
        }

        // getRoomAdults(index) {
        //     return this.touristsJSON[index]?.adults || 0
        // }
        //
        // getRoomChildren(index) {
        //     return this.touristsJSON[index]?.childrenAges || []
        // }

        increaseAdults(index) {
            // const adults = this.getRoomAdults(index)
            // const childrenAges = this.getRoomChildren(index)
            // const roomsUpdated = [...this.searchRequestCopy.rooms]
            // roomsUpdated[index] =
            //     `adults:${adults + 1}` + (childrenAges.length ? `,childrenAges: ${childrenAges.join()}` : '')
            // this.searchRequestCopy = {...this.searchRequestCopy, rooms: roomsUpdated}
            this.rooms[index].adults++
            this.searchRequestCopy.rooms = this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
            this.refreshDisabled = false
        }

        decreaseAdults(index) {
            // const adults = this.getRoomAdults(index)
            // if (adults > 1) {
            //     const childrenAges = this.getRoomChildren(index)
            //     const roomsUpdated = [...this.searchRequestCopy.rooms]
            //     roomsUpdated[index] =
            //         `adults:${adults - 1}` + (childrenAges.length ? `,childrenAges: ${childrenAges.join()}` : '')
            //     this.searchRequestCopy = {...this.searchRequestCopy, rooms: roomsUpdated}
            //
            //     this.refreshDisabled = false
            // }
            if (this.rooms[index].adults > 1) {
                this.rooms[index].adults--
                this.searchRequestCopy.rooms = this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
                this.refreshDisabled = false
            }
        }

        increaseChildren(index) {
            // const adults = this.getRoomAdults(index)
            // const childrenAges = this.getRoomChildren(index)
            // childrenAges.push(2)
            // const roomsUpdated = [...this.searchRequestCopy.rooms]
            // roomsUpdated[index] = `adults:${adults},childrenAges:${childrenAges.join()}`
            // this.searchRequestCopy = {...this.searchRequestCopy, rooms: roomsUpdated}
            this.rooms[index].childrenAges.push(2)
            this.searchRequestCopy.rooms = this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
            this.refreshDisabled = false
        }

        decreaseChildren(index) {
            // const adults = this.getRoomAdults(index)
            // const childrenAges = this.getRoomChildren(index)
            // if (childrenAges.length > 0) {
            //     childrenAges.pop()
            //     const roomsUpdated = [...this.searchRequestCopy.rooms]
            //     roomsUpdated[index] =
            //         `adults:${adults}` + (childrenAges.length ? `,childrenAges:${childrenAges.join()}` : '')
            //     this.searchRequestCopy = {...this.searchRequestCopy, rooms: roomsUpdated}
            //
            //     this.refreshDisabled = false
            // }
            if (this.rooms[index].childrenAges?.length) {
                const length = this.rooms[index].childrenAges.length
                this.rooms[index].childrenAges.splice(length - 1, 1)
                this.searchRequestCopy.rooms = this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room))
                this.refreshDisabled = false
            }
        }

        onRefreshClick() {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    rooms: this.searchRequestCopy.rooms,
                    startDate: this.searchRequestCopy.startDate,
                    endDate: this.searchRequestCopy.endDate,
                },
            })

            this.$emit('refresh')
        }

        // get touristsJSON() {
        //     return this.searchRequestCopy.rooms
        //         ? hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequestCopy)
        //         : []
        // }

        get childrenAgeItems() {
            return Array.from(Array(18).keys())
        }
    }
</script>
