<template>
    <div v-if="services.length" class="d-block my-4 px-0">
        <div class="font-weight-bold mb-2">
            {{ $t(label) }}
        </div>
        <div v-for="service in services" :key="service.type" class="py-0 d-flex caption">
            <span>{{ service.name }}</span>
            <span v-if="service.quantity > 1">&nbsp;* {{ service.quantity }} </span>
            <v-spacer />
            <span class="font-weight-bold">
                {{
                    {
                        amount:
                            (service.price.currency ? service.price.amount : service.price.originalAmount) *
                            service.quantity,
                        currency: service.price.currency || service.price.originalCurrency,
                    } | price
                }}
            </span>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class ExtraServicesSummary extends Vue {
        @Prop({required: true}) services
        @Prop({default: 'extra_services', type: String}) label
    }
</script>
