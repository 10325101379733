<template>
    <div>
        <selected-filters
            :selected="filterValues"
            :translatable="['cancellationPolicy']"
            @delete-filter="clearFilter"
        />
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            label="Model name"
            filter="name"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.cancellationPolicy && filters.cancellationPolicy.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            filter="cancellationPolicy"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.pickUpPoints && filters.pickUpPoints.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="pickUpPoints"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.carRentCompanyName && filters.carRentCompanyName.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="carRentCompanyName"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.vehicleCategory && filters.vehicleCategory.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="vehicleCategory"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.vehicleClass && filters.vehicleClass.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="vehicleClass"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.transmissionType && filters.transmissionType.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            :translate-labels="true"
            filter="transmissionType"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.fuelType && filters.fuelType.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            :translate-labels="true"
            filter="fuelType"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.features && filters.features.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            :translate-labels="true"
            filter="features"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.doorCount && filters.doorCount.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="doorCount"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.passengerQuantity && filters.passengerQuantity.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="passengerQuantity"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.baggageQuantity && filters.baggageQuantity.length > 1"
            v-model="filterValues"
            :filters="filters"
            :panel-expanded="false"
            filter="baggageQuantity"
            @change="change($event)"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {filters} from '@/utils/cars/cars-rent-blank-state'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import NameFilter from '~src/components/filters/nameFilter.src'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import FiltersBase from '@/components/search/FiltersBase'
    import SelectedFilters from '~src/components/snippets/selectedFilters.src'

    @Component({
        components: {
            PriceFilter,
            NameFilter,
            CheckboxFilter,
            SelectedFilters,
        },
    })
    export default class CarsFilters extends FiltersBase {
        @Prop({required: true, default: () => filters()}) filterValues
    }
</script>
