<template>
    <div>
        <div v-if="!editable" class="text--secondary">
            {{ entry.duration }} {{ $tc('night', entry.duration) }}
            <v-icon color="primary" small @click="editable = true">mdi-pencil</v-icon>
        </div>
        <v-tooltip v-else :key="key" top>
            <template v-slot:activator="{on}">
                <v-select
                    :value="duration"
                    :items="items"
                    single-line
                    hide-details
                    outlined
                    dense
                    :disabled="loading"
                    :loading="loading"
                    class="pe-5"
                    @change="change"
                    v-on="on"
                >
                    <template v-slot:selection="{item}">
                        {{ item }}
                        &nbsp;
                        <span>
                            {{ $tc('night', item) }}
                        </span>
                    </template>
                </v-select>
            </template>
            {{ $t('tours.notification.related_offers_was_changed') }}
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {getSupplierIdByCode} from '@/store/modules/hotels/hotelsRuntime'
    import {hotelsRuntimeStore, toursRuntimeStore} from '@/utils/store-accessor'
    import {getMethod} from '~/api'
    import {salesTermsRsTransformer} from '@/utils/api-helpers'

    @Component
    export default class HotelServiceDurationSelect extends Vue {
        @Prop({required: true}) entry
        @Prop({required: true}) offer
        @Prop({required: true}) searchRequest
        @Prop() citizenshipId

        loading = false
        key = 0
        editable = false

        async change(duration) {
            try {
                this.loading = true
                const diff = duration - this.entry.duration
                const {convertToCurrency, rooms} = this.searchRequest
                let adults = 0,
                    childrenAges = []
                for (const room of hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)) {
                    adults += room.adults
                    childrenAges.push(...room.childrenAges)
                }
                const startDate = this.entry.entryDate.split(' ')[0],
                    prevEndDateObj = this.$dateFns.addDays(this.$dateFns.parseISO(startDate), this.entry.duration),
                    endDateObj = this.$dateFns.addDays(this.$dateFns.parseISO(startDate), duration)

                const newHotels = await Promise.all(
                    this.entry.offers.map(async ({supplierCode, cityCode, hotelCode}) => {
                        const supplierId = await getSupplierIdByCode(supplierCode)
                        const {
                            offers: [hotel],
                        } = await this.$api.searchAccommodation.get({
                            cityCode,
                            hotelCode,
                            supplierId,
                            convertToCurrency,
                            rooms,
                            startDate,
                            endDate: this.$dateFns.format(endDateObj),
                        })
                        hotel.rooms.forEach(room => {
                            room.notDeltaPrice = room.price
                            delete room.price
                        })
                        return hotel
                    })
                )
                const oldHotel = hotelsRuntimeStore.findHotelByOfferKey(this.entry.offers, this.entry.selectedOfferKey)
                const oldRoom = hotelsRuntimeStore.findRoomOffer(oldHotel.rooms, this.entry.selectedOfferKey)
                const newHotel = hotelsRuntimeStore.findSameHotel(newHotels, oldHotel)
                const newRoom = hotelsRuntimeStore.findSameRoomOffer(newHotel.rooms, oldRoom)
                const selectedOfferKey = newRoom.groupedOffers[0].offerKey
                const zeroDeltaPriceOldRoom = oldHotel.rooms.find(({deltaPrice: {amount}}) => amount === 0)
                const zeroDeltaPriceNewRoom = hotelsRuntimeStore.findSameRoomOffer(
                    newHotel.rooms,
                    zeroDeltaPriceOldRoom
                )
                newHotels.forEach(({rooms}) => {
                    rooms.forEach(room => {
                        const {amount, currency} = room.notDeltaPrice
                        room.deltaPrice = {amount: amount - zeroDeltaPriceNewRoom.notDeltaPrice.amount, currency}
                    })
                })
                const updatedEntries = [{...this.entry, selectedOfferKey, offers: newHotels, duration}]

                for (const entry of this.offer.entries.filter(({type}) => ['FLIGHT', 'OWNCHARTER'].includes(type))) {
                    let needUpdate = false
                    const {itinerary} = entry.offers[0]
                    const routes = itinerary.map(({segments}) => {
                        const first = segments[0],
                            last = segments[segments.length - 1]
                        let departureDate = first.departure.date.split(' ')[0]
                        const departureDateObj = this.$dateFns.parseISO(departureDate)
                        if (this.$dateFns.isEqual(departureDateObj, prevEndDateObj)) {
                            needUpdate = true
                            departureDate = this.$dateFns.format(this.$dateFns.addDays(departureDateObj, diff))
                        }
                        return `${first.departure.airportCode}-${last.arrival.airportCode},${departureDate}`
                    })
                    if (needUpdate) {
                        const {
                            offers: [flight],
                        } = await this.$api.searchFlight.get({
                            convertToCurrency,
                            routes,
                            adults,
                            childrenAges,
                            citizenshipId: this.citizenshipId,
                        })
                        const selectedOfferKey = flight.offerKey
                        updatedEntries.push({...entry, selectedOfferKey, offers: [flight]})
                    }
                }

                for (const entry of this.offer.entries.filter(({type}) => type === 'TRANSFER')) {
                    const offer = entry.offers[0]
                    const startDateObj = this.$dateFns.addDays(
                        this.$dateFns.parseISO(this.offer.info.packageDate.split(' ')[0]),
                        entry.dayNumber - 1
                    )
                    if (this.$dateFns.isEqual(startDateObj, prevEndDateObj)) {
                        const dateTime = this.$dateFns.format(this.$dateFns.addDays(startDateObj, diff))
                        const place = ({type, code}) =>
                            type === 'AIRPORT' ? code : type === 'STATION' ? code : type === 'HOTEL' ? 'hotel' : 'hotel'
                        const {product} = await getMethod('/api/searchTransfer', {
                            convertToCurrency,
                            departurePlace: place(offer.departureInfo),
                            departureCityId: offer.departureInfo.cityId,
                            departureCountryId: offer.departureInfo.countryId,
                            arrivalPlace: place(offer.arrivalInfo),
                            arrivalCityId: offer.arrivalInfo.cityId,
                            arrivalCountryId: offer.arrivalInfo.countryId,
                            adults,
                            childrenAges,
                            dateTime,
                        })
                        let transfer
                        product.find(({offers}) => {
                            transfer = offers.find(({info}) => info.name === offer.info.name)
                            return transfer
                        })
                        if (transfer) {
                            const {price} = salesTermsRsTransformer(transfer.salesTerms)
                            //transfer.deltaPrice = deltaPrice
                            transfer.notDeltaPrice = price
                            delete transfer.salesTerms

                            const selectedOfferKey = transfer.offerKey
                            updatedEntries.push({
                                ...entry,
                                selectedOfferKey,
                                offers: [transfer],
                                dayNumber: entry.dayNumber + diff,
                            })
                        }
                    }
                }

                toursRuntimeStore.UPDATE_ENTRIES(updatedEntries)
            } catch (e) {
                this.key++
                console.error(e)
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            } finally {
                this.loading = false
            }
        }

        get duration() {
            return this.entry.duration
        }

        get items() {
            const arr = Array.from(Array(31).keys())
            arr.shift()
            return arr
        }
    }
</script>
