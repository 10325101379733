<template>
    <div ref="touristForm" class="d-flex flex-column">
        <slot name="label">
            <div v-if="!hideIndex" class="text-subtitle-1 text-capitalize font-weight-bold">
                {{ `${$tc(label, 1)} ${index + 1} ${isChild ? `(${$tc('child')})` : ''}` }}
            </div>
        </slot>
        <v-row v-if="showTouristSelector" class="mb-1">
            <v-col cols="12" md="6">
                <tourist-selector
                    :tourist="tourist"
                    :selected-tourists="selectedTourists"
                    :required="corporatePolicyViolation"
                    :disabled="disabled"
                    @selected="personSelected"
                />
            </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" data-cy="booking-form" :disabled="isDisabled">
            <v-row
                v-if="
                    !showTouristSelector || !corporatePolicyViolation || (corporatePolicyViolation && tourist.personId)
                "
            >
                <v-col v-if="!isFieldDisabled('PREFIX')" cols="12" md="2">
                    <v-select
                        v-model="tourist.prefix"
                        :items="['Mr', 'Ms', 'Mrs']"
                        :label="fieldLabel('salutation', 'PREFIX')"
                        :rules="[requiredRule('salutation', 'PREFIX')]"
                        persistent-placeholder
                        data-cy="tourist-field-prefix"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <v-col :cols="nameFieldCols.cols" :md="nameFieldCols.md" :lg="nameFieldCols.lg">
                    <v-text-field
                        v-model="tourist.firstName"
                        :label="fieldLabel('first_name')"
                        :rules="validationRules('first_name', [latinRule, requiredRule])"
                        :disabled="disablePrivateClientName"
                        data-cy="tourist-field-first-name"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <v-col cols="12" md="4" lg="2">
                    <v-text-field
                        v-model="tourist.lastName"
                        :label="fieldLabel('last_name')"
                        :rules="validationRules('last_name', [latinRule, requiredRule])"
                        :disabled="disablePrivateClientName"
                        data-cy="tourist-field-last-name"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <v-col v-if="!isFieldDisabled('BIRTHDATE')" cols="12" md="4" lg="2">
                    <date-picker
                        v-model="tourist.birthdate"
                        :label="fieldLabel('birthdate', 'BIRTHDATE')"
                        :rules="[requiredRule('birthdate', 'BIRTHDATE')]"
                        :min="minBirthDate"
                        :max="maxBirthDate"
                        year-active-picker
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <v-col v-if="!disableCitizenship && !isFieldDisabled('CITIZENSHIP')" cols="12" md="4" lg="2">
                    <v-autocomplete
                        v-model="tourist.citizenshipId"
                        :label="fieldLabel('citizenship', 'CITIZENSHIP')"
                        :items="countries"
                        item-text="name"
                        item-value="id"
                        :rules="[requiredRule('citizenship', 'CITIZENSHIP')]"
                        data-cy="tourist-field-citizenship"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <slot name="beforeContacts" />
                <v-col v-if="!isFieldDisabled('EMAIL')" cols="12" md="4" lg="2">
                    <v-text-field
                        v-model="tourist.email"
                        :label="fieldLabel('email', 'EMAIL')"
                        type="email"
                        :rules="emailRules"
                        data-cy="tourist-field-email"
                        :validate-on-blur="validateOnSubmit"
                        :disabled="hideContactData"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <v-col v-if="!isFieldDisabled('PHONE')" cols="12" md="4" lg="2">
                    <phone-field
                        v-model="tourist.phone"
                        :disabled="hideContactData"
                        :label="$t('phone')"
                        data-cy="tourist-field-phone"
                        :required="isFieldMandatory('PHONE')"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <slot :tourist="tourist" :required-rule="requiredRule" :field-label="fieldLabel" />
            </v-row>
        </v-form>
    </div>
</template>

<script>
    import MealTypeSelector from '~/components/booking/forms/fields/MealTypeSelector'
    import {Component, Emit, mixins, Prop, PropSync, VModel, Watch} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TouristSelector from '@/components/parts/Booking/TouristSelector'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import PhoneField from '~src/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {
            PhoneField,
            TouristSelector,
            MealTypeSelector,
            DatePicker,
        },
    })
    export default class TouristForm extends mixins(FormValidationRulesMixin, BookingFieldsMixin) {
        @VModel() tourist
        @Prop({default: 'guest'}) label
        @Prop({required: true}) index
        @Prop() disabled
        @PropSync('_valid', {default: false}) valid
        @Prop() corporatePolicyViolation
        @Prop({default: false, type: Boolean}) hideIndex
        @Prop({default: false, type: Boolean}) disablePrivateClientName
        @Prop({default: false, type: Boolean}) hideContactData
        @Prop({default: false, type: Boolean}) disableCitizenship
        @Prop({default: () => []}) selectedTourists
        @Prop() startDate
        @Prop() countryId

        @Watch('locale')
        onLocaleChange() {
            this.$refs.form.validate()
        }

        @Emit()
        personSelected(person) {
            const index = this.index

            if (!person && authStore.isCorporate) {
                return {person: {}, index}
            }

            return {person, index}
        }

        get validateOnSubmit() {
            return true
        }

        get emailRules() {
            const rules = [this.emailRule('email')]
            if (this.isFieldMandatory('EMAIL')) {
                rules.push(this.requiredRule('email', 'EMAIL'))
            }
            return rules
        }

        get locale() {
            return this.$i18n.locale
        }

        get countries() {
            return runtimeStore.countries
        }

        get isDisabled() {
            return (authStore.isCorporate && this.tourist.personId) || this.disabled
        }

        get showTouristSelector() {
            return authStore.person && ['corp.trip_coordinator', 'corp.director'].includes(authStore.person.role)
        }

        get nameFieldCols() {
            return {
                cols: 12,
                md: 4,
                lg: 2,
            }
        }
    }
</script>
