<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
        <template v-slot:activator="{on, attrs}">
            <v-btn color="primary" icon class="me-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-circle-edit-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h6">
                {{ $t('сonfigure_table_with_reservations') }}
            </v-card-title>
            <v-card-text>
                {{ $t('check_the_columns') }}
            </v-card-text>
            <v-card-text>
                <template v-for="item in value">
                    <v-checkbox
                        v-if="item.value !== 'id'"
                        :key="item.value"
                        v-model="item.show"
                        :label="item.text"
                        :value="item.value"
                        dense
                        class="mt-0"
                    />
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="save">
                    {{ $t('save') }}
                </v-btn>
                <v-btn text @click="dialog = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class ReservationsTableOptionsModal extends Vue {
        @Prop() tableColumns

        dialog = false
        isLoading = false

        async save() {
            this.isLoading = true
            try {
                const columns = []
                this.value.forEach(item => {
                    if (!item.show) columns.push(item.value)
                })
                await this.$api.reservationsTableOptions.post({userId: this.userId, columns})
                this.$emit('change', columns)
                this.dialog = false
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.isLoading = false
        }

        get userId() {
            return authStore.person.personId
        }

        get value() {
            return clone(this.tableColumns)
        }
    }
</script>
