<template>
    <v-dialog v-model="modal" :max-width="1200">
        <v-card>
            <div class="d-flex flex-wrap">
                <div class="me-md-6 flex-grow-0 flex-shrink-1">
                    <v-card-title>
                        {{ title }}
                        <v-icon class="v-card-close mt-3 me-3" @click="modal = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-subtitle>
                        <div class="d-flex mt-2 align-end">
                            <v-icon class="me-1">{{ serviceIcon }}</v-icon>
                            <span>
                                {{ service && service.serviceName }}
                            </span>
                        </div>
                    </v-card-subtitle>
                </div>
                <div v-if="isTO1" class="pa-4 flex-grow-1 d-flex align-center">
                    <v-icon color="red" x-large left>mdi-alert-circle-outline</v-icon>
                    <div class="text-caption red--text">
                        <span class="text-uppercase">{{ `${$t('warning')}!!!` }}</span>
                        <div>{{ $t('manual_correction_warning') }}</div>
                        <div>{{ $t('manual_correction_info') }}</div>
                    </div>
                </div>
            </div>
            <v-card-text>
                <v-row>
                    <v-col :md="2">
                        <modify-manual-status :_status.sync="status" />
                    </v-col>
                    <v-col :md="2">
                        <v-text-field
                            v-model="refNumber"
                            outlined
                            dense
                            :label="`${$t('reference_number')}`"
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row v-if="isTO1 && service && service.dueToConfirmDate">
                    <v-col :md="4">
                        <vc-date-picker
                            v-model="dueToConfirmDate"
                            :model-config="{type: 'string', mask: 'YYYY-MM-DDTHH:mm'}"
                            :locale="$i18n.locale"
                            :min-date="$dateFns.format(new Date())"
                            :max-date="service.startDateTime"
                            :first-day-of-week="$config.firstDayOfWeek || undefined"
                            mode="dateTime"
                            is24hr
                            is-required
                        >
                            <template v-slot="{inputEvents}">
                                <div v-on="inputEvents">
                                    <v-text-field
                                        :label="$t('due_to_confirm_by')"
                                        readonly
                                        hide-details
                                        background-color="white"
                                        prepend-inner-icon="mdi-av-timer"
                                        :value="$options.filters.dateTimeFormat(dueToConfirmDate)"
                                        outlined
                                        dense
                                    />
                                </div>
                            </template>
                        </vc-date-picker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-subtitle-1 font-weight-medium">{{ $t('order_travelers') }}</div>
                    </v-col>
                </v-row>
                <div v-for="(traveler, index) in travelers" :key="traveler.travelerId">
                    <modify-manual-traveler
                        v-if="!traveler.delete"
                        :index="index"
                        :_traveler="traveler"
                        @remove="removeTraveler"
                    />
                </div>
                <v-row class="mb-8" no-gutters>
                    <v-col :cols="12" class="d-flex justify-center justify-md-end align-center">
                        <v-checkbox
                            v-model="isNewTravelerChild"
                            class="text-capitalize mt-5 mr-2"
                            :label="`${$tc('child', 1)}`"
                        />
                        <v-btn :color="'primary'" icon @click="addTraveler">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <template v-if="salesTerms">
                    <div v-for="salesTerm in salesTerms" :key="salesTerm.type" class="d-flex flex-column mb-8">
                        <modify-manual-sales-term :_sales-term="salesTerm" />
                    </div>
                </template>
                <div v-if="infoByServiceType" class="pt-4">
                    <component :is="infoComponent" v-model="infoByServiceType" />
                </div>
            </v-card-text>
            <v-card-actions class="justify-center py-5">
                <v-btn color="primary" small class="mr-5" :loading="loading" @click="confirm">
                    {{ $t('confirm') }}
                </v-btn>
                <v-btn color="white" small @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_MODIFY_MANUAL_MODAL} from '@/utils/event-bus'
    import ModifyManualStatus from '@/components/account/modify/manual/ModifyManualStatus'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import ModifyManualTraveler from '@/components/account/modify/manual/ModifyManualTraveler'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import ModifyManualSalesTerm from '@/components/account/modify/manual/ModifyManualSalesTerm'
    import ModifyManualAccommodation from '@/components/account/modify/manual/serviceType/ModifyManualAccommodation'
    import ModifyManualFlight from '@/components/account/modify/manual/serviceType/ModifyManualFlight'
    import ModifyManualActivity from '@/components/account/modify/manual/serviceType/ModifyManualActivity'
    import ModifyManualCarRent from '@/components/account/modify/manual/serviceType/ModifyManualCarRent'
    import ModifyManualTrain from '@/components/account/modify/manual/serviceType/ModifyManualTrain'
    import ModifyManualTransfer from '@/components/account/modify/manual/serviceType/ModifyManualTransfer'
    import ModifyManualInsurance from '@/components/account/modify/manual/serviceType/ModifyManualInsurance'
    import ModifyManualCruise from '@/components/account/modify/manual/serviceType/ModifyManualCruise'
    import {manualModification} from '@/api'

    @Component({
        components: {
            ModifyManualActivity,
            ModifyManualFlight,
            ModifyManualCarRent,
            ModifyManualAccommodation,
            ModifyManualInsurance,
            ModifyManualTrain,
            ModifyManualTransfer,
            ModifyManualCruise,
            ModifyManualSalesTerm,
            ModifyManualTraveler,
            ModifyManualStatus,
        },
    })
    export default class ModifyManualModal extends Vue {
        modal = false
        errors = null
        service = null
        loading = false
        title = 'Manual modify service'

        travelers = []
        status = null
        refNumber = null
        salesTerms = null

        infoByServiceType = null
        isNewTravelerChild = false
        dueToConfirmDate = null

        created() {
            EventBus.$on(SHOW_MODIFY_MANUAL_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_MODIFY_MANUAL_MODAL, this.showModal)
        }

        showModal(service) {
            this.service = service
            this.errors = null
            this.loading = false
            this.travelers = this.service.travelers.map(traveler => ({
                prefix: traveler.prefix,
                firstName: traveler.name[0].firstName,
                lastName: traveler.name[0].lastName,
                middleName: traveler.name[0].middleName,
                dateOfBirth: traveler.dateOfBirth,
                travelerId: traveler.travelerId,
                type: traveler.isChild ? 'child' : 'adult',
                delete: false,
            }))
            let status = runtimeStore.orderServiceStatus(this.service.status)
            if (status === 'PRECONFIRMED') {
                status = 'PRE-BOOKING'
                this.dueToConfirmDate = this.service.dueToConfirmDate
            } else if (status === 'CONFIRMATION_PENDING') {
                status = 'CONFIRMATION PENDING'
            }
            this.status = status
            this.refNumber = this.service.refNum
            this.salesTerms = [...this.service.salesTerms].map(e => ({
                type: e.type,
                price: {
                    amount: e.price.amount,
                    currency: e.price.currency,
                    commission: {
                        amount: e.price.commission,
                    },
                },
                cancelPenalty: [...e.cancelPenalty]
                    .filter(p => !p.noShow)
                    .map(p => ({
                        amount: p.amount,
                        endDateTime: p.endDateTime?.split(' ')[0] || '',
                        startDateTime: p.startDateTime?.split(' ')[0] || '',
                    }))
                    .sort((a, b) => {
                        return new Date(a.startDateTime) - new Date(b.startDateTime)
                    }),
            }))

            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    this.infoByServiceType = {
                        accommodation: {
                            startDate: this.service.startDateTime.split(' ')[0],
                            endDate: this.service.endDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'FLIGHT':
                    this.infoByServiceType = {
                        flight: {
                            lastTicketingDate: this.service.serviceDetails[0].lastTicketingDate?.split(' ')[0],
                            segments: this.service.serviceDetails[0].itineraries.map(i => {
                                const segment = {...i.flightSegments[0]}

                                return {
                                    departureAirportCode: segment.departureAirportCode,
                                    departureDate: segment.departureDate.split(' ')[0],
                                    arrivalAirportCode: segment.arrivalAirportCode,
                                    arrivalDate: segment.arrivalDate.split(' ')[0],
                                }
                            }),
                        },
                    }
                    break
                case 'TRANSFER':
                    this.infoByServiceType = {
                        transfer: {
                            startDateTime: this.service.startDateTime.split(' ').join('T'),
                            departure: {
                                ...(this.service.serviceDetails[0].departurePlace.airportCode && {
                                    flightInfo: {
                                        otherAirportCode:
                                            this.service.serviceDetails[0].departurePlace.otherAirportCode,
                                        flightNumber: this.service.serviceDetails[0].departurePlace.flightNumber,
                                        dateTime: this.service.serviceDetails[0].departurePlace.dateTime?.split(' ')[0],
                                    },
                                }),
                                ...(this.service.serviceDetails[0].departurePlace.trainNumber && {
                                    trainInfo: {
                                        trainNumber: this.service.serviceDetails[0].departurePlace.trainNumber,
                                    },
                                }),
                            },
                            arrival: {
                                ...(this.service.serviceDetails[0].arrivalPlace.airportCode && {
                                    flightInfo: {
                                        otherAirportCode: this.service.serviceDetails[0].arrivalPlace.otherAirportCode,
                                        flightNumber: this.service.serviceDetails[0].arrivalPlace.flightNumber,
                                        dateTime: this.service.serviceDetails[0].arrivalPlace.dateTime?.split(' ')[0],
                                    },
                                }),
                                ...(this.service.serviceDetails[0].arrivalPlace.trainNumber && {
                                    trainInfo: {
                                        trainNumber: this.service.serviceDetails[0].arrivalPlace.trainNumber,
                                    },
                                }),
                            },
                        },
                    }
                    break
                case 'ACTIVITY':
                    this.infoByServiceType = {
                        activity: {
                            startDateTime: [
                                this.service.serviceDetails[0].startDate,
                                this.service.serviceDetails[0].pickupTime,
                            ].join('T'),
                        },
                    }
                    break
                case 'CARRENT':
                    this.infoByServiceType = {
                        carRent: {
                            startDateTime: this.service.startDateTime.split(' ').join('T'),
                            endDateTime: this.service.endDateTime.split(' ').join('T'),
                        },
                    }
                    break
                case 'TRAIN':
                    this.infoByServiceType = {
                        train: {
                            startDateTime: this.service.startDateTime.split(' ')[0],
                            endDateTime: this.service.endDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'INSURANCE':
                    this.infoByServiceType = {
                        insurance: {
                            startDateTime: this.service.startDateTime.split(' ')[0],
                            endDateTime: this.service.endDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'OWNVISA':
                    this.infoByServiceType = {
                        visa: {
                            startDate: this.service.startDateTime.split(' ')[0],
                        },
                    }
                    break
                case 'CRUISE':
                    this.infoByServiceType = {
                        cruise: {
                            startDate: this.service.startDateTime.split(' ')[0],
                            endDate: this.service.endDateTime.split(' ')[0],
                            cruiseLine: this.service.serviceDetails[0].cruiseLine,
                            shipName: this.service.serviceDetails[0].shipName,
                            cabinCategory: this.service.serviceDetails[0].cabinCategoryName,
                            cabinTypeName: this.service.serviceDetails[0].cabinTypeName,
                            deckName: this.service.serviceDetails[0].deckName,
                            deckNumber: this.service.serviceDetails[0].deckNumber,
                            cabinNumber: this.service.serviceDetails[0].cabinNumber,
                        },
                    }
                    break
                default:
                    this.infoByServiceType = null
            }

            this.modal = true
        }

        async confirm() {
            try {
                this.loading = true
                await manualModification.post(this.request)
                this.$emit('modifyService')
            } catch (e) {
                if (e.msg) {
                    this.errors = [{message: e.msg}]
                } else {
                    this.errors = e.errors
                }
                this.errors.forEach(error => {
                    this.$toast.error(error.message)
                })
            } finally {
                this.loading = false
            }
        }

        removeTraveler(index) {
            this.travelers[index].delete = true
        }

        addTraveler() {
            this.travelers.push({
                type: this.isNewTravelerChild ? 'child' : 'adult',
                delete: false,
            })
        }

        get request() {
            return {
                processId: this.service.processId,
                status: this.status,
                refNum: this.refNumber,
                salesTerm: this.salesTerms.map(s => {
                    const salesTermCopy = {...s}
                    delete salesTermCopy.price.currency
                    return salesTermCopy
                }),
                travelers: this.travelers,
                ...this.infoByServiceType,
                ...(this.dueToConfirmDate && {dueToConfirmDate: this.dueToConfirmDate}),
            }
        }

        get infoComponent() {
            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    return 'modify-manual-accommodation'
                case 'FLIGHT':
                    return 'modify-manual-flight'
                case 'ACTIVITY':
                    return 'modify-manual-activity'
                case 'TRANSFER':
                    return 'modify-manual-transfer'
                case 'CARRENT':
                    return 'modify-manual-car-rent'
                case 'TRAIN':
                    return 'modify-manual-train'
                case 'INSURANCE':
                    return 'modify-manual-insurance'
                case 'OWNVISA':
                    return 'modify-manual-visa'
                case 'CRUISE':
                    return 'modify-manual-cruise'
                default:
                    return null
            }
        }

        get serviceIcon() {
            return getServiceTypeIcon(this.service?.serviceType)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
