<template>
    <v-card class="elevation-0 pa-4" outlined>
        <v-card-title>
            <span class="text-h5 font-weight-medium black--text">
                {{ $t('comment') }}
            </span>
        </v-card-title>
        <v-card-text>
            <div class="text-subtitle-1 text-justify mb-2">
                {{ $t('notes.other_comment_note') }}
            </div>
            <v-form ref="form" v-model="valid" :disabled="disabled">
                <v-textarea v-model="comment" :filled="filled" :placeholder="$t('type_here')" rows="3" />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import BookingCommentsForm from '~/components/booking/forms/BookingCommentsForm'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class BookingCommentsFormMod extends BookingCommentsForm {
        @Prop({default: false, type: Boolean}) filled
    }
</script>
