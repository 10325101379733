<template>
    <v-card :outlined="outlined" data-cy="payment-methods">
        <v-card-title>{{ $t('payment') }}</v-card-title>
        <v-card-text class="px-1 px-md-3">
            <div
                v-if="!paymentMethods.length"
                class="px-1"
                style="white-space: pre-line"
                v-text="$t('no_payment_options')"
            />
            <v-radio-group v-else v-model="paymentMethodId" :disabled="disabled">
                <template v-for="paymentMethod in paymentMethods">
                    <v-radio :key="paymentMethod.id" :value="paymentMethod.id" @click="onRadioClick(paymentMethod.id)">
                        <template v-slot:label>
                            <span v-if="paymentMethod.pspCode === 'h_pay_mada'">
                                {{ $t('mada_debit_card') }}
                            </span>
                            <span v-else>{{ paymentMethod.name }}</span>
                            &nbsp;
                            <strong v-if="totalCost(paymentMethod) > 0">
                                {{ totalCost(paymentMethod) | priceFormat(paymentMethod.totalAmount.currency) }}
                            </strong>
                            <payment-option-img :payment-method="paymentMethod.pspCode" class="ms-2" />
                        </template>
                    </v-radio>
                    <div
                        :key="`${paymentMethod.id}_description`"
                        class="text-caption ml-8 mb-2"
                        v-html="paymentMethod.description"
                    />
                    <div
                        v-if="
                            paymentMethod.splitPaymentOptions &&
                            paymentMethod.splitPaymentOptions.length &&
                            paymentMethod.id === paymentMethodId
                        "
                        :key="`select-${paymentMethod.id}`"
                        class="pl-8"
                    >
                        <v-select
                            v-model="numberOfPayments"
                            :items="paymentsCounterList"
                            :label="$t('number_of_payments')"
                            class="payments-counter"
                        />
                        {{ `${$t('amount_of_first_and_next_payments')}:` }}
                        <span>{{
                            (totalCost(paymentMethod) / numberOfPayments)
                                | priceFormat(paymentMethod.totalAmount.currency)
                        }}</span>
                    </div>
                </template>
            </v-radio-group>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, PropSync, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {persistentStore} from '~/store'
    import PaymentOptionImg from '~src/components/booking/PaymentOptionImg.src'

    @Component({
        components: {
            PaymentOptionImg,
        },
    })
    export default class PaymentOptions extends Vue {
        @VModel() paymentMethodId
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) outlined
        @PropSync('_numberOfPayments') numberOfPayments

        @Watch('paymentsCounterList')
        changePaymentsCounterList(val) {
            if (val) this.numberOfPayments = this.paymentsCounterList[0]
            else this.numberOfPayments = null
        }

        totalCost(paymentMethod) {
            if (this.paymentMethodId && this.numberOfPayments) {
                const index = paymentMethod.splitPaymentOptions.findIndex(
                    option => option.split('_')[0] === this.numberOfPayments
                )

                if (index >= 0) {
                    const total = paymentMethod.totalAmount.value
                    const percent = Number(paymentMethod.splitPaymentOptions[index].split('_')[1])

                    return total + (total * percent) / 100
                }
            }
            return paymentMethod.totalAmount.value
        }

        onRadioClick(val) {
            if (val === this.paymentMethodId) {
                this.paymentMethodId = null
            }
        }

        get paymentsCounterList() {
            if (this.paymentMethodId) {
                const paymentMethod = this.paymentMethods.find(method => method.id === this.paymentMethodId)
                if (!paymentMethod) return []
                return paymentMethod.splitPaymentOptions.reduce((paymentsCounter, option) => {
                    paymentsCounter.push(option.split('_')[0])

                    return paymentsCounter
                }, [])
            }
            return []
        }

        get paymentMethods() {
            return persistentStore.paymentMethods.filter(paymentMethod => paymentMethod.book)
        }
    }
</script>

<style scoped>
    .payments-counter {
        max-width: 200px;
    }
</style>
