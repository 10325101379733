<template>
    <div v-if="slides[0].length">
        <div class="mb-2 font-weight-medium text-truncate">
            {{ title }}
        </div>
        <v-carousel :show-arrows="slides.length > 1" show-arrows-on-hover hide-delimiters height="auto">
            <v-carousel-item v-for="(slide, index) in slides" :key="index">
                <v-row>
                    <v-col v-for="hotel in slide" :key="hotel.hotelCode" md="6">
                        <hotel-offer-card
                            :offer="hotel"
                            :search-request="searchRequest"
                            hide-room-offers
                            hide-tripadvisor-rating
                        >
                            <template v-slot="{minPriceRoomOffer, hotelPageLink}">
                                <div class="mt-2 pl-2 pr-4 d-flex justify-space-between align-center">
                                    <div v-if="minPriceRoomOffer" class="text-right font-weight-medium">
                                        {{ minPriceRoomOffer.price | price }}
                                    </div>
                                    <v-btn
                                        v-if="getHotelPageLink(hotelPageLink, hotel)"
                                        :to="getHotelPageLink(hotelPageLink, hotel)"
                                        color="primary"
                                        depressed
                                        nuxt
                                    >
                                        {{ $t('view') }}
                                    </v-btn>
                                </div>
                            </template>
                        </hotel-offer-card>
                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/utils/store-accessor'
    import HotelOfferCard from '~src/components/search/offers/hotelOfferCard.src'
    import {EventBus, FILTER_EVENT} from '@/utils/event-bus'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'

    @Component({components: {HotelOfferCard}})
    export default class SimilarHotels extends Vue {
        @Prop({required: false, type: Object}) searchRequest
        @Prop({required: false, type: Object}) city

        async mounted() {
            EventBus.$on(FILTER_EVENT, this.filterSimilar)
            await this.$store.restored
            await this.loadOffers()
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.filterSimilar)
        }

        filterSimilar() {
            if (hotelsRuntimeStore.filteredOffers.length) {
                hotelsRuntimeStore.filterSimilar(this.info)
            }
        }

        async loadOffers() {
            if (this.searchRequest.cityId !== hotelsStore.searchRequest.cityId) {
                hotelsRuntimeStore.SET_SIMILAR_OFFERS([])
                const sr = Object.assign(searchRequest(), this.searchRequest)

                delete sr.hotelId
                if (!sr.category) sr.category = hotelsStore.category(this.info.stdCategory)
                await hotelsRuntimeStore.search(sr)

                return
            }
            if (!hotelsRuntimeStore.filteredOffers.length) {
                const offers = await this.$localForage.getItem('hotels')
                if (offers) hotelsRuntimeStore.load(offers)
            } else {
                hotelsRuntimeStore.filterSimilar(this.info)
            }
        }

        getHotelPageLink(link) {
            return link
        }

        get hotels() {
            return hotelsRuntimeStore.similarOffers.filter(hotel => hotel.hotelCode !== this.info.hotelCode)
        }

        get slides() {
            let counter = 0
            let index = 0
            let slides = [[]]
            if (this.hotels.length) {
                this.hotels.forEach(hotel => {
                    if (slides.length === 2 && slides[1].length === 2) return false
                    if (counter < 2) {
                        slides[index].push(hotel)
                        counter++
                    } else {
                        counter = 0
                        index++
                        slides[index] = []
                        slides[index].push(hotel)
                    }
                })
            }
            return slides
        }

        get info() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        get title() {
            return `${this.$t('similar_hotels')} ${this.$t('in')} ${this.city.name}`
        }
    }
</script>
