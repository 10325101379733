<template>
    <div class="d-flex flex-column align-center" style="background: #fbfbfb">
        <compare-snackbar v-model="snackbar.show" @close-comparison="toggleComparison" />
        <v-card
            v-if="!isMobile || mobileTabs === 1"
            img="/search-bg.png"
            class="full-width d-flex flex-column align-center justify-center"
            :min-height="isMobile ? 88 : 168"
        >
            <div class="full-width full-height d-flex align-center justify-center py-4 flex-column">
                <v-expand-transition>
                    <hotel-search-form
                        v-show="!isMobile || changeDetailsExpand"
                        :style="{width: $breakpoint.lgAndUp ? '80%' : '100%'}"
                        row
                        dark
                        hide-options
                        @search="changeDetailsExpand = false"
                    />
                </v-expand-transition>
                <v-sheet
                    v-if="isMobile"
                    v-ripple
                    dark
                    color="rgba(0, 0, 0, 0.6)"
                    elevation="0"
                    :style="{width: changeDetailsExpand ? '100%' : '80%'}"
                    :class="{'mt-2': changeDetailsExpand, 'rounded-lg': !changeDetailsExpand}"
                    class="py-4 d-flex align-center justify-center"
                    @click="changeDetailsExpand = !changeDetailsExpand"
                >
                    <span class="primary--text">
                        {{ changeDetailsExpand ? $t('hide_details') : $t('change_details') }}
                    </span>
                    <v-icon right color="primary">
                        {{ changeDetailsExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </v-sheet>
            </div>
        </v-card>
        <search-layout
            v-if="loaded"
            :map-expanded="mapExpanded"
            :offers-count="filteredOffers.length"
            :search-active="searchActive"
            :mobile-tabs="mobileTabs"
            :style="{width: isMobile ? '90%' : $breakpoint.lgAndUp ? '80%' : '90%'}"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
                <hotel-breadcrumbs class="py-2" :search-request="searchRequest" />
            </template>
            <template v-slot:left-sidebar>
                <v-card class="px-5">
                    <hotel-filters
                        v-if="searchResponse.offersCount"
                        :filter-values="filterValues"
                        :filters="searchResponse.filters"
                        :filtered-offers-count="filteredOffers.length"
                        :nights="nights"
                        :currency="currency"
                        @change="changeFilter"
                        @reset="resetFilters"
                        @close-filters="mobileTabs = 1"
                    />
                </v-card>
                <overlay-progress :active="filterActive && !searchActive" />
            </template>
            <template v-slot:main-content>
                <search-progress
                    :filtered-offers-count="filteredOffers.length"
                    :total-offers-count="searchResponse.offersCount"
                    :search-active="searchActive"
                    :hide-stop-btn="$route.name === 'flightsAvailability'"
                    :city="city"
                    product-type="hotel"
                    @stop="stopSearch"
                >
                    <div
                        class="d-flex mb-4 mb-md-0 align-end align-center"
                        :class="{'justify-space-between': isMobile, 'full-width': isMobile}"
                    >
                        <v-btn
                            v-if="isMobile"
                            icon
                            tile
                            class="rounded-lg"
                            outlined
                            color="primary"
                            @click="mobileTabs === 0 ? (mobileTabs = 1) : (mobileTabs = 0)"
                        >
                            <v-icon color="primary"> mdi-filter-outline</v-icon>
                        </v-btn>
                        <div style="max-width: 120px" class="me-2">
                            <sort-select v-if="searchResponse.offersCount" v-model="sortKey" :items="sortItems" />
                        </div>
                        <v-btn
                            v-if="filteredOffers.length > 1 && !searchActive"
                            :outlined="!compareMode"
                            class="me-2"
                            :color="compareMode ? 'primary' : 'warning'"
                            elevation="0"
                            :dark="compareMode"
                            @click="toggleComparison"
                        >
                            <span v-if="compareMode" style="font-weight: 300" class="me-1">
                                {{ $t('close_comparison') }}
                            </span>
                            <span v-else style="font-weight: 300" class="black--text">
                                {{ $t('compare_hotels') }}
                            </span>
                        </v-btn>
                        <v-btn
                            :outlined="!showMap"
                            :icon="isMobile"
                            :tile="isMobile"
                            :class="{'rounded-lg': isMobile}"
                            :color="showMap ? 'primary' : 'warning'"
                            elevation="0"
                            :dark="showMap"
                            @click="showMap = !showMap"
                        >
                            <div class="d-flex align-center" style="font-weight: 300">
                                <v-icon :class="{'me-1': !isMobile}" :color="showMap ? 'white' : 'warning'">
                                    mdi-map-legend
                                </v-icon>
                                <template v-if="!isMobile">
                                    <span v-if="showMap" class="me-1">
                                        {{ $t('hide_map') }}
                                    </span>
                                    <span v-else class="black--text">
                                        {{ $t('map') }}
                                    </span>
                                </template>
                            </div>
                        </v-btn>
                    </div>
                </search-progress>
                <hotel-offers v-show="!showMap" :page="page" :compare-mode="compareMode" />
                <client-only>
                    <div
                        :class="{'pe-2': !isMobile, 'ms-6': isMobile}"
                        class="d-flex flex-column align-center justify-center"
                    >
                        <hotel-map
                            v-show="showMap"
                            :city="city"
                            style="position: relative !important; top: 0 !important"
                            :_expanded.sync="mapExpanded"
                            :offers="filteredOffers"
                            :height="isMobile ? '222px' : '570px'"
                            :location-filter="!isMobile"
                        />
                        <v-btn
                            v-if="isMobile && showMap"
                            class="mt-4 px-8"
                            elevation="0"
                            color="primary"
                            @click="showMap = false"
                        >
                            {{ $t('hide_map') }}
                        </v-btn>
                    </div>
                </client-only>
                <pagination v-show="!showMap" :page-prop.sync="page" :pages="pages" />
            </template>
            <template v-slot:right-sidebar />
        </search-layout>
        <div class="full-width d-flex justify-center" style="background: #f5f8f8">
            <questions-block />
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelsPage from '@/pages/hotels/HotelsPage'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import CompareSnackbar from '~src/components/snippets/CompareSnackbarMod'
    import QuestionsBlock from '~~/mods/ajbooking/components/parts/QuestionsBlock'

    @Component({
        components: {QuestionsBlock, CompareSnackbar, HotelSearchForm},
    })
    export default class HotelsPageMod extends HotelsPage {
        showMap = false
        changeDetailsExpand = false
        compareMode = false
        snackbar = {
            show: false,
        }
        mobileTabs = 1

        toggleComparison() {
            this.snackbar.show = !this.snackbar.show
            this.compareMode = !this.compareMode
            this.showMap = false
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
