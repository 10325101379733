var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"main-page-form-wrapper d-flex flex-column align-center px-8 px-md-0",staticStyle:{"margin-top":"90px","position":"relative","min-height":"calc(100vh - 150px)","background":"#f5f8f8","box-shadow":"none !important"},style:({marginTop: _vm.isMobile ? '40px' : '90px'}),attrs:{"tile":"","flat":"","elevation":"0"}},[_c('img',{staticStyle:{"z-index":"0","position":"absolute","overflow":"hidden"},style:({
            top: !_vm.isMobile ? '100px' : '40px',
            width: !_vm.isMobile ? '80%' : ' width: 300%',
            right: _vm.$vuetify.rtl ? '0' : '',
            left: _vm.$vuetify.rtl ? '' : '0',
        }),attrs:{"alt":"world-map","src":'/world_map.png'}}),_vm._v(" "),_c('div',{staticClass:"full-width d-flex flex-column text-start",staticStyle:{"z-index":"2","position":"relative"}},[_c('span',{staticClass:"ms-2 text-h3 primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.$t('time_for_new'))+" ")]),_vm._v(" "),_c('span',{staticClass:"ms-2 text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('adventures'))+" ")])]),_vm._v(" "),_c('search-form-tabs',{staticClass:"align-self-md-start",staticStyle:{"z-index":"2","position":"relative","margin-top":"80px"},style:(_vm.isMobile ? 'width: calc(100% - 8px)' : ''),attrs:{"city-id":_vm.cityId,"choose-agent":""},on:{"change":_vm.changeImg}}),_vm._v(" "),_c('img',{staticClass:"px-4 py-8",staticStyle:{"z-index":"0","width":"100%"},style:({
            position: !_vm.isMobile ? 'absolute' : 'relative',
            width: !_vm.isMobile ? '50%' : '100%',
            top: !_vm.isMobile ? '0' : '',
            right: _vm.$vuetify.rtl ? '' : '0',
            left: _vm.$vuetify.rtl ? '0' : '',
        }),attrs:{"alt":"background-places","src":'/background-places.png'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }