<template>
    <div class="caption">
        <span>{{ $t('original_currency_notification', {currency}) }}</span>
        <span>{{
            $t('original_currency_amount_notification', {
                currency: originalCurrency,
                price: $options.filters.priceFormat(originalAmount, originalCurrency),
            })
        }}</span>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component
    export default class OriginalCurrencyNotificationMod extends OriginalCurrencyNotification {}
</script>
