<template>
    <v-layout :fill-height="$fetchState.pending" column>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
        <v-container v-if="!$fetchState.pending" fluid :style="{width: isMobile ? '100%' : '80%'}">
            <hotel-breadcrumbs :search-request="searchRequest" class="align-self-start py-2" />
            <v-slide-y-transition>
                <v-row v-if="!isMobile || !showDetailsMobile">
                    <v-col v-show="!isMobile || (isMobile && mobileTabs === 0)" md="12">
                        <hotel-info class="mb-3" :search-request="searchRequest" @showMapTab="showMapTab" />
                        <v-card class="mb-n2">
                            <product-gallery-mobile
                                v-if="isMobile"
                                :aspect-ratio="352 / 180"
                                :images-data="info.images.image"
                            />
                            <v-btn v-if="isMobile" x-large text block color="primary" @click="showDetailsMobile = true">
                                <span class="caption">
                                    {{ $t('change_date') }}
                                </span>
                                <v-icon small right>mdi-chevron-up</v-icon>
                            </v-btn>
                        </v-card>
                        <v-row v-if="!isMobile" style="position: relative">
                            <v-col cols="6">
                                <v-img
                                    v-if="info.images.image[0] && info.images.image[0].url"
                                    :src="info.images.image[0].url | imageUrl(350)"
                                    :lazy-src="'/placeholder.png' | srcHost"
                                    height="256"
                                    class="grey lighten-2"
                            /></v-col>
                            <v-col cols="3">
                                <v-img
                                    v-if="info.images.image[1] && info.images.image[1].url"
                                    :src="info.images.image[1].url | imageUrl(350)"
                                    :lazy-src="'/placeholder.png' | srcHost"
                                    height="256"
                                    class="grey lighten-2"
                            /></v-col>
                            <v-col cols="3">
                                <v-img
                                    v-if="info.images.image[2] && info.images.image[2].url"
                                    :src="info.images.image[2].url | imageUrl(350)"
                                    :lazy-src="'/placeholder.png' | srcHost"
                                    height="256"
                                    class="grey lighten-2"
                            /></v-col>
                            <v-dialog v-model="galleryDialog" width="650">
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        style="position: absolute; bottom: 30px; right: 30px; z-index: 1"
                                        color="primary"
                                        class="px-6 py-2"
                                        elevation="0"
                                        v-on="on"
                                    >
                                        <span class="caption">
                                            {{ $t('all_photos') }}
                                        </span>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-icon class="v-card-close mt-3 me-3" @click="galleryDialog = false"
                                        >mdi-close</v-icon
                                    >
                                    <v-card-title class="mb-4"> {{ info.hotelName }} </v-card-title>
                                    <v-card-text>
                                        <product-gallery :images-data="info.images.image" />
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </v-col>
                    <v-col md="8">
                        <div class="d-flex align-center mb-4">
                            <v-btn
                                nuxt
                                text
                                :rounded="false"
                                class="v-btn--router footer-link me-4 py-6 px-4 text-h6"
                                :class="{'v-btn--active': activeButton === 0, 'justify-start': isMobile}"
                                @click.prevent="
                                    $vuetify.goTo('#rooms')
                                    activeButton = 0
                                "
                            >
                                {{ $t('description_type.rooms') }}
                            </v-btn>
                            <v-btn
                                v-if="!isMobile"
                                nuxt
                                text
                                :rounded="false"
                                class="v-btn--router footer-link me-4 py-6 px-4 text-h6"
                                :class="{'v-btn--active': activeButton === 1, 'justify-start': isMobile}"
                                @click.prevent="
                                    $vuetify.goTo('#additional-info')
                                    activeButton = 1
                                "
                            >
                                {{ $t('additional_info') }}
                            </v-btn>
                            <v-btn
                                v-if="!isMobile && services.length"
                                nuxt
                                text
                                :rounded="false"
                                class="v-btn--router footer-link me-4 py-6 px-4 text-h6"
                                :class="{'v-btn--active': activeButton === 2, 'justify-start': isMobile}"
                                @click.prevent="
                                    $vuetify.goTo('#services')
                                    activeButton = 2
                                "
                            >
                                {{ $t('services') }}
                            </v-btn>
                        </div>
                        <hotel-info-room-offers-wrapper
                            id="rooms"
                            class="mb-5 pb-4"
                            :search-request="searchRequest"
                            :offer="offer"
                        />
                        <v-divider v-if="isMobile" class="my-8" />
                        <v-expansion-panels accordion flat>
                            <v-expansion-panel
                                id="additional-info"
                                style="border: 1px solid #e9e9e9; border-radius: 6px"
                            >
                                <v-expansion-panel-header class="pa-4">
                                    <span class="text-h6">
                                        {{ $t('additional_info') }}
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <hotel-info-descriptions class="mb-5" />
                                    <hotel-info-time v-if="isMobile" card class="mb-5" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                                v-if="services.length"
                                id="services"
                                style="border: 1px solid #e9e9e9; border-radius: 6px"
                                class="mt-6"
                            >
                                <v-expansion-panel-header class="pa-4">
                                    <span class="text-h6">
                                        {{ $t('services') }}
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <hotel-info-services class="mb-5" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <client-only>
                            <hotel-info-map :city="city" />
                        </client-only>
                    </v-col>
                    <v-col v-if="!isMobile" md="4" cols="12">
                        <slot name="sidebar" />
                        <hotel-info-time card class="mt-5 mb-8" />
                        <hotel-info-search-summary :search-request="searchRequest" />
                    </v-col>
                </v-row>
            </v-slide-y-transition>
            <v-row v-if="isMobile && showDetailsMobile">
                <v-col cols="12">
                    <v-btn x-large text block color="primary" @click="showDetailsMobile = false">
                        <span class="caption">
                            {{ $t('see_details') }}
                        </span>
                        <v-icon small right>mdi-chevron-down</v-icon>
                    </v-btn>
                    <hotel-info-time v-if="!isMobile" card class="mt-5 mb-8" />
                    <hotel-info-search-summary :search-request="searchRequest" @refresh="showDetailsMobile = false" />
                </v-col>
            </v-row>
        </v-container>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <div class="full-width d-flex justify-center" style="background: #f5f8f8">
            <questions-block />
        </div>
    </v-layout>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelPage from '@/pages/hotels/HotelPage'
    import QuestionsBlock from '~~/mods/ajbooking/components/parts/QuestionsBlock'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component({
        components: {QuestionsBlock},
    })
    export default class HotelPageMod extends HotelPage {
        activeButton = 0
        galleryDialog = false
        showDetailsMobile = false

        get services() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.services
                ? hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.services.service.filter(
                      service => service.active
                  )
                : []
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    ::v-deep {
        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 5px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            .map {
                height: 220px !important;
            }
        }
    }
</style>
