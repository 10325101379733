<template>
    <order-summary-layout
        :product-store="productStore"
        :room-offers="roomOffers"
        :total-price="totalPrice"
        :offer="offer"
        class="hotel-order-summary"
    >
        <template v-slot>
            <div class="d-flex mb-8">
                <v-img
                    tile
                    :src="offer.thumb | imageUrl(350)"
                    :alt="offer.name"
                    eager
                    width="124"
                    max-width="124"
                    height="100"
                    :lazy-src="'/placeholder.png' | srcHost"
                    class="me-2"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && offer.thumb"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <div class="ma-2 mt-0 flex-grow-1 flex-shrink-1 text-truncate">
                    <div class="d-flex align-center flex-wrap">
                        <hotel-stars :hotel="offer" :size="10" :small="false" class="mb-1 me-3" />
                        <hotel-type-label class="ms-1" :offer="offer" />
                    </div>
                    <div class="font-weight-bold text-truncate mb-1">
                        {{ offer.name }}
                    </div>
                    <hotel-supplier-name-label v-if="isTO1" :supplier-name="offer.supplierName" />
                    <hotel-address-label hide-icon :address="offer.address" />
                </div>
            </div>
            <slot name="summary" />
            <div v-for="(room, roomIndex) in roomOffers" :key="roomIndex">
                <div
                    class="d-flex align-center font-weight-medium blac--text cursor-pointer mb-1"
                    @click="roomInfo(room)"
                >
                    <v-icon small left>mdi-information-outline</v-icon>
                    {{ room.name }}
                </div>
                <div class="caption font-weight-light">
                    <hotel-available-label :room="room" :icon-color="'warning'" />
                    <hotel-meal-type-label
                        :hotel="offer"
                        thin
                        :icon-color="'warning'"
                        :room="room"
                        :selected-meal-types="selectedMealTypes(roomIndex)"
                    />
                    <tariff-label :room="room" :hotel="offer" :icon-color="'warning'" />
                    <conditions-label
                        :offer-key="room.groupedOffers[0].offerKey"
                        :price="room.price"
                        :offer="offer"
                        :icon-color="'warning'"
                        @conditions="showConditionsInfo(room)"
                    />
                </div>
                <v-divider v-if="roomCheckInOutExtraServices(roomIndex).length" class="mb-4" />
                <v-list-item v-if="roomCheckInOutExtraServices(roomIndex).length" class="d-block my-4 px-0">
                    <v-row class="flex-column">
                        <v-col
                            v-for="service in roomCheckInOutExtraServices(roomIndex)"
                            :key="service.time + service.text"
                            class="py-0 d-flex align-center caption"
                        >
                            <span>{{ `${$t(service.text)} ` }}{{ service.time }}</span>
                            <v-spacer />
                            <span>{{ service.price | price }}</span>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider v-if="roomIndex < roomOffers.length - 1" class="my-4" />
            </div>
            <conditions-modal>
                <template v-slot:info>
                    <hotel-conditions-modal-info />
                </template>
                <template v-slot:hotelPriceNote>
                    <hotel-price-note :search-request="searchRequest" />
                </template>
            </conditions-modal>
            <hotel-meal-type-info-modal />
            <hotel-room-info-modal />
            <additional-fees-modal />
        </template>
        <template v-slot:violatedPolicies>
            <corporate-policy-violation v-if="corporatePolicyViolated" :policies="violatedPolicies" />
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOrderSummary from '@/components/booking/HotelOrderSummary'

    @Component
    export default class HotelOrderSummaryMod extends HotelOrderSummary {}
</script>
