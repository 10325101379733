<template>
    <div v-if="person">
        <span v-if="!isMobile" class="person-label text-truncate" v-text="`${person.firstName || person.lastName}`" />
        <div v-else class="d-flex align-center">
            <v-avatar size="58" class="me-3">
                <v-img :src="(person && person.avatar) || '/profile-avatar.png'" />
            </v-avatar>
            <span
                class="person-label text-truncate font-weight-medium"
                v-text="`${person.firstName || person.lastName}`"
            />
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import PersonLabel from '~/components/account/snippets/PersonLabel'

    @Component
    export default class PersonLabelMod extends PersonLabel {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
