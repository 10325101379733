<template>
    <v-row>
        <v-col :md="5" :cols="12">
            <span class="text-subtitle-1 font-weight-medium">{{ $t(`${salesTerm.type.toLowerCase()}_price`) }} </span>
            <v-row class="mt-1">
                <v-col v-if="selectCurrency" cols="12" md="3">
                    <v-select
                        v-model="salesTerm.price.currency"
                        :label="`${$t('currency')}`"
                        :items="currencies"
                        item-text="text"
                        item-value="value"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="salesTerm.price.amount"
                        :prefix="salesTerm.price.currency"
                        outlined
                        type="number"
                        dense
                        :label="`${$t('invoices.amount')}`"
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="salesTerm.price.commission.amount"
                        :prefix="salesTerm.price.currency"
                        :label="`${$t('commission')}`"
                        outlined
                        type="number"
                        dense
                        hide-details
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-col :md="7" :cols="12">
            <span class="text-subtitle-1 font-weight-medium"> {{ $t('cancellation_penalties') }} </span>
            <v-radio-group v-model="cancelPenaltyEnabled" row>
                <v-radio :label="$t('no_fees')" :value="false" />
                <v-radio :label="$t('calculate_cancellation_fees')" :value="true" />
            </v-radio-group>
            <v-row v-for="(penalty, i) in salesTerm.cancelPenalty" :key="penalty.startDateTime" align="center">
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="penalty.amount"
                        :prefix="salesTerm.price.currency"
                        :label="`${$t('invoices.amount')}`"
                        outlined
                        type="number"
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="12" md="5">
                    <daterange-m
                        class="mt-2"
                        :label="$t('modify.subtitle_dates')"
                        outlined
                        dense
                        :start-date.sync="penalty.startDateTime"
                        :end-date.sync="penalty.endDateTime"
                    />
                </v-col>
                <v-col cols="12" :md="1" class="d-flex justify-md-end justify-center align-center mt-md-2">
                    <v-btn icon @click="removeCancelPenalty(i)">
                        <v-icon>mdi-close-box-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="cancelPenaltyEnabled" no-gutters class="mt-2">
                <v-col :cols="12" :md="10" class="d-flex justify-center justify-md-end">
                    <v-btn :color="'primary'" class="mx-auto" icon @click="addCancelPenalty">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, PropSync, Vue, Watch} from 'nuxt-property-decorator'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component({
        components: {DaterangeM},
    })
    export default class ModifyManualSalesTerm extends Vue {
        @PropSync('_salesTerm', {default: () => []}) salesTerm
        @Prop({default: false, type: Boolean}) selectCurrency

        cancelPenaltyEnabled = false

        @Watch('cancelPenaltyEnabled')
        onCancelPenaltyEnabledChange() {
            if (this.cancelPenaltyEnabled) {
                if (!this.salesTerm.cancelPenalty.length) {
                    this.addCancelPenalty()
                }
            } else {
                this.salesTerm.cancelPenalty = []
            }
        }

        @Watch('salesTerm.cancelPenalty.length', {immediate: true})
        onCancelPenaltyChange() {
            this.cancelPenaltyEnabled = !!this.salesTerm.cancelPenalty.length
        }

        addCancelPenalty() {
            this.salesTerm.cancelPenalty.push({
                amount: 0,
                startDateTime: null,
                endDateTime: null,
            })
        }

        removeCancelPenalty(i) {
            this.salesTerm.cancelPenalty.splice(i, 1)
        }

        get currencies() {
            return Object.values(this.$config.currency.available).map(currency => ({
                text: currency,
                value: currency,
            }))
        }
    }
</script>
