<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class BookingFieldsMixin extends Vue {
        isFieldMandatory(fieldKey) {
            return (
                runtimeStore.isTouristBookingField(fieldKey, 'MANDATORY', this.isMainTourist, this.isChild) ||
                (fieldKey === 'EMAIL' &&
                    this.isTourLead &&
                    (!authStore.person ||
                        runtimeStore.touristBookingFields.supplierConfigDetails?.tourLeadEmailRequired))
            )
        }

        isFieldDisabled(fieldKey) {
            return (
                runtimeStore.isTouristBookingFieldDisabled(fieldKey, this.isMainTourist, this.isChild) &&
                !this.isFieldMandatory(fieldKey)
            )
        }

        fieldLabel(token, fieldKey) {
            return `${this.$t(token)}${
                (fieldKey &&
                    this.isFieldMandatory(fieldKey) &&
                    !runtimeStore.isTouristNotRequired(this.tourist, this.isMainTourist)) ||
                (!fieldKey && !runtimeStore.isTouristNotRequired(this.tourist, this.isMainTourist))
                    ? '*'
                    : ''
            }`
        }

        requiredRule(token, fieldKey) {
            return v =>
                !!v ||
                (fieldKey && !this.isFieldMandatory(fieldKey)) ||
                runtimeStore.isTouristNotRequired(this.tourist, this.isMainTourist) ||
                `${this.$t(token)} ${this.$t('validation.required')}`
        }

        get isMainTourist() {
            return this.index === 0
        }

        get isTourLead() {
            return this.index === 0
        }

        get isChild() {
            return this.tourist.type === 'child'
        }

        get age() {
            return this.tourist.age
        }

        get date() {
            //TODO Need to add start date for all products
            return this.startDate ? this.$dateFns.parseISO(this.startDate) : new Date()
        }

        get minBirthDate() {
            return this.age ? this.$dateFns.format(this.$dateFns.subYears(this.date, +this.age + 1)) : '1900-01-01'
        }

        get maxBirthDate() {
            return this.age
                ? this.$dateFns.format(this.$dateFns.subYears(this.date, +this.age))
                : this.$dateFns.format(new Date())
        }
    }
</script>
