<template>
    <div>
        <v-tabs
            v-if="activeProducts.length > 1 || tabLinks.length > 0"
            ref="tabs"
            v-model="productTabs"
            show-arrows
            color="white"
            background-color="rgba(0, 0, 0, 0.6)"
            slider-color="primary"
            dark
            @change="change"
        >
            <v-tab v-for="product in activeProducts" :key="product + '_tab'">
                <v-icon class="me-1">
                    {{ products[product].ico }}
                </v-icon>
                <span class="hidden-sm-and-down">{{ $t(products[product].title) }}</span>
            </v-tab>
            <search-form-tab-link
                v-for="tab in tabLinks"
                :key="tab.name"
                :name="tab.name"
                :href="tab.href"
                :ico="tab.ico"
            />
        </v-tabs>
        <choose-agent-dialog v-if="selectAgentEnabled && !selectedAgent && chooseAgent" />
        <v-tabs-items v-model="productTabs" class="overflow--visible">
            <v-tab-item v-for="(product, index) in activeProducts" :key="index">
                <component
                    :is="products[product].form"
                    :variant="products[product].variant"
                    :product-name="products[product].title"
                    :city-id="cityId"
                    :class="{'product-tabs': activeProducts.length > 1}"
                    :data-cy="products[product]['data-cy']"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import TransferSearchForm from '~src/components/transfers/search/forms/transferSearchForm.src'
    import ActivitySearchForm from '~src/components/activities/search/forms/activitySearchForm.src'
    import FlightSearchForm from '~src/components/flights/search/forms/flightSearchForm.src'
    import InsuranceSearchForm from '@/components/insurance/search/forms/InsuranceSearchForm'
    import PackagesSearchForm from '@/components/packages/search/forms/PackagesSearchForm'
    import CarsSearchForm from '@/components/carsRent/search/forms/CarsSearchForm'
    import ExtraServiceSearchForm from '@/components/extraServices/search/forms/ExtraServiceSearchForm'
    import TourSearchForm from '@/components/tours/search/forms/TourSearchForm'
    import CruisesSearchForm from '@/components/cruises/search/forms/CruisesSearchForm'
    import SearchFormTabLink from '@/components/SearchFormTabLink'
    import ChooseAgentDialog from '@/components/ChooseAgentDialog'
    import {authStore, persistentStore} from '@/utils/store-accessor'

    @Component({
        components: {
            ChooseAgentDialog,
            SearchFormTabLink,
            HotelSearchForm,
            TransferSearchForm,
            ActivitySearchForm,
            FlightSearchForm,
            PackagesSearchForm,
            CarsSearchForm,
            ExtraServiceSearchForm,
            TourSearchForm,
            CruisesSearchForm,
            InsuranceSearchForm,
        },
    })
    export default class SearchFormTabs extends Vue {
        @Prop() cityId
        @Prop({default: false, type: Boolean}) chooseAgent

        productTabs = 0

        @Emit()
        change(index) {
            return this.activeProducts[index]
        }

        @Watch('locale')
        onLocaleChange() {
            this.$refs.tabs.onResize()
        }

        mounted() {
            this.change(this.productTabs)
        }

        get selectAgentEnabled() {
            if (!this.$config.account.showCorporateClients && this.isAgency) {
                return false
            }

            return this.isTO1 || this.isTO2 || (this.isB2B && this.$config.account.showSelectAgentB2B)
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get tabLinks() {
            return []
        }

        get selectedAgent() {
            return persistentStore.selectedAgent
        }

        get activeProducts() {
            return Object.values(this.$config.activeProducts)
        }

        get isB2B() {
            return authStore.hasCorporateAccess
        }

        get products() {
            return {
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                    form: 'hotel-search-form',
                    'data-cy': 'hotel-search-form',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                    form: 'transfer-search-form',
                    'data-cy': 'transfer-search-form',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    form: 'activity-search-form',
                    'data-cy': 'activity-search-form',
                },
                flights: {
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                    form: 'flight-search-form',
                    'data-cy': 'flight-search-form',
                },
                packages: {
                    ico: 'mdi-ticket-confirmation-outline',
                    title: 'packages.packages',
                    form: 'packages-search-form',
                    'data-cy': 'flight-hotel-search-form',
                },
                tours: {
                    ico: 'mdi-wallet-travel',
                    title: 'tours.tours',
                    form: 'tour-search-form',
                    'data-cy': 'packages-search-form',
                },
                carsRent: {
                    ico: 'mdi-car',
                    title: 'carsRent.cars_rent',
                    form: 'cars-search-form',
                    'data-cy': 'car-search-form',
                },
                insurance: {
                    ico: 'mdi-umbrella',
                    title: 'insurance.insurance',
                    form: 'insurance-search-form',
                    'data-cy': 'insurance-search-form',
                },
                extraServices: {
                    ico: 'mdi-star-box',
                    title: 'extraServices.extra_services',
                    form: 'extra-service-search-form',
                    'data-cy': 'extra-services-search-form',
                },
                conferenceHall: {
                    ico: 'mdi-monitor-dashboard',
                    title: 'extraServices.conference_hall',
                    form: 'extra-service-search-form',
                    variant: 'conference',
                    'data-cy': 'conference-hall-search-form',
                },
                catering: {
                    ico: 'mdi-cup',
                    title: 'extraServices.catering',
                    form: 'extra-service-search-form',
                    variant: 'catering',
                    'data-cy': 'catering-search-form',
                },
                cruises: {
                    ico: 'mdi-ferry',
                    title: 'cruises.cruises',
                    form: 'cruises-search-form',
                },
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-tabs > .v-tabs-bar {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }

        .theme--light.v-tabs-items {
            background-color: transparent;
        }

        .v-card.main-form {
            width: auto;

            &.product-tabs.theme--light.v-card.v-sheet--outlined {
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
</style>
