var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":!_vm.loaded,"column":""}},[(_vm.loaded)?_c('car-booking-page-layout',{ref:"layout",attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer,"offer-key":_vm.offerKey},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('car-breadcrumbs',{attrs:{"search-request":_vm.searchRequest,"product":_vm.product}})]},proxy:true},{key:"tourists",fn:function(ref){
var disabled = ref.disabled;
var tourists = ref.tourists;
var personSelected = ref.personSelected;
return [_c('tourist-form',{attrs:{"index":0,"hide-index":true,"disabled":disabled,"corporate-policy-violation":_vm.offer.corporatePolicyViolation,"selected-tourists":tourists,"start-date":_vm.startDate,"data-cy":"tourist-0","label":"carsRent.driver"},on:{"person-selected":personSelected},model:{value:(tourists[0]),callback:function ($$v) {_vm.$set(tourists, 0, $$v)},expression:"tourists[0]"}})]}},{key:"forms",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('car-details-form',{staticClass:"mb-4",attrs:{"disabled":!!bookingKey,"offer":_vm.offer,"outlined":""}}),_vm._v(" "),_c('booking-comments-form',{attrs:{"disabled":!!bookingKey,"product-store":_vm.productStore,"outlined":false}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('car-order-summary',{attrs:{"search-request":_vm.searchRequest,"info":_vm.product.info,"offer":_vm.product.offer,"prebooked":!!bookingKey},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('v-card',{staticClass:"mb-1 pa-0",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('search-summary-content',{attrs:{"search-request":_vm.searchRequest,"pick-up-location":_vm.pickUpLocation,"drop-off-location":_vm.dropOffLocation,"show-passengers":""}})],1)],1)]},proxy:true}],null,true)})]}}],null,false,3605507302)}):_c('v-layout',{staticClass:"align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_vm._v(" "),_c('conditions-modal',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('car-conditions-modal-info')]},proxy:true}])}),_vm._v(" "),_c('car-info-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }