<template>
    <div class="mb-5">
        <v-card outlined class="mb-2">
            <div class="pa-4"><slot /></div>
            <v-card-text v-if="showPrice && originalCurrency !== currency">
                <original-currency-notification :price="totalPrice" />
            </v-card-text>
        </v-card>
        <div v-if="showPrice" class="d-flex">
            <v-spacer />
            <div class="text-right">
                <div>
                    <total-price-label :total-price="totalPrice" :offer="offer" class="text-h6" />
                    <commission-amount
                        v-if="isAgency && totalPrice.commission"
                        :commission="{amount: totalPrice.commission, currency: totalPrice.currency}"
                    />
                    <net-amount :price="netAmount" />
                    <slot name="violatedPolicies" />
                    <change-total-price-dialog
                        v-if="(isTO1 || isTO2) && totalPrice.supplierPriceAmount !== undefined && !bookingKey"
                        :room-offers="roomOffers"
                        :product-store="productStore"
                        :total-price="totalPrice"
                    />
                </div>
                <div v-if="!isNaN(totalPrice.loyaltyPoints)" class="text-caption text--secondary">
                    <div>{{ $t('loyalty') }} : {{ totalPrice.loyaltyPoints }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import OriginalCurrencyNotification from '~src/components/booking/snippets/originalCurrencyNotification.src'
    import TotalPriceLabel from '~src/components/booking/snippets/totalPriceLabel.src'
    import ChangeTotalPriceDialog from '@/components/booking/ChangeTotalPriceDialog'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'

    @Component({
        components: {
            ChangeTotalPriceDialog,
            OriginalCurrencyNotification,
            TotalPriceLabel,
            CommissionAmount,
            NetAmount,
        },
    })
    export default class OrderSummaryLayout extends Vue {
        @Prop({required: true}) totalPrice
        @Prop() offer
        @Prop({required: true}) productStore
        @Prop() roomOffers

        /**
         * For medistays
         * @returns {boolean}
         */
        get netAmount() {
            const netAmount = {
                ...this.totalPrice,
                commission: {
                    amount: this.totalPrice.commission,
                },
            }

            return netAmount
        }

        get showPrice() {
            return true
        }

        get currency() {
            return persistentStore.getCurrency(this.totalPrice.currency)
        }

        get originalCurrency() {
            return this.totalPrice.originalCurrency || this.totalPrice.currency
        }

        get bookingKey() {
            return this.productStore.prepareBookResponse.bookingKey
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>
