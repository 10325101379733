<script>
    import {Component} from 'nuxt-property-decorator'
    import FakeTemplatesMixin from '~src/components/account/mixins/fakeTemplatesMixin.src'

    @Component
    export default class DocumentTemplatesMixin extends FakeTemplatesMixin {
        documentTemplates = []

        async loadDocumentTemplates() {
            try {
                this.documentTemplates = (await this.$api.documentTemplates.get()).templates

                // this.documentTemplates = []
                // await this.loadFakeTemplates()
                // this.documentTemplates = [...this.documentTemplates, ...this.fakeTemplates]

                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
