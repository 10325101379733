var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('tour-booking-page-layout',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer,"search-request-country-id":_vm.searchRequestCountryId},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('tour-breadcrumbs',{attrs:{"search-request":_vm.searchRequest}})]},proxy:true},{key:"tourists",fn:function(ref){
var tourists = ref.tourists;
var index = ref.index;
var disabled = ref.disabled;
var personSelected = ref.personSelected;
return [_c(_vm.hasFlightOffers ? 'flight-tourist-form' : 'tourist-form',{tag:"component",attrs:{"disabled":disabled,"index":index,"offer":_vm.hasFlightOffers ? _vm.firstFlightOffer : null,"hide-index":tourists.length === 1,"start-date":_vm.startDate},on:{"person-selected":personSelected},model:{value:(tourists[index]),callback:function ($$v) {_vm.$set(tourists, index, $$v)},expression:"tourists[index]"}})]}},{key:"forms",fn:function(ref){
var bookingKey = ref.bookingKey;
return [(_vm.firstTransfer || _vm.lastTransfer)?_c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-capitalize"},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-bus")]),_vm._v("\n                "+_vm._s(_vm.$t('productType.transfer'))+"\n            ")],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(_vm.firstTransfer)?_c('tour-transfer-form',{attrs:{"product":_vm.firstTransfer,"disabled":!!bookingKey,"point-type":"departure"}}):_vm._e(),_vm._v(" "),(_vm.firstTransfer && _vm.lastTransfer)?_c('v-divider'):_vm._e(),_vm._v(" "),(_vm.lastTransfer)?_c('tour-transfer-form',{attrs:{"product":_vm.lastTransfer,"disabled":!!bookingKey,"point-type":"arrival"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.hasCarOffers)?_c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-capitalize"},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-car")]),_vm._v("\n                "+_vm._s(_vm.$t('productType.carRent'))+"\n            ")],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('car-details-form',{attrs:{"disabled":!!bookingKey,"offer":_vm.carEntry.offer}})],1):_vm._e()]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('tour-order-summary',{attrs:{"offer":_vm.offer,"prebooked":!!bookingKey,"search-request":_vm.searchRequest}})]}}],null,false,1787323792)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }