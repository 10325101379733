<template>
    <v-card
        class="hotel-card"
        :class="{pointer: compareMode}"
        outlined
        :hover="compareMode"
        @click.native="selectToCompare(offer)"
    >
        <component
            :is="imageWrapper"
            no-prefetch
            :to="hotelPageLink"
            :href="$router.resolve(hotelPageLink).href"
            target="_blank"
            class="title text-decoration-none"
        >
            <v-img
                tile
                :src="offer.thumb | imageUrl(350)"
                :alt="offer.name"
                eager
                :aspect-ratio="346 / 244"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <recommended-icon v-if="offer.recommended" class="recommended-thumb" />
        </component>
        <div>
            <div class="d-flex align-center mt-4">
                <hotel-stars :size="20" :small="false" :color="'warning'" class="me-3 mt-n1" :hotel="offer" />
                <hotel-type-label class="text-uppercase" :offer="offer" :x-small="false" small />
                <hotel-own-label class="ms-1" :offer="offer" />
                <v-spacer />
                <tripadvisor-rating
                    v-if="
                        !hideTripadvisorRating && (offer.tripadvisorRating || (offer.ratings && offer.ratings.length))
                    "
                    :tripadvisor-rating="offer.tripadvisorRating"
                    :ratings="offer.ratings"
                />
            </div>
            <nuxt-link
                :to="hotelPageLink"
                class="my-2 break-word text-h6 font-weight-regular text-decoration-none black--text"
            >
                {{ offer.name }}
            </nuxt-link>
            <hotel-location-label class="my-1" :offer="offer" />
            <hotel-address-label class="my-1 mb-4" :address="offer.address" :offer="offer" />
            <div
                v-if="isFullyRefundable"
                class="text-subtitle-1 success--text font-weight-regular"
                v-text="`${$t('fully')} ${$t('filters_refundable.true').toLowerCase()}`"
            />
            <hotel-services :offer="offer" :color="'yellowlime'" />
            <v-divider class="my-6" style="border-color: #e9e9e9" />
            <slot name="price" :room="minPriceRoomOffer">
                <hotel-offer-price-block
                    class="d-flex justify-space-between my-3 align-center"
                    :offer="offer"
                    :search-request="searchRequest"
                    :hotel-page-link="hotelPageLink"
                    mobile
                />
            </slot>
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="compareMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :search-request="searchRequest"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" />
                    </template>
                </hotel-room-offers-wrapper>
                <hotel-multiple-room-select-block
                    v-if="isMultiRoom"
                    :search-request="searchRequest"
                    :offer="offer"
                    :selected-offer-keys="selectedOfferKeys"
                    class="pa-4 pt-0"
                />
            </div>
        </v-expand-transition>
        <v-btn
            block
            depressed
            class="my-3 primary--text"
            data-cy="hotel-card-toggle-offers"
            @click="expanded = !expanded"
        >
            {{ expanded ? $t('hide_room_offers') : $t('view_room_offers') }}
            <v-icon color="primary">mdi-chevron-{{ expanded ? 'up' : 'down' }}</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferCardMobile from '~/components/search/offers/HotelOfferCardMobile'
    import TripadvisorRating from '~src/components/hotels/snippets/tripadvisorRating.src'

    @Component({
        components: {TripadvisorRating},
    })
    export default class HotelOfferCardMobileMod extends HotelOfferCardMobile {}
</script>
