<template>
    <template-editor-page-layout content-class="flex-column" @save-new="saveNew">
        <template v-slot:appBar="{isNewTemplate, newTemplateDialog}">
            <keep-alive>
                <code-editor-dialog :ckeditor="editor" />
            </keep-alive>
            <v-btn
                depressed
                color="primary"
                :loading="saveLoading"
                @click="isNewTemplate ? (newTemplateDialog = true) : save()"
            >
                {{ isNewTemplate ? $t('save') : $t('save_changes') }}
            </v-btn>
            <v-btn depressed color="secondary" nuxt :to="{name: 'documentTemplates'}">
                {{ $t('cancel') }}
            </v-btn>
        </template>
        <keep-alive>
            <html-editor
                v-if="template.htmlTemplate"
                ref="editor"
                :html="template.htmlTemplate"
                @loaded="editor = $event"
            />
        </keep-alive>
    </template-editor-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TemplateEditorPageLayout from '@/components/admin/TemplateEditorPageLayout'
    import TemplateEditorPageMixin from '@/components/account/mixins/TemplateEditorPageMixin'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'

    @Component({
        components: {
            CodeEditorDialog: () => import('@/components/admin/modals/CodeEditorDialog'),
            HtmlEditor: () => import('@/components/admin/HtmlEditor'),
            TemplateEditorPageLayout,
        },
        middleware: ['auth', 'authorization'],
        layout: 'pure',
    })
    export default class DocumentEditorPage extends mixins(DocumentTemplatesMixin, TemplateEditorPageMixin) {
        template = {
            htmlTemplate: '',
        }
        editor = null
        saveLoading = false

        head() {
            return {
                bodyAttrs: {
                    'data-editor': 'DecoupledDocumentEditor',
                    'data-collaboration': false,
                    'data-revision-history': false,
                },
            }
        }

        async mounted() {
            if (!this.$route.params.id) return
            await this.loadDocumentTemplates()
            this.template = this.documentTemplates.find(({id}) => id === +this.$route.params.id)
            if (!this.template) {
                this.$nuxt.error({statusCode: 404})
            }
        }

        async save() {
            // eslint-disable-next-line no-console
            console.log(this.editor.getData())
            // eslint-disable-next-line no-console
            console.log(this.$refs.editor.ckContent)
            //TODO Wait API implementation

            try {
                this.saveLoading = true
                await this.$api.documentTemplates.put(this.template.id, {
                    htmlTemplate: this.editor.getData(),
                })
                await this.$router.push({name: 'documentTemplates'})
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.saveLoading = false
            }
        }

        async saveNew(template) {
            // eslint-disable-next-line no-console
            console.log(template, this.editor.getData())
            //TODO Wait API implementation
            /*await this.saveNewTemplate({
                ...template,
                htmlTemplate: this.template.htmlTemplate,
            })*/
        }
    }
</script>
