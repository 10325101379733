<template>
    <div>
        <v-card :img="img" dark height="167" rounded="0">
            <v-card-title
                class="d-flex align-center justify-center full-width full-height font-weight-medium text-h3"
                :class="{'text-h3': isMobile}"
            >
                <span>{{ title }} </span>
            </v-card-title>
        </v-card>
        <div class="body-2 d-flex justify-center align-center" style="width: 100%; background: white">
            <div v-if="isNews" :style="{width: isMobile ? '100%' : '80%'}" class="d-flex align-center justify-center">
                <news-list class="pt-8" />
            </div>
            <ard-contacts v-else-if="isContacts" />
            <div v-else :style="{width: isMobile ? '100%' : '80%'}" class="d-flex align-center justify-center">
                <vue-markdown class="pt-8" :source="content" />
            </div>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import InfoPage from '~/pages/cms/InfoPage'
    import BlogItem from '~src/components/parts/blogItem.src'
    import NewsList from '~~/mods/ajbooking/components/parts/NewsList'
    import ArdContacts from '~~/mods/ajbooking/components/parts/ArdContacts'

    @Component({
        components: {ArdContacts, NewsList, BlogItem},
    })
    export default class InfoPageMod extends InfoPage {
        get img() {
            return this.isContacts ? '/info-header-2.png' : '/info-header.png'
        }

        get isNews() {
            return this.$route.path === '/info/news'
        }

        get isContacts() {
            return this.$route.path === '/info/contacts'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
