<template>
    <v-form ref="form" v-model="valid" data-cy="authorization-form" @keyup.native.enter="auth">
        <v-text-field
            v-model="email"
            :placeholder="$t('email')"
            type="email"
            :rules="validationRules('email', [emailRule, requiredRule])"
            required
            data-cy="authorization-email-input"
            :background-color="dark ? 'white' : undefined"
            :dense="dark"
            :outlined="dark"
        />
        <v-text-field
            v-model="password"
            :placeholder="$t('password')"
            type="password"
            :rules="[requiredRule('password')]"
            required
            data-cy="authorization-password-input"
            :background-color="dark ? 'white' : undefined"
            :dense="dark"
            :outlined="dark"
        />
        <div class="d-flex">
            <v-btn
                :loading="isLoading"
                :disabled="isLoading || !valid"
                color="primary"
                class="mr-2"
                :dark="dark"
                data-cy="authorization-submit-button"
                @click="auth"
            >
                {{ $t('sign_in') }}
            </v-btn>
            <v-btn outlined color="primary" class="text-capitalize" @click="$emit('switch-forgot-password')">
                {{ $t('forgot_your_password') }}?
            </v-btn>
        </div>
    </v-form>
</template>

<script>
    import {Mixins, Component, Prop} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class AuthorizationForm extends Mixins(FormValidationRulesMixin) {
        @Prop({default: false, type: Boolean}) dark

        email = null
        password = null
        valid = false

        mounted() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            this.$nextTick(() => {
                setTimeout(() => {
                    if (document.querySelectorAll('input:-webkit-autofill').length) {
                        this.valid = true
                    }
                }, 0)
            })
        }

        async auth() {
            if (!this.valid) return

            try {
                await authStore.auth({
                    login: this.email,
                    password: this.password,
                })
            } catch (e) {
                if (e.status === 401) {
                    this.$toast.error(this.$t('incorrect_credentials'))
                } else if (e.errors && e.errors.length) {
                    this.$toast.error(e.errors[0].message)
                }
            }
        }

        get isLoading() {
            return runtimeStore.authActive
        }
    }
</script>
