<template>
    <v-img v-if="paymentMethod === 'h_pay_mada'" src="/mada.png" height="55" max-width="120" />
    <div v-else-if="paymentMethod === 'h_pay_visa'" class="ms-4">
        <v-img src="/mastervisa.png" height="55" max-width="130" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class PaymentOptionImgMod extends Vue {
        @Prop({required: true}) paymentMethod
    }
</script>
