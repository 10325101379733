<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import {PHONE_REGEXP} from '@/utils/helpers'

    @Component
    export default class CompanyAutocomplete extends ClientAutocomplete {
        @Prop() types

        companyPhoneString = null

        async loadClients() {
            try {
                this.isLoadingClients = true

                const result = []

                this.companyPhoneString = PHONE_REGEXP.test(this.searchInput) ? this.searchInput : null

                const promises = this.types.map(type => {
                    return async () => {
                        const [dataByPhone, dataByName] = await Promise.all([
                            (async () => {
                                return this.companyPhoneString
                                    ? (
                                          await this.$api.companies.get({
                                              type,
                                              shortResponse: true,
                                              phone: this.searchInput,
                                          })
                                      ).object
                                    : []
                            })(),
                            (async () => {
                                return (
                                    await this.$api.companies.get({
                                        type,
                                        shortResponse: true,
                                        name: this.searchInput,
                                    })
                                ).object
                            })(),
                        ])

                        result.push(...dataByName, ...dataByPhone)
                    }
                })

                await Promise.all(promises.map(e => e()))

                result.sort((a, b) => a.name.localeCompare(b.name))

                this.clients = result
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoadingClients = false
            }
        }

        inputText(val) {
            const phone = val?.phones.find(p => p.includes(this.companyPhoneString)) || null

            return val?.name + (phone ? ` (${phone})` : '') || null
        }
    }
</script>
