<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class ConfirmationRedirectMixin extends Vue {
        redirectAfterPayment(orderId) {
            this.orderDetailsRedirect(orderId)
        }

        orderDetailsRedirect(orderId) {
            if (this.isDriver || this.isGuide) {
                this.$router.push({name: 'scheduler', params: {id: orderId}})
            } else {
                this.$router.push({name: 'order-details', params: {id: orderId}})
            }
        }

        get isGuide() {
            return authStore.isGuide
        }

        get isDriver() {
            return authStore.isDriver
        }
    }
</script>
