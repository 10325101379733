<template>
    <div>
        <v-tabs
            v-if="(activeProducts.length > 0 || tabLinks.length > 0) && !row"
            ref="tabs"
            v-model="productTabs"
            show-arrows
            color="black"
            background-color="transparent"
            slider-color="white"
            light
            style="
                margin-left: -4px;
                margin-bottom: -8px;
                padding-left: 3px;
                padding-bottom: 3px;
                z-index: 10;
                position: absolute;
                top: -46px;
            "
            @change="change"
        >
            <v-tab
                v-for="product in activeProducts"
                :key="product + '_tab'"
                class="v-tab-custom"
                style="
                    text-transform: none;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
                    background-color: white;
                    border-left: 1px solid rgba(0, 0, 0, 0.03);
                    border-bottom: none;
                    width: 128px;
                    height: 60px;
                    margin-top: 2px;
                    border-radius: 7px 7px;
                    position: relative;
                "
            >
                <div class="d-flex align-center pb-3">
                    <v-icon class="me-2" color="primary">
                        {{ products[product].ico }}
                    </v-icon>
                    <span class="font-weight-medium">{{ $t(products[product].title) }}</span>
                </div>
            </v-tab>
            <search-form-tab-link
                v-for="tab in tabLinks"
                :key="tab.name"
                :name="tab.name"
                :href="tab.href"
                :ico="tab.ico"
            />
        </v-tabs>
        <choose-agent-dialog v-if="selectAgentEnabled && !selectedAgent && chooseAgent" />
        <v-tabs-items v-model="productTabs" class="overflow--visible">
            <v-tab-item v-for="(product, index) in activeProducts" :key="index">
                <component
                    :is="products[product].form"
                    :variant="products[product].variant"
                    :product-name="products[product].title"
                    :city-id="cityId"
                    :row="row"
                    :dark="dark"
                    :class="{'product-tabs': activeProducts.length > 1}"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import SearchFormTabs from '@/components/SearchFormTabs'

    @Component
    export default class SearchFormTabsMod extends SearchFormTabs {
        @Prop({default: false, type: Boolean}) row
        @Prop({default: false, type: Boolean}) dark

        get products() {
            return {
                ...SearchFormTabs.options.computed.products.get.call(this),
                hotels: {
                    ico: 'mdi-bed-outline',
                    title: 'hotels',
                    form: 'hotel-search-form',
                },
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-tabs > .v-tabs-bar {
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }

        .theme--light.v-tabs-items {
            background-color: transparent;
        }

        .v-card.main-form {
            width: auto;

            &.product-tabs.theme--light.v-card.v-sheet--outlined {
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }

        .v-tab.v-tab-custom:before {
            border-radius: 6px !important;
        }
    }
</style>
