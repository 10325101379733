<template>
    <order-summary-layout v-if="offer" :product-store="productStore" :total-price="totalPrice">
        <template v-slot>
            <div class="text-h6">
                {{ $t('flights.flight_details') }}
            </div>
            <flight-itinerary v-for="(itinerary, index) in offer.itinerary" :key="index" :itinerary="itinerary" />
            <v-dialog v-model="detailModal" max-width="600px" :fullscreen="$breakpoint.smAndDown">
                <template v-slot:activator="{on}">
                    <v-btn text color="info" small v-on="on">
                        {{ $t('flights.view_details') }}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-end">
                        <v-icon @click="detailModal = false">mdi-close</v-icon>
                    </v-card-title>
                    <flight-detail :offer="offer" />
                </v-card>
            </v-dialog>
            <fare-rules :offer-key="offer.offerKey" />
            <slot name="summary" />
            <flight-conditions-label
                :offer-key="offer.offerKey"
                :price="offer.price"
                :refundable="offer.refundable"
                :regular="offer.regular"
                class="mt-3 text-caption"
                @conditions="showConditionsInfo"
            />
            <div v-if="extraBaggageCount" class="d-flex mt-5">
                <span class="caption">{{ $t('flights.extra_baggage') }}</span>
                <strong class="ml-auto pl-2">
                    {{ extraBaggageCount }} {{ $tc('flights.baggage_item', extraBaggageCount) }}
                    <template v-if="extraBaggagePrice.amount">({{ extraBaggagePrice | price }})</template>
                </strong>
            </div>
            <div v-if="seatsCount" class="d-flex mt-5">
                <span class="caption">{{ $t('flights.selected_seats') }}</span>
                <strong class="ml-auto pl-2">
                    {{ seatsCount }}
                    {{ $tc('flights.seat', seatsCount) }}
                    {{ seats.map(({rowNumber, seatNumber}) => `${rowNumber}${seatNumber}`).join(', ') }}
                    <template v-if="seatsPrice.amount">({{ seatsPrice | price }})</template>
                </strong>
            </div>
            <conditions-modal>
                <template v-slot:info>
                    <flight-conditions-modal-info />
                </template>
            </conditions-modal>
        </template>
        <template v-slot:violatedPolicies>
            <corporate-policy-violation v-if="corporatePolicyViolated" :policies="violatedPolicies" />
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import {flightsStore, persistentStore} from '~/store'
    import FareRules from '~/components/flights/FareRules'
    import FlightConditionsLabel from '@/components/flights/snippets/FlightConditionsLabel'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import {EventBus, SHOW_FLIGHT_CONDITIONS_INFO} from '@/utils/event-bus'
    import FlightConditionsModalInfo from '@/components/flights/snippets/FlightConditionsModalInfo'
    import FlightDetail from '~/components/flights/search/offers/FlightDetail'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import FlightBookingExtraMixin from '@/components/flights/booking/mixins/FlightBookingExtraMixin.vue'
    import {sumPrice} from '@/utils/api-helpers'

    @Component({
        components: {
            OrderSummaryLayout,
            FlightConditionsModalInfo,
            ConditionsModal,
            FlightConditionsLabel,
            FlightItinerary,
            FlightDetail,
            FareRules,
            CorporatePolicyViolation,
        },
    })
    export default class FlightOrderSummary extends FlightBookingExtraMixin {
        @Prop({required: true}) offer
        @Prop({default: true}) prebooked
        @Prop({required: true}) flightSeatsMap
        @Prop({required: true}) extraBaggageOptions

        detailModal = false

        showConditionsInfo() {
            EventBus.$emit(SHOW_FLIGHT_CONDITIONS_INFO, this.offer)
        }

        get productStore() {
            return flightsStore
        }

        get violatedPolicies() {
            return this.offer.corporatePolicy
        }

        get corporatePolicyViolated() {
            return this.offer.corporatePolicyViolation
        }

        get currency() {
            return persistentStore.getCurrency(this.offer.price.currency)
        }

        get prepareBookRequest() {
            return flightsStore.prepareBookRequest
        }

        get tourists() {
            return persistentStore.tourists
        }

        get totalPrice() {
            const totalPrice = flightsStore.orderTotalPrice(this.offer, this.prebooked)
            if (this.prebooked) return totalPrice
            return sumPrice([totalPrice, this.extraBaggagePrice, this.seatsPrice])
        }

        get seatsCount() {
            return flightsStore.prepareBookRequest.seats ? flightsStore.prepareBookRequest.seats.length : 0
        }

        get seats() {
            return flightsStore.prepareBookRequest.seats
        }

        get extraBaggageCount() {
            return flightsStore.prepareBookRequest.extraBaggage
                ? flightsStore.prepareBookRequest.extraBaggage.reduce(
                      (count, extraBaggage) => count + extraBaggage.quantity,
                      0
                  )
                : 0
        }
    }
</script>
