<template>
    <v-select
        v-model="value"
        :items="nightsItems"
        :prefix="`${$tc('night_qty', 5)}:`"
        background-color="white"
        :menu-props="{bottom: true, offsetY: true}"
        type="number"
        single-line
        filled
        class="text-capitalize"
        hide-details
        :dense="dense"
        :outlined="outlined"
        :prepend-inner-icon="showIcon ? 'mdi-weather-night' : null"
        :class="['input--no-arrows', {' caption': $breakpoint.smAndDown}]"
    />
</template>

<script>
    import {Component, PropSync, Vue} from 'nuxt-property-decorator'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class AgeSelect extends Vue {
        @PropSync('_value') value
        @Prop({default: true}) showIcon
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) outlined

        get nightsItems() {
            return [...Array(31).keys()]
        }
    }
</script>
