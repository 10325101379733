<template>
    <product-card-layout
        :thumb="product.mainImage"
        :name="product.info.name"
        :product-link="productLink"
        :offers-count="product.offers && product.offers.length"
        :expanded="expanded"
        :expanded-limit="expandedLimit"
        :is-recommended="product.recommended"
    >
        <template v-slot:wishlist>
            <wishlist-like
                v-if="person"
                :info="{
                    productType: 'activity',
                    mainImage: product.mainImage,
                    shortDescription: product.shortDescription,
                    info: {
                        name: product.info.name,
                        id: product.info.id,
                    },
                    offers: [],
                    searchRequest,
                }"
                :query="{id: product.info.id, productType: 'activity'}"
                :person="person"
                :product-runtime-store="productRuntimeStore"
                :in-wishlist="product.wishlist"
            />
        </template>
        <template v-slot>
            <div>
                <div class="d-flex align-center text-truncate">
                    <nuxt-link no-prefetch :to="productLink" class="me-2 font-weight-medium text-decoration-none">
                        {{ product.info.name }}
                    </nuxt-link>
                    <v-chip v-if="product.type" label x-small>
                        {{ $t(`filters_activityType.${product.type}`) }}
                    </v-chip>
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" class="mb-2" />
                <div
                    v-if="product.shortDescription"
                    class="short-description"
                    v-html="$options.filters.stripHtml(product.shortDescription)"
                />
                <div class="mt-3 date-filter">
                    <activity-date-filter v-if="expanded" v-model="dateFilter" :offers="product.offers" />
                </div>
                <activity-duration
                    v-if="product.offers[0]"
                    :days="product.offers[0].durationDays"
                    :time="product.offers[0].durationTime"
                    class="mt-auto"
                />
                <div><slot /></div>
            </div>
        </template>
        <template v-if="minPriceOffer" v-slot:priceCol>
            <slot name="price" :offer="minPriceOffer" :offer-key="minPriceOffer.offerKey">
                <activity-card-price-block :offer="minPriceOffer" />
                <v-btn depressed block color="primary" class="my-2" nuxt :to="productLink">
                    {{ $t('book') }}
                </v-btn>
            </slot>
            <div class="primary--text cursor-pointer text-no-wrap" @click="toggleOffers">
                <span>
                    {{ expanded ? $t('hide') : $t('show') }}
                </span>
                <v-icon color="primary">
                    {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </div>
        </template>
        <template v-if="product.offers.length" v-slot:offers>
            <div v-if="expanded" class="mt-3">
                <activity-grouped-offers :offers="product.offers" :date-filter="dateFilter" :hide-price="hidePrice">
                    <template v-slot:price="{offer, offerKey}">
                        <slot name="price" :offer="offer" :offer-key="offerKey" />
                    </template>
                </activity-grouped-offers>
                <template v-if="groupOffersBy === 'none'">
                    <activity-offer-card
                        v-for="(offer, offerIndex) in sortedOffers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="false"
                        :data-cy="`activity-offer-card-${offerIndex}`"
                    >
                        <template v-slot:price>
                            <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                        </template>
                    </activity-offer-card>
                    <v-list-item
                        v-if="expanded === expandedLimit && filteredOffers.length > expandedLimit"
                        dense
                        class="d-flex justify-center"
                        @click.stop="
                            expanded = product.offers.length
                            expandedLimit = product.offers.length
                        "
                    >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item>
                </template>
            </div>
        </template>
    </product-card-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityGroupedOffers from '@/components/activities/search/offers/ActivityGroupedOffers'
    import {activitiesRuntimeStore, activitiesStore, authStore} from '@/utils/store-accessor'
    import ActivityCardPriceBlock from '~src/components/activities/search/offers/activityCardPriceBlock.src'
    import ActivityDateFilter from '@/components/activities/snippets/ActivityDateFilter'
    import ProductCardLayout from '~/components/search/ProductCardLayout'
    import ActivityDuration from '@/components/activities/search/offers/ActivityDuration'
    import WishlistLike from '@/components/search/offers/WishlistLike'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    const sortFns = {
        priceDesc: (a, b) => a.price.amount - b.price.amount,
        priceAsc: (a, b) => b.price.amount - a.price.amount,
    }

    @Component({
        components: {
            WishlistLike,
            ActivityDuration,
            ActivityDateFilter,
            ActivityGroupedOffers,
            CommissionAmount,
            DiscountLabel,
            ActivityOfferCard,
            ActivityCardPriceBlock,
            ProductCardLayout,
            SupplierNameLabel,
        },
    })
    export default class ActivityCard extends Vue {
        @Prop({required: true}) product
        @Prop({required: true, type: Object}) searchRequest
        @Prop({default: false, type: Boolean}) hidePrice

        expanded = 0
        expandedLimit = 3
        imgError = false
        dateFilter = null

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }

        get filteredOffers() {
            let offers = [...this.product.offers]
            if (this.dateFilter) {
                offers = offers.filter(offer => offer.date === this.dateFilter)
            }
            return offers
        }

        get sortedOffers() {
            return this.filteredOffers.sort(sortFns[this.sortKey]).slice(0, this.expanded)
        }

        get minPriceOffer() {
            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        get groupOffersBy() {
            return activitiesStore.groupOffersBy
        }

        get sortKey() {
            return activitiesStore.sortFnName
        }

        get productLink() {
            return activitiesRuntimeStore.infoPageLink(this.product, this.searchRequest)
        }

        get productRuntimeStore() {
            return activitiesRuntimeStore
        }

        get person() {
            return authStore.person
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    .short-description {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .date-filter {
        max-width: 300px;
    }
</style>
