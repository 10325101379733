<template>
    <div class="d-flex align-center">
        <v-autocomplete
            ref="input"
            v-model="location"
            class="me-3"
            :items="items"
            hide-no-data
            hide-selected
            dense
            :placeholder="$t('search_on_map')"
            no-filter
            clearable
            return-object
            single-line
            outlined
            filled
            hide-details
            background-color="white"
            @change="change"
            @click:clear="clear"
            @update:search-input="onSearch"
        >
            <template v-slot:append>
                <v-icon v-if="!loading">mdi-magnify</v-icon>
                <v-progress-circular v-else indeterminate size="24" color="grey" />
            </template>
            <template v-slot:item="{item}">
                <span v-text="item.formatted_address" />
            </template>
            <template v-slot:selection="{item}">
                {{ item.formatted_address }}
            </template>
        </v-autocomplete>
        <v-btn color="white" @click="!circle ? setCircle(cityLocation) : clear()">
            <v-icon>{{ !circle ? `mdi-circle-double` : 'mdi-close-circle' }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {EventBus, SEARCH_EVENT} from '@/utils/event-bus'
    import HotelMapMixin from '@/components/hotels/mixins/HotelMapMixin'

    const centerFilter = center => {
        const runtimeFilters = clone(hotelsRuntimeStore.runtimeFilters)
        runtimeFilters.map.center = center
        hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
        hotelsRuntimeStore.filter()
    }

    @Component
    export default class HotelMapLocationFilter extends mixins(HotelMapMixin) {
        @Prop({required: true}) google
        @Prop({required: true}) map
        @Prop({required: true}) city

        timer = null
        items = []
        loading = false
        location = null
        circle = null
        marker = null

        created() {
            EventBus.$on(SEARCH_EVENT, this.clear)
        }

        beforeDestroy() {
            EventBus.$off(SEARCH_EVENT, this.clear)
        }

        onSearch(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async initSearch(val) {
            if (this.loading || (this.items.length > 0 && this.items.indexOf(val) !== -1) || !val) return
            this.loading = true
            const location = this.cityLocation
            /*await this.$axios.$get('https://maps.googleapis.com/maps/api/place/findplacefromtext/json', {
                params: {
                    input: val,
                    locationbias: {
                        point: `${location.lat},${location.lng}`,
                        radius: 10000,
                    },
                    key: this.$config.googleapisKey,
                    language: this.$i18n.locale,
                    fields: ['name', 'formatted_address', 'geometry'],
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            })*/
            const service = new this.google.maps.places.PlacesService(this.map)
            service.textSearch(
                {
                    query: val,
                    radius: 30000,
                    location,
                },
                (results, status) => {
                    if (status === this.google.maps.places.PlacesServiceStatus.OK) {
                        this.items = results
                    }
                    this.loading = false
                }
            )
        }

        change() {
            if (this.location) {
                const center = this.location.geometry.location.toJSON()
                this.setCircle(center)
            }
            this.$refs.input.blur()
        }

        setCircle(center) {
            this.circle?.setMap(null)
            this.marker?.setMap(null)
            this.circle = new this.google.maps.Circle({
                strokeColor: this.$vuetify.theme.themes.light.primary,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: this.$vuetify.theme.themes.light.primary,
                fillOpacity: 0.35,
                center,
                radius: hotelsRuntimeStore.runtimeFilters.map.radius,
                editable: true,
            })
            this.circle.setMap(this.map)
            centerFilter(center)
            this.marker = new this.google.maps.Marker({
                position: center,
                icon: this.createMarkerIcon(),
                map: this.map,
            })
            this.circle.addListener('radius_changed', () => {
                const runtimeFilters = clone(hotelsRuntimeStore.runtimeFilters)
                runtimeFilters.map.radius = this.circle.radius
                hotelsRuntimeStore.SET_RUNTIME_FILTERS(runtimeFilters)
                hotelsRuntimeStore.filter()
            })
            this.circle.addListener('center_changed', () => {
                this.location = null
                const center = this.circle.center.toJSON()
                centerFilter(center)
                this.marker.setPosition(center)
            })
        }

        clear() {
            if (!this.circle) return
            this.circle.setMap(null)
            this.circle = null
            this.marker.setMap(null)
            this.marker = null
            centerFilter(null)
        }

        markerColor() {
            return this.$vuetify.theme.themes.light.success
        }

        get cityLocation() {
            return {lat: this.city.latitude, lng: this.city.longitude}
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .v-input {
        width: 15vw;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            width: 60vw;
        }
    }
</style>
