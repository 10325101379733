<template>
    <div v-if="hotel">
        <strong class="d-block">{{ hotel.name }}</strong>
        <strong class="d-block">{{ room.name }}</strong>
        <strong class="d-block">
            {{ $t('check_in') }} / {{ $t('check_out') }}: {{ searchRequest.startDate | dateFormat }} -
            {{ searchRequest.endDate | dateFormat }}
        </strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_HOTEL_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class HotelConditionsModalInfo extends Vue {
        searchRequest = null
        hotel = null
        room = null

        mounted() {
            EventBus.$on(SHOW_HOTEL_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_HOTEL_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(searchRequest, hotel, room) {
            this.searchRequest = searchRequest
            this.hotel = hotel
            this.room = room
        }
    }
</script>
