<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" offset-y attach :max-width="300">
        <template v-slot:activator="{on}">
            <v-text-field
                :value="roomsLabel"
                :single-line="singleLine"
                :outlined="outlined"
                filled
                :background-color="backgroundColor"
                :rounded="rounded"
                :prepend-inner-icon="showIcon ? prependInnerIcon : null"
                hide-details
                readonly
                :dense="dense"
                data-cy="rooms-selector"
                :label="getLabel"
                :placeholder="$tc('room', 2).charAt(0).toUpperCase() + $tc('room', 2).slice(1)"
                :class="{caption: $breakpoint.smAndDown}"
                v-on="on"
            />
        </template>
        <v-card
            class="rooms-drop-down"
            max-height="425"
            color="white"
            :width="maxWidth || 'fit-content'"
            :max-width="maxWidth || 'fit-content'"
        >
            <rooms-select-list v-model="rooms">
                <v-btn color="primary" text @click="menu = false">OK</v-btn>
            </rooms-select-list>
        </v-card>
    </v-menu>
</template>

<script>
    import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator'
    import {authStore} from '~/store'
    import RoomsSelectList from '~src/components/search/forms/roomsSelectList.src'

    @Component({
        components: {
            RoomsSelectList,
        },
    })
    export default class RoomsSelect extends Vue {
        @Model('change') value
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: true}) showIcon
        @Prop({default: 'mdi-account'}) prependInnerIcon
        @Prop({default: 'white'}) backgroundColor
        @Prop({default: false, type: Boolean}) rounded
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: null}) maxWidth

        menu = false
        rooms = []

        @Watch('rooms')
        onChangeRooms(rooms) {
            this.$emit('change', rooms)
        }

        @Watch('value')
        onChangeValue(val) {
            this.rooms = val
        }

        beforeDestroy() {
            this.$refs.menu.activatorNode = null
        }

        removeRoom(index) {
            this.rooms.splice(index, 1)
        }

        addRoom() {
            this.rooms.push({adults: this.isDisabled ? 1 : 2, childrenAges: []})
        }

        get roomsLabel() {
            if (!this.rooms.length) return ''
            const touristsTotal = this.rooms.reduce((total, tourists) => {
                return total + tourists.adults + tourists.childrenAges.length
            }, 0)
            return `${this.rooms.length} ${this.$tc('room', this.rooms.length)} ${touristsTotal} ${this.$tc(
                'guest',
                touristsTotal
            )}`
        }

        get isDisabled() {
            return authStore.user.isEmployeeOrExternalCorp || this.rooms.length > 19
        }

        get getLabel() {
            return `${this.$tc('room', this.rooms.length)}`
        }
    }
</script>

<style scoped lang="scss">
    @media (max-width: 960px) {
        .rooms-drop-down {
            max-width: 100% !important;
            width: 100% !important;
        }
    }

    ::v-deep {
        .v-list-item__content {
            overflow: visible;
        }
    }
</style>
