<template>
    <v-form v-model="valid" class="mb-5">
        <v-checkbox v-model="termsOfUse" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="termsOfUseHtml" />
            </template>
        </v-checkbox>
        <v-checkbox v-model="privacyPolicy" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="privacyPolicyHtml" />
            </template>
        </v-checkbox>
        <div v-if="showHoldBooking" class="d-flex">
            <v-checkbox v-model="_dueToConfirmDateActive" hide-details class="me-2">
                <template v-slot:label>
                    <div class="confirmation-text" @click.stop>
                        {{ $t('need_hold_booking') }}
                    </div>
                </template>
            </v-checkbox>
            <vc-date-picker
                v-model="_dueToConfirmDate"
                :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm'}"
                :locale="$i18n.locale"
                :min-date="minDateTime"
                :max-date="initialDueToConfirmDate"
                :first-day-of-week="$config.firstDayOfWeek || undefined"
                mode="dateTime"
                is24hr
                is-required
            >
                <template v-slot="{inputEvents}">
                    <div v-on="_dueToConfirmDateActive && isTO1 ? inputEvents : null">
                        <v-text-field
                            :label="$t('prebooking_until')"
                            readonly
                            single-line
                            filled
                            hide-details
                            background-color="white"
                            prepend-inner-icon="mdi-av-timer"
                            :value="formattedDate"
                            dense
                            :disabled="!(_dueToConfirmDateActive && isTO1)"
                        />
                    </div>
                </template>
            </vc-date-picker>
        </div>
    </v-form>
</template>

<script>
    import {Component, Prop, PropSync, VModel, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class BookingAgreementForm extends Vue {
        @VModel() valid
        @PropSync('dueToConfirmDate') _dueToConfirmDate
        @PropSync('dueToConfirmDateActive') _dueToConfirmDateActive
        @Prop() countryId
        @Prop({required: true}) startDate
        @Prop({required: true}) selectedAddOns

        privacyPolicy = false
        termsOfUse = false
        initialDueToConfirmDate = null

        mounted() {
            this.initialDueToConfirmDate = this._dueToConfirmDate
        }

        get termsOfUseHtml() {
            return this.$t('confirmation_terms_of_use').replace('%s', `-${this.countryId}` || '')
        }

        get privacyPolicyHtml() {
            return this.$t('confirmation_privacy_policy').replace('%s', `-${this.countryId}` || '')
        }

        get isTO1() {
            return authStore.isTO1
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get formattedDate() {
            return this._dueToConfirmDate !== null ? this.$options.filters.dateTimeFormat(this._dueToConfirmDate) : ''
        }

        get showHoldBooking() {
            return (
                this._dueToConfirmDate &&
                this.$dateFns.isAfter(
                    this.$dateFns.parseISO(this._dueToConfirmDate),
                    this.$dateFns.subDays(new Date(), 1)
                ) &&
                this.$dateFns.isBefore(
                    this.$dateFns.parseISO(this._dueToConfirmDate),
                    this.$dateFns.addDays(this.$dateFns.parseISO(this.startDate), 1)
                ) &&
                this.selectedAddOns.length === 0 &&
                this.isTO1
            )
        }
    }
</script>

<style scoped lang="scss">
    .confirmation-text {
        font-size: 0.875rem;
    }
</style>
