<template>
    <component
        :is="tripadvisorRating && tripadvisorRating.reviewsURL ? 'a' : 'div'"
        :href="tripadvisorRating && tripadvisorRating.reviewsURL"
        target="_blank"
    >
        <v-chip color="warning" label dark>
            <span
                v-if="ratingAmount !== null"
                class="text-subtitle-2 secondary--text text-truncate me-1 font-weight-regular white--text"
            >
                {{ ratingAmount }}
            </span>
            <span
                v-if="ratingAmount !== null"
                class="text-subtitle-2 secondary--text text-truncate font-weight-regular white--text"
            >
                {{ ratingString }}
            </span>
        </v-chip>
    </component>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import TripadvisorRating from '~/components/hotels/snippets/TripadvisorRating'

    @Component
    export default class TripadvisorRatingMod extends TripadvisorRating {
        @Prop() ratings

        get ratingString() {
            if (this.ratingAmount > 4) return this.$t('hotelRating.excellent')
            if (this.ratingAmount > 3) return this.$t('hotelRating.very_good')
            if (this.ratingAmount > 2) return this.$t('hotelRating.good')
            if (this.ratingAmount > 1) return this.$t('hotelRating.satisfactory')
            return this.$t('hotelRating.sufficient')
        }

        get ratingAmount() {
            if (typeof this.tripadvisorRating?.rating === 'number') {
                return this.tripadvisorRating.rating
            }
            if (this.ratings?.length) {
                return this.ratings.find(el => el.ratingSource === 'INTERNAL')?.rating / 2 || this.ratings[0].rating / 2
            }

            return null
        }
    }
</script>
