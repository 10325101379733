<template>
    <v-checkbox
        ref="option"
        v-model="value"
        :disabled="disabled"
        :name="name"
        :label="label"
        clearable
        single-line
        hide-details
        :dark="dark"
        :false-value="canBeFalse ? false : undefined"
    />
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'

    @Component
    export default class CheckboxOption extends Vue {
        @Prop({required: true}) name
        @Prop() label
        @Prop({default: true, type: Boolean}) dark
        @Prop() defaultValue
        @Prop() valueName
        @Prop() disabled
        @Prop({default: false, type: Boolean}) canBeFalse

        value = null

        @Watch('value')
        onChange() {
            this.change()
        }

        @Emit()
        change() {
            return {name: this.name, value: this.value}
        }

        created() {
            if (typeof this.$route.query[this.name] !== 'undefined') {
                this.value = this.$route.query[this.name] + '' === 'true'
            } else {
                this.value = this.defaultValue
            }
        }
    }
</script>
