<template>
    <div>
        <div class="d-flex align-start justify-end full-width">
            <selected-filters
                class="full-width"
                :selected="filterValues"
                :translatable="[
                    'distance',
                    'mealTypes',
                    'availability',
                    'supplierCode',
                    'specialOffer',
                    'wishlist',
                    'cancellationPolicy',
                ]"
                @delete-filter="clearFilter"
            />
            <v-btn v-if="isMobile" icon class="mt-3" @click="closeFilters">
                <v-icon large> mdi-close </v-icon>
            </v-btn>
        </div>
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            class="filter"
            :filters="filters"
            :nights="nights"
            :currency="currency"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            filter="name"
            class="filter"
            :placeholder="$t('filterHotelNamePlaceholder')"
            @change="change($event)"
        />
        <category-filter
            v-if="filters.category && filters.category.length > 1"
            v-model="filterValues"
            :filters="filters"
            class="filter"
            :translate-labels="true"
            filter="category"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.type && filters.type.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="type"
            class="filter"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.distance && filters.distance.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="distance"
            class="filter"
            :translate-labels="true"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter-grouped
            v-if="filters.services && filters.services.length > 1"
            v-model="filterValues"
            :filters="filters"
            class="filter"
            filter="services"
            :panel-expanded="false"
            @change="change($event)"
        />
        <tripadvisor-filter
            v-if="filters.tripadvisorRating && filters.tripadvisorRating.length > 1"
            v-model="filterValues"
            :filters="filters"
            class="filter"
            :translate-labels="true"
            filter="tripadvisorRating"
            :panel-expanded="false"
            @change="change($event)"
        />
    </div>
</template>

<script>
    import {Component, Emit} from 'nuxt-property-decorator'
    import HotelFilters from '@/components/search/filters/HotelFilters'
    import CategoryFilter from '~~/mods/ajbooking/components/search/filters/CategoryFilter'

    @Component({
        components: {CategoryFilter},
    })
    export default class HotelFiltersMod extends HotelFilters {
        @Emit()
        closeFilters() {}

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
