<template>
    <v-card class="my-2" :elevation="!fullCard ? 0 : undefined" :outlined="fullCard">
        <v-list-item three-line :class="fullCard ? 'pl-0' : ''">
            <v-list-item-avatar v-if="fullCard" class="my-0" :size="!$breakpoint.smAndDown ? '125px' : '25%'" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="mainImage.url | imageUrl(200)"
                    :alt="info.serviceName"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && mainImage.url"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <div
                    class="wrap-words d-flex align-center text-subtitle-1 font-weight-bold cursor-pointer mb-1"
                    @click="offerInfo()"
                >
                    <v-icon left small>mdi-information-outline</v-icon>
                    <div>
                        {{ info.serviceName }}
                    </div>
                </div>
                <div>
                    <span v-if="offer.available" class="d-flex align-center caption">
                        <v-icon left :title="$t('available')" small class="success--text">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="success--text" v-text="$t('available')" />
                    </span>
                    <span v-else class="d-flex align-center mt-1 caption">
                        <v-icon left :title="$t('on_request')" small class="warning--text">mdi-alert-circle</v-icon>
                        <span class="warning--text" v-text="$t('on_request')" />
                    </span>
                </div>
                <discount-label v-if="offer.price" :price="offer.price" class="d-inline-block" />
                <div class="d-flex align-center caption">
                    <v-icon small left>mdi-calendar</v-icon>
                    <span>
                        {{ $options.filters.dateFormat(offer.startDate) }}
                    </span>
                </div>
                <span v-if="offer && offer.price" class="caption">
                    <conditions-label
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
                <div v-if="offer.info.tariff" class="d-flex align-center caption">
                    <v-icon left small class="info--text">mdi-tag-text</v-icon>
                    <span>
                        {{ offer.info.tariff }}
                    </span>
                </div>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center d-flex flex-column align-center">
                <slot name="price">
                    <v-btn
                        :loading="!info.productName"
                        :disabled="!info.productName"
                        color="primary"
                        height="36px"
                        max-height="36px"
                        block
                        nuxt
                        small
                        no-prefetch
                        data-cy="client-price"
                        @click="selectForBooking"
                    >
                        {{ offer.price | price }}
                    </v-btn>
                    <commission-amount :commission="offer.price.commission" />
                    <net-amount :price="offer.price" />
                </slot>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_EXTRA_SERVICE_CONDITIONS_INFO, SHOW_EXTRA_SERVICE_INFO_MODAL} from '@/utils/event-bus'
    import {authStore, extraServicesStore} from '~/store'
    import NetAmount from '@/components/snippets/NetAmount'

    @Component({
        components: {
            CommissionAmount,
            DiscountLabel,
            ConditionsLabel,
            NetAmount,
        },
    })
    export default class ExtraServiceOfferCard extends Vue {
        @Prop() offer
        @Prop({default: true}) fullCard
        @Prop({default: ''}) description

        imgError = false
        info = {
            images: [],
        }

        async mounted() {
            try {
                this.info = await this.$api.ownExtraServiceInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard) {
                    this.$emit('description', this.info.productDescription)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.info.description = this.description
            this.info.service = this.offer.info.service
            this.info.tariff = this.offer.info.tariff
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            extraServicesStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'extraServiceBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.offer)
        }

        get mainImage() {
            let mainImage = this.info.images?.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images?.length > 0) {
                mainImage = this.info.images[0]
            }
            if (
                !mainImage.linkedEntityName ||
                (mainImage.linkedEntityName && mainImage.linkedEntityName !== this.info.serviceName)
            ) {
                mainImage =
                    this.info.images?.find(image => image.linkedEntityName === this.info.serviceName) || mainImage
            }
            return mainImage
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
