<template>
    <div class="full-width">
        <hotel-room-name-label v-if="groupBy !== 'name'" :hotel="hotel" :room="room" :compare-mode="compareMode">
            <template v-slot>
                <div v-if="!hdi" class="d-flex align-center font-weight-medium cursor-pointer mb-3" @click="roomInfo">
                    <v-icon small left>mdi-information-outline</v-icon>
                    {{ room.name }}
                </div>
            </template>
        </hotel-room-name-label>
        <div class="d-flex flex-column flex-md-row">
            <div class="d-flex flex-md-row full-height" :class="{'flex-column': !hdi}">
                <v-img
                    v-if="groupBy !== 'name' && imgSrc"
                    tile
                    class="cursor-pointer mb-4 me-4"
                    :src="imgSrc | imageUrl(300)"
                    :alt="room.name"
                    eager
                    :max-width="hdi ? (isMobile ? 173 : 210) : 111"
                    :max-height="hdi ? (isMobile ? 129 : 156) : 83"
                    aspect-ratio="0.96"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @click="roomInfo"
                />
                <div class="caption d-flex flex-column">
                    <div
                        v-if="hdi"
                        class="subtitle-1 d-flex align-center font-weight-medium cursor-pointer mb-3"
                        @click="roomInfo"
                    >
                        {{ room.name }}
                    </div>
                    <div class="font-weight-light">
                        <special-offer-label
                            v-if="room.price && (room.specialOffer || room.price.discountDetails)"
                            :details="room.price.discountDetails"
                        />
                        <hotel-available-label :room="room" :icon-color="'warning'" class="mt-1" />
                        <hotel-meal-type-label
                            v-if="groupBy !== 'mealTypeStandardCode'"
                            light
                            :hotel="hotel"
                            :room="room"
                            class="mt-1"
                            :icon-color="'warning'"
                        />
                        <tariff-label :hotel="hotel" :room="room" :icon-color="'warning'" class="mt-1" />
                        <conditions-label
                            v-if="(room && room.price) || ($config.packagesNotDeltaPrice && room.notDeltaPrice)"
                            :offer-key="room.groupedOffers[0].offerKey"
                            :price="room.price || room.notDeltaPrice"
                            :offer="hotel"
                            :icon-color="'warning'"
                            class="mt-1"
                            @conditions="showConditionsInfo"
                        />
                    </div>
                    <div class="d-flex">
                        <div v-if="room.bedroomsQuantity" class="me-3">
                            <v-icon color="info" :title="$t('bedrooms_quantity')">mdi-bed-outline</v-icon>
                            {{ room.bedroomsQuantity }}
                        </div>
                        <div v-if="room.bathroomsQuantity" class="me-3">
                            <v-icon color="info" :title="$t('bathrooms_quantity')">mdi-shower</v-icon>
                            {{ room.bathroomsQuantity }}
                        </div>
                        <hotel-bed-type-label v-if="room.bedTypes && room.bedTypes.length" :bed-types="room.bedTypes" />
                    </div>
                    <div v-if="accommodationText" class="font-italic">
                        {{ accommodationText }}
                    </div>
                    <span
                        v-if="hdi"
                        class="mt-auto primary--text cursor-pointer ms-1 mb-4 pt-4 text-subtitle-2"
                        @click="roomInfo"
                    >
                        {{ $t('room_details') }}
                    </span>
                </div>
            </div>
            <div
                class="price-col ms-auto d-flex flex-column"
                :class="{'full-width': isMobile, 'mt-2': isMobile, 'justify-center': hdi}"
            >
                <template v-if="!isMultiRoom">
                    <slot name="price" :room="room">
                        <hotel-room-offer-price-block
                            :hdi="hdi"
                            :offer="hotel"
                            :room="room"
                            :search-request="searchRequest"
                        />
                    </slot>
                </template>
                <template v-else>
                    <hotel-room-offer-price-block
                        :offer="hotel"
                        :room="room"
                        :search-request="searchRequest"
                        :rph="rph"
                        class="me-4"
                    />
                </template>
            </div>
            <span
                v-if="room.price && room.price.discountDetails && room.specialOffer && isMobile"
                class="success--text caption font-weight-medium"
                >{{ room.price.discountDetails }}</span
            >
        </div>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import HotelRoom from '~/components/hotels/search/offers/HotelRoom'

    @Component
    export default class HotelRoomMod extends HotelRoom {
        @Prop({default: false, type: Boolean}) hdi
    }
</script>
