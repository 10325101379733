<template>
    <v-expansion-panels
        v-show="!hideExtraOptions"
        flat
        style="background-color: transparent"
        tile
        dark
        class="additional-options"
    >
        <v-expansion-panel style="background-color: transparent">
            <slot name="header">
                <v-expansion-panel-header class="pl-1 width-auto">
                    {{ $t('extra_options') }}
                </v-expansion-panel-header>
            </slot>
            <v-expansion-panel-content eager>
                <slot />
                <v-form ref="additional-form">
                    <v-row>
                        <v-col v-if="enabled('rating')" cols="12" md="2">
                            <select-option
                                name="category"
                                :items="ratingItems"
                                :label="$t('filterTitles.category')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                autocomplete-disabled
                                dark
                            />
                        </v-col>
                        <v-col v-if="enabled('meal')" cols="12" md="4" lg="3" xl="2">
                            <select-option
                                name="mealType"
                                :items="mealTypeItems"
                                :label="$t('filterTitles.mealTypes')"
                                multiple
                                :single-line="singleLine"
                                :outlined="outlined"
                                :clearable="false"
                                autocomplete-disabled
                                dark
                            />
                        </v-col>
                        <v-col v-if="enabled('flight-class')" cols="12" md="3">
                            <select-option
                                name="flightClass"
                                :items="flightClasses"
                                :label="$t('flights.options.class')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                :default-value="flightClassDefaultValue"
                                dark
                            />
                        </v-col>
                        <v-col v-if="enabled('airlines')" cols="12" md="3">
                            <select-option
                                name="airlineCodes"
                                :multiple="true"
                                :items="airlines"
                                :label="$t('flights.options.airlines')"
                                small-cheaps
                                :single-line="singleLine"
                                :outlined="outlined"
                                dark
                            />
                        </v-col>
                        <template v-if="enabled('flight-options')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="baggageOnly"
                                    :label="$t('flights.options.baggage_only')"
                                    :default-value="false"
                                />
                            </v-col>
                            <v-col cols="12" md="2" :class="$i18n.locale === 'ru' ? 'ref-up-on-small' : ''">
                                <checkbox-option
                                    name="refundableOnly"
                                    :label="$t('flights.options.refundable_only')"
                                    :default-value="false"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="directFlight"
                                    :label="$t('flights.options.direct_only')"
                                    :default-value="false"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('transfer-type')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="privateTransfer"
                                    :label="$t('transfers.private_transfer')"
                                    :default-value="true"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="sharedTransfer"
                                    :label="$t('transfers.shared_transfer')"
                                    :default-value="true"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('activity-type')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="privateActivity"
                                    :label="$t('activities.private_activity')"
                                    :default-value="true"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="sharedActivity"
                                    :label="$t('activities.shared_activity')"
                                    :default-value="true"
                                />
                            </v-col>
                        </template>
                        <v-col v-if="enabled('citizenship') && !isCitizenshipMainOption" cols="12" md="2">
                            <select-option
                                :key="`citizenshipId-${defaultCitizenshipId}`"
                                name="citizenshipId"
                                :items="citizenshipItems"
                                :label="$t('citizenship')"
                                :default-value="defaultCitizenshipId"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dark
                                @change="changeCitizenship"
                            />
                        </v-col>
                        <v-col v-if="enabled('driverCitizenship')" cols="12" md="2">
                            <select-option
                                name="driverCitizenshipId"
                                :items="citizenshipItems"
                                :label="$t('citizenship')"
                                :default-value="defaultCitizenshipId"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dark
                            />
                        </v-col>
                        <v-col v-if="enabled('promocode') && searchFormOptions.promoCode" cols="12" md="2">
                            <text-option
                                name="promoCode"
                                :label="$t('promocode')"
                                :single-line="singleLine"
                                :outlined="outlined"
                            />
                        </v-col>
                        <v-col v-if="enabled('passengers')" cols="12" md="2">
                            <adults-select name="passengers" :label="$t('carsRent.number_of_passengers')" />
                        </v-col>
                        <v-col v-if="enabled('availableOnly')" cols="12" md="2">
                            <checkbox-option
                                name="availableOnly"
                                :label="$t('carsRent.availableOnly')"
                                :default-value="false"
                            />
                        </v-col>
                        <v-col v-if="enabled('freeOnly')" cols="12" md="2">
                            <checkbox-option name="freeOnly" :label="$t('free_only')" :default-value="false" />
                        </v-col>
                        <v-col v-if="enabled('adultsOnly')" cols="12" md="2">
                            <checkbox-option
                                name="services"
                                :value-name="'ADULTS_ONLY'"
                                :label="$t('service.adults_only')"
                            />
                        </v-col>
                        <v-col v-if="searchFormOptions.showCommission && (isAgency || isTourOperator)" cols="12" md="2">
                            <checkbox-option
                                name="showCommission"
                                :label="$t('show_commission')"
                                :default-value="true"
                            />
                        </v-col>
                        <template v-if="isTO1">
                            <v-col
                                v-if="enabled('internalSuppliers')"
                                cols="12"
                                md="2 "
                                :style="{opacity: disabledInternalSupplier ? '0.5' : '1'}"
                            >
                                <checkbox-option
                                    ref="internalSuppliers"
                                    name="internalSuppliers"
                                    :default-value="true"
                                    can-be-false
                                    :disabled="disabledInternalSupplier"
                                    :label="$t('internal_suppliers')"
                                />
                            </v-col>
                            <v-col
                                v-if="enabled('externalSuppliers')"
                                cols="12"
                                md="2"
                                :style="{opacity: disabledExternalSupplier ? '0.5' : '1'}"
                            >
                                <checkbox-option
                                    ref="externalSuppliers"
                                    name="externalSuppliers"
                                    :default-value="true"
                                    can-be-false
                                    :label="$t('external_suppliers')"
                                    :disabled="disabledExternalSupplier"
                                />
                            </v-col>
                            <v-col v-if="enabled('supplierId') && supplierItems.length" cols="12" md="2">
                                <select-option
                                    name="supplierId"
                                    :items="supplierItems"
                                    :label="$t('supplier_id')"
                                    :single-line="singleLine"
                                    :outlined="outlined"
                                    dark
                                    @change="onSupplierItemChange"
                                />
                            </v-col>
                        </template>
                    </v-row>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {authStore, flightsStore, persistentStore, runtimeStore} from '~/store'
    import TextOption from '@/components/snippets/AdditionalSearchOptions/TextOption'
    import SelectOption from '@/components/snippets/AdditionalSearchOptions/SelectOption'
    import CheckboxOption from '@/components/snippets/AdditionalSearchOptions/CheckboxOption'
    import AdultsSelect from '@/components/snippets/AdditionalSearchOptions/AdultsSelect'

    @Component({
        components: {
            CheckboxOption,
            SelectOption,
            TextOption,
            AdultsSelect,
        },
    })
    export default class AdditionalOptionsWrapper extends Vue {
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false}) singleLine
        @Prop({default: true}) showIcon
        @Prop({default: () => []}) options
        @Prop({default: () => []}) suppliers

        promocode = null
        ratingItems = []
        mealTypeItems = []

        disabledInternalSupplier = false
        disabledExternalSupplier = false
        hideSupplierItems = false

        @Watch('locale')
        onLocaleChange() {
            this.setMealTypes()
            this.setRatings()
        }

        async mounted() {
            await this.$store.restored
            this.setMealTypes()
            this.setRatings()
            if (this.enabled('airlines')) {
                this.loadAirlines()
            }
        }

        @Emit()
        changeCitizenship(e) {
            return e.value
        }

        enabled(val) {
            return this.options.indexOf(val) >= 0
        }

        loadAirlines() {
            flightsStore.loadAirlines()
        }

        get flightClasses() {
            return [
                {
                    name: this.$t('flights.classes.ECONOMY'),
                    value: 'ECONOMY',
                },
                {
                    name: this.$t('flights.classes.PREMIUM_ECONOMY'),
                    value: 'PREMIUM_ECONOMY',
                },
                {
                    name: this.$t('flights.classes.FIRST'),
                    value: 'FIRST',
                },
                {
                    name: this.$t('flights.classes.BUSINESS'),
                    value: 'BUSINESS',
                },
            ]
        }

        setMealTypes() {
            this.mealTypeItems = [
                {
                    name: this.$t('filters_mealTypes.RO'),
                    value: 'RO',
                },
                {
                    name: this.$t('filters_mealTypes.BB'),
                    value: 'BB',
                },
                {
                    name: this.$t('filters_mealTypes.HB'),
                    value: 'HB',
                },
                {
                    name: this.$t('filters_mealTypes.FB'),
                    value: 'FB',
                },
                {
                    name: this.$t('filters_mealTypes.BBT'),
                    value: 'BBT',
                },
                {
                    name: this.$t('filters_mealTypes.HBT'),
                    value: 'HBT',
                },
                {
                    name: this.$t('filters_mealTypes.FBT'),
                    value: 'FBT',
                },
                {
                    name: this.$t('filters_mealTypes.ALL'),
                    value: 'ALL',
                },
            ]
        }

        setRatings() {
            this.ratingItems = Array.from(Array(5).keys())
                .map(key => {
                    return {
                        name: this.$tc('filters_category', key + 1) + ' ' + this.$t('and_more'),
                        value: (key + 1).toString(),
                    }
                })
                .sort((a, b) => {
                    return b.value - a.value
                })
        }

        setCitizenshipId(value) {
            this.citizenshipId = value
        }

        onSupplierItemChange(e) {
            if (e.value) {
                this.disabledInternalSupplier = true
                this.disabledExternalSupplier = true

                if (this.$refs?.externalSuppliers) {
                    this.$refs.externalSuppliers.value = true
                }
                if (this.$refs?.internalSuppliers) {
                    this.$refs.internalSuppliers.value = true
                }
            } else {
                this.disabledInternalSupplier = false
                this.disabledExternalSupplier = false
            }
        }

        get isTO1() {
            return authStore.isTO1
        }

        get flightClassDefaultValue() {
            return this.flightClasses.find(e => e.value === this.searchFormOptions.flightClassDefault)?.value
        }

        get isCitizenshipMainOption() {
            return this.$config.isCitizenshipMainOption
        }

        get citizenshipItems() {
            return runtimeStore.restrictedCountries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }

        get locale() {
            return this.$i18n.locale
        }

        get defaultCitizenshipId() {
            return (
                persistentStore.selectedAgent?.companyCountryId ||
                authStore.person?.citizenshipId ||
                this.$config.defaultCitizenshipId
            )
        }

        get searchFormOptions() {
            return this.$config.searchFormOptions
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get supplierItems() {
            return this.suppliers.map(el => ({
                name: el.name,
                value: el.id,
            }))
        }

        get airlines() {
            return flightsStore.airlines.map(({codes, name}) => {
                return {
                    name: name[this.$i18n.locale] || name['en'],
                    value: codes.IATA,
                }
            })
        }

        get hideExtraOptions() {
            return false
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 1350px) and (max-width: 1450px) {
        .ref-up-on-small {
            margin-top: -8px;
        }
    }

    .additional-options form {
        margin-top: -20px;
    }
</style>
