<template>
    <v-form v-model="valid">
        <v-row>
            <v-col cols="12">
                <div class="d-flex flex-column flex-md-row">
                    <div v-if="editMode" class="d-flex flex-column">
                        <div class="d-flex">
                            <span> {{ `${$t('created_by')}: ` }} &nbsp; </span>
                            <span> {{ editNoteInfo.created }} &nbsp; </span>
                            <span>
                                {{ editNoteInfo.createdUser }}
                            </span>
                        </div>
                        <div class="d-flex">
                            <span> {{ `${$t('modified_by')}: ` }} &nbsp; </span>
                            <span> {{ editNoteInfo.modified }} &nbsp; </span>
                            <span>
                                {{ editNoteInfo.modifiedUser }}
                            </span>
                        </div>
                    </div>
                    <v-spacer />
                    <v-checkbox v-model="info.active" :label="`${$t('active')}`" />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea
                    v-model="info.description"
                    outlined
                    :label="`${$tc('internal_note', 1)}`"
                    persistent-placeholder
                    :rules="[requiredRule('note')]"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-select
                    v-if="!specifiedService"
                    v-model="info.processId"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="relatedToServices"
                    :label="`${$t('relates_to')}*`"
                />
                <template v-if="specifiedService || info.processId">
                    <div class="mt-5 text-subtitle-1">{{ $t('show_in') }}:</div>
                    <v-checkbox v-model="info.showInInvoice" :label="$t('invoice')" hide-details />
                    <v-checkbox v-model="info.showInQuotation" :label="$t('quotation')" hide-details />
                </template>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync, VModel} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class InternalNoteEditForm extends mixins(FormValidationRulesMixin) {
        @VModel() info
        @Prop() editMode
        @Prop() services
        @Prop() specifiedService
        @Prop() editNoteInfo
        @PropSync('_valid', {default: true}) valid

        created() {
            if (!this.info.communicationDateTime) {
                this.info.communicationDateTime = this.$dateFns.format(new Date(), "yyyy-MM-dd'T'HH:mm")
            }
            if (!this.info.processId) {
                this.info.processId = this.relatedToServices[0].value
            }
        }

        get relatedToServices() {
            if (this.specifiedService) {
                const {serviceName, processId} = this.specifiedService
                return [{name: serviceName, value: processId}]
            }
            const itemsByServices = this.services.map(e => ({name: e.serviceName, value: e.processId}))
            return [{name: this.$t('reservation'), value: null}, ...itemsByServices]
        }
    }
</script>
