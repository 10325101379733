<template>
    <v-card outlined>
        <v-card-title>
            <v-icon left color="primary">mdi-star</v-icon>
            {{ $t('extra_services') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-row v-if="mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-bold">
                        {{ $t('extra_services_included') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in mandatoryExtraServices"
                :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
                :extra-service="extraService"
                :hide-action="true"
            />
            <v-row v-if="extraServices.length && mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-bold">
                        {{ $t('extra_services') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in extraServices"
                :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
                :extra-service="extraService"
                :hide-action="isDisabled"
                @update="setExtraService"
            />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import ExtraService from '~src/components/booking/forms/fields/extraService.src'

    @Component({
        components: {ExtraService},
    })
    export default class ExtraServicesForm extends Vue {
        @Prop() roomIndex
        @Prop() pStore
        @Prop({default: false}) isDisabled

        setExtraService(data) {
            const type = `extra-service-${data.service.serviceRPH}`
            if (!data.operation) {
                this.pStore.DELETE_EXTRA_SERVICE(type)
            } else {
                this.pStore.SET_BOOKING_EXTRA_SERVICE({
                    type,
                    name: data.service.name,
                    serviceRPH: data.service.serviceRPH,
                    quantity: parseInt(data.qty),
                    price: data.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT'),
                    mandatory: data.service.mandatory,
                })
            }
            this.$emit('updateExtraServices')
        }

        get extraServices() {
            if (!this.pStore.bookingAdditionalOptions.extraServices) {
                return []
            }
            return (
                this.pStore.bookingAdditionalOptions.extraServices.filter(extraService => !extraService.mandatory) || []
            )
        }

        get mandatoryExtraServices() {
            if (!this.pStore.bookingAdditionalOptions.extraServices) {
                return []
            }
            return (
                this.pStore.bookingAdditionalOptions.extraServices.filter(extraService => extraService.mandatory) || []
            )
        }
    }
</script>
