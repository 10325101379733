<template>
    <div>
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            label="filters.insurance"
            filter="insuranceName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            label="filters.insurance"
            filter="insuranceDescription"
            @change="change($event)"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {filters} from '@/utils/insurance/insurance-blank-states'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import NameFilter from '~src/components/filters/nameFilter.src'
    import FiltersBase from '@/components/search/FiltersBase'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'

    @Component({
        components: {
            CheckboxFilter,
            PriceFilter,
            NameFilter,
        },
    })
    export default class InsuranceFilters extends FiltersBase {
        @Prop({required: true, default: () => filters()}) filterValues
    }
</script>
