<template>
    <div>
        <v-tabs v-if="activeProducts.length > 1" v-model="productTabs" :show-arrows="false" style="position: relative">
            <v-tab v-for="product in activeProducts" :key="product + '_tab'">
                <v-icon class="mr-1">
                    {{ products[product].ico }}
                </v-icon>
                <span class="hidden-sm-and-down">{{ $t(products[product].title) }}</span>
            </v-tab>
            <nuxt-link
                v-if="showLink"
                no-prefetch
                :to="{name: 'supplierProducts'}"
                style="position: absolute; right: 0; top: 12px"
            >
                <strong>{{ $t('full_view') }}</strong>
            </nuxt-link>
        </v-tabs>
        <v-tabs-items v-model="productTabs">
            <v-tab-item v-for="product in activeProducts" :key="product + '_table'">
                <v-data-table
                    :headers="contractsHeaders"
                    :items="getContracts(product)"
                    class="elevation-1"
                    :items-per-page="limit"
                >
                    <template v-slot:item.name="{item}">
                        <nuxt-link no-prefetch :to="{name: 'supplierContract', params: {contractId: item.id}}">
                            <strong>{{ item.name }}</strong>
                        </nuxt-link>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small class="mr-2" @click="toRoute('chart')">mdi-chart-areaspline-variant</v-icon>
                        <v-icon small class="mr-2" @click="toRoute('info')">mdi-information-outline</v-icon>
                        <v-icon small class="mr-2" @click="toRoute('prices')">mdi-sale</v-icon>
                        <nuxt-link no-prefetch :to="{name: 'supplierContract', params: {contractId: item.id}}">
                            <v-icon small>mdi-calendar-month</v-icon>
                        </nuxt-link>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, supplierStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class Contracts extends Vue {
        @Prop({default: true}) showLink
        @Prop({default: 5}) limit

        productTabs = 0
        loaded = false

        async mounted() {
            await supplierStore.loadContracts()
            this.loaded = true
        }

        getContracts(product) {
            switch (product) {
                case 'hotels':
                    return supplierStore.contracts.filter(
                        contract => contract.serviceType === 'ownHotel' && contract.active
                    )
                case 'flights':
                    return supplierStore.contracts.filter(
                        contract => contract.serviceType === 'ownCharter' && contract.active
                    )
                case 'activities':
                    return supplierStore.contracts.filter(
                        contract => contract.serviceType === 'ownExcursion' && contract.active
                    )
                case 'transfers':
                    return supplierStore.contracts.filter(
                        contract => contract.serviceType === 'ownTransfer' && contract.active
                    )
            }
        }

        get products() {
            return {
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                },
                flights: {
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                },
                packages: {
                    ico: 'mdi-wallet-travel',
                    title: 'packages.packages',
                },
                extraServices: {
                    ico: 'mdi-star-box',
                    title: 'extraServices.extra_services',
                },
            }
        }

        get contractsHeaders() {
            return [
                {text: this.$t('name'), value: 'name'},
                {text: this.$t('country'), value: 'country'},
                {text: this.$t('city'), value: 'city'},
                {text: '', value: 'actions', sortable: false},
            ]
        }

        get activeProducts() {
            return clone(authStore.availableProducts)
                .sort()
                .map(product => {
                    switch (product) {
                        case 'accommodation':
                            return 'hotels'
                        case 'flight':
                            return 'flights'
                        case 'transfer':
                            return 'transfers'
                        case 'excursion':
                            return 'activities'
                        /*case 'dynamicTour':
                            return 'packages'
                        case 'ownExtraService':
                            return 'extraServices'*/
                        default:
                            return null
                    }
                })
                .filter(product => {
                    return product != null
                })
        }
    }
</script>
