<template>
    <v-dialog v-model="modal" hide-overlay :persistent="loading">
        <v-card>
            <v-card-title class="white--text primary">
                {{ serviceDetails.cruiseName }}
            </v-card-title>
            <v-card-text v-if="bookingFieldsLoading" class="d-flex full-width align-center justify-center pa-8">
                <v-progress-circular color="primary" indeterminate size="64" />
            </v-card-text>
            <template v-else>
                <v-card-text>
                    <div class="my-5">
                        <template v-for="(tourist, index) in tourists">
                            <div :key="index">
                                <tourist-form
                                    :ref="`tourist${index}`"
                                    v-model="tourists[index]"
                                    :index="index"
                                    label="passenger"
                                    :disabled="loading"
                                    :hide-index="tourists.length === 1"
                                    :selected-tourists="tourists"
                                    :start-date="startDate"
                                    @person-selected="personSelected($event)"
                                />
                                <tourist-passport-form :index="index" expanded-all @set-prop="onTouristsChange" />
                            </div>
                        </template>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div @click="validateTouristsWithErrors">
                        <v-btn
                            color="primary"
                            :disabled="!allTouristsValid || loading"
                            :loading="loading"
                            @click="save"
                        >
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                    <v-btn text :disabled="loading" @click="modal = false">{{ $t('cancel') }}</v-btn>
                </v-card-actions>
            </template>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, mixins, Prop, VModel, Watch} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import BookingTouristsMixin from '@/components/booking/mixins/BookingTouristsMixin'
    import TouristForm from '~src/components/booking/forms/touristForm.src'
    import CruisesPageMixin from '@/components/cruises/mixins/CruisesPageMixin.vue'
    import FillServiceBookingDataMixin from '@/components/account/mixins/FillServiceBookingDataMixin'
    import TouristPassportForm from '@/components/booking/forms/TouristPassportForm'

    //TODO Add train stations
    @Component({
        components: {
            TouristPassportForm,
            TouristForm,
        },
    })
    export default class FillCruiseBookingDataModal extends mixins(
        BookingTouristsMixin,
        CruisesPageMixin,
        FillServiceBookingDataMixin
    ) {
        @VModel() modal
        @Prop({required: true}) service
        @Prop({required: true}) order

        loading = false
        allTouristsValid = false

        @Watch('modal')
        async onModalOpen() {
            if (this.modal) {
                this.setTourists()
                await this.loadTouristBookingFields()
                this.$nextTick(() => {
                    this.setAllTouristsValid()
                })
            }
        }

        @Watch('tourists', {deep: true})
        async onTouristsChange() {
            await this.$nextTick(() => {})
            this.setAllTouristsValid()
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        async save() {
            try {
                this.loading = true
                const touristsWithoutPassport = this.tourists.map((t, i) => {
                    return {...t, passport: persistentStore.tourists[i].passport}
                })
                await this.$api.fillCruiseBookingData.post({
                    tourists: this.prepareBookTourists(touristsWithoutPassport),
                    processId: this.service.processId,
                })
                await runtimeStore.refreshOrder()
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.modal = false
                this.loading = false
            }
        }

        setAllTouristsValid() {
            const validation = []

            this.tourists.forEach((t, i) => {
                validation.push(this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.validate())
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.resetValidation()
            })

            this.allTouristsValid = validation.every(el => el === true)
        }

        validateTouristsWithErrors() {
            this.tourists.forEach((t, i) => {
                this.$refs[`tourist${i}`]?.[0]?.$refs?.['form']?.validate()
            })
        }

        personSelected({person, index}) {
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                persistentStore.SET_TOURIST({index, val})
            } else {
                persistentStore.SET_TOURIST_PROP({index, prop: 'personId', val: null})
            }
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get searchRequest() {
            return {
                dateTime: this.service.startDateTime.split(' ')[0],
                adults: this.service.travelers.filter(({isChild}) => !isChild).length,
                childrenAges: this.service.travelers.reduce((childrenAges, {isChild, dateOfBirth}) => {
                    //TODO Missing age prop in get orders
                    if (isChild) {
                        if (dateOfBirth) {
                            childrenAges.push(
                                this.$dateFns.differenceInYears(
                                    new Date(this.service.startDateTime),
                                    this.$dateFns.parseISO(dateOfBirth)
                                )
                            )
                        } else {
                            childrenAges.push(4)
                        }
                    }
                    return childrenAges
                }, []),
            }
        }

        get startDate() {
            return this.searchRequest.dateTime
        }
    }
</script>
