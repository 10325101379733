var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('flight-booking-page-layout',{attrs:{"offer":_vm.offer,"search-request":_vm.searchRequest,"search-request-country-id":_vm.searchRequestCountryId},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('flight-breadcrumbs',{attrs:{"search-request":_vm.searchRequest}})]},proxy:true},{key:"tourists",fn:function(ref){
var tourists = ref.tourists;
var index = ref.index;
var disabled = ref.disabled;
var personSelected = ref.personSelected;
return [_c('flight-tourist-form',{attrs:{"disabled":disabled,"index":index,"offer":_vm.offer,"hide-index":tourists.length === 1,"selected-tourists":tourists,"start-date":_vm.startDate,"data-cy":("tourist-" + index)},on:{"person-selected":personSelected},model:{value:(tourists[index]),callback:function ($$v) {_vm.$set(tourists, index, $$v)},expression:"tourists[index]"}}),_vm._v(" "),_c('extra-baggage-form',{attrs:{"index":index,"disabled":disabled,"offer":_vm.offer,"extra-baggage-options":_vm.extraBaggageOptions},model:{value:(_vm.prepareBookRequest),callback:function ($$v) {_vm.prepareBookRequest=$$v},expression:"prepareBookRequest"}})]}},{key:"orderCommentsForm",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('booking-comments-form',{attrs:{"disabled":!!bookingKey,"product-store":_vm.productStore}})]}},{key:"forms",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('flight-seats-map',{attrs:{"offer":_vm.offer,"disabled":!!bookingKey,"search-request":_vm.searchRequest,"tourists":_vm.tourists},on:{"map-loaded":function($event){_vm.flightSeatsMap = $event}},model:{value:(_vm.prepareBookRequest),callback:function ($$v) {_vm.prepareBookRequest=$$v},expression:"prepareBookRequest"}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('flight-order-summary',{attrs:{"offer":_vm.offer,"prebooked":!!bookingKey,"flight-seats-map":_vm.flightSeatsMap,"extra-baggage-options":_vm.extraBaggageOptions},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('v-divider',{staticClass:"my-2 mb-3"}),_vm._v(" "),_c('v-card',{staticClass:"mb-1 pa-0",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('search-summary-content',{attrs:{"search-request":_vm.searchRequest,"show-tourists":""}})],1)],1)]},proxy:true}],null,true)})]}}],null,false,3458422512)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }