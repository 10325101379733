<template>
    <v-row dense>
        <v-col cols="12" md="2" align-self="center" class="text-center text-no-wrap">
            <v-img :src="logoSrc" :max-height="50" contain />
            <div class="d-inline d-md-block">
                {{ segment.aircraftName }}
            </div>
            <div v-if="segment.duration" class="d-inline d-md-block">
                {{ segment.duration | durationFormat }}
            </div>
        </v-col>
        <v-col cols="12" md="10" class="pl-0">
            <v-stepper vertical flat class="elevation-0 pb-0">
                <v-stepper-step class="" step="1" complete complete-icon="mdi-airplane-takeoff">
                    <div class="d-flex font-weight-light" :class="{'high-line': $breakpoint.smAndDown}">
                        <span v-if="segment.departureDate">
                            {{ segment.departureDate | hoursFromDate }}
                        </span>
                        <span v-else>{{ $t('undefined') }}&nbsp;</span>
                        {{ segment.departureAirportCode }}
                        {{ segment.departureAirportDescription.airportName }}
                        {{ segment.departureAirportDescription.cityName }}
                        {{ segment.departureAirportDescription.countryName }}
                        {{ segment.departureTerminal }}
                    </div>
                </v-stepper-step>
                <v-stepper-content step="1">
                    <v-row no-gutters>
                        <v-col v-if="segment.categoryClass" cols="12">
                            {{ $t('flights.class') }}: {{ segment.categoryClass.classType }}
                        </v-col>
                        <v-col v-if="segment.categoryClass && segment.categoryClass.quantity" cols="12">
                            {{ $t('flights.seats') }}:
                            <span class="green--text">
                                <span v-if="+segment.categoryClass.quantity !== 9" class="red--text">{{
                                    $t('flights.seats_quantity.only') + ' '
                                }}</span>
                                <span :class="+segment.categoryClass.quantity !== 9 ? 'red--text' : 'green--text'">
                                    {{ segment.categoryClass.quantity
                                    }}{{ segment.categoryClass.quantity == 9 ? '+' : '' }}
                                </span>
                                <span v-if="segment.categoryClass.quantity == 9">{{
                                    $t('flights.seats').toLowerCase()
                                }}</span>
                                <span v-else class="red--text">
                                    {{
                                        $tc('flights.seat', +segment.categoryClass.quantity) +
                                        ' ' +
                                        $t('flights.seats_quantity.available')
                                    }}
                                </span>
                            </span>
                        </v-col>
                        <v-col v-if="segment.brandedFareName" cols="12">
                            {{ $t('flights.tariff') }}: {{ segment.brandedFareName }}
                        </v-col>
                        <v-col v-if="segment.mealCode" cols="12">
                            {{ $t('flights.meal_type') }}: {{ segment.mealCode }}
                        </v-col>
                        <v-col cols="12">
                            {{ $t('flights.flight_number') }}:
                            <span v-if="segment.operatingAirline">
                                {{ segment.operatingAirline }}
                            </span>
                            <span v-else>
                                {{ $t('no_airline_code') }}
                            </span>
                            {{ segment.flightNumber }}
                        </v-col>
                        <v-col v-if="baggageInfo" cols="12">
                            {{ $t('flights.baggage') }}:
                            {{
                                baggageInfo.unitQuantity != 0
                                    ? `${$t('flights.up_to')} ${baggageInfo.unitQuantity}
                                    ${$t(`flights.baggageType.${baggageInfo.unitCode}`)} `
                                    : $t('flights.no_baggage')
                            }}
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-step step="2" complete complete-icon="mdi-airplane-landing">
                    <div class="d-flex" :class="{'high-line': $breakpoint.smAndDown}">
                        <span v-if="segment.arrivalDate">
                            {{ segment.arrivalDate | hoursFromDate }}
                        </span>
                        <span v-else>{{ $t('undefined') }}&nbsp;</span>
                        {{ segment.arrivalAirportCode }}
                        {{ segment.arrivalAirportDescription.airportName }}
                        {{ segment.arrivalAirportDescription.cityName }}
                        {{ segment.arrivalAirportDescription.countryName }}
                        {{ segment.arrivalTerminal }}
                    </div>
                </v-stepper-step>
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {fullUrl} from '~src/utils/host-adapter'

    @Component
    export default class FlightSegmentDetail extends Vue {
        @Prop()
        segment

        get baggageInfo() {
            if (this.segment.baggageInfo && this.segment.baggageInfo[0]) {
                return this.segment.baggageInfo[0].baggage
            }
            return null
        }

        get logoSrc() {
            return fullUrl(`/stat/images/airlines/${this.segment.marketingAirline}.gif`)
        }
    }
</script>

<style lang="scss" scoped>
    .high-line {
        line-height: 18px;
    }
</style>
