var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('n-link',{staticClass:"d-block text-right",attrs:{"no-prefetch":"","to":{name: 'orders'}}},[_c('strong',[_vm._v(_vm._s(_vm.$t('full_view')))])]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.services,"loading":_vm.isLoading,"disable-sort":"","no-data-text":_vm.noDataText,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('n-link',{attrs:{"no-prefetch":"","to":{name: 'order-details', params: {id: item.orderId}}}},[_c('strong',[_vm._v(_vm._s(item.orderId))])])],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.created)}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-start"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("\n                    "+_vm._s(_vm.getServiceTypeIcon(item.serviceType))+"\n                ")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(item.title + item.location)}})],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.date)}})]}},{key:"item.travellers",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.travellers)}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.statusColor},[_vm._v(_vm._s(item.statusText))]),_vm._v(" "),(_vm.isTO1)?_c('div',[_c('span',{staticClass:"commission"},[_vm._v("\n                    "+_vm._s(item.supplierCompanyName)+"\n                ")])]):_vm._e()]}},{key:"item.supplierPrice",fn:function(ref){
var item = ref.item;
return [(item.supplier.price)?_c('span',[_vm._v("\n                "+_vm._s(_vm._f("priceFormat")(item.supplier.price.amount,item.supplier.price.currency))+"\n            ")]):_vm._e(),_vm._v(" "),_c('payment-status-label',{staticClass:"text-no-wrap",attrs:{"status":item.supplier.paymentStatus,"card-guarantee":item.supplier.cardGuarantee}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }