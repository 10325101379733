<template>
    <div :style="contentStyle">
        <v-btn x-small fab class="cursor-pointer" elevation="0" @click="wishlisted ? unlike() : like()">
            <v-icon :color="wishlisted ? 'error' : 'secondary'"
                >{{ wishlisted ? 'mdi-cards-heart' : 'mdi-cards-heart-outline' }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class WishlistLike extends Vue {
        @Prop() info
        @Prop() query
        @Prop() person
        @Prop() productRuntimeStore
        @Prop() inWishlist
        @Prop({default: 'position: absolute; right: 12px; top: 4px'}) contentStyle

        wishlisted = false

        mounted() {
            this.wishlisted = this.inWishlist
        }

        like() {
            try {
                if (this.userId) {
                    this.$api.wishlist.post({userId: this.userId, ...this.info, ...this.query})
                }
                this.wishlisted = true
                this.productRuntimeStore.updateWishlist({
                    ...this.info,
                    ...this.query,
                    isWishlist: true,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.wishlisted = false
            }
        }

        unlike() {
            try {
                if (this.userId) {
                    this.$api.wishlist.remove({userId: this.userId, ...this.query})
                }
                this.wishlisted = false
                this.productRuntimeStore.updateWishlist({
                    ...this.query,
                    isWishlist: false,
                })
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.wishlisted = true
            }
        }

        get userId() {
            return this.person?.personId
        }
    }
</script>
