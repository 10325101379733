<template>
    <v-navigation-drawer v-model="drawer" style="z-index: 200" app left width="100vw">
        <div class="pt-6 d-flex align-center" style="position: sticky">
            <v-spacer />
            <v-icon large class="me-4" @click="onCloseClick"> mdi-close </v-icon>
        </div>
        <v-list nav dense>
            <template v-if="!isShowForm">
                <v-list-item class="py-0 px-4">
                    <footer-menu style="margin-left: 0 !important" />
                </v-list-item>
                <v-divider class="my-2" />
                <template v-if="!['authorization', 'b2b-auth', 'restore-password'].includes($route.name)">
                    <v-list-item class="pa-0">
                        <div class="d-flex flex-row justify-space-between full-width">
                            <currency-switcher class="mx-0 px-0" style="max-width: 90px" />
                            <language-switcher class="mx-0 px-0" style="max-width: 90px" />
                        </div>
                    </v-list-item>
                </template>
                <v-divider class="mt-2 mb-4" />
            </template>
            <v-list-item>
                <account-menu v-if="!isMobile" @auth="drawer = false" />
                <account-menu-mobile-mod
                    v-if="isMobile"
                    v-model="isShowForm"
                    class="px-4"
                    @auth="
                        drawer = false
                        isShowForm = false
                    "
                />
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import NavigationDrawerMobile from '~/components/snippets/NavigationDrawerMobile'
    import FooterMenu from '~src/components/snippets/footerMenu.src'
    import AccountMenuMobileMod from '~~/mods/ajbooking/components/account/AccountMenuMobileMod'

    @Component({
        components: {AccountMenuMobileMod, FooterMenu},
    })
    export default class NavigationDrawerMobileMod extends NavigationDrawerMobile {
        isShowForm = false

        onCloseClick() {
            if (this.isShowForm) {
                this.isShowForm = false
            } else {
                this.drawer = false
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
