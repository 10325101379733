<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-lightning-bolt-outline"
        :is-first="isFirst"
        :extra-service-name="info.serviceName"
        :order="order"
        @modifyService="$emit('modifyService', $event)"
        @showInfo="showInfo"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service-check-in="service.startDateTime | dateTimeFormat"
                :service-check-out="
                    service.startDateTime !== service.endDateTime
                        ? $options.filters.dateTimeFormat(service.endDateTime)
                        : null
                "
                :service="service"
                :service-image="mainImage"
                :order-end-date="orderEndDate"
                :is-package="isPackage"
                :service-label="service.serviceDetails[0].extraServiceName"
                :order="order"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="mt-4" />
                <div v-if="mealType" class="d-flex align-top font-weight-bold">
                    <v-icon small left>mdi-silverware-fork-knife</v-icon>
                    {{ mealType }}
                </div>
                <div v-if="roomType" class="d-flex align-top font-weight-bold">
                    <v-icon small left>mdi-bed-empty</v-icon>
                    {{ roomType }}
                </div>
                <div v-if="departureAirportCode" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.departure_airport') }}
                    </span>
                    <span>
                        {{ departureAirportCode }}
                    </span>
                </div>
                <div v-if="departureTerminal" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.departure_terminal') }}
                    </span>
                    <span>
                        {{ departureTerminal }}
                    </span>
                </div>
                <div v-if="arrivalAirportCode" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.arrival_airport') }}
                    </span>
                    <span>
                        {{ arrivalAirportCode }}
                    </span>
                </div>
                <div v-if="arrivalTerminal" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.arrival_terminal') }}
                    </span>
                    <span>
                        {{ arrivalTerminal }}
                    </span>
                </div>
                <div v-if="flightNumber" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.flight_number') }}
                    </span>
                    <span>
                        {{ flightNumber }}
                    </span>
                </div>
                <div v-if="flightClass" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.flight_class') }}
                    </span>
                    <span>
                        {{ flightClass }}
                    </span>
                </div>
                <div v-if="bookingClass" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.booking_class') }}
                    </span>
                    <span>
                        {{ bookingClass }}
                    </span>
                </div>
                <div v-if="seatsPrebooked" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('flights.seats_prebooked') }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="mealIncluded" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('meal_included') }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="durationTime" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('duration_time') }}
                    </span>
                    <span>
                        {{ durationTime }}
                    </span>
                </div>
                <div v-if="durationDays" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('duration_days') }}
                    </span>
                    <span>
                        {{ durationDays }}
                    </span>
                </div>
                <div v-if="pickUpPoint" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('pick_up_point') }}
                    </span>
                    <span>
                        {{ pickUpPoint }}
                    </span>
                </div>
                <div v-if="pickUpTime" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('pick_up_time') }}
                    </span>
                    <span>
                        {{ pickUpTime }}
                    </span>
                </div>
                <div v-if="dropOffPoint" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('drop_off_point') }}
                    </span>
                    <span>
                        {{ dropOffPoint }}
                    </span>
                </div>
                <div v-if="dropOffTime" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('drop_off_time') }}
                    </span>
                    <span>
                        {{ dropOffTime }}
                    </span>
                </div>
                <div v-if="vehicleCategory" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('filterTitles.vehicle_type') }}
                    </span>
                    <span>
                        {{ vehicleCategory }}
                    </span>
                </div>
                <div v-if="oneWay !== undefined" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ `${oneWay ? $t('transfers.one_way') : $t('transfers.return_transfer')}` }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="groupTransfer !== undefined" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ `${groupTransfer ? $t('transfers.shared_transfer') : $t('transfers.private_transfer')}` }}
                    </span>
                    <v-icon small left>mdi-check</v-icon>
                </div>
                <div v-if="duration" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('duration') }}
                    </span>
                    <span>
                        {{ duration }}
                    </span>
                </div>
                <div v-if="contactNumber" class="d-flex align-top">
                    <span class="me-1 font-weight-bold">
                        {{ $t('number_or_transfer_provider') }}
                    </span>
                    <span>
                        {{ contactNumber }}
                    </span>
                </div>
                <div class="mt-4" />
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base :service="service" :info="info">
                    <template v-slot:serviceInfo>
                        <div
                            v-if="service.serviceDetails[0].description"
                            class="make-new-line"
                            v-html="service.serviceDetails[0].description"
                        />
                        <div v-if="info.productDescription" v-html="info.productDescription" />
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ServiceCard from '@/components/account/orderServices/ServiceCard'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'

    @Component({
        components: {
            ServiceInfoBase,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceCard,
        },
    })
    export default class OfllineService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() isFirst

        info = {}

        modal = false

        showInfo() {
            this.modal = true
        }

        async mounted() {
            // const processId = this.service.processId.replace(/^.{6}/, '')
            try {
                // this.info = await this.$api.extraServiceInfo.get({processId})
                this.info = {}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get mainImage() {
            if (this.info.images && this.info.images.length) {
                let image = this.info.images.find(el => el.mainImage)
                if (!image) {
                    image = this.info.images[0]
                }
                return image.url
            }
            return null
        }

        get mealType() {
            return this.service.serviceDetails[0]?.mealType
        }

        get roomType() {
            return this.service.serviceDetails[0]?.roomType
        }

        get arrivalAirportCode() {
            return this.service.serviceDetails[0]?.arrivalAirportCode
        }

        get arrivalTerminal() {
            return this.service.serviceDetails[0]?.arrivalTerminal
        }

        get bookingClass() {
            return this.service.serviceDetails[0]?.bookingClass
        }

        get departureAirportCode() {
            return this.service.serviceDetails[0]?.departureAirportCode
        }

        get departureTerminal() {
            return this.service.serviceDetails[0]?.departureTerminal
        }

        get flightClass() {
            return this.service.serviceDetails[0]?.flightClass
        }

        get flightNumber() {
            return this.service.serviceDetails[0]?.flightNumber
        }

        get seatsPrebooked() {
            return this.service.serviceDetails[0]?.seatsPrebooked
        }

        get mealIncluded() {
            return this.service.serviceDetails[0]?.mealIncluded
        }

        get durationTime() {
            return this.service.serviceDetails[0]?.durationTime
        }

        get durationDays() {
            return this.service.serviceDetails[0]?.durationDays
        }

        get pickUpPoint() {
            return this.service.serviceDetails[0]?.pickUpPoint
        }

        get pickUpTime() {
            return this.service.serviceDetails[0]?.pickUpTime
        }

        get dropOffPoint() {
            return this.service.serviceDetails[0]?.dropOffPoint
        }

        get dropOffTime() {
            return this.service.serviceDetails[0]?.dropOffTime
        }

        get vehicleCategory() {
            return this.service.serviceDetails[0]?.vehicleCategory
        }

        get oneWay() {
            return this.service.serviceDetails[0]?.oneWay
        }

        get groupTransfer() {
            return this.service.serviceDetails[0]?.groupTransfer
        }

        get duration() {
            return this.service.serviceDetails[0]?.duration
        }

        get contactNumber() {
            return this.service.serviceDetails[0]?.contactNumber
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }

    .make-new-line {
        white-space: pre-line;
    }
</style>
