<template>
    <v-list dense class="d-flex account-menu" :class="$breakpoint.smAndDown ? 'flex-column full-width' : ''">
        <account-menu-custom-items />
        <v-list-item
            v-if="person && (isB2B || isTourOperator) && !isSupplier && $config.showReportsTab"
            :to="{name: 'reports'}"
            data-cy="account-reports-button"
            @click="
                menu = false
                menuCompany = false
            "
        >
            <v-list-item-content :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                <v-list-item-title>
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                        {{ $t('reports') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="person && isSupplier"
            :to="{name: 'supplierProducts'}"
            data-cy="account-supplier-products-button"
            @click="
                menu = false
                menuCompany = false
            "
        >
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text>
                        {{ $t('products') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="person && (isB2B || isTourOperator) && !isSupplier">
            <v-menu v-model="menuCompany" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''" v-on="on">
                        {{ $t('my_company') }}
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item
                        v-if="isB2B"
                        :to="{name: 'company-profile'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('my_company') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B"
                        :to="{name: 'companyPersons'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('users') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && !isCorporate"
                        :to="{name: 'companyClients'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-human-male-female</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && (isAgency || isTourOperator) && $config.account.showCorporateClients"
                        :to="{name: 'companyCorporateClients'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-account-tie</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('corporate_clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && isTourOperator"
                        :to="{name: 'companyAgencies'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>mdi-brightness-auto</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('agencies') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item>
        <v-list-item>
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn
                        v-if="!person"
                        block
                        data-cy="login-menu-button"
                        :class="$breakpoint.smAndDown ? 'py-0' : ''"
                        :color="'primary'"
                        class="caption px-8"
                        elevation="0"
                        v-on="on"
                    >
                        <span>{{ $t('log_in') }}</span>
                    </v-btn>
                    <v-btn
                        v-else
                        block
                        data-cy="login-menu-button"
                        :class="$breakpoint.smAndDown ? 'py-0' : ''"
                        :color="'transparent'"
                        class="caption"
                        elevation="0"
                        x-large
                        v-on="on"
                    >
                        <div>
                            <v-avatar size="40" class="me-3">
                                <v-img :src="(person && person.avatar) || '/profile-avatar.png'" />
                            </v-avatar>
                            <span
                                class="person-label text-truncate font-weight-medium"
                                v-text="`${person.firstName || person.lastName}`"
                            />
                            <v-icon right>mdi-menu-down</v-icon>
                        </div>
                    </v-btn>
                </template>
                <v-list v-if="person" dense>
                    <v-list-item
                        :to="isB2B ? {name: 'companyPerson', params: {id: person.personId}} : {name: 'user-settings'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon color="#8598B4" left>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('account') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="person"
                        :to="{name: 'orders'}"
                        data-cy="account-orders-button"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon color="#8598B4" left>mdi-format-list-bulleted</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('orders') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="hasEmailTemplatesAccess"
                        :to="{name: 'emailTemplates'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon color="#8598B4" left>mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('admin.settings') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasCMSAccess" @click="cmsLogin">
                        <v-list-item-icon>
                            <v-icon color="#8598B4" left>mdi-rocket</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('admin.cms') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon color="#8598B4" left>mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('log_out') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-card v-else-if="!forgotPassword" width="410" class="px-5 py-3">
                    <v-card-title class="title font-weight-light">
                        <div class="d-flex align-center justify-center full-width mb-4">
                            <v-btn
                                x-large
                                nuxt
                                text
                                :rounded="false"
                                class="v-btn--router footer-link me-4 py-8 px-4"
                                :class="{'v-btn--active': isSignInMode}"
                                @click="isSignInMode = true"
                            >
                                <span class="text-h6">
                                    {{ $t('sign_in') }}
                                </span>
                            </v-btn>
                            <v-divider vertical class="mx-4 mb-2" inset />
                            <v-btn
                                x-large
                                nuxt
                                text
                                :rounded="false"
                                :class="{'v-btn--active': !isSignInMode}"
                                class="v-btn--router footer-link me-4 py-8 px-4"
                                @click="isSignInMode = false"
                            >
                                <span class="text-h6">
                                    {{ $t('register') }}
                                </span>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <authorization-form v-if="isSignInMode" @switch-forgot-password="forgotPassword = true" />
                        <registration-form v-else @registered="onRegistered" />
                    </v-card-text>
                </v-card>
                <v-card v-else width="350">
                    <v-card-title class="title font-weight-light">
                        {{ $t('forgot_your_password') }}
                    </v-card-title>
                    <v-card-text>
                        <forgot-password-form
                            @switch-login="forgotPassword = false"
                            @forgot-password-success="onForgotPassword"
                        />
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-list-item>
    </v-list>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import AccountMenu from '@/components/account/AccountMenu'
    import RegistrationForm from '~src/components/account/forms/registrationForm.src'

    @Component({
        components: {RegistrationForm},
    })
    export default class AccountMenuMod extends AccountMenu {
        isSignInMode = true

        async onRegistered() {
            await this.$router.push({name: 'home'})
            this.$toast.success(this.$t('registration_successful'))
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-list-item:hover::before,
        .v-list-item:active::before,
        .v-list-item:focus::before,
        .v-list-item--active::before {
            opacity: 0 !important;
        }

        .v-list-item--active .v-btn::before {
            opacity: 0.04 !important;
        }

        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 7px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }
    }
</style>
