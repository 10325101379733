<template>
    <v-card data-cy="hotel-room-offers">
        <v-card-title v-if="!filteredOffer && !isSearchActive && loaded" class="title font-weight-light">
            {{ $t('no_available_rooms') }}
        </v-card-title>
        <hotel-room-offers
            v-if="loaded"
            :offer="filteredOffer"
            :_selected-offer-keys.sync="selectedOfferKeys"
            :search-request="searchRequest"
            hdi
            :hotel-info="hotelInfo"
        >
            <template v-slot:price="{room}">
                <slot name="price" :room="room" />
            </template>
        </hotel-room-offers>
        <template v-if="isSearchActive && !filteredOffer">
            <v-card-title v-if="!filteredOffer" class="title font-weight-light">
                {{ $t('loading_room_offers') }}...
            </v-card-title>
            <v-skeleton-loader transition="fade-transition" height="100" type="list-item-three-line" />
        </template>
        <v-card-actions v-if="searchRoomsCount > 1 && filteredOffer" class="flex-column">
            <hotel-multiple-room-select-block
                :search-request="searchRequest"
                :selected-offer-keys="selectedOfferKeys"
                :offer="filteredOffer"
            />
        </v-card-actions>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelInfoRoomOffersWrapper from '~/components/info/offers/HotelInfoRoomOffersWrapper'

    @Component
    export default class HotelInfoRoomOffersWrapperMod extends HotelInfoRoomOffersWrapper {}
</script>
