<script>
    import {Component, Vue, Watch} from 'nuxt-property-decorator'
    import {CHANGE_LOCALE_EVENT, EventBus, FILTER_EVENT} from '@/utils/event-bus'

    @Component
    export default class InfoPageMixin extends Vue {
        mobileTabs = 0
        images = []
        loaded = false

        validate({query}) {
            return query.name
        }

        @Watch('product')
        onProductLoad() {
            if (this.productRuntimeStore.searchActive) {
                this.productRuntimeStore.stopSearch()
            }
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.search)
        }

        async mounted() {
            EventBus.$on(FILTER_EVENT, this.onLoaded)
            await this.$store.restored
            await this.loadRuntimeData()

            if (this.needNewSearch()) {
                await this.search()
            } else {
                const offers = await this.$localForage.getItem(this.productName)
                this.productRuntimeStore.SET_OFFERS(offers)
                this.onLoaded()
            }
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.onLoaded)
            EventBus.$off(CHANGE_LOCALE_EVENT, this.search)
        }

        goToSearchPage() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        search() {
            this.productRuntimeStore.search(this.searchRequest)
        }

        onLoaded() {
            if (!Object.keys(this.product).length) return this.$nuxt.error({statusCode: 404})
            this.loaded = true
        }

        get product() {
            return (
                this.productRuntimeStore.offers?.find(
                    offer => offer.productId === Number(this.$route.query.productId)
                ) || {}
            )
        }
    }
</script>
