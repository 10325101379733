<template>
    <div dir="ltr">
        <div class="full-width d-flex justify-space-between text-center mb-6">
            <span class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                <span> {{ $t('our_news') }} </span>
                <span class="primary--text"> & {{ $t('offers') }} </span>
            </span>
            <div v-if="!isMobile" class="d-flex justify-center mt-6">
                <v-btn v-if="items.length > showCount" id="blogSwiperButtonPrev" icon elevation="1" class="mx-2">
                    <v-icon color="primary"> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn v-if="items.length > showCount" id="blogSwiperButtonNext" icon elevation="1" class="mx-2">
                    <v-icon color="primary"> mdi-chevron-right </v-icon>
                </v-btn>
            </div>
        </div>
        <swiper :options="swiperOptions" class="swiper pa-6 pb-8">
            <swiper-slide v-for="(item, index) in items" :key="index" :class="{'swiper-slide-ssr': swiperSlideSsr}">
                <blog-item :item="item" />
            </swiper-slide>
            <div v-if="items.length > showCount" slot="pagination" class="swiper-pagination" style="bottom: 0" />
        </swiper>
        <div class="full-width d-flex justify-center pt-6">
            <v-btn
                color="primary"
                class="font-weight-regular text-subtitle-2 font-weight-regular px-8"
                to="/info/news"
                elevation="0"
                >{{ $t('see_our_blog') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import Blog from '@/components/parts/Blog'

    @Component
    export default class BlogMod extends Blog {
        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 2 : 3
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                spaceBetween: 48,
                breakpoints: {
                    960: {
                        slidesPerView: 2,
                    },
                    1264: {
                        slidesPerView: 3,
                    },
                },
                navigation: {
                    nextEl: '#blogSwiperButtonNext',
                    prevEl: '#blogSwiperButtonPrev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true,
                },
                observer: true,
                observeParents: true,
            }
        }
    }
</script>

<style scoped lang="scss">
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
        bottom: 0 !important;
        top: unset;
    }

    ::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #d8e562;
    }
</style>
