<template>
    <div>
        <v-dialog v-model="serviceTipsDialog" width="400">
            <order-details-tips :tips="serviceTips" :loaded="true" show-close @close="serviceTipsDialog = false" />
        </v-dialog>
        <v-card outlined class="mb-2 service-card">
            <v-alert
                color="grey lighten-4 service-card-header"
                class="black--text mb-0"
                @click="isMinimized = !isMinimized"
            >
                <div class="d-flex flex-wrap flex-md-nowrap" :class="$breakpoint.smAndDown ? 'flex-column' : ''">
                    <div class="d-flex justify-center flex-column">
                        <div class="d-flex flex-row align-center" :class="{'mb-1': isMinimized === true}">
                            <template v-if="service.serviceType === 'EXTRASERVICE'">
                                <v-icon color="primary" class="mr-2 mt-1 align-self-start">
                                    {{ 'mdi-cloud-off-outline' }}
                                </v-icon>
                                <v-icon color="primary" class="mr-2 mt-1 align-self-start">
                                    {{ extraServiceTypeIcon }}
                                </v-icon>
                            </template>
                            <v-icon v-else color="primary" class="mr-2 mt-1 align-self-start">
                                {{ serviceTypeIcon }}
                            </v-icon>
                            <span class="service-name mr-1 text-h6 font-weight-medium" v-text="serviceName" />
                            <hotel-stars
                                v-if="service.serviceDetails[0].category || service.serviceDetails[0].ratings"
                                class="mt-n1 mx-2"
                                :hotel="service.serviceDetails[0]"
                            />
                            <v-icon class="info-icon me-2" right color="#8598B4" @click.stop="serviceInfo">
                                mdi-information-outline
                            </v-icon>
                            <v-chip v-if="serviceType || extraServiceName" label x-small>
                                {{ serviceType || extraServiceName }}
                            </v-chip>
                            <div
                                v-if="serviceTips && serviceTips.length"
                                class="cursor-pointer"
                                @click.stop="serviceTipsDialog = true"
                            >
                                <service-tips-icon />
                            </div>
                        </div>
                        <v-expand-transition>
                            <div v-if="isMinimized === true" class="d-flex">
                                <div style="width: 34px" />
                                <div>
                                    <div class="d-flex date mb-2 text-caption">
                                        <span v-text="dateString" />
                                    </div>
                                    <div
                                        class="d-flex flex-row text-caption font-weight-bold grey--text text--darken-2"
                                        style="min-height: 1.25rem"
                                    >
                                        <span class="travelers" v-text="travelersString" />
                                    </div>
                                </div>
                            </div>
                        </v-expand-transition>
                    </div>
                    <v-spacer class="hidden-sm-and-down" />
                    <div class="d-flex justify-md-end" :class="[{'ml-7 mt-2': $breakpoint.smAndDown}]">
                        <div
                            class="d-flex flex-column justify-md-center"
                            :class="[{'text-right': !$breakpoint.smAndDown}]"
                        >
                            <div
                                class="d-flex flex-row align-center"
                                :class="[{'mb-1': !!lastTicketingDate}, {'ml-auto': !$breakpoint.smAndDown}]"
                            >
                                <span
                                    class="ml-1 font-weight-medium status-text"
                                    :class="[
                                        {'fs-16': $breakpoint.smAndDown},
                                        {'fs-14': !$breakpoint.smAndDown},
                                        {[`${statusColor}--text`]: true},
                                    ]"
                                    v-text="$t(`bookingStatus.${status}`)"
                                />
                            </div>
                            <small v-if="lastTicketingDate" class="d-block mt-1 ml-1">
                                {{ $t('flights.last_ticketing_date') }}:
                                <strong>{{ lastTicketingDate | dateTimeFormat }}</strong>
                            </small>
                        </div>
                        <v-icon icon color="primary" :class="['mx-2 align-self-end-sm', {'chevron-down': isMinimized}]">
                            {{ isMinimized ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                        </v-icon>
                    </div>
                </div>
            </v-alert>
            <v-expand-transition>
                <div v-if="!isMinimized">
                    <v-card-text>
                        <slot name="orderInfo" />
                        <v-divider class="mt-3" />
                        <slot name="serviceInfo" />
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import ServiceCard from '~/components/account/orderServices/ServiceCard'
    import {getServiceTypeIcon} from '@/utils/helpers'

    @Component
    export default class ServiceCardMod extends ServiceCard {
        get serviceTypeIcon() {
            switch (this.service.serviceType) {
                case 'ACCOMMODATION':
                    return 'mdi-domain'
            }

            return getServiceTypeIcon(this.service.serviceType)
        }
    }
</script>
