<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col cols="12" sm="8">
                <h4 class="title text-capitalize">
                    {{ $t('personal_information') }}
                </h4>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="person.prefix"
                            :items="['Mr', 'Ms', 'Mrs']"
                            :label="`${$t('salutation')}*`"
                            :rules="[requiredRule('salutation')]"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="person.firstName"
                            :label="`${$t('first_name')}*`"
                            :rules="validationRules('first_name', [latinRule, requiredRule])"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="person.lastName"
                            :label="`${$t('last_name')}*`"
                            :rules="validationRules('last_name', [latinRule, requiredRule])"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <phone-field v-model="person.contactPhone" :label="$t('phone')" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="person.email"
                            :label="$t('email')"
                            type="email"
                            :rules="validationRules('email', [emailRule])"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="person.postalCode" :label="`${$t('postal_code')}`" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="person.countryId"
                            :items="countries"
                            item-text="name"
                            item-value="id"
                            :label="`${$t('country')}`"
                            @change="loadCities"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="person.cityId"
                            :items="cities"
                            item-text="name"
                            item-value="id"
                            :label="`${$t('city')}`"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="person.addressLine" :label="`${$t('address')}`" />
                    </v-col>
                </v-row>
                <h4 class="title text-capitalize">
                    {{ $t('additional_information') }}
                </h4>
                <v-row>
                    <v-col cols="12" sm="6">
                        <date-picker
                            v-model="person.birthdate"
                            :label="$t('birthdate')"
                            :max="$dateFns.format(new Date())"
                            year-active-picker
                        />
                    </v-col>
                    <v-col v-if="!hideCitizenship" cols="12" sm="6">
                        <v-autocomplete
                            v-model="person.citizenshipId"
                            :label="$t('citizenship')"
                            :items="countries"
                            item-text="name"
                            item-value="id"
                        />
                    </v-col>
                </v-row>
                <div v-if="person.personId">
                    <h4 class="title text-capitalize">
                        {{ $t('comment') }}
                    </h4>
                    <v-subheader class="px-0 font-italic">
                        {{ $t('enter_comment') }}
                    </v-subheader>
                    <v-textarea v-model="person.comments" outlined />
                </div>
            </v-col>
            <v-col cols="12" sm="4">
                <h4 class="title text-capitalize">
                    {{ person.personId ? $t('change_password') : $t('password') }}
                </h4>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="password"
                            :label="`${$t('password')}${
                                person.personId || isMember || isTourOperator || isAgency ? '' : '*'
                            }`"
                            type="password"
                            :rules="[
                                v =>
                                    !!v ||
                                    !!person.personId ||
                                    isMember ||
                                    isTourOperator ||
                                    isAgency ||
                                    `${$t('password')} ${$t('validation.required')}`,
                            ]"
                        />
                        <v-text-field
                            v-model="passwordToMatch"
                            :label="`${$t('password_confirm')}${
                                person.personId || isMember || isTourOperator || isAgency ? '' : '*'
                            }`"
                            type="password"
                            autocomplete="new-password"
                            :rules="passwordConfirmationRules"
                        />
                    </v-col>
                </v-row>
                <v-switch
                    v-if="person.personId"
                    v-model="person.active"
                    color="primary"
                    :label="person.active ? $t('active') : $t('inactive')"
                />

                <div v-if="person.personId && !isMember && !hidePassport">
                    <h4 class="title text-capitalize mt-12">
                        {{ $t('passport') }}
                    </h4>
                    <v-list v-if="person.passports.length">
                        <v-list-item
                            v-for="(passport, passportIndex) in person.passports"
                            :key="passportIndex"
                            class="pa-0"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div
                                        class="d-flex align-center"
                                        style="cursor: pointer"
                                        @click="editPassport('crmperson', passport, person.personId)"
                                    >
                                        <v-icon class="mr-2">mdi-file-document-edit</v-icon>
                                        {{ passport.number }}
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn
                        :loading="isLoading"
                        block
                        color="primary"
                        class="mt-4"
                        @click="createPassport('crmperson', person.personId)"
                    >
                        {{ $t('create_passport') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-btn :loading="isLoading" :disabled="isLoading || !valid" block color="primary" @click="save">
            {{ $t('save') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import {runtimeStore} from '~/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import PassportMixin from '@/components/account/mixins/PassportMixin'
    import {authStore} from '@/utils/store-accessor'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import PhoneField from '~src/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {PhoneField, DatePicker},
    })
    export default class ClientForm extends mixins(FormValidationRulesMixin, PassportMixin) {
        @Prop() person
        @Prop({default: false}) isMember

        valid = false
        isLoading = false
        password = null
        passwordToMatch = null
        cities = []

        @Watch('password')
        onChangePassword() {
            this.$refs.form.resetValidation()
        }

        mounted() {
            if (this.person.personId) {
                this.$refs.form.validate()
                this.loadCities()
            }
        }

        async save() {
            this.isLoading = true
            try {
                const {
                        prefix,
                        firstName,
                        lastName,
                        contactPhone,
                        email,
                        postalCode,
                        birthdate,
                        citizenshipId,
                        comments,
                        active,
                        countryId,
                        cityId,
                        addressLine,
                    } = this.person,
                    person = {
                        prefix,
                        firstName,
                        lastName,
                        contactPhone,
                        email,
                        postalCode,
                        birthdate,
                        citizenshipId,
                        comments,
                        countryId,
                        cityId,
                        addressLine,
                    }
                if (this.password) {
                    Object.assign(person, {password: this.password})
                }
                if (this.person.personId) {
                    Object.assign(person, {active})
                    await this.$api.privateClients.put(this.person.personId, person)
                } else {
                    Object.assign(person, {
                        memberLevel: 'REGISTERED',
                    })
                    await this.$api.privateClients.post(person)
                }
                this.$toast.success(this.$t('messages.success.user_saved'))
                const routeName = this.isMember ? 'members' : 'companyClients'
                await this.$router.push({name: routeName})
            } catch (e) {
                if (e.errors) {
                    e.errors.forEach(error => {
                        if (error.errorType === 'validation') {
                            this.$toast.error(error.message)
                        } else {
                            this.$toast.error(this.$t('error_message.save_error'))
                        }
                    })
                } else {
                    this.$toast.error(this.$t('error_message.save_error'))
                }
            } finally {
                this.isLoading = false
            }
        }

        async loadCities() {
            const rs = await this.$api.locations.get({
                limitCities: -1,
                orderBy: 'ALPHABET',
                countryId: this.person.countryId,
            })

            this.cities = rs.cities
        }

        get countries() {
            return runtimeStore.countries
        }

        get passwordConfirmationRules() {
            return [
                v =>
                    this.password === v ||
                    !this.password ||
                    `${this.$t('password_confirm')} ${this.$t('validation.match')}`,
                //v => !!v || `${this.$t('password_confirm')} ${this.$t('validation.required')}`,
            ]
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get hidePassport() {
            return false
        }

        get hideCitizenship() {
            return false
        }
    }
</script>
