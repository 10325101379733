var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('package-booking-page-layout',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.basketItem.hotel},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('package-breadcrumbs',{attrs:{"search-request":_vm.searchRequest}})]},proxy:true},{key:"tourists",fn:function(ref){
var tourists = ref.tourists;
var index = ref.index;
var disabled = ref.disabled;
var personSelected = ref.personSelected;
return [_c('flight-tourist-form',{attrs:{"disabled":disabled,"index":index,"offer":_vm.basketItem.flight,"is-package":"","hide-index":tourists.length === 1,"start-date":_vm.startDate,"data-cy":("tourist-" + index)},on:{"person-selected":personSelected},model:{value:(tourists[index]),callback:function ($$v) {_vm.$set(tourists, index, $$v)},expression:"tourists[index]"}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('selected-offers',{attrs:{"direction":'column',"bookable":false,"changable":!bookingKey,"hotel":_vm.basketItem.hotel,"room":_vm.basketItem.room,"flight":_vm.basketItem.flight,"price":_vm.basketItem.price,"search-request":_vm.searchRequest,"total-order-price":_vm.basketItem.totalPrice}})]}}],null,false,3639433423)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }