var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('activity-booking-page-layout',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer,"product":_vm.product,"product-store":_vm.productStore,"search-request-country-id":_vm.searchRequestCountryId},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('activity-breadcrumbs',{attrs:{"search-request":_vm.searchRequest,"product":_vm.product}})]},proxy:true},{key:"forms",fn:function(){return [_c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-map-marker")]),_vm._v("\n                "+_vm._s(_vm.$t('activities.pick-up_point'))+"\n            ")],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('activity-booking-form',{attrs:{"info":_vm.basketItem.info},on:{"change":_vm.setPickupPointField}})],1)],1)]},proxy:true},{key:"orderCommentsForm",fn:function(ref){
var bookingKey = ref.bookingKey;
var basketKey = ref.basketKey;
return [_c('booking-comments-form',{attrs:{"disabled":!!bookingKey || !!basketKey,"product-store":_vm.productStore}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
var basketKey = ref.basketKey;
var selectedAddOns = ref.selectedAddOns;
return [_c('activity-order-summary',{attrs:{"product":_vm.product,"offer":_vm.offer,"info":_vm.basketItem.info,"prebooked":!!bookingKey || !!basketKey,"selected-add-ons":selectedAddOns},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('v-card',{staticClass:"my-2 pa-0",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('search-summary-content',{attrs:{"search-request":_vm.searchRequest,"date-prop":_vm.offer.date,"tourists-label":"guest","show-date":"","show-tourists":""}})],1)],1)]},proxy:true}],null,true)}),_vm._v(" "),_c('activity-info-modal')]}}],null,false,389452701)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }