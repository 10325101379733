<template>
    <v-card :outlined="outlined">
        <v-card-text class="black--text subtitle-2">
            <v-row no-gutters>
                <v-col :cols="12" md="12">
                    <v-row no-gutters>
                        <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('order') }}:</v-col>
                        <v-col :cols="6" md="6" class="font-weight-medium">
                            {{ order.orderId }}
                        </v-col>
                    </v-row>
                    <v-row v-if="orderStatus" no-gutters>
                        <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('order_status') }}: </v-col>
                        <v-col :cols="6" md="6" class="font-weight-medium">
                            <span>
                                {{ orderStatus }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="showPaymentAndInvoiceStatus && isShowPaymentStatus && order.status !== 'NEW'"
                        no-gutters
                    >
                        <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('payment_status') }}: </v-col>
                        <v-col :cols="6" md="6" class="font-weight-medium">
                            <payment-status-label
                                :status="isAgency ? supplierPaymentStatus : customerPaymentStatus"
                                :card-guarantee="gotPayAtHotelService(order)"
                                no-color
                            />
                        </v-col>
                    </v-row>
                    <div v-if="order.clientCompanyName || tripAgentName" class="mt-4">
                        <v-row v-if="order.clientCompanyName" no-gutters class="mt-4">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('company') }}: </v-col>
                            <v-col :cols="6" md="6" class="font-weight-medium">
                                {{ order.clientCompanyName }}
                            </v-col>
                        </v-row>
                        <v-row v-if="tripAgentName" no-gutters align="center">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('agent') }}: </v-col>
                            <v-col :cols="6" md="6" class="font-weight-medium">
                                <div v-if="!editAgentMode" class="d-flex align-center">
                                    <span>
                                        {{ tripAgentName }}
                                        <v-btn v-if="isTO1" small icon @click="editAgentMode = !editAgentMode">
                                            <v-icon small color="primary" c>mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </div>
                                <order-details-edit-agent
                                    v-else
                                    :order-id="order.orderId"
                                    :agent-id="order.agent.id"
                                    :company-id="order.agentCompanyId"
                                    :save-loading="agentSaveLoading"
                                    @save="onAgentChange"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="tripOrganaizerName || clientName || managerName" class="mt-4">
                        <v-row v-if="tripOrganaizerName" no-gutters>
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('trip_organaizer') }}: </v-col>
                            <v-col :cols="6" md="6" class="font-weight-medium">
                                {{ tripOrganaizerName }}
                            </v-col>
                        </v-row>
                        <v-row v-if="clientName" no-gutters>
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('client') }}: </v-col>
                            <v-col :cols="6" md="6" class="font-weight-medium">
                                {{ clientName }}
                            </v-col>
                        </v-row>
                        <v-row v-if="managerName" no-gutters align="center">
                            <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('manager') }}: </v-col>
                            <v-col :cols="6" md="6" class="font-weight-medium">
                                <div v-if="!editManagerMode" class="d-flex align-center">
                                    <span>
                                        {{ managerName }}
                                        <v-btn v-if="isTO1" small icon @click="editManagerMode = !editManagerMode">
                                            <v-icon small color="primary" c>mdi-pencil</v-icon>
                                        </v-btn>
                                    </span>
                                </div>
                                <order-details-edit-agent
                                    v-else
                                    :order-id="order.orderId"
                                    :agent-id="order.manager.id"
                                    :company-id="person.companyId"
                                    :save-loading="managerSaveLoading"
                                    @save="onManagerChange"
                                />
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="minDate || maxDate" no-gutters class="mt-4">
                        <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('dates') }}:</v-col>
                        <v-col :cols="6" md="6" class="font-weight-medium">
                            <span>
                                {{ minDate | dateFormat }} <br />
                                <span v-if="minDate !== maxDate">{{ maxDate | dateFormat }}</span>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="duration" no-gutters>
                        <v-col :cols="6" md="6" class="font-weight-regular"> {{ $t('duration') }}: </v-col>
                        <v-col :cols="6" md="6" class="font-weight-medium">
                            {{ duration }}
                        </v-col>
                    </v-row>
                    <template v-if="order.status !== 'NEW'">
                        <v-row v-if="isTourOperator" no-gutters class="my-4">
                            <v-col v-if="commissionAndNettoCheckbox" :cols="6" md="6">
                                <total-order-prices
                                    :order-prices="orderPrices('SUPPLIER')"
                                    :payment-status="supplierPaymentStatus"
                                    :service-card-guarantee="gotPayAtHotelService(order)"
                                    :services="order.services"
                                    title="supplier_price"
                                />
                            </v-col>
                            <v-col :cols="6" md="6">
                                <total-order-prices
                                    title="client_price"
                                    :order-prices="orderPrices('CLIENT')"
                                    :payment-status="customerPaymentStatus"
                                    :service-card-guarantee="gotPayAtHotelService(order)"
                                    :services="order.services"
                                />
                                <cancel-penalty-label
                                    v-if="order.cancelPenalty"
                                    class="mt-2 font-weight-regular"
                                    :price="getTotalPrice(orderPrices('CLIENT'))"
                                    :cancel-penalty="order.cancelPenalty"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-else no-gutters class="mt-4 font-weight-regular">
                            <v-col :cols="6" md="6">{{ $t('price') }}:</v-col>
                            <v-col :cols="6" md="6">
                                <order-price-label
                                    :order-prices="orderPrices(isAgency ? 'SUPPLIER' : 'CLIENT')"
                                    :services="order.services"
                                    :cancel-penalties="order.cancelPenalty"
                                    :payment-status="customerPaymentStatus"
                                    class="font-weight-medium"
                                />
                            </v-col>
                        </v-row>
                        <template
                            v-if="
                                isAgency &&
                                orderPrices('SUPPLIER').some(orderPrice => !!orderPrice.commission) &&
                                commissionAndNettoCheckbox
                            "
                        >
                            <v-row no-gutters class="font-weight-regular">
                                <v-col :cols="6" md="6">{{ $t('commission') }}:</v-col>
                                <v-col :cols="6" md="6">
                                    <template v-for="(orderPrice, index) in orderPrices('SUPPLIER')">
                                        <div v-if="orderPrice.commission" :key="index" class="font-weight-bold">
                                            {{ orderPrice.commission | priceFormat(orderPrice.currency) }}
                                        </div>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-4 font-weight-regular">
                                <v-col :cols="6" md="6">{{ $t('net_amount') }}:</v-col>
                                <v-col :cols="6" md="6">
                                    <template v-for="(orderPrice, index) in orderPrices('SUPPLIER')">
                                        <div v-if="orderPrice.commission" :key="index" class="font-weight-bold">
                                            {{
                                                (orderPrice.amount - orderPrice.commission)
                                                    | priceFormat(orderPrice.currency)
                                            }}
                                        </div>
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                    </template>
                </v-col>
                <v-col v-if="!isSupplier && !isGuest" :cols="12" md="12">
                    <v-divider class="my-3" />
                    <v-row>
                        <v-col :cols="12" class="font-weight-regular pb-0">
                            {{ $t('documents_for_download') }}
                        </v-col>
                        <v-col :cols="12">
                            <v-row>
                                <v-col v-if="!$config.account.hideItineraryBtn && order.status !== 'NEW'" cols="6">
                                    <v-btn
                                        :disabled="loadItinerary"
                                        :loading="loadItinerary"
                                        text
                                        class="px-0 black--text"
                                        @click="getItinerary"
                                    >
                                        <v-icon color="primary" left>mdi-map-legend</v-icon>
                                        {{ $t('itinerary') }}
                                    </v-btn>
                                </v-col>
                                <v-col v-if="voucherAvailable" cols="6">
                                    <v-btn
                                        :disabled="voucherLoading"
                                        :loading="voucherLoading"
                                        class="px-0"
                                        text
                                        @click="downloadVoucher"
                                    >
                                        <v-icon color="primary" left>mdi-file-document-outline</v-icon>
                                        {{ $t('voucher') }}
                                    </v-btn>
                                </v-col>

                                <v-col
                                    v-if="
                                        showPaymentAndInvoiceStatus &&
                                        !gotPayAtHotelService(order) &&
                                        order.status !== 'NEW'
                                    "
                                    cols="6"
                                >
                                    <v-btn
                                        text
                                        class="px-0"
                                        @click="isAgency ? showInvoices('SUPPLIER') : showInvoices('CUSTOMER')"
                                    >
                                        <v-icon color="primary" left>mdi-receipt-text-outline</v-icon>
                                        {{ $t('invoices.invoice') }}
                                    </v-btn>
                                </v-col>

                                <v-col v-if="isB2B" cols="6">
                                    <v-btn text class="px-0" @click="getDocuments">
                                        <v-icon color="primary" left>mdi-text-box-search-outline</v-icon>
                                        {{ $t('documents') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <order-custom-documents :order="order" />
                                </v-col>
                                <v-col v-if="isTO1" cols="6">
                                    <v-btn text class="px-0" @click="sendEmail">
                                        <v-icon color="primary" left>mdi-email-outline</v-icon>
                                        {{ $t('send_email') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <div class="d-flex flex-wrap justify-center mt-2">
                        <v-btn
                            v-if="showPaymentAndInvoiceStatus && canPayOrder && !gotPayAtHotelService(order)"
                            depressed
                            class="mb-2 white--text"
                            color="primary"
                            block
                            :small="$breakpoint.smAndDown"
                            @click="payOrder"
                        >
                            {{ $t('pay_order') }}
                        </v-btn>
                        <chat-modal v-if="order && $config.isChatEnabled" :order-id="order.orderId" />
                        <v-btn
                            v-if="!$config.account.hideAddServiceBtn"
                            outlined
                            class="mb-2 rounded-lg"
                            color="warning lighten-1"
                            block
                            data-cy="add-service-button"
                            large
                            @click="isShowOrderAddServiceModal = true"
                        >
                            <v-icon left> mdi-plus </v-icon>
                            <span class="black--text font-weight-regular">
                                {{ $t('add_service') }}
                            </span>
                        </v-btn>
                        <order-add-service-modal v-model="isShowOrderAddServiceModal" :order="order" row dark />
                        <add-offline-service-dialog
                            v-if="(isB2B && !$config.account.addOfflineServiceTO1Only) || isTO1"
                            :order-travelers="orderTravelers"
                            :order-process-id="order.processId"
                            @send-request="sendRequest"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderDetailsSummary from '~/components/account/OrderDetailsSummary'

    @Component
    export default class OrderDetailsSummaryMod extends OrderDetailsSummary {}
</script>
