<template>
    <v-card class="my-2" outlined>
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar class="my-0" size="200" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="product.info.imageUrl | imageUrl(350)"
                    :alt="product.info.name"
                    :lazy-src="'/placeholder.png' | srcHost"
                    contain
                    @error="imgError = true"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && product.info.imageUrl"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start">
                <v-row dense no-gutters>
                    <v-col cols="9">
                        <v-list-item-title :title="product.info.name" class="wrap-words">
                            <div class="mb-1">
                                <nuxt-link
                                    no-prefetch
                                    :to="productLink"
                                    class="text-decoration-none font-weight-medium"
                                >
                                    {{ product.info.name }}
                                </nuxt-link>
                                <v-chip v-if="minPriceOffer.transferType" label x-small>
                                    {{ $t(`filters_transferType.${minPriceOffer.transferType}`) }}
                                </v-chip>
                            </div>
                            <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" class="mb-2" />
                        </v-list-item-title>
                        <v-list-item-subtitle class="mt-2">
                            {{ product.info.description }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-2">
                            <transfer-driver-languages :lang-codes="langCodes" />
                        </v-list-item-subtitle>
                    </v-col>
                    <v-col v-if="product.offers[0].price && !hidePrice" cols="3" class="text-right">
                        <strong data-cy="client-price">{{ $t('from') }} {{ minPriceOffer.price | price }}</strong>
                        <discount-label :price="minPriceOffer.price" />
                        <small class="mt-2">
                            <commission-amount :commission="minPriceOffer.price.commission" />
                        </small>
                    </v-col>
                </v-row>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn text icon @click.stop="toggleOffers">
                    <v-icon v-if="!expanded">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
            <div v-if="expanded">
                <transfer-offer-card
                    v-for="(offer, offerIndex) in product.offers.slice(0, expanded)"
                    :key="offerIndex"
                    :offer="offer"
                    :is-return-transfer="isReturnTransfer"
                    :full-card="false"
                    :data-cy="`transfer-offer-card-${offerIndex}`"
                    :is-add-on="isAddOn"
                    @select-transfer-offer="selectTransferOffer"
                >
                    <template v-slot:price>
                        <slot name="price" :offer="offer" :offer-key="offer.offerKey" />
                    </template>
                </transfer-offer-card>
                <v-list-item
                    v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                    dense
                    class="d-flex justify-center"
                    @click.stop="expanded = product.offers.length"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import TransferOfferCard from '~src/components/transfers/info/transferOfferCard.src'
    import {authStore, transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {TransferDriverLanguages, CommissionAmount, DiscountLabel, TransferOfferCard, SupplierNameLabel},
    })
    export default class TransferCard extends Vue {
        @Prop({default: () => []}) product
        @Prop() isReturnTransfer
        @Prop() isFirst
        @Prop({default: false, type: Boolean}) hidePrice
        @Prop({default: false, type: Boolean}) isAddOn

        expanded = 0
        imgError = false

        mounted() {
            if (this.isReturnTransfer && this.isFirst) {
                this.expanded = this.expandedLimit
            }
        }

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }

        @Emit()
        selectTransferOffer(offer) {
            return offer
        }

        get langCodes() {
            return this.product.offers.reduce((arr, e) => {
                e.langCodes.forEach(lang => {
                    if (!arr.includes(lang)) {
                        arr.push(lang)
                    }
                })
                return arr
            }, [])
        }

        get expandedLimit() {
            if (this.$config.expandOnClickAllTransfers === true) {
                return this.product.offers.length
            }

            return 3
        }

        get minPriceOffer() {
            if (this.$route.name.includes('tour') || this.$route.name === 'home') {
                return this.product.offers.reduce(
                    (a, b) => (a.notDeltaPrice.amount < b.notDeltaPrice.amount ? a : b),
                    this.product.offers[0]
                )
            }

            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        get productLink() {
            return transfersRuntimeStore.infoPageLink(this.product, transfersStore.searchRequest)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
