<template>
    <div v-if="showPrice && price.discount && !price.hiddenDiscount">
        <del data-cy="before-discount-price" class="body-2 d-block">{{ priceBeforeDiscount | price }}</del>
        <v-chip color="accent" data-cy="discount-percentage" label x-small>
            &minus;{{ discountLabel }}%{{ price.promoCode ? ' ' + $t('by_promo_code') : '' }}
        </v-chip>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class DiscountLabel extends Vue {
        @Prop({required: true}) price

        get priceBeforeDiscount() {
            const originalCurrency = this.price.originalCurrency,
                originalAmount = originalCurrency
                    ? (this.price.originalAmount / (1 - this.price.discount / 100)).toFixed(2)
                    : undefined
            return {
                amount: (this.price.amount / (1 - this.price.discount / 100)).toFixed(2),
                currency: this.price.currency,
                originalCurrency,
                originalAmount,
            }
        }

        get discountLabel() {
            return this.price.discount.toFixed(2)
        }

        get showPrice() {
            return true
        }
    }
</script>
