<template>
    <v-expansion-panels
        flat
        style="background-color: transparent"
        tile
        :light="!dark"
        :dark="dark"
        class="additional-options"
    >
        <v-expansion-panel style="background-color: transparent">
            <slot name="header">
                <v-expansion-panel-header class="pl-1 width-auto" style="color: #626262">
                    {{ $t('extra_options') }}
                </v-expansion-panel-header>
            </slot>
            <v-expansion-panel-content eager>
                <slot />
                <v-form ref="additional-form">
                    <v-row>
                        <v-col v-if="enabled('rating')" cols="12">
                            <select-option
                                name="category"
                                :items="ratingItems"
                                :label="$t('filterTitles.category')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                autocomplete-disabled
                                :light="!dark"
                                :dark="dark"
                            />
                        </v-col>
                        <v-col v-if="enabled('meal')" cols="12">
                            <select-option
                                name="mealType"
                                :items="mealTypeItems"
                                :label="$t('filterTitles.mealTypes')"
                                multiple
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :clearable="false"
                                autocomplete-disabled
                                :light="!dark"
                                :dark="dark"
                            />
                        </v-col>
                        <v-col v-if="enabled('flight-class')" cols="12">
                            <select-option
                                name="flightClass"
                                :items="flightClasses"
                                :label="$t('flights.options.class')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :default-value="flightClassDefaultValue"
                                :light="!dark"
                                :dark="dark"
                            />
                        </v-col>
                        <v-col v-if="enabled('airlines')" cols="12">
                            <select-option
                                name="airlineCodes"
                                :multiple="true"
                                :items="airlines"
                                :label="$t('flights.options.airlines')"
                                small-cheaps
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :light="!dark"
                                :dark="dark"
                            />
                        </v-col>
                        <template v-if="enabled('flight-options')">
                            <v-col cols="12">
                                <checkbox-option
                                    name="baggageOnly"
                                    :label="$t('flights.options.baggage_only')"
                                    :default-value="false"
                                    :dark="false"
                                />
                            </v-col>
                            <v-col cols="12" :class="$i18n.locale === 'ru' ? 'ref-up-on-small' : ''">
                                <checkbox-option
                                    name="refundableOnly"
                                    :label="$t('flights.options.refundable_only')"
                                    :default-value="false"
                                    :dark="false"
                                />
                            </v-col>
                            <v-col cols="12">
                                <checkbox-option
                                    name="directFlight"
                                    :label="$t('flights.options.direct_only')"
                                    :default-value="false"
                                    :dark="false"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('transfer-type')">
                            <v-col cols="12">
                                <checkbox-option
                                    name="privateTransfer"
                                    :label="$t('transfers.private_transfer')"
                                    :default-value="true"
                                    :dark="false"
                                />
                            </v-col>
                            <v-col cols="12">
                                <checkbox-option
                                    name="sharedTransfer"
                                    :label="$t('transfers.shared_transfer')"
                                    :default-value="true"
                                    :dark="false"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('activity-type')">
                            <v-col cols="12">
                                <checkbox-option
                                    name="privateActivity"
                                    :label="$t('activities.private_activity')"
                                    :default-value="true"
                                    :dark="false"
                                />
                            </v-col>
                            <v-col cols="12">
                                <checkbox-option
                                    name="sharedActivity"
                                    :label="$t('activities.shared_activity')"
                                    :default-value="true"
                                    :dark="false"
                                />
                            </v-col>
                        </template>
                        <v-col v-if="enabled('citizenship') && !isCitizenshipMainOption" cols="12">
                            <select-option
                                :key="`citizenshipId-${defaultCitizenshipId}`"
                                name="citizenshipId"
                                :items="citizenshipItems"
                                :label="$t('citizenship')"
                                :default-value="defaultCitizenshipId"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :light="!dark"
                                :dark="dark"
                                @change="changeCitizenship"
                            />
                        </v-col>
                        <v-col v-if="enabled('driverCitizenship')" cols="12">
                            <select-option
                                name="driverCitizenshipId"
                                :items="citizenshipItems"
                                :label="$t('citizenship')"
                                :default-value="defaultCitizenshipId"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :light="!dark"
                                :dark="dark"
                            />
                        </v-col>
                        <v-col v-if="enabled('promocode') && searchFormOptions.promoCode" cols="12">
                            <text-option
                                name="promoCode"
                                :label="$t('promocode')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                dense
                                :dark="false"
                            />
                        </v-col>
                        <v-col v-if="enabled('passengers')" cols="12">
                            <adults-select name="passengers" :label="$t('carsRent.number_of_passengers')" />
                        </v-col>
                        <v-col v-if="enabled('availableOnly')" cols="12">
                            <checkbox-option
                                :dark="false"
                                name="availableOnly"
                                :label="$t('carsRent.availableOnly')"
                                :default-value="false"
                            />
                        </v-col>
                        <v-col v-if="enabled('freeOnly')" cols="12">
                            <checkbox-option
                                name="freeOnly"
                                :label="$t('free_only')"
                                :default-value="false"
                                :dark="false"
                            />
                        </v-col>
                        <v-col v-if="enabled('adultsOnly')" cols="12">
                            <checkbox-option
                                name="services"
                                :dark="false"
                                :value-name="'ADULTS_ONLY'"
                                :label="$t('service.adults_only')"
                            />
                        </v-col>
                        <v-col v-if="searchFormOptions.showCommission && (isAgency || isTourOperator)" cols="12">
                            <checkbox-option
                                name="showCommission"
                                :label="$t('show_commission')"
                                :default-value="true"
                                :dark="false"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import AdditionalOptionsWrapper from '~/components/snippets/AdditionalSearchOptions/AdditionalOptionsWrapper'
    import {Component, Prop} from 'nuxt-property-decorator'
    import {runtimeStore} from '~/store'

    @Component
    export default class AdditionalOptionsWrapperMod extends AdditionalOptionsWrapper {
        @Prop({default: false, type: Boolean}) dark

        get citizenshipItems() {
            return runtimeStore.countries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }
    }
</script>
