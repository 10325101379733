var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupDetails),expression:"groupDetails"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.guestsPrepared,"items-per-page":15,"hide-default-header":_vm.isMobile,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-slide-x-transition',[(
                                        _vm.isExcursion &&
                                        (!_vm.selectedGuests[0] || _vm.selectedGuests[0].orderId === item.orderId)
                                    )?_c('v-checkbox',{staticClass:"me-2",attrs:{"value":item},model:{value:(_vm.selectedGuests),callback:function ($$v) {_vm.selectedGuests=$$v},expression:"selectedGuests"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-6"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(((item.firstName) + " " + (item.lastName)))+" ")]),_vm._v(" "),(item.departure.pickUpSign)?_c('span',{staticClass:"text-caption text--secondary font-weight-medium",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                                    "+_vm._s(((_vm.$t('transfers.pickup_sign')) + ": " + (item.departure.pickUpSign)))+"\n                                ")]):_vm._e(),_vm._v(" "),(!item.adult)?_c('span',{staticClass:"text-caption text--secondary",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(((_vm.$t('age')) + ": " + (item.age))))]):_vm._e()])],1),_vm._v(" "),_vm._l((item.extraGuests),function(guest){return _c('div',{key:guest.id,staticClass:"d-flex align-center",staticStyle:{"border-top":"1px solid #eeeeee"}},[_c('v-slide-x-transition',[(
                                        _vm.isExcursion &&
                                        (!_vm.selectedGuests[0] || _vm.selectedGuests[0].orderId === guest.orderId)
                                    )?_c('v-checkbox',{staticClass:"me-2",attrs:{"value":guest},model:{value:(_vm.selectedGuests),callback:function ($$v) {_vm.selectedGuests=$$v},expression:"selectedGuests"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column py-6"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(((guest.firstName) + " " + (guest.lastName))))]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[(guest.departure.pickUpSign)?_c('span',{staticClass:"text-caption text--secondary font-weight-medium",staticStyle:{"white-space":"nowrap"}},[_vm._v("\n                                        "+_vm._s(((_vm.$t('transfers.pickup_sign')) + ": " + (guest.departure.pickUpSign)))+"\n                                    ")]):_vm._e()]),_vm._v(" "),(!guest.adult)?_c('span',{staticClass:"text-caption text--secondary",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(((_vm.$t('age')) + ": " + (guest.age))))]):_vm._e()])],1)})],2)])]}},{key:"item.orderId",fn:function(ref){
                                    var item = ref.item;
return [(_vm.isMobile)?_c('span',{staticClass:"primary--text text-decoration-underline",on:{"click":function($event){_vm.detailsMobileDialogItem = item
                        _vm.showDetailsMobileDialog = true}}},[_vm._v(_vm._s(item.orderId)+"\n                ")]):_c('span',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(item.orderId)+" ")])]}},{key:"item.arrived",fn:function(ref){
                        var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.setArrived(item, $event)}},model:{value:(item.arrivedAll),callback:function ($$v) {_vm.$set(item, "arrivedAll", $$v)},expression:"item.arrivedAll"}})]}},{key:"item.orderStatus",fn:function(ref){
                        var item = ref.item;
return [_c('span',{class:item.statusColor},[_vm._v("\n                    "+_vm._s(_vm.$t(("bookingStatus." + (item.orderStatus.toUpperCase()))))+"\n                ")])]}},{key:"item.accommodation",fn:function(ref){
                        var item = ref.item;
return [(item.accommodation)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"d-flex font-weight-medium justify-end justify-md-start"},[_c('v-icon',{staticClass:"me-1 ms-n1 d-inline-block",attrs:{"small":""}},[_vm._v(_vm._s('mdi-office-building'))]),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.accommodation.hotelName)}})],1),_vm._v(" "),_c('div',[_vm._v("\n                        "+_vm._s(((item.accommodation.counrtyName) + ", " + (item.accommodation.cityName)))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"text--secondary"},[_vm._v("\n                        "+_vm._s(((_vm.getDateFormatted(item.accommodation.startDateTime)) + " - " + (_vm.getDateFormatted(
                                item.accommodation.endDateTime
                            ))))+"\n                    ")])]):_vm._e()]}},{key:"item.departure",fn:function(ref){
                            var item = ref.item;
return [(item.departure)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',[_vm._v("\n                        "+_vm._s(("" + (item.departure.place)))+"\n                    ")])]):_vm._e()]}},{key:"item.arrival",fn:function(ref){
                            var item = ref.item;
return [(item.arrival)?_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',[_vm._v("\n                        "+_vm._s(("" + (item.arrival.place)))+"\n                    ")])]):_vm._e()]}},{key:"item.pricePrintable",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(("" + (item.pricePrintable)))+" ")]),_vm._v(" "),_c('payment-status-label',{attrs:{"status":item.paymentStatus}})],1)]}}])})],1),_vm._v(" "),(_vm.isMobile && _vm.detailsMobileDialogItem)?_c('group-details-mobile-dialog',{attrs:{"details-mobile-dialog-item":_vm.detailsMobileDialogItem},model:{value:(_vm.showDetailsMobileDialog),callback:function ($$v) {_vm.showDetailsMobileDialog=$$v},expression:"showDetailsMobileDialog"}}):_vm._e(),_vm._v(" "),_c('v-expand-transition',[_c('div',{staticClass:"d-flex"},[(_vm.isMobile)?_c('v-spacer'):_vm._e(),_vm._v(" "),(_vm.selectedGuests[0] && _vm.isExcursion)?_c('v-btn',{staticClass:"my-4",attrs:{"color":'primary'},on:{"click":_vm.showCalendarModal}},[_vm._v("\n                "+_vm._s(_vm.$t('up_sell'))+"\n            ")]):_vm._e()],1)]),_vm._v(" "),_c('activity-calendar-modal',{attrs:{"city":_vm.city,"query-params":_vm.activityCalendarQueryParams},on:{"close":_vm.clearSelectedOrderId},model:{value:(_vm.isShowCalendarModal),callback:function ($$v) {_vm.isShowCalendarModal=$$v},expression:"isShowCalendarModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }