<template>
    <v-dialog v-model="modal" overlay-opacity="0" width="100%" max-width="600" @click:outside="closeModal">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-if="loaded" justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="closeModal">mdi-close</v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.pay_invoices_title') }} {{ orderId }}
                </v-card-title>
            </div>
            <v-card-subtitle v-if="paymentMethods.length" class="mt-2 mb-0 py-0">
                {{ $t('invoices.pay_invoices_subtitle') }}
            </v-card-subtitle>
            <v-card-text v-if="paymentMethods.length">
                <v-radio-group v-model="invoicePaymentKey">
                    <template v-for="paymentMethod in paymentMethods">
                        <v-radio :key="paymentMethod.id" :value="paymentMethod.invoicePaymentKey">
                            <template v-slot:label>
                                <span>
                                    {{
                                        paymentMethod.paymentMethodCode
                                            ? paymentMethod.paymentMethodCode
                                            : paymentMethod.paymentMethod === 'BANK_TRANSFER'
                                            ? $t('bank_transfer.bank_transfer')
                                            : $t('deposit')
                                    }}
                                </span>
                                &nbsp;
                                <strong>{{ paymentMethod.paymentAmount | priceFormat(paymentMethod.currency) }}</strong>
                            </template>
                        </v-radio>
                    </template>
                </v-radio-group>
                <div id="dropin-container" />
            </v-card-text>
            <v-alert v-if="depositError" prominent type="error" dismissible @input="closeAlert">
                <div>{{ depositError }}</div>
            </v-alert>
            <v-card-actions v-if="paymentMethods.length" class="justify-end pa-6">
                <v-btn class="black--text mr-4" color="white" :disabled="payLoading" @click="closeModal">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    class="white--text"
                    color="primary"
                    :disabled="!invoicePaymentKey"
                    :loading="payLoading"
                    @click="pay"
                >
                    {{ $t('pay') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <payment-redirect-form ref="paymentRedirectForm" />
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, PAY_ORDER_EVENT, PAY_SERVICE_EVENT, SHOW_NOTIFICATIONS} from '@/utils/event-bus'
    import {invoiceIssueInfo, invoicePaymentByKey, invoicePaymentMethods, invoices, issueInvoice} from '~/api'
    import {persistentStore} from '@/utils/store-accessor'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import {loadStripe} from '@stripe/stripe-js'
    import AdyenCheckout from '@adyen/adyen-web'

    //TODO Need refactoring with BookingMixin
    @Component({
        components: {
            PaymentRedirectForm,
        },
    })
    export default class InvoicesPayModal extends Vue {
        modal = false
        loaded = false
        orderId = null
        objectId = null
        paymentMethods = []
        paymentInitiateRs = {}
        invoicePaymentKey = null
        payLoading = false
        invoiceType = 'SUPPLIER'
        depositError = false
        invoiceId = null

        created() {
            EventBus.$on(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$on(PAY_ORDER_EVENT, this.payServiceModal)
        }

        beforeDestroy() {
            EventBus.$off(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$off(PAY_ORDER_EVENT, this.payServiceModal)
        }

        closeModal() {
            this.modal = false
            this.depositError = false
            this.invoicePaymentKey = false
        }

        closeAlert() {
            this.depositError = false
        }

        async payServiceModal(obj) {
            this.loaded = false
            this.modal = true
            this.orderId = obj.orderId
            this.objectId = obj.objectId
            this.invoiceId = obj.invoiceId
            if (obj.invoiceType) {
                this.invoiceType = obj.invoiceType
            }

            await this.invoicePaymentMethods()

            this.loaded = true
        }

        async invoicePaymentMethods() {
            try {
                let invoiceIds = []

                if (!this.invoiceId) {
                    const issueInvoicesInfo = await invoiceIssueInfo.get({
                        orderId: this.objectId,
                        invoiceType: this.invoiceType,
                    })

                    if (issueInvoicesInfo.transactions.length) {
                        const serviceIds = issueInvoicesInfo.transactions.map(transaction => {
                            return transaction.serviceId
                        })

                        await issueInvoice.post({
                            serviceIds: serviceIds,
                            invoiceType: this.invoiceType,
                        })
                    }

                    const orderInvoices = await invoices.get({
                        orderId: this.objectId,
                        invoiceType: this.invoiceType,
                    })

                    invoiceIds = orderInvoices.invoices
                        .filter(invoice => {
                            return invoice.paymentStatus == 'BILL'
                        })
                        .map(invoice => {
                            return invoice.invoiceId
                        })
                } else {
                    invoiceIds = [this.invoiceId]
                }
                if (invoiceIds.length) {
                    const paymentMethods = await invoicePaymentMethods.get({
                        orderId: this.objectId,
                        invoiceId: invoiceIds,
                        invoiceType: this.invoiceType,
                    })
                    this.paymentMethods = paymentMethods.invoicePaymentMethods.filter(paymentMethod => {
                        return ['DEPOSIT_PAYMENT', 'PAYMENT_SYSTEM_PROVIDER', 'BANK_TRANSFER'].includes(
                            paymentMethod.paymentMethod
                        )
                    })
                }
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        get stripeOkToSubmit() {
            return this.$refs.stripeCard ? this.$refs.stripeCard[0].okToSubmit : undefined
        }

        async pay() {
            this.payLoading = true

            const paymentMethod = this.paymentMethods.find(
                paymentMethod => paymentMethod.invoicePaymentKey === this.invoicePaymentKey
            )

            // DEPOSIT
            if (!paymentMethod.paymentMethodCode) {
                try {
                    await invoicePaymentByKey.put({
                        invoicePaymentKey: this.invoicePaymentKey,
                    })
                    this.modal = false
                    this.$emit('issuedInvoice', this.invoiceType)
                } catch (error) {
                    this.depositError = this.$t('error_message.deposit_error')
                }
            } else {
                await this.makeOnlinePayment()
            }

            this.payLoading = false
        }

        //TODO MAKE REFACTORING IN ALL 3 PLACES
        async makeOnlinePayment() {
            try {
                const paymentCompletePageQuery = status => {
                    return {
                        invoicePaymentKey: this.invoicePaymentKey,
                        orderId: this.orderId,
                        status: status,
                    }
                }

                const getPaymentUrl = paymentStatus => {
                    return (
                        window.location.origin +
                        this.$router.resolve({
                            name: 'invoicePaymentComplete',
                            query: paymentCompletePageQuery(paymentStatus),
                        }).href
                    )
                }

                const rq = {
                    invoicePaymentKey: this.invoicePaymentKey,
                }
                const rqOnline = {
                    ...rq,
                    ...{
                        successUrl: getPaymentUrl('success'),
                        failureUrl: getPaymentUrl('failure'),
                        cancelUrl: getPaymentUrl('cancel'),
                    },
                }

                const paymentInitiateRs = await persistentStore.paymentInitiate(rqOnline)

                if (paymentInitiateRs.simpleRedirect) {
                    if (paymentInitiateRs.parameters.length) {
                        const query = new URLSearchParams(
                            paymentInitiateRs.parameters.map(({name, value}) => [name, value])
                        )
                        window.location = paymentInitiateRs.url + '?' + query
                    } else {
                        window.location = paymentInitiateRs.url
                    }
                } else {
                    const paymentMethod = this.paymentMethods.find(
                        paymentMethod => paymentMethod.invoicePaymentKey === this.invoicePaymentKey
                    )

                    if (paymentMethod.paymentMethodCode === 'webpay' && this.$config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        await this.$refs.paymentRedirectForm.submit({
                            url: `${this.$config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else if (paymentMethod.paymentMethodCode === 'stripe') {
                        const {APIPublicKey, sessionId} = paymentInitiateRs.parameters.reduce(
                            (parameters, {name, value}) => {
                                parameters[name] = value
                                return parameters
                            },
                            {}
                        )
                        const stripe = await loadStripe(APIPublicKey)
                        await stripe.redirectToCheckout({sessionId})
                    } else if (paymentMethod.paymentMethodCode === 'adyen') {
                        const findParameter = (name, array) => {
                            return array.find(e => e.name === name)?.value
                        }
                        const checkout = await AdyenCheckout({
                            session: {
                                id: findParameter('SESSION_ID', persistentStore.paymentInitiateResponse.parameters),
                                sessionData: findParameter(
                                    'SESSION_DATA',
                                    persistentStore.paymentInitiateResponse.parameters
                                ),
                            },
                            clientKey: findParameter(
                                'CLIENT_API_KEY',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            environment: findParameter(
                                'ENVIRONMENT',
                                persistentStore.paymentInitiateResponse.parameters
                            ),
                            onPaymentCompleted: res => {
                                if (res.resultCode.toLowerCase() === 'authorised') {
                                    const params = {
                                        paymentNumber: paymentInitiateRs.paymentNumber,
                                        ...res,
                                    }
                                    window.location = `${getPaymentUrl('success')}&${new URLSearchParams(
                                        params
                                    ).toString()}`
                                } else {
                                    window.location = getPaymentUrl('failure')
                                }
                            },
                            onError: () => {},
                        })

                        checkout.create('dropin').mount('#dropin-container')
                    } else {
                        await this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
                return true
            } catch (e) {
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('error_message.payment_initiate_error')],
                    color: 'error',
                })
                return false
            }
        }
    }
</script>
