<template>
    <div class="blog-block">
        <a class="blog-url text-decoration-none" :class="{'cursor-pointer': blogUrl}" :href="blogUrl">
            <main-header :title="$t('blog')" />
        </a>
        <swiper :options="swiperOptions" class="swiper pa-4" @slide-change="updateLoop">
            <swiper-slide v-for="(item, index) in items" :key="index" :class="{'swiper-slide-ssr': swiperSlideSsr}">
                <blog-item :item="item" @selected="search($event)" />
            </swiper-slide>
            <div
                v-if="items.length > showCount"
                id="blogSwiperButtonPrev"
                slot="button-prev"
                class="swiper-button-prev"
            />
            <div
                v-if="items.length > showCount"
                id="blogSwiperButtonNext"
                slot="button-next"
                class="swiper-button-next"
            />
        </swiper>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {cmsStore, hotelsRuntimeStore} from '@/store'
    import BlogItem from '~src/components/parts/blogItem.src'

    //TODO It is Swiper6 workaround
    import {Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import 'swiper/swiper-bundle.min.css'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import MainHeader from '~src/components/snippets/mainHeader.src'

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    Vue.use(getAwesomeSwiper(SwiperClass))
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    @Component({
        components: {
            MainHeader,
            BlogItem,
            Swiper,
            SwiperSlide,
        },
    })
    export default class Blog extends Vue {
        @Prop({required: true}) items

        swiperSlideSsr = false

        created() {
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        async search(cityId) {
            await this.$router.push(
                hotelsRuntimeStore.hotelsPageLink({
                    ...searchRequest(),
                    cityId,
                    citizenshipId: this.$config.defaultCitizenshipId,
                })
            )
        }

        updateLoop(e) {
            e.loopDestroy()
            e.loopCreate()
        }

        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 4 : 5
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                breakpoints: {
                    960: {
                        slidesPerView: 4,
                    },
                    1264: {
                        slidesPerView: 5,
                    },
                },
                navigation: {
                    nextEl: '#blogSwiperButtonNext',
                    prevEl: '#blogSwiperButtonPrev',
                },
            }
        }

        get blogUrl() {
            return cmsStore.homePageSettings.blogUrl ? cmsStore.homePageSettings.blogUrl : 'javascript: void(0);'
        }
    }
</script>

<style scoped>
    div.swiper-button-prev,
    div.swiper-button-next {
        color: white;
        top: 142px;
    }
    div.swiper-button-prev {
        left: 20px;
    }
    div.swiper-button-next {
        right: 20px;
    }
    .blog-url {
        color: #000;
        cursor: default;
    }
    /*.blog-url:hover {
        color: #6699cc;
    }*/
    .swiper-slide-ssr {
        width: 25%;
        margin-right: 20px;
        float: left;
    }
</style>
