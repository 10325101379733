<template>
    <div class="cursor-pointer d-flex align-center" @click="showInfo">
        <v-icon v-if="room.mealTypeOTACode !== 14" class="info--text" left small :color="iconColor"
            >mdi-silverware-fork-knife</v-icon
        >
        <v-icon v-else small left class="info--text" :color="iconColor">mdi-information-outline</v-icon>
        <span class="caption" :class="{'font-weight-light': thin}">{{ label }}</span>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_MEAL_TYPE_INFO_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelMealTypeLabel extends Vue {
        @Prop({required: true}) hotel
        @Prop({required: true}) room
        @Prop({default: 'primary'}) iconColor
        @Prop({default: () => []}) selectedMealTypes
        @Prop({default: false, type: Boolean}) thin

        showInfo() {
            const mealTypeNames = !this.selectedMealTypes.length
                ? [this.room.mealTypeName]
                : this.selectedMealTypes.map(({mealTypeName}) => mealTypeName)
            EventBus.$emit(SHOW_MEAL_TYPE_INFO_EVENT, this.hotel, mealTypeNames)
        }

        get label() {
            if (!this.selectedMealTypes.length) return this.room.mealTypeName
            return this.selectedMealTypes.map(({name, quantity}) => `${name} * ${quantity}`).join(', ')
        }
    }
</script>
