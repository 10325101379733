<template>
    <v-select
        v-if="mealTypes.length"
        :label="$t('change_extra_meal_type')"
        :items="mealTypes"
        item-value="id"
        :item-text="mealTypeLabel"
        :prepend-item="{name: '', value: null}"
        :clearable="clearable"
        :append-icon="appendIcon"
        :value="$attrs.value"
        :disabled="disabled"
        @change="update"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'

    @Component
    export default class MealTypeSelector extends Vue {
        @Prop() roomIndex
        @Prop() standardMealName
        @Prop() appendIcon
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) isTourLead
        @Prop({required: true}) tourist

        mounted() {
            this.update(this.$attrs.value)
        }

        mealTypeLabel({name, prices}) {
            const price = hotelsRuntimeStore.mealTypePrice(prices, this.tourist)
            const selectedMealTypePrice = hotelsRuntimeStore.mealTypePrice(this.selectedMealType.prices, this.tourist)
            const deltaPrice = {
                amount: price.amount - selectedMealTypePrice.amount,
                currency: price.currency,
            }
            return name + (deltaPrice.amount ? ` (${this.$options.filters.price(deltaPrice)})` : '')
        }

        update(value) {
            const mealType = this.mealTypes.find(el => el.id === value)
            if (!mealType) {
                value = null
            }
            this.$emit('input', value)
            this.$emit('update', mealType)
        }

        get selectedMealType() {
            return this.mealTypes.find(({id}) => id === this.$attrs.value)
        }

        get mealTypes() {
            const meals = hotelsStore.extraMealTypes
            return meals[this.roomIndex]
                ? meals[this.roomIndex]
                      .filter(el => hotelsRuntimeStore.mealTypePrice(el.prices, this.tourist))
                      .sort((a, b) =>
                          hotelsRuntimeStore.mealTypePrice(a.prices, this.tourist).amount <
                          hotelsRuntimeStore.mealTypePrice(b.prices, this.tourist).amount
                              ? -1
                              : 1
                      )
                : []
        }

        get clearable() {
            return this.selectedMealType.name !== this.standardMealName
        }
    }
</script>
