<template>
    <v-card
        class="hotel-card"
        :class="{'cursor-pointer': compareMode}"
        outlined
        :hover="compareMode"
        @click.native="selectToCompare(offer)"
    >
        <div class="d-flex wrapper">
            <div style="position: relative">
                <v-img
                    tile
                    :src="offer.thumb | imageUrl(350)"
                    :alt="offer.name"
                    eager
                    width="200"
                    max-width="200"
                    height="200"
                    :lazy-src="'/placeholder.png' | srcHost"
                    class="me-2"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && offer.thumb"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <wishlist-like
                    v-if="showFavorites"
                    :info="favoritesInfo"
                    :query="{
                        supplierCode: offer.supplierCode,
                        hotelCode: offer.hotelCode,
                        cityCode: offer.cityCode,
                        productType: 'hotel',
                    }"
                    :person="person"
                    :product-runtime-store="productRuntimeStore"
                    :in-wishlist="offer.wishlist"
                    style="position: absolute; right: 12px; top: 4px"
                />
            </div>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate" style="position: relative">
                <div class="d-flex align-center flex-wrap">
                    <hotel-stars :hotel="offer" />
                    <hotel-type-label class="ms-1" :offer="offer" />
                    <hotel-own-label class="ms-1" :offer="offer" />
                </div>
                <div class="font-weight-medium text-truncate">
                    <a
                        v-if="$route.name === 'hotels' && infoInNewTab"
                        :href="$router.resolve(hotelPageLink).href"
                        target="_blank"
                        class="text-decoration-none"
                    >
                        {{ offer.name }}
                    </a>
                    <nuxt-link
                        v-else-if="$route.name === 'hotels'"
                        no-prefetch
                        :to="hotelPageLink"
                        class="text-decoration-none"
                    >
                        {{ offer.name }}
                    </nuxt-link>
                    <span v-else>
                        {{ offer.name }}
                    </span>
                    <supplier-name-label
                        v-if="isTO1"
                        :supplier-name="isMultipleSuppliers ? $t('multiple_suppliers') : offer.supplierName"
                    />
                    <div v-if="isAddOn" class="d-flex align-center caption">
                        <v-icon :title="$t('date')" left small>mdi-calendar</v-icon>
                        <span>{{ offer.startDate | dateFormat }} - {{ offer.endDate | dateFormat }}</span>
                    </div>
                </div>
                <hotel-location-label :offer="offer" />
                <hotel-address-label :address="offer.address" :offer="offer" />
                <hotel-services :offer="offer" class="mt-2" />
                <!--v-chip
                    v-for="(classification, classificationIndex) in offer.classifications"
                    :key="classificationIndex"
                    x-small
                    class="ma-1"
                    v-text="classification"
                /-->
                <tripadvisor-rating-wrapper
                    v-if="!hideTripadvisorRating"
                    :tripadvisor-rating="offer.tripadvisorRating"
                />
                <slot :min-price-room-offer="minPriceRoomOffer" :hotel-page-link="hotelPageLink" />
                <v-img
                    v-if="offer.recommended && showPreferred"
                    :src="'/preferred.png' | srcHost"
                    :width="120"
                    class="preferred-thumb"
                />
                <v-img
                    v-if="offer.tariffRecommended && showPreferred && isFavoritesPage"
                    :src="'/long-stay.png' | srcHost"
                    :width="120"
                    class="long-stay-fav"
                />
            </div>
            <div v-if="!hideRoomOffers" class="ma-4 price-col flex-shrink-0 text-right" style="position: relative">
                <slot
                    v-if="!(isMultiRoom && isPackage)"
                    name="price"
                    :room="minPriceRoomOffer"
                    :offer="minPriceRoomOffer"
                    :offer-key="minPriceRoomOffer.groupedOffers && minPriceRoomOffer.groupedOffers[0].offerKey"
                >
                    <div style="position: relative">
                        <hotel-offer-price-block
                            v-if="!isAllSoldOut"
                            :offer="offer"
                            :search-request="searchRequest"
                            :hotel-page-link="hotelPageLink"
                        />
                        <sold-out-modal v-else />
                    </div>
                </slot>
                <div
                    class="primary--text cursor-pointer text-no-wrap"
                    data-cy="hotel-card-toggle-offers"
                    @click="expanded = !expanded"
                >
                    <span class="show-rooms-label">
                        {{ expanded ? $t('hide_room_offers') : $t('view_room_offers') }}
                    </span>
                    <span class="show-rooms-label__short">
                        {{ expanded ? $t('hide_room_offers__short') : $t('view_room_offers__short') }}
                    </span>
                    <v-icon color="primary">
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
                <v-img
                    v-if="offer.tariffRecommended && showPreferred"
                    :src="'/long-stay.png' | srcHost"
                    :width="120"
                    class="long-stay"
                />
            </div>
            <recommended-icon v-if="offer.recommended && !showPreferred" class="recommended-thumb" />
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="compareMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :search-request="searchRequest"
                    :is-package="isPackage"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" :offer="room" :offer-key="room.groupedOffers[0].offerKey" />
                    </template>
                </hotel-room-offers-wrapper>
                <slot v-if="isMultiRoom" name="multiRoomSelect">
                    <hotel-multiple-room-select-block
                        :search-request="searchRequest"
                        :offer="offer"
                        :selected-offer-keys="selectedOfferKeys"
                        class="pa-4 pt-0"
                    />
                </slot>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, Vue, Watch} from 'nuxt-property-decorator'
    import {authStore, hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelServices from '~src/components/hotels/snippets/hotelServices.src'
    import TripadvisorRatingWrapper from '~src/components/hotels/snippets/tripadvisorRatingWrapper.src'
    import HotelRoomOffers from '~/components/search/offers/HotelRoomOffers'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import HotelRoomOffersWrapper from '~src/components/search/offers/HotelRoomOffersWrapper'
    import HotelOfferPriceBlock from '~src/components/search/offers/hotelOfferPriceBlock.src'
    import HotelNameAndRating from '~src/components/hotels/snippets/hotelNameAndRating.src'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMultipleRoomSelectBlock from '~src/components/hotels/snippets/hotelMultipleRoomSelectBlock.src'
    import HotelOwnLabel from '~src/components/hotels/snippets/HotelOwnLabel'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelTypeLabel from '@/components/hotels/snippets/HotelTypeLabel'
    import HotelLocationLabel from '~src/components/hotels/snippets/hotelLocationLabel.src'
    import HotelAddressLabel from '~src/components/hotels/snippets/hotelAddressLabel.src'
    import RecommendedIcon from '@/components/search/RecommendedIcon'
    import WishlistLike from '@/components/search/offers/WishlistLike'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import SoldOutModal from '@/components/snippets/SoldOutModal'

    @Component({
        components: {
            WishlistLike,
            RecommendedIcon,
            HotelOwnLabel,
            HotelMultipleRoomSelectBlock,
            HotelPriceNote,
            HotelNameAndRating,
            DiscountLabel,
            HotelServices,
            TripadvisorRatingWrapper,
            HotelRoomOffers,
            CommissionAmount,
            HotelOfferPriceBlock,
            HotelRoomOffersWrapper,
            HotelStars,
            HotelTypeLabel,
            HotelLocationLabel,
            HotelAddressLabel,
            SupplierNameLabel,
            SoldOutModal,
        },
    })
    export default class HotelOfferCard extends Vue {
        @Prop({default: false, type: Boolean}) compareMode
        @Prop({default: () => ({})}) offer
        @Prop({required: true}) searchRequest
        @Prop({default: false, type: Boolean}) hideRoomOffers
        @Prop({default: false, type: Boolean}) hideTripadvisorRating
        @Prop({default: false, type: Boolean}) isPackage
        @Prop({default: () => [], type: Array}) initialSelectedOfferKeys
        @Prop({default: false, type: Boolean}) isAddOn

        expanded = false
        imgError = false
        selectedOfferKeys = []

        @Emit()
        changeSelectedOfferKeys() {
            return this.selectedOfferKeys
        }

        @Watch('selectedOfferKeys')
        onChangeSelectedOfferKeys() {
            this.changeSelectedOfferKeys()
        }

        mounted() {
            this.selectedOfferKeys = [...this.initialSelectedOfferKeys]
        }

        onImgError() {
            this.imgError = true
            return false
        }

        selectToCompare(offer) {
            if (this.compareMode) {
                hotelsStore.SELECT_TO_COMPARE(offer.hotelCode)
            }
        }

        get isFavoritesPage() {
            return this.$route.name === 'favorites'
        }

        get isAllSoldOut() {
            return this.offer.rooms.every(room => room.soldOut)
        }

        get isMultipleSuppliers() {
            return this.offer.rooms?.some(room => room.hotelSupplierName !== this.offer.supplierName)
        }

        get hotelPageLink() {
            return hotelsRuntimeStore.hotelPageLink(this.offer, this.searchRequest)
        }

        get minPriceRoomOffer() {
            if (!this.offer.rooms) return {}

            return hotelsRuntimeStore.minPriceRoomOffer(this.offer)
        }

        get infoInNewTab() {
            return this.$config.infoInNewTab
        }

        get isMultiRoom() {
            return this.searchRequest.rooms?.length > 1
        }

        get person() {
            return authStore.person
        }

        get productRuntimeStore() {
            return hotelsRuntimeStore
        }

        get isTO1() {
            return authStore.isTO1
        }

        get showFavorites() {
            return !!this.person
        }

        get favoritesInfo() {
            return {
                name: this.offer.name,
                thumb: this.offer.thumb,
                address: this.offer.address,
                distance: this.offer.distance,
                district: this.offer.district,
                services: this.offer.services,
                otaCodeServices: this.offer.otaCodeServices,
                category: this.offer.category,
                type: this.offer.type,
                searchRequest: this.searchRequest,
                recommended: this.offer.recommended,
                tariffRecommended: this.offer.tariffRecommended,
            }
        }

        get showPreferred() {
            return false
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        height: 200px;
    }

    .price-col {
        width: 180px;

        .show-rooms-label__short {
            display: none;
        }
    }

    .hotel-card {
        overflow: hidden;
        cursor: unset;
    }

    .recommended-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
    }

    .preferred-thumb {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .long-stay {
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .long-stay-fav {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;

            .show-rooms-label {
                display: none;

                &__short {
                    display: inline;
                }
            }
        }
    }
</style>
