<template>
    <div>
        <breadcrumbs />
        <company-profile :company="company" />
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CompanyProfile from '~src/components/account/companyProfile.src'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component({
        components: {
            CompanyProfile,
            Breadcrumbs,
        },
        middleware: 'auth',
    })
    export default class CompanyProfilePage extends Vue {
        company = {}

        async asyncData({$api, params}) {
            if (params.id == 0) return {company: {contactPerson: {}, phones: []}}

            const rq = !params.id
                ? {relationship: 'OWN', shortResponse: false}
                : {id: params.id, shortResponse: false, active: false}
            try {
                const company = (await $api.companies.get(rq)).object[0]
                if (!company.contactPerson) {
                    company.contactPerson = {}
                }
                return {company}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
