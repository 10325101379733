var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.documentTemplates,"loading":_vm.loading,"disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-h6 ps-3"},[_vm._v("\n            "+_vm._s(_vm.$t('admin.document_templates'))+"\n        ")]),_vm._v(" "),_c('v-btn',{staticClass:"ms-3 mb-2",attrs:{"color":"primary","to":{name: 'newDocumentEditor'}}},[_vm._v("\n            "+_vm._s(_vm.$t('add'))+"\n        ")])]},proxy:true},{key:"item.notificationType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("\n            "+_vm._s(item.documentType[0].toUpperCase() + item.documentType.slice(1))+"\n        ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"nuxt":"","to":{
                name: 'documentEditor',
                params: {id: item.id},
            },"icon":"","small":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }