<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <cruises-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <cruises-search-summary search-disabled :search-request="searchRequest" :dates="dates" />
            <cruise-order-summary :product="product" :offer="offer" :info="basketItem.info" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import CruisesBreadcrumbs from '@/components/cruises/CruisesBreadcrumbs'
    import CruisesSearchSummary from '@/components/cruises/search/CruisesSearchSummary'
    import CruiseOrderSummary from '@/components/cruises/booking/CruiseOrderSummary'
    import CruisesPageMixin from '@/components/cruises/mixins/CruisesPageMixin.vue'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'

    @Component({
        components: {
            CruiseOrderSummary,
            CruisesSearchSummary,
            CruisesBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
        },
        layout: 'blank',
    })
    export default class CruiseConfirmationPage extends mixins(
        CruisesPageMixin,
        CruisesStoreMixin,
        ConfirmationPageMixin
    ) {
        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        get dates() {
            return `${this.formattedDate(this.searchRequest.startDate)} - ${this.formattedDate(
                this.searchRequest.endDate
            )}`
        }

        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
