<template>
    <v-container :fill-height="!loaded" column fluid>
        <template v-if="loaded">
            <breadcrumbs v-if="!isMobile" id="breadcrumbs" class="mb-4" />
            <order-details
                ref="order"
                @cancelService="loadOrder"
                @modifyService="loadOrder"
                @issuedInvoice="issuedInvoice"
                @issuedTicket="loadOrder"
                @load-order="loadOrder"
            />
        </template>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <v-dialog v-if="isShowConfirmationDialog" v-model="confirmationStatusModal" hide-overlay width="500px">
            <v-card>
                <v-card-text class="pt-5">
                    <confirmation-status v-if="bookingConfirmation === 'order'" :company="company" />
                    <invoice-payment-status v-if="bookingConfirmation === 'invoice'" :order-id="$route.params.id" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn color="primary" class="mx-auto" text @click="closeConfirmationModal">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <order-service-book-modal />
        <send-email-modal />
        <contact-details-modal />
    </v-container>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import Breadcrumbs from '~src/components/breadcrumbs.src'
    import {authStore, persistentStore, runtimeStore} from '~/store'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import OrderDetails from '~src/components/account/orderDetails.src'
    import {CHANGE_LOCALE_EVENT, EventBus, ORDER_SERVICE_BOOKED, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import InvoicePaymentStatus from '@/components/booking/InvoicePaymentStatus'
    import OrderServiceBookModal from '@/components/account/OrderServiceBookModal'
    import SendEmailModal from '@/components/account/modals/SendEmailModal'
    import ContactDetailsModal from '@/components/account/modals/ContactDetailsModal'

    async function showConfirmationModal() {
        try {
            const rs = await this.$api.companies.get({relationship: this.relationship})
            this.company = rs.object[0]
            this.bookingConfirmation = 'order'
            this.confirmationStatusModal = true
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    @Component({
        components: {
            SendEmailModal,
            OrderServiceBookModal,
            InvoicePaymentStatus,
            ConfirmationStatus,
            OrderDetails,
            Breadcrumbs,
            ContactDetailsModal,
        },
        middleware: 'orderAuth',
        layout: 'blank',
    })
    export default class OrderDetailsPage extends Vue {
        confirmationStatusModal = false
        company = {
            phones: [],
        }
        bookingConfirmation = 'order'
        loaded = false

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.loadOrder)
            EventBus.$on(ORDER_SERVICE_BOOKED, this.serviceBookConfirmation)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.loadOrder)
            EventBus.$off(ORDER_SERVICE_BOOKED, this.serviceBookConfirmation)
        }

        async mounted() {
            await this.$store.restored
            if (persistentStore.selectedOrderId) {
                persistentStore.SET_SELECTED_ORDER_ID(null)
            }
            await this.loadOrder()
        }

        beforeRouteEnter(to, from, next) {
            if (from.name && (from.name === 'paymentComplete' || from.name.indexOf('Booking') !== -1)) {
                next(async vm => await showConfirmationModal.call(vm))
            }
            if (from.name && from.name === 'invoicePaymentComplete') {
                next(async vm => {
                    vm.bookingConfirmation = 'invoice'
                    vm.confirmationStatusModal = true
                })
            }
            next()
        }

        async serviceBookConfirmation() {
            await this.loadOrder()
            await showConfirmationModal.call(this)
        }

        async loadOrder() {
            this.loaded = false
            const result = await runtimeStore.loadOrder({
                orderType: authStore.orderType,
                orderId: this.$route.params.id,
            })
            if (result?.status === 500 && result?.msg === 'Network Error') {
                return this.$nuxt.error({
                    statusCode: result.status,
                    message: this.$t('error_message.network_error'),
                    note: this.$t('notes.need_internet_connection'),
                })
            } else if (!result) {
                return this.$nuxt.error({statusCode: 404})
            }
            this.loaded = true
        }

        async issuedInvoice(invoiceType) {
            await this.loadOrder()
            const order = runtimeStore.orderDetails
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: order.orderId,
                objectId: order.objectId,
                invoiceType: invoiceType,
                order,
            })
        }

        closeConfirmationModal() {
            this.confirmationStatusModal = false

            persistentStore.SET_BOOK_RESPONSE([
                {
                    orderId: null,
                    refNumber: null,
                    status: null,
                },
            ])
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get isShowConfirmationDialog() {
            return !!this.bookResponsePersistent?.orderId
        }

        get bookResponsePersistent() {
            return persistentStore.bookResponse[0]
        }

        get relationship() {
            return authStore.isCorporate || authStore.isTO2 || authStore.isAgency ? 'SUPPLIER' : 'OWN'
        }
    }
</script>
