<template>
    <v-layout>
        <cruise-booking-page-layout
            v-if="loaded"
            :search-request="searchRequest"
            :offer="offer"
            :product="product"
            :product-store="productStore"
        >
            <template v-slot:tourists="{tourists, index, disabled, personSelected}">
                <tourist-form
                    v-model="tourists[index]"
                    :disabled="disabled"
                    :index="index"
                    :offer="offer"
                    :hide-index="tourists.length === 1"
                    :selected-tourists="tourists"
                    :start-date="startDate"
                    :data-cy="`tourist-${index}`"
                    @person-selected="personSelected"
                />
                <tourist-passport-form
                    :index="index"
                    :disabled="disabled"
                    :corporate-policy-violation="offer.corporatePolicyViolation"
                    expanded-all
                />
            </template>
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
                <cruises-breadcrumbs :search-request="searchRequest" :product="product" />
            </template>
            <template v-slot:orderCommentsForm="{bookingKey}">
                <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
            </template>
            <template v-slot:sidebar="{bookingKey, selectedAddOns}">
                <cruises-search-summary search-disabled :search-request="searchRequest" :dates="dates" />
                <cruise-order-summary
                    :offer="offer"
                    :info="basketItem.info"
                    :prebooked="!!bookingKey"
                    :selected-add-ons="selectedAddOns"
                />
                <cruise-offer-info-modal />
            </template>
        </cruise-booking-page-layout>
        <conditions-modal>
            <template v-slot:info>
                <cruise-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '@/components/booking/forms/BookingCommentsForm'
    import CruiseBookingPageLayout from '@/components/cruises/booking/CruiseBookingPageLayout'
    import CruisesBreadcrumbs from '@/components/cruises/CruisesBreadcrumbs'
    import CruisesSearchSummary from '@/components/cruises/search/CruisesSearchSummary'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'
    import CruisesPageMixin from '@/components/cruises/mixins/CruisesPageMixin.vue'
    import CruiseOrderSummary from '@/components/cruises/booking/CruiseOrderSummary'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import CruiseConditionsModalInfo from '@/components/cruises/snippets/CruiseConditionsModalInfo'
    import CruiseOfferInfoModal from '@/components/cruises/info/CruiseOfferInfoModal'
    import TouristForm from '@/components/booking/forms/TouristForm'
    import TouristPassportForm from '@/components/booking/forms/TouristPassportForm'

    @Component({
        components: {
            TouristPassportForm,
            TouristForm,
            CruiseOfferInfoModal,
            CruiseConditionsModalInfo,
            ConditionsModal,
            CruiseOrderSummary,
            CruisesSearchSummary,
            CruisesBreadcrumbs,
            CruiseBookingPageLayout,
            ExpiredOffersSnackbar,
            BookingCommentsForm,
        },
        layout: 'blank',
    })
    export default class CruiseBookingPage extends mixins(CruisesStoreMixin, CruisesPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            await runtimeStore.loadTouristBookingFields({offerKey: this.$route.query.offerKey})
            this.productStore.clearBookingAdditionalOptions()
            await this.productStore.getExtraServiceAdditionalOptions(this.$route.query.offerKey)
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return this.productStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get dates() {
            return `${this.formattedDate(this.offer?.startDate)}`
        }

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }

        get startDate() {
            return this.searchRequest.dateTime
        }
    }
</script>
