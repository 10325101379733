<template>
    <client-only>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :placeholder="$t('start_typing')"
                    single-line
                    hide-details
                    :disabled="isLoading"
                />
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                :loading="isLoading"
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                sort-by="name"
                class="elevation-1"
                data-cy="persons-table"
                :search="search"
            >
                <template v-slot:item.active="{item}">
                    <v-switch
                        v-model="item.active"
                        color="primary"
                        :label="item.active ? $t('active') : $t('inactive')"
                        @change="changeActive(item)"
                    />
                </template>
                <template v-slot:item.edit="{item}">
                    <v-icon color="primary" @click="edit(item.id)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
        </v-card>
    </client-only>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class PersonsTable extends Mixins(LoadMixin) {
        search = ''

        get headers() {
            return [
                {text: this.$t('name'), value: 'name'},
                {text: this.$t('login'), value: 'login'},
                {text: this.$t('role'), value: 'role'},
                {text: this.$t('email'), value: 'email'},
                {text: this.$t('status'), value: 'active', width: '10%'},
                {text: this.$t('edit'), value: 'edit', align: 'center'},
            ]
        }

        get items() {
            return runtimeStore.persons.map(person => {
                const firstName = person.firstName || person.firstNameLocalized || ''
                const lastName = person.lastName || person.lastNameLocalized || ''

                return {
                    id: person.personId,
                    name: `${person.prefix || ''} ${firstName} ${lastName}`,
                    login: person.login,
                    role: this.$t(`roles.${person.role.split('.')[1]}`),
                    email: person.email,
                    active: person.active === 'true',
                }
            })
        }

        get serverItemsLength() {
            return runtimeStore.persons.length
        }

        get isLoading() {
            return runtimeStore.loading
        }

        load() {
            runtimeStore.loadPersons({
                companyId: this.$route.query.company ? this.$route.query.company : authStore.person.companyId,
            })
        }

        edit(id) {
            this.$router.push({name: 'companyPerson', params: {id}})
        }

        async changeActive(item) {
            const {id, active} = item
            try {
                await this.$api.persons.put(id, {active})
            } catch (e) {
                await runtimeStore.loadPersons({companyId: authStore.person.companyId})
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-label {
        font-size: 0.75rem !important;
    }
    ::v-deep .v-input__icon--append {
        display: none;
    }
</style>
