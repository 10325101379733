var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":!_vm.loaded,"column":""}},[(_vm.loaded)?_c('insurance-booking-page-layout',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer,"search-request-country-id":_vm.searchRequestCountryId},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('insurance-breadcrumbs',{attrs:{"search-request":_vm.searchRequest}})]},proxy:true},{key:"tourists",fn:function(ref){
var tourists = ref.tourists;
var index = ref.index;
var personSelected = ref.personSelected;
var bookingKey = ref.bookingKey;
var touristToken = ref.touristToken;
return [_c('tourist-form',{key:index,attrs:{"index":index,"label":touristToken,"disabled":!!bookingKey,"corporate-policy-violation":_vm.offer ? _vm.offer.corporatePolicyViolation : undefined,"hide-index":tourists.length === 1,"selected-tourists":tourists,"start-date":_vm.startDate,"data-cy":("tourist-" + index)},on:{"person-selected":function($event){return personSelected($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tourist = ref.tourist;
var requiredRule = ref.requiredRule;
var fieldLabel = ref.fieldLabel;
return [_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":fieldLabel('insurance.passport_number', 'PASSPORT_NUMBER'),"rules":[requiredRule('insurance.passport_number', 'PASSPORT_NUMBER')],"disabled":!!bookingKey},model:{value:(tourist.passportNumber),callback:function ($$v) {_vm.$set(tourist, "passportNumber", $$v)},expression:"tourist.passportNumber"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('address')) + "*"),"rules":[requiredRule('address')],"disabled":!!bookingKey,"data-cy":"tourist-field-address"},model:{value:(tourist.address),callback:function ($$v) {_vm.$set(tourist, "address", $$v)},expression:"tourist.address"}})],1)]}}],null,true),model:{value:(tourists[index]),callback:function ($$v) {_vm.$set(tourists, index, $$v)},expression:"tourists[index]"}})]}},{key:"forms",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('booking-comments-form',{attrs:{"disabled":!!bookingKey,"product-store":_vm.productStore,"outlined":false}})]}},{key:"sidebar",fn:function(){return [_c('insurance-order-summary',{attrs:{"search-request":_vm.searchRequest,"product":_vm.product,"offer":_vm.offer},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('v-card',{staticClass:"mb-2 pa-0",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('search-summary-content',{attrs:{"search-request":_vm.searchRequest,"tourists-label":"guest","show-dates":"","show-days-count":"","show-tourists":""}})],1)],1)]},proxy:true}],null,false,1259258122)}),_vm._v(" "),_c('insurance-info-modal')]},proxy:true}],null,false,711127960)}):_vm._e(),_vm._v(" "),_c('conditions-modal',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('insurance-conditions-modal-info')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }