<template>
    <v-row>
        <v-col cols="12" md="8" class="d-md-flex justify-center justify-md-space-between">
            <city-airport-autocomplete
                ref="firstLocation"
                v-model="from"
                single-line
                filled
                label="departure_location"
                :rules="[v => !!v || `${$t('departure_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
                class="flex-grow-1 location-field"
            />
            <div class="text-center swap-btn">
                <v-btn icon @click="swapLocations">
                    <v-icon color="white">
                        {{ isMobile ? 'mdi-swap-vertical-bold' : 'mdi-swap-horizontal-bold' }}
                    </v-icon>
                </v-btn>
            </div>
            <city-airport-autocomplete
                ref="secondLocation"
                v-model="to"
                single-line
                filled
                label="arrival_location"
                :rules="[v => !!v || `${$t('arrival_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
                class="flex-grow-1 location-field"
            />
        </v-col>
        <v-col cols="12" md="4">
            <daterange-m :start-date.sync="startDate" :end-date.sync="endDate" product="flights" />
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CityAirportAutocomplete from '~/components/flights/search/forms/CityAirportAutocomplete'
    import {flightsRuntimeStore} from '~/store'
    import FlightSearchFormMixin from '~/mixins/Flights/FlightSearchFormMixin'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component({
        components: {
            CityAirportAutocomplete,
            DaterangeM,
        },
        mixins: [FlightSearchFormMixin],
    })
    export default class RoundTrip extends Vue {
        startDate = null
        endDate = null

        getRoutes() {
            return [
                {
                    departure: this.from,
                    arrival: this.to,
                    date: this.startDate,
                },
                {
                    departure: this.to,
                    arrival: this.from,
                    date: this.endDate,
                },
            ]
        }

        initDates() {
            if (
                flightsRuntimeStore.routes[0] &&
                flightsRuntimeStore.routes[1] &&
                this.$dateFns.isAfter(this.$dateFns.parseISO(flightsRuntimeStore.routes[0].date), new Date())
            ) {
                this.startDate = flightsRuntimeStore.routes[0].date
                this.endDate = flightsRuntimeStore.routes[1].date
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
        }

        swapLocations() {
            const first = {...this.$refs.firstLocation.city}
            const second = {...this.$refs.secondLocation.city}
            this.from = Object.keys(second).length ? second : null
            this.to = Object.keys(first).length ? first : null
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .location-field {
            flex-basis: 100%;
        }
        .swap-btn {
            line-height: 56px;
        }
    }
</style>
