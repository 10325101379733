<template>
    <v-layout :fill-height="!loaded" column>
        <hotel-booking-page-layout
            v-if="loaded"
            ref="layout"
            :search-request="searchRequest"
            :room-offers="roomOffers"
            :offer-keys="offerKeys"
            :offer="offer"
            :search-request-country-id="searchRequestCountryId"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar
                    ref="expiredOffersSnackbar"
                    :is-offers-expired="isOffersExpired"
                    disable-restart
                    @refresh="refreshExpiredOffers"
                />
                <hotel-refresh-offers-dialog
                    ref="refreshOffersDialog"
                    :expired-offer-keys="offerKeys"
                    @refresh="onOffersRefreshed"
                />
                <hotel-breadcrumbs :search-request="searchRequest" :offer="offer" />
                <refresh-offers-citizenship-snackbar :basket-item="basketItem" @refresh="refreshExpiredOffers" />
            </template>
            <template v-slot:touristsCard="{tourists, bookingKey, basketKey}">
                <template v-for="(roomTourists, roomIndex) in tourists">
                    <v-card :key="`tourist-${roomIndex}`" :data-cy="`room-${roomIndex}`" outlined class="mb-5">
                        <v-card-title class="text-capitalize">
                            <v-icon left color="primary">mdi-account</v-icon>
                            <template v-if="tourists.length > 1">
                                {{ `${$tc('room', 1)} ${roomIndex + 1}` }}
                            </template>
                            <template v-else>
                                {{ $t('guests') }}
                            </template>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                            <template v-for="(tourist, index) in roomTourists">
                                <hotel-tourist-form
                                    :key="roomIndex + '_' + index"
                                    v-model="roomTourists[index]"
                                    :room-index="roomIndex"
                                    :index="index"
                                    :disabled="!!bookingKey || !!basketKey"
                                    :show-meal-types="ownProduct"
                                    :standard-meal-name="getMealName(roomIndex)"
                                    :corporate-policy-violation="roomOffers[roomIndex].corporatePolicyViolation"
                                    :hide-index="roomTourists.length === 1"
                                    :start-date="startDate"
                                    :hotel="offer"
                                    :data-cy="`tourist-${index}`"
                                />
                            </template>
                            <early-check-in-late-check-out
                                :room-index="roomIndex"
                                :disabled="!!bookingKey || !!basketKey"
                            />
                        </v-card-text>
                    </v-card>
                    <hotel-extra-services-form
                        v-if="
                            showExtraServices &&
                            productStore.extraServices[roomIndex] &&
                            productStore.extraServices[roomIndex].length
                        "
                        :key="`extra-services-${roomIndex}`"
                        :room-index="roomIndex"
                        :is-disabled="!!bookingKey || !!basketKey"
                        class="mb-5"
                        @updateExtraServices="
                            bookingKey = null
                            basketKey = null
                        "
                    />
                    <hotel-comments-form
                        :key="`comments-${roomIndex}`"
                        :index="roomIndex"
                        :disabled="!!bookingKey || !!basketKey"
                        :product-store="productStore"
                    />
                </template>
            </template>
            <template v-slot:sidebar="{bookingKey, basketKey, selectedAddOns}">
                <hotel-order-summary
                    :offer="offer"
                    :room-offers="roomOffers"
                    :search-request="searchRequest"
                    :prebooked="!!bookingKey || !!basketKey"
                    :selected-add-ons="selectedAddOns"
                >
                    <template v-slot:summary>
                        <v-card flat tile class="mb-2 pa-0">
                            <v-card-text class="pa-0">
                                <search-summary-content
                                    :search-request="searchRequest"
                                    tourists-label="guest"
                                    show-dates
                                    show-nights
                                    show-tourists
                                />
                            </v-card-text>
                        </v-card>
                    </template>
                </hotel-order-summary>
            </template>
        </hotel-booking-page-layout>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore, runtimeStore} from '@/store'
    import HotelBookingPageLayout from '~src/components/hotels/booking/hotelBookingPageLayout.src'
    import HotelTouristForm from '~src/components/booking/forms/hotelTouristForm.src'
    import HotelCommentsForm from '@/components/booking/forms/HotelCommentsForm'
    import HotelOrderSummary from '~src/components/booking/hotelOrderSummary.src'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import {UTCToDate} from '@/utils/helpers'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import HotelTouristPassportForm from '@/components/hotels/booking/form/HotelTouristPassportForm'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import HotelExtraServicesForm from '@/components/booking/forms/HotelExtraServicesForm'
    import EarlyCheckInLateCheckOut from '~/components/booking/forms/fields/EarlyCheckInLateCheckOut'
    import HotelRefreshOffersDialog from '@/components/hotels/modals/HotelRefreshOffersDialog'
    import RefreshOffersCitizenshipSnackbar from '@/components/search/RefreshOffersCitizenshipSnackbar'
    import SearchSummaryContent from '~src/components/parts/searchSummaryContent.src'

    @Component({
        components: {
            RefreshOffersCitizenshipSnackbar,
            HotelExtraServicesForm,
            HotelBreadcrumbs,
            HotelTouristPassportForm,
            ExpiredOffersSnackbar,
            HotelInfoTime,
            HotelOrderSummary,
            HotelCommentsForm,
            HotelTouristForm,
            HotelBookingPageLayout,
            EarlyCheckInLateCheckOut,
            HotelRefreshOffersDialog,
            SearchSummaryContent,
        },
        layout: 'blank',
    })
    export default class HotelBookingPage extends mixins(HotelPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            const promises = []
            promises.push(this.loadRuntimeData())
            promises.push(this.loadTouristBookingFields())
            hotelsStore.clearBookingAdditionalOptions()
            this.offerKeys.forEach(offerKey => {
                promises.push(hotelsStore.loadExtraMealTypes({offerKey}))
            })
            await Promise.all(promises)
            await hotelsRuntimeStore.loadOwnProductPaxes()
            this.loaded = true
        }

        async loadTouristBookingFields() {
            await runtimeStore.loadTouristBookingFields({offerKey: this.offerKeys[0]})
        }

        getMealName(index) {
            return this.roomOffers.length && this.roomOffers[index] ? this.roomOffers[index].mealTypeName : null
        }

        refreshExpiredOffers(hotelId) {
            this.$refs.refreshOffersDialog.search(hotelId)
        }

        onOffersRefreshed() {
            this.$refs.layout.pageLock = false
            this.$refs.expiredOffersSnackbar.start()
        }

        isOffersExpired() {
            //TODO Need check basketKey
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !hotelsStore.prepareBookResponse.bookingKey
            )
        }

        get searchRequestCountryId() {
            return hotelsRuntimeStore.city.countryId
        }

        get offerKeys() {
            return !Array.isArray(this.$route.query.offerKey)
                ? [this.$route.query.offerKey]
                : this.$route.query.offerKey
        }

        get basketItem() {
            return hotelsStore.basketItem(this.offerKeys)
        }

        get offer() {
            return this.basketItem.offer
        }

        get roomOffers() {
            return this.basketItem.roomOffers
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get ownProduct() {
            return hotelsRuntimeStore.ownProduct(this.offer)
        }

        get hotelInfoRequest() {
            const {supplierCode, cityCode, hotelCode, hotelId} = this.offer
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get cityId() {
            return this.searchRequest.cityId
        }

        get productStore() {
            return hotelsStore
        }

        get showExtraServices() {
            return true
        }

        get startDate() {
            return this.searchRequest.startDate
        }
    }
</script>
