<template>
    <v-card outlined class="d-flex flex-column width-max-content">
        <div class="d-flex justify-center">
            <v-date-picker
                :locale="$i18n.locale"
                :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
                :events="functionEvents"
                @update:picker-date="pageChange"
            />
        </div>
        <v-progress-linear v-if="loading" indeterminate />
    </v-card>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class EventsCalendar extends Vue {
        @VModel() groups

        loading = false

        async loadGroups(startDate, endDate) {
            try {
                this.groups = null
                this.loading = true

                this.groups = (
                    await this.$api.schedulerGroups.get({
                        dateStart: startDate,
                        dateEnd: endDate,
                        ...(this.isDriver && {driverId: this.personId}),
                        ...(this.isGuide && {guideId: this.personId}),
                    })
                ).schedulerGroups
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        functionEvents(date) {
            const groupsExists = this.groups?.filter(g => g.dateTime.split(' ')[0] === date)

            if (groupsExists?.length) {
                return groupsExists.every(g => g.status === 'DONE') ? 'success lighten-3' : 'yellow lighten-3'
            }

            return false
        }

        async pageChange(e) {
            const [year, month] = e.split('-')

            const startDate = this.$dateFns.format(new Date(+year, +month - 1, 1), 'yyyy-MM-dd')
            const endDate = this.$dateFns.format(new Date(+year, +month, 0), 'yyyy-MM-dd')

            await this.loadGroups(startDate, endDate)
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.$i18n.locale].weekStartsOn
        }

        get personId() {
            return authStore.person.personId
        }

        get isGuide() {
            return authStore.isGuide
        }

        get isDriver() {
            return authStore.isDriver
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-date-picker-table__events {
            width: 100%;
            height: 100%;
            z-index: -1;
            bottom: 1px;
            left: -2px;

            div {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
