<template>
    <div>
        <v-subheader class="pl-0">
            {{ $t(`filterTitles.${filter}`) }}
        </v-subheader>
        <v-row class="checkbox-container">
            <v-col v-for="(item, index) in partOfValues" :key="item.value" :cols="12" class="py-1">
                <v-checkbox
                    v-model="checked"
                    small
                    :hide-details="true"
                    :class="{'pb-3': index + 1 === partOfValues.length}"
                    class="py-0 my-0"
                    :value="item.value"
                >
                    <template v-slot:label>
                        <div class="d-flex" :class="{'label-wrapper': !$breakpoint.smAndDown}">
                            <hotel-tripadvisor-rating-label :tripadvisor-rating="{rating: item.value}" />
                            <div class="ml-auto filter-qty font-weight-bold">{{ item.count }}</div>
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-btn v-if="values.length > showLimit" block x-small text @click="showAll = !showAll">
            {{ showAll ? $t('hide') : $t('show') }} {{ $t('all') }}
        </v-btn>
        <v-divider />
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import HotelTripadvisorRatingLabel from '~src/components/snippets/hotelTripadvisorRatingLabel.src'

    @Component({
        components: {
            HotelTripadvisorRatingLabel,
        },
    })
    export default class TripadvisorFilterMod extends CheckboxFilter {}
</script>
