<template>
    <product-card-layout-mobile
        :product-link="productLink"
        :name="product.info.name"
        :thumb="product.mainImage"
        :offers-count="product.offers && product.offers.length"
        :expanded.sync="expanded"
        :expanded-limit.sync="expandedLimit"
        :is-recommended="product.recommended"
    >
        <template v-slot>
            <div
                v-if="product.shortDescription"
                class="short-description mt-3"
                v-html="$options.filters.stripHtml(product.shortDescription)"
            />
            <div v-if="expanded" class="mt-3 date-filter">
                <activity-date-filter v-model="dateFilter" :offers="product.offers" />
            </div>
            <v-divider v-else-if="product.shortDescription" class="my-3" />
        </template>
        <template v-slot:price>
            <div>
                <div class="font-weight-medium text-h6">
                    <span>{{ $t('from') }} {{ minPriceOffer.price | price }}</span>
                </div>
                <discount-label :price="minPriceOffer.price" />
                <commission-amount :commission="minPriceOffer.price.commission" />
            </div>
        </template>
        <template v-slot:offers>
            <activity-grouped-offers :offers="product.offers" :date-filter="dateFilter" :hide-price="hidePrice" />
            <template v-if="groupOffersBy === 'none'">
                <activity-offer-card
                    v-for="(offer, offerIndex) in sortedOffers"
                    :key="offerIndex"
                    :offer="offer"
                    :full-card="false"
                    :data-cy="`activity-offer-card-${offerIndex}`"
                />
                <v-list-item
                    v-if="expanded === expandedLimit && filteredOffers.length > expandedLimit"
                    dense
                    class="d-flex justify-center"
                    @click.stop="
                        expanded = product.offers.length
                        expandedLimit = product.offers.length
                    "
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </template>
        </template>
    </product-card-layout-mobile>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import ActivityCard from '@/components/activities/search/offers/ActivityCard'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityGroupedOffers from '~/components/activities/search/offers/ActivityGroupedOffers'
    import ProductCardLayoutMobile from '~/components/search/ProductCardLayoutMobile'

    @Component({
        components: {
            ActivityGroupedOffers,
            DiscountLabel,
            CommissionAmount,
            ActivityOfferCard,
            ProductCardLayoutMobile,
        },
    })
    export default class ActivityCardMobile extends ActivityCard {}
</script>
