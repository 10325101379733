<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="180px"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                v-model="time"
                :label="label"
                :rules="rules"
                background-color="white"
                :outlined="outlined"
                :dense="dense"
                prepend-icon="mdi-clock"
                type="time"
                required
                :hide-details="hideDetails"
                v-on="on"
            />
        </template>
        <time-picker v-model="time" @update="menu = false" />
    </v-menu>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TimePicker from '@/components/snippets/forms/TimePicker'

    //TODO Move TimePicker here and rename this to TimePicker
    @Component({
        components: {TimePicker},
    })
    export default class TimeField extends Vue {
        @VModel() time
        @Prop() label
        @Prop() rules
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) hideDetails

        menu = false
    }
</script>
