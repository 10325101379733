<template>
    <div dir="ltr">
        <main-header :title="$t('popular_destinations')" />
        <swiper :options="swiperOptions" class="swiper pa-4" @slide-change="updateLoop">
            <swiper-slide
                v-for="(destination, index) in destinations"
                :key="index"
                :class="{'swiper-slide-ssr': swiperSlideSsr}"
            >
                <popular-destination
                    :destination="destination"
                    :dir="$vuetify.rtl ? 'rtl' : 'ltr'"
                    @selected="search($event)"
                />
            </swiper-slide>
            <div
                v-if="destinations.length > showCount"
                id="destinationSwiperButtonPrev"
                slot="button-prev"
                class="swiper-button-prev"
            />
            <div
                v-if="destinations.length > showCount"
                id="destinationSwiperButtonNext"
                slot="button-next"
                class="swiper-button-next"
            />
        </swiper>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, hotelsRuntimeStore} from '@/store'
    import PopularDestination from '~src/components/parts/popularDestination.src'
    //import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //TODO It is Swiper6 workaround
    import {Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import 'swiper/swiper-bundle.min.css'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import MainHeader from '~src/components/snippets/mainHeader.src'

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    Vue.use(getAwesomeSwiper(SwiperClass))
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    @Component({
        components: {
            PopularDestination,
            Swiper,
            SwiperSlide,
            MainHeader,
        },
    })
    export default class PopularDestinations extends Vue {
        @Prop({required: true}) destinations

        swiperSlideSsr = false

        created() {
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        async search(cityId) {
            await this.$router.push(
                hotelsRuntimeStore.hotelsPageLink({
                    ...searchRequest(),
                    cityId,
                    citizenshipId: this.$config.defaultCitizenshipId || this.person?.citizenshipId,
                })
            )
        }

        updateLoop(e) {
            e.loopDestroy()
            e.loopCreate()
        }

        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 4 : 5
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                breakpoints: {
                    960: {
                        slidesPerView: 4,
                    },
                    1264: {
                        slidesPerView: 5,
                    },
                },
                navigation: {
                    nextEl: '#destinationSwiperButtonNext',
                    prevEl: '#destinationSwiperButtonPrev',
                },
            }
        }

        get person() {
            return authStore.person
        }
    }
</script>

<style scoped>
    div.swiper-button-prev,
    div.swiper-button-next {
        color: white;
        top: 142px;
    }
    div.swiper-button-prev {
        left: 20px;
    }
    div.swiper-button-next {
        right: 20px;
    }
    .swiper-slide-ssr {
        width: 25%;
        margin-right: 20px;
        float: left;
    }
</style>
