<template>
    <v-form>
        <v-row v-show="!!formComponent">
            <v-col cols="12" class="py-8">
                <component :is="formComponent" v-model="product" :product-type="productType" :tourists="tourists" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import OfflineServiceFormFlight from '@/components/account/offlineServices/forms/OfflineServiceFormFlight'
    import OfflineServiceFormAccommodation from '@/components/account/offlineServices/forms/OfflineServiceFormAccommodation'
    import OfflineServiceFormTransfer from '@/components/account/offlineServices/forms/OfflineServiceFormTransfer'
    import OfflineServiceFormCars from '@/components/account/offlineServices/forms/OfflineServiceFormCars'
    import OfflineServiceFormExcursion from '@/components/account/offlineServices/forms/OfflineServiceFormExcursion'

    @Component({
        components: {
            OfflineServiceFormExcursion,
            OfflineServiceFormCars,
            OfflineServiceFormTransfer,
            OfflineServiceFormAccommodation,
            OfflineServiceFormFlight,
        },
    })
    export default class OfflineServiceProductForm extends Vue {
        @Prop() productType
        @Prop() tourists
        @VModel() product

        get formComponent() {
            switch (this.productType) {
                case 'FLIGHT':
                    return 'offline-service-form-flight'
                case 'ACCOMMODATION':
                    return 'offline-service-form-accommodation'
                case 'TRANSFER':
                    return 'offline-service-form-transfer'
                case 'CAR_RENT':
                    return 'offline-service-form-cars'
                case 'EXCURSION':
                    return 'offline-service-form-excursion'
                default:
                    return null
            }
        }
    }
</script>
