<template>
    <v-card outlined class="pa-4">
        <v-card-title>
            <span class="black--text text-h5 font-weight-medium">
                {{ $t('extra_services') }}
            </span>
        </v-card-title>
        <v-card-text>
            <v-row v-if="mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-medium black--text mb-4">
                        {{ $t('extra_services_included') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in mandatoryExtraServices"
                :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
                :extra-service="extraService"
                :hide-action="true"
            />
            <v-row v-if="extraServices.length && mandatoryExtraServices.length">
                <v-col cols="12">
                    <h4 class="text-subtitle-1 font-weight-medium black--text mb-4">
                        {{ $t('extra_services') }}
                    </h4>
                </v-col>
            </v-row>
            <extra-service
                v-for="(extraService, extraServiceIndex) in extraServices"
                :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
                :extra-service="extraService"
                :hide-action="isDisabled"
                @update="setExtraService"
            />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import ExtraServicesForm from '~/components/booking/forms/ExtraServicesForm'

    @Component
    export default class ExtraServicesFormMod extends ExtraServicesForm {}
</script>
