<template>
    <error :error="error" />
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import Error from '~src/components/error.src'

    @Component({
        components: {Error},
        layout: 'centered',
    })
    export default class ErrorLayout extends Vue {
        @Prop() error
    }
</script>

<style lang="scss" scoped>
    .errorContainer {
        width: 100%;
    }
</style>
