<template>
    <div class="d-flex justify-center align-center full-width">
        <div class="full-width">
            <v-row
                v-if="contacts && contacts[0]"
                class="d-flex justify-space-between full-width mx-0"
                style="padding: 40px 0"
            >
                <v-col cols="12" md="4" class="d-flex align-center justify-center">
                    <v-sheet
                        color="primary"
                        width="40"
                        height="40"
                        class="d-flex align-center justify-center rounded-lg me-3"
                    >
                        <v-icon color="white">mdi-map-marker-outline</v-icon>
                    </v-sheet>
                    <span>{{ contacts[0].description }} </span>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center justify-center">
                    <v-sheet
                        color="yellowlime"
                        width="40"
                        height="40"
                        class="d-flex align-center justify-center rounded-lg me-3"
                    >
                        <v-icon color="white">mdi-email-outline</v-icon>
                    </v-sheet>
                    <span>{{ contacts[0].email }}</span>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-center justify-center">
                    <v-sheet
                        color="warning"
                        width="40"
                        height="40"
                        class="d-flex align-center justify-center rounded-lg me-3"
                    >
                        <v-icon color="white">mdi-cellphone</v-icon>
                    </v-sheet>
                    <span>{{ contacts[0].phone }} </span>
                </v-col>
            </v-row>
            <v-row align="center" style="background: #f5f8f8" class="d-flex align-center justify-center mt-0">
                <div
                    :style="{width: isMobile ? '100%' : '80%', padding: '50px 0'}"
                    class="d-flex align-center justify-center"
                >
                    <v-col colas="12" md="6">
                        <map-location
                            :latitude="25.276987"
                            :longitude="55.296249"
                            :height="isMobile ? '228px' : `400px`"
                    /></v-col>
                    <v-col cols="12" md="6">
                        <questions-block-form :class="{'ps-4': !isMobile}" />
                    </v-col>
                </div>
            </v-row>
            <div style="background: white; padding: 80px 0" class="d-flex align-center flex-column">
                <div :style="{width: isMobile ? '100%' : '80%'}">
                    <subscribe-us-block />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'
    import QuestionsBlockForm from '~~/mods/ajbooking/components/parts/QuestionsBlockForm'
    import MapLocation from '~src/components/info/mapLocation.src'
    import SubscribeUsBlock from '~~/mods/ajbooking/components/parts/SubscribeUsBlock'

    @Component({
        components: {SubscribeUsBlock, MapLocation, QuestionsBlockForm},
    })
    export default class QuestionsBlock extends Vue {
        checked = true

        get contacts() {
            return cmsStore.layoutSettings.contacts
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
