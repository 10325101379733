<template>
    <v-dialog v-model="modal" max-width="600" hide-overlay>
        <v-card v-if="!isLoading" class="pa-6">
            <template v-if="company">
                <v-card-title class="pa-0">{{ $t('company') }}</v-card-title>
                <v-list lines="two">
                    <v-list-item v-if="company.countryName" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('country') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ company.countryName }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="company.cityName" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('city') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ company.cityName }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="company.address" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('address') }}:</v-list-item-title>
                            <v-list-item-subtitle>{{ company.address }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="
                            company.contactPerson && (company.contactPerson.firstName || company.contactPerson.lastName)
                        "
                        two-line
                        class="pa-0"
                    >
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('contact_person') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="company.contactPerson.prefix" v-html="company.contactPerson.prefix" />
                                <span v-if="company.contactPerson.firstName" v-html="company.contactPerson.firstName" />
                                <span
                                    v-if="company.contactPerson.middleName"
                                    v-html="company.contactPerson.middleName"
                                />
                                <span v-if="company.contactPerson.lastName" v-html="company.contactPerson.lastName" />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="company.phones && company.phones.length" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('phone') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-for="(phone, index) in company.phones" :key="phone">
                                    <a :href="`tel:${phone}`">{{ phone }}</a>
                                    <span v-if="index !== company.phones.length - 1 && company.phones.length > 1"
                                        >,
                                    </span>
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="company.emails && company.emails.length" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('email') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-for="(email, index) in company.emails" :key="email">
                                    <a :href="`mailto:${email}`">{{ email }}</a>
                                    <span v-if="index !== company.emails.length - 1 && company.emails.length > 1"
                                        >,
                                    </span>
                                </span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-else-if="client">
                <v-card-title class="pa-0">{{ $t('client') }}</v-card-title>
                <v-list lines="two">
                    <v-list-item v-if="client.firstName || client.lastName" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('name') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="client.prefix" v-html="client.prefix" />
                                <span v-if="client.firstName" v-html="client.firstName" />
                                <span v-if="client.lastName" v-html="client.lastName" />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="client.birthdate" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('birthdate') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ client.birthdate }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="client.contactPhone" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('phone') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <a :href="`tel:${client.contactPhone}`">{{ client.contactPhone }}</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="client.email" two-line class="pa-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>{{ $t('email') }}:</v-list-item-title>
                            <v-list-item-subtitle>
                                <a :href="`mailto:${client.email}`">{{ client.email }}</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-card>
        <v-card v-else class="pa-4 text-lowercase">
            {{ $t('loading') }}
            <v-progress-linear indeterminate color="primary" />
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_CONTACT_DETAILS_MODAL} from '@/utils/event-bus'

    @Component
    export default class ContactDetailsModal extends Vue {
        modal = false
        isLoading = true
        company = null
        client = null

        created() {
            EventBus.$on(SHOW_CONTACT_DETAILS_MODAL, this.showModal)
        }

        mounted() {
            this.company = null
            this.client = null
        }

        beforeDestroy() {
            EventBus.$off(SHOW_CONTACT_DETAILS_MODAL, this.showModal)
        }

        async showModal({type, id}) {
            this.isLoading = true
            this.modal = true
            if (type === 'company') {
                try {
                    this.company = (await this.$api.companies.get({id})).object[0]
                } catch (e) {
                    this.modal = false
                } finally {
                    this.isLoading = false
                }
            } else if (type === 'client') {
                try {
                    this.client = (await this.$api.privateClients.get({id})).persons[0]
                } catch (e) {
                    this.modal = false
                } finally {
                    this.isLoading = false
                }
            }
        }
    }
</script>
