<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class AdditionalSearchOptionsMixin extends Vue {
        getAdditionalOptions() {
            const form = this.$refs.addForm.$refs['additional-form']
            if (form) {
                const values = form.$children.map(child => child.$refs.option)
                const params = {}
                values.forEach(el => {
                    params[el.$attrs.name] =
                        el.$parent.value !== undefined
                            ? el.$parent.valueName
                                ? el.$parent.valueName
                                : el.$parent.value
                            : undefined
                })
                return Object.fromEntries(Object.entries(params).filter(el => el[1] !== undefined))
            }
            return {}
        }

        mounted() {
            const oldQuery = this.newQuery
            Object.defineProperty(this, 'newQuery', {
                value: () => {
                    const query = oldQuery()
                    const add = this.getAdditionalOptions()
                    return Object.assign(query, add)
                },
            })
        }
    }
</script>
