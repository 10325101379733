<template>
    <div class="checkbox-wrapper">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="checkbox-container pb-3">
                        <v-col v-for="(item, index) in valuesForNamedRating" :key="item.value" :cols="12" class="py-1">
                            <v-checkbox
                                v-model="checked"
                                small
                                :hide-details="true"
                                :class="{'pb-3': index + 1 === valuesForNamedRating.length}"
                                class="py-0 my-0"
                                :value="item.value"
                            >
                                <template v-slot:label>
                                    <span
                                        class="label-text"
                                        v-text="`${ratingString(item.value)} ${item.value + 0.5}+`"
                                    />
                                    <div class="ml-auto mr-1 filter-qty">
                                        {{ item.count }}
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import TripadvisorFilter from '@/components/search/filters/TripadvisorFilterMod'
    import {Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TripadvisorFilterMod extends TripadvisorFilter {
        @Prop({default: true}) panelExpanded

        expanded = 0

        ratingString(value) {
            if (value === 4) return this.$t('hotelRating.excellent')
            if (value === 3) return this.$t('hotelRating.very_good')
            if (value === 2) return this.$t('hotelRating.good')
            if (value === 1) return this.$t('hotelRating.satisfactory')
            return this.$t('hotelRating.sufficient')
        }

        get valuesForNamedRating() {
            return this.values.reduce((acc, e) => {
                const moreThanNumber = Math.trunc(+e.value - 0.5)
                const existingObjectWithNumber = acc.find(el => el.value === moreThanNumber)

                if (existingObjectWithNumber) {
                    existingObjectWithNumber.count += e.count
                } else {
                    acc.push({value: moreThanNumber, count: +e.count})
                }
                return acc
            }, [])
        }

        mounted() {
            this.expanded = this.panelExpanded ? 0 : undefined
        }
    }
</script>
