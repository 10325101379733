<script>
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
    import {flightsRuntimeStore} from '~/store'

    @Component
    export default class FlightBaseForm extends Vue {
        @Prop() initSearch
        @Prop({default: 0}) formIndex

        from = null
        to = null

        @Watch('initSearch')
        onSearchInit() {
            if (this.initSearch) {
                const routes = this.getRoutes()
                this.$emit('form-data', routes)
            }
        }

        mounted() {
            this.initDestinations()
            this.initDates()
        }

        initDestinations() {
            if (flightsRuntimeStore.routes.length >= this.formIndex + 1) {
                this.from = {...flightsRuntimeStore.routes[this.formIndex].departure}
                this.to = {...flightsRuntimeStore.routes[this.formIndex].arrival}
            }
        }

        initDates() {}
    }
</script>
