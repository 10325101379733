<template>
    <v-select
        autowidth
        hide-details
        class="cursor-pointer"
        :value="value"
        dense
        :items="items"
        solo
        filled
        :menu-props="{bottom: true, offsetY: true}"
        single-line
        flat
        @change="change"
    >
        <template v-slot:selection>
            <span>
                {{ $t('sort.sort') }}
            </span>
        </template>
    </v-select>
</template>

<script>
    import SortSelect from '~/components/search/SortSelect'
    import {Component} from 'nuxt-property-decorator'

    @Component
    export default class SortSelectMod extends SortSelect {}
</script>

<style scoped></style>
