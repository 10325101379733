<template>
    <div ref="map" v-intersect="onIntersect" class="map" :style="{height}" />
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import gmapsInit from '~/utils/gmaps'

    @Component
    export default class MapLocation extends Vue {
        @Prop({required: true}) latitude
        @Prop({required: true}) longitude
        @Prop({default: '300px'}) height

        async load() {
            try {
                const google = await gmapsInit()
                const position = {
                    lat: this.latitude,
                    lng: this.longitude,
                }
                const map = new google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    fullscreenControl: true,
                    zoom: 16,
                    center: position,
                })
                const templateColor = this.$vuetify.theme.themes.light.primary
                const markerIcon = {
                    path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                    fillColor: templateColor,
                    fillOpacity: 0.95,
                    scale: 2,
                    strokeColor: '#fff',
                    strokeWeight: 3,
                    anchor: new google.maps.Point(12, 24),
                }
                new google.maps.Marker({
                    position,
                    map,
                    icon: markerIcon,
                })
            } catch (error) {
                console.error(error)
            }
        }

        onIntersect() {
            this.load()
        }
    }
</script>

<style scoped lang="scss">
    .map {
        min-height: 300px;
        z-index: 1;
    }
</style>
