<template>
    <div class="d-flex flex-column align-center">
        <hotel-breadcrumbs :search-request="searchRequest" class="py-2" :style="{width: isMobile ? '100%' : '80%'}" />
        <div ref="container" class="overflow-x-auto" :style="{width: isMobile ? '100%' : '80%'}">
            <v-snackbar
                v-show="false"
                ref="comporationOptins"
                vertical
                :content-class="hideComparationOptions ? 'd-flex flex-column pa-0 ma-0' : ''"
                bottom
                right
                :value="true"
                :timeout="-1"
                light
                color="cyan darken-2"
                class="hidden-sm-and-down"
            >
                <div v-if="!hideComparationOptions" class="d-flex flex-wrap">
                    <v-row>
                        <v-col :cols="12" class="py-0">
                            <h4>
                                {{ $t('comparison.options') }}
                            </h4>
                        </v-col>
                        <v-col class="pt-0">
                            <v-switch v-model="showAll" color="warning" :hide-details="true">
                                <template v-slot:label>
                                    <span class="white--text">{{ $t('comparison.show_similar') }}</span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col class="pt-0">
                            <v-switch v-model="mapVisible" color="warning" :hide-details="true" @change="showMap">
                                <template v-slot:label>
                                    <span class="white--text">{{ $t('comparison.show_map') }}</span>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                </div>
                <v-btn v-else class="mt-1 white--text cyan darken-3" @click="hideCompare">
                    {{ $t('show') }}
                </v-btn>
                <template v-if="!hideComparationOptions" v-slot:action="{attrs}">
                    <v-row v-bind="attrs">
                        <v-col class="pa-0" align-self="center">
                            <v-btn class="white--text cyan darken-3 mr-2" @click="closeCompare">
                                {{ $t('close') }}
                            </v-btn>
                            <v-btn class="white--text cyan darken-3 mr-2" @click="hideCompare">
                                {{ $t('hide') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-snackbar>
            <v-row>
                <v-expansion-panels v-if="!isMobile" v-model="panel" flat accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="text-xs-center">
                            {{ $t('comparison.map') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mx-0 px-0" eager>
                            <client-only>
                                <compare-map
                                    v-if="city.latitude && city.longitude"
                                    :offers="offers"
                                    :_expanded.sync="mapExpanded"
                                    :is-fixed="false"
                                    :height="isMobile ? '20vh' : '40vh'"
                                    :width="width"
                                    :city="city"
                                    style="position: relative"
                                />
                            </client-only>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <template v-else>
                    <compare-map
                        v-if="city.latitude && city.longitude"
                        :offers="offers"
                        :_expanded.sync="mapExpanded"
                        :is-fixed="false"
                        :height="isMobile ? '20vh' : '40vh'"
                        :width="width"
                        :city="city"
                        style="position: relative"
                    />
                </template>
            </v-row>
            <v-row ref="width" class="odd-line flex-md-nowrap fit">
                <v-col
                    cols="12"
                    md="2"
                    class="text-md-left subtitle-2 field-name"
                    style="max-width: 305px"
                    :style="{'text-center': isMobile}"
                >
                    {{ $t('comparison.name') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-center px-0 mx-0"
                    :style="{'min-width': rowWidth}"
                >
                    <n-link
                        :class="{'ml-auto': isMobile}"
                        no-prefetch
                        :to="hotelPageLink(offer)"
                        class="centered black--text text-decoration-none font-weight-medium caption"
                    >
                        {{ offer.name }}
                    </n-link>
                    <v-icon right color="red" @click="removeFromComparison(offer.hotelCode)">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2 hidden-sm-and-down">
                {{ $t('comparison.general_info') }}
            </v-row>
            <v-row v-if="!isMobile" class="flex-md-nowrap">
                <v-col
                    cols="12"
                    md="2"
                    class="text-center text-md-left hidden-sm-and-down subtitle-2 field-name"
                    style="max-width: 305px"
                >
                    {{ $t('comparison.image') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between flex-md-row px-0 mr-1 flex-wrap"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up px-5">
                        {{ offer.name }}
                    </div>
                    <v-img
                        :src="offer.thumb | imageUrl(350)"
                        :lazy-src="'/placeholder.png' | srcHost"
                        :max-width="rowWidth"
                    />
                </v-col>
            </v-row>
            <v-row class="odd-line flex-md-nowrap" :style="{width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width: 305px">
                    {{ $t('comparison.rating') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <v-rating
                        class="d-inline-block ml-auto ml-md-0"
                        small
                        dense
                        readonly
                        :color="'warning'"
                        :value="parseInt(offer.category)"
                    />
                </v-col>
            </v-row>
            <v-row class="flex-md-nowrap">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width: 305px">
                    {{ $t('comparison.address') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between flex-xs-column"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span class="ml-auto ml-md-0 body-2">
                        {{ offer.address }}
                    </span>
                </v-col>
            </v-row>
            <v-row class="odd-line flex-md-nowrap" :style="{width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width: 305px">
                    {{ $t('comparison.distance') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between flex-xs-column"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span class="ml-auto ml-md-0 body-2">
                        {{ offer.distance ? `${offer.distance} km` : '' }}
                    </span>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2" :style="{width}">
                {{ $t('comparison.services') }}
            </v-row>
            <v-row
                v-for="(key, index) in filteredKeys"
                :key="`${key}-${index}`"
                :class="{'odd-line': index % 2 !== 0}"
                :style="{width}"
            >
                <v-col
                    cols="12"
                    md="2"
                    class="text-center text-md-left subtitle-2 field-name"
                    :style="$breakpoint.smAndDown ? 'max-width: 100%' : 'max-width:305px'"
                >
                    {{ key }}
                </v-col>
                <v-col :cols="12" md="10">
                    <v-row class="flex-md-nowrap">
                        <v-col
                            v-for="offer in offers"
                            :key="offer.hotelCode"
                            class="d-flex justify-md-center justify-xs-space-between px-2 mx-2 px-md-0 mx-md-0"
                            :style="{'min-width': rowWidth}"
                        >
                            <div class="hidden-md-and-up">
                                {{ offer.name }}
                            </div>
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-icon
                                        :color="hasKey(key, offer.services) ? 'yellowlime' : 'grey'"
                                        class="ml-auto ml-md-0"
                                        small
                                        v-on="on"
                                    >
                                        {{ hasKey(key, offer.services) ? 'mdi-check-bold' : 'mdi-close' }}
                                    </v-icon>
                                </template>
                                <span>{{ key }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2" :style="{width: width}">
                {{ $t('price') }}
            </v-row>
            <v-row v-for="mealCode in mealCodes" :key="mealCode" class="flex-md-nowrap" :style="{width: width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width: 305px">
                    {{ $t(`filters_mealTypes.${mealCode}`) }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-center"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span v-if="getBestPrice(offer, mealCode)" class="ml-auto ml-md-0">
                        {{ getBestPrice(offer, mealCode) | price }}
                    </span>
                    <span v-else class="ml-auto ml-md-0">
                        <v-icon color="grey" small>mdi-close</v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center mb-8" :style="{width: width}" align="center">
                <v-col cols="12" md="2" class="d-flex justify-center">
                    <v-btn color="primary" :to="searchPageLink" flat large class="px-8" elevation="0">
                        Back to search page
                    </v-btn>
                </v-col>
            </v-row>
            <div class="scroll-container" @scroll="scrollContainer">
                <div ref="scrollbar" :style="{width}" @scroll="scrollContainer">&nbsp;</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import ComparisonPage from '~/pages/comparison/ComparisonPage'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class ComparisonPageMod extends ComparisonPage {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get searchPageLink() {
            return hotelsRuntimeStore.hotelsPageLink(this.searchRequest)
        }

        get rowWidth() {
            return this.$breakpoint.smAndDown
                ? '60%'
                : this.width === '100%'
                ? '250px'
                : `${(parseInt(this.width.replace('px', '')) - 305) / this.offers.length}px`
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .comparison-header {
        background-color: var(--v-warning-base);
        color: white;
    }

    div.v-expansion-panel-content__wrap::v-deep {
        padding: 0 !important;
    }

    .max-width-300 {
        max-width: 300px;
    }

    .width-300 {
        min-width: 250px !important;
        max-width: 650px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .width-300 {
            min-width: 100% !important;
            max-width: 100%;
        }
    }

    .overflow-x-scroll {
        overflow-x: scroll;
    }

    .odd-line {
        background-color: #f5f8f8;
    }

    .sticky {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 4;
    }

    .centered {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .scroll-container {
        max-width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 3;
        overflow-x: auto;
        height: 30px;
    }

    .scroll-container div {
        height: 10px;
    }

    .fit {
        //min-width: fit-content;
    }

    .field-name {
        position: sticky;
        left: 0;
        z-index: 2;
    }
</style>
