<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import OfferDeltaButton from '~/components/packages/search/OfferDeltaButton'
    import {toursRuntimeStore, toursStore, persistentStore} from '~/store'
    import {convertPrice} from '@/utils/filters'

    @Component({
        components: {
            OfferDeltaButton,
        },
    })
    export default class TourOfferDeltaButton extends OfferDeltaButton {
        @Prop({required: false, type: Object}) room
        @Prop({required: false, type: Object}) offer
        @Prop({required: true}) offerKey
        @Prop({required: true}) entryName

        selectProduct() {
            if (this.offer) this.selectEntry()
            else this.room ? this.selectHotel() : this.selectFlight()
        }

        async selectHotel() {
            const selectedOfferKey = this.room.groupedOffers[0].offerKey
            toursRuntimeStore.SELECT_OFFER({offerKey: this.offerKey, selectedOfferKey})
        }

        selectEntry() {
            const selectedOfferKey = this.product.offerKey
            toursRuntimeStore.SELECT_OFFER({offerKey: this.offerKey, selectedOfferKey})
        }

        calcPrice() {
            return this.entry.obligatory
                ? this.calcDelta(this.product.deltaPrice)
                : this.calcNotDelta(this.product.notDeltaPrice)
        }

        calcDelta(deltaPrice) {
            const convertPrice = this.$options.filters.convertPrice
            const selectedProduct = this.selectedOffer
            return {
                amount: convertPrice(deltaPrice).amount - convertPrice(selectedProduct.deltaPrice).amount,
                currency: persistentStore.currency,
            }
        }

        calcNotDelta(notDeltaPrice) {
            const convertPrice = this.$options.filters.convertPrice
            const selectedProduct = this.selectedOffer
            return {
                amount: convertPrice(notDeltaPrice).amount - convertPrice(selectedProduct.notDeltaPrice).amount,
                currency: persistentStore.currency,
            }
        }

        calcTotalPrice(price) {
            let amount = this.totalPrice.amount
            if (
                this.room &&
                this.isProductSelected &&
                this.selectedRoom.groupedOffers[0].offerKey !== this.product.offerKey
            ) {
                amount += price.amount
            } else if (
                this.product &&
                this.isProductSelected &&
                this.selectedOffer.offerKey !== this.product.offerKey
            ) {
                if (price.amount) amount += price.amount
            }
            return {
                amount,
                currency: persistentStore.currency,
            }
        }

        get tour() {
            return (
                toursRuntimeStore.offers.find(el => el.offerKey === this.offerKey) ||
                toursStore.singleTourSearchResponse.offers[0]
            )
        }

        get entry() {
            return this.tour.entries.find(entry => entry.entryName === this.entryName)
        }

        get isProductSelected() {
            return !!this.entry.selectedOfferKey
        }

        get product() {
            return this.room || this.flight || this.offer
        }

        get selectedOffer() {
            const entry = this.tour.entries.find(entry => entry.entryName === this.entryName)
            if (entry.type === 'ACCOMMODATION') {
                if (!entry.selectedOfferKey && !entry.obligatory) return entry.offers[0].rooms[0]

                return entry.offers.reduce(
                    (room, offer) =>
                        room ||
                        offer.rooms.find(({groupedOffers}) => groupedOffers[0].offerKey === entry.selectedOfferKey),
                    null
                )
            }

            const productOffersAdapter = product =>
                product.offers?.length
                    ? product.offers
                    : product.packageOffers?.length
                    ? product.packageOffers
                    : [product]
            if (!entry.selectedOfferKey && !entry.obligatory) return productOffersAdapter(entry.offers[0])[0]
            for (const product of entry.offers) {
                const selectedOffer = productOffersAdapter(product).find(
                    offer => offer.offerKey === entry.selectedOfferKey
                )
                if (selectedOffer) return selectedOffer
            }
            return productOffersAdapter(entry.offers[0])[0]
        }

        get selectedRoom() {
            return this.selectedOffer
        }

        get currentlySelected() {
            if (!this.isProductSelected) return false
            if (this.room) {
                return this.room.groupedOffers[0].offerKey === this.selectedRoom.groupedOffers[0].offerKey
            }
            return this.product.offerKey === this.selectedOffer.offerKey
        }

        get updateRoomsActive() {
            //TODO: updateRoomsActive
            return false
        }

        get totalPrice() {
            if (!this.isProductSelected) {
                let price
                if (this.room) {
                    price = this.entry.obligatory ? this.room.deltaPrice.amount : this.room.notDeltaPrice.amount
                } else {
                    price = this.entry.obligatory ? this.product.deltaPrice.amount : this.product.notDeltaPrice.amount
                }
                const amount = toursRuntimeStore.totalPrice(this.tour).amount + price
                const currency = toursRuntimeStore.totalPrice(this.tour).currency

                return convertPrice({amount, currency})
            }
            return toursRuntimeStore.totalPrice(this.tour)
        }
    }
</script>
