<template>
    <v-select
        v-model="selectedAgent"
        hide-details
        :loading="agentLoading || saveLoading"
        :disabled="agentLoading || saveLoading"
        outlined
        return-object
        dense
        :items="persons"
        :item-text="getItemText"
        @change="save"
    />
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OrderDetailsEditAgent extends Vue {
        @Prop({required: true}) companyId
        @Prop({required: true}) agentId
        @Prop({required: true}) saveLoading

        agentLoading = false
        persons = []
        selectedAgent = null

        async mounted() {
            await this.load()
        }

        async load() {
            this.agentLoading = true
            try {
                this.persons = (await this.$api.persons.get({companyId: this.companyId}))['users']
                this.selectedAgent = this.persons.find(p => p.personId === this.agentId)
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.agentLoading = false
            }
        }

        @Emit()
        save() {
            return {
                firstName: this.selectedAgent.firstName,
                lastName: this.selectedAgent.lastName,
                prefix: this.selectedAgent.prefix,
                id: this.selectedAgent.personId,
            }
        }

        getItemText(user) {
            return `${user.prefix || ''} ${user.firstName || ''} ${user.lastName || ''} ${
                user.email ? '(' + user.email + ')' : ''
            }`
        }
    }
</script>
