<template>
    <div v-if="items.length" dir="ltr">
        <main-header :title="$t('tours.top_tours')" />
        <swiper ref="swiper" :options="swiperOptions" class="pa-4" @slide-change="updateLoop">
            <swiper-slide v-for="(item, index) in items" :key="index" :class="{'swiper-slide-ssr': swiperSlideSsr}">
                <top-tour-card :dir="$vuetify.rtl ? 'rtl' : 'ltr'" :item="item" />
            </swiper-slide>
            <div
                v-if="items.length > slidesPerView"
                id="tourSwiperButtonPrev"
                slot="button-prev"
                class="swiper-button-prev"
            />
            <div
                v-if="items.length > slidesPerView"
                id="tourSwiperButtonNext"
                slot="button-next"
                class="swiper-button-next"
            />
        </swiper>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    //import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //TODO It is Swiper6 workaround
    import {Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import 'swiper/swiper-bundle.min.css'
    import TopTourCard from '@/components/tours/TopTourCard'
    import {cmsStore, toursRuntimeStore} from '@/utils/store-accessor'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import MainHeader from '~src/components/snippets/mainHeader.src'

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    Vue.use(getAwesomeSwiper(SwiperClass))
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    //TODO Need to refactoring with PopularDestinations and Blogs
    @Component({
        components: {
            TopTourCard,
            Swiper,
            SwiperSlide,
            MainHeader,
        },
    })
    export default class TopTours extends Vue {
        swiperSlideSsr = false

        async fetch() {
            await toursRuntimeStore.loadPackageTours()
            if (toursRuntimeStore.packageTours.length) {
                await cmsStore.loadTopTours()
            }
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, toursRuntimeStore.loadPackageTours)
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, toursRuntimeStore.loadPackageTours)
        }

        updateLoop(e) {
            e.loopDestroy()
            e.loopCreate()
        }

        get title() {
            return this.$t('tours.top_tours')
        }

        get items() {
            return cmsStore.topTours
                .map(({packageTourId}) =>
                    toursRuntimeStore.packageTours.find(packageTour => packageTour.packageTourId === packageTourId)
                )
                .filter(Boolean)
        }

        get slidesPerView() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 4 : 5
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                breakpoints: {
                    960: {
                        slidesPerView: 4,
                    },
                    1264: {
                        slidesPerView: 5,
                    },
                },
                navigation: {
                    nextEl: '#tourSwiperButtonNext',
                    prevEl: '#tourSwiperButtonPrev',
                },
            }
        }
    }
</script>

<style scoped>
    div.swiper-button-prev,
    div.swiper-button-next {
        color: white;
    }
    div.swiper-button-prev {
        left: 20px;
    }
    div.swiper-button-next {
        right: 20px;
    }
    .swiper-slide-ssr {
        width: 25%;
        margin-right: 20px;
    }
</style>
