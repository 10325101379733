<template>
    <div class="full-width d-flex flex-column align-center">
        <div :style="{width: isMobile ? '100%' : '80%'}" class="d-flex flex-column align-center">
            <breadcrumbs class="full-width" />
            <user-settings :style="{width: isMobile ? '100%' : '80%'}" />
        </div>
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import SettingsPage from '~/pages/account/SettingsPage'

    @Component
    export default class SettingsPageMod extends SettingsPage {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
