<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {adult, child} from '~src/utils/tourist-blank-states.src'

    @Component
    export default class BookingTouristsMixin extends Vue {
        setTourists() {
            persistentStore.SET_TOURISTS(this.initTourists(this.searchRequest, clone(persistentStore.tourists)))
        }

        initTourists({adults = 1, childrenAges = []}, tourists, roomIndex = 0) {
            let i = 0
            for (i; i < adults; i++) {
                if (
                    !tourists[i] ||
                    tourists[i].type !== 'adult' ||
                    !(tourists[i].firstName || tourists[i].alternativeMealTypeId)
                ) {
                    const user = authStore.user
                    if (
                        i === 0 &&
                        roomIndex === 0 &&
                        authStore.person &&
                        (!authStore.person.role || user.isEmployeeOrExternalCorp)
                    ) {
                        tourists[0] = this.touristFromPerson(authStore.person) || adult(this.addCitizenship())
                    } else {
                        tourists[i] = adult(this.addCitizenship())
                        if (i === 0) {
                            Object.assign(tourists[i], {phone: null, email: null})
                        }
                    }
                }
            }
            childrenAges.forEach((age, index) => {
                const i = index + adults
                if (!tourists[i] || tourists[i].type !== 'child' || tourists[i].age !== age) {
                    tourists[i] = child(this.addCitizenship(), age)
                }
            })
            const touristsTotal = adults + childrenAges.length
            return tourists.slice(0, touristsTotal)
        }

        //TODO Need additional analyse how to refactoring
        touristFromPerson(person) {
            const tourist = runtimeStore.touristFromPerson(person)
            if (!tourist.citizenshipId && !tourist.personId) {
                tourist.citizenshipId = this.searchRequest.citizenshipId
            }
            return Object.assign({}, this.tourLead, tourist)
        }

        addCitizenship() {
            if (persistentStore.selectedAgent?.companyCountryId) return persistentStore.selectedAgent.companyCountryId
            if (this.searchRequest.citizenshipId) return this.searchRequest.citizenshipId
            return this.b2BAutoCompliteCitezenship ? authStore.company.countryId : authStore.person?.countryId || null
        }

        prepareBookTourists(tourists, specifiedTouristBookingFields) {
            const isFieldDisabled = (tourist, index, fieldKey) =>
                runtimeStore.isTouristBookingFieldDisabled(
                    fieldKey,
                    index === 0,
                    tourist.type === 'child',
                    specifiedTouristBookingFields
                )
            return tourists
                .filter(
                    (tourist, index) =>
                        !runtimeStore.isTouristNotRequired(tourist, index === 0, specifiedTouristBookingFields)
                )
                .map((tourist, index) => {
                    const newTourist = {...tourist}
                    const {expiryDate, number, issueDate} = tourist.passport
                    if (expiryDate || number || issueDate) {
                        newTourist.passport = {
                            expiryDate,
                            number,
                            issueDate,
                        }
                        if (isFieldDisabled(tourist, index, 'PASSPORT_NUMBER')) delete newTourist.passport.number
                        if (isFieldDisabled(tourist, index, 'PASSPORT_EXPIRE_DATE'))
                            delete newTourist.passport.expiryDate
                        if (isFieldDisabled(tourist, index, 'PASSPORT_ISSUE_DATE')) delete newTourist.passport.issueDate
                        if (!Object.keys(newTourist.passport).length) delete newTourist.passport
                    } else {
                        delete newTourist.passport
                    }
                    if (isFieldDisabled(tourist, index, 'PHONE')) delete newTourist.phone
                    if (isFieldDisabled(tourist, index, 'EMAIL')) delete newTourist.email
                    if (isFieldDisabled(tourist, index, 'PREFIX')) delete newTourist.prefix
                    if (isFieldDisabled(tourist, index, 'CITIZENSHIP')) delete newTourist.citizenshipId
                    if (isFieldDisabled(tourist, index, 'BIRTHDATE')) delete newTourist.birthdate
                    delete newTourist.age
                    return newTourist
                })
        }

        get tourLead() {
            return persistentStore.tourists[0]
        }

        set tourLead(val) {
            persistentStore.SET_TOURIST({val, index: 0})
        }

        get tourists() {
            const tourists =
                runtimeStore.touristBookingFields.supplierConfigDetails?.guestsInformationRequiredType ===
                'ONLY_TOURLEAD'
                    ? persistentStore.tourists.slice(0, 1)
                    : persistentStore.tourists
            return tourists.map(
                (tourist, index) =>
                    new Proxy(tourist, {
                        set: (target, prop, val) => {
                            persistentStore.SET_TOURIST_PROP({prop, val, index})
                            return true
                        },
                        get: (target, prop) => {
                            return persistentStore.tourists[index][prop]
                        },
                    })
            )
        }
    }
</script>
