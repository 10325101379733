<template>
    <div style="color: #626262">
        <div v-if="offer.distance" class="caption d-flex align-center font-weight-regular">
            <v-icon class="me-2" small color="accent">mdi-map-marker-distance</v-icon>
            <span class="text--secondary">
                {{ offer.distance }}
                {{ $t('km_from_center') }}
            </span>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelLocationLabel from '@/components/hotels/snippets/HotelLocationLabel'

    @Component
    export default class HotelLocationLabelMod extends HotelLocationLabel {}
</script>
