<template>
    <div>
        <v-row class="mt-4">
            <v-col cols="12" md="3">
                <v-select
                    v-model="info.type"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    outlined
                    hide-details
                    :items="types"
                    :label="`${$t('type')}*`"
                />
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    v-model="info.stage"
                    item-text="name"
                    item-value="value"
                    :menu-props="{bottom: true, offsetY: true}"
                    hide-details
                    outlined
                    :items="stages"
                    :label="`${$t('stage')}*`"
                />
            </v-col>
            <v-col :md="3">
                <date-picker v-model="date" hide-details outlined class="cursor-pointer" :label="`${$t('date')}`" />
            </v-col>
            <v-col :md="3">
                <time-field v-model="time" outlined class="cursor-pointer" :label="`${$t('time')}`" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="info.subject" outlined :label="$t('subject')" persistent-placeholder />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea v-model="info.description" outlined :label="$t('details')" persistent-placeholder />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class CommunicationEditForm extends Vue {
        @VModel() info

        created() {
            if (!this.info.communicationDateTime) {
                this.info.communicationDateTime = this.$dateFns.format(new Date(), "yyyy-MM-dd'T'HH:mm")
            }
            if (!this.info.type) {
                this.info.type = this.types[0].value
            }
            if (!this.info.stage) {
                this.info.stage = this.stages[0].value
            }
        }

        get types() {
            return [
                {
                    name: this.$t('communication_type.CALL'),
                    value: 'CALL',
                },
                {
                    name: this.$t('communication_type.EMAIL'),
                    value: 'EMAIL',
                },
                {
                    name: this.$t('communication_type.MEETING'),
                    value: 'MEETING',
                },
                {
                    name: this.$t('communication_type.OTHER'),
                    value: 'OTHER',
                },
            ]
        }

        get stages() {
            return [
                {
                    name: this.$t('communication_stage.PLANNED'),
                    value: 'PLANNED',
                },
                {
                    name: this.$t('communication_stage.HELD'),
                    value: 'HELD',
                },
                {
                    name: this.$t('communication_stage.NOT_HELD'),
                    value: 'NOT_HELD',
                },
            ]
        }

        get date() {
            return this.info.communicationDateTime?.split('T')[0]
        }

        set date(date) {
            this.info.communicationDateTime = `${date}T${this.info.communicationDateTime?.split('T')[1]}`
        }

        get time() {
            return this.info.communicationDateTime?.split('T')[1]
        }

        set time(time) {
            this.info.communicationDateTime = `${this.info.communicationDateTime?.split('T')[0]}T${time}`
        }
    }
</script>
