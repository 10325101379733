<template>
    <div dir="ltr">
        <div class="full-width text-center mb-8">
            <span class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                <span class="primary--text"> {{ $t('popular') }} </span>
                <span>
                    {{ $t('tour_region') }}
                </span>
            </span>
        </div>
        <swiper :options="swiperOptions" class="swiper pa-4 pb-8">
            <swiper-slide
                v-for="(destination, index) in destinations"
                :key="index"
                :class="{'swiper-slide-ssr': swiperSlideSsr}"
            >
                <popular-destination
                    :destination="destination"
                    :dir="$vuetify.rtl ? 'rtl' : 'ltr'"
                    @selected="search($event)"
                />
            </swiper-slide>
            <div v-if="destinations.length > showCount" slot="pagination" class="swiper-pagination" style="bottom: 0" />
        </swiper>
        <div v-if="!isMobile" class="full-width d-flex justify-center mt-6">
            <v-btn
                v-if="destinations.length > showCount"
                id="destinationSwiperButtonPrev"
                icon
                elevation="1"
                class="mx-2"
            >
                <v-icon color="primary"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
                v-if="destinations.length > showCount"
                id="destinationSwiperButtonNext"
                icon
                elevation="1"
                class="mx-2"
            >
                <v-icon color="primary"> mdi-chevron-right </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import PopularDestinations from '~/components/parts/PopularDestinations'

    @Component({})
    export default class PopularDestinationsMod extends PopularDestinations {
        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 3 : 4
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                centerInsufficientSlides: true,
                spaceBetween: 20,
                breakpoints: {
                    960: {
                        slidesPerView: 3,
                    },
                    1264: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: '#destinationSwiperButtonNext',
                    prevEl: '#destinationSwiperButtonPrev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true,
                },
                observer: true,
                observeParents: true,
            }
        }
    }
</script>

<style scoped lang="scss">
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
        bottom: 0 !important;
        top: unset;
    }

    ::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #d8e562;
    }
</style>
