<template>
    <v-dialog v-model="isShowOrderAddServiceModal" content-class="overflow--visible">
        <v-card-title>
            <v-spacer />
            {{ `${$t('adding_service_to_order')} #${order.orderId}` }}
            <v-spacer />
            <v-btn icon dark @click="isShowOrderAddServiceModal = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <search-form-tabs :row="row" :dark="dark" />
    </v-dialog>
</template>

<script>
    import {Component, Prop, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import {
        activitiesRuntimeStore,
        activitiesStore,
        hotelsStore,
        persistentStore,
        transfersRuntimeStore,
        transfersStore,
    } from '@/store'
    import SearchFormTabs from '~src/components/searchFormTabs.src'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            SearchFormTabs,
        },
    })
    export default class OrderAddServiceModal extends Vue {
        @VModel() isShowOrderAddServiceModal
        @Prop({required: true}) order
        @Prop({default: false, type: Boolean}) row
        @Prop({default: false, type: Boolean}) dark

        persistentTourists = []
        hotelsTourists = []

        @Watch('isShowOrderAddServiceModal')
        onDialogShow(val) {
            if (val) {
                persistentStore.SET_SELECTED_ORDER_ID(this.order.orderId)
                this.tourists = clone(persistentStore.tourists)
                this.hotelsTourists = clone(hotelsStore.tourists)
                const tourists = this.order.services.reduce((tourists, service) => {
                    service.travelers.forEach(
                        ({
                            prefix,
                            name: [{firstName, lastName}],
                            citizenshipId,
                            isChild,
                            phone,
                            email,
                            dateOfBirth: birthdate,
                            passports: [passport],
                        }) => {
                            const newTraveler = {
                                prefix,
                                firstName,
                                lastName,
                                citizenshipId,
                                type: isChild ? 'child' : 'adult',
                                ...(!isChild && {phone}),
                                ...(!isChild && {email}),
                                birthdate,
                                ...(isChild && {
                                    age: this.$dateFns.differenceInYears(new Date(), this.$dateFns.parseISO(birthdate)),
                                }),
                                passport: {
                                    type: passport?.type || null,
                                    number: passport?.number || null,
                                    issueDate: passport?.issueDate || null,
                                    expiryDate: passport?.expiryDate || null,
                                },
                            }

                            if (
                                tourists.findIndex(
                                    tourist =>
                                        tourist.firstName === newTraveler.firstName &&
                                        tourist.lastName === newTraveler.lastName
                                )
                            ) {
                                tourists.push(newTraveler)
                            }
                        }
                    )
                    return tourists
                }, [])
                persistentStore.SET_TOURISTS(tourists)
                hotelsStore.SET_TOURISTS([tourists])

                const accommodationService = this.order.services.find(
                    service => service.serviceType === 'ACCOMMODATION'
                )
                const flightService = this.order.services.find(
                    service => service.serviceType === 'OWNCHARTER' || service.serviceType === 'FLIGHT'
                )
                let adults = 0,
                    childrenAges = []

                tourists.forEach(tourist => {
                    if (tourist.type === 'adult') adults += 1
                    else {
                        childrenAges.push(tourist.age)
                    }
                })

                if (accommodationService && this.order.services.some(service => service.serviceType === 'TRANSFER')) {
                    const {
                        hotelCode: departureHotelCode,
                        hotelName: departureHotelName,
                        cityId: departureCityId,
                    } = accommodationService.serviceDetails[0]
                    transfersRuntimeStore.loadDeparturePoint({
                        departureHotelCode,
                        departureHotelName,
                        departureCityId,
                    })
                    transfersStore.SET_SEARCH_REQUEST({
                        ...transfersStore.searchRequest,
                        departureCityId,
                        ...(adults && {adults}),
                        ...(childrenAges.length && {childrenAges}),
                        dateTime: accommodationService.endDateTime,
                    })
                    activitiesRuntimeStore.loadCity(departureCityId)
                    activitiesStore.SET_SEARCH_REQUEST({...activitiesStore.searchRequest, cityId: departureCityId})
                } else if (accommodationService) {
                    const {
                        hotelCode: arrivalHotelCode,
                        hotelName: arrivalHotelName,
                        cityId: arrivalCityId,
                        countryId,
                    } = accommodationService.serviceDetails[0]
                    transfersRuntimeStore.loadArrivalPoint({
                        arrivalHotelCode,
                        arrivalHotelName,
                        arrivalCityId,
                    })
                    const searchRequest = {
                        ...transfersStore.searchRequest,
                        arrivalCityId,
                        ...(adults && {adults}),
                        ...(childrenAges.length && {childrenAges}),
                        dateTime: accommodationService.startDateTime,
                    }
                    if (flightService) {
                        const {arrivalAirportCode, flightNumber} =
                            flightService.serviceDetails[0].itineraries[0].flightSegments[0]
                        searchRequest.departurePlace = arrivalAirportCode
                        searchRequest.flightNumber = flightNumber
                        searchRequest.departureCityId = arrivalCityId
                        searchRequest.departureCountryId = countryId

                        transfersRuntimeStore.loadDeparturePoint({
                            departurePlace: arrivalAirportCode,
                            departureCityId: arrivalCityId,
                            departureCountryId: countryId,
                            flightNumber,
                        })
                    }
                    transfersStore.SET_SEARCH_REQUEST(searchRequest)
                    activitiesRuntimeStore.loadCity(arrivalCityId)
                    activitiesStore.SET_SEARCH_REQUEST({...activitiesStore.searchRequest, cityId: arrivalCityId})
                }
            } else {
                persistentStore.SET_SELECTED_ORDER_ID(null)
                persistentStore.SET_TOURISTS(this.persistentTourists)
                hotelsStore.SET_TOURISTS(this.hotelsTourists)
                transfersRuntimeStore.SET_DEPARTURE_POINT({})
                transfersRuntimeStore.SET_ARRIVAL_POINT({})
                transfersStore.SET_SEARCH_REQUEST({...transfersStore.searchRequest, departureCityId: null})
                activitiesRuntimeStore.SET_CITY({})
                activitiesStore.SET_SEARCH_REQUEST({...activitiesStore.searchRequest, cityId: null})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-card__title {
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
    }

    ::v-deep {
        .v-tabs > .v-tabs-bar {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
</style>
