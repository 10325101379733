<template>
    <div>
        <v-row dense>
            <v-col cols="6">
                <span class="subtitle-1 text--secondary">
                    {{ $t('adults') }}
                </span>
                <v-spacer />
                <div class="d-flex align-center mt-2">
                    <v-btn
                        tile
                        dark
                        x-small
                        :disabled="disabled"
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="inputAdults(adultsCount - 1)"
                    >
                        <v-icon x-small dark>mdi-minus</v-icon>
                    </v-btn>
                    <v-sheet class="py-1 px-2 mx-2" color="#F5F8F8" rounded>
                        <span> {{ adultsCount }} </span>
                    </v-sheet>
                    <v-btn
                        tile
                        dark
                        x-small
                        :disabled="disabled"
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="inputAdults(adultsCount + 1)"
                    >
                        <v-icon x-small dark>mdi-plus</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="6">
                <span class="subtitle-1 text--secondary">
                    {{ $t('children') }}
                </span>
                <div class="d-flex align-center mt-2">
                    <v-btn
                        tile
                        dark
                        :disabled="disabled"
                        x-small
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="inputChildren(childrenAges.length - 1)"
                    >
                        <v-icon x-small dark>mdi-minus</v-icon>
                    </v-btn>
                    <v-sheet class="py-1 px-2 mx-2" color="#F5F8F8" rounded>
                        <span> {{ childrenAges.length }} </span>
                    </v-sheet>
                    <v-btn
                        tile
                        dark
                        x-small
                        :disabled="disabled"
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="inputChildren(childrenAges.length + 1)"
                    >
                        <v-icon x-small dark>mdi-plus</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col v-show="childrenAges.length > 0" cols="12" class="pt-2">
                <label>
                    {{ $t('children_ages') }}
                </label>
            </v-col>
            <v-col v-for="(age, index) in childrenAges" :key="index" cols="4">
                <v-select
                    :key="index"
                    class="pt-0"
                    dense
                    hide-details
                    :value="age"
                    :items="childrenAgeItems"
                    data-cy="children-age-selector"
                    @change="changeAge($event, index)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import TouristsSelect from '~/components/search/forms/TouristsSelect'

    @Component
    export default class TouristsSelectMod extends TouristsSelect {}
</script>
