<template>
    <v-card
        class="hotel-card mb-5"
        :class="{'cursor-pointer': compareMode}"
        flat
        style="border-radius: 0"
        :hover="compareMode"
        @click.native="selectToCompare(offer)"
    >
        <div class="d-flex wrapper">
            <div style="position: relative">
                <v-img
                    tile
                    :src="offer.thumb | imageUrl(350)"
                    :alt="offer.name"
                    eager
                    height="244"
                    width="346"
                    :aspect-ratio="346 / 244"
                    :lazy-src="'/placeholder.png' | srcHost"
                    class="me-2"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && offer.thumb"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <wishlist-like
                    v-if="person"
                    :info="{
                        name: offer.name,
                        thumb: offer.thumb,
                        address: offer.address,
                        distance: offer.distance,
                        district: offer.district,
                        services: offer.services,
                        otaCodeServices: offer.otaCodeServices,
                        category: offer.category,
                        type: offer.type,
                        searchRequest,
                    }"
                    :query="{
                        supplierCode: offer.supplierCode,
                        hotelCode: offer.hotelCode,
                        cityCode: offer.cityCode,
                        productType: 'hotel',
                    }"
                    :product-runtime-store="productRuntimeStore"
                    :in-wishlist="offer.wishlist"
                    style="position: absolute; right: 12px; top: 4px"
                />
            </div>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate d-flex flex-column">
                <div class="d-flex align-center flex-wrap">
                    <hotel-stars :hotel="offer" :color="'warning'" />
                    <hotel-type-label class="ms-1 text-uppercase" style="margin-top: 2px" :offer="offer" small />
                    <hotel-own-label class="ms-1" :offer="offer" />
                </div>
                <div class="text-h6 font-weight-medium black--text my-1" style="font-size: 20px !important">
                    <a
                        v-if="$route.name === 'hotels' && infoInNewTab"
                        :href="$router.resolve(hotelPageLink).href"
                        target="_blank"
                        class="text-decoration-none black--text font-weight-regular multiline-elipsis"
                    >
                        {{ offer.name }}
                    </a>
                    <nuxt-link
                        v-else-if="$route.name === 'hotels'"
                        no-prefetch
                        :to="hotelPageLink"
                        class="text-decoration-none black--text font-weight-regular multiline-elipsis"
                    >
                        {{ offer.name }}
                    </nuxt-link>
                    <span v-else class="multiline-elipsis">
                        {{ offer.name }}
                    </span>
                </div>
                <hotel-location-label :offer="offer" class="my-1" />
                <hotel-address-label :address="offer.address" :offer="offer" class="caption d-flex align-center my-1" />
                <div class="flex-grow-1" />
                <div
                    v-if="isFullyRefundable"
                    class="text-subtitle-1 success--text font-weight-regular"
                    v-text="`${$t('fully')} ${$t('filters_refundable.true').toLowerCase()}`"
                />

                <hotel-services :offer="offer" :color="'yellowlime'" />
                <slot :min-price-room-offer="minPriceRoomOffer" :hotel-page-link="hotelPageLink" />
            </div>
            <div class="d-flex">
                <v-divider vertical inset style="border-color: #e9e9e9" />
                <div
                    v-if="!hideRoomOffers"
                    class="ma-4 price-col flex-shrink-0 text-right d-flex flex-column align-center"
                >
                    <tripadvisor-rating
                        v-if="
                            !hideTripadvisorRating &&
                            (offer.tripadvisorRating || (offer.ratings && offer.ratings.length))
                        "
                        :tripadvisor-rating="offer.tripadvisorRating"
                        :ratings="offer.ratings"
                    />
                    <div class="flex-grow-1" />
                    <slot name="price" :room="minPriceRoomOffer">
                        <hotel-offer-price-block
                            :offer="offer"
                            :search-request="searchRequest"
                            :hotel-page-link="hotelPageLink"
                        />
                    </slot>
                    <div
                        class="primary--text cursor-pointer text-no-wrap caption"
                        data-cy="hotel-card-toggle-offers"
                        @click="expanded = !expanded"
                    >
                        <span class="show-rooms-label">
                            {{ expanded ? $t('hide_room_offers') : $t('view_offers') }}
                        </span>
                        <span class="show-rooms-label__short">
                            {{ expanded ? $t('hide_room_offers__short') : $t('view_room_offers__short') }}
                        </span>
                        <v-icon color="primary">
                            {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                    </div>
                </div>
                <recommended-icon v-if="offer.recommended" class="recommended-thumb" />
            </div>
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="compareMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :search-request="searchRequest"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" />
                    </template>
                </hotel-room-offers-wrapper>
                <hotel-multiple-room-select-block
                    v-if="isMultiRoom"
                    :search-request="searchRequest"
                    :offer="offer"
                    :selected-offer-keys="selectedOfferKeys"
                    class="pa-4 pt-0"
                />
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferCard from '~/components/search/offers/HotelOfferCard'
    import TripadvisorRating from '~src/components/hotels/snippets/tripadvisorRating.src'

    @Component({
        components: {TripadvisorRating},
    })
    export default class HotelOfferCardMod extends HotelOfferCard {
        get isFullyRefundable() {
            return this.offer.rooms.every(e => {
                return e.nonRefundable === false
            })
        }
    }
</script>

<style scoped lang="scss">
    .price-col {
        width: 180px;

        .show-rooms-label__short {
            display: none;
        }
    }

    .hotel-card {
        overflow: hidden;
        cursor: unset;
    }

    .recommended-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
    }

    .multiline-elipsis {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        white-space: initial;
    }
    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;

            .show-rooms-label {
                display: none;

                &__short {
                    display: inline;
                }
            }
        }
    }
</style>
