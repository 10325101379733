<template>
    <div>
        <selected-filters
            :selected="filterValues"
            :translatable="['distance', 'mealTypes', 'availability']"
            @delete-filter="clearFilter"
        />
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            :filters="filters"
            :label="$t('price')"
            :currency="currency"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.supplierName && filters.supplierName.length > 1 && isTO1"
            v-model="filterValues"
            :filters="filters"
            filter="supplierName"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            label="filters.tourName"
            filter="tourName"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.hotelName && filters.hotelName.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="hotelName"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.packageDate && filters.packageDate.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="packageDate"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.nightsDuration && filters.nightsDuration.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            :pluralization="true"
            filter="nightsDuration"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.category && filters.category.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            :pluralization="true"
            filter="category"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.mealTypes && filters.mealTypes.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            filter="mealTypes"
            @change="change($event)"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {filters} from '@/utils/tours/tours-blank-states'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import NameFilter from '~src/components/filters/nameFilter.src'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import FiltersBase from '@/components/search/FiltersBase'
    import SelectedFilters from '~src/components/snippets/selectedFilters.src'

    @Component({
        components: {
            PriceFilter,
            NameFilter,
            CheckboxFilter,
            SelectedFilters,
        },
    })
    export default class ToursFilters extends FiltersBase {
        @Prop({required: true, default: () => filters()}) filterValues
    }
</script>
