<template>
    <div>
        <component
            :is="component"
            v-for="(product, index) in offers"
            :key="product.info.name"
            :product="product"
            :is-return-transfer="isReturnTransfer"
            :is-first="product === offers[0]"
            :data-cy="`transfer-offer-${index}`"
            class="my-2"
            @select-transfer-offer="selectTransferOffer"
        />
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
    </div>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo'

    @Component({
        components: {
            TransferConditionsModalInfo,
            ConditionsModal,
            TransferCard: () => import('~src/components/transfers/search/offers/transferCard.src'),
            TransferCardMobile: () => import('~/components/transfers/search/offers/TransferCardMobile.vue'),
        },
    })
    export default class TransferOffers extends Vue {
        @Prop({default: () => []}) offers
        @Prop() isReturnTransfer

        /*get groupedOffers() {
            return transfersRuntimeStore.offers.reduce((groupedProducts, product) => {
                const groupedProductIndex = groupedProducts.findIndex(
                    groupedProduct => groupedProduct.name === product.info.name
                )
                if (groupedProductIndex === -1) {
                    groupedProducts.push({
                        name: product.info.name,
                        products: [product],
                    })
                } else {
                    groupedProducts[groupedProductIndex].products.push(product)
                }
                return groupedProducts
            }, [])
        }*/

        @Emit()
        selectTransferOffer(offer) {
            return offer
        }

        get component() {
            return !this.$breakpoint.smAndDown ? 'transfer-card' : 'transfer-card-mobile'
        }
    }
</script>
