<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <extra-service-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <extra-service-search-summary search-disabled :search-request="searchRequest" />
            <extra-service-order-summary :product="product" :offer="offer" :info="basketItem.info" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceOrderSummary from '~src/components/extraServices/booking/ExtraServiceOrderSummary'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import ConfirmationPageLayout from '~src/components/parts/ConfirmationPageLayout'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'

    @Component({
        components: {
            ExtraServiceBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            ExtraServiceSearchSummary,
            ExtraServiceOrderSummary,
        },
        layout: 'blank',
    })
    export default class ExtraServiceConfirmationPage extends mixins(
        ExtraServicePageMixin,
        ExtraServiceStoreMixin,
        ConfirmationPageMixin
    ) {
        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
