<script>
    import {Component, Prop} from 'vue-property-decorator'
    import HotelMap from '~src/components/search/offers/hotelMap.src'

    @Component
    export default class CompareMap extends HotelMap {
        @Prop({default: null}) height
        @Prop({default: null}) width

        hideExpandButton = true

        async load() {
            await this.initMap()
            this.setMarkers(this.offers)
        }

        setMapXPosition() {
            this.$el.style.width = this.width
        }

        setMapYPosition() {
            this.$el.style.top = '0px'
            this.$el.style.height = this.height
        }

        saveMapPosition() {}
    }
</script>
