<template>
    <v-form v-model="value">
        <vc-date-picker
            v-if="startDate && endDate"
            v-model="range"
            is-range
            :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
            :locale="$i18n.locale"
            :columns="$breakpoint.smAndDown ? 1 : 2"
            :rows="$breakpoint.smAndDown ? 2 : 1"
            :first-day-of-week="$config.firstDayOfWeek || undefined"
            :popover="{placement: $breakpoint.smAndDown ? 'top-start' : 'auto'}"
        >
            <template v-slot="{inputEvents}">
                <div v-on="inputEvents.start">
                    <v-text-field
                        :class="{caption: $breakpoint.smAndDown, 'mb-4': true}"
                        :label="$t('daterange')"
                        readonly
                        outlined
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :value="formattedDate"
                    />
                </div>
            </template>
        </vc-date-picker>
        <v-radio-group v-if="startDateType" v-model="startDateType" :column="false">
            <template v-slot:label>
                {{ $t('reportParameterNames.START_DATE_TYPE') }}
            </template>
            <v-radio class="mx-2" :label="$t('created')" value="Created" />
            <v-radio :label="$t('checkIn')" value="CheckIn" />
        </v-radio-group>
        <v-select
            v-if="country !== null"
            v-model="country"
            :items="countries"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('country')}`"
            :placeholder="`${$t('country')}`"
            class="mb-4"
        />
        <city-autocomplete
            v-if="cityId !== null"
            v-model="city"
            label="city"
            outlined
            :single-line="false"
            :disabled="!country"
            :country-id="country"
            class="mb-4"
        />
        <hotel-autocomplete
            v-if="hotelId !== null"
            v-model="hotel"
            label="hotel_name"
            outlined
            :single-line="false"
            :country-id="country"
            :city-id="cityId"
            :content-provider-id="1"
            class="mb-4"
        />
        <v-select
            v-if="currency"
            v-model="currency"
            :items="currencies"
            outlined
            clearable
            hide-details
            :label="`${$t('currency')}`"
            class="mb-4"
        />
        <date-picker
            v-if="exchangeRateDate"
            v-model="exchangeRateDate"
            :label="$t('reportParameterNames.DateForFixedDateRate')"
            :max="$dateFns.format(new Date())"
            outlined
            hide-details
        />
        <div class="radio-groups-alignment">
            <v-radio-group v-if="dateType" v-model="dateType" :column="false" hide-details>
                <template v-slot:label>
                    {{ $t('reportParameterNames.DateType') }}
                </template>
                <v-radio class="mx-2" :label="$t('reportParameterNames.reservationDate')" value="Created" />
                <v-radio class="mr-2" :label="$t('reportParameterNames.chekOutDate')" value="CheckOut" />
            </v-radio-group>
            <v-radio-group v-if="datePeriod" v-model="datePeriod" :column="false" hide-details>
                <template v-slot:label>
                    {{ $t('reportParameterNames.DatePeriod') }}
                </template>
                <v-radio class="mx-2" :label="$t('month')" value="Month" />
                <v-radio class="mr-2" :label="$t('quarter')" value="Quarter" />
                <v-radio class="mr-2" :label="$t('halfYear')" value="HalfYear" />
                <v-radio :label="$t('year')" value="Year" />
            </v-radio-group>
        </div>

        <v-select
            v-if="priceListType && !isAgency"
            v-model="priceListType"
            :items="priceListTypeItems"
            outlined
            hide-details
            :label="`${$t('price_list_type')}`"
            class="mb-4"
        />
        <v-select
            v-if="priceListType === 'CONTRACT_GROUP'"
            v-model="priceListTypeSecond"
            :items="contractGroups"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('priceListTypes.CONTRACT_GROUP')}`"
            class="mb-4"
        />
        <v-select
            v-if="priceListType === 'CLIENT' && !isAgency"
            v-model="priceListTypeSecond"
            :items="companies"
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-details
            :label="`${$t('priceListTypes.CLIENT')}`"
            class="mb-4"
        />
    </v-form>
</template>

<script>
    import {Component, Model, PropSync, Vue} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import HotelAutocomplete from '@/components/search/forms/HotelAutocomplete'
    import DatePicker from '@/components/snippets/forms/DatePicker'

    @Component({
        components: {HotelAutocomplete, CityAutocomplete, DatePicker},
    })
    export default class ReportParametersForm extends Vue {
        @Model('change', {default: true}) value
        @PropSync('_params', {default: () => ({})}) params

        showExchangeRateDatePicker = false
        cityItem = null
        hotelItem = null
        contractGroups = []
        companies = []

        async mounted() {
            try {
                const data = await Promise.all([
                    this.$api.contractGroups.get(),
                    this.$api.companies.get({relationship: 'CLIENT', shortResponse: true}),
                ])
                this.contractGroups = data[0].contractGroups
                this.companies = data[1].object
                if (this.priceListType && this.isAgency) {
                    this.priceListType = 'CLIENT'
                    this.priceListTypeSecond = this.companies[0].id
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get priceListTypeItems() {
            const priceListTypeItems = [
                {
                    value: 'NETTO',
                    text: this.$t('priceListTypes.NETTO'),
                },
                {
                    value: 'DIRECT_SALES',
                    text: this.$t('priceListTypes.DIRECT_SALES'),
                },
                {
                    value: 'CONTRACT_GROUP',
                    text: this.$t('priceListTypes.CONTRACT_GROUP'),
                },
                {
                    value: 'CLIENT',
                    text: this.$t('priceListTypes.CLIENT'),
                },
            ]
            if (authStore.isTO2) {
                priceListTypeItems.splice(0, 1)
            }
            return priceListTypeItems
        }

        get countries() {
            return runtimeStore.countries
        }

        get currencies() {
            return Object.values(this.$config.currency.available).map(currency => ({
                text: currency,
                value: currency,
            }))
        }

        get range() {
            return {
                start: this.startDate,
                end: this.endDate,
            }
        }

        set range(val) {
            if (!val) return
            this.setParam('DateFrom', `${val.start} 00:00`)
            this.setParam('DateTill', `${val.end} 23:59`)
        }

        get startDate() {
            return this.getParam('DateFrom')
        }

        get endDate() {
            return this.getParam('DateTill')
        }

        get country() {
            return this.getParam('Country')
        }

        set country(val) {
            this.setParam('Country', !val ? 0 : val)
            this.city = null
            this.hotel = null
        }

        get cityId() {
            return this.getParam('City')
        }

        get city() {
            return this.cityItem
        }

        set city(val) {
            this.cityItem = val
            this.setParam('City', !val ? 0 : val.id)
            this.hotel = null
        }

        get hotelId() {
            return this.getParam('OwnHotelSuggestion')
        }

        get hotel() {
            return this.hotelItem
        }

        set hotel(val) {
            this.hotelItem = val
            this.setParam('OwnHotelSuggestion', !val ? 0 : val.id)
        }

        get startDateType() {
            return this.getParam('START_DATE_TYPE')
        }

        set startDateType(val) {
            this.setParam('START_DATE_TYPE', val)
        }

        get currency() {
            return this.getParam('Currency')
        }

        set currency(val) {
            this.setParam('Currency', val)
        }

        get exchangeRateDate() {
            return this.getParam('DateForFixedDateRate')
        }

        set exchangeRateDate(val) {
            this.setParam('DateForFixedDateRate', `${val} 23:59`)
        }

        get datePeriod() {
            return this.getParam('DatePeriod')
        }

        get dateType() {
            return this.getParam('DateType')
        }

        set dateType(val) {
            this.setParam('DateType', val)
        }

        set datePeriod(val) {
            this.setParam('DatePeriod', val)
        }

        get priceListType() {
            const priceListType = this.getParam('PriceListType')
            return priceListType ? priceListType.first : null
        }

        set priceListType(first) {
            this.setParam('PriceListType', {first})
        }

        get priceListTypeSecond() {
            return this.getParam('PriceListType').second
        }

        set priceListTypeSecond(second) {
            this.setParam('PriceListType', {first: this.priceListType, second})
        }

        get formattedDate() {
            return this.range.start !== null && this.range.end !== null
                ? `${this.$options.filters.dateFormat(this.range.start)} - ${this.$options.filters.dateFormat(
                      this.range.end
                  )}`
                : ''
        }

        getParam(paramName) {
            const param = this.params.find(param => param.name === paramName)
            return param ? param.value : null
        }

        setParam(paramName, value) {
            const param = this.params.find(param => param.name === paramName)
            if (value) {
                this.$set(param, 'value', value)
            }
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        .radio-groups-alignment * {
            legend {
                flex: 0 0 80px;
            }
        }
    }
</style>
