<template>
    <div ref="header" class="header">
        <div>
            <v-app-bar v-show="visible" dense flat class="header" height="90" :dark="dark">
                <div class="full-width d-flex align-center justify-center">
                    <div
                        :style="{width: isMobile ? '100vw' : 'calc(80% + 64px)'}"
                        class="d-flex align-center justify-center"
                    >
                        <v-app-bar-nav-icon
                            v-if="$breakpoint.smAndDown"
                            style="color: #62b7e5"
                            @click="drawer = !drawer"
                        />
                        <header-block
                            v-if="!isMobile"
                            :style="$vuetify.rtl ? 'margin-left: 80px' : 'margin-right: 80px'"
                        />
                        <footer-menu v-if="!isMobile" />
                        <div class="flex-grow-1" />
                        <client-only>
                            <div v-if="$breakpoint.mdAndUp" class="d-md-flex d-lg-flex align-center">
                                <currency-switcher
                                    v-if="
                                        !['authorization', 'b2b-auth', 'restore-password', 'hotelDirectory'].includes(
                                            $route.name
                                        ) ||
                                        ($route.name === 'hotelDirectory' && person)
                                    "
                                />
                                <language-switcher class="ma-0" />
                                <account-menu
                                    v-if="
                                        !['authorization', 'b2b-auth', 'restore-password', 'hotelDirectory'].includes(
                                            $route.name
                                        ) ||
                                        ($route.name === 'hotelDirectory' && person)
                                    "
                                />
                            </div>
                        </client-only>
                        <header-block v-if="isMobile" />
                    </div>
                </div>
            </v-app-bar>
        </div>
        <portal-target name="under-header" />
    </div>
</template>

<script>
    import HeaderBar from '~/components/HeaderBar'
    import FooterMenu from '~src/components/snippets/footerMenu.src'
    import {Component} from 'nuxt-property-decorator'

    @Component({
        components: {FooterMenu},
    })
    export default class HeaderBarMod extends HeaderBar {}
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .theme--light.v-app-bar.v-toolbar.v-sheet.header {
        background-color: #ffffff;
        border-bottom: 1px #eeeeee solid;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .header {
            transition: all 0.3s ease-in-out;
            position: sticky;
            top: 0;
            z-index: 10;
        }

        .header.hide {
            top: -90px;
        }
    }
</style>
