<template>
    <div v-if="tripadvisorRating">
        <v-divider class="mt-3 mb-2" />
        <tripadvisor-rating :tripadvisor-rating="tripadvisorRating" />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import TripadvisorRating from '~src/components/hotels/snippets/tripadvisorRating.src'

    @Component({components: {TripadvisorRating}})
    export default class TripadvisorRatingWrapper extends Vue {
        @Prop() tripadvisorRating
    }
</script>
