<template>
    <v-footer
        v-show="visible"
        v-intersect="onIntersect"
        dark
        color="#262626"
        :height="!isMobile ? '386' : 'auto'"
        class="d-flex justify-center pt-8"
    >
        <div class="d-flex flex-column full-height" :style="{width: isMobile ? '100%' : '80%'}">
            <v-row class="py-8 d-flex justify-start">
                <v-col cols="12" md="2">
                    <div class="full-width d-flex align-center" style="height: 60px">
                        <img :src="logo | srcHost" alt="" style="height: 50px" />
                    </div>
                    <social-icons v-if="socialLinks.length" style="width: max-content" class="mt-4" />
                </v-col>
                <v-col cols="12" md="10">
                    <div class="d-flex flex-column flex-md-row mb-8 align-md-center align-start">
                        <div class="d-flex">
                            <footer-menu
                                v-if="
                                    footerMenu.length &&
                                    !['authorization', 'b2b-auth', 'restore-password'].includes($route.name)
                                "
                                dark
                            />
                        </div>
                        <div class="flex-grow-1" />
                        <v-form
                            v-model="valid"
                            action="https://ajbooking.us21.list-manage.com/subscribe/post?u=68e1ffea5e0abb69844aa57c9&amp;id=2b41945763&amp;f_id=0046aae1f0%22"
                            method="post"
                            name="mc-embedded-subscribe-form"
                            target="_blank"
                            novalidate
                        >
                            <div class="d-flex flex-column flex-md-row align-center full-width">
                                <v-text-field
                                    style="background: white"
                                    :class="{'full-width': isMobile}"
                                    type="email"
                                    name="EMAIL"
                                    :rules="validationRules('email', [emailRule, requiredRule])"
                                    dense
                                    class="flex-grow-1 text-subtitle-2 font-weight-regular"
                                    hide-details
                                    background-color="white"
                                    outlined
                                    light
                                    :style="!isMobile ? 'width: 250px' : 'width: 100%'"
                                    :placeholder="$t('enter_email')"
                                />
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px" aria-hidden="true">
                                    <input
                                        type="text"
                                        name="b_0c1ac0c4e178ae7f98885d924_65d53e82a0"
                                        tabindex="-1"
                                        value=""
                                    />
                                </div>
                                <v-btn
                                    type="submit"
                                    value="Subscribe"
                                    name="subscribe"
                                    :disabled="!valid"
                                    :class="{'mt-4': isMobile}"
                                    :large="isMobile"
                                    elevation="0"
                                    color="primary text-subtitle-2 font-weight-regular"
                                    style="height: 40px"
                                    :style="!isMobile ? '' : 'width: 100%'"
                                    class="ms-md-4 px-8 mt-4 mt-md-0"
                                >
                                    {{ $t('subscribe') }}
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                    <v-divider v-if="!isMobile" />
                    <div class="d-flex mb-8 align-center pt-8">
                        <div v-if="!isMobile" class="flex-grow-1" />
                        <div
                            class="d-flex align-center text-subtitle-2 font-weight-regular"
                            :style="!isMobile ? '' : 'width: 100%'"
                        >
                            <span class="mx-md-8"> {{ $t('our_payments') }} </span>
                            <div v-if="isMobile" class="flex-grow-1" />
                            <v-sheet
                                color="white"
                                height="41"
                                width="230"
                                class="d-flex align-center justify-space-between rounded-lg px-4"
                            >
                                <v-img src="/mada.png" max-width="60px" />
                                <v-img src="/VISA.png" max-width="50px" />
                                <v-img src="/hyper-pay.png" max-width="60px" />
                            </v-sheet>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div class="flex-grow-1" />
            <v-divider />
            <div
                class="d-flex flex-column flex-md-row pt-5 text-subtitle-2 align-center align-md-start font-weight-regular justify-center justify-md-start"
                style="height: 106px; opacity: 0.5"
            >
                <span v-html="$t('copyright')" />
                <span v-if="!hideAppVersion" class="mx-1">v{{ version }}</span>
                <v-spacer />
                <v-btn text to="/info/privacy-policy">
                    {{ $t('privacy_policy') }}
                </v-btn>
            </div>
        </div>
    </v-footer>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import FooterBar from '~/components/FooterBar'
    import {mixins} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class FooterBarMod extends mixins(FormValidationRulesMixin, FooterBar) {
        valid = false

        get logo() {
            return '/logo.png'
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
