<template>
    <div>
        <div class="float-right text-right">
            <tripadvisor-rating
                v-if="offer && offer.tripadvisorRating && !isMobile"
                :tripadvisor-rating="offer.tripadvisorRating"
            />
        </div>
        <div class="d-flex flex-column flex-md-row mb-4">
            <div class="title d-flex flex-column flex-md-row align-md-center" data-cy="hotel-name">
                <span class="text-h5 font-weight-bold text-md-h4">
                    {{ info.hotelName }}
                </span>
                <div class="d-flex align-center">
                    <hotel-stars
                        v-if="offer"
                        color="warning"
                        :size="20"
                        :small="false"
                        class="ms-md-3 me-3 mt-1 mb-2 mt-md-0 mb-md-0"
                        :hotel="{category, ratings: offer.ratings}"
                    />
                    <v-chip
                        v-if="type"
                        color="#E4EEF5"
                        style="width: max-content"
                        x-small
                        label
                        class="text-uppercase mt-1 ms-md-3"
                    >
                        {{ type }}
                    </v-chip>
                </div>
                <tripadvisor-rating
                    v-if="offer && offer.tripadvisorRating && isMobile"
                    :tripadvisor-rating="offer.tripadvisorRating"
                />
            </div>
            <hotel-own-label v-if="offer && offer.rooms" class="ms-1" :offer="offer" />
        </div>
        <hotel-supplier-name-label v-if="isTO1 && offer" :supplier-name="offer.supplierName" />
        <div v-if="offer" class="d-flex flex-column flex-md-row caption">
            <div v-if="offer.distance" class="me-8">
                <v-icon left small color="accent">mdi-map-marker-distance</v-icon>
                {{ offer.distance }}
                {{ $t('km_from_center') }}
            </div>
            <div v-if="address" class="me-8 mt-3 mt-md-0" @click="$emit('showMapTab')">
                <v-icon small left color="accent">mdi-map-marker</v-icon>
                {{ address }}
            </div>
            <div v-if="offer.district && offer.district !== 'other'" class="mt-3 mt-md-0">
                <v-icon left small color="accent">mdi-map-marker-distance</v-icon>
                <span class="font-weight-medium">{{ $t('area') }}:</span> {{ offer.district }}
            </div>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelInfo from '@/components/info/HotelInfo'
    import TripadvisorRating from '~src/components/hotels/snippets/tripadvisorRating.src'

    @Component({
        components: {TripadvisorRating},
    })
    export default class HotelInfoMod extends HotelInfo {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
