<template>
    <order-summary-layout :product-store="productStore" :total-price="totalPrice">
        <div class="d-flex mb-4">
            <v-img
                :alt="offer.name"
                src="/insurance.jpg"
                lazy-src="/placeholder.png"
                width="150"
                height="150"
                tile
                class="me-3"
                @error="imgError = true"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && offer.mainImageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div>
                <div class="font-weight-medium text-truncate primary--text">
                    {{ offer.name }}
                </div>
                <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" />
                <div class="text-body-2" v-html="offer.shortDescription" />
            </div>
        </div>
        <slot name="summary" />
        <v-divider class="mb-2" />
        <div>
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                <v-icon small left>mdi-information-outline</v-icon>
                <span>
                    {{ offer.planName }}
                </span>
            </div>
            <div>
                <div v-if="offer.available" class="caption mt-1">
                    <v-icon :title="$t('available')" left small class="success--text">
                        mdi-checkbox-marked-circle
                    </v-icon>
                    <span class="success--text" v-text="$t('available')" />
                </div>
                <div v-else class="caption mt-1">
                    <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                    <span class="warning--text" v-text="$t('on_request')" />
                </div>
            </div>
            <conditions-label
                v-if="offer"
                :offer-key="offer.offerKey"
                :price="offer.price"
                class="text-caption"
                @conditions="showConditionsInfo"
            />
            <div class="mt-1 font-weight-bold">
                <span>
                    {{ offer.price | price }}
                </span>
                <commission-amount :commission="offer.price.commission" />
                <net-amount :price="offer.price" />
            </div>
        </div>
    </order-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'
    import {authStore, insuranceStore} from '@/utils/store-accessor'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_INSURANCE_CONDITIONS_INFO, SHOW_INSURANCE_INFO_MODAL} from '@/utils/event-bus'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import NetAmount from '@/components/snippets/NetAmount'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            OrderSummaryLayout,
            ConditionsLabel,
            CommissionAmount,
            NetAmount,
            SupplierNameLabel,
        },
    })
    export default class InsuranceOrderSummary extends Vue {
        @Prop({required: true}) product
        @Prop({required: true}) offer
        @Prop({required: true}) searchRequest
        @Prop({default: true}) prebooked

        imgError = false

        showConditionsInfo() {
            EventBus.$emit(SHOW_INSURANCE_CONDITIONS_INFO, this.searchRequest, this.offer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_INSURANCE_INFO_MODAL, {offer: this.offer})
        }

        get productStore() {
            return insuranceStore
        }

        get totalPrice() {
            return insuranceStore.orderTotalPrice(this.offer, this.prebooked)
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
