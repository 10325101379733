<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <v-list-item-avatar v-if="offer.thumb" class="my-0" :size="isCompactMode ? '50' : '125'" tile>
            <v-img
                tile
                class="thumb"
                :src="offer.thumb | imageUrl(250)"
                :alt="offer.name"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="onImgError"
                @load="load"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </v-list-item-avatar>
        <div class="align-self-start" :class="isCompactMode ? 'small-content' : ''">
            <div class="d-flex align-center flex-wrap">
                <hotel-stars :hotel="offer" :color="'warning'" />
                <hotel-own-label class="ms-1" :offer="offer" />
            </div>
            <div class="text-h6 text-truncate black--text mb-1">
                <span>
                    {{ offer.name }}
                </span>
            </div>
            <hotel-location-label :offer="offer" class="my-1" />
            <hotel-address-label :address="offer.address" :offer="offer" class="caption d-flex align-center my-1" />
            <template v-if="minPriceRoomOffer">
                <div
                    v-if="minPriceRoomOffer.price"
                    class="subtitle-1 d-flex font-weight-bold"
                    :class="isCompactMode ? 'small-content' : ''"
                >
                    <span>
                        {{ $t('from') }}
                    </span>
                    &nbsp;
                    <price-label :price="minPriceRoomOffer.price" />
                </div>
                <offer-delta-button v-else :room="minPriceRoomOffer" />
            </template>
            <v-btn
                v-if="hotelPageLink"
                depressed
                color="primary"
                class="px-8 mt-2 font-weight-regular"
                nuxt
                :to="hotelPageLink"
            >
                {{ $t('see_details') }}
            </v-btn>
        </div>
    </v-list-item>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelMapInfoWindow from '@/components/search/offers/HotelMapInfoWindow'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelTypeLabel from '@/components/hotels/snippets/HotelTypeLabel'
    import HotelOwnLabel from '@/components/hotels/snippets/HotelOwnLabel'
    import HotelLocationLabel from '~src/components/hotels/snippets/hotelLocationLabel.src'
    import HotelAddressLabel from '~src/components/hotels/snippets/hotelAddressLabel.src'

    @Component({
        components: {HotelAddressLabel, HotelLocationLabel, HotelOwnLabel, HotelTypeLabel, HotelStars},
    })
    export default class HotelMapInfoWindowMod extends HotelMapInfoWindow {}
</script>
