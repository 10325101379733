<template>
    <div v-if="address" class="text-truncate" :class="{'cursor-pointer': !!offer}" @click.stop="showOnMap">
        <small>
            <v-icon v-if="!hideIcon" small color="accent">mdi-map-marker</v-icon>
            {{ address }}
        </small>
        <div class="ms-5 text-caption primary--text text-decoration-underline text-lowercase">
            {{ $t('show_on_map') }}
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus, SHOW_ON_MAP_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelAddressLabel extends Vue {
        @Prop({required: true}) address
        @Prop({default: false, type: Boolean}) hideIcon
        @Prop() offer

        showOnMap() {
            if (!this.offer) return
            if (this.$breakpoint.smAndDown) {
                EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 2)
            }
            EventBus.$emit(SHOW_ON_MAP_EVENT, this.offer)
        }
    }
</script>
