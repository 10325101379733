<template>
    <div>
        <tourist-form
            ref="form"
            v-model="tourist"
            :index="index"
            :disabled="disabled"
            :corporate-policy-violation="corporatePolicyViolation"
            :hide-index="hideIndex"
            :disable-private-client-name="lockEditPrivateClientName"
            :hide-contact-data="roomLength !== 1 && !isTourLead && sameContactDataForAll"
            :selected-tourists="selectedTourists"
            :disable-citizenship="disableCitizenship"
            :start-date="startDate"
            :country-id="countryId"
            @person-selected="personSelected"
        >
            <template v-slot:label>
                <div class="d-flex mb-6">
                    <div v-if="!hideIndex" class="text-h6 text-capitalize font-weight-bold">
                        {{ `${$tc('guest', 1)} ${index + 1} ${isChild ? `(${$tc('child')})` : ''}` }}
                    </div>
                    <div v-if="bedType" class="ms-4 d-flex align-center">
                        <div v-if="bedType === 'onExtraBed'" class="font-weight-bold grey--text">
                            <v-icon color="info" left>mdi-bed-outline</v-icon>
                            {{ $t('extra_bed') }}
                        </div>
                        <div v-if="bedType === 'onWithoutPlace'" class="font-weight-bold grey--text">
                            <v-icon color="warning" left>mdi-bed-empty</v-icon>
                            {{ $t('without_bed') }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:beforeContacts>
                <v-col v-if="showMealTypes" cols="12" md="4" lg="2" class="hideEmpty">
                    <meal-type-selector
                        v-model="alternativeMealTypeId"
                        :room-index="roomIndex"
                        :append-icon="'mdi-chevron-down'"
                        :standard-meal-name="standardMealName"
                        :disabled="disabled"
                        data-cy="tourist-field-meal-type"
                        :is-tour-lead="isTourLead"
                        :tourist="tourist"
                        @update="setExtraMealType"
                    />
                </v-col>
            </template>
            <v-col v-if="showSameContactDataCheckbox" cols="12">
                <v-checkbox
                    :input-value="sameContactDataForAll"
                    :label="`${$t('same_contact_data')}`"
                    @change="changeSameContactData"
                />
            </v-col>
        </tourist-form>
        <hotel-tourist-passport-form
            :room-index="roomIndex"
            :index="index"
            :append-icon="'mdi-chevron-down'"
            :disabled="disabled"
            :corporate-policy-violation="corporatePolicyViolation"
            @set-prop="$emit('change-passport')"
        />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelTouristForm from '@/components/booking/forms/HotelTouristForm'

    @Component
    export default class HotelTouristFormMod extends HotelTouristForm {}
</script>
