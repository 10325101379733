<template>
    <div v-if="selectedFilters.length">
        <div class="full-width d-flex py-3">
            <div class="selected_title text-h5 font-weight-medium">
                {{ $t('filter_by') }}
            </div>
            <div class="flex-grow-1" />
            <v-btn color="primary" text @click="clearFilters">
                <span class="font-weight-regular">
                    {{ $t('clear_filters') }}
                </span>
            </v-btn>
        </div>
        <div v-for="item in selectedFilters" :key="item.key" class="mt-1">
            <div class="d-flex flex-wrap">
                <v-chip
                    v-for="value in item.values"
                    :key="value"
                    class="me-1 mb-1"
                    color="primary"
                    close
                    @click:close="deleteFilter(item.key, value)"
                >
                    {{ item.translatable ? $t(`filters_${item.key}.${value}`) : value }}
                </v-chip>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import SelectedFilters from '@/components/snippets/SelectedFilters'

    @Component
    export default class SelectedFiltersMod extends SelectedFilters {
        clearFilters() {
            this.selectedFilters.forEach(item => {
                item.values.forEach(value => {
                    this.deleteFilter(item.key, value)
                })
            })
        }
    }
</script>
