<template>
    <div class="mb-5">
        <v-card class="mb-2 pb-8">
            <div class="pa-4"><slot /></div>
            <v-divider class="my-4" />
            <span class="text-h6 text-md-h5 font-weight-medium ps-4">
                {{ $t('price') }}
            </span>
            <v-card-text v-if="showPrice && originalCurrency !== currency">
                <original-currency-notification :price="totalPrice" />
            </v-card-text>
            <div v-if="showPrice" class="d-flex">
                <v-spacer v-if="!isMobile" />
                <div class="text-right px-4 px-md-2">
                    <div>
                        <total-price-label
                            :total-price="totalPrice"
                            :offer="offer"
                            class="text-h6 text-md-h5 font-weight-medium"
                        />
                        <commission-amount
                            v-if="isAgency && totalPrice.commission"
                            :commission="{amount: totalPrice.commission, currency: totalPrice.currency}"
                        />
                        <net-amount :price="netAmount" />
                        <slot name="violatedPolicies" />
                        <change-total-price-dialog
                            v-if="(isTO1 || isTO2) && totalPrice.supplierPriceAmount !== undefined && !bookingKey"
                            :room-offers="roomOffers"
                            :product-store="productStore"
                            :total-price="totalPrice"
                        />
                    </div>
                    <div v-if="!isNaN(totalPrice.loyaltyPoints)" class="text-caption text--secondary">
                        <div>{{ $t('loyalty') }} : {{ totalPrice.loyaltyPoints }}</div>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import OrderSummaryLayout from '@/components/booking/OrderSummaryLayout'

    @Component
    export default class OrderSummaryLayoutMod extends OrderSummaryLayout {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
