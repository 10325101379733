<template>
    <v-data-table :headers="headers" :items="documentTemplates" :loading="loading" disable-sort class="mt-3">
        <template v-slot:top>
            <div class="text-h6 ps-3">
                {{ $t('admin.document_templates') }}
            </div>
            <v-btn class="ms-3 mb-2" color="primary" :to="{name: 'newDocumentEditor'}">
                {{ $t('add') }}
            </v-btn>
        </template>
        <template v-slot:item.notificationType="{item}">
            <span>
                {{ item.documentType[0].toUpperCase() + item.documentType.slice(1) }}
            </span>
        </template>
        <template v-slot:item.edit="{item}">
            <v-btn
                nuxt
                :to="{
                    name: 'documentEditor',
                    params: {id: item.id},
                }"
                icon
                small
                color="primary"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import EmailTemplatesPage from '@/pages/admin/EmailTemplatesPage'
    import DocumentTemplatesMixin from '@/components/account/mixins/DocumentTemplatesMixin'

    @Component
    export default class DocumentTemplatesPage extends mixins(EmailTemplatesPage, DocumentTemplatesMixin) {
        async mounted() {
            this.loading = true
            await this.loadDocumentTemplates()
            this.loading = false
        }
    }
</script>
