<template>
    <div>
        <v-text-field
            ref="option"
            v-model="value"
            :name="name"
            :label="label"
            clearable
            :single-line="singleLine"
            :outlined="outlined"
            :dense="dense"
            hide-details
            :dark="dark"
        />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class TextOption extends Vue {
        @Prop({default: false}) outlined
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: true, type: Boolean}) dark
        @Prop({default: true}) singleLine
        value = null

        @Prop({
            required: true,
        })
        name

        @Prop()
        label

        created() {
            this.value = this.$route.query[this.name]
        }
    }
</script>
