<script>
    import {persistentStore} from '@/utils/store-accessor'
    import {Component} from 'nuxt-property-decorator'
    import InvoicePaymentCompletePage from '@/pages/booking/InvoicePaymentCompletePage'

    @Component
    export default class ManualInvoicePaymentCompletePage extends InvoicePaymentCompletePage {
        async load() {
            const {invoicePaymentKey, paymentNumber, orderId, status, remark, transactionDateTime} = this.$route.query

            if (status !== 'success') {
                persistentStore.SET_SELECTED_ORDER_ID(null)
                this.orderDetailsRedirect(orderId)
                return
            }

            let pspResponse = this.$route.query

            delete pspResponse.status
            delete pspResponse.invoicePaymentKey
            delete pspResponse.orderId
            delete pspResponse.remark
            delete pspResponse.transactionDateTime

            pspResponse = new URLSearchParams(pspResponse).toString()
            try {
                const paymentCompleteRs = await persistentStore.paymentComplete({
                    invoicePaymentKey,
                    paymentNumber,
                    pspResponse,
                })
                if (paymentCompleteRs.result === 'Success') {
                    this.paymentComplete = true
                    try {
                        await this.$api.manualInvoicePaymentByKey.put({
                            invoicePaymentKey: invoicePaymentKey,
                            remark,
                            transactionDateTime,
                        })
                        persistentStore.SET_SELECTED_ORDER_ID(null)
                        this.orderDetailsRedirect(orderId)
                    } catch (e) {
                        this.$toast.error('Something wrong on change invoice step')
                        this.error = true
                    }
                } else {
                    this.$toast.error('Something wrong on payment complete step')
                    console.error(paymentCompleteRs)
                    this.error = true
                }
            } catch (e) {
                this.$toast.error('Something wrong on payment complete step')
                this.error = true
            }
        }
    }
</script>
