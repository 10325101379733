<template>
    <div class="mb-5">
        <strong class="d-block">{{ offer.serviceName }}</strong>
        <strong class="d-block">{{ $t('date') }}: {{ offer.date | dateFormat }}</strong>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_ACTIVITY_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component
    export default class ActivityConditionsModalInfo extends Vue {
        offer = {}

        mounted() {
            EventBus.$on(SHOW_ACTIVITY_CONDITIONS_INFO, this.showInfo)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_ACTIVITY_CONDITIONS_INFO, this.showInfo)
        }

        showInfo(offer) {
            this.offer = offer
        }
    }
</script>
