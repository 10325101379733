<template>
    <div>
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field
                        v-model="name"
                        outlined
                        hide-details
                        filled
                        :prepend-inner-icon="'mdi-magnify'"
                        class="mb-8"
                        single-line
                        dense
                        :clearable="true"
                        :placeholder="placeholder"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import NameFilter from '~/components/filters/NameFilter'

    @Component
    export default class NameFilterMod extends NameFilter {}
</script>
