<template>
    <company-autocomplete v-model="selectedCompany" :types="['CORPORATOR']" :label="`${$t('choose.company_long')}*`" />
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import CompanyAutocomplete from '@/components/account/modals/newOrderRequest/CompanyAutocomplete'

    @Component({
        components: {CompanyAutocomplete},
    })
    export default class CorporateClient extends Vue {
        @VModel() selectedCompany
    }
</script>
