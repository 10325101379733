<template>
    <v-card class="my-2 car-card" outlined>
        <v-img
            tile
            :src="mainImg | imageUrl(600)"
            :alt="product.info.modelName"
            height="200"
            lazy-src="/placeholder.png"
            eager
            class="white--text align-end"
        >
            <template v-slot:placeholder>
                <v-row
                    v-show="!imgError && product.info.imageUrl"
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
            </template>
        </v-img>
        <div class="mb-1 font-weight-medium text-truncate">
            {{ product.info.modelName }}
        </div>
        <supplier-name-label v-if="isTO1" :supplier-name="product.info.supplierName" class="mb-1" />
        <slot name="summary" />
        <car-feature-labels :info="product.info" small class="mb-2" />
        <car-characteristic :info="product.info" />
        <div v-if="product.info.shortDescription" v-html="product.info.shortDescription" />
        <div class="cursor-pointer" @click="mapModal = true">
            <v-icon small color="accent">mdi-map-marker</v-icon>
            <span class="text-caption primary--text text-decoration-underline text-lowercase">
                {{ $t('show_on_map') }}
            </span>
        </div>
        <div class="wrap-words mt-1">
            <div v-if="product.offer.available" class="d-flex align-center caption">
                <v-icon :title="$t('available')" left small>mdi-checkbox-marked-circle</v-icon>
                <span class="success--text" v-text="$t('available')" />
            </div>
            <div v-else class="d-flex align-center caption">
                <v-icon :title="$t('on_request')" left small>mdi-alert-circle</v-icon>
                <span class="warning--text" v-text="$t('on_request')" />
            </div>
        </div>
        <span v-if="product.offer && product.offer.price" class="caption mt-1">
            <conditions-label
                :offer-key="product.offer.offerKey"
                :price="product.offer.price"
                @conditions="showConditionsInfo"
            />
        </span>
        <div class="d-flex align-center primary--text cursor-pointer text-no-wrap caption" @click="showOfferInfo">
            <v-icon small left dense>mdi-information-outline</v-icon>
            <span class="show-description-label">
                {{ `${$t('show')} ${$t('description').toLowerCase()}` }}
            </span>
        </div>
        <feature-includes :features="product.info.features" class="mt-1" />
        <div v-if="!bookDisabled" class="d-flex justify-space-between align-center">
            <discount-label :price="product.offer.price" class="d-inline-block" />
            <div data-cy="client-price" class="font-weight-medium">
                {{ product.offer.price | price }}
            </div>
            <v-btn color="primary" depressed nuxt class="my-2" @click="selectForBooking">
                {{ $t('book') }}
            </v-btn>
        </div>
        <client-only>
            <v-dialog v-model="mapModal" :max-width="600">
                <car-map-points
                    :_expanded.sync="mapExpanded"
                    :pick-up-points="product.offer.pickUpPoints"
                    :return-points="product.offer.returnPoints"
                    :parent="true"
                    style="max-height: 500px; position: unset"
                />
            </v-dialog>
        </client-only>
    </v-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import FeatureIncludes from '@/components/carsRent/snippets/FeatureIncludes'
    import CarFeatureLabels from '@/components/carsRent/snippets/CarFeatureLabels'
    import CarCharacteristic from '@/components/carsRent/snippets/CarCharacteristic'
    import CarOfferCard from '@/components/carsRent/search/offers/CarOfferCard'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'

    @Component({
        components: {
            CommissionAmount,
            ConditionsLabel,
            DiscountLabel,
            FeatureIncludes,
            CarFeatureLabels,
            CarCharacteristic,
            SupplierNameLabel,
        },
    })
    export default class CarOfferCardMobile extends CarOfferCard {
        @Prop({default: false, type: Boolean}) bookDisabled
    }
</script>

<style lang="scss" scoped>
    .car-card {
        border: none !important;
    }
</style>
