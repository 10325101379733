<template>
    <v-form v-if="segments.length" ref="form">
        <h4 class="text-subtitle-1 font-weight-bold text-capitalize">
            {{ $t('flights.extra_baggage') }}
        </h4>
        <v-expansion-panels :disabled="disabled" accordion class="mb-5" flat focusable>
            <v-expansion-panel v-for="segment in segments" :key="segment.rph">
                <v-expansion-panel-header v-slot="{open}">
                    <div>
                        {{ segment.departure.airportDescription.airportName }},
                        {{ segment.departure.airportDescription.cityName }}
                        ({{ segment.departure.airportCode }}) - {{ segment.arrival.airportDescription.airportName }},
                        {{ segment.arrival.airportDescription.cityName }}
                        ({{ segment.arrival.airportCode }})
                    </div>
                    <div class="d-flex justify-end mr-5">
                        <v-chip v-if="segmentTotalPrice(segment.rph).amount" color="info" label small>
                            +{{ segmentTotalPrice(segment.rph) | price }}
                        </v-chip>
                        <span v-else-if="open">
                            {{ $t('flights.select_extra_baggage') }}
                        </span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row
                        v-for="extraBaggageOption in specifiedExtraBaggageOptions(segment.rph)"
                        :key="extraBaggageOption.serviceRPH"
                        dense
                    >
                        <v-col cols="12" md="3">
                            <v-checkbox
                                :disabled="disabled"
                                :input-value="!!selectedExtraBaggage(extraBaggageOption.serviceRPH)"
                                :label="extraBaggageOption.name"
                                @change="toggleExtraBaggage(extraBaggageOption.serviceRPH)"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                :label="$t('price')"
                                :value="extraBaggageOption.price | price"
                                class="price"
                                disabled
                            />
                        </v-col>
                        <v-col v-if="selectedExtraBaggage(extraBaggageOption.serviceRPH)" cols="12" md="2">
                            <v-text-field
                                :label="$t('quantity')"
                                :max="extraBaggageOption.limit || 5"
                                :min="1"
                                :rules="[
                                    v =>
                                        (!extraBaggageOption.limit && v <= 5 && v > 0) ||
                                        (v <= extraBaggageOption.limit && v > 0),
                                ]"
                                :value="
                                    selectedExtraBaggage(extraBaggageOption.serviceRPH)
                                        ? selectedExtraBaggage(extraBaggageOption.serviceRPH).quantity
                                        : 0
                                "
                                type="number"
                                @input="changeQuantity(extraBaggageOption.serviceRPH, $event)"
                            />
                        </v-col>
                        <v-col v-if="selectedExtraBaggage(extraBaggageOption.serviceRPH)" cols="12" md="2">
                            <v-text-field
                                :label="$t('total')"
                                :value="selectedExtraBaggageTotal(extraBaggageOption) | price"
                                class="price"
                                disabled
                            />
                        </v-col>
                        <v-col
                            v-if="segmentsCount(extraBaggageOption.serviceRPH) > 1"
                            class="caption d-flex align-center"
                            cols="12"
                            md="2"
                        >
                            *{{ $t('flights.for_multiple_flights') }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-form>
</template>

<script>
    import {Component, Model, Prop, Vue} from 'nuxt-property-decorator'
    import {persistentStore} from '@/store'
    import {clone} from '@/utils/helpers'

    @Component
    export default class ExtraBaggageForm extends Vue {
        @Model() prepareBookRequest
        @Prop({required: true}) index
        @Prop({required: true}) disabled
        @Prop({required: true}) offer
        @Prop({required: true}) extraBaggageOptions

        mounted() {
            if (this.prepareBookRequest.extraBaggage && this.prepareBookRequest.offerKey !== this.offer.offerKey) {
                this.prepareBookRequest.extraBaggage = []
            }
        }

        specifiedExtraBaggageOptions(rph) {
            return this.extraBaggageOptions.filter(
                extraBaggageOption => !extraBaggageOption.segmentRPHs || extraBaggageOption.segmentRPHs.includes(rph)
            )
        }

        selectedExtraBaggage(serviceRPH) {
            if (!this.prepareBookRequest.extraBaggage) return null
            return this.prepareBookRequest.extraBaggage.find(
                extraBaggage => extraBaggage.touristIndex === this.index && extraBaggage.serviceRPH === serviceRPH
            )
        }

        selectedExtraBaggageTotal(extraBaggageOption) {
            const extraBaggage = this.selectedExtraBaggage(extraBaggageOption.serviceRPH)
            return {
                amount: extraBaggageOption.price.amount * extraBaggage.quantity,
                currency: extraBaggageOption.price.currency,
            }
        }

        toggleExtraBaggage(serviceRPH) {
            let extraBaggage = []
            if (this.prepareBookRequest.extraBaggage) {
                extraBaggage = clone(this.prepareBookRequest.extraBaggage)
            }
            const extraBaggageIndex = extraBaggage.findIndex(
                extraBaggage => extraBaggage.touristIndex === this.index && extraBaggage.serviceRPH === serviceRPH
            )
            if (extraBaggageIndex === -1) {
                extraBaggage.push({
                    serviceRPH,
                    quantity: 1,
                    touristIndex: this.index,
                })
            } else {
                extraBaggage.splice(extraBaggageIndex, 1)
            }
            if (this.prepareBookRequest.offerKey !== this.offer.offerKey) {
                this.prepareBookRequest.offerKey = this.offer.offerKey
            }
            this.prepareBookRequest.extraBaggage = extraBaggage
        }

        changeQuantity(serviceRPH, quantity) {
            const extraBaggage = clone(this.prepareBookRequest.extraBaggage)
            const extraBaggageIndex = this.prepareBookRequest.extraBaggage.findIndex(
                extraBaggage => extraBaggage.touristIndex === this.index && extraBaggage.serviceRPH === serviceRPH
            )
            extraBaggage[extraBaggageIndex].quantity = +quantity
            this.prepareBookRequest.extraBaggage = extraBaggage
        }

        segmentTotalPrice(rph) {
            const segmentTotalPrice = {amount: 0, currency: persistentStore.currency}
            if (!this.prepareBookRequest.extraBaggage) return segmentTotalPrice
            return this.prepareBookRequest.extraBaggage.reduce((segmentTotalPrice, extraBaggage) => {
                if (extraBaggage.touristIndex !== this.index) return segmentTotalPrice
                const extraBaggageOption = this.specifiedExtraBaggageOptions(rph).find(
                    extraBaggageOption => extraBaggage.serviceRPH === extraBaggageOption.serviceRPH
                )
                if (
                    !extraBaggageOption ||
                    !extraBaggageOption.price ||
                    (extraBaggageOption.segmentRPHs && extraBaggageOption.segmentRPHs[0] !== rph)
                )
                    return segmentTotalPrice
                segmentTotalPrice.amount +=
                    this.$options.filters.convertPrice(extraBaggageOption.price).amount * extraBaggage.quantity
                return segmentTotalPrice
            }, segmentTotalPrice)
        }

        segmentsCount(serviceRPH) {
            const extraBaggageOption = this.extraBaggageOptions.find(
                extraBaggageOption => extraBaggageOption.serviceRPH === serviceRPH
            )
            return extraBaggageOption.segmentRPHs ? extraBaggageOption.segmentRPHs.length : 1
        }

        get segments() {
            return this.offer.itinerary.reduce((segments, itinerary) => {
                itinerary.segments.forEach(segment => {
                    if (this.specifiedExtraBaggageOptions(segment.rph).length) {
                        segments.push(segment)
                    }
                })
                return segments
            }, [])
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    ::v-deep {
        .price .theme--light.v-label--is-disabled {
            color: map-get(map-get($material-light, 'text'), 'secondary');
        }

        .price input {
            color: map-get(map-get($material-light, 'text'), 'primary');
        }
    }
</style>
