var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"footer-props":{'items-per-page-options': [50, 100]},"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.tourName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium text-truncate"},[(_vm.$route.name === 'tourSearch')?_c('nuxt-link',{staticClass:"text-decoration-none",attrs:{"no-prefetch":"","to":{name: 'tour', query: item.query}}},[_vm._v("\n                    "+_vm._s(item.tourName)+"\n                ")]):_c('span',[_vm._v(_vm._s(item.tourName))])],1)]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"nowrap"},[_vm._v("\n                "+_vm._s(item.duration.days)+"\n                "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-weather-sunny")]),_vm._v("\n                "+_vm._s(("/ " + (item.duration.nights)))+"\n                "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-weather-night")])],1)]}},{key:"item.accommodation",fn:function(ref){
var item = ref.item;
return [(item.accommodation)?_c('div',[_vm._v("\n                "+_vm._s(item.accommodation)+"\n            ")]):_c('span',[_vm._v("-")])]}},{key:"item.services",fn:function(ref){
var item = ref.item;
return [_c('tour-included-entries',{attrs:{"entries":item.services}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","depressed":"","x-small":""},on:{"click":function($event){return _vm.selectForBooking(item)}}},[_vm._v("\n                "+_vm._s(_vm._f("price")(item.price))+"\n            ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }