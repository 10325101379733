<template>
    <v-row>
        <v-col cols="12">
            <div class="pa-2">
                <v-card class="pa-4" outlined>
                    <div class="d-flex align-center" style="position: absolute; top: 12px; right: 24px; z-index: 1">
                        <v-icon left small> mdi-account-check-outline </v-icon>
                        <span class="caption">
                            {{ `${orderTravelers.length} ${$tc('guest', orderTravelers.length)}` }}
                        </span>
                    </div>
                    <template v-if="orderTravelersWithNames.length">
                        <v-slide-y-transition class="full-width" group tag="v-row">
                            <v-col
                                v-for="(traveler, index) in orderTravelersWithNamesSliced"
                                :key="traveler.travelerId"
                                cols="12"
                                md="6"
                            >
                                <v-card elevation="0" class="full-height">
                                    <v-card-text class="full-height py-1">
                                        <v-row>
                                            <v-col cols="4" md="3" xl="2" class="text-capitalize">
                                                {{
                                                    traveler.isTourLead
                                                        ? $t('lead_guest')
                                                        : $tc('guest', 1) + ' ' + (index + 1)
                                                }}
                                            </v-col>
                                            <v-col cols="8" md="9" xl="10">
                                                <div>
                                                    {{ traveler.prefix || '' }}
                                                </div>
                                                <div class="text-h6 font-weight-bold black--text">
                                                    {{
                                                        `${traveler.name[0].firstName || ''} ${
                                                            traveler.name[0].lastName || ''
                                                        }`
                                                    }}
                                                </div>
                                                <div class="d-flex align-md-center flex-column flex-md-row">
                                                    <div v-if="traveler.email">
                                                        {{ `${traveler.email}${traveler.phone ? ',' : ''}` }}
                                                        &nbsp;
                                                    </div>
                                                    <div v-if="traveler.phone">
                                                        {{ ` ${traveler.phone}` }}
                                                    </div>
                                                </div>
                                                <div v-if="traveler.passports && traveler.passports.length">
                                                    {{ $t('documents') }}:
                                                    {{
                                                        `${traveler.passports[0].number || ''} (${
                                                            traveler.passports[0].expiryDate
                                                        })`
                                                    }}
                                                </div>
                                                <div v-if="traveler.dateOfBirth">
                                                    {{ $t('birthdate') }}:
                                                    {{ `${traveler.dateOfBirth}` }}
                                                    <span v-if="orderEndDate && showAge">
                                                        {{ `(${getAge(traveler.dateOfBirth)})` }}
                                                    </span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider v-if="orderTravelersWithNamesSliced.length > 2" />
                                </v-card>
                            </v-col>
                        </v-slide-y-transition>
                        <v-row v-if="orderTravelersWithNames.length > expandLimit">
                            <v-col>
                                <v-btn
                                    color="primary"
                                    small
                                    text
                                    style="text-transform: none"
                                    @click="expanded = !expanded"
                                >
                                    <span class="caption">
                                        {{ expanded ? $t('collapse') : $t('view_all') }}
                                    </span>
                                    <v-icon right small>
                                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <div v-else class="text-center text-caption text--secondary full-width pb-4">
                        {{ $t('no_data') }}
                    </div>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class OrderDetailsTravelerList extends Vue {
        @Prop() orderTravelers
        @Prop() orderEndDate

        expanded = false
        expandLimit = 4

        getAge(dateOfBirth) {
            return this.$dateFns.differenceInYears(new Date(this.orderEndDate), this.$dateFns.parseISO(dateOfBirth))
        }

        get showAge() {
            return false
        }

        get orderTravelersWithNames() {
            return this.orderTravelers.filter(t => t.name[0].firstName || t.name[0].lastName)
        }

        get orderTravelersWithNamesSliced() {
            return this.expanded
                ? this.orderTravelersWithNames
                : this.orderTravelersWithNames.slice(0, this.expandLimit)
        }
    }
</script>
