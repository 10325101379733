<template>
    <v-text-field
        ref="option"
        :key="key"
        :value="value"
        :name="name"
        :prefix="`${$t('age')}:`"
        :hint="$t('carsRent.from_18_to_120').toLocaleLowerCase()"
        background-color="white"
        data-cy="adults-selector"
        type="number"
        single-line
        outlined
        class="input--no-arrows"
        @input="inputAge"
    />
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'nuxt-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class AgeSelect extends Vue {
        @PropSync('_value') value
        @Prop({required: true}) name

        key = 0

        inputAge(v) {
            let value = !v ? 0 : parseInt(v.length > 3 ? v.slice(-1) : v, 10)
            if (value < 18 && String(value).length > 1) value = 18
            else if (value === 0 && String(value).length <= 1) value = 18
            else if (value > 120) value = 120
            if (this.value === value) this.key++
            this.value = value
        }

        get disabled() {
            return authStore.user.isEmployeeOrExternalCorp
        }
    }
</script>
