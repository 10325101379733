import { render, staticRenderFns } from "./SubscribeUsBlock.vue?vue&type=template&id=6ff837e0&"
import script from "./SubscribeUsBlock.vue?vue&type=script&lang=js&"
export * from "./SubscribeUsBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports