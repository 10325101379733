<template>
    <div v-if="showPrice && price && currency">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ labelText }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters class="mb-5">
                        <v-col>
                            <v-text-field
                                :value="
                                    minPriceFocused ? minPrice : $options.filters.price({amount: price[0], currency})
                                "
                                :type="minPriceFocused ? 'number' : 'text'"
                                single-line
                                hide-details
                                dense
                                background-color="white"
                                @focus="minPriceFocus"
                                @blur="setMinPrice"
                                @input="minPriceInput"
                                @change="setMinPrice"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                class="text-end-input"
                                :value="
                                    maxPriceFocused ? maxPrice : $options.filters.price({amount: price[1], currency})
                                "
                                :type="maxPriceFocused ? 'number' : 'text'"
                                single-line
                                hide-details
                                dense
                                background-color="white"
                                @focus="maxPriceFocus"
                                @blur="setMaxPrice"
                                @input="maxPriceInput"
                                @change="setMaxPrice"
                            />
                        </v-col>
                    </v-row>
                    <v-range-slider
                        v-model="price"
                        :min="min"
                        :max="max"
                        class="mt-n3"
                        :thumb-label="false"
                        :disabled="disabled"
                        @start="start"
                        @end="end"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import PriceFilter from '@/components/search/filters/PriceFilter'

    @Component
    export default class PriceFilterMod extends PriceFilter {}
</script>

<style scoped lang="scss">
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }

    ::v-deep .text-end-input.v-input input {
        text-align: right !important;
    }
</style>
