<template>
    <v-dialog v-model="dialog" :width="newSupplierMode ? null : 1200">
        <template v-slot:activator="{on}">
            <v-btn
                outlined
                class="mb-2 black--text"
                color="grey darken-1"
                block
                data-cy="add-service-button"
                :small="$breakpoint.smAndDown"
                v-on="on"
                v-text="$t('add_offline_service')"
            />
        </template>
        <v-card>
            <v-card-title>
                <div class="py-3 d-flex full-width align-center">
                    <v-btn v-if="newSupplierMode" fab small elevation="0" class="me-3" @click="newSupplierMode = false">
                        <v-icon> mdi-arrow-left</v-icon>
                    </v-btn>
                    {{ newSupplierMode ? $t('add_supplier') : $t('add_offline_service') }}
                    <v-spacer />
                    <v-btn icon class="close-btn" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <create-supplier-form v-if="newSupplierMode" @save="saveSupplier" />
                <template v-else>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" :md="2">
                                <v-select
                                    v-model="type"
                                    outlined
                                    dense
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('type')}`"
                                    hide-details
                                    :items="types"
                                />
                            </v-col>
                            <v-col cols="12" :md="2">
                                <v-select
                                    v-model="status"
                                    outlined
                                    dense
                                    item-text="name"
                                    item-value="value"
                                    :label="`${$t('status')}`"
                                    hide-details
                                    :items="statuses"
                                />
                            </v-col>
                            <v-col cols="12" :md="3">
                                <v-text-field v-model="name" dense outlined :label="nameLabel" hide-details />
                            </v-col>
                            <v-col cols="12" :md="2">
                                <v-text-field
                                    v-model="refNumber"
                                    dense
                                    outlined
                                    :label="`${$t('reference_number')}`"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" :md="3">
                                <v-autocomplete
                                    v-model="supplierId"
                                    dense
                                    :disabled="suppliersLoading"
                                    :items="suppliers"
                                    :loading="suppliersLoading"
                                    item-text="name"
                                    item-value="id"
                                    hide-no-data
                                    :label="`${$t('supplier_id')}*`"
                                    persistent-placeholder
                                    outlined
                                    background-color="white"
                                    :clearable="true"
                                />
                                <div class="full-width d-flex flex-row align-center mt-n4">
                                    <v-spacer />
                                    <span
                                        style="z-index: 1"
                                        class="primary--text cursor-pointer font-weight-bold ms-auto"
                                        @click="newSupplierMode = true"
                                    >
                                        {{ $t('add_supplier') }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="12" :md="4" class="mt-n8">
                                <daterange-m
                                    outlined
                                    dense
                                    :label="$t('modify.subtitle_dates')"
                                    class="mt-2"
                                    :start-date.sync="startDate"
                                    :end-date.sync="endDate"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-show="showTime">
                            <v-col cols="12" md="2">
                                <time-field v-model="startTime" outlined dense :label="`${$t('departure_time')}`" />
                            </v-col>
                            <v-col cols="12" md="2">
                                <time-field v-model="endTime" outlined dense :label="`${$t('arrival_time')}`" />
                            </v-col>
                        </v-row>
                        <offline-service-product-form v-model="product" :product-type="type" :tourists="tourists" />
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="description"
                                    hide-details
                                    rows="2"
                                    dense
                                    outlined
                                    :label="`${$t('description')}`"
                                />
                            </v-col>
                            <v-col v-if="showATOLSwitcher && type === 'FLIGHT'" cols="1">
                                <v-switch v-model="isATOL" hide-details inset :label="`ATOL`" />
                            </v-col>
                            <v-col v-else cols="12">
                                <v-textarea
                                    v-model="comment"
                                    dense
                                    hide-details
                                    rows="1"
                                    outlined
                                    :label="`${$t('comment')}`"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <modify-manual-sales-term select-currency :_sales-term="salesTermSupplier" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <modify-manual-sales-term select-currency :_sales-term="salesTermClient" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="d-flex align-center">
                                    <v-radio-group v-model="singleTouristMode" dense row hide-details class="ma-0 pa-0">
                                        <v-radio :label="$t('traveler_specify_primary')" :value="true" />
                                        <v-radio :label="$t('traveler_specify_all')" :value="false" />
                                    </v-radio-group>
                                    <v-btn v-if="!singleTouristMode" fab small dark color="primary" @click="addTourist">
                                        <v-icon dark> mdi-plus</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="singleTouristMode">
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="singleTourist"
                                    outlined
                                    dense
                                    :label="$t('order_travelers')"
                                    :items="touristsSelect"
                                    item-text="name"
                                    item-value="value"
                                />
                            </v-col>
                        </v-row>
                        <template v-else>
                            <v-row v-for="(tourist, i) in tourists" :key="i" class="d-flex align-center">
                                <v-col cols="12" md="2">
                                    <v-select
                                        v-model="tourist.prefix"
                                        outlined
                                        dense
                                        hide-details
                                        :items="['Mr', 'Ms', 'Mrs']"
                                        :label="`${$t('salutation')}`"
                                    />
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field
                                        v-model="tourist.firstName"
                                        outlined
                                        dense
                                        :label="`${$t('first_name')}`"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-text-field
                                        v-model="tourist.lastName"
                                        outlined
                                        dense
                                        :label="`${$t('last_name')}`"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" md="2">
                                    <date-picker
                                        v-model="tourist.birthdate"
                                        :label="$t('birthdate')"
                                        outlined
                                        dense
                                        hide-details
                                        :max="$dateFns.format(new Date())"
                                        year-active-picker
                                        :rules="[birthDateInfantRule(tourist), birthDateAdultRule(tourist)]"
                                    />
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select
                                        v-model="tourist.type"
                                        :items="touristTypes"
                                        outlined
                                        dense
                                        :label="`${$t('traveler_type')}`"
                                        hide-details
                                        @change="onTypeChange"
                                    />
                                </v-col>
                                <v-col v-if="showSeats" cols="12" md="1">
                                    <v-text-field
                                        v-if="product.seats && product.seats[i]"
                                        v-model="product.seats[i].seatNumber"
                                        outlined
                                        dense
                                        :label="`${$t('flights.seat_number')}`"
                                        hide-details
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-btn icon class="close-btn" @click="tourists.splice(i, 1)">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-form>
                </template>
            </v-card-text>
            <v-card-actions v-if="!newSupplierMode" class="justify-center">
                <div class="d-flex py-4">
                    <v-btn
                        class="mx-1"
                        depressed
                        color="primary"
                        :loading="loading"
                        :disabled="!supplierId || !valid"
                        @click="sendRequest"
                    >
                        {{ $t('save') }}
                    </v-btn>
                    <v-btn class="mx-1" depressed @click="dialog = false">
                        {{ $t('close') }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import ModifyManualTraveler from '@/components/account/modify/manual/ModifyManualTraveler'
    import ModifyManualStatus from '@/components/account/modify/manual/ModifyManualStatus'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import ModifyManualSalesTerm from '@/components/account/modify/manual/ModifyManualSalesTerm'
    import {addDays, format} from 'date-fns'
    import CreateSupplierForm from '@/components/account/offlineServices/CreateSupplierForm'
    import OfflineServiceProductForm from '@/components/account/offlineServices/OfflineServiceProductForm'
    import TimeField from '@/components/snippets/forms/TimeField'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {
            OfflineServiceProductForm,
            CreateSupplierForm,
            ModifyManualSalesTerm,
            ModifyManualStatus,
            ModifyManualTraveler,
            DaterangeM,
            TimeField,
            DatePicker,
        },
    })
    export default class AddOfflineServiceDialog extends mixins(FormValidationRulesMixin) {
        @Prop() orderTravelers
        @Prop() orderProcessId

        dialog = false
        valid = false
        newSupplierMode = false
        loading = false
        suppliers = []
        suppliersLoading = false
        singleTouristMode = false

        type = null
        status = null
        refNumber = null
        name = null
        supplierId = null

        startDate = null
        endDate = null
        description = null
        comment = null
        salesTermSupplier = {
            type: 'SUPPLIER',
            price: {amount: 0, currency: 'EUR', commission: {amount: 0}},
            cancelPenalty: [],
        }
        salesTermClient = {
            type: 'CLIENT',
            price: {amount: 0, currency: 'EUR', commission: {amount: 0}},
            cancelPenalty: [],
        }
        singleTourist = null
        tourists = []
        product = {}
        isATOL = false
        startTime = null
        endTime = null

        async mounted() {
            await this.getSuppliers()
        }

        @Watch('type')
        onProductTypeChange() {
            this.product = {}
            if (this.showSeats) {
                this.product.seats = this.tourists.map((el, i) => ({travelerRPH: i, seatNumber: ''}))
            }
        }

        @Watch('tourists', {immediate: true})
        onTouristsChange() {
            if (this.showSeats) {
                this.product.seats = this.tourists.map((el, i) => ({travelerRPH: i, seatNumber: ''}))
            }
        }

        @Watch('dialog')
        onDialogChange() {
            this.reset()
        }

        @Watch('singleTouristMode', {immediate: true})
        onTouristModeChange() {
            if (this.singleTouristMode) {
                this.singleTourist = this.touristsSelect[0]?.value
            } else {
                this.tourists = this.touristsSelect.map(e => e.value)
            }
        }

        reset() {
            this.type = this.types[0].value
            this.status = this.statuses[0].value
            this.startDate = format(new Date(), 'yyyy-MM-dd')
            this.endDate = format(addDays(new Date(), 6), 'yyyy-MM-dd')
            this.newSupplierMode = false
            this.singleTouristMode = true
        }

        async getSuppliers() {
            try {
                this.suppliersLoading = true
                this.suppliers = (await this.$api.companies.get({type: 'INTERNAL_SUPPLIER', active: true}))?.object

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.suppliersLoading = false
            }
        }

        saveSupplier() {
            this.newSupplierMode = false
            this.supplierId = null
            this.getSuppliers()
        }

        addTourist() {
            this.tourists.push({
                prefix: '',
                firstName: '',
                lastName: '',
                birthdate: '',
                type: 'ADULT',
            })
        }

        birthDateInfantRule() {
            return () => true
        }

        birthDateAdultRule() {
            return () => true
        }

        onTypeChange() {
            this.$refs.form?.validate()
        }

        @Emit()
        async sendRequest() {
            try {
                this.loading = true
                await this.$api.orderServices.post(this.requestBody)
                this.$toast.success(this.$t('success_message.saved_successfully'))
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        get touristTypes() {
            return ['ADULT', 'CHILD']
        }

        get showSeats() {
            return this.type === 'FLIGHT'
        }

        get showTime() {
            return this.type === 'FLIGHT'
        }

        get nameLabel() {
            return `${this.$t('name')}`
        }

        get showATOLSwitcher() {
            return false
        }

        get touristsSelect() {
            return this.orderTravelers.map(e => ({
                name: `${e.prefix || ''} ${e.name[0].firstName || ''} ${e.name[0].lastName || ''}`,
                value: {
                    prefix: e.prefix,
                    firstName: e.name[0].firstName,
                    lastName: e.name[0].lastName,
                    birthdate: e.dateOfBirth,
                    type: e.isChild ? 'CHILD' : 'ADULT',
                },
            }))
        }

        get types() {
            return [
                {
                    name: this.$t('serviceType.ACCOMMODATION'),
                    value: 'ACCOMMODATION',
                },
                {
                    name: this.$t('serviceType.TRANSFER'),
                    value: 'TRANSFER',
                },
                {
                    name: this.$t('serviceType.FLIGHT'),
                    value: 'FLIGHT',
                },
                {
                    name: this.$t('serviceType.EXCURSION'),
                    value: 'EXCURSION',
                },
                {
                    name: this.$t('serviceType.CARRENT'),
                    value: 'CAR_RENT',
                },
                {
                    name: this.$t('serviceType.INSURANCE'),
                    value: 'INSURANCE',
                },
                {
                    name: this.$t('serviceType.TOUR'),
                    value: 'TOUR',
                },
                {
                    name: this.$t('serviceType.OWNVISA'),
                    value: 'VISA',
                },
                {
                    name: this.$t('serviceType.TOUR'),
                    value: 'TOUR',
                },
                {
                    name: this.$t('serviceType.OTHER'),
                    value: 'OTHER',
                },
            ]
        }

        get statuses() {
            return [
                {
                    name: this.$t('bookingStatus.CONFIRMED'),
                    value: 'CONFIRMED',
                },
                {
                    name: this.$t('bookingStatus.CONFIRMATION_PENDING'),
                    value: 'CONFIRMATION_PENDING',
                },
                {
                    name: this.$t('bookingStatus.QUOTED'),
                    value: 'QUOTED',
                },
            ]
        }

        get requestBody() {
            return {
                orderProcessId: this.orderProcessId,
                productType: this.type,
                name: this.name,
                status: this.status,
                refNum: this.refNumber,
                supplierId: this.supplierId,
                startDate: this.showTime ? `${this.startDate}T${this.startTime || '00:00'}` : this.startDate,
                endDate: this.showTime ? `${this.endDate}T${this.endTime || '00:00'}` : this.endDate,
                description: this.description,
                comment:
                    this.showATOLSwitcher && this.type === 'FLIGHT' ? (this.isATOL ? 'ATOL' : 'NO ATOL') : this.comment,
                nettoPrice: {
                    ...this.salesTermSupplier.price,
                },
                bruttoPrice: {
                    ...this.salesTermClient.price,
                },
                supplierPenalties: this.salesTermSupplier.cancelPenalty.map(e => {
                    return {
                        dateFrom: e.startDateTime,
                        dateTo: e.startDateTime,
                        price: {
                            currency: this.salesTermSupplier.price.currency,
                            amount: e.amount,
                        },
                    }
                }),
                clientPenalties: this.salesTermSupplier.cancelPenalty.map(e => {
                    return {
                        dateFrom: e.startDateTime,
                        dateTo: e.startDateTime,
                        price: {
                            currency: this.salesTermSupplier.price.currency,
                            amount: e.amount,
                        },
                    }
                }),
                tourists: this.singleTouristMode ? [this.singleTourist] : this.tourists,
                product: this.product,
            }
        }
    }
</script>

<style scoped lang="scss"></style>
