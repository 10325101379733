<template>
    <div>
        <v-carousel :show-arrows="images.length > 1" hide-delimiters height="auto">
            <v-carousel-item v-for="(image, index) in images" :key="index">
                <v-img
                    v-if="image && image.url"
                    :src="image.url | imageUrl(600)"
                    :title="image.title"
                    :lazy-src="'/placeholder.png' | srcHost"
                    :aspect-ratio="aspectRatio"
                    class="grey lighten-2"
                    @click="showPhotoSwipe(index)"
                    @error="onImgError(index)"
                >
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError[index]" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-carousel-item>
        </v-carousel>
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ProductGallery from '@/components/info/ProductGallery'

    @Component
    export default class ProductGalleryMobile extends ProductGallery {
        @Prop({default: 1}) aspectRatio

        imgError = []

        onImgError(index) {
            this.$set(this.imgError, index, true)
            return false
        }
    }
</script>
