<template>
    <div v-if="serviceGroup.length || services.length" class="d-flex flex-column">
        <v-row v-if="serviceGroup.length" dense>
            <v-col v-for="group in serviceGroup" :key="group.id" cols="6" sm="3" class="text-capitalize mb-5">
                <div class="font-weight-bold">{{ group.name }}</div>
                <div
                    v-for="(service, index) in group.services.service.filter(({active}) => active)"
                    :key="`${group.id}_${index}`"
                >
                    <div class="d-flex align-start">
                        <v-icon color="primary" left> mdi-check </v-icon>
                        <hotel-info-services-label :service="service" />
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-else-if="services.length" dense>
            <v-col v-for="(service, index) in services" :key="index" cols="6" sm="3" class="text-capitalize">
                <div class="d-flex align-start">
                    <v-icon color="primary" left> mdi-check </v-icon>
                    <hotel-info-services-label :service="service" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelInfoServices from '~/components/info/HotelInfoServices'

    @Component
    export default class HotelInfoServicesMod extends HotelInfoServices {}
</script>
