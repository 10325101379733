var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.templates,"loading":_vm.loading,"disable-sort":"","search":_vm.recipientType,"custom-filter":_vm.recipientTypeFilter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-h6 pl-3"},[_vm._v("\n            "+_vm._s(_vm.$t('admin.email_templates'))+"\n        ")]),_vm._v(" "),(_vm.recipientTypes.length)?_c('v-select',{staticClass:"pl-3 filter-input",attrs:{"items":_vm.recipientTypes,"label":_vm.$t('admin.recipient_type')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.$t(("admin.recipientType." + item)))+"\n            ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.$t(("admin.recipientType." + item)))+"\n            ")]}}],null,false,369711132),model:{value:(_vm.recipientType),callback:function ($$v) {_vm.recipientType=$$v},expression:"recipientType"}}):_vm._e()]},proxy:true},{key:"item.notificationType",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.$t(("admin.notificationType." + (item.notificationType))))+"\n    ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"nuxt":"","to":{
                name: 'emailEditor',
                params: {id: item.id},
            },"icon":"","small":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }