<template>
    <div class="d-flex flex-column">
        <v-tabs v-if="isMobile" v-model="mobileTab" fixed-tabs class="sticky-bar mb-6">
            <v-tab v-for="(tab, i) in defaultTabs" :key="tab" :disabled="isTabDisabled(i)">
                <v-icon>{{ tab }}</v-icon>
            </v-tab>
        </v-tabs>
        <v-row>
            <v-col v-show="!isMobile || isTabMobileActiveFirst" :cols="12" :md="6">
                <events-calendar v-model="groups" class="mx-auto" />
                <groups-list-table
                    v-model="selectedGroup"
                    class="mt-6"
                    :groups="groups"
                    :details-loading="detailsLoading"
                />
            </v-col>
            <v-col v-show="!isMobile || isTabMobileActiveSecond" :cols="12" :md="6">
                <v-card v-show="selectedGroupDetails" outlined>
                    <v-card-title>
                        <group-details-info :selected-group-details="selectedGroupDetails" />
                    </v-card-title>
                    <v-card-text>
                        <group-details-table
                            v-model="selectedGroupDetails"
                            :selected-group="selectedGroup"
                            :city="city"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Vue, Watch} from 'nuxt-property-decorator'
    import EventsCalendar from '@/components/scheduler/EventsCalendar'
    import GroupsListTable from '@/components/scheduler/GroupsListTable'
    import GroupDetailsInfo from '@/components/scheduler/GroupDetailsInfo'
    import GroupDetailsTable from '@/components/scheduler/GroupDetailsTable'

    @Component({
        components: {GroupDetailsTable, GroupDetailsInfo, GroupsListTable, EventsCalendar},
        middleware: 'auth',
    })
    export default class SchedulerPage extends Vue {
        groups = null
        selectedGroup = null
        selectedGroupDetails = null
        detailsLoading = false
        city = false

        mobileTab = 0
        defaultTabs = ['mdi-calendar', 'mdi-account-details']

        @Watch('selectedGroup')
        async onSelectedGroupChange() {
            await this.loadDetails()
        }

        @Watch('selectedGroupDetails')
        detailsLoaded() {
            if (this.selectedGroupDetails) {
                this.mobileTab = 1

                this.$nextTick(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                })
            }
        }

        async loadDetails() {
            try {
                this.selectedGroupDetails = null
                this.detailsLoading = true
                this.selectedGuests = []

                let driver
                let guide

                const rs = await this.$api.schedulerGroupDetails.get({
                    groupId: this.selectedGroup.groupId,
                })

                await Promise.all([
                    (async () => {
                        this.city = (
                            await this.$api.locations.get({pattern: this.selectedGroup.city, limitCities: 1})
                        ).cities[0]
                    })(),
                    (async () => {
                        driver = rs?.groupDetails.driverId
                            ? (await this.$api.persons.get({id: rs.groupDetails.driverId})).users[0]
                            : null
                    })(),
                    (async () => {
                        guide = rs?.groupDetails.guideId
                            ? (await this.$api.persons.get({id: rs.groupDetails.guideId})).users[0]
                            : null
                    })(),
                ])

                this.selectedGroupDetails = {
                    ...rs.groupDetails,
                    guide,
                    driver,
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.detailsLoading = false
            }
        }

        isTabDisabled(index) {
            return index === 1 && !this.selectedGroupDetails
        }

        get isTabMobileActiveFirst() {
            return this.isMobile && this.mobileTab === 0
        }

        get isTabMobileActiveSecond() {
            return this.isMobile && this.mobileTab === 1
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
