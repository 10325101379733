<template>
    <div>
        <breadcrumbs />
        <user-settings />
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import UserSettings from '~src/components/account/userSettings.src'

    @Component({
        components: {
            UserSettings,
            Breadcrumbs,
        },
    })
    export default class SettingsPage extends Vue {}
</script>
