<template>
    <v-form ref="form" v-model="valid" :disabled="disabled">
        <v-row>
            <v-col v-if="!isProductFieldDisabled('PICK_UP_TIME')" cols="5" sm="3" md="3" lg="2">
                <time-field
                    v-if="!offer.time.length"
                    v-model="time"
                    :label="productFieldLabel('transfers.pickup_time', 'PICK_UP_TIME')"
                    :rules="timeRules"
                />
                <v-select
                    v-else
                    v-model="time"
                    :items="offer.time"
                    :rules="timeRules"
                    background-color="white"
                    prepend-icon="mdi-clock"
                    :label="productFieldLabel('transfers.pickup_time', 'PICK_UP_TIME')"
                />
            </v-col>
            <v-col v-if="!hidePickupSine && !isProductFieldDisabled('PICK_UP_SIGN')" cols="7" sm="9" md="6" xl="5">
                <v-text-field
                    v-model="pickupSign"
                    :rules="pickupSignRules"
                    :label="productFieldLabel('transfers.pickup_sign', 'PICK_UP_SIGN')"
                />
            </v-col>
        </v-row>
        <div v-if="groupTransfer && !offer.time.length" class="text-caption font-italic font-weight-medium">
            {{ $t('transfers.pickup_time_notification') }}
        </div>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({
        components: {TimeField},
    })
    export default class TransferDetailsForm extends mixins(
        TransferFormMixin,
        ProductBookingFieldsMixin,
        FormValidationRulesMixin
    ) {
        @Prop({required: true}) offer
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) hidePickupSine
        @PropSync('_valid', {default: false}) valid

        mounted() {
            if (this.offer.time.length === 1) {
                this.time = this.offer.time[0]
            }
            if (this.$config.booking.transferPickupSign && !this.pickupSign) {
                this.pickupSign = this.$config.booking.transferPickupSign
            }
        }

        getProp(prop) {
            return this.request[prop]
        }

        setProp(prop, val) {
            this.change({prop, val})
        }

        get pickupSignRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_SIGN')) {
                rules.push(this.requiredRule('transfers.pickup_sign', 'PICK_UP_SIGN'))
            }
            return rules
        }

        get time() {
            return this.getProp('time')
        }

        set time(val) {
            this.setProp('time', val)
        }

        get pickupSign() {
            return this.getProp('pickupSign')
        }

        set pickupSign(val) {
            this.setProp('pickupSign', val)
        }

        get groupTransfer() {
            return this.offer.transferType === 'GROUP'
        }

        get timeRules() {
            const rules = []

            if (this.isProductFieldMandatory('PICK_UP_TIME')) {
                rules.push(this.requiredRule('transfers.pickup_time', 'PICK_UP_TIME'))
            }

            return rules
        }
    }
</script>
