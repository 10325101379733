<template>
    <v-dialog v-model="modal" hide-overlay width="100%" max-width="1000">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-if="loaded" justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.issue_invoices_title') }} {{ requestInfo.orderId }}
                </v-card-title>
            </div>
            <v-card-subtitle
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
                class="mt-2 mb-0 py-0"
            >
                {{ $t('invoices.issue_invoice_subtitle') }}
            </v-card-subtitle>
            <v-card-text
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
            >
                <v-list-item
                    v-for="transaction in issueInvoicesInfo.transactions"
                    :key="transaction.serviceId"
                    class="pl-0"
                >
                    <v-checkbox v-model="serviceIds" :value="transaction.serviceId" hide-details>
                        <template v-slot:label>
                            <v-list-item-content>
                                <div>{{ transaction.description }}</div>
                                <v-list-item-subtitle>
                                    <strong :class="statusColor(transaction.status)">
                                        {{ $t(`bookingStatus.${status(transaction.status)}`) }}
                                    </strong>
                                    <strong class="ml-2">{{
                                        transaction.amount | priceFormat(transaction.currency)
                                    }}</strong>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-checkbox>
                </v-list-item>
            </v-card-text>
            <v-card-actions
                v-if="issueInvoicesInfo.requestInvoiceAllowed !== false && issueInvoicesInfo.transactions.length"
                class="justify-end pa-6"
            >
                <v-btn
                    class="black--text mr-4"
                    color="white"
                    :disabled="issueInvoiceLoading"
                    @click="cancelIssueInvoices"
                >
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    class="white--text"
                    color="primary"
                    :disabled="!serviceIds.length"
                    :loading="issueInvoiceLoading"
                    @click="issueInvoices"
                >
                    {{ $t('invoices.generate_invoice') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, ISSUE_INVOICES_MODAL_EVENT, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import {invoiceIssueInfo, issueInvoice} from '~/api'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component({})
    export default class InvoicesIssueModal extends Vue {
        issueInvoicesInfo = {
            requestInvoiceAllowed: false,
            transactions: [],
            wholeOrderInvoice: false,
        }
        modal = false
        loaded = false
        requestInfo = {}
        invoiceType = null
        serviceIds = []
        issueInvoiceLoading = false

        created() {
            EventBus.$on(ISSUE_INVOICES_MODAL_EVENT, this.showIssueInvoiceModal)
        }

        beforeDestroy() {
            EventBus.$off(ISSUE_INVOICES_MODAL_EVENT, this.showIssueInvoiceModal)
        }

        async showIssueInvoiceModal(rq) {
            this.loaded = false
            this.modal = true
            this.requestInfo = rq
            this.serviceIds = []
            this.invoiceType = rq.invoiceType
            await this.invoiceIssueInfo()

            this.loaded = true
        }

        async invoiceIssueInfo() {
            try {
                this.issueInvoicesInfo = await invoiceIssueInfo.get({
                    orderId: this.requestInfo.objectId,
                    invoiceType: this.invoiceType,
                })
            } catch (error) {
                console.error(error.response)
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data.errors[0].message
                    this.snackbar.show = true
                }
            }
        }

        async issueInvoices() {
            this.issueInvoiceLoading = true
            try {
                await issueInvoice.post({
                    serviceIds: this.serviceIds,
                    invoiceType: this.invoiceType,
                })
                this.modal = false
                this.$emit('issuedInvoice', this.invoiceType)
            } catch (error) {
                console.error(error.response)
                if (this.snackbar) {
                    this.snackbar.message = error.response?.data.errors[0].message
                    this.snackbar.show = true
                }
            }
            this.issueInvoiceLoading = false
        }

        cancelIssueInvoices() {
            this.modal = false
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: this.requestInfo.orderId,
                objectId: this.requestInfo.objectId,
                invoiceType: this.invoiceType,
                order: this.requestInfo.order,
            })
        }

        statusColor(status) {
            return runtimeStore.orderServiceStatusColor(runtimeStore.orderServiceStatus(status))
        }

        status(status) {
            return runtimeStore.orderServiceStatus(status)
        }
    }
</script>
