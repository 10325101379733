<template>
    <div>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`dates`) }} </span>
        <v-row class="mt-1">
            <v-col :md="3">
                <date-picker v-model="startDate" outlined dense :label="`${$t('transfers.departure_date')}`" />
            </v-col>
            <v-col :md="2">
                <time-field v-model="startTime" outlined dense :label="`${$t('transfers.pickup_time')}`" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField, DatePicker},
    })
    export default class ModifyManualActivity extends Vue {
        @VModel() infoByServiceType

        get startDate() {
            return this.infoByServiceType.activity.startDateTime.split('T')[0]
        }

        set startDate(date) {
            this.infoByServiceType.activity.startDateTime = `${date}T${
                this.infoByServiceType.activity.startDateTime.split('T')[1]
            }`
        }

        get startTime() {
            return this.infoByServiceType.activity.startDateTime.split('T')[1]
        }

        set startTime(time) {
            this.infoByServiceType.activity.startDateTime = `${
                this.infoByServiceType.activity.startDateTime.split('T')[0]
            }T${time}`
        }
    }
</script>
