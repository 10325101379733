var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"header",staticClass:"header"},[_c('div',[_c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"header",attrs:{"dense":"","flat":"","height":"90","dark":_vm.dark}},[_c('div',{staticClass:"full-width d-flex align-center justify-center"},[_c('div',{staticClass:"d-flex align-center justify-center",style:({width: _vm.isMobile ? '100vw' : 'calc(80% + 64px)'})},[(_vm.$breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{staticStyle:{"color":"#62b7e5"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('header-block',{style:(_vm.$vuetify.rtl ? 'margin-left: 80px' : 'margin-right: 80px')}):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('footer-menu'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-grow-1"}),_vm._v(" "),_c('client-only',[(_vm.$breakpoint.mdAndUp)?_c('div',{staticClass:"d-md-flex d-lg-flex align-center"},[(
                                    !['authorization', 'b2b-auth', 'restore-password', 'hotelDirectory'].includes(
                                        _vm.$route.name
                                    ) ||
                                    (_vm.$route.name === 'hotelDirectory' && _vm.person)
                                )?_c('currency-switcher'):_vm._e(),_vm._v(" "),_c('language-switcher',{staticClass:"ma-0"}),_vm._v(" "),(
                                    !['authorization', 'b2b-auth', 'restore-password', 'hotelDirectory'].includes(
                                        _vm.$route.name
                                    ) ||
                                    (_vm.$route.name === 'hotelDirectory' && _vm.person)
                                )?_c('account-menu'):_vm._e()],1):_vm._e()]),_vm._v(" "),(_vm.isMobile)?_c('header-block'):_vm._e()],1)])])],1),_vm._v(" "),_c('portal-target',{attrs:{"name":"under-header"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }