<template>
    <div class="d-flex px-2 rounded-lg" :style="{'min-height': isMobile ? '340px' : '272px', background: '#F5F8F8'}">
        <div class="full-width" style="position: relative">
            <v-img
                :width="isMobile ? '35%' : '15%'"
                contain
                src="/subscribe-bg-1.png"
                style="position: absolute; bottom: 0"
            />
            <v-img
                contain
                :width="isMobile ? '45%' : '25%'"
                src="/subscribe-bg-2.png"
                style="position: absolute; bottom: 0; right: 0"
            />
            <div class="d-flex flex-column justify-center align-center full-height">
                <v-form
                    v-model="valid"
                    action="https://ajbooking.us21.list-manage.com/subscribe/post?u=68e1ffea5e0abb69844aa57c9&amp;id=2b41945763&amp;f_id=0046aae1f0%22"
                    method="post"
                    name="mc-embedded-subscribe-form"
                    target="_blank"
                    novalidate
                >
                    <div
                        class="d-flex flex-column text-h4 font-weight-bold"
                        :class="{'text-h5': isMobile, 'align-center': isMobile}"
                    >
                        <span style="z-index: 1">
                            <span class="primary--text"> {{ $t('subscribe_newsletter') }} </span>
                            <span> & {{ $t('get_latest_news') }} </span>
                        </span>
                        <v-row class="full-width my-md-4 my-0" justify="center">
                            <v-col cols="12">
                                <div class="d-flex flex-column flex-md-row align-center full-width">
                                    <v-text-field
                                        :dense="!isMobile"
                                        class="flex-grow-1 text-subtitle-2 font-weight-regular mb-4 mb-md-0 pa-1 rounded-sm mt-0"
                                        hide-details
                                        style="background: white"
                                        background-color="white"
                                        :class="{'full-width': isMobile}"
                                        :placeholder="$t('enter_email')"
                                        type="email"
                                        name="EMAIL"
                                        :rules="validationRules('email', [emailRule, requiredRule])"
                                    />
                                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                    <div style="position: absolute; left: -5000px" aria-hidden="true">
                                        <input
                                            type="text"
                                            name="b_0c1ac0c4e178ae7f98885d924_65d53e82a0"
                                            tabindex="-1"
                                            value=""
                                        />
                                    </div>
                                    <v-btn
                                        type="submit"
                                        value="Subscribe"
                                        name="subscribe"
                                        :disabled="!valid"
                                        class="px-8 ms-4"
                                        :class="{'mt-4': isMobile}"
                                        :large="isMobile"
                                        elevation="0"
                                        color="primary text-subtitle-2 font-weight-regular font-weight-regular px-8 ms-4"
                                    >
                                        {{ $t('subscribe') }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class SubscribeUsBlock extends mixins(FormValidationRulesMixin) {
        valid = false

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
