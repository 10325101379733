<template>
    <div class="text-center">
        <hotel-price-note
            v-if="selectedOfferKeys.length === searchRoomsCount"
            :search-request="searchRequest"
            :rooms-count="selectedOfferKeys.length"
            class="text-center"
        />
        <v-btn
            :disabled="selectedOfferKeys.length < searchRoomsCount"
            depressed
            class="primary"
            min-width="250"
            @click="selectForBooking"
        >
            {{ showPrice && sumPrice.amount ? `${btnText}  ${$options.filters.price(sumPrice)}` : btnText }}
        </v-btn>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsStore, persistentStore} from '@/store'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'

    @Component({
        components: {
            HotelPriceNote,
        },
    })
    export default class HotelMultipleRoomSelectBlock extends Vue {
        @Prop({required: true}) selectedOfferKeys
        @Prop({required: true}) searchRequest
        @Prop({required: true}) offer

        selectForBooking() {
            const offerKey = this.selectedOfferKeys
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }

        get sumPrice() {
            const sumPrice = {amount: 0, currency: persistentStore.getCurrency(this.offer.rooms[0]?.price.currency)}
            if (!this.selectedOfferKeys) return sumPrice
            return this.offer.rooms.reduce((sumPrice, room) => {
                if (room.groupedOffers.findIndex(({offerKey}) => this.selectedOfferKeys.includes(offerKey)) !== -1) {
                    sumPrice.amount += this.$options.filters.convertPrice(room.price).amount
                }
                return sumPrice
            }, sumPrice)
        }

        get showPrice() {
            return true
        }

        get btnText() {
            return this.$t('book')
        }
    }
</script>
