<template>
    <v-footer v-show="visible" v-intersect="onIntersect" app>
        <social-icons v-if="socialLinks.length" />
        <footer-menu
            v-if="
                footerMenu.length &&
                $breakpoint.mdAndUp &&
                !['authorization', 'b2b-auth', 'restore-password'].includes($route.name)
            "
        />
        <div class="ml-auto caption d-flex align-center">
            <div v-if="mailChimpLink" class="mx-8">
                <mail-chimp-form :mail-chimp-link="mailChimpLink" />
            </div>
            <span v-html="$t('copyright')" /> <span v-if="!hideAppVersion">v{{ version }}</span>
        </div>
    </v-footer>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import SocialIcons from '~src/components/snippets/socialIcons.src'
    import FooterMenu from '~src/components/snippets/footerMenu.src'
    import {EventBus, FOOTER_INTERSECT_EVENT, FULL_SCREEN_MODE} from '@/utils/event-bus'
    import {cmsStore} from '@/utils/store-accessor'
    import MailChimpForm from '@/components/MailChimpForm'

    const pjson = require('~~/package.json')

    @Component({
        components: {
            MailChimpForm,
            SocialIcons,
            FooterMenu,
        },
    })
    export default class FooterBar extends Vue {
        visible = true

        created() {
            EventBus.$on(FULL_SCREEN_MODE, this.toggle)
        }

        beforeDestroy() {
            EventBus.$off(FULL_SCREEN_MODE, this.toggle)
        }

        toggle(fullScreenMode) {
            this.visible = !fullScreenMode
        }

        onIntersect(entries, observer, isIntersecting) {
            EventBus.$emit(FOOTER_INTERSECT_EVENT, isIntersecting)
        }

        get version() {
            return pjson.version
        }

        get mailChimpLink() {
            return this.$config.mailChimpLink
        }

        get socialLinks() {
            return cmsStore.layoutSettings.socialLinks
        }

        get footerMenu() {
            return cmsStore.layoutSettings.footerMenu
        }

        get hideAppVersion() {
            return this.$config.hideAppVersion
        }
    }
</script>

<style scoped>
    footer {
        position: relative;
    }

    @media (min-width: 991px) {
        footer {
            height: 100px;
        }
    }
</style>
