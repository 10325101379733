<template>
    <v-chip
        v-if="offer.type && offer.type.toLowerCase() !== 'other'"
        color="blue-grey lighten-5"
        :x-small="xSmall"
        :small="small"
        label
        v-text="offer.type"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelTypeLabel extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true, type: Boolean}) xSmall
        @Prop({default: false, type: Boolean}) small
    }
</script>
