<template>
    <v-row v-if="newClient">
        <v-col :cols="6">
            <v-text-field
                v-model="newClient.firstName"
                :label="`${$t('first_name')}*`"
                persistent-placeholder
                :rules="validationRules('first_name', [requiredRule])"
                outlined
            />
        </v-col>
        <v-col :cols="6">
            <v-text-field
                v-model="newClient.lastName"
                :label="`${$t('last_name')}*`"
                persistent-placeholder
                :rules="validationRules('last_name', [requiredRule])"
                outlined
            />
        </v-col>
        <v-col :cols="6">
            <phone-field v-model="newClient.contactPhone" :label="$t('phone')" persistent-placeholder outlined />
        </v-col>
        <v-col :cols="6">
            <v-text-field
                v-model="newClient.email"
                :label="`${$t('email')}*`"
                persistent-placeholder
                type="email"
                outlined
                :rules="validationRules('email', [emailRule, requiredRule])"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {Component, VModel} from 'nuxt-property-decorator'
    import ClientAutocomplete from '@/components/account/modals/newOrderRequest/ClientAutocomplete'
    import {Mixins} from 'vue-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import PhoneField from '~src/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {PhoneField, ClientAutocomplete},
    })
    export default class CreateNewClient extends Mixins(FormValidationRulesMixin) {
        @VModel() newClient
    }
</script>
