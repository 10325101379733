<template>
    <div class="d-flex flex-column align-center">
        <breadcrumbs class="mb-8" :style="{width: isMobile ? '100%' : '80%'}" />
        <div class="d-flex align-center justify-center full-width mb-4" :style="{width: isMobile ? '100%' : '80%'}">
            <v-btn
                x-large
                nuxt
                :to="'/settings'"
                text
                :rounded="false"
                class="v-btn--router footer-link me-4 py-8 px-4"
            >
                <span class="text-h6">
                    {{ $t('my_profile') }}
                </span>
            </v-btn>
            <v-divider vertical class="mx-4 mb-2" inset />
            <v-btn x-large nuxt :to="'/orders'" text :rounded="false" class="v-btn--router footer-link me-4 py-8 px-4">
                <span class="text-h6">
                    {{ $t('orders') }}
                </span>
            </v-btn>
        </div>
        <div>
            <v-row>
                <v-col cols="12">
                    <orders
                        ref="orders"
                        v-model="selectedOrders"
                        :is-expanded-all="!expandedAllButtonGroup"
                        :selectable="selectable"
                        :table-columns="tableColumns"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import OrdersPage from '~/pages/account/OrdersPage'

    @Component
    export default class OrdersPageMod extends OrdersPage {
        get tableColumns() {
            const tableColumns = [
                {text: this.$t('statement_of_account.refNumber'), value: 'id', show: 'id'},
                {text: this.$t('created'), value: 'created', show: 'created'},
                {text: this.$t('modified'), value: 'modified', show: 'modified'},
            ]
            tableColumns.push(
                this.isShowOrderStatusByServicesTA
                    ? {text: this.$t('status_by_services'), value: 'statusByServices', show: 'statusByServices'}
                    : {text: this.$t('status'), value: 'status', show: 'status'}
            )
            tableColumns.push({text: this.$t('order_service'), value: 'title', show: 'title'})
            tableColumns.push({text: this.$t('dates'), value: 'date', show: 'date', align: 'center'})
            tableColumns.push({text: this.$t('order_travelers'), value: 'travellers', show: 'travellers'})
            if ((this.isTourOperator || this.isAgency) && this.commissionAndNettoCheckbox) {
                tableColumns.push({
                    text: this.$t(this.isAgency ? 'price' : 'netto'),
                    value: 'supplierPrice',
                    show: 'supplierPrice',
                    align: 'right',
                })
            }
            if (!this.isAgency) {
                tableColumns.push({
                    text: this.$t(this.isTourOperator ? 'brutto' : 'price'),
                    value: 'price',
                    show: 'price',
                    align: 'right',
                })
            }
            tableColumns.push({text: this.$t('voucher'), value: 'voucher', show: 'voucher', align: 'center'})
            if (this.hiddenColumns?.[0]?.columns.length) {
                this.hiddenColumns[0].columns.forEach(item => {
                    tableColumns.find(el => el.value === item).show = null
                })
            }

            return tableColumns
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    ::v-deep {
        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 7px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }
    }
</style>
