<template>
    <search-summary-layout
        :search-disabled="searchDisabled"
        :form-name="searchFormComponent"
        :search-request="searchRequest"
        tourists-label="guest"
        show-dates
        show-nights
        show-tourists
        @refresh="$emit('refresh')"
    >
        <template v-slot:title>
            {{ locationLabel }}
        </template>
    </search-summary-layout>
</template>

<script>
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import HotelSearchForm from '~/components/search/forms/HotelSearchForm'
    import SearchSummaryLayout from '~src/components/parts/searchSummaryLayout.src'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            HotelSearchForm,
            SearchSummaryLayout,
        },
    })
    export default class HotelSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest

        searchForm = false

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        get searchFormComponent() {
            return 'hotel-search-form'
        }

        get isSearchActive() {
            return hotelsRuntimeStore.searchActive
        }

        get locationLabel() {
            return `${hotelsRuntimeStore.city.name}, ${hotelsRuntimeStore.city.countryName}`
        }
    }
</script>
