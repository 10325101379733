<template>
    <div>
        <breadcrumbs class="mb-3" />
        <v-btn
            nuxt
            :to="{name: 'companyPerson', params: {id: 0}, query: {company: $route.query.company}}"
            class="primary my-4"
        >
            {{ $t('new_user_profile') }}
        </v-btn>
        <persons-table />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import PersonsTable from '@/components/account/PersonsTable'

    @Component({
        components: {
            Breadcrumbs,
            PersonsTable,
        },
        middleware: 'auth',
    })
    export default class CompanyPersonsPage extends Vue {}
</script>
