<template>
    <v-card outlined class="d-flex flex-column tourist-form px-4 py-8" :rounded="'lg'">
        <slot name="label">
            <div v-if="!hideIndex" class="text-h6 text-capitalize font-weight-bold">
                {{ `${$tc(label, 1)} ${index + 1} ${isChild ? `(${$tc('child')})` : ''}` }}
            </div>
        </slot>
        <v-row v-if="showTouristSelector" class="mb-1">
            <v-col cols="12" md="6">
                <tourist-selector
                    :tourist="tourist"
                    :selected-tourists="selectedTourists"
                    :required="corporatePolicyViolation"
                    :disabled="disabled"
                    @selected="personSelected"
                />
            </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" data-cy="booking-form" :disabled="isDisabled">
            <v-row
                v-if="
                    !showTouristSelector || !corporatePolicyViolation || (corporatePolicyViolation && tourist.personId)
                "
            >
                <v-col v-if="!isFieldDisabled('PREFIX')" cols="12" md="3">
                    <v-select
                        v-model="tourist.prefix"
                        :items="['Mr', 'Ms', 'Mrs']"
                        :label="fieldLabel('salutation', 'PREFIX')"
                        :rules="[requiredRule('salutation', 'PREFIX')]"
                        :append-icon="'mdi-chevron-down'"
                        data-cy="tourist-field-prefix"
                        hide-details
                        filled
                    />
                </v-col>
                <v-col :cols="12" :md="3">
                    <v-text-field
                        v-model="tourist.firstName"
                        :label="fieldLabel('first_name')"
                        :rules="validationRules('first_name', [latinRule, requiredRule])"
                        :disabled="disablePrivateClientName"
                        data-cy="tourist-field-first-name"
                        hide-details
                        filled
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="tourist.lastName"
                        :label="fieldLabel('last_name')"
                        :rules="validationRules('last_name', [latinRule, requiredRule])"
                        :disabled="disablePrivateClientName"
                        data-cy="tourist-field-last-name"
                        hide-details
                        filled
                    />
                </v-col>
                <v-col v-if="!isFieldDisabled('BIRTHDATE')" cols="12" md="3">
                    <date-picker
                        v-model="tourist.birthdate"
                        :label="fieldLabel('birthdate', 'BIRTHDATE')"
                        :rules="[requiredRule('birthdate', 'BIRTHDATE')]"
                        :min="minBirthDate"
                        :max="maxBirthDate"
                        year-active-picker
                        hide-details
                        filled
                    />
                </v-col>
                <v-col v-if="!disableCitizenship && !isFieldDisabled('CITIZENSHIP')" cols="12" md="3">
                    <v-autocomplete
                        v-model="tourist.citizenshipId"
                        :label="fieldLabel('citizenship', 'CITIZENSHIP')"
                        :items="countries"
                        item-text="name"
                        :append-icon="'mdi-chevron-down'"
                        item-value="id"
                        :rules="[requiredRule('citizenship', 'CITIZENSHIP')]"
                        hide-details
                        filled
                        data-cy="tourist-field-citizenship"
                    />
                </v-col>
                <slot name="beforeContacts" />
                <v-col v-if="!isFieldDisabled('EMAIL') && !hideContactData" cols="12" md="3">
                    <v-text-field
                        v-model="tourist.email"
                        :label="fieldLabel('email', 'EMAIL')"
                        type="email"
                        filled
                        :rules="emailRules"
                        hide-details
                        data-cy="tourist-field-email"
                    />
                </v-col>
                <v-col v-if="!isFieldDisabled('PHONE') && !hideContactData" cols="12" md="3">
                    <phone-field
                        v-model="tourist.phone"
                        :disabled="hideContactData"
                        :label="$t('phone')"
                        data-cy="tourist-field-phone"
                        :required="isFieldMandatory('PHONE')"
                        :validate-on-blur="validateOnSubmit"
                        @blur="validateOnSubmit ? $refs.form.resetValidation() : null"
                    />
                </v-col>
                <slot :tourist="tourist" :required-rule="requiredRule" :field-label="fieldLabel" />
            </v-row>
        </v-form>
    </v-card>
</template>

<script>
    import TouristForm from '~/components/booking/forms/TouristForm'
    import {Component} from 'nuxt-property-decorator'

    @Component
    export default class TouristFormMod extends TouristForm {}
</script>
