<template>
    <v-row no-gutters class="pb-3">
        <v-col :cols="searchActive && !hideStopBtn ? ($breakpoint.smAndDown ? 10 : 11) : 12">
            <div v-if="!isMobile" class="d-flex full-width align-center">
                <span class="text-h5 font-weight-medium">
                    <span v-if="city"> {{ city.name }}: </span>
                    {{ progressLabel }}
                </span>
                <div class="flex-grow-1" />
                <slot />
            </div>
            <div v-else class="d-flex full-width align-center flex-md-row flex-column">
                <slot v-if="isMobile" />
                <div class="text-h5 full-width font-weight-medium text-start d-flex align-center">
                    <span v-if="city"> {{ city.name }}:&nbsp;</span>
                    {{ progressLabel }}
                </div>
            </div>
        </v-col>
        <v-col v-if="searchActive && !hideStopBtn && !isMobile" cols="2" sm="1" class="ps-3">
            <div class="d-flex align-center full-height">
                <v-btn depressed block class="primary" data-cy="stop-search-button" @click="stop">
                    {{ $t('stop') }}
                </v-btn>
            </div>
        </v-col>
        <v-col v-if="searchActive" cols="12">
            <div class="d-flex full-width align-center">
                <v-progress-linear
                    :value="progress"
                    color="primary"
                    class="my-4"
                    :height="4"
                    :indeterminate="searchActive"
                />
                <v-btn
                    v-if="isMobile"
                    depressed
                    x-small
                    class="primary ms-2"
                    data-cy="stop-search-button"
                    @click="stop"
                >
                    {{ $t('stop') }}
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import SearchProgress from '@/components/search/SearchProgress'

    @Component
    export default class SearchProgressMod extends SearchProgress {
        @Prop() city

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
