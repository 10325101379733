<template>
    <v-card class="px-5 align-center justify-center d-flex swiper-card" hover>
        <v-card-text>
            <v-img :src="item.img" class="rounded-lg" :aspect-ratio="530 / 150" />
            <div class="d-flex flex-column">
                <div class="full-width justify-space-between d-flex align-center">
                    <span class="black--text font-weight-medium text-h6 my-2">
                        {{ item.title }}
                    </span>
                    <div class="flex-grow-1" />
                    <span class="text--secondary font-weight-light">{{
                        item.articleDate || item.createdAt | dateShortFormat
                    }}</span>
                </div>
                <span class="black--text text-subtitle-2 font-weight-light mb-2">
                    {{ item.category }}
                </span>
                <v-btn style="width: max-content" text small class="ps-0" :href="item.url" target="_blank" :link="true">
                    <span class="primary--text font-weight-light text-subtitle-2 font-weight-light">
                        {{ $t('read_more') }}
                    </span>
                    <v-icon class="ms-2" small color="primary">mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import BlogItem from '@/components/parts/BlogItem'

    @Component
    export default class BlogItemMod extends BlogItem {
        @Prop() item
    }
</script>
