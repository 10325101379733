<template>
    <v-row>
        <v-col v-if="loading" cols="12" class="d-flex align-center justify-center">
            <v-progress-circular indeterminate color="grey" />
        </v-col>
        <v-col v-for="(item, i) in itemsToShow" :key="i" cols="12" md="6">
            <blog-item :item="item" />
        </v-col>
        <v-col v-if="itemsShown && itemsShown < blogItems.length" cols="12" class="d-flex align-center justify-center">
            <v-btn class="px-4" elevation="0" color="primary" @click="loadMore"> Load more </v-btn>
        </v-col>
        <subscribe-us-block class="full-width" style="margin: 50px 0 60px" />
    </v-row>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import BlogItem from '~src/components/parts/blogItem.src'
    import {cmsStore} from '@/utils/store-accessor'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import SubscribeUsBlock from '~~/mods/ajbooking/components/parts/SubscribeUsBlock'

    @Component({
        components: {SubscribeUsBlock, BlogItem},
    })
    export default class NewsList extends Vue {
        itemsShown = 0
        loading = false

        async mounted() {
            this.loading = true
            await Promise.all([cmsStore.loadBlogItems()])
            this.loading = false
            this.itemsShown =
                this.blogItems.length >= this.itemsShowCountStep ? this.itemsShowCountStep : this.blogItems.length
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadBlogItems)
        }

        loadMore() {
            this.itemsShown += this.itemsShowCountStep
        }

        get itemsToShow() {
            return this.blogItems.slice(0, this.itemsShown)
        }

        get itemsShowCountStep() {
            return 6
        }

        get blogItems() {
            return cmsStore.blogItems
        }
    }
</script>
