<template>
    <div>
        <breadcrumbs />
        <v-tabs v-model="selectedTabIndex" show-arrows center-active>
            <v-tab v-for="tab in tabs" :key="tab.name">
                <v-icon class="me-1">
                    {{ tab.ico }}
                </v-icon>
                {{ $t(tab.title) }}
            </v-tab>
        </v-tabs>
        <v-row v-show="tabSelectedName === 'hotels' && hotels.length">
            <template v-for="(product, index) in hotels">
                <v-col :key="`hotel-${index}`" md="6" lg="4">
                    <hotel-offer-card
                        :offer="product"
                        :search-request="product.searchRequest"
                        hide-room-offers
                        hide-tripadvisor-rating
                    >
                        <template v-slot="{hotelPageLink}">
                            <div class="d-flex justify-end">
                                <v-btn v-if="hotelPageLink" :to="hotelPageLink" color="primary" depressed nuxt>
                                    {{ $t('book') }}
                                </v-btn>
                            </div>
                        </template>
                    </hotel-offer-card>
                </v-col>
            </template>
        </v-row>
        <v-row v-show="tabSelectedName === 'activities' && activities.length">
            <v-col v-for="(product, index) in activities" :key="`activity-${index}`" md="4">
                <activity-card
                    :product="product"
                    :search-request="product.searchRequest"
                    hide-price
                    class="activity-card"
                >
                    <template v-slot>
                        <div class="d-flex justify-end">
                            <v-btn :to="getPageLink(product)" color="primary" depressed nuxt>
                                {{ $t('book') }}
                            </v-btn>
                        </div>
                    </template>
                </activity-card>
            </v-col>
        </v-row>
        <v-row v-if="isLoading">
            <v-col md="4"><v-skeleton-loader type="card" /></v-col>
        </v-row>
        <div
            v-if="
                !isLoading &&
                ((tabSelectedName === 'activities' && !activities.length) ||
                    (tabSelectedName === 'hotels' && !hotels.length))
            "
            class="pa-4"
        >
            {{ $t('favorite_list_is_empty') }}
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {authStore} from '@/utils/store-accessor'
    import HotelOfferCard from '~src/components/search/offers/hotelOfferCard.src'
    import {activitiesRuntimeStore} from '~/store'
    import ActivityCard from '@/components/activities/search/offers/ActivityCard'

    @Component({
        components: {
            Breadcrumbs,
            HotelOfferCard,
            ActivityCard,
        },
        middleware: 'auth',
    })
    export default class FavoritesPage extends Vue {
        selectedTabIndex = 0
        hotels = []
        activities = []
        counter = 0
        isLoading = true

        mounted() {
            this.load()
        }

        async load() {
            try {
                const userId = authStore.person.personId
                const wishlist = await this.$api.wishlist.get({userId})
                wishlist.forEach(item => (item.wishlist = true))

                this.hotels = wishlist.filter(item => item.productType === 'hotel')
                this.activities = wishlist.filter(item => item.productType === 'activity')
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.isLoading = false
        }

        getPageLink(product) {
            return activitiesRuntimeStore.infoPageLink(product, product.searchRequest)
        }

        get tabs() {
            const tabs = []
            const activeProducts = this.$config.activeProducts

            if (activeProducts.includes('hotels')) {
                tabs.push({
                    ico: 'mdi-office-building',
                    title: 'hotels',
                    name: 'hotels',
                })
            }
            if (activeProducts.includes('activities')) {
                tabs.push({
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    name: 'activities',
                })
            }

            return tabs
        }

        get tabSelectedName() {
            return this.tabs[this.selectedTabIndex].name
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .activity-card .price-col {
            display: none;
        }
    }
</style>
