<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-car"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service-image="mainImage"
                :is-package="isPackage"
                :service-label="serviceDetails.planName"
                :order="order"
                :order-end-date="orderEndDate"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="d-flex align-center mt-4">
                    <v-icon left small>mdi-city</v-icon>
                    {{ serviceDetails.zoneName }}
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <v-card class="pa-1 pa-md-3">
                    <div class="modal-close-btn-block">
                        <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    </div>
                    <service-info-base :service="service" :info="info" :image="mainImage">
                        <template v-if="info" v-slot:serviceInfo>
                            <div class="mb-1 font-weight-medium body-1 grey--text">
                                {{ serviceDetails.planName }}
                            </div>
                            <div class="mb-1">
                                {{
                                    `${$t('duration')}: ${serviceDetails.durationDays} ${$tc(
                                        'day',
                                        serviceDetails.durationDays
                                    )}`
                                }}
                            </div>
                        </template>
                    </service-info-base>
                </v-card>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import OrderInfoBase from '~src/components/account/orderServices/orderInfoBase.src'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'

    @Component({
        components: {
            ServiceCard,
            OrderInfoBase,
            ServiceInfoBase,
        },
    })
    export default class InsuranceService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage

        modal = false

        showInfo() {
            this.modal = true
        }

        get mainImage() {
            return ''
        }

        get info() {
            return {}
        }

        get serviceCheckIn() {
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `<span> ${this.$t('start_date_from')}: ${date}</span>`
        }

        get serviceCheckOut() {
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `<span> ${this.$t('start_date_to')}: ${date}</span>`
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }
    }
</script>
