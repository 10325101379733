<template>
    <service-card
        :service="formattedService"
        icon="mdi-hotel"
        :is-first="isFirst"
        :service-tips="serviceTips"
        :order="order"
        @showInfo="showInfo"
    >
        <template v-if="info" v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :is-package="isPackage"
                :service-image="mainImage"
                :icons-color="'primary'"
                :order="order"
                :order-end-date="orderEndDate"
                :not-itinerary-start="notItineraryStart"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-if="info" v-slot:additionalInfo>
                    <small
                        v-if="info.address"
                        class="d-block hotel-address mt-2"
                        :style="{cursor: info.latitude && info.longitude ? 'pointer' : 'inherit'}"
                        @click="mapModal = true"
                    >
                        <v-icon v-if="info.latitude && info.longitude" small color="primary">mdi-map-marker</v-icon>
                        {{ hotelAddress }}
                    </small>
                </template>
                <div class="d-flex">
                    <v-icon color="primary" left small>mdi-weather-night</v-icon>
                    <strong class="black--text">{{ `${nights} ${$tc('night', nights)}` }}</strong>
                </div>
                <hotel-supplier-name-label v-if="isTO1" :supplier-name="service.supplierCompanyName" />
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillAccommodationBookingDataModal = true"
                        >mdi-pencil</v-icon
                    >
                    <span
                        class="text-caption cursor-pointer primary--text"
                        @click="fillAccommodationBookingDataModal = true"
                    >
                        {{ $t('enter_details') }}
                    </span>
                    <fill-accommodation-booking-data-modal
                        v-model="fillAccommodationBookingDataModal"
                        :service="service"
                        :order="order"
                    />
                </div>
                <div v-if="!$breakpoint.smAndDown" class="mt-4" />
                <template v-slot:travelers="{emptyAdditionalFields}">
                    <v-row v-for="room in rooms" :key="room.roomId">
                        <v-col>
                            <div class="d-flex text-subtitle-1">
                                <div class="ms-3">
                                    <v-icon left small>mdi-bed-empty</v-icon>
                                    <strong class="font-weight-bold black--text">
                                        {{ room.roomTypeName }}
                                    </strong>
                                </div>
                                <hotel-bed-type-label
                                    v-if="room.bedTypes && room.bedTypes.length"
                                    :bed-types="room.bedTypes"
                                    class="ms-3"
                                />
                                <!--v-icon right small @click="showRoomModal(room)">
                                    mdi-information-outline
                                </v-icon-->
                            </div>
                            <travelers
                                :service="service"
                                :travelers="roomTravelers(room)"
                                :product="service.serviceType"
                                :details="service.serviceDetails"
                                :empty-additional-fields="emptyAdditionalFields"
                            >
                                <template v-slot:mealType="{traveler}">
                                    <v-col cols="12" md="2">
                                        <small class="d-block traveler-label">{{ $t('filterTitles.mealTypes') }}</small>
                                        <span class="text-uppercase black--text font-weight-bold">
                                            {{ traveler.mealType ? traveler.mealType : room.mealTypeName }}
                                        </span>
                                    </v-col>
                                </template>
                            </travelers>
                        </v-col>
                    </v-row>
                    <v-dialog v-if="roomData" v-model="roomModal" width="600" hide-overlay>
                        <div class="modal-close-btn-block">
                            <v-icon class="v-card-close" @click="roomModal = false">mdi-close</v-icon>
                        </div>
                        <div class="mb-2 text-h3">
                            {{ service.serviceName }}
                        </div>
                        <div v-if="roomData.roomTypeName" class="mb-1">
                            <span class="font-weight-medium">{{ $t('roomGroupBy.name') }}</span>
                            : {{ roomData.roomTypeName }}
                        </div>
                        <div v-if="roomData.mealTypeName" class="mb-1">
                            <span class="font-weight-medium">{{ $t('roomGroupBy.name') }}</span>
                            : {{ roomData.mealTypeName }}
                        </div>
                    </v-dialog>
                </template>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon color="primary" class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base v-if="info" :service="formattedService" :info="infoData" :image="mainImage">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                    <template v-slot:serviceInfo>
                        <div class="d-flex align-center">
                            <v-icon left small color="accent">mdi-map-marker</v-icon>
                            <small>{{ hotelAddress }}</small>
                        </div>
                        <div v-if="info.email" class="d-flex align-center">
                            <v-icon left small>mdi-at</v-icon>
                            {{ info.email }}
                        </div>
                        <div v-if="info.url" class="d-flex align-center">
                            <v-icon left small>mdi-web</v-icon>
                            <a :href="info.url" target="_blank">
                                {{ info.url }}
                            </a>
                        </div>
                        <v-row no-gutters>
                            <v-col v-if="info.phone" :cols="12" md="12">
                                <div class="d-flex align-center">
                                    <v-icon left small>mdi-phone</v-icon>
                                    {{ info.phone }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col v-if="info.fax" :cols="12" md="12">
                                <div class="d-flex align-center">
                                    <v-icon left small>mdi-fax</v-icon>
                                    {{ info.fax }}
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:extraInfo>
                        <v-col :cols="12" md="12" class="mt-5">
                            <p
                                v-for="desc in info.descriptions.description"
                                :key="desc.type"
                                v-html="desc.description"
                            />
                        </v-col>
                        <v-col :cols="12" md="8" class="mt-3">
                            <div class="hidden-lg-and-down">
                                <v-row no-gutters>
                                    <v-col class="col-title">
                                        <v-icon left small>mdi-star-circle-outline</v-icon>
                                        {{ $t('services') }}:
                                    </v-col>
                                    <v-col class="pl-2 d-flex flex-wrap">
                                        <v-chip
                                            v-for="service in info.services.service"
                                            :key="service.supplierSpecificCode"
                                            small
                                            class="px-2 mr-1 mt-1"
                                        >
                                            {{ service.otaname || service.supplierServiceName }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </template>
                </service-info-base>
            </v-dialog>
            <client-only>
                <v-dialog v-if="info && info.latitude && info.longitude" v-model="mapModal" :max-width="500">
                    <map-location
                        v-if="mapModal"
                        :latitude="info.latitude"
                        :longitude="info.longitude"
                        :height="`500px`"
                    />
                </v-dialog>
            </client-only>
        </template>
    </service-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import AccommodationService from '@/components/account/orderServices/AccommodationService'

    @Component
    export default class AccommodationServiceMod extends AccommodationService {
        get serviceCheckIn() {
            const time = this.service.serviceDetails[0].checkInFrom
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return time
                ? `<span>${date}</span> <span style="color: #9B9B9B" class="font-weight-regular">${this.$t(
                      'time_from'
                  )} ${time}</span>`
                : date
        }

        get serviceCheckOut() {
            const time = this.service.serviceDetails[0].checkOutTo
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return time
                ? `<span>${date}</span> <span style="color: #9B9B9B" class="font-weight-regular">${this.$t(
                      'time_before'
                  )} ${time}</span>`
                : date
        }
    }
</script>
