<template>
    <div class="errorContainer">
        <div v-if="error.statusCode !== 404" class="text-h3 grey--text">
            {{ error.statusCode }}
        </div>
        <template v-if="error.statusCode === 404">
            <v-row>
                <v-col v-if="isMobile" cols="12" md="6">
                    <v-img :src="'/404.png'" />
                </v-col>
                <v-col cols="12" md="6">
                    <div class="d-flex flex-column full-height justify-center align-center">
                        <div class="d-flex flex-column align-start">
                            <span class="text-h3 font-weight-medium">
                                <span>
                                    {{ $t('page') }}
                                </span>
                                <span class="primary--text"> {{ $t('not found') }}. </span>
                            </span>
                            <span class="font-weight-medium mt-2"> {{ $t('let_help') }}. </span>
                            <div class="py-6" />
                            <v-btn
                                x-large
                                class="mt-8 px-8"
                                elevation="0"
                                :block="isMobile"
                                color="primary"
                                @click="$router.push('/')"
                            >
                                {{ $t('back_to_homepage') }}</v-btn
                            >
                        </div>
                    </div>
                </v-col>
                <v-col v-if="!isMobile" cols="12" md="6">
                    <v-img :src="'/404.png'" />
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <div class="text-h3 grey--text text--lighten-1">
                {{ error.message }}
            </div>
            <div v-if="error.statusCode === 500" class="text-subtitle-1 grey--text text--lighten-1 mb-5">
                {{ error.note || $t('warning_message.service_unavailable_note') }}
            </div>
        </template>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import Error from '@/components/Error'

    @Component
    export default class ErrorMod extends Error {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
