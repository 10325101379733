<template>
    <v-card outlined class="mb-3 pa-0">
        <v-card-text class="black--text d-flex flex-column">
            <v-row dense>
                <v-col cols="12" md="9">
                    <flight-itinerary
                        v-for="(itinerary, index) in offer.itinerary"
                        :key="index"
                        :itinerary="itinerary"
                    />
                    <v-row v-if="!$breakpoint.smAndDown" no-gutters>
                        <v-col cols="12" md="12">
                            <v-btn text color="info" small @click="expanded = !expanded">
                                {{ $t('flights.detail_info') }}
                            </v-btn>
                            <fare-rules :offer-key="offer.offerKey" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="$breakpoint.smAndDown" cols="12" class="pa-0">
                    <v-row>
                        <v-col cols="12" md="3" class="py-1 py-md-3">
                            <v-btn text color="info" small @click="expanded = !expanded">
                                {{ $t('flights.detail_info') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="3" class="py-1 py-md-3">
                            <v-btn
                                v-if="offer.fareOptions && !offer.deltaCurrency"
                                text
                                small
                                color="info"
                                block
                                @click="showFareOptions = !showFareOptions"
                            >
                                {{ showFareOptions ? $t('flights.hide_tariffs') : $t('flights.show_tariffs') }}
                            </v-btn>
                            <fare-rules :offer-key="offer.offerKey" />
                        </v-col>
                    </v-row>
                    <div v-if="$breakpoint.smAndDown" class="mb-4">
                        <v-expand-transition>
                            <keep-alive>
                                <flight-detail v-if="expanded" :offer="offer" />
                            </keep-alive>
                        </v-expand-transition>
                        <v-expand-transition>
                            <keep-alive>
                                <flight-fare-options v-if="showFareOptions" :offer-key="offer.offerKey" />
                            </keep-alive>
                        </v-expand-transition>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    class="d-flex justify-center align-center flex-wrap pa-0 pl-2 flex-column"
                    :class="{border: !$breakpoint.smAndDown}"
                >
                    <slot name="price">
                        <div class="headline d-flex">
                            <div v-if="offer.price" class="text-right">
                                <price-label
                                    :price="offer.price"
                                    :content-class="'text-h6 mb-1'"
                                    convert-price
                                    column
                                />
                                <discount-label :price="offer.price" class="d-inline-block mt-1" />
                            </div>
                            <corporate-policy-violation
                                v-if="offer.corporatePolicyViolation"
                                :policies="offer.corporatePolicy"
                            />
                            <price-details-btn :offer="offer" />
                        </div>
                        <commission-amount v-if="offer.price" :commission="offer.price.commission" />
                        <flight-conditions-label
                            class="mb-1"
                            small
                            :offer-key="offer.offerKey"
                            :price="offer.price"
                            :refundable="offer.refundable"
                            :regular="offer.regular"
                            @conditions="showConditionsInfo"
                        />
                        <div class="align-self-end widen">
                            <v-btn block color="success" class="mb-2" @click="selectForBooking">
                                {{ $t('book') }}
                            </v-btn>
                            <v-btn
                                v-if="offer.fareOptions && !offer.deltaPrice"
                                text
                                small
                                color="info"
                                block
                                @click="showFareOptions = !showFareOptions"
                            >
                                {{ showFareOptions ? $t('flights.hide_tariffs') : $t('flights.show_tariffs') }}
                            </v-btn>
                        </div>
                    </slot>
                </v-col>
            </v-row>
            <div v-if="!$breakpoint.smAndDown">
                <v-expand-transition>
                    <keep-alive>
                        <flight-detail v-if="expanded" :offer="offer" />
                    </keep-alive>
                </v-expand-transition>
                <v-expand-transition>
                    <keep-alive>
                        <flight-fare-options v-if="showFareOptions" :offer-key="offer.offerKey" />
                    </keep-alive>
                </v-expand-transition>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import PriceDetailsBtn from '~/components/flights/PriceDetailsBtn'
    import FlightConditionsLabel from '@/components/flights/snippets/FlightConditionsLabel'
    import {flightsStore} from '@/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import {EventBus, SHOW_FLIGHT_CONDITIONS_INFO} from '@/utils/event-bus'
    import PriceLabel from '~src/components/snippets/priceLabel.src'

    @Component({
        components: {
            PriceLabel,
            FlightConditionsLabel,
            FlightItinerary,
            FlightDetail: () => import('~/components/flights/search/offers/FlightDetail'),
            FlightFareOptions: () => import('~/components/flights/search/offers/FlightFareOptions'),
            CommissionAmount: () => import('~/components/snippets/CommissionAmount'),
            PriceDetailsBtn,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
            FareRules: () => import('~/components/flights/FareRules'),
            DiscountLabel,
        },
    })
    export default class FlightCard extends Vue {
        @Prop({required: true}) offer

        expanded = false
        showFareOptions = false

        selectForBooking() {
            flightsStore.ADD_TO_BASKET(this.offer)
            this.$router.push({
                name: 'flightBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_FLIGHT_CONDITIONS_INFO, this.offer)
        }
    }
</script>

<style lang="scss" scoped>
    .border {
        border-left: 1px solid grey !important;
    }

    .widen {
        width: 100%;
    }
</style>
