<template>
    <div>
        <v-carousel :show-arrows="nextCarouselItems.length > 0" hide-delimiters height="auto">
            <v-carousel-item key="0">
                <v-row no-gutters>
                    <v-col class="d-flex child-flex ps-0 pb-2" cols="12">
                        <v-card flat tile class="d-flex">
                            <v-img
                                v-if="mainImage && mainImage.url"
                                :src="mainImage.url | imageUrl(600)"
                                :title="imgTitle(mainImage)"
                                :lazy-src="'/placeholder.png' | srcHost"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                max-height="330"
                                @click="showPhotoSwipe(0)"
                                @load="onImageLoad"
                                @error="hidePlaceholder(mainImage.url)"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        v-show="imagePlaceholdersVision[0]"
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5" />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-row dense>
                            <v-col
                                v-for="(image, imageIndex) in firstCarouselItem"
                                :key="imageIndex"
                                class="d-flex child-flex"
                                cols="3"
                            >
                                <v-card flat tile class="d-flex">
                                    <v-img
                                        v-if="image && image.url"
                                        :src="image.url | imageUrl(350)"
                                        :title="imgTitle(image)"
                                        :lazy-src="'/placeholder.png' | srcHost"
                                        aspect-ratio="1"
                                        max-height="75"
                                        class="grey lighten-2"
                                        @click="showPhotoSwipe(imageIndex + 1)"
                                        @error="hidePlaceholder(image.url)"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                v-show="imagePlaceholdersVision[getImageIndex(image.url)]"
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular indeterminate color="grey lighten-5" />
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-carousel-item>
            <v-carousel-item v-for="(images, index) in nextCarouselItems" :key="index + 1">
                <v-row dense>
                    <v-col v-for="(image, imageIndex) in images" :key="imageIndex" class="d-flex child-flex" cols="2">
                        <v-card flat tile class="d-flex">
                            <v-img
                                v-if="image && image.url"
                                :src="image.url | imageUrl(600)"
                                :title="imgTitle(image)"
                                :lazy-src="'/placeholder.png' | srcHost"
                                aspect-ratio="1"
                                class="grey lighten-2"
                                @error="hidePlaceholder(image.url)"
                                @click="
                                    showPhotoSwipe(
                                        imagesCountInFirstCarouselItem + index * imagesCountInCarouselItem + imageIndex
                                    )
                                "
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        v-show="imagePlaceholdersVision[getImageIndex(image.url)]"
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey lighten-5" />
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import ProductGallery from '~/components/info/ProductGallery'

    @Component
    export default class ProductGalleryMod extends ProductGallery {
        get imagesCountInFirstCarouselItem() {
            return 5
        }
    }
</script>
