<template>
    <v-card
        tile
        flat
        class="main-page-form-wrapper d-flex flex-column align-center px-8 px-md-0"
        style="
            margin-top: 90px;
            position: relative;
            min-height: calc(100vh - 150px);
            background: #f5f8f8;
            box-shadow: none !important;
        "
        :style="{marginTop: isMobile ? '40px' : '90px'}"
        elevation="0"
    >
        <img
            alt="world-map"
            :src="'/world_map.png'"
            style="z-index: 0; position: absolute; overflow: hidden"
            :style="{
                top: !isMobile ? '100px' : '40px',
                width: !isMobile ? '80%' : ' width: 300%',
                right: $vuetify.rtl ? '0' : '',
                left: $vuetify.rtl ? '' : '0',
            }"
        />
        <div style="z-index: 2; position: relative" class="full-width d-flex flex-column text-start">
            <span class="ms-2 text-h3 primary--text font-weight-bold">{{ $t('time_for_new') }} </span>
            <span class="ms-2 text-h4 font-weight-bold"> {{ $t('adventures') }} </span>
        </div>
        <search-form-tabs
            class="align-self-md-start"
            style="z-index: 2; position: relative; margin-top: 80px"
            :style="isMobile ? 'width: calc(100% - 8px)' : ''"
            :city-id="cityId"
            choose-agent
            @change="changeImg"
        />
        <img
            alt="background-places"
            :src="'/background-places.png'"
            style="z-index: 0; width: 100%"
            class="px-4 py-8"
            :style="{
                position: !isMobile ? 'absolute' : 'relative',
                width: !isMobile ? '50%' : '100%',
                top: !isMobile ? '0' : '',
                right: $vuetify.rtl ? '' : '0',
                left: $vuetify.rtl ? '0' : '',
            }"
        />
    </v-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import MainPageFormWrapper from '@/components/parts/MainPageFormWrapper'

    @Component
    export default class MainPageFormWrapperMod extends MainPageFormWrapper {
        @Prop({default: 1200}) minHeight

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
