<template>
    <v-dialog v-model="modal" persistent width="600">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-title class="justify-space-between px-4 fs-14">
                {{ services.length > 1 ? $t('book') + ' ' + $t('all') : services[0] && services[0].serviceName }}
            </v-card-title>
            <v-card-text class="fs-14">
                <slot name="info" />
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else class="v-card-modal">
            <v-card-title>
                {{ services.length > 1 ? $t('book') + ' ' + $t('all') : services[0] && services[0].serviceName }}
            </v-card-title>
            <payment-options v-model="paymentMethodId" class="elevation-0" :disabled="bookingActive" />
            <payment-redirect-form ref="paymentRedirectForm" />
            <v-divider />
            <v-card-actions class="justify-center">
                <v-btn
                    depressed
                    color="primary"
                    :disabled="bookingActive || !paymentMethodId"
                    :loading="bookingActive"
                    class="me-5"
                    @click="book"
                >
                    {{ $t('book') }}
                </v-btn>
                <v-btn depressed color="white" :disabled="bookingActive" @click="modal = false">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <prepare-book-messages-modal
            v-if="prepareBookResponse"
            :prepare-book-response="prepareBookResponse"
            :offers-data="offersData"
            @approve="loadPayments"
            @cancel="modal = false"
        />
    </v-dialog>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {EventBus, ORDER_SERVICE_BOOK, ORDER_SERVICE_BOOKED} from '@/utils/event-bus'
    import BookingMixin from '@/components/mixins/BookingMixin'
    import {persistentStore} from '@/utils/store-accessor'
    import PaymentOptions from '@/components/booking/PaymentOptions'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import PrepareBookMessagesModal from '@/components/booking/modals/PrepareBookMessagesModal.vue'
    import {isPrepareBookResultClean, prepareBookResult} from '@/utils/PrepareBookAdapter'

    @Component({
        components: {
            PrepareBookMessagesModal,
            PaymentOptions,
            PaymentRedirectForm,
        },
    })
    export default class OrderServiceBookModal extends mixins(BookingMixin) {
        services = []
        loaded = false
        modal = false
        prepareBookResponse = null
        paymentMethodId = null
        bookingActive = false

        @Watch('modal')
        onChangeModal() {
            this.prepareBookResponse = null
        }

        mounted() {
            EventBus.$on(ORDER_SERVICE_BOOK, this.show)
        }

        beforeDestroy() {
            EventBus.$off(ORDER_SERVICE_BOOK, this.show)
        }

        async show(services) {
            this.services = services
            this.loaded = false
            this.modal = true
            this.paymentMethodId = null
            this.bookingActive = false
            try {
                this.prepareBookResponse = await this.$api.prepareBookSaveOrder.post({
                    processIds: this.services.map(s => s.processId.replace(/^.{6}/, '')),
                    checkAvailability: false,
                })
                if (isPrepareBookResultClean(prepareBookResult(this.prepareBookResponse, this.offersData))) {
                    await this.loadPayments()
                }
            } catch (e) {
                console.error(e)
                this.prepareBookErrorProcessing(e)
                this.modal = false
            }
        }

        async loadPayments() {
            try {
                await persistentStore.getPaymentOptions({
                    bookingKey: this.prepareBookResponse.bookingKey,
                    convertToCurrency: persistentStore.getCurrency(this.services[0].salesTerms[0].price.currency),
                })
                this.loaded = true
            } catch (e) {
                this.modal = false
                this.$toast.warning(this.$t('error_message.get_payment_options_unknown_error'))
            }
        }

        async book() {
            this.bookingActive = true
            const paymentMethod = persistentStore.paymentMethods.find(
                paymentMethod => paymentMethod.id === this.paymentMethodId
            )
            const success = !paymentMethod.onlinePSP ? await this.makeOfflinePayment() : await this.makeOnlinePayment()
            if (success) {
                this.modal = false
            } else {
                this.bookingActive = false
            }
        }

        async makeOfflinePayment() {
            try {
                await persistentStore.book({
                    bookingKey: this.bookingKey,
                    paymentMethodId: this.paymentMethodId,
                })
                EventBus.$emit(ORDER_SERVICE_BOOKED)
                return true
            } catch (e) {
                this.$toast.error(this.$t('error_message.unknown_booking_error'))
                return false
            }
        }

        getPaymentCancelUrl() {
            return window.location.origin + this.$route.fullPath
        }

        get bookingKey() {
            return this.prepareBookResponse?.bookingKey
        }

        get productName() {
            return null
        }

        get corporatePolicyViolationCodeId() {
            return null
        }

        get offersData() {
            return this.services.map(({serviceName, supplierCode}) => ({name: serviceName, supplierCode}))
        }
    }
</script>

<style scoped lang="scss">
    .v-card-modal .v-card__title {
        background: var(--v-primary-base);
        color: white;
    }
</style>
