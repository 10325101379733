<template>
    <v-rating
        :color="color"
        background-color="grey lighten-2"
        empty-icon="mdi-star"
        :small="small"
        dense
        readonly
        :size="size"
        :value="parseInt(hotel.category)"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelStars extends Vue {
        @Prop({required: true}) hotel
        @Prop({required: false, default: 'amber'}) color
        @Prop({default: true, type: Boolean}) small
        @Prop() size
    }
</script>
