<template>
    <v-form ref="form" v-model="valid" data-cy="authorization-form" @keyup.native.enter="auth">
        <v-text-field
            v-model="email"
            :placeholder="$t('email')"
            type="email"
            :rules="validationRules('email', [emailRule, requiredRule])"
            required
            data-cy="authorization-email-input"
            :background-color="dark ? 'white' : undefined"
            dense
            class="rounded-md"
            filled
            single-line
            :outlined="dark"
        />
        <v-text-field
            v-model="password"
            :placeholder="$t('password')"
            type="password"
            :rules="[requiredRule('password')]"
            required
            data-cy="authorization-password-input"
            :background-color="dark ? 'white' : undefined"
            dense
            filled
            single-line
            :outlined="dark"
        />
        <v-row>
            <v-col cols="6">
                <v-btn
                    :loading="isLoading"
                    :disabled="isLoading || !valid"
                    color="primary"
                    block
                    elevation="0"
                    :dark="dark"
                    data-cy="authorization-submit-button"
                    @click="auth"
                >
                    {{ $t('sign_in') }}
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn
                    style="max-width: inherit !important"
                    block
                    color="primary"
                    class="text-capitalize"
                    text
                    @click="$emit('switch-forgot-password')"
                >
                    <span class="black--text"> {{ `${$t('forgot_your_password')}${$t('?')}` }} </span>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import AuthorizationForm from '~/components/account/forms/AuthorizationForm'

    @Component
    export default class AuthorizationFormMod extends AuthorizationForm {}
</script>
