<template>
    <v-form>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`details`) }} </span>
        <v-row>
            <v-col cols="12" md="2">
                <airports-search-input
                    v-model="product.departureAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.departure_airport')}`"
                    hide-details
                    single-line
                    :item-text="'iataCode'"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.departureTerminal"
                    outlined
                    dense
                    :label="`${$t('flights.departure_terminal')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <airports-search-input
                    v-model="product.arrivalAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.arrival_airport')}`"
                    hide-details
                    single-line
                    :item-text="'iataCode'"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.arrivalTerminal"
                    outlined
                    dense
                    :label="`${$t('flights.arrival_terminal')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.flightNumber"
                    outlined
                    dense
                    :label="`${$t('flights.flight_number')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.flightClass"
                    outlined
                    dense
                    :label="`${$t('flights.flight_class')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.bookingClass"
                    outlined
                    dense
                    :label="`${$t('flights.booking_class')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.seatsPrebooked"
                    outlined
                    dense
                    :label="`${$t('flights.seats_prebooked')}`"
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-checkbox
                    v-model="product.mealIncluded"
                    outlined
                    dense
                    :label="`${$t('meal_included')}`"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import AirportsSearchInput from '@/components/snippets/AirportsSearchInput'

    @Component({
        components: {AirportsSearchInput},
    })
    export default class OfflineServiceFormFlight extends Vue {
        @Prop() productType
        @VModel() product

        airports = []

        mounted() {}
    }
</script>
