<template>
    <v-card>
        <v-card-title class="py-5 full-width">
            <v-btn v-if="createMode || editMode" fab class="me-3" small elevation="0" @click="goToTable">
                <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <span v-if="createMode">
                {{ `${$t('create_internal_note')}` }}
            </span>
            <span v-else-if="editMode">
                {{ `${$t('edit_internal_note')}` }}
            </span>
            <span v-else>
                {{ `${$tc('internal_note', 2)}` }}
            </span>
            <v-btn v-if="!(createMode || editMode)" color="primary" right text absolute @click="toggleActive">
                <v-icon v-if="!active" left> mdi-arrow-left </v-icon>
                <v-icon left> mdi-archive-arrow-{{ active ? 'down' : 'up' }} </v-icon>
                {{ $t(active ? 'archive' : 'standard_mode') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <internal-note-edit-form
                v-if="createMode || editMode"
                v-model="communicationData"
                :edit-note-info="editNoteInfo"
                :edit-mode="editMode"
                :services="services"
                :specified-service="specifiedService"
                :_valid.sync="valid"
            />
            <v-data-table
                v-else
                :headers="headers"
                :items="communicationsFiltered"
                :loading="loading || communicationsLoading"
                class="elevation-1"
                :hide-default-footer="communications && communications.length < 15"
                :items-per-page="15"
                :item-class="() => (!active ? 'inactive-item' : '')"
                @click:row="editCommunication"
            >
                <template v-slot:item.status="{item}">
                    {{ $t(`${item.status.toLowerCase()}`) }}
                </template>
                <template v-slot:item.communicationDateTime="{item}">
                    {{ formatDate(item.communicationDateTime) }}
                </template>
            </v-data-table>
            <v-btn v-if="!createMode && !editMode" color="primary" class="mt-4" @click="createMode = !createMode">
                {{ $t('create') }}
            </v-btn>
        </v-card-text>
        <v-card-actions class="py-4">
            <v-spacer />
            <v-btn v-if="createMode || editMode" color="primary" :loading="loading" :disabled="!valid" @click="save">
                {{ $t('save') }}
            </v-btn>
            <v-btn v-if="editMode" text @click="cancel">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import InternalNoteEditForm from '@/components/account/internalNotes/InternalNoteEditForm'
    import Communications from '@/components/account/communications/Communications'
    import {format} from 'date-fns'
    import {dateToUTC} from '@/utils/helpers'

    @Component({
        components: {InternalNoteEditForm, TimeField, DatePicker, Communications},
    })
    export default class InternalNotes extends Communications {
        @Prop() services
        @Prop() specifiedService

        valid = false
        communicationData = {
            type: 'NOTES',
            communicationDateTime: null,
            description: null,
            active: true,
            showInInvoice: false,
            showInQuotation: false,
            processId: null,
        }
        editNoteInfo = null
        active = true

        async mounted() {
            Communications.options.m
        }

        toggleActive() {
            this.active = !this.active
        }

        resetData() {
            this.communicationData = {
                type: 'NOTES',
                communicationDateTime: null,
                description: null,
                active: true,
            }
        }

        editCommunication(e) {
            const processId =
                this.specifiedService?.processId ||
                this.services.find(item => item.serviceName === e.serviceName)?.processId

            this.communicationId = e.communicationId
            this.communicationData = {
                assignedToId: e.assignedUser.id,
                accountId: e.accountId,
                accountType: e.accountType,
                communicationDateTime: e.communicationDateTime?.split(' ').join('T'),
                description: e.description,
                orderId: e.reservationNumber,
                ...(processId && {processId}),
                active: e.status === 'ACTIVE',
                showInInvoice: e.showInInvoice,
                showInQuotation: e.showInQuotation,
            }

            this.editNoteInfo = {
                created: e.createdDateTime,
                modified: e.modifiedDateTime,
                createdUser: e.createdByUserName,
                modifiedUser: e.modifiedByUserName,
            }

            this.editMode = true
        }

        formatDate(date) {
            return format(dateToUTC(new Date(date)), 'dd MMM yyyy HH:mm')
        }

        get communicationsFiltered() {
            return this.communications
                .filter(({serviceName}) => !this.specifiedService || this.specifiedService.serviceName === serviceName)
                .filter(e => (this.active ? e.status === 'ACTIVE' : e.status === 'INACTIVE'))
        }

        get requestData() {
            const {type, communicationDateTime, description, processId, active, showInInvoice, showInQuotation} =
                this.communicationData
            return {
                assignedToId: this.person.personId,
                accountId: this.accountId,
                accountType: this.accountType,
                type,
                communicationDateTime,
                description,
                orderId: this.orderId,
                processId,
                active,
                showInInvoice,
                showInQuotation,
            }
        }

        get headers() {
            return [
                {text: `${this.$t('date')}/${this.$t('time')}`, value: 'communicationDateTime'},
                {text: this.$tc('internal_note', 1), value: 'description'},
                ...(!this.specifiedService ? [{text: this.$t('relates_to'), value: 'serviceName'}] : []),
                ...(!this.specifiedService ? [{text: this.$t('user'), value: 'createdByUserName'}] : []),
                {text: this.$t('status'), value: 'status'},
            ]
        }
    }
</script>
