<template>
    <div v-if="!person">
        <v-alert v-if="memberLevel === 'GUEST'">
            <div class="text-subtitle-1 d-inline-flex me-4 mb-3 mb-md-0">
                {{ $t('notes.logged_unregistered') }}
            </div>
            <v-btn
                depressed
                :disabled="disabled"
                large
                color="primary"
                class="me-2 px-8 rounded-lg"
                @click="memberLevel = 'AUTH'"
            >
                {{ $t('log_in') }}
            </v-btn>
            <v-btn
                :disabled="disabled"
                color="primary"
                large
                elevation="0"
                text
                class="text-capitalize rounded-lg"
                @click="memberLevel = 'REGISTERED'"
            >
                {{ `${$t('register_new_account')}${$t('?')}` }}
            </v-btn>
        </v-alert>
        <v-card v-else outlined class="mb-5" :disabled="disabled">
            <v-card-title class="title font-weight-light">
                {{ memberLevel === 'REGISTERED' ? $t('register') : $t('sign_in') }} {{ $t('or') }}&nbsp;
                <a href="#" class="font-weight-medium text-decoration-none" @click.prevent="memberLevel = 'GUEST'">
                    {{ $t('continue_as_guest') }}
                </a>
            </v-card-title>
            <v-card-text>
                <registration-form v-if="memberLevel === 'REGISTERED'" hide-btn />
                <template v-if="memberLevel === 'AUTH'">
                    <authorization-form v-if="!forgotPassword" @switch-forgot-password="forgotPassword = true" />
                    <template v-if="forgotPassword">
                        <div class="title mt-4">{{ $t('forgot_your_password') }}</div>
                        <forgot-password-form
                            @switch-login="forgotPassword = false"
                            @forgot-password-success="forgotPassword = false"
                        />
                    </template>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import AccountBookingInfo from '~/components/booking/AccountBookingInfo'

    @Component
    export default class AccountBookingInfoMod extends AccountBookingInfo {}
</script>

<style lang="scss" scoped>
    .v-alert {
        background-color: white !important;
        border: 1px solid #e9e9e9;
        border-left: 2px solid var(--v-error-base);
        border-radius: 0;
    }
</style>
