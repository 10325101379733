<template>
    <div v-if="room.tariff && ownProduct" class="d-flex align-center">
        <v-icon left small class="info--text" :color="iconColor">mdi-tag-text</v-icon>
        {{ room.tariff }}
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TariffLabel extends Vue {
        @Prop({required: true}) room
        @Prop({default: 'primary'}) iconColor
        @Prop({required: true}) hotel

        get ownProduct() {
            return hotelsRuntimeStore.ownProduct(this.hotel)
        }
    }
</script>
