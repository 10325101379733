<template>
    <v-row class="d-flex" align="center">
        <v-col cols="12" :md="2">
            <v-select
                v-model="traveler.prefix"
                outlined
                dense
                :items="['Mr', 'Ms', 'Mrs']"
                :label="`${$t('salutation')}`"
                hide-details
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field v-model="traveler.firstName" outlined dense :label="`${$t('first_name')}`" hide-details />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field v-model="traveler.middleName" outlined dense :label="`${$t('middle_name')}`" hide-details />
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field v-model="traveler.lastName" outlined dense :label="`${$t('last_name')}`" hide-details />
        </v-col>
        <v-col cols="12" md="2">
            <date-picker
                v-model="traveler.dateOfBirth"
                :label="$t('birthdate')"
                :max="$dateFns.format(new Date())"
                hide-details
                outlined
                dense
            />
        </v-col>
        <v-col cols="12" md="1">
            <v-text-field v-model="type" outlined dense disabled :label="$t('flights.passenger_type')" hide-details />
        </v-col>
        <v-col cols="12" :md="1" class="d-flex justify-md-end justify-center align-center">
            <v-btn icon @click="remove">
                <v-icon>mdi-close-box-outline</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Emit, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {capitalizeTheFirstLetterOfEachWord} from '@/utils/helpers'

    @Component({
        components: {DatePicker},
    })
    export default class ModifyManualTraveler extends Vue {
        @PropSync('_traveler', {default: () => []}) traveler
        @Prop({required: true}) index

        @Emit()
        remove() {
            return this.index
        }

        get type() {
            return capitalizeTheFirstLetterOfEachWord(this.$tc(this.traveler.type, 1))
        }

        set type(v) {}
    }
</script>
