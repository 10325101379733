<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'
    import {isOwnProduct} from '@/utils/api-helpers'

    @Component
    export default class CruiseBookingPageLayout extends mixins(BookingPageBase, CruisesStoreMixin) {
        @Prop({required: true}) product

        get productSearchPageName() {
            return 'cruises'
        }

        get productConfirmationPageName() {
            return 'cruiseConfirmation'
        }

        get productName() {
            return 'cruise'
        }

        get productBookingPageName() {
            return 'cruiseBooking'
        }

        get corporatePolicyType() {
            return 'cruise'
        }

        get touristToken() {
            return 'guest'
        }

        get startDate() {
            return this.offer.startDate
        }

        get showAddOns() {
            return isOwnProduct(this.product.supplierId)
        }
    }
</script>
