<template>
    <v-card
        class="main-form"
        outlined
        tile
        :width="width"
        light
        :style="[
            {'box-shadow': row ? '' : '0px 4px 29px rgba(0, 0, 0, 0.06)'},
            {border: row ? '' : '1px solid rgba(0, 0, 0, 0.07)'},
        ]"
    >
        <v-card-title v-if="$breakpoint.smAndDown && popup" class="d-flex justify-end">
            <v-icon color="white" @click="$emit('close-popup')">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-else-if="$breakpoint.smAndDown && !popup" class="white--text">
            <span v-if="activeProducts.length > 1">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text class="white--text">
            <slot name="form" />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class SearchFormLayoutMod extends Vue {
        @Prop({default: false, type: Boolean}) popup
        @Prop() title
        @Prop() width
        @Prop({default: false, type: Boolean}) row

        get activeProducts() {
            return Object.values(this.$config.activeProducts)
        }
    }
</script>
