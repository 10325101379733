<template>
    <search-form-layout :popup="popup" title="activities.activities" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="4" cols="12">
                        <city-autocomplete
                            ref="locationAutocomplete"
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                            product-type="ACTIVITY"
                        />
                    </v-col>
                    <v-col md="4" cols="12">
                        <daterange-m :start-date.sync="startDate" :end-date.sync="endDate" />
                    </v-col>
                    <v-col md="2" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="guest"
                            :max-total="50"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :suppliers="suppliers"
                :options="[
                    'activity-type',
                    'promocode',
                    'citizenship',
                    'internalSuppliers',
                    'externalSuppliers',
                    'supplierId',
                ]"
            >
                <template v-slot:header>
                    <div class="d-flex align-center">
                        <v-expansion-panel-header class="pl-1 width-auto">
                            {{ $t('extra_options') }}
                        </v-expansion-panel-header>
                        <v-btn
                            v-if="isShowOwnActivitiesCalendar"
                            :disabled="!city"
                            text
                            dense
                            class="text-capitalize font-weight-regular view-calendar-btn"
                            @click="isShowActivityCalendar = true"
                        >
                            {{ $t('view_calendar') }}
                        </v-btn>
                        <activity-calendar-modal
                            v-model="isShowActivityCalendar"
                            :city="city"
                            :query-params="{
                                adults,
                                childrenAges,
                            }"
                            :first-start-date="startDate"
                        />
                    </div>
                </template>
            </additional-options-wrapper>
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {activitiesRuntimeStore, activitiesStore, authStore, persistentStore} from '@/store'
    import SearchFormLayout from '~src/components/parts/searchFormLayout.src'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '@/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import ActivityCalendarModal from '@/components/activities/modals/ActivityCalendarModal'

    @Component({
        components: {
            ActivityCalendarModal,
            TouristsSelectMenu,
            CityAutocomplete,
            DaterangeM,
            SearchFormLayout,
            AdditionalOptionsWrapper,
        },
    })
    export default class ActivitySearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup
        @Prop() cityId

        city = {}
        startDate = null
        endDate = null
        adults = 2
        childrenAges = []
        valid = false
        popularDestinations = []
        isShowActivityCalendar = false
        suppliers = []

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            await this.loadPopularDestinations()
            this.$refs.locationAutocomplete.clear()
            if (this.city && this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            } else {
                this.$refs.locationAutocomplete.load()
            }
        }

        newQuery() {
            const query = {
                cityId: this.city.id,
                startDate: this.startDate,
                endDate: this.endDate,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            const query = this.newQuery()
            activitiesRuntimeStore.SET_CITY(this.city)
            this.$emit('close-popup')
            if (this.$route.name !== 'activities') {
                activitiesRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name: 'activities', query})
            } catch (e) {
                await activitiesRuntimeStore.search(this.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            await this.loadPopularDestinations()
            const cityId = this.cityId || this.searchRequest.cityId
            if (cityId && activitiesRuntimeStore.city.id !== cityId) {
                await activitiesRuntimeStore.loadCity(this.cityId)
            }
            this.city = cityId && Object.keys(activitiesRuntimeStore.city).length ? activitiesRuntimeStore.city : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(this.searchRequest.startDate), new Date())) {
                this.startDate = this.searchRequest.startDate
                this.endDate = this.searchRequest.endDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
            //TODO Need refactoring with other products
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : this.searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp ? [] : [...this.searchRequest.childrenAges]
            if (this.isTO1) {
                this.suppliers = (await this.$api.suppliers.get({productType: 'excursion'}))?.suppliers
            }
        }

        async loadPopularDestinations() {
            try {
                this.popularDestinations = (
                    await this.$api.locations.get({limitCities: 7, productType: 'ACTIVITY'})
                ).cities
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isShowOwnActivitiesCalendar() {
            return this.$config.searchFormOptions?.ownActivitiesCalendar
        }

        get searchRequest() {
            return activitiesStore.searchRequest
        }
    }
</script>

<style scoped lang="scss">
    .view-calendar-btn {
        font-size: 0.9375rem;
        letter-spacing: normal;
    }
</style>
