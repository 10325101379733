var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expand-transition',[(_vm.groupItems)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groupItems,"group-by":"date","loading":_vm.detailsLoading,"sort-by":'time',"items-per-page":15,"mobile-breakpoint":"0","hide-default-header":_vm.isMobile},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{staticClass:"cursor-pointer",attrs:{"colspan":"12"},on:{"click":toggle}},[_c('v-icon',[_vm._v(_vm._s(isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]),_vm._v(" "),_c('span',[_vm._v("\n                    "+_vm._s(((items[0].date) + " ( " + (_vm.getDayOfWeek(items[0].date)) + " )"))+"\n                ")])],1)]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-3 align-start"},[_c('span',{staticClass:"d-flex justify-end justify-md-start font-weight-medium"},[_c('v-icon',{staticClass:"mr-1 ml-n1 d-inline-block",attrs:{"small":""}},[_vm._v(_vm._s(_vm.getServiceTypeIcon(item.serviceType)))]),_vm._v(" "),_c('div',{staticClass:"text-subtitle-2",domProps:{"innerHTML":_vm._s(item.serviceName)}})],1),_vm._v(" "),_c('span',{staticClass:"text-caption text--secondary"},[_vm._v("\n                    "+_vm._s(((item.city) + ", " + (item.country)))+"\n                ")]),_vm._v(" "),(_vm.isMobile)?_c('span',[_vm._v("\n                    "+_vm._s(((item.touristsQuantity) + "  " + (_vm.$tc('guest', item.touristsQuantity))))+"\n                ")]):_vm._e()])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.statusColor},[_vm._v(_vm._s(_vm.$t(("extranetGroupStatus." + (item.status)))))])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column align-end py-3"},[(_vm.isMobile)?_c('span',{staticClass:"pb-1",class:item.statusColor},[_vm._v(_vm._s(_vm.$t(("extranetGroupStatus." + (item.status)))))]):_vm._e(),_vm._v(" "),_c('v-btn',{attrs:{"color":'primary',"small":"","disabled":_vm.detailsLoading},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('details'))+"\n                ")])],1)]}}],null,false,827433320)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }