<template>
    <div>
        <div v-for="(price, index) in orderPrices" :key="index">
            <price-label :price="price" />
        </div>
        <payment-status-label v-if="$config.account.paymentScheduler" :status="paymentStatus" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import PriceLabel from '~src/components/snippets/priceLabel.src'
    import PaymentStatusLabel from '~src/components/account/snippets/paymentStatusLabel.src'

    @Component({components: {PaymentStatusLabel, PriceLabel}})
    export default class OrderPriceLabel extends Vue {
        @Prop({required: true}) orderPrices
        @Prop({required: true}) services
        @Prop({required: true}) paymentStatus
    }
</script>
