<template>
    <v-checkbox
        v-model="commissionAndNettoCheckbox"
        :label="$t('show_commission_and_net_amount')"
        hide-details
        class="mt-0 commission-and-netto-checkbox"
    />
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '@/utils/store-accessor'

    @Component
    export default class ShowCommissionAndNettoCheckbox extends Vue {
        async mounted() {
            await this.$store.restored
            this.load()
        }

        load() {
            if (!(this.isAgency || this.isTourOperator)) {
                persistentStore.SET_COMMISSION_AND_NETTO_CHECKBOX(true)
            }
        }

        get commissionAndNettoCheckbox() {
            return persistentStore.commissionAndNettoCheckbox
        }

        set commissionAndNettoCheckbox(val) {
            persistentStore.SET_COMMISSION_AND_NETTO_CHECKBOX(val)
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }
    }
</script>

<style lang="scss" scoped>
    .commission-and-netto-checkbox::v-deep {
        i {
            font-size: 20px;
        }

        label {
            font-size: 13px;
        }
    }
</style>
