<template>
    <div>
        <v-row>
            <v-col :md="2" :cols="12">
                <date-picker
                    v-model="infoByServiceType.flight.lastTicketingDate"
                    outlined
                    dense
                    :label="`${$t('flights.last_ticketing_date')}`"
                />
            </v-col>
        </v-row>
        <v-row v-for="(segment, i) in infoByServiceType.flight.segments" :key="i">
            <v-col :md="1" :cols="12">
                <v-text-field
                    v-model="segment.departureAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.departure_from')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="1" :cols="12">
                <v-text-field
                    v-model="segment.arrivalAirportCode"
                    outlined
                    dense
                    :label="`${$t('flights.arrival_to')}`"
                    hide-details
                />
            </v-col>
            <v-col :md="3" :cols="12">
                <daterange-m
                    dense
                    outlined
                    class="mt-2"
                    :start-date.sync="segment.departureDate"
                    :end-date.sync="segment.arrivalDate"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, VModel, Vue} from 'nuxt-property-decorator'
    import DaterangeM from '@/components/search/forms/DaterangeM'

    @Component({
        components: {DaterangeM},
    })
    export default class ModifyManualFlight extends Vue {
        @VModel() infoByServiceType
    }
</script>
