<template>
    <v-form>
        <span class="text-subtitle-1 font-weight-medium">{{ $t(`details`) }} </span>
        <v-row>
            <v-col cols="12" md="2">
                <time-field
                    v-model="product.durationTime"
                    outlined
                    dense
                    hide-details
                    class="cursor-pointer"
                    :label="`${$t('duration_time')}`"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="product.durationDays"
                    outlined
                    dense
                    type="number"
                    :label="`${$t('duration_days')}`"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'

    @Component({
        components: {TimeField},
    })
    export default class OfflineServiceFormTransfer extends Vue {
        @Prop() productType
        @Prop() tourists
        @VModel() product
    }
</script>
