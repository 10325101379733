<template>
    <v-list dense class="px-4 pt-4">
        <v-list-item v-for="(tourists, index) in rooms" :key="index" :data-cy="'room-selector-' + index">
            <v-list-item-content>
                <v-list-item-title class="text-capitalize">
                    <div class="d-flex align-center">
                        <span class="font-weight-medium subtitle-1 mb-2 black--text">
                            {{ $tc('room', 1) }} {{ index + 1 }}
                        </span>
                        <v-spacer />
                        <v-btn v-if="rooms.length > 1" icon @click="removeRoom(index)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-list-item-title>
                <div class="d-flex align-center">
                    <tourists-select
                        :adults-prop.sync="tourists.adults"
                        :children-prop.sync="tourists.childrenAges"
                        :max-total="maxTourists"
                        @change-age="changeAge"
                    />
                </div>
            </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn text :disabled="isDisabled" @click="addRoom()">
                {{ $t('add_room') }}
            </v-btn>
            <slot />
        </v-card-actions>
    </v-list>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import RoomsSelectList from '~/components/search/forms/RoomsSelectList'

    @Component
    export default class RoomsSelectListMod extends RoomsSelectList {}
</script>
