<template>
    <v-form ref="form" v-model="valid">
        <div class="d-flex align-center" :class="{'mb-8': !!point.address}">
            <div class="text-h6 text-capitalize me-2">
                {{ `${$t(`transfers.${pointType === 'departure' ? 'from' : 'to'}`)}: ` }}
            </div>
            <div class="d-flex align-center">
                <v-icon left>mdi-office-building</v-icon>
                <div class="text-subtitle-2">
                    {{ formTitle }}
                    <div v-if="point.address" class="text-caption" style="position: absolute">
                        {{ point.address }}
                    </div>
                </div>
            </div>
        </div>
        <v-autocomplete
            v-if="!point.hotelCode"
            v-model="hotelCode"
            :items="hotels"
            return-object
            item-text="hotelName"
            item-value="hotelCode"
            :label="`${$t('transfers.transfer_hotel')}${!ownProduct ? '*' : ''}`"
            :disabled="disabled"
            :rules="[
                v => !!v.hotelCode || ownProduct || `${$t('transfers.transfer_hotel')} ${$t('validation.required')}`,
            ]"
            clearable
        />
    </v-form>
</template>

<script>
    import {Component, mixins, Prop, PropSync} from 'nuxt-property-decorator'
    import {transfersRuntimeStore} from '@/utils/store-accessor'
    import TransferFormMixin from '@/components/transfers/mixins/TransferFormMixin'

    //TODO Need refactoring among other transfer forms
    @Component
    export default class TransferHotelForm extends mixins(TransferFormMixin) {
        @Prop({required: true}) pointType
        @Prop({default: () => []}) hotels
        @PropSync('_valid', {default: false}) valid
        @Prop({default: false, type: Boolean}) disabled
        @Prop({required: true}) product

        mounted() {
            if (this.point?.hotelCode) {
                this.setProp('hotelCode', this.point.hotelCode)
                this.setProp('hotelName', this.point.name)
            }
        }

        get hotelCode() {
            return {
                hotelCode: this.getProp('hotelCode'),
                hotelName: this.getProp('hotelName'),
            }
        }

        set hotelCode(value) {
            if (value) {
                this.setProp('hotelCode', value.hotelCode)
                this.setProp('hotelName', value.hotelName)
            } else {
                this.setProp('hotelCode', null)
                this.setProp('hotelName', null)
            }
        }

        get ownProduct() {
            return transfersRuntimeStore.ownProduct(this.product)
        }
    }
</script>
