<template>
    <search-form-layout :popup="popup" title="cruises.cruises" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="10">
                        <v-row>
                            <v-col md="4" xl="3" cols="12">
                                <city-autocomplete
                                    v-model="departureCity"
                                    label="cruises.departure_location"
                                    single-line
                                    filled
                                    :product-type="'CRUISE'"
                                />
                            </v-col>
                            <v-col md="4" xl="3" cols="12">
                                <city-autocomplete
                                    v-model="destinationCity"
                                    label="cruises.destination_location"
                                    single-line
                                    filled
                                    :product-type="'CRUISE'"
                                    :rules="[
                                        v =>
                                            !!v || `${$t('cruises.destination_location')} ${$t('validation.required')}`,
                                    ]"
                                />
                            </v-col>
                            <v-col md="4" cols="12">
                                <daterange-m
                                    label=""
                                    :start-date.sync="startDate"
                                    :end-date.sync="endDate"
                                    product="cruises"
                                />
                            </v-col>
                            <v-col md="4" xl="2" cols="12">
                                <tourists-select-menu
                                    :_adults.sync="adults"
                                    :_children-ages.sync="childrenAges"
                                    :max-total="4"
                                    token="guest"
                                />
                            </v-col>
                            <v-col md="2" cols="12">
                                <v-text-field
                                    v-model="durationFrom"
                                    :label="$t('cruises.duration_from')"
                                    single-line
                                    filled
                                    type="number"
                                    background-color="white"
                                    hide-details
                                />
                            </v-col>
                            <v-col md="2" cols="12">
                                <v-text-field
                                    v-model="durationTo"
                                    :label="$t('cruises.duration_to')"
                                    single-line
                                    filled
                                    type="number"
                                    background-color="white"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="2" cols="12" class="hidden-sm-and-down">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :suppliers="suppliers"
                :options="['internalSuppliers', 'externalSuppliers', 'supplierId']"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import {authStore, cruisesRuntimeStore, cruisesStore, persistentStore} from '@/store'
    import SearchFormLayout from '@/components/parts/SearchFormLayout'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '@/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '@/components/snippets/AdditionalSearchOptions/AdditionalOptionsWrapper'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            DaterangeM,
            TouristsSelectMenu,
            SearchFormLayout,
            CityAutocomplete,
            AdditionalOptionsWrapper,
        },
    })
    export default class CruisesSearchForm extends AdditionalSearchOptionsMixin {
        @Prop({default: false}) popup

        valid = false
        departureCity = {}
        destinationCity = {}
        adults = 1
        childrenAges = []
        startDate = null
        endDate = null
        durationFrom = null
        durationTo = null
        suppliers = []

        newQuery() {
            const query = {
                startDate: this.startDate,
                endDate: this.endDate,
                adults: this.adults,
                childrenAges: this.childrenAges,
                convertToCurrency: persistentStore.currency,
                ...(this.departureCity?.id && {departureCityId: this.departureCity.id}),
                destinationCityId: this.destinationCity.id,
                ...(this.durationFrom && {durationFrom: this.durationFrom}),
                ...(this.durationTo && {durationTo: this.durationTo}),
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        async search() {
            this.$emit('close-popup')

            cruisesRuntimeStore.SET_DEPARTURE_CITY(this.departureCity)
            cruisesRuntimeStore.SET_DESTINATION_CITY(this.destinationCity)

            const query = this.newQuery()
            try {
                await this.$router.push({name: 'cruises', query})

                // eslint-disable-next-line no-empty
            } catch (e) {}
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async load() {
            this.departureCity =
                cruisesStore.searchRequest.departureCityId && Object.keys(cruisesRuntimeStore.departureCity).length
                    ? cruisesRuntimeStore.departureCity
                    : null
            this.destinationCity =
                cruisesStore.searchRequest.destinationCityId && Object.keys(cruisesRuntimeStore.destinationCity).length
                    ? cruisesRuntimeStore.destinationCity
                    : null

            this.startDate = cruisesStore.searchRequest.startDate
            this.endDate = cruisesStore.searchRequest.endDate
            this.durationFrom = cruisesStore.searchRequest.durationFrom
            this.durationTo = cruisesStore.searchRequest.durationTo
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : cruisesStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp
                ? []
                : [...cruisesStore.searchRequest.childrenAges]
            if (this.isTO1) {
                this.suppliers = (await this.$api.suppliers.get({productType: 'insurance'}))?.suppliers
            }
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        get isTO1() {
            return authStore.isTO1
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }
    }
</script>
