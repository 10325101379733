var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.offer)?_c('order-summary-layout',{attrs:{"product-store":_vm.productStore,"total-price":_vm.totalPrice},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"text-h6"},[_vm._v("\n            "+_vm._s(_vm.$t('flights.flight_details'))+"\n        ")]),_vm._v(" "),_vm._l((_vm.offer.itinerary),function(itinerary,index){return _c('flight-itinerary',{key:index,attrs:{"itinerary":itinerary}})}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"info","small":""}},on),[_vm._v("\n                    "+_vm._s(_vm.$t('flights.view_details'))+"\n                ")])]}}],null,false,514551939),model:{value:(_vm.detailModal),callback:function ($$v) {_vm.detailModal=$$v},expression:"detailModal"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-icon',{on:{"click":function($event){_vm.detailModal = false}}},[_vm._v("mdi-close")])],1),_vm._v(" "),_c('flight-detail',{attrs:{"offer":_vm.offer}})],1)],1),_vm._v(" "),_c('fare-rules',{attrs:{"offer-key":_vm.offer.offerKey}}),_vm._v(" "),_vm._t("summary"),_vm._v(" "),_c('flight-conditions-label',{staticClass:"mt-3 text-caption",attrs:{"offer-key":_vm.offer.offerKey,"price":_vm.offer.price,"refundable":_vm.offer.refundable,"regular":_vm.offer.regular},on:{"conditions":_vm.showConditionsInfo}}),_vm._v(" "),(_vm.extraBaggageCount)?_c('div',{staticClass:"d-flex mt-5"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('flights.extra_baggage')))]),_vm._v(" "),_c('strong',{staticClass:"ml-auto pl-2"},[_vm._v("\n                "+_vm._s(_vm.extraBaggageCount)+" "+_vm._s(_vm.$tc('flights.baggage_item', _vm.extraBaggageCount))+"\n                "),(_vm.extraBaggagePrice.amount)?[_vm._v("("+_vm._s(_vm._f("price")(_vm.extraBaggagePrice))+")")]:_vm._e()],2)]):_vm._e(),_vm._v(" "),(_vm.seatsCount)?_c('div',{staticClass:"d-flex mt-5"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('flights.selected_seats')))]),_vm._v(" "),_c('strong',{staticClass:"ml-auto pl-2"},[_vm._v("\n                "+_vm._s(_vm.seatsCount)+"\n                "+_vm._s(_vm.$tc('flights.seat', _vm.seatsCount))+"\n                "+_vm._s(_vm.seats.map(function (ref) {
	var rowNumber = ref.rowNumber;
	var seatNumber = ref.seatNumber;

	return ("" + rowNumber + seatNumber);
}).join(', '))+"\n                "),(_vm.seatsPrice.amount)?[_vm._v("("+_vm._s(_vm._f("price")(_vm.seatsPrice))+")")]:_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('conditions-modal',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('flight-conditions-modal-info')]},proxy:true}],null,false,902215958)})]},proxy:true},{key:"violatedPolicies",fn:function(){return [(_vm.corporatePolicyViolated)?_c('corporate-policy-violation',{attrs:{"policies":_vm.violatedPolicies}}):_vm._e()]},proxy:true}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }