<template>
    <v-row>
        <v-col>
            <v-menu
                v-model="showStartDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on}">
                    <v-text-field
                        ref="startDateInput"
                        :value="startDateInput"
                        :label="startDateLabel"
                        :clearable="clearable"
                        :single-line="singleLine"
                        :outlined="outlined"
                        hide-details
                        :persistent-placeholder="persistentPlaceholder"
                        :dense="dense"
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :type="startDateType"
                        :min="minStartDate"
                        :max="maxStartDate"
                        @change="onStartDateInput"
                        @focus="onStartDateFocus(on, $event)"
                        @blur="onStartDateBlur"
                        @click:clear="onClearStartDate"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="startDate"
                    :locale="locale"
                    :min="minStartDate"
                    :max="maxStartDate"
                    :show-current="true"
                    :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
                    :no-title="noTitle"
                    @change="onChangeStartDate"
                />
            </v-menu>
        </v-col>
        <v-col>
            <v-menu
                v-model="showEndDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on}">
                    <v-text-field
                        ref="endDateInput"
                        :value="endDateInput"
                        :label="endDateLabel"
                        :clearable="clearable"
                        :single-line="singleLine"
                        :outlined="outlined"
                        :persistent-placeholder="persistentPlaceholder"
                        hide-details
                        :dense="dense"
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :type="endDateType"
                        :min="minEndDate"
                        :max="maxEndDate"
                        @change="onEndDateInput"
                        @focus="onEndDateFocus(on, $event)"
                        @blur="onEndDateBlur"
                        @click:clear="onClearEndDate"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="endDate"
                    :locale="locale"
                    :min="minEndDate"
                    :max="maxEndDate"
                    :show-current="true"
                    :first-day-of-week="$config.firstDayOfWeek ? $config.firstDayOfWeek - 1 : firstDayOfWeekByLocale"
                    :no-title="noTitle"
                    @change="showEndDatePicker = false"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'

    @Component
    export default class Daterange extends Vue {
        @Prop({default: () => this.$t('check_in')}) startDateLabel
        @Prop({default: () => this.$t('check_out')}) endDateLabel
        @Prop({default: false}) clearable
        @Prop({default: true}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: false}) noTitle
        @Prop({default: false, type: Boolean}) persistentPlaceholder
        @Prop({default: false, type: Boolean}) dense
        @PropSync('_startDate') startDate
        @PropSync('_endDate') endDate

        showStartDatePicker = false
        showEndDatePicker = false
        startDateType = 'text'
        startDateFocused = false
        startDateCleared = false
        startDateTmp = null
        endDateType = 'text'
        endDateFocused = false
        endDateCleared = false
        endDateTmp = null

        checkEndDate(newStartDate) {
            if (
                this.$dateFns.parseISO(newStartDate) >= this.$dateFns.parseISO(this.endDate) ||
                this.nights(this.endDate, newStartDate) > 60
            ) {
                this.endDate = this.addDays(newStartDate, this.nights(this.startDate, this.endDate))
            }
        }

        onChangeStartDate(newStartDate) {
            this.showStartDatePicker = false
            this.checkEndDate(newStartDate)
            this.$refs.endDateInput.focus()
            this.showEndDatePicker = true
        }

        onStartDateInput(val) {
            this.startDateTmp = val
        }

        onEndDateInput(val) {
            this.endDateTmp = val
        }

        onStartDateFocus(on, $event) {
            if (this.startDateCleared) {
                this.startDateCleared = false
                return
            }

            this.startDateTmp = this.startDate
            this.showEndDatePicker = false
            if (this.startDateType === 'text') {
                this.startDateFocused = true
                this.startDateType = 'date'
                on.click($event)
            }
        }

        onStartDateBlur() {
            if (this.startDateFocused) {
                this.startDateFocused = false
                return
            }
            const newStartDate = this.$dateFns.parseISO(this.startDateTmp)
            if (
                (!this.minStartDate || newStartDate >= this.$dateFns.parseISO(this.minStartDate)) &&
                (!this.maxStartDate || newStartDate <= this.$dateFns.parseISO(this.maxStartDate))
            ) {
                this.checkEndDate(this.startDateTmp)
                this.startDate = this.startDateTmp
            }
            this.startDateType = 'text'
        }

        onEndDateFocus(on, $event) {
            if (this.endDateCleared) {
                this.endDateCleared = false
                return
            }

            this.endDateTmp = this.endDate
            this.showStartDatePicker = false
            if (this.endDateType === 'text') {
                this.endDateFocused = true
                this.endDateType = 'date'
                on.click($event)
            }
        }

        onEndDateBlur() {
            if (this.endDateFocused) {
                this.endDateFocused = false
                return
            }
            const newEndDate = this.$dateFns.parseISO(this.endDateTmp)
            if (
                (!this.minEndDate || newEndDate >= this.$dateFns.parseISO(this.minEndDate)) &&
                (!this.maxEndDate || newEndDate <= this.$dateFns.parseISO(this.maxEndDate))
            ) {
                this.endDate = this.endDateTmp
            }
            this.endDateType = 'text'
        }

        onClearStartDate() {
            this.startDateCleared = true
            this.startDateTmp = null
            this.startDate = null
        }

        onClearEndDate() {
            this.endDateCleared = true
            this.endDateTmp = null
            this.endDate = null
        }

        addDays(dateStr, days) {
            return this.$dateFns.format(this.$dateFns.addDays(this.$dateFns.parseISO(dateStr), days))
        }

        nights(startDate, endDate) {
            return this.$dateFns.differenceInDays(this.$dateFns.parseISO(endDate), this.$dateFns.parseISO(startDate))
        }

        get firstDayOfWeekByLocale() {
            return this.$dateFns.localeOptions[this.locale].weekStartsOn
        }

        get startDateInput() {
            return this.startDateType === 'text'
                ? this.$options.filters.dateShortFormat(this.startDate)
                : this.startDateTmp
        }

        get endDateInput() {
            return this.endDateType === 'text' ? this.$options.filters.dateShortFormat(this.endDate) : this.endDateTmp
        }

        get locale() {
            return this.$i18n.locale
        }

        get minStartDate() {
            return this.$dateFns.format(new Date())
        }

        get maxStartDate() {
            return undefined
        }

        get minEndDate() {
            return this.addDays(this.startDate, 1)
        }

        get maxEndDate() {
            return this.addDays(this.startDate, 60)
        }
    }
</script>
