<template>
    <div>
        <div v-if="showDate">
            {{ date | dateFormat }}
            <span v-if="isReturnTransfer"> - {{ dateReturn | dateFormat }}</span>
        </div>
        <div v-if="showDates">
            {{ dates }}
            <span v-if="showNights">({{ nights }} {{ $tc('night', nights) }})</span>
        </div>
        <div v-if="showFlightLocations" class="mb-2">
            <div
                v-for="(location, index) in flightLocations"
                :key="location"
                :class="{'mb-1': index < flightLocations.length - 1}"
            >
                {{ location }}
            </div>
        </div>
        <div v-if="showTourists">
            <span v-if="!showDaysCount">{{ `${touristsTotal} ${$tc(touristsLabel, touristsTotal)}` }}</span>
            <span v-else>
                {{ `${touristsTotal} ${$tc(touristsLabel, touristsTotal)}, ${daysCount} ${$tc('day', daysCount)}` }}
            </span>
        </div>
        <div v-if="showDuration">
            {{ duration }}
        </div>
        <div v-if="pickUpDateTime && dropOffDateTime">
            {{ pickUpDateTime | dateTimeFormat }} - {{ dropOffDateTime | dateTimeFormat }}
        </div>
        <div v-if="showPassengers && numberOfPassengers">
            {{ $t('carsRent.number_of_passengers') }}
            {{ numberOfPassengers }}
        </div>
        <div v-if="pickUpAddress || pickUpName" class="mt-1 mb-1">
            <p v-if="pickUpName" class="mb-0 font-weight-bold">
                {{ pickUpName }}
            </p>
            <p v-if="pickUpAddress" class="mb-0">
                {{ `${$t('carsRent.pick-up_address')}: ${pickUpAddress}` }}
            </p>
        </div>
        <div v-if="dropOffAddress || dropOffName">
            <p v-if="dropOffName" class="mb-0 font-weight-bold">
                {{ dropOffName }}
            </p>
            <p v-if="dropOffAddress" class="mb-0">
                {{ `${$t('carsRent.drop-off_address')}: ${dropOffAddress}` }}
            </p>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {flightsRuntimeStore, hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class SearchSummaryContent extends Vue {
        @Prop({required: true}) searchRequest
        @Prop() searchRequestReturnTransfer
        @Prop() isReturnTransfer
        @Prop({default: false, type: Boolean}) showDate
        @Prop({default: false, type: Boolean}) showDates
        @Prop({default: false, type: Boolean}) showFlightLocations
        @Prop({default: false, type: Boolean}) showNights
        @Prop({default: false, type: Boolean}) showDuration
        @Prop({default: false, type: Boolean}) showDaysCount
        @Prop({default: false, type: Boolean}) showTourists
        @Prop({default: 'passenger', type: String}) touristsLabel
        @Prop() pickUpLocation
        @Prop() dropOffLocation
        @Prop({default: false, type: Boolean}) showPassengers
        @Prop() dateProp

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        get start() {
            return this.searchRequest.startDate || this.searchRequest.startDateFrom
        }

        get end() {
            return this.searchRequest.endDate || this.searchRequest.startDateTo
        }

        get dates() {
            return `${this.formattedDate(this.start)} - ${this.formattedDate(this.end)}`
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get date() {
            return (
                this.dateProp ||
                this.searchRequest.dateTime ||
                this.searchRequest.startDate ||
                this.searchRequest.startDateFrom
            )
        }

        get flightLocations() {
            return flightsRuntimeStore.routes.map(({departure, arrival, date}) => {
                return `${this.$options.filters.dateFormat(date)} | ${departure.cityName}, ${departure.countryName} (${
                    departure.iataCode
                }) - ${arrival.cityName}, ${arrival.countryName} (${arrival.iataCode})`
            })
        }

        get daysCount() {
            return this.searchRequest.daysCount
        }

        get dateReturn() {
            return this.searchRequestReturnTransfer.dateTime
        }

        get touristsTotal() {
            if (this.searchRequest.rooms) return hotelsRuntimeStore.searchRequestTouristsTotal(this.searchRequest)

            return this.searchRequest.adults + (this.searchRequest.childrenAges?.length || 0)
        }

        get pickUpDateTime() {
            return this.searchRequest.pickUpDateTime
        }

        get dropOffDateTime() {
            return this.searchRequest.dropOffDateTime
        }

        get numberOfPassengers() {
            return this.searchRequest?.passengers ? Number(this.searchRequest.passengers) : ''
        }

        get pickUpName() {
            return this.pickUpLocation?.name ? this.pickUpLocation.name : ''
        }

        get dropOffName() {
            return this.dropOffLocation?.name ? this.dropOffLocation.name : ''
        }

        get pickUpAddress() {
            return this.pickUpLocation?.address ? this.pickUpLocation.address : ''
        }

        get dropOffAddress() {
            return this.dropOffLocation?.address ? this.dropOffLocation.address : ''
        }

        get nightsFrom() {
            return this.searchRequest.durationFrom - 1
        }

        get nightsTo() {
            return this.searchRequest.durationTo - 1
        }

        get duration() {
            return `${this.$t('tours.from')} ${this.nightsFrom} ${this.$tc('night', this.nightsFrom)} -
                ${this.$t('tours.to')} ${this.nightsTo} ${this.$tc('night', this.nightsTo)}`
        }
    }
</script>
