<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import ExtraServicesForm from '~src/components/booking/forms/extraServicesForm.src'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'

    @Component
    export default class HotelExtraServicesForm extends Mixins(ExtraServicesForm, HotelStoreMixin) {
        @Prop() roomIndex

        get extraServices() {
            const extraServices = this.productStore.extraServices
            if (!extraServices[this.roomIndex]) {
                return []
            }
            return extraServices[this.roomIndex].filter(extraService => !extraService.mandatory) || []
        }

        get mandatoryExtraServices() {
            const extraServices = this.productStore.extraServices
            if (!extraServices[this.roomIndex]) {
                return []
            }
            return extraServices[this.roomIndex].filter(extraService => extraService.mandatory) || []
        }

        setExtraService(data) {
            const type = `extra-service-${this.roomIndex}-${data.service.serviceRPH}`
            if (!data.operation) {
                this.productStore.DELETE_EXTRA_SERVICE(type)
            } else {
                this.productStore.SET_BOOKING_EXTRA_SERVICE({
                    type,
                    roomIndex: this.roomIndex,
                    name: data.service.name,
                    serviceRPH: data.service.serviceRPH,
                    quantity: parseInt(data.qty),
                    price: data.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT'),
                    mandatory: data.service.mandatory,
                })
            }
            this.$emit('updateExtraServices')
        }
    }
</script>
