<template>
    <loading-page-layout :loaded="loaded">
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
        <portal v-if="isMobile" to="under-header">
            <cruise-info-mobile-tabs v-model="mobileTabs" />
        </portal>
        <v-container fluid>
            <cruises-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" :product="product" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <div class="d-flex flex-column mb-2">
                        <h1 v-if="product.name" class="title">
                            {{ product.name }}
                        </h1>
                        <supplier-name-label v-if="isTO1" :supplier-name="product.supplierName" />
                    </div>
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="uniqueImages"
                    />
                    <template v-if="!$breakpoint.smAndDown">
                        <cruise-grouped-offers
                            :product="filteredProduct"
                            full-card
                            class="mt-4"
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, ...$event]"
                            @set-filters="setFilters"
                        />
                        <template v-if="groupOffersBy === 'none'">
                            <cruise-offer-card
                                v-for="offer in limitedOffers"
                                :key="offer.offerKey"
                                :offer="offer"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, ...$event]"
                            />
                            <v-list-item
                                v-if="
                                    filteredProduct &&
                                    filteredProduct.offers &&
                                    expanded < filteredProduct.offers.length
                                "
                                dense
                                class="d-flex justify-center"
                                @click.stop="expanded += expandStep"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-list-item>
                        </template>
                    </template>
                    <div v-if="product.duration">
                        <div class="font-weight-bold mt-2">
                            {{ $t('duration') }}
                        </div>
                        <span>
                            {{ `${product.duration} ${$tc('day', product.duration)}` }}
                        </span>
                    </div>
                    <template v-if="showShortDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.short`) }}
                        </div>
                        <div v-html="shortDescription" />
                    </template>
                    <template v-if="fullDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.general`) }}
                        </div>
                        <div v-html="fullDescription" />
                    </template>
                    <div v-if="itineraryPoints.length" class="d-flex flex-column">
                        <div class="font-weight-bold my-2">
                            {{ $t('itinerary') }}
                        </div>
                        <ul class="d-flex flex-column">
                            <li v-for="(point, i) in itineraryPoints" :key="i">
                                {{ point.cityName }}
                            </li>
                        </ul>
                        <!--                        <v-data-table-->
                        <!--                            style="border: thin solid rgba(0, 0, 0, 0.12)"-->
                        <!--                            :headers="headers"-->
                        <!--                            :items="itineraryPoints"-->
                        <!--                            :items-per-page="5"-->
                        <!--                        />-->
                        <v-divider class="my-4" />
                    </div>

                    <template v-if="shipName">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.ship_name`) }}
                        </div>
                        <span>
                            {{ shipName }}
                        </span>
                    </template>
                    <template v-if="shipType">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.ship_type`) }}
                        </div>
                        <span>
                            {{ $t(`cruises.ship_types.${shipType}`) }}
                        </span>
                    </template>
                    <template v-if="cruiseLine">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.cruise_line`) }}
                        </div>
                        <span>
                            {{ cruiseLine }}
                        </span>
                    </template>

                    <template v-if="yearBuilt">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.year`) }}
                        </div>
                        <span>
                            {{ yearBuilt }}
                        </span>
                    </template>
                    <template v-if="length">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.length`) }}
                        </div>
                        <span>
                            {{ length }}
                        </span>
                    </template>
                    <template v-if="width">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.width`) }}
                        </div>
                        <span>
                            {{ width }}
                        </span>
                    </template>
                    <template v-if="decksCount">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.decks_count`) }}
                        </div>
                        <span>
                            {{ decksCount }}
                        </span>
                    </template>
                    <template v-if="cabinsCount">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.cabins_count`) }}
                        </div>
                        <span>
                            {{ cabinsCount }}
                        </span>
                    </template>
                    <template v-if="maxPassengers">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.max_passengers`) }}
                        </div>
                        <span>
                            {{ maxPassengers }}
                        </span>
                    </template>
                    <template v-if="shipDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.ship_description`) }}
                        </div>
                        <div v-html="shipDescription" />
                    </template>
                    <template v-if="deckDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`cruises.decks_description`) }}
                        </div>
                        <div v-html="deckDescription" />
                    </template>
                    <template v-if="descriptions && descriptions.length">
                        <strong>{{ $t('description') }} </strong><br />
                        <div v-for="(desc, descIndex) in descriptions" :key="descIndex" v-html="desc" />
                    </template>
                    <template v-if="servicesDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`services`) }}
                        </div>
                        <div v-html="servicesDescription" />
                    </template>
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <cruises-search-summary :search-request="searchRequest" :dates="dates" />
                    <template v-if="$breakpoint.smAndDown">
                        <cruise-grouped-offers
                            :product="filteredProduct"
                            full-card
                            class="mt-4"
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, ...$event]"
                            @set-filters="setFilters"
                        />
                        <template v-if="groupOffersBy === 'none'">
                            <cruise-offer-card
                                v-for="offer in limitedOffers"
                                :key="offer.offerKey"
                                :offer="offer"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, ...$event]"
                            />
                            <v-list-item
                                v-if="
                                    filteredProduct &&
                                    filteredProduct.offers &&
                                    expanded < filteredProduct.offers.length
                                "
                                dense
                                class="d-flex justify-center"
                                @click.stop="expanded += expandStep"
                            >
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-list-item>
                        </template>
                    </template>
                    <div v-if="!$breakpoint.smAndDown" style="position: relative">
                        <client-only>
                            <cruise-map
                                :points="mapPoints"
                                style="position: relative; max-height: 70vh; top: 0 !important"
                                class="disable-top"
                            />
                        </client-only>
                    </div>
                </v-col>
                <v-col v-if="isMobile && mobileTabs === 2" :cols="12">
                    <client-only>
                        <cruise-map :points="mapPoints" />
                    </client-only>
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal />
        <cruise-offer-info-modal />
    </loading-page-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import LoadingPageLayout from '@/components/LoadingPageLayout'
    import SearchPageMixin from '@/components/mixins/SearchPageMixin'
    import InfoPageMixin from '@/components/mixins/InfoPageMixin'
    import SearchPageAuthMixin from '@/components/mixins/SearchPageAuthMixin'
    import CruisesPageMixin from '@/components/cruises/mixins/CruisesPageMixin.vue'
    import CruisesStoreMixin from '@/mixins/CruisesStoreMixin.vue'
    import CruisesBreadcrumbs from '@/components/cruises/CruisesBreadcrumbs'
    import CruiseOfferCard from '@/components/cruises/info/CruiseOfferCard'
    import CruisesSearchSummary from '@/components/cruises/search/CruisesSearchSummary'
    import CruisesMap from '@/components/cruises/search/CruiseMap'
    import CruiseMap from '@/components/cruises/search/CruiseMap'
    import {handleNewLine} from '@/utils/filters'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import {authStore} from '@/utils/store-accessor'
    import TransferMap from '@/components/transfers/search/TransferMap'
    import CruiseOfferInfoModal from '@/components/cruises/info/CruiseOfferInfoModal'
    import CruiseInfoMobileTabs from '@/components/cruises/info/snippets/CruiseInfoMobileTabs'
    import CruiseGroupedOffers from '@/components/cruises/search/offers/CruiseGroupedOffers'
    import filterCruiseOffers from '@/filters/cruisesFilter'

    @Component({
        components: {
            CruiseGroupedOffers,
            CruiseInfoMobileTabs,
            CruiseOfferInfoModal,
            TransferMap,
            SupplierNameLabel,
            CruiseMap,
            CruisesSearchSummary,
            CruiseOfferCard,
            CruisesBreadcrumbs,
            LoadingPageLayout,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            MobileInfoPageTabs,
            ConditionsModal,
            ProductGallery,
            ProductGalleryMobile,
            CruisesMap,
        },
        layout: 'blank',
    })
    export default class CruisePage extends mixins(
        CruisesPageMixin,
        CruisesStoreMixin,
        SearchPageMixin,
        InfoPageMixin,
        SearchPageAuthMixin
    ) {
        offersInfo = []
        expanded = 5
        expandStep = 5
        mapPoints = []
        itineraryPoints = []
        headers = [
            {text: 'Days', value: 'cityName', sortable: false},
            {text: 'City', value: 'cityName', sortable: false},
        ]
        selectedFilters = {
            availability: [],
            mealTypeName: [],
            cabinType: [],
        }

        @Watch('offersInfo', {deep: true})
        async onOffersInfoLoad() {
            if (this.offersInfo[0]) {
                this.itineraryPoints = [this.departurePoint, ...this.offersInfo[0].itineraryPoints]
            }

            if (this.itineraryPoints?.length) {
                const destinationCities = await Promise.all(
                    this.itineraryPoints.map(p => {
                        return (async () => {
                            try {
                                const locations = await this.$api.locations.get({
                                    id: p.cityId,
                                    countryId: p.countryId,
                                    limitCities: 1,
                                })

                                return locations?.cities[0]
                            } catch (e) {
                                return null
                            }
                        })()
                    })
                )

                this.mapPoints = [...destinationCities]
            }
        }

        get groupOffersBy() {
            return this.productStore.groupOffersBy
        }

        get departurePoint() {
            return this.offersInfo[0]?.departurePoint
        }

        get shipName() {
            return this.offersInfo[0]?.shipName
        }

        get cruiseLine() {
            return this.offersInfo[0]?.cruiseLine
        }

        get yearBuilt() {
            return this.offersInfo[0]?.yearBuilt
        }

        get maxPassengers() {
            return this.offersInfo[0]?.maxPassengers
        }

        get shipType() {
            return this.offersInfo[0]?.shipType
        }

        get length() {
            return this.offersInfo[0]?.length
        }

        get width() {
            return this.offersInfo[0]?.width
        }

        get cabinsCount() {
            return this.offersInfo[0]?.cabinsCount
        }

        get decksCount() {
            return this.offersInfo[0]?.decksCount
        }

        get deckDescription() {
            return this.offersInfo[0]?.deckDescription
        }

        get cruiseServices() {
            return this.offersInfo[0]?.cruiseServices
        }

        setFilters(filter) {
            this.selectedFilters[filter.key] = filter.value ? filter.value : []
        }

        get filteredProduct() {
            return this.product ? filterCruiseOffers([this.product], this.selectedFilters)[0] : null
        }

        get limitedOffers() {
            if (!this.filteredProduct?.offers) {
                return []
            }

            let offers = [...this.filteredProduct.offers]

            return offers.slice(0, this.expanded)
        }

        get fullDescription() {
            return handleNewLine(this.offersInfo.find(({fullDescription}) => fullDescription)?.fullDescription)
        }

        get shipDescription() {
            return handleNewLine(
                this.offersInfo.find(({fullDescription}) => fullDescription)?.shipDescriptions?.join('')
            )
        }

        get servicesDescription() {
            return handleNewLine(
                this.offersInfo.find(({servicesDescription}) => servicesDescription)?.servicesDescription
            )
        }

        get shortDescription() {
            return handleNewLine(this.offersInfo.find(({shortDescription}) => shortDescription)?.shortDescription)
        }

        get descriptions() {
            return this.offersInfo.find(({descriptions}) => descriptions.length)?.descriptions
        }

        get showShortDescription() {
            return (
                this.shortDescription &&
                (!this.fullDescription || this.fullDescription.indexOf(this.shortDescription) === -1)
            )
        }

        get uniqueImages() {
            return this.images.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i)
        }

        get city() {
            return this.productRuntimeStore.departureCity
        }

        get dates() {
            return `${this.formattedDate(this.searchRequest.startDate)} - ${this.formattedDate(
                this.searchRequest.endDate
            )}`
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }
    }
</script>

<style scoped lang="scss">
    .disable-top {
        top: 0 !important;
    }
</style>
