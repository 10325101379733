<template>
    <v-card v-if="addOns.length" class="elevation-0" tile>
        <v-card-title>
            <v-icon left color="primary">mdi-star</v-icon>
            {{ $t('add_ons') }}
        </v-card-title>
        <component
            :is="productComponent(addOn.productType)"
            v-for="(addOn, index) in addOns"
            :key="index"
            :product="addOn.product[0]"
            :offer="addOn.product[0]"
            :search-request="{}"
            :hide-price="addOn.free"
            is-add-on
        >
            <template v-slot:price="{offer, offerKey}">
                <add-on-price-button
                    :offer="offer"
                    :offer-key="offerKey"
                    :selected-offer-keys="selectedOfferKeys"
                    :free="addOn.free"
                    :disabled="disabled"
                    @select="select(offerKey, addOn.product[0].offers)"
                />
            </template>
        </component>
    </v-card>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import {EXCURSION} from '@/utils/activities/activities-const'
    import AddOnPriceButton from '@/components/booking/addOns/AddOnPriceButton.vue'
    import {runtimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'
    import {TRANSFER} from '@/utils/transfers/transfers-const'
    import {ACCOMMODATION, HOTEL} from '@/utils/hotels/hotels-const'

    @Component({
        components: {
            AddOnPriceButton,
            ActivityCard: () => import('@/components/activities/search/offers/ActivityCard.vue'),
            ActivityCardMobile: () => import('@/components/activities/search/offers/ActivityCardMobile.vue'),
            TransferCard: () => import('~src/components/transfers/search/offers/transferCard.src'),
            TransferCardMobile: () => import('@/components/transfers/search/offers/TransferCardMobile.vue'),
            HotelOfferCard: () => import('@/components/search/offers/HotelOfferCard.vue'),
            HotelOfferCardMobile: () => import('@/components/search/offers/HotelOfferCardMobile.vue'),
        },
    })
    export default class AddOns extends Vue {
        @Prop({required: true}) offerKey
        @Prop({required: true}) initialTouristBookingFields
        @Prop({required: true}) disabled
        @Prop({required: true}) productType
        @Prop({required: true}) offer

        addOns = []
        selectedOfferKeys = []
        touristBookingFields = {}
        cities = []
        airports = []
        trainStations = []

        async mounted() {
            this.addOns = (await this.$api.addOns.get({offerKey: this.offerKey})).filter(({productType}) =>
                [EXCURSION, TRANSFER, ACCOMMODATION].includes(productType)
            )
        }

        @Emit()
        async select(offerKey, offers) {
            const index = this.selectedOfferKeys.findIndex(selectedOfferKey => selectedOfferKey === offerKey)
            if (index === -1) {
                const index = this.selectedOfferKeys.findIndex(selectedOfferKey =>
                    offers.map(({offerKey}) => offerKey).includes(selectedOfferKey)
                )
                if (index === -1) {
                    this.selectedOfferKeys.push(offerKey)
                } else {
                    this.selectedOfferKeys.splice(index, 1, offerKey)
                }
                if (!this.touristBookingFields[offerKey]) {
                    try {
                        this.touristBookingFields[offerKey] = await this.$api.touristBookingFields.get({offerKey})
                        //TODO Remove passport type???
                        this.touristBookingFields[offerKey].bookingFields.PASSPORT_TYPE = {
                            mainTouristStatus: 'DISABLED',
                            allTouristsStatus: 'DISABLED',
                            childrenStatus: 'DISABLED',
                        }
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }
            } else {
                this.selectedOfferKeys.splice(index, 1)
            }
            const merge = (a, b) => {
                if (a === 'MANDATORY' || b === 'MANDATORY') {
                    return 'MANDATORY'
                } else if (a === 'OPTIONAL' || b === 'OPTIONAL') {
                    return 'OPTIONAL'
                } else {
                    return 'DISABLED'
                }
            }
            const touristBookingFields = this.selectedOfferKeys.reduce((touristBookingFields, offerKey) => {
                if (this.touristBookingFields[offerKey]) {
                    for (const key of Object.keys(touristBookingFields.bookingFields)) {
                        const a = touristBookingFields.bookingFields[key],
                            b = this.touristBookingFields[offerKey].bookingFields[key]
                        if (a && b) {
                            touristBookingFields.bookingFields[key] = {
                                mainTouristStatus: merge(a.mainTouristStatus, b.mainTouristStatus),
                                allTouristsStatus: merge(a.mainTouristStatus, b.mainTouristStatus),
                                childrenStatus: merge(a.mainTouristStatus, b.mainTouristStatus),
                            }
                        }
                    }
                    const a = touristBookingFields.supplierConfigDetails?.guestsInformationRequiredType,
                        b = this.touristBookingFields[offerKey].supplierConfigDetails?.guestsInformationRequiredType
                    if (a === 'ALL' || b === 'ALL') {
                        touristBookingFields.supplierConfigDetails.guestsInformationRequiredType = 'ALL'
                    } else if (a === 'MIXED' || b === 'MIXED') {
                        touristBookingFields.supplierConfigDetails.guestsInformationRequiredType = 'MIXED'
                    }
                }
                return touristBookingFields
            }, clone(this.initialTouristBookingFields))
            runtimeStore.SET_TOURIST_BOOKING_FIELDS(touristBookingFields)
            const selectedAddOns = []
            for (const addOn of this.addOns) {
                const offers = addOn.product[0].offers.filter(offer => this.selectedOfferKeys.includes(offer.offerKey))
                const rooms = addOn.product[0].rooms
                    ? addOn.product[0].rooms.filter(room =>
                          this.selectedOfferKeys.includes(room.groupedOffers[0].offerKey)
                      )
                    : undefined
                if (offers.length) {
                    const selectedAddOn = clone(addOn)
                    selectedAddOn.product[0].offers = offers
                    if (rooms) {
                        selectedAddOn.product[0].rooms = rooms
                    }
                    selectedAddOn.touristBookingFields = this.touristBookingFields[offers[0].offerKey]
                    if (selectedAddOn.productType === TRANSFER) {
                        const point = async info => {
                            const getCity = async id => {
                                try {
                                    const location = this.cities.find(location => location.id === id)
                                    if (location) {
                                        return location
                                    } else {
                                        const {
                                            cities: [location],
                                        } = await this.$api.locations.get({id})
                                        this.cities.push(location)
                                        return location
                                    }
                                } catch (e) {
                                    return {}
                                }
                            }
                            const getTrainStation = async iataCode => {
                                try {
                                    const location = this.trainStations.find(location => location.iataCode === iataCode)
                                    if (location) {
                                        return location
                                    } else {
                                        const [location] = await this.$api.trainStations.get({iataCode})
                                        this.trainStations.push(location)
                                        return location
                                    }
                                } catch (e) {
                                    return {}
                                }
                            }
                            const getAirport = async iataCode => {
                                try {
                                    const location = this.airports.find(location => location.iataCode === iataCode)
                                    if (location) {
                                        return location
                                    } else {
                                        const [location] = await this.$api.airports.get({iataCode})
                                        this.airports.push(location)
                                        return location
                                    }
                                } catch (e) {
                                    return {}
                                }
                            }
                            if (info.type === 'AIRPORT') {
                                const {cityName, countryName, cityId, name} = await getAirport(info.code)
                                return {type: 'airport', cityName, countryName, cityId, name}
                            } else if (info.type === 'STATION') {
                                const {cityName, countryName, cityId, name} = await getTrainStation(info.code)
                                return {type: 'trainStation', cityName, countryName, cityId, name}
                            } else if (info.type === 'HOTEL') {
                                const hotelCode = this.offer?.hotelCode
                                const hotelName = hotelCode ? this.offer.name : null
                                const {name: cityName, countryName, id: cityId} = await getCity(info.cityId)
                                return {
                                    type: 'hotel',
                                    hotelCode,
                                    name: hotelName || cityName,
                                    cityName,
                                    countryName,
                                    cityId,
                                }
                            } else if (info.type === 'ADDRESS') {
                                const {countryName, id, name} = await getCity(info.cityId)
                                return {type: 'city', countryName, id, name}
                            } else if (info.type === 'MANUAL') {
                                const {latitude, longitude} = await getCity(info.cityId)
                                return {type: 'manual', latitude, longitude}
                            }
                        }
                        selectedAddOn.arrivalPoint = await point(offers[0].arrivalInfo)
                        selectedAddOn.departurePoint = await point(offers[0].departureInfo)

                        const departure = {},
                            arrival = {}
                        if (this.productType === HOTEL) {
                            if (offers[0].arrivalInfo.type === 'HOTEL') {
                                arrival.hotelName = this.offer.name
                                arrival.hotelCode = this.offer.hotelCode
                            }
                            if (offers[0].departureInfo.type === 'HOTEL') {
                                departure.hotelName = this.offer.name
                                departure.hotelCode = this.offer.hotelCode
                            }
                        }

                        //TODO Need refactoring with TransferBookingPage and total transfer booking code refactoring
                        const getType = (productType, pointType, product) => {
                            switch (productType) {
                                case 'airport':
                                    return 'airport'
                                case 'city':
                                    return product[
                                        `allowedHotels${pointType.charAt(0).toUpperCase()}${pointType.slice(1)}`
                                    ]
                                        ? 'hotel'
                                        : 'address'
                                case 'trainStation':
                                    return 'train'
                                case 'hotel':
                                    return 'hotel'
                            }
                        }
                        arrival.type = getType(selectedAddOn.arrivalPoint.type, 'arrival', selectedAddOn.product[0])
                        departure.type = getType(
                            selectedAddOn.departurePoint.type,
                            'departure',
                            selectedAddOn.product[0]
                        )
                        if (selectedAddOn.arrivalPoint.type === 'manual') {
                            Object.assign(arrival, selectedAddOn.arrivalPoint)
                        }
                        if (selectedAddOn.departurePoint.type === 'manual') {
                            Object.assign(departure, selectedAddOn.departurePoint)
                        }
                        selectedAddOn.prepareBookRequest = {
                            departure,
                            arrival,
                        }
                    }
                    selectedAddOns.push(selectedAddOn)
                }
            }
            return selectedAddOns
        }

        productComponent(productType) {
            switch (productType) {
                case ACCOMMODATION:
                    return this.$breakpoint.smAndDown ? 'HotelOfferCardMobile' : 'HotelOfferCard'
                case EXCURSION:
                    return this.$breakpoint.smAndDown ? 'ActivityCardMobile' : 'ActivityCard'
                case TRANSFER:
                    return this.$breakpoint.smAndDown ? 'TransferCardMobile' : 'TransferCard'
            }
        }
    }
</script>
