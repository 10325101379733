<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col v-if="!isProductFieldDisabled('PICK_UP_TIME')" md="2" cols="12">
                <time-field
                    v-model="time"
                    :label="productFieldLabel('transfers.pickup_time', 'PICK_UP_TIME')"
                    :rules="timeRules"
                />
            </v-col>
            <v-col
                v-if="pointHotelsObject && pointHotelsObject.hotels.length && !isProductFieldDisabled('PICK_UP_HOTEL')"
                md="4"
                cols="12"
            >
                <v-select
                    v-model="hotel"
                    :items="pointHotelsObject.hotels"
                    :label="productFieldLabel('select_hotel', 'PICK_UP_HOTEL')"
                    hide-details
                    :item-text="'hotelName'"
                    clearable
                    :rules="hotelRules"
                    :disabled="!!placeChecked"
                />
            </v-col>
            <template v-if="pointPlaceObject && !isProductFieldDisabled('PICK_UP_PLACE')">
                <v-col v-if="!isProductFieldDisabled('PICK_UP_HOTEL')" md="1" cols="12">
                    <div class="d-flex align-center full-height full-width justify-md-center">
                        <span>
                            {{ $t('or') }}
                        </span>
                    </div>
                </v-col>
                <v-col md="5" cols="12">
                    <div class="d-flex align-center full-height">
                        <v-btn icon left @click="mapModal = true">
                            <v-icon color="accent">mdi-map-marker</v-icon>
                        </v-btn>
                        <v-checkbox
                            v-model="placeChecked"
                            class="mb-2"
                            dense
                            :rules="placeRules"
                            :label="pointPlaceObject.point + productFieldLabel('', 'PICK_UP_PLACE')"
                            hide-details
                            :disabled="!!hotel"
                        />
                    </div>
                </v-col>
            </template>
        </v-row>
        <client-only>
            <v-dialog
                v-if="pointPlaceObject && pointPlaceObject.latitude && pointPlaceObject.longitude"
                v-model="mapModal"
                :max-width="500"
            >
                <map-location
                    v-if="mapModal"
                    :latitude="pointPlaceObject.latitude"
                    :longitude="pointPlaceObject.longitude"
                    :height="`500px`"
                />
            </v-dialog>
        </client-only>
    </v-form>
</template>

<script>
    import {Component, Emit, mixins, Prop, VModel} from 'nuxt-property-decorator'
    import ProductBookingFieldsMixin from '@/components/booking/mixins/ProductBookingFieldsMixin'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TimeField from '@/components/snippets/forms/TimeField'
    import MapLocation from '@/components/info/MapLocation'
    import {activitiesStore} from '@/utils/store-accessor'

    @Component({
        components: {MapLocation, TimeField},
    })
    export default class ActivityBookingForm extends mixins(ProductBookingFieldsMixin, FormValidationRulesMixin) {
        @Prop() info
        @VModel() valid

        mapModal = false

        @Emit()
        change({prop, val}) {
            return {prop, val}
        }

        get hotel() {
            return activitiesStore.prepareBookRequest.hotelPointKey
        }

        set hotel(val) {
            this.change({prop: 'hotelPointKey', val: val})
        }

        get time() {
            return activitiesStore.prepareBookRequest.startTime
        }

        set time(val) {
            this.change({prop: 'startTime', val: val})
        }

        get placeChecked() {
            return !!activitiesStore.prepareBookRequest.pickUpPlace
        }

        set placeChecked(val) {
            this.change({prop: 'pickUpPlace', val: val ? this.pointPlaceObject.point : null})
        }

        get pointPlaceObject() {
            return this.info?.departures?.find(el => !!el.latitude && !!el.longitude)
        }

        get pointHotelsObject() {
            return this.info?.departures?.find(el => !!el.hotels)
        }

        get timeRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_TIME')) {
                rules.push(this.requiredRule('transfers.pickup_time', 'PICK_UP_TIME'))
            }
            return rules
        }

        get hotelRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_HOTEL')) {
                rules.push(this.requiredRule('select_hotel', 'PICK_UP_HOTEL'))
            }
            return rules
        }

        get placeRules() {
            const rules = []
            if (this.isProductFieldMandatory('PICK_UP_PLACE')) {
                rules.push(this.requiredRule('pickup_location', 'PICK_UP_PLACE'))
            }
            return rules
        }
    }
</script>
