<template>
    <v-card hover rounded="0" class="swiper-card" style="box-sizing: border-box" @click="$emit('selected', hotel)">
        <div v-if="hotel.internalRating" style="position: absolute; top: 15px; left: 20px; z-index: 1">
            <v-chip small label color="warning"> {{ hotel.internalRating }} </v-chip>
        </div>
        <v-img
            :src="hotel.img"
            :lazy-src="'/placeholder.png' | srcHost"
            eager
            :aspect-ratio="387 / 238"
            :max-height="250"
            class="white--text align-end"
        />
        <div class="full-width pa-4">
            <v-rating
                color="#FFD600"
                half-increments
                length="5"
                size="20"
                :style="{visibility: hotel.starRating === undefined ? 'hidden' : ''}"
                :value="hotel.starRating || 0"
                class="mb-2"
            />
            <div class="d-flex flex-column px-1">
                <span class="word-break font-weight-bold text-subtitle-1 black--text font-weight-light">
                    {{ hotel.title }}
                </span>
                <span v-if="hotel.hotelAddress" class="my-1 font-weight-light" style="color: #9b9b9b">
                    {{ hotel.hotelAddress }}
                </span>
                <div v-else />
                <span class="my-1 black--text">
                    <span class="caption font-weight-light">1 {{ $tc('room', 1) }} / 2 {{ $t('adults') }} </span>
                </span>
                <div class="d-flex align-center my-2 rounded full-width pa-2" style="background: #f5f8f8">
                    <v-icon color="warning" class="me-2" small> mdi-calendar-blank-outline </v-icon>
                    <span class="caption black--text font-weight-light"> {{ `${startDate} - ${endDate}` }} </span>
                </div>
                <div class="d-flex align-center my-2 rounded full-width pa-2" style="background: #f5f8f8">
                    <v-icon color="warning" class="me-2" left small> mdi-weather-night </v-icon>
                    <span class="caption black--text font-weight-light"> 7 {{ $tc('night', 7) }} </span>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import PopularHotel from '@/components/parts/PopularHotel'

    @Component
    export default class PopularHotelMod extends PopularHotel {
        startDate = null
        endDate = null

        mounted() {
            this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1), 'dd MMM yyyy (eee)')
            this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7), 'dd MMM yyyy (eee)')
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon {
        padding: 2px;
    }
</style>
