<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col cols="12" sm="8">
                <h4 class="title text-capitalize">
                    {{ $t('personal_information') }}
                </h4>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select
                            v-model="person.prefix"
                            :items="['Mr', 'Ms', 'Mrs']"
                            :label="`${$t('salutation')}*`"
                            :rules="[requiredRule('salutation')]"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="person.firstName"
                            :label="`${$t('first_name')}*`"
                            :rules="validationRules('first_name', [latinRule, requiredRule])"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-model="person.lastName"
                            :label="`${$t('last_name')}*`"
                            :rules="validationRules('last_name', [latinRule, requiredRule])"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <phone-field v-model="person.contactPhone" :label="$t('phone')" />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field v-model="person.email" :label="emailLabel" type="email" :rules="emailRules" />
                    </v-col>
                    <v-col v-show="!hideSkype" cols="12" sm="4">
                        <v-text-field v-model="person.skype" :label="`${$t('skype')}`" />
                    </v-col>
                </v-row>
                <h4 v-show="!hideBirthdate || !hideCitizenship" class="title text-capitalize">
                    {{ $t('additional_information') }}
                </h4>
                <v-row v-show="!hideBirthdate || !hideCitizenship">
                    <v-col v-show="!hideBirthdate" cols="12" sm="6">
                        <date-picker
                            v-model="person.birthdate"
                            :label="$t('birthdate')"
                            :max="$dateFns.format(new Date())"
                            year-active-picker
                        />
                    </v-col>
                    <v-col v-show="!hideCitizenship" cols="12" sm="6">
                        <v-autocomplete
                            v-model="person.citizenshipId"
                            :label="$t('citizenship')"
                            :items="countries"
                            item-text="name"
                            item-value="id"
                        />
                    </v-col>
                </v-row>
                <h4 class="title text-capitalize">
                    {{ $t('comment') }}
                </h4>
                <v-subheader class="px-0 font-italic">
                    {{ $t('enter_comment') }}
                </v-subheader>
                <v-textarea v-model="person.comments" outlined />
            </v-col>
            <v-col cols="12" sm="4">
                <h4 class="title text-capitalize">
                    {{ $t('role') }}
                </h4>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-if="
                                (person.personId && roleItems.map(r => r.value).includes(person.role)) ||
                                !person.personId
                            "
                            v-model="person.role"
                            :disabled="!!person.personId"
                            :items="roleItems"
                            :label="`${$t('role')}*`"
                        />
                        <v-text-field v-else v-model="person.role" disabled :label="`${$t('role')}*`" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="person.login"
                            :disabled="!!person.personId && hasLogin"
                            :label="`${$t('login')}${credentialsRequired ? '*' : ''}`"
                            :rules="[v => !credentialsRequired || !!v || `${$t('login')} ${$t('validation.required')}`]"
                        />
                    </v-col>
                </v-row>
                <h4 class="title text-capitalize">
                    {{ person.personId ? $t('change_password') : $t('password') }}
                </h4>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="password"
                            :label="`${$t('password')}${!credentialsRequired || person.personId ? '' : '*'}`"
                            type="password"
                            autocomplete="new-password"
                            :rules="[
                                v =>
                                    !credentialsRequired ||
                                    !!v ||
                                    !!person.personId ||
                                    `${$t('password')} ${$t('validation.required')}`,
                            ]"
                            @input="passwordToMatch = null"
                        />
                        <v-text-field
                            v-model="passwordToMatch"
                            :label="`${$t('password_confirm')}${!credentialsRequired || person.personId ? '' : '*'}`"
                            type="password"
                            autocomplete="new-password"
                            :rules="passwordConfirmationRules"
                        />
                    </v-col>
                </v-row>
                <v-switch
                    v-if="person.personId"
                    v-model="person.active"
                    color="primary"
                    :label="person.active ? $t('active') : $t('inactive')"
                />
                <v-row v-if="person.personId && !hidePassport">
                    <v-col cols="12">
                        <h4 class="title text-capitalize">
                            {{ $t('passport') }}
                        </h4>
                        <v-list v-if="person.passports.length">
                            <v-list-item
                                v-for="(passport, passportIndex) in person.passports"
                                :key="passportIndex"
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div
                                            class="d-flex align-center"
                                            style="cursor: pointer"
                                            @click="editPassport('person', passport, person.personId)"
                                        >
                                            <v-icon class="mr-2">mdi-file-document-edit</v-icon>
                                            {{ passport.number }}
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-btn
                            :loading="isLoading"
                            block
                            color="primary"
                            class="mt-4"
                            @click="createPassport('person', person.personId)"
                        >
                            {{ $t('create_passport') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-btn :loading="isLoading" :disabled="isLoading || !valid" block color="primary" @click="save">
            {{ $t('save') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component, Emit, mixins, Prop, Watch} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import PassportMixin from '@/components/account/mixins/PassportMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import PhoneField from '~src/components/snippets/forms/PhoneField.vue'

    @Component({
        components: {PhoneField, DatePicker},
    })
    export default class PersonForm extends mixins(FormValidationRulesMixin, PassportMixin) {
        @Prop() person
        @Prop() companyId
        @Prop() rolePrefix
        @Prop() customRoles
        @Prop({default: true, type: Boolean}) redirect
        @Prop({default: false, type: Boolean}) allowNoCredentials

        valid = false
        showBirthDatePicker = false
        isLoading = false
        password = null
        passwordToMatch = null
        hasLogin = false

        @Watch('password')
        onChangePassword() {
            this.$refs.form.resetValidation()
        }

        @Watch('person.role')
        onChangeRole() {
            this.$refs.form.resetValidation()
        }

        mounted() {
            if (this.person.personId) {
                this.$refs.form.validate()
            }
            this.hasLogin = !!this.person.login
        }

        @Emit()
        async save() {
            this.isLoading = true
            try {
                const {
                        prefix,
                        firstName,
                        lastName,
                        contactPhone,
                        email,
                        skype,
                        birthdate,
                        citizenshipId,
                        comments,
                        active,
                        role,
                        login,
                    } = this.person,
                    person = {
                        prefix,
                        firstName,
                        lastName,
                        contactPhone,
                        email,
                        skype,
                        birthdate,
                        citizenshipId,
                        comments,
                    }
                if (this.person.personId) {
                    Object.assign(person, {active})
                    if (this.password) {
                        Object.assign(person, {password: this.password})
                    }
                    await this.$api.persons.put(this.person.personId, person)
                    if (this.person.personId === authStore.person.personId) {
                        delete person.password
                        authStore.SET_PERSON({...this.person, ...person})
                    }
                } else {
                    Object.assign(person, {
                        role,
                        login,
                        password: this.password,
                        companyId: this.companyId || this.$route.query.company || authStore.person.companyId,
                    })
                    await this.$api.persons.post(person)
                }
                this.$toast.success(this.$t('messages.success.user_saved'))
                if (this.redirect) {
                    await this.$router.push({
                        name: 'companyPersons',
                        query: {
                            company: this.$route.query.company ? this.$route.query.company : authStore.person.companyId,
                        },
                    })
                }
            } catch (e) {
                if (e.errors) {
                    e.errors.forEach(error => {
                        if (error.errorType === 'validation') {
                            this.$toast.error(error.message)
                        } else {
                            this.$toast.error(this.$t('error_message.save_error'))
                        }
                    })
                } else {
                    this.$toast.error(this.$t('error_message.save_error'))
                }
            } finally {
                this.isLoading = false
            }
        }

        get countries() {
            return runtimeStore.countries
        }

        get passwordConfirmationRules() {
            return [v => this.password === v || `${this.$t('password_confirm')} ${this.$t('validation.match')}`]
        }

        get roleItems() {
            let roles = []
            let company = []
            if (this.$route.query.company) {
                company = runtimeStore.companies.filter(company => this.$route.query.company == company.id)
            }
            if (this.customRoles) {
                roles = this.customRoles
            } else if (authStore.companyType === 'CORPORATOR' || (company.length && company[0].type === 'CORPORATOR')) {
                roles = ['employee', 'external_person', 'director', 'trip_coordinator', 'group_supervisor']
            } else {
                roles = ['director', 'supervisor', 'sales_manager', 'agent']
            }
            return roles.map(role => ({
                text: this.$t(`roles.${role}`),
                value: this.rolePrefix
                    ? `${this.rolePrefix}.${role}`
                    : company.length
                    ? role
                    : `${authStore.rolePrefix}.${role}`,
            }))
        }

        get credentialsRequired() {
            return !this.allowNoCredentials
        }

        get hideCitizenship() {
            return false
        }

        get hidePassport() {
            return false
        }

        get hideBirthdate() {
            return false
        }

        get hideSkype() {
            return false
        }

        get emailRules() {
            return this.validationRules('email', [this.emailRule])
        }

        get emailLabel() {
            return `${this.$t('email')}`
        }
    }
</script>
