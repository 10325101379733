<template>
    <package-booking-page-layout v-if="loaded" :search-request="searchRequest" :offer="basketItem.hotel">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <package-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:tourists="{tourists, index, disabled, personSelected}">
            <flight-tourist-form
                v-model="tourists[index]"
                :disabled="disabled"
                :index="index"
                :offer="basketItem.flight"
                is-package
                :hide-index="tourists.length === 1"
                :start-date="startDate"
                :data-cy="`tourist-${index}`"
                @person-selected="personSelected"
            />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <selected-offers
                :direction="'column'"
                :bookable="false"
                :changable="!bookingKey"
                :hotel="basketItem.hotel"
                :room="basketItem.room"
                :flight="basketItem.flight"
                :price="basketItem.price"
                :search-request="searchRequest"
                :total-order-price="basketItem.totalPrice"
            />
        </template>
    </package-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {packagesRuntimeStore, packagesStore, runtimeStore} from '@/utils/store-accessor'
    import PackageBookingPageLayout from '~/components/packages/booking/PackageBookingPageLayout'
    import FlightTouristForm from '~src/components/booking/forms/flightTouristForm.src'
    import SelectedOffers from '~/components/packages/search/SelectedOffers'
    import PackageBreadcrumbs from '~/components/packages/PackageBreadcrumbs'
    import PackagePageMixin from '@/components/packages/mixins/PackagePageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            PackageBookingPageLayout,
            FlightTouristForm,
            SelectedOffers,
            PackageBreadcrumbs,
        },
        layout: 'blank',
    })
    export default class PackageBookingPage extends mixins(PackagePageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await Promise.all([
                this.loadRuntimeData(),
                runtimeStore.loadTouristBookingFields({offerKey: this.basketItem.flight.offerKey}),
            ])
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(
                    new Date(),
                    UTCToDate(this.$dateFns.parseISO(this.basketItem.flight.expirationTime))
                ) &&
                !runtimeStore.bookingActive &&
                !packagesStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(packagesRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return packagesStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get startDate() {
            return this.searchRequest.startDate
        }
    }
</script>
