var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"fill-height":!_vm.loaded,"column":""}},[(_vm.loaded)?_c('hotel-booking-page-layout',{ref:"layout",attrs:{"search-request":_vm.searchRequest,"room-offers":_vm.roomOffers,"offer-keys":_vm.offerKeys,"offer":_vm.offer,"search-request-country-id":_vm.searchRequestCountryId},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{ref:"expiredOffersSnackbar",attrs:{"is-offers-expired":_vm.isOffersExpired,"disable-restart":""},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('hotel-refresh-offers-dialog',{ref:"refreshOffersDialog",attrs:{"expired-offer-keys":_vm.offerKeys},on:{"refresh":_vm.onOffersRefreshed}}),_vm._v(" "),_c('hotel-breadcrumbs',{attrs:{"search-request":_vm.searchRequest,"offer":_vm.offer}}),_vm._v(" "),_c('refresh-offers-citizenship-snackbar',{attrs:{"basket-item":_vm.basketItem},on:{"refresh":_vm.refreshExpiredOffers}})]},proxy:true},{key:"touristsCard",fn:function(ref){
var tourists = ref.tourists;
var bookingKey = ref.bookingKey;
var basketKey = ref.basketKey;
return [_vm._l((tourists),function(roomTourists,roomIndex){return [_c('v-card',{key:("tourist-" + roomIndex),staticClass:"mb-5",attrs:{"data-cy":("room-" + roomIndex),"outlined":""}},[_c('v-card-title',{staticClass:"text-capitalize"},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" "),(tourists.length > 1)?[_vm._v("\n                            "+_vm._s(((_vm.$tc('room', 1)) + " " + (roomIndex + 1)))+"\n                        ")]:[_vm._v("\n                            "+_vm._s(_vm.$t('guests'))+"\n                        ")]],2),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_vm._l((roomTourists),function(tourist,index){return [_c('hotel-tourist-form',{key:roomIndex + '_' + index,attrs:{"room-index":roomIndex,"index":index,"disabled":!!bookingKey || !!basketKey,"show-meal-types":_vm.ownProduct,"standard-meal-name":_vm.getMealName(roomIndex),"corporate-policy-violation":_vm.roomOffers[roomIndex].corporatePolicyViolation,"hide-index":roomTourists.length === 1,"start-date":_vm.startDate,"hotel":_vm.offer,"data-cy":("tourist-" + index)},model:{value:(roomTourists[index]),callback:function ($$v) {_vm.$set(roomTourists, index, $$v)},expression:"roomTourists[index]"}})]}),_vm._v(" "),_c('early-check-in-late-check-out',{attrs:{"room-index":roomIndex,"disabled":!!bookingKey || !!basketKey}})],2)],1),_vm._v(" "),(
                        _vm.showExtraServices &&
                        _vm.productStore.extraServices[roomIndex] &&
                        _vm.productStore.extraServices[roomIndex].length
                    )?_c('hotel-extra-services-form',{key:("extra-services-" + roomIndex),staticClass:"mb-5",attrs:{"room-index":roomIndex,"is-disabled":!!bookingKey || !!basketKey},on:{"updateExtraServices":function($event){bookingKey = null
                        basketKey = null}}}):_vm._e(),_vm._v(" "),_c('hotel-comments-form',{key:("comments-" + roomIndex),attrs:{"index":roomIndex,"disabled":!!bookingKey || !!basketKey,"product-store":_vm.productStore}})]})]}},{key:"sidebar",fn:function(ref){
                        var bookingKey = ref.bookingKey;
                        var basketKey = ref.basketKey;
                        var selectedAddOns = ref.selectedAddOns;
return [_c('hotel-order-summary',{attrs:{"offer":_vm.offer,"room-offers":_vm.roomOffers,"search-request":_vm.searchRequest,"prebooked":!!bookingKey || !!basketKey,"selected-add-ons":selectedAddOns},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('v-card',{staticClass:"mb-2 pa-0",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('search-summary-content',{attrs:{"search-request":_vm.searchRequest,"tourists-label":"guest","show-dates":"","show-nights":"","show-tourists":""}})],1)],1)]},proxy:true}],null,true)})]}}],null,false,502918947)}):_c('v-layout',{staticClass:"align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }