<template>
    <div v-if="selectedAddOns && selectedAddOns.length">
        <v-divider class="my-4" />
        <div class="font-weight-bold">
            {{ $t('add_ons') }}
        </div>
        <div v-for="(addOn, index) in selectedAddOns" :key="index" class="d-flex text-caption mt-4">
            <div>{{ addOnName(addOn) }}</div>
            <v-spacer />
            <div>{{ addOn.free ? $t('free') : $options.filters.price(addOnPrice(addOn)) }}</div>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'

    @Component
    export default class AddOnsSelectedList extends Vue {
        @Prop({required: true, type: Array}) selectedAddOns

        addOnName(addOn) {
            const product = addOn.product[0]
            return product.info?.name || product?.name || ''
        }

        addOnPrice(addOn) {
            return addOn.product[0].offers[0].price
        }
    }
</script>
