<template>
    <div class="d-flex align-center">
        <v-icon small left :color="iconColor" class="success--text"> mdi-bed-king-outline </v-icon>
        <span v-if="room.availableRooms">
            {{ `${room.availableRooms} ${$tc('room', room.availableRooms)}` }}
        </span>
        <span v-else-if="!room.available" class="warning--text" v-text="$t('on_request')" />
        <span v-else class="success--text" v-text="$t('available')" />
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelAvailableLabel from '~/components/hotels/snippets/HotelAvailableLabel'
    import {Prop} from 'vue-property-decorator'

    @Component
    export default class HotelAvailableLabelMod extends HotelAvailableLabel {
        @Prop({default: 'success'}) iconColor
    }
</script>
