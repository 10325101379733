<template>
    <v-card class="insurance-card" outlined>
        <div>
            <v-img
                tile
                :src="mainImg | imageUrl(200)"
                :alt="product.name"
                height="200"
                lazy-src="/placeholder.png"
                eager
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.mainImageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1">
                <div class="mb-2 font-weight-medium text-truncate primary--text">
                    {{ product.name }}
                </div>
                <div v-if="product.shortDescription" v-html="product.shortDescription" />
            </div>
            <div class="ma-4 price-col flex-shrink-0 text-right">
                <slot name="price" :offer="product.offers[0]">
                    <div data-cy="client-price" class="font-weight-medium">
                        {{ $t('from') }} {{ minPriceOffer.price | price }}
                    </div>
                    <discount-label :price="product.offers[0].price" />
                    <commission-amount :commission="minPriceOffer.price.commission" />
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="my-2"
                        nuxt
                        @click="selectForBooking(product.offers[0])"
                    >
                        {{ $t('book') }}
                    </v-btn>
                </slot>
                <div class="primary--text cursor-pointer text-no-wrap" @click="expanded = !expanded">
                    <span>
                        {{ $t('tours.detailed_info') }}
                    </span>
                    <v-icon color="primary">
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
        <v-expand-transition>
            <v-list v-if="expanded">
                <template v-for="(offer, index) in product.offers">
                    <v-list-item :key="offer.offerKey" :data-cy="`insurance-offer-card-${index}`" class="pa-4">
                        <div class="caption">
                            <div class="mb-1 font-weight-medium body-1 grey--text">
                                <v-icon small class="mr-1 info--text">mdi-information-outline</v-icon>
                                {{ offer.planName }}
                            </div>
                            <div class="mb-1">
                                <span v-if="isAvailable">
                                    <v-icon :title="$t('available')" small class="mr-2 success--text">
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    <span class="success--text" v-text="$t('available')" />
                                </span>
                                <span v-else>
                                    <v-icon :title="$t('on_request')" small class="mr-2 warning--text">
                                        mdi-alert-circle
                                    </v-icon>
                                    <span class="warning--text" v-text="$t('on_request')" />
                                </span>
                            </div>
                            <div>
                                <conditions-label
                                    v-if="offer"
                                    :offer-key="offer.offerKey"
                                    :price="offer.price"
                                    @conditions="showConditionsInfo(offer)"
                                />
                            </div>
                        </div>
                        <div class="price-col ms-auto">
                            <slot name="price" :offer="offer">
                                <v-btn
                                    color="primary"
                                    block
                                    depressed
                                    nuxt
                                    data-cy="client-price"
                                    class="my-2"
                                    @click="selectForBooking(offer)"
                                >
                                    {{ offer.price | price }}
                                </v-btn>
                                <div class="text-caption text--secondary">
                                    {{
                                        `${$t('for')} ${touristsTotal} ${$tc('person', touristsTotal)},
                                     ${searchRequest.daysCount} ${$tc('day', searchRequest.daysCount)}`
                                    }}
                                </div>
                                <commission-amount :commission="offer.price.commission" />
                                <net-amount :price="offer.price" />
                            </slot>
                        </div>
                    </v-list-item>
                    <v-divider v-if="index !== product.offers.length - 1" :key="index" />
                </template>
            </v-list>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import InsuranceOfferCard from '@/components/insurance/search/offers/InsuranceOfferCard'

    @Component
    export default class InsuranceOfferCardMobile extends InsuranceOfferCard {}
</script>

<style lang="scss" scoped>
    .insurance-card {
        border: none !important;
    }
</style>
