<template>
    <client-only>
        <div>
            <v-data-table
                :headers="headers"
                :items="isLoading ? [] : services"
                :items-per-page="ordersOnPage"
                :server-items-length="ordersCount"
                :loading="isLoading"
                hide-default-footer
                :options.sync="options"
                :footer-props="{'items-per-page-options': [ordersOnPage, 100]}"
                :hide-default-header="isMobile"
                :no-data-text="noDataText"
                :mobile-breakpoint="0"
                data-cy="orders-table"
                group-by="orderId"
                disable-sort
                class="elevation-0"
            >
                <template v-if="selectable" v-slot:header.id="{header}">
                    <v-checkbox
                        v-model="selectAll"
                        :ripple="false"
                        :value="false"
                        dense
                        hide-details
                        @change="changeSelectAll"
                    >
                        <template v-slot:label>
                            <span>{{ header.text }}</span>
                        </template>
                    </v-checkbox>
                </template>
                <template v-slot:header.date="{header}">
                    <span class="cursor-pointer" @click="toggleSortOrder">
                        {{ header.text }}
                        <v-icon v-if="sortOrder !== 'none'" class="cursor-pointer hidden-sm-and-down me-1">
                            {{ sortOrder === 'ASCENDING' ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                    </span>
                </template>
                <template v-slot:group.header="{group, toggle, isOpen}">
                    <td
                        :class="{open: isOpen}"
                        class="text-start cursor-pointer toggle-expanded"
                        @click="toggleExpanded(toggle)"
                    >
                        <div class="d-flex align-center">
                            <v-icon v-if="isB2B" class="cursor-pointer hidden-sm-and-down me-1">
                                {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                            <v-checkbox
                                v-if="selectable && !isHideCheckbox && !update"
                                :key="group"
                                v-model="selected"
                                :ripple="false"
                                :value="getOrder(group)"
                                :value-comparator="(orderA, orderB) => orderA.orderId === orderB.orderId"
                                dense
                                hide-details
                                @click.stop
                            />
                            <template v-if="selectable">
                                <nuxt-link no-prefetch :to="{name: 'order-details', params: {id: group}}">
                                    <strong>{{ group }}</strong>
                                </nuxt-link>
                                <span v-if="!orderTitleEdit[group]" class="ml-2">
                                    {{ getOrder(group).title }}
                                    <v-icon
                                        small
                                        color="primary"
                                        @click.stop="
                                            $set(orderTitleEdit, group, true)
                                            $set(orderTitle, group, getOrder(group).title)
                                        "
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </span>
                                <div v-else class="d-flex align-center ml-4">
                                    <v-text-field
                                        v-model="orderTitle[group]"
                                        dense
                                        hide-details
                                        class="order-title-edit"
                                    />
                                    <v-btn small class="ml-1" color="primary" @click.stop="saveOrderTitle(group)">
                                        {{ $t('save') }}
                                    </v-btn>
                                </div>
                            </template>
                            <nuxt-link v-else no-prefetch :to="{name: 'order-details', params: {id: group}}">
                                <strong>{{ group }}</strong>
                            </nuxt-link>
                        </div>
                    </td>
                    <template v-if="isB2C && !isMobile">
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td class="cursor-pointer py-1 text-right" @click="toggleExpanded(toggle)">
                            <template v-if="!groupedOrder(group).isEmptyOrder">
                                <div v-html="groupedOrder(group).brutto.title" />
                                <div
                                    v-if="
                                        (isAgency || isTourOperator) &&
                                        groupedOrder(group).brutto.commissionAmount &&
                                        commissionAndNettoCheckbox
                                    "
                                >
                                    <div class="commission text-no-wrap">
                                        {{ $t('commission') }}:
                                        {{
                                            groupedOrder(group).brutto.commissionAmount
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                    <div class="commission text-no-wrap">
                                        {{ $t('net_amount') }}:
                                        {{
                                            (groupedOrder(group).brutto.amount -
                                                groupedOrder(group).brutto.commissionAmount)
                                                | priceFormat(groupedOrder(group).brutto.currency)
                                        }}
                                    </div>
                                </div>
                                <payment-status-label :status="groupedOrder(group).brutto.status" />
                            </template>
                        </td>
                        <td />
                    </template>
                </template>
                <template v-slot:item.mobile="{item}">
                    <orders-service-mobile
                        :service="item"
                        @open-voucher-dialog="openVoucherDialog"
                        @show-add-service-modal="showAddServiceModal"
                    />
                </template>
                <template v-slot:item.created="{item}">
                    <div class="text-no-wrap" v-html="item.created" />
                </template>
                <template v-slot:item.modified="{item}">
                    <div class="text-no-wrap" v-html="item.modified" />
                </template>
                <template v-slot:item.title="{item}">
                    <div v-if="!item.isEmptyOrder" class="d-flex flex-column">
                        <span class="d-flex justify-end justify-md-start">
                            <v-icon color="primary" small class="mr-1">{{
                                getServiceTypeIcon(item.serviceType)
                            }}</v-icon>
                            <div v-html="item.pureTitle" />
                        </span>
                        <div v-html="item.title + item.location" />
                    </div>
                    <v-btn
                        v-if="!$config.account.hideAddServiceBtn && item.isEmptyOrder"
                        :color="'primary'"
                        small
                        class="mb-2"
                        @click="showAddServiceModal(item.orderId)"
                        v-text="$t('add_service')"
                    />
                </template>
                <template v-slot:item.date="{item}">
                    <div class="text-no-wrap" v-html="item.date" />
                </template>
                <template v-slot:item.travellers="{item}">
                    <span v-html="item.travellers" />
                </template>
                <template v-slot:item.status="{item}">
                    <span :class="`${item.statusColor}--text`">{{ item.status }}</span>
                    <div v-if="isTO1">
                        <span class="commission">
                            {{ item.supplierCompanyName }}
                        </span>
                    </div>
                </template>
                <template v-slot:item.statusByServices="{item}">
                    <span :class="`${item.statusColor}--text`">{{ item.status }}</span>
                    <div v-if="isTO1">
                        <span class="commission">
                            {{ item.supplierCompanyName }}
                        </span>
                    </div>
                </template>
                <template v-slot:item.supplierPrice="{item}">
                    <orders-supplier-price-label v-if="!item.isEmptyOrder && item.showAllServicesPrice" :item="item" />
                </template>
                <template v-slot:item.price="{item}">
                    <orders-price-label v-if="!item.isEmptyOrder && item.showAllServicesPrice" :item="item" />
                </template>
                <template v-if="isShowDueToPay" v-slot:item.dueToPay="{item}">
                    <div v-if="!item.isEmptyOrder && item.status !== 'Quote' && item.showAllServicesPrice">
                        {{
                            isAgency
                                ? item.supplier.price.amountDue
                                : item.customer.price.amountDue | priceFormat(item.supplier.price.currency)
                        }}
                    </div>
                </template>
                <template v-slot:item.income="{item}">
                    <div v-if="!item.isEmptyOrder && item.income.amount && item.showAllServicesPrice">
                        {{ item.income.amount | priceFormat(item.income.currency) }}
                        <div v-if="item.income.percent" class="grey--text">
                            {{ `${item.income.percent}%` }}
                        </div>
                    </div>
                </template>
                <template v-slot:item.clientCompanyName="{item}">
                    <strong>{{ item.clientCompanyName }}</strong>
                    <small>{{ item.agent }}</small>
                </template>
                <template v-slot:item.voucher="{item}">
                    <v-btn
                        v-if="!item.isEmptyOrder && voucherAvailable(item)"
                        class="cursor-pointer"
                        text
                        style="text-transform: unset !important"
                        @click="openVoucherDialog(item)"
                    >
                        <div class="d-flex flex-column">
                            <v-icon dense class="mt-1">mdi-tray-arrow-down</v-icon>
                        </div>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination v-if="pagingLength" v-model="page" light :length="pagingLength" class="py-8" />
            <voucher-dialog
                v-model="isShowVoucherDialog"
                :is-loading="voucherLoading"
                :title="$t('voucher')"
                @download-voucher="downloadVoucher(voucherService)"
                @send-on-email="sendVoucherOnEmail(voucherService, $event)"
            />
            <order-add-service-modal
                v-if="addServiceOrder"
                v-model="isShowOrderAddServiceModal"
                :order="addServiceOrder"
            />
            <invoices-modal @issuedInvoice="onIssuedInvoice" />
        </div>
    </client-only>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import Orders from '~/components/account/Orders'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class OrdersMod extends Orders {
        get page() {
            return this.options.page
        }

        set page(val) {
            persistentStore.SET_ORDERS_TABLE_OPTIONS({
                itemsPerPage: persistentStore.ordersTableOptions.itemsPerPage,
                page: +val,
            })

            this.loadOrders()
        }

        get pagingLength() {
            return Math.ceil(this.ordersCount / this.options.itemsPerPage)
        }

        getServiceTypeIcon(serviceType) {
            switch (serviceType) {
                case 'ACCOMMODATION':
                    return 'mdi-domain'
                case 'FLIGHT':
                    return 'mdi-airplane'
                case 'OWNCHARTER':
                    return 'mdi-airplane-takeoff'
                case 'CARRENT':
                    return 'mdi-car'
                case 'ACTIVITY':
                    return 'mdi-lightning-bolt-outline'
                case 'TRANSFER':
                    return 'mdi-bus'
                case 'EXTRASERVICE':
                case 'OWNEXTRASERVICE':
                    return 'mdi-domain-plus'
                case 'PACKAGE_TOUR':
                    return 'mdi-gift-outline'
                case 'DYNAMIC_PACKAGE':
                    return 'mdi-wallet-travel'
                case 'TRAIN':
                    return 'mdi-train'
                case 'INSURANCE':
                    return 'mdi-file-document-outline'
            }
        }

        get isShowDueToPay() {
            return (this.isTourOperator || this.isAgency) && this.commissionAndNettoCheckbox
        }
    }
</script>
