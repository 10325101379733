<template>
    <v-form ref="form" v-model="valid" class="d-flex flex-column align-center">
        <v-row no-gutters>
            <v-col cols="12" md="6" class="pe-2">
                <v-select
                    v-model="prefix"
                    :append-icon="'mdi-chevron-down'"
                    :items="['Mr', 'Ms', 'Mrs']"
                    :placeholder="`${$t('salutation')}*`"
                    :rules="[requiredRule('salutation')]"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="firstName"
                    :placeholder="`${$t('first_name')}*`"
                    :rules="validationRules('first_name', [latinRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="lastName"
                    :placeholder="`${$t('last_name')}*`"
                    :rules="validationRules('last_name', [latinRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12" md="6">
                <phone-field v-model="contactPhone" :placeholder="$t('phone')" />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="email"
                    :placeholder="`${$t('email')}*`"
                    type="email"
                    :rules="validationRules('email', [emailRule, requiredRule])"
                    required
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="password"
                    :placeholder="`${$t('password')}*`"
                    type="password"
                    autocomplete="new-password"
                    :rules="[requiredRule('password')]"
                    required
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="passwordToMatch"
                    :placeholder="`${$t('password_confirm')}*`"
                    type="password"
                    autocomplete="new-password"
                    :rules="passwordConfirmationRules"
                    required
                />
            </v-col>
        </v-row>
        <v-btn
            v-if="hideBtn === false"
            :loading="isLoading"
            :disabled="isLoading || !valid"
            color="primary"
            class="px-8"
            @click="register"
        >
            {{ $t('register') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import RegistrationForm from '~/components/account/forms/RegistrationForm'

    @Component
    export default class RegistrationFormMod extends RegistrationForm {}
</script>
