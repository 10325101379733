<template>
    <v-card>
        <v-card-title class="py-5">
            <v-btn v-if="createMode || editMode" fab class="me-3" small elevation="0" @click="goToTable">
                <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <span v-if="createMode">
                {{ `${$t('create_new_communication')}` }}
            </span>
            <span v-else-if="editMode">
                {{ `${$t('edit_communication')}` }}
            </span>
            <span v-else>
                {{ `${$t('communications')}` }}
            </span>
        </v-card-title>
        <v-card-text>
            <communication-edit-form v-if="createMode || editMode" v-model="communicationData" />
            <v-data-table
                v-else
                :headers="headers"
                :items="communications"
                :loading="loading || communicationsLoading"
                class="elevation-1"
                :hide-default-footer="communications && communications.length < 15"
                :items-per-page="15"
                @click:row="editCommunication"
            >
                <template v-slot:item.communicationStage="{item}">
                    {{ $t(`communication_stage.${item.communicationStage}`) }}
                </template>
                <template v-slot:item.status="{item}">
                    {{ $t(`${item.status.toLowerCase()}`) }}
                </template>
            </v-data-table>
            <v-btn v-if="!createMode && !editMode" color="primary" class="mt-4" @click="createMode = !createMode">
                {{ $t('create') }}
            </v-btn>
        </v-card-text>
        <v-card-actions class="py-4">
            <v-spacer />
            <v-btn v-if="createMode || editMode" color="primary" :loading="loading" @click="save">
                {{ $t('save') }}
            </v-btn>
            <v-btn v-if="editMode" text @click="cancel">
                {{ $t('cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TimeField from '@/components/snippets/forms/TimeField'
    import {authStore} from '@/utils/store-accessor'
    import CommunicationEditForm from '@/components/account/communications/CommunicationEditForm'

    @Component({
        components: {CommunicationEditForm, TimeField, DatePicker},
    })
    export default class Communications extends Vue {
        @Prop() orderId
        @Prop() communications
        @Prop() communicationsLoading

        loading = false
        createMode = false
        editMode = false

        communicationData = {
            type: null,
            stage: null,
            communicationDateTime: null,
            subject: null,
            details: null,
        }
        communicationId = null

        async mounted() {
            await this.getCommunications()
        }

        @Emit()
        async getCommunications() {}

        async sendCreateCommunication() {
            try {
                this.loading = true

                await this.$api.communications.post(this.requestData)
                await this.getCommunications()

                this.goToTable()
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.msg) {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.loading = false
            }
        }

        async sendEditCommunication() {
            try {
                this.loading = true

                await this.$api.communications.put(this.communicationId, {
                    ...this.requestData,
                    communicationId: this.communicationId,
                })
                await this.getCommunications()

                this.goToTable()
                // eslint-disable-next-line no-empty
            } catch (e) {
                if (e.msg) {
                    this.$toast.error(e.msg)
                }
            } finally {
                this.loading = false
            }
        }

        async save() {
            this.editMode ? await this.sendEditCommunication() : await this.sendCreateCommunication()
            this.resetData()
        }

        resetData() {
            this.communicationData = {
                type: null,
                stage: null,
                communicationDateTime: null,
                subject: null,
                details: null,
            }
        }

        editCommunication(e) {
            this.communicationId = e.communicationId
            this.communicationData = {
                assignedToId: e.assignedUser.id,
                accountId: e.accountId,
                accountType: e.accountType,
                stage: e.communicationStage,
                communicationDateTime: e.communicationDateTime?.split(' ').join('T'),
                subject: e.subject,
                description: e.description,
                orderId: e.reservationNumber,
                active: true,
            }

            this.editMode = true
        }

        goToTable() {
            this.resetData()
            this.createMode = false
            this.editMode = false
        }

        cancel() {
            if (!this.createMode && !this.editMode) {
                this.close()
            }

            this.goToTable()
        }

        close() {
            this.goToTable()
        }

        get requestData() {
            return {
                assignedToId: this.person.personId,
                accountId: this.accountId,
                accountType: this.accountType,
                type: this.communicationData.type,
                stage: this.communicationData.stage,
                communicationDateTime: this.communicationData.communicationDateTime,
                subject: this.communicationData.subject,
                description: this.communicationData.description,
                orderId: this.orderId,
                active: true,
            }
        }

        get headers() {
            return [
                {text: `${this.$t('date')}/${this.$t('time')}`, value: 'communicationDateTime'},
                {text: this.$t('subject'), value: 'subject'},
                {text: this.$t('order'), value: 'reservationNumber'},
                {text: this.$t('stage'), value: 'communicationStage'},
                {text: this.$t('status'), value: 'status'},
            ]
        }

        get accountType() {
            if (this.isCorporate) {
                return 'CORPORATE_CLIENT'
            }
            if (this.isTO1 || this.isTO2 || this.isAgency) {
                return 'AGENCY_DISTRIBUTOR'
            }
            if (this.isSupplier) {
                return 'SUPPLIER'
            }

            return 'PRIVATE_CLIENT'
        }

        get accountId() {
            if (this.isB2B) {
                return this.company.id
            }
            return this.person.personId
        }

        get person() {
            return authStore.person
        }

        get company() {
            return authStore.company
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isCorporate() {
            return authStore.isCorporate
        }

        get isB2B() {
            return authStore.isB2B
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .v-data-table__wrapper tr:hover {
        cursor: pointer;
    }
</style>
