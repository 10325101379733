<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <insurance-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:sidebar>
            <insurance-search-summary :search-request="searchRequest" search-disabled />
            <insurance-order-summary :search-request="searchRequest" :offer="offer" />
            <insurance-info-modal />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import InsuranceBreadcrumbs from '@/components/insurance/InsuranceBreadcrumbs'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import InsurancePageMixin from '@/components/insurance/mixins/InsurancePageMixin'
    import {insuranceStore} from '@/utils/store-accessor'
    import InsuranceSearchSummary from '@/components/insurance/search/InsuranceSearchSummary'
    import InsuranceOrderSummary from '@/components/insurance/booking/InsuranceOrderSummary'
    import InsuranceInfoModal from '@/components/insurance/info/InsuranceInfoModal'

    @Component({
        components: {
            InsuranceInfoModal,
            ConfirmationPageLayout,
            InsuranceBreadcrumbs,
            InsurancePageMixin,
            InsuranceSearchSummary,
            InsuranceOrderSummary,
        },
        layout: 'blank',
    })
    export default class InsuranceConfirmationPage extends mixins(ConfirmationPageMixin, InsurancePageMixin) {
        get basketItem() {
            return insuranceStore.basketItem(insuranceStore.prepareBookRequest.offerKey)
        }

        get offer() {
            return this.basketItem.product.offers.find(
                offer => offer.offerKey === insuranceStore.prepareBookRequest.offerKey
            )
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }
    }
</script>
