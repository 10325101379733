<template>
    <div>
        <v-form ref="form" v-model="valid" data-cy="forgot-password-form" @keyup.enter="forgotPassword">
            <v-text-field
                v-model="email"
                :placeholder="$t('email')"
                type="email"
                :rules="validationRules('email', [emailRule, requiredRule])"
                required
                filled
                data-cy="authorization-email-input"
            />
        </v-form>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading || !valid"
            color="primary"
            class="mr-2"
            data-cy="forgot-password-button"
            @click="forgotPassword"
        >
            {{ $t('submit') }}
        </v-btn>
        <v-btn outlined color="primary" class="text-capitalize" @click="$emit('switch-login')">
            {{ $t('sign_in') }}?
        </v-btn>
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import ForgotPasswordForm from '~/components/account/forms/ForgotPasswordForm'

    @Component
    export default class ForgotPasswordFormMod extends ForgotPasswordForm {}
</script>
