<template>
    <div class="d-flex flex-column">
        <v-select v-model="status" outlined dense :items="statuses" :label="$t('status')" />
    </div>
</template>

<script>
    import {Component, PropSync, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ModifyManualStatus extends Vue {
        @PropSync('_status') status

        get statuses() {
            return ['CONFIRMED', 'CANCELLED', 'REJECTED', 'CONFIRMATION PENDING', 'QUOTE', 'PRE-BOOKING']
        }
    }
</script>
