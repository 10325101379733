<template>
    <div v-if="isTO1" class="d-flex flex-column">
        <v-btn plain :disabled="loading" class="px-0" @click="download">
            <v-icon color="primary" left>{{ loading ? 'mdi-loading mdi-spin' : 'mdi-file-pdf-box' }}</v-icon>
            {{ documentName }}
        </v-btn>
        <span v-if="checkboxServicesSelected" class="caption mt-n3 text--secondary" style="font-size: 10px !important">
            {{ `${$t('items_selected')}: ${checkboxServicesSelected.length}` }}
        </span>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {downloadData} from '@/utils/helpers'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class DownloadCustomDocumentButton extends Vue {
        @Prop({required: true}) order
        @Prop({required: true}) template
        @Prop() checkboxServicesSelected

        loading = false

        async download() {
            if (!this.checkboxServicesSelected?.length) {
                return this.$toast.warning(this.$t('items_selected_error'))
            }

            try {
                this.loading = true
                const orderId = this.order.orderId

                const data = await this.$api.customDocuments.get({
                    orderId,
                    templateId: this.template.id,
                    ...(this.checkboxServicesSelected && {
                        serviceIds: this.checkboxServicesSelected,
                    }),
                })

                const tourLead =
                    this.order.services[0]?.travelers?.find(e => e.isTourLead) || this.order.services[0]?.travelers[0]
                const tourLeadLastName = tourLead.name[0].lastName?.toUpperCase().replace(' ', '_')
                const dateReverse = new Date().toISOString().slice(0, 10).replace(/-/g, '')

                const fileName = `${this.order.orderId ? this.order.orderId + '-' : ''}${
                    tourLeadLastName ? tourLeadLastName + '-' : ''
                }${this.template.documentType.replace(' ', '_')}${dateReverse ? '-' + dateReverse : ''}.pdf`

                downloadData(data, fileName)
            } catch (e) {
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            } finally {
                this.loading = false
            }
        }

        get documentName() {
            const name = this.template.documentType.split(' ').join('_').toLowerCase()

            return this.$t(name)
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
