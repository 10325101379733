<template>
    <v-dialog v-model="searchForm" content-class="search-form-dialog" :fullscreen="$breakpoint.smAndDown">
        <template v-slot:activator="{}">
            <v-card class="mb-5">
                <v-card-text>
                    <slot name="content">
                        <search-summary-content
                            :search-request="searchRequest"
                            :show-date="showDate"
                            :show-dates="showDates"
                            :show-days-count="showDaysCount"
                            :show-nights="showNights"
                            :show-duration="showDuration"
                            :show-tourists="showTourists"
                            :pick-up-location="pickUpLocation"
                            :drop-off-location="dropOffLocation"
                            :show-passengers="showPassengers"
                            :date-prop="dateProp"
                            :tourists-label="touristsLabel"
                            @refresh="$emit('refresh')"
                        />
                    </slot>
                </v-card-text>
            </v-card>
        </template>
        <component
            :is="formName"
            style="width: 100%"
            :popup="true"
            :variant="searchRequest.type"
            :product-area="productArea"
            @close-popup="searchForm = false"
        />
    </v-dialog>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import SearchSummaryLayout from '@/components/parts/SearchSummaryLayout'

    @Component
    export default class SearchSummaryLayoutMod extends SearchSummaryLayout {}
</script>
