<template>
    <component
        :is="card ? 'v-card' : 'div'"
        v-if="info.checkInFrom || info.checkInTo || info.checkOutFrom || info.checkOutTo"
        outlined
        class="d-flex flex-column black--text subtitle-2"
        :class="{'pt-3 px-4': card}"
    >
        <v-divider v-if="!card" class="mb-3" />
        <v-row v-if="info.checkInFrom || info.checkInTo" class="d-flex align-start" no-gutters>
            <v-col cols="4" class="d-flex font-weight-bold">
                <v-icon class="me-2" small color="warning">mdi-clock-outline</v-icon>
                <div class="font-weight-bold">{{ $t('earlyCheckInTitle') }}:</div>
            </v-col>
            <v-col cols="8" class="d-flex flex-column flex-grow-1">
                <div class="ps-2 width-max-content">{{ checkInLabel }}</div>
                <v-range-slider
                    v-model="checkInRange"
                    :track-fill-color="'yellowlime'"
                    :track-color="'transparent'"
                    hide-details
                    dense
                    :min="0"
                    :max="24"
                    readonly
                />
            </v-col>
        </v-row>
        <v-row v-if="info.checkOutFrom || info.checkOutTo" class="d-flex align-start" no-gutters>
            <v-col cols="4" class="d-flex font-weight-bold">
                <v-icon class="me-2" small color="warning">mdi-clock-outline</v-icon>
                <div class="font-weight-bold">{{ $t('lateCheckOutTitle') }}:</div>
            </v-col>
            <v-col cols="8" class="d-flex flex-column flex-grow-1">
                <div class="ps-2">{{ checkOutLabel }}</div>
                <v-range-slider
                    v-model="checkOutRange"
                    :track-fill-color="'yellowlime'"
                    :track-color="'transparent'"
                    dense
                    hide-details
                    :min="0"
                    :max="24"
                    readonly
                />
            </v-col>
        </v-row>
        <v-divider v-if="!card" />
    </component>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelInfoTime from '~/components/info/HotelInfoTime'

    @Component
    export default class HotelInfoTimeMod extends HotelInfoTime {}
</script>

<style scoped lang="scss">
    ::v-deep .v-slider__thumb-container {
        visibility: hidden;
    }

    ::v-deep .v-slider--horizontal .v-slider__track-container {
        height: 4px;
    }
</style>
