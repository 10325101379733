<template>
    <v-tabs v-model="mobileTabs" fixed-tabs class="sticky-bar">
        <v-tab>
            {{ $t('booking') }}
        </v-tab>
        <v-tab>
            {{ $t('summary') }}
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, Model, Watch} from 'vue-property-decorator'

    @Component
    export default class MobileInfoPageTabs extends Vue {
        @Model('change') value
        mobileTabs = 0

        @Watch('value')
        onChangeValue(val) {
            this.mobileTabs = val
        }

        @Watch('mobileTabs')
        onChangeMobileTabs(val) {
            this.$emit('change', val)
        }
    }
</script>
