<template>
    <order-summary-layout
        :product-store="productStore"
        :room-offers="roomOffers"
        :total-price="totalPrice"
        :offer="offer"
        class="hotel-order-summary"
    >
        <template v-slot>
            <div class="d-flex mb-4">
                <v-img
                    tile
                    :src="offer.thumb | imageUrl(350)"
                    :alt="offer.name"
                    eager
                    width="100"
                    max-width="100"
                    height="100"
                    :lazy-src="'/placeholder.png' | srcHost"
                    class="me-2"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && offer.thumb"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                    <div class="d-flex align-center flex-wrap">
                        <hotel-stars :hotel="offer" />
                        <hotel-type-label class="ms-1" :offer="offer" />
                        <v-img
                            v-if="offer && offer.recommended && showPreferred"
                            :src="'/preferred.png' | srcHost"
                            width="80px"
                            class="preferred-thumb flex-grow-0 mx-1"
                        />
                    </div>
                    <div class="font-weight-bold text-truncate">
                        {{ offer.name }}
                    </div>
                    <supplier-name-label v-if="isTO1" :supplier-name="offer.supplierName" />
                    <hotel-address-label
                        :address="offer.address"
                        :class="{'cursor-pointer': offer.coordinates && offer.coordinates.length}"
                        @click.native="mapModal = true"
                    />
                </div>
            </div>
            <slot name="summary" />
            <hotel-info-time class="mb-3" />
            <div v-for="(room, roomIndex) in roomOffers" :key="roomIndex">
                <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="roomInfo(room)">
                    <v-icon small left>mdi-information-outline</v-icon>
                    {{ room.name }}
                </div>
                <div class="caption">
                    <hotel-available-label :room="room" />
                    <hotel-meal-type-label
                        :hotel="offer"
                        :room="room"
                        :selected-meal-types="selectedMealTypes(roomIndex)"
                    />
                    <tariff-label :room="room" :hotel="offer" />
                    <conditions-label
                        :offer-key="room.groupedOffers[0].offerKey"
                        :price="room.price"
                        :offer="offer"
                        @conditions="showConditionsInfo(room)"
                    />
                </div>
                <div v-if="showPrice" class="room-price mt-1">
                    <room-price-label :price="roomPrice(roomIndex)" :room="room" />
                    <commission-amount :commission="room.price.commission" />
                    <net-amount :price="room.price" />
                    <rate-note :room="room" />
                </div>
                <extra-services-summary
                    :services="selectedMealTypes(roomIndex).filter(({price}) => price)"
                    label="meal_type"
                />
                <extra-services-summary
                    :services="roomExtraServices(roomIndex).filter(({isMealType}) => !isMealType)"
                />
                <v-divider v-if="roomCheckInOutExtraServices(roomIndex).length" class="mb-4" />
                <v-list-item v-if="roomCheckInOutExtraServices(roomIndex).length" class="d-block my-4 px-0">
                    <v-row class="flex-column">
                        <v-col
                            v-for="service in roomCheckInOutExtraServices(roomIndex)"
                            :key="service.time + service.text"
                            class="py-0 d-flex align-center caption"
                        >
                            <span>{{ `${$t(service.text)} ` }}{{ service.time }}</span>
                            <v-spacer />
                            <span>{{ service.price | price }}</span>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider v-if="roomIndex < roomOffers.length - 1" class="my-4" />
            </div>
            <add-ons-summary-list :selected-add-ons="selectedAddOns" />
            <conditions-modal>
                <template v-slot:info>
                    <hotel-conditions-modal-info />
                </template>
                <template v-slot:hotelPriceNote>
                    <hotel-price-note :search-request="searchRequest" />
                </template>
            </conditions-modal>
            <hotel-meal-type-info-modal />
            <hotel-room-info-modal />
            <additional-fees-modal />
            <client-only>
                <v-dialog v-if="offer.coordinates && offer.coordinates.length" v-model="mapModal" :max-width="500">
                    <map-location
                        v-if="mapModal"
                        :latitude="offer.coordinates[0]"
                        :longitude="offer.coordinates[1]"
                        :height="`500px`"
                    />
                </v-dialog>
            </client-only>
        </template>
        <template v-slot:violatedPolicies>
            <corporate-policy-violation v-if="corporatePolicyViolated" :policies="violatedPolicies" />
        </template>
    </order-summary-layout>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import {authStore, hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelCorporatePolicyViolationMixin from '@/mixins/HotelCorporatePolicyViolationMixin'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo'
    import {EventBus, SHOW_HOTEL_CONDITIONS_INFO, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import HotelMealTypeLabel from '~src/components/hotels/snippets/hotelMealTypeLabel.src'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import OriginalCurrencyNotification from '~src/components/booking/snippets/originalCurrencyNotification.src'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import RateNote from '~src/components/snippets/RateNote'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import OrderSummaryLayout from '~src/components/booking/orderSummaryLayout.src'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import HotelAvailableLabel from '~src/components/hotels/snippets/hotelAvailableLabel.src'
    import HotelTypeLabel from '@/components/hotels/snippets/HotelTypeLabel'
    import HotelOwnLabel from '~src/components/hotels/snippets/HotelOwnLabel'
    import HotelAddressLabel from '~src/components/hotels/snippets/hotelAddressLabel.src'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import RoomPriceLabel from '~src/components/hotels/booking/snippets/roomPriceLabel.src'
    import HotelInfoTime from '~src/components/info/hotelInfoTime.src'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import NetAmount from '@/components/snippets/NetAmount'
    import MapLocation from '~src/components/info/mapLocation.src'
    import AddOnsSummaryList from '@/components/booking/addOns/AddOnsSummaryList.vue'

    @Component({
        components: {
            AddOnsSummaryList,
            HotelInfoTime,
            RoomPriceLabel,
            ExtraServicesSummary,
            OrderSummaryLayout,
            HotelStars,
            AdditionalFeesModal,
            HotelRoomInfoModal,
            RateNote,
            OriginalCurrencyNotification,
            HotelMealTypeInfoModal,
            HotelMealTypeLabel,
            HotelConditionsModalInfo,
            ConditionsLabel,
            ConditionsModal,
            CommissionAmount,
            CorporatePolicyViolation,
            HotelPriceNote,
            TariffLabel,
            HotelAvailableLabel,
            HotelTypeLabel,
            HotelOwnLabel,
            HotelAddressLabel,
            SupplierNameLabel,
            NetAmount,
            MapLocation,
        },
    })
    export default class HotelOrderSummary extends mixins(HotelCorporatePolicyViolationMixin) {
        @Prop({required: true}) offer
        @Prop({required: true}) roomOffers
        @Prop({required: true}) searchRequest
        @Prop({default: true}) prebooked
        @Prop({required: true, type: Array}) selectedAddOns

        imgError = false
        mapModal = false

        onImgError() {
            this.imgError = true
            return false
        }

        roomPrice(roomIndex) {
            const totalPrice = this.$options.filters.convertPrice(this.roomOffers[roomIndex].price)
            const extraServices = hotelsStore.bookingAdditionalOptions[roomIndex]?.extraServices
            const mandatory = extraServices?.filter(extraService => extraService.mandatory) || []

            if (mandatory.length) {
                mandatory.forEach(
                    item => (totalPrice.amount -= item.salesTerms.find(el => el.type === 'CLIENT').price.amount)
                )
            }

            return totalPrice
        }

        showConditionsInfo(room) {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.offer, room)
        }

        roomExtraServices(roomIndex) {
            const extraServices = hotelsStore.bookingAdditionalOptions[roomIndex]?.extraServices
            const mandatory = extraServices?.filter(extraService => extraService.mandatory) || []

            mandatory.forEach(item => {
                const price = item.salesTerms.find(el => el.type === 'CLIENT')

                item.price = {
                    ...price.price,
                    ...price,
                }
                item.quantity = 1
            })

            return [
                ...mandatory,
                ...hotelsStore.bookingExtraServices.filter(
                    bookingExtraService => bookingExtraService.roomIndex === roomIndex
                ),
            ]
        }

        selectedMealTypes(roomIndex) {
            const defaultMealTypeName = this.roomOffers[roomIndex].mealTypeName
            const touristsCount = hotelsRuntimeStore.searchRequestRoomTouristsCount(this.searchRequest, roomIndex)
            const selectedMealTypes = this.roomExtraServices(roomIndex).reduce((selectedMealTypes, service) => {
                if (service.isMealType && defaultMealTypeName !== service.mealTypeName) {
                    const selectedMealType = selectedMealTypes.find(
                        ({mealTypeName}) => mealTypeName === service.mealTypeName
                    )
                    if (selectedMealType) {
                        selectedMealType.quantity++
                    } else {
                        selectedMealTypes.push({
                            mealTypeName: service.mealTypeName,
                            quantity: 1,
                            name: service.name,
                            price: service.price,
                        })
                    }
                }
                return selectedMealTypes
            }, [])
            const selectedMealTypesCount = selectedMealTypes.reduce(
                (selectedMealTypesCount, {quantity}) => selectedMealTypesCount + quantity,
                0
            )
            if (
                selectedMealTypes.findIndex(({mealTypeName}) => mealTypeName === defaultMealTypeName) === -1 &&
                touristsCount > selectedMealTypesCount
            ) {
                const extraMealType = hotelsStore.extraMealTypes[roomIndex]?.find(
                    ({name}) => name === defaultMealTypeName
                )
                if (extraMealType) {
                    selectedMealTypes.unshift({
                        name: extraMealType.code,
                        mealTypeName: extraMealType.name,
                        quantity: touristsCount - selectedMealTypesCount,
                    })
                }
            }
            return selectedMealTypes
        }

        roomCheckInOutExtraServices(roomIndex) {
            return hotelsStore.checkInOutExtraService[roomIndex]
                ? Object.values(hotelsStore.checkInOutExtraService[roomIndex])
                : []
        }

        roomInfo(room) {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, this.offer, room.groupedOffers[0].offerKey)
        }

        get showPreferred() {
            return false
        }

        get productStore() {
            return hotelsStore
        }

        get totalPrice() {
            return hotelsStore.orderTotalPrice(this.roomOffers, this.prebooked, this.selectedAddOns)
        }

        get showPrice() {
            return true
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>
