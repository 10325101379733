<template>
    <div class="d-flex full-width">
        <v-img v-if="!isMobile" src="/about-us.png" width="50%" />
        <div :class="{'full-width': isMobile}" class="d-flex flex-column justify-center">
            <div class="d-flex flex-column pb-8">
                <span class="caption text-uppercase font-weight-bold mb-1"> {{ $t('about_us') }} </span>
                <span class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                    <span> {{ $t('we_have') }} </span>
                    <span class="primary--text"> {{ $t('best_offers_month') }} </span>
                </span>
            </div>
            <div v-if="isMobile" class="d-flex justify-center full-width">
                <img src="/about-us.png" style="width: calc(70vw)" alt="" />
            </div>
            <div class="d-flex justify-space-between pt-8">
                <div class="d-flex flex-column align-center">
                    <span class="text-h3 font-weight-medium yellowlime--text"> 500+ </span>
                    <span class="caption"> {{ $t('customers') }} </span>
                </div>
                <div class="d-flex flex-column align-center">
                    <span class="text-h3 font-weight-medium yellowlime--text"> 1000+ </span>
                    <span class="caption"> {{ $t('searches') }}</span>
                </div>
                <div class="d-flex flex-column align-center">
                    <span class="text-h3 font-weight-medium yellowlime--text"> 500+ </span>
                    <span class="caption"> {{ $t('bookings') }} </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class AboutUs extends Vue {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
