<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {clone} from '@/utils/helpers'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {ACCOMMODATION, HOTEL} from '@/utils/hotels/hotels-const'
    import {ACTIVITY, EXCURSION} from '@/utils/activities/activities-const'
    import {TRANSFER} from '@/utils/transfers/transfers-const'
    import {salesTermsRsTransformer} from '@/utils/api-helpers'
    import {isPrepareBookResultClean, prepareBookResult} from '@/utils/PrepareBookAdapter'
    import {CHANGE_PRICE_EVENT, EventBus} from '@/utils/event-bus'
    import {CRUISE} from '@/utils/cruises/cruises-const'

    @Component
    export default class AddOnMixin extends Vue {
        selectedAddOns = []
        touristBookingFields = null
        basketKey = null
        tmpBasketKey = null
        basketPrepareBookResponses = null
        basketPrepareBookRequests = null

        mounted() {
            this.touristBookingFields = clone(runtimeStore.touristBookingFields)
        }

        selectAddOn(selectedAddOns) {
            this.selectedAddOns = selectedAddOns
        }

        async prepareBookWithAddOns() {
            runtimeStore.SET_BOOKING_ACTIVE(true)
            const {basketKey} = await this.$api.basket.post()
            this.tmpBasketKey = basketKey
            const prepareBookRequests = []
            const prepareBookProductTypes = []
            const prepareBookRequest = this.prepareBookRequest(this.touristBookingFields)
            if (this.isSavingWithoutDetails) {
                if (Array.isArray(prepareBookRequest)) {
                    prepareBookRequest.forEach(rq => {
                        delete rq.tourists
                    })
                } else {
                    delete prepareBookRequest.tourists
                }
            }
            const addToBasket = (productType, prepareBookRequest) => {
                prepareBookRequests.push(prepareBookRequest)
                prepareBookProductTypes.push(productType)
            }
            addToBasket(this.productType, prepareBookRequest)
            this.selectedAddOns.forEach(addOn => {
                let prepareBookRequest
                const touristsData = this.productType === HOTEL ? [].concat(...this.tourists) : this.tourists
                const tourists = this.prepareBookTourists(touristsData, addOn.touristBookingFields).map(
                    // eslint-disable-next-line no-unused-vars
                    ({alternativeMealTypeId, ...tourist}) => tourist
                )
                switch (addOn.productType) {
                    case ACCOMMODATION:
                        prepareBookRequest = [
                            {
                                ...(!this.isSavingWithoutDetails && {tourists}),
                                offerKey: addOn.product[0].offers[0].offerKey,
                            },
                        ]
                        break
                    case ACTIVITY:
                    case EXCURSION:
                        prepareBookRequest = {
                            ...(!this.isSavingWithoutDetails && {tourists}),
                            offerKey: addOn.product[0].offers[0].offerKey,
                        }
                        break
                    case TRANSFER:
                        prepareBookRequest = {
                            ...addOn.prepareBookRequest,
                            ...(!this.isSavingWithoutDetails && {tourists}),
                            offerKey: addOn.product[0].offers[0].offerKey,
                        }
                        break
                }
                addToBasket(addOn.productType, prepareBookRequest)
            })
            try {
                const basketPrepareBookResponses = []
                let index = 0
                for (const prepareBookRequest of prepareBookRequests) {
                    switch (prepareBookProductTypes[index]) {
                        case ACCOMMODATION:
                        case HOTEL:
                            basketPrepareBookResponses.push(
                                ...(await this.$api.prepareAccommodationBook.put(basketKey, prepareBookRequest))
                            )
                            break
                        case ACTIVITY:
                        case EXCURSION:
                            basketPrepareBookResponses.push(
                                await this.$api.prepareActivityBook.put(basketKey, prepareBookRequest)
                            )
                            break
                        case TRANSFER:
                            basketPrepareBookResponses.push(
                                await this.$api.prepareBookTransfer.put(basketKey, prepareBookRequest)
                            )
                            break
                        case CRUISE:
                            basketPrepareBookResponses.push(
                                await this.$api.prepareBookCruise.put(basketKey, prepareBookRequest)
                            )
                            break
                    }
                    index++
                }
                this.basketPrepareBookResponses = basketPrepareBookResponses
                this.basketPrepareBookRequests = prepareBookRequests
                if (isPrepareBookResultClean(prepareBookResult(this.prepareBookResponse, this.offersData))) {
                    if (!this.isSavingWithoutDetails) {
                        await this.paymentStepInit()
                    } else {
                        await this.bookWithoutDetails()
                    }
                }
            } catch (e) {
                this.prepareBookErrorProcessing(e)
                runtimeStore.SET_BOOKING_ACTIVE(false)
            }
        }

        async paymentStepInit() {
            const result = prepareBookResult(this.prepareBookResponse, this.offersData)
            this.dueToConfirmDate = result[0].dueToConfirmDate
            const mainServiceIndex = this.offerKeys ? this.offerKeys.length : 1
            let index = 0
            for (const {priceChanged, adjustedPriceAmount} of result.slice(0, mainServiceIndex)) {
                if (priceChanged || adjustedPriceAmount) {
                    if (this.offerKeys) {
                        EventBus.$emit(CHANGE_PRICE_EVENT, {
                            offerKey: this.offerKeys[index],
                            prepareBookResponse: this.prepareBookResponse.prepareBookResponses[index],
                        })
                    } else {
                        EventBus.$emit(CHANGE_PRICE_EVENT, {
                            offerKey: this.$route.query.offerKey,
                            prepareBookResponse: this.prepareBookResponse,
                        })
                    }
                }
                index++
            }
            if (this.tmpBasketKey) {
                runtimeStore.SET_BOOKING_ACTIVE(false)
                const changePrice = (offerKey, prepareBookResponse, addOnIndex) => {
                    const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
                    const addOn = this.selectedAddOns[addOnIndex]
                    addOn.product[0].offers[0].price = price
                    persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
                }
                let addOnIndex = 0
                for (const {priceChanged, adjustedPriceAmount} of result.slice(mainServiceIndex)) {
                    if (priceChanged || adjustedPriceAmount) {
                        const rq = this.basketPrepareBookRequests[index - mainServiceIndex + 1]
                        const offerKey = Array.isArray(rq) ? rq[0].offerKey : rq.offerKey
                        changePrice(offerKey, this.prepareBookResponse.prepareBookResponses[index], addOnIndex)
                    }
                    addOnIndex++
                    index++
                }
            }

            const loyaltyPointsOptions = await this.loyaltyPointsPaymentOptions(this.prepareBookResponse)
            await this.getPaymentOptions(loyaltyPointsOptions, this.tmpBasketKey)
            this.initBookingKey()
        }

        initBookingKey() {
            if (this.tmpBasketKey) {
                this.basketKey = this.tmpBasketKey
                persistentStore.ADD_BASKET_ADD_ONS({
                    basketKey: this.basketKey,
                    selectedAddOns: this.selectedAddOns,
                    offerKeys: this.offerKeys || [this.$route.query.offerKey],
                })
            } else {
                this.bookingKey = this.prepareBookResponse.bookingKey
            }
        }

        get vendorMessageOffers() {
            const bookingOffers = []
            const offerItemForVendorMessage = (productType, offer) => ({
                serviceName: offer.info?.name || offer.name,
                supplierCode: offer.supplierCode,
            })
            if (!this.selectedAddOns.length) {
                return this.roomOffers
                    ? this.roomOffers.map(room => ({
                          serviceName: room.name,
                          supplierCode: this.offer.supplierCode,
                      }))
                    : [this.offer]
            } else {
                if (this.roomOffers) {
                    bookingOffers.push(
                        ...this.roomOffers.map(room => ({
                            serviceName: this.offer.name + ' ' + room.name,
                            supplierCode: this.offer.supplierCode,
                        }))
                    )
                } else {
                    bookingOffers.push(offerItemForVendorMessage(this.productType, this.offer))
                }
            }
            this.selectedAddOns.forEach(addOn => {
                bookingOffers.push(offerItemForVendorMessage(addOn.productType, addOn.product[0]))
            })
            return bookingOffers
        }

        get offersData() {
            return this.vendorMessageOffers.map(({serviceName, supplierCode}) => ({name: serviceName, supplierCode}))
        }

        get prepareBookResponse() {
            if (this.basketPrepareBookResponses) {
                return {
                    prepareBookResponses: this.basketPrepareBookResponses.map(({initResponse}) => initResponse),
                }
            }
            return this.productStore.prepareBookResponse
        }

        get isPrepareBookResponseExist() {
            return Object.keys(this.prepareBookResponse).length > 0
        }
    }
</script>
