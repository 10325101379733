<template>
    <v-form ref="form" v-model="valid" :disabled="disabled" class="pa-4">
        <div class="text-subtitle-1 text-capitalize font-weight-bold">
            {{ product.name }}
        </div>
        <v-row>
            <v-col cols="12" md="4">
                <time-field
                    v-model="time"
                    :label="`${$t('tours.pickup_time')}*`"
                    :rules="[v => !!v || `${$t('time')} ${$t('validation.required')}`]"
                />
            </v-col>
            <v-col cols="12" md="8">
                <v-text-field
                    v-model="address"
                    :label="`${addressLabel}*`"
                    :rules="[v => !!v || `${addressLabel} ${$t('validation.required')}`]"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Vue, Prop, PropSync} from 'nuxt-property-decorator'
    import TimeField from '@/components/snippets/forms/TimeField'
    import {toursStore} from '@/utils/store-accessor'

    @Component({
        components: {TimeField},
    })
    export default class TourTransferForm extends Vue {
        @PropSync('_valid', {default: false}) valid
        @Prop({required: true}) product
        @Prop({required: true}) pointType
        @Prop({default: false, type: Boolean}) disabled

        async mounted() {
            const productOffer = this.product.offers.find(offer => offer.offerKey === this.product.selectedOfferKey)

            if (productOffer.time.length === 1) {
                this.time = productOffer.time[0]
            }
            if (productOffer[`${this.pointType}Info`]) {
                if (productOffer[`${this.pointType}Info`].type === 'address') {
                    this.address = productOffer[this.pointType].address
                } else if (
                    productOffer[`${this.pointType}Info`].type === 'STATION' ||
                    productOffer[`${this.pointType}Info`].type === 'AIRPORT'
                ) {
                    try {
                        const rs = await this.$api.transferInfo.get({offerKey: productOffer.offerKey})
                        this.address = rs.transferPoints.find(point => point.pointType === this.pointType).name
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                }
            }
        }

        get address() {
            const entryParam = toursStore.prepareBookRequest.entryParams.find(
                entry => entry.entryName === this.product.entryName
            )
            return entryParam[this.pointType].address
        }

        set address(val) {
            toursStore.SET_PREPARE_BOOK_ENTRY_PARAM_PROP({
                entryName: this.product.entryName,
                prop: this.pointType,
                val: {
                    type: 'address',
                    address: val,
                },
            })
        }

        get time() {
            const entryParam = toursStore.prepareBookRequest.entryParams.find(
                entry => entry.entryName === this.product.entryName
            )
            return entryParam.time
        }

        set time(val) {
            toursStore.SET_PREPARE_BOOK_ENTRY_PARAM_PROP({
                entryName: this.product.entryName,
                prop: 'time',
                val,
            })
        }

        get addressLabel() {
            return this.$t(`tours.${this.pointType}_address`)
        }
    }
</script>
