<template>
    <v-dialog v-model="modal" hide-overlay width="900">
        <template v-for="(offer, index) in entry.offers">
            <component
                :is="!$breakpoint.smAndDown ? 'hotel-offer-card' : 'hotel-offer-card-mobile'"
                :key="index"
                :offer="offer"
                :search-request="searchRequest"
                :initial-selected-offer-keys="selectedOfferKeys"
                is-package
                @change-selected-offer-keys="changeSelectedOfferKeys"
            >
                <template v-slot:price="{room}">
                    <tour-offer-delta-button
                        v-if="!searchRequest.rooms || (searchRequest.rooms && searchRequest.rooms.length === 1)"
                        :room="room"
                        :offer-key="offerKey"
                        :entry-name="entry.entryName"
                    />
                    <tour-multiple-room-offer-delta
                        v-else
                        :room="room"
                        :offer-key="offerKey"
                        :entry-name="entry.entryName"
                        :hotel-offer="offer"
                        class="pe-5"
                    />
                </template>
                <template v-slot:multiRoomSelect>
                    <div class="text-center">
                        <v-btn color="primary" class="mb-4" @click="modal = false">
                            {{ $t('select') }}
                        </v-btn>
                    </div>
                </template>
            </component>
        </template>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_TOUR_HOTEL_INFO} from '@/utils/event-bus'
    import TourOfferDeltaButton from '@/components/tours/search/TourOfferDeltaButton'
    import TourMultipleRoomOfferDelta from '@/components/tours/search/TourMultipleRoomOfferDelta.vue'
    import {toursRuntimeStore} from '@/utils/store-accessor'

    @Component({
        components: {
            TourMultipleRoomOfferDelta,
            HotelOfferCard: () => import('@/components/search/offers/HotelOfferCard'),
            HotelOfferCardMobile: () => import('@/components/search/offers/HotelOfferCardMobile'),
            TourOfferDeltaButton,
        },
    })
    export default class TourHotelInfoModal extends Vue {
        @Prop({required: true}) searchRequest

        modal = false
        entry = {}
        offerKey = null
        selectedOfferKeys = []

        mounted() {
            EventBus.$on(SHOW_TOUR_HOTEL_INFO, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TOUR_HOTEL_INFO, this.showModal)
        }

        showModal(entry, offerKey) {
            this.entry = entry
            this.entry.offers = [...this.entry.offers].sort(
                (a, b) => a.rooms[0].notDeltaPrice.amount - b.rooms[0].notDeltaPrice.amount
            )
            this.offerKey = offerKey
            if (entry.selectedOfferKeys) this.selectedOfferKeys = entry.selectedOfferKeys
            this.modal = true
        }

        changeSelectedOfferKeys(selectedOfferKeys) {
            toursRuntimeStore.SELECT_OFFER({
                offerKey: this.offerKey,
                selectedOfferKey: selectedOfferKeys[0],
                selectedOfferKeys,
            })
        }
    }
</script>
