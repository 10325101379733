<template>
    <div v-show="selectedFilters.length" class="mb-2">
        <div class="selected_title">
            {{ $t('selected_filters') }}
        </div>
        <v-chip
            v-for="item in selectedFilters"
            :key="item.key"
            color="primary"
            close
            small
            class="mr-1"
            @click:close="deleteFilter(item.key)"
        >
            {{ item.label }}
        </v-chip>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import CityAutocomplete from '@/components/search/forms/CityAutocomplete'
    import OrderCreateDaterange from '@/components/account/filters/OrderCreateDaterange'
    import OrderServiceDaterange from '@/components/account/filters/OrderServiceDaterange'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {orderFilters} from '@/utils/store-blank-states'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            CityAutocomplete,
            OrderCreateDaterange,
            OrderServiceDaterange,
        },
    })
    export default class OrderSelectedFilters extends Vue {
        get selectedFilters() {
            const selectedFilters = [],
                filters = clone(persistentStore.orderFilters)
            if (filters.active !== 'all' && filters.active !== 'cancelled') {
                selectedFilters.push({
                    key: 'active',
                    label: filters.active ? this.$t('future_trips') : this.$t('past_trips'),
                })
            }
            const setTextLabel = (key, label, translationKey) => {
                if (filters[key]) {
                    selectedFilters.push({
                        key,
                        label:
                            `${this.$t(label)}: ` +
                            (translationKey ? this.$t(`${translationKey}.${filters[key]}`) : filters[key]),
                    })
                }
            }
            const setArrayLabel = (key, label, translationKey) => {
                if (filters[key].length) {
                    if (this.$config.account.plainPaymentStatus || this.$config.account.paymentScheduler) {
                        if (key === 'customerPaymentStatuses') {
                            translationKey = 'plainPaymentStatus'
                            filters.customerPaymentStatuses = runtimeStore.plainPaymentStatuses(
                                filters.customerPaymentStatuses
                            )
                        } else if (key === 'supplierPaymentStatuses') {
                            translationKey = 'plainPaymentStatus'
                            filters.supplierPaymentStatuses = runtimeStore.plainPaymentStatuses(
                                filters.supplierPaymentStatuses
                            )
                        }
                    }
                    selectedFilters.push({
                        key,
                        label:
                            `${this.$t(label)}: ` +
                            filters[key]
                                .map(value => this.$t(translationKey ? `${translationKey}.${value}` : value))
                                .join(', '),
                    })
                }
            }
            if (filters.filterServices) {
                selectedFilters.push({
                    key: 'filterServices',
                    label: this.$t('apply_filter_to_services'),
                })
            }
            setTextLabel('orderId', 'order_number')
            setTextLabel('serviceName', 'service_name')
            setTextLabel('travelerName', 'traveler_name')
            setTextLabel('agentCompanyConsortium', 'agent_consortium')
            setTextLabel('agentCompanySubconsortium', 'agent_sub_consortium')

            if (filters.city) {
                selectedFilters.push({
                    key: 'city',
                    label: filters.city.name,
                })
            }
            setTextLabel('createdDateFrom', 'create_date_from')
            setTextLabel('createdDateTo', 'create_date_to')
            setTextLabel('serviceStartDateFrom', 'start_date_from')
            setTextLabel('serviceStartDateTo', 'start_date_to')
            setTextLabel('serviceEndDateFrom', 'end_date_from')
            setTextLabel('serviceEndDateTo', 'end_date_to')
            setTextLabel('modifiedDateFrom', 'modified_date_from')
            setTextLabel('modifiedDateTo', 'modified_date_to')
            setTextLabel('lastTicketingDateFrom', 'last_ticketing_date_from')
            setTextLabel('lastTicketingDateTo', 'last_ticketing_date_to')
            setTextLabel('ticketNumber', 'ticket_number')
            setTextLabel('reservationName', 'reservation_title')
            setTextLabel('promoCode', 'promocode')
            setTextLabel('loyaltyPointsMin', 'loyalty_from')
            setTextLabel('loyaltyPointsMax', 'loyalty_to')
            setTextLabel('clientType', 'client_type')
            setTextLabel('crmPriority', 'client_priority')
            setTextLabel('waitAuthorize', 'approval_required')
            setTextLabel('supplierRef', 'supplier_ref')
            setTextLabel('channelManagerCode', 'channel_manager_name')
            setTextLabel('chmPartnerBookingNumber', 'channel_manager_ref')
            setArrayLabel('serviceTypes', 'service_types')
            setArrayLabel('processStatuses', 'process_statuses', 'bookingStatus')
            setArrayLabel('customerPaymentStatuses', 'customer_payment_status', 'paymentStatus')
            setArrayLabel('customerInvoiceStatuses', 'client_invoice_status', 'paymentStatus')
            setArrayLabel('customerPenalty', 'customer_penalty', 'penalty')
            setArrayLabel('supplierPenalty', 'supplier_penalty', 'penalty')
            setArrayLabel('reservationStatus', 'reservation_statuses', 'orderStatus')
            setArrayLabel('supplierPaymentStatuses', 'supplier_payment_status', 'paymentStatus')
            setArrayLabel('supplierInvoiceStatuses', 'supplier_invoice_status', 'paymentStatus')
            setArrayLabel('bookingMethod', 'booked_from', 'booking_methods')

            if (Array.isArray(filters.paymentMethod)) {
                setArrayLabel('paymentMethod', 'payment_method')
            } else if (filters.paymentMethod) {
                selectedFilters.push({
                    key: 'paymentMethod',
                    label: filters.paymentMethod,
                })
            }
            if (filters.supplier) {
                selectedFilters.push({
                    key: 'supplier',
                    label: `${this.$t('supplier_name')}: ${filters.supplier.name}`,
                })
            }
            if (filters.reservationManager) {
                const {firstName, lastName} = filters.reservationManager
                const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`

                selectedFilters.push({
                    key: 'reservationManager',
                    label: `${this.$t('reservation_manager')}: ${name}`,
                })
            }
            if (filters.agent) {
                const {firstName, lastName} = filters.agent
                const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`

                selectedFilters.push({
                    key: 'agent',
                    label: `${this.$t('agent_name')}: ${name}`,
                })
            }
            if (filters.tripOrganizer) {
                const {firstName, lastName} = filters.tripOrganizer
                const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`

                selectedFilters.push({
                    key: 'tripOrganizer',
                    label: `${this.$t('trip_organizer_name')}: ${name}`,
                })
            }
            if (filters.serviceManager) {
                const {firstName, lastName} = filters.serviceManager
                const name = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`

                selectedFilters.push({
                    key: 'serviceManager',
                    label: `${this.$t('service_manager')}: ${name}`,
                })
            }
            if (filters.customerCompany) {
                const {name} = filters.customerCompany

                selectedFilters.push({
                    key: 'customerCompany',
                    label: `${this.$t('customer_company')}: ${name}`,
                })
            }
            if (filters.client) {
                const prefix = filters.client.prefix ? filters.client.prefix : ''
                const firstName = filters.client.firstName ? filters.client.firstName : ''
                const lastName = filters.client.lastName ? filters.client.lastName : ''
                const label =
                    (prefix ? `${prefix} ` : '') + (firstName ? `${firstName} ` : '') + (lastName ? `${lastName}` : '')

                selectedFilters.push({
                    key: 'client',
                    label: `${this.$t('client_name')}: ${label}`,
                })
            }
            if (filters.waitingApprovalFromMyself) {
                selectedFilters.push({
                    key: 'waitingApprovalFromMyself',
                    label: this.$t('waiting_approval_from_myself'),
                })
            }
            if (filters.noSupplierRef) {
                selectedFilters.push({
                    key: 'noSupplierRef',
                    label: this.$t('empty_supplier_ref'),
                })
            }

            return selectedFilters
        }

        deleteFilter(key) {
            const filter = {}

            filter[key] = orderFilters()[key]
            if (key === 'reservationStatus') {
                filter.active = 'all'
            }

            persistentStore.SET_ORDER_FILTERS(Object.assign({}, persistentStore.orderFilters, filter))
            this.$emit('filter')
        }
    }
</script>
