<template>
    <div>
        <v-dialog v-model="serviceTipsDialog" width="1200">
            <order-details-tips :tips="serviceTips" :loaded="true" show-close @close="serviceTipsDialog = false" />
        </v-dialog>
        <delete-service-dialog
            v-model="isShowDeleteServiceDialog"
            :process-id="service.processId"
            :service-name="service.serviceName"
            @update-order="$emit('modifyService', $event)"
        />
        <v-card outlined class="mb-2 service-card">
            <v-alert
                color="grey lighten-4 service-card-header"
                class="black--text mb-0"
                @click="isMinimized = !isMinimized"
            >
                <div class="d-flex flex-wrap flex-md-nowrap" :class="$breakpoint.smAndDown ? 'flex-column' : ''">
                    <div class="d-flex justify-center flex-column">
                        <div class="d-flex flex-row align-center" :class="{'mb-1': isMinimized === true}">
                            <slot name="checkbox" />
                            <template v-if="service.serviceType === 'EXTRASERVICE'">
                                <v-icon color="primary" class="mr-2 mt-1 align-self-start">
                                    {{ 'mdi-cloud-off-outline' }}
                                </v-icon>
                                <v-icon color="primary" class="mr-2 mt-1 align-self-start">
                                    {{ extraServiceTypeIcon }}
                                </v-icon>
                            </template>
                            <v-icon v-else color="primary" class="mr-2 mt-1 align-self-start">
                                {{ serviceTypeIcon }}
                            </v-icon>
                            <span class="service-name mr-1 text-h6 font-weight-bold" v-text="serviceName" />
                            <hotel-stars
                                v-if="service.serviceDetails[0].category || service.serviceDetails[0].ratings"
                                :hotel="service.serviceDetails[0]"
                            />
                            <v-icon class="info-icon me-2" right small color="black" @click.stop="serviceInfo">
                                mdi-information-outline
                            </v-icon>
                            <v-chip v-if="serviceType" label x-small>
                                {{ $t(`filters_${service.serviceType.toLowerCase()}Type.${serviceType}`) }}
                            </v-chip>
                            <div
                                v-if="serviceTips && serviceTips.length"
                                class="cursor-pointer"
                                @click.stop="serviceTipsDialog = true"
                            >
                                <service-tips-icon />
                            </div>
                            <v-btn v-if="isTO1" icon @click.stop="showServiceNotes">
                                <v-icon small :title="$tc('internal_note', 2)">mdi-note-plus-outline</v-icon>
                            </v-btn>
                            <v-tooltip v-if="isAddOn" top>
                                <template v-slot:activator="{on}">
                                    <v-icon small color="primary" v-on="on">mdi-puzzle</v-icon>
                                </template>
                                {{
                                    $t('add_on_to_service', {
                                        service: addOnBaseService.serviceName,
                                    })
                                }}
                            </v-tooltip>
                        </div>
                        <v-expand-transition>
                            <div v-if="isMinimized === true" class="d-flex">
                                <div style="width: 40px" />
                                <div>
                                    <div class="d-flex date mb-2 text-caption">
                                        <span v-text="dateString" />
                                    </div>
                                    <div
                                        class="d-flex flex-row text-caption font-weight-bold grey--text text--darken-2"
                                        style="min-height: 1.25rem"
                                    >
                                        <span class="travelers" v-text="travelersString" />
                                    </div>
                                </div>
                            </div>
                        </v-expand-transition>
                    </div>
                    <v-spacer class="hidden-sm-and-down" />
                    <div class="d-flex justify-end align-center" :class="[{'ml-7 mt-2': $breakpoint.smAndDown}]">
                        <div v-if="isShowBookingMethod && service.bookingMethod">
                            <v-chip small class="mx-4" :color="bookingMethodColor" text-color="white">
                                <span>
                                    <span v-if="service.bookingMethod !== 'MANUALLY_AMENDED'">
                                        {{ $t('booked_from_label') }}
                                    </span>
                                    <span>
                                        {{ $t(`booking_methods.${service.bookingMethod}`) }}
                                    </span>
                                </span>
                            </v-chip>
                        </div>
                        <div
                            class="d-flex flex-column justify-md-center"
                            :class="[{'text-right': !$breakpoint.smAndDown}]"
                        >
                            <div
                                class="d-flex flex-row align-center"
                                :class="[{'mb-1': !!lastTicketingDate}, {'ml-auto': !$breakpoint.smAndDown}]"
                            >
                                <v-btn
                                    v-if="service.status.toUpperCase() === 'QUOTE' && isTO1"
                                    icon
                                    class="me-1"
                                    @click.stop="deleteService"
                                >
                                    <v-icon> mdi-trash-can-outline </v-icon>
                                </v-btn>
                                <v-icon
                                    v-if="service.status.toUpperCase() === 'CONFIRMED'"
                                    :class="statusColor"
                                    class="mr-1"
                                >
                                    mdi-checkbox-marked-circle
                                </v-icon>
                                <v-icon v-else class="mr-1" :class="statusColor">mdi-checkbox-blank-circle</v-icon>
                                <span
                                    class="ml-1 font-weight-bold status-text"
                                    :class="[{'fs-16': $breakpoint.smAndDown}, {'fs-14': !$breakpoint.smAndDown}]"
                                    v-text="$t(`bookingStatus.${status}`)"
                                />
                            </div>
                            <small v-if="lastTicketingDate" class="d-block mt-1 ml-1">
                                {{ $t('flights.last_ticketing_date') }}:
                                <strong>{{ lastTicketingDate | dateTimeFormat }}</strong>
                            </small>
                        </div>
                        <v-icon icon color="primary" :class="['mx-2 align-self-end-sm', {'chevron-down': isMinimized}]">
                            {{ isMinimized ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                        </v-icon>
                    </div>
                </div>
            </v-alert>
            <v-expand-transition>
                <div v-show="!isMinimized">
                    <v-card-text>
                        <slot name="orderInfo" />
                        <v-divider class="mt-3" />
                        <slot name="serviceInfo" />
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import HotelStars from '~src/components/hotels/snippets/hotelStars.src'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import OrderDetailsTips from '@/components/account/OrderDetailsTips'
    import ServiceTipsIcon from '~src/components/account/orderServices/serviceTipsIcon.src'
    import {EventBus, SHOW_SERVICE_NOTES_MODAL} from '@/utils/event-bus'
    import DeleteServiceDialog from '@/components/account/orderServices/DeleteServiceDialog'

    @Component({
        components: {DeleteServiceDialog, ServiceTipsIcon, OrderDetailsTips, HotelStars},
    })
    export default class ServiceCard extends Vue {
        @Prop() icon
        @Prop() service
        @Prop() isFirst
        @Prop() extraServiceName
        @Prop() serviceTips
        @Prop({required: true}) order

        isShowDeleteServiceDialog = false
        isMinimized = false
        serviceTipsDialog = false

        created() {
            if (this.isFirst === false) {
                this.isMinimized = true
            }
        }

        serviceInfo() {
            this.$emit('showInfo')
        }

        showServiceNotes() {
            EventBus.$emit(SHOW_SERVICE_NOTES_MODAL, this.service)
        }

        deleteService() {
            this.isShowDeleteServiceDialog = true
        }

        get isShowBookingMethod() {
            return this.isTO1 && this.isOwnProduct
        }

        get bookingMethodColor() {
            switch (this.service.bookingMethod) {
                case 'MANUALLY_AMENDED':
                    return 'primary'
                case 'ALLOTMENT':
                    return 'success'
                case 'ON_REQUEST':
                    return 'warning'
                default:
                    return 'error'
            }
        }

        get isOwnProduct() {
            return runtimeStore.ownProduct(this.service.supplierCode)
        }

        get serviceType() {
            return this.service.serviceDetails[0].type || this.service.serviceDetails[0].transferType
        }

        get statusColor() {
            return runtimeStore.orderServiceStatusColor(this.status)
        }

        get status() {
            return runtimeStore.orderServiceStatus(this.service.status)
        }

        get lastTicketingDate() {
            return this.service.serviceDetails[0].lastTicketingDate
        }

        get serviceTypeIcon() {
            return getServiceTypeIcon(this.service.serviceType)
        }

        get extraServiceTypeIcon() {
            return getServiceTypeIcon(this.service.serviceDetails[0]?.extraServiceProductType)
        }

        get serviceName() {
            return (
                this.service.serviceDetails[0].flightName ||
                this.service.serviceDetails[0].transferName ||
                this.service.serviceName
            )
        }

        get dateString() {
            const formattedDateStart = this.$options.filters.dateFormat(this.service.startDateTime)
            const formattedDateEnd = this.$options.filters.dateFormat(this.service.endDateTime)

            return (
                formattedDateStart + (formattedDateEnd === formattedDateStart ? '' : `\u00A0-\u00A0${formattedDateEnd}`)
            )
        }

        get travelersString() {
            if (this.service.travelers.length > 2) {
                let tourLead =
                    this.service.travelers.find(t => {
                        t.isTourLead
                    }) || this.service.travelers[0]
                return `${tourLead.name[0].firstName} ${tourLead.name[0].lastName || ''} + ${
                    this.service.travelers.length - 1
                }`
            } else {
                return this.service.travelers
                    .filter(t => t.name[0].firstName || t.name[0].lastName)
                    .map(e => {
                        return `${e.name[0].firstName || ''} ${e.name[0].lastName || ''}`
                    })
                    .join(', ')
            }
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isAddOn() {
            return !!this.service.addOnServiceId && this.service.serviceType !== this.addOnBaseService.serviceType
        }

        get addOnBaseService() {
            return this.order.services.find(service => service.serviceId === this.service.addOnServiceId)
        }
    }
</script>
