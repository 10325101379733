<template>
    <search-form-layout :popup="popup" title="tours.tours" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="10" cols="12">
                        <v-row>
                            <v-col v-if="$route.name !== 'tour'" md="8" cols="12">
                                <v-row>
                                    <v-col md="4" cols="12">
                                        <city-autocomplete
                                            v-model="departureCity"
                                            label="tours.departure"
                                            single-line
                                            filled
                                        />
                                    </v-col>
                                    <v-col md="4" cols="12">
                                        <city-autocomplete
                                            v-model="arrivalCity"
                                            :rules="[
                                                v => !!v || `${$t('tours.destination')} ${$t('validation.required')}`,
                                            ]"
                                            label="tours.destination"
                                            single-line
                                            filled
                                        />
                                    </v-col>
                                    <v-col md="4" cols="12">
                                        <daterange-m :start-date.sync="startDateFrom" :end-date.sync="startDateTo" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-else md="6" cols="12">
                                <daterange-m :start-date.sync="startDateFrom" :end-date.sync="startDateTo" />
                            </v-col>
                            <v-col :md="$route.name !== 'tour' ? 4 : 6" cols="12">
                                <v-row>
                                    <v-col md="6" cols="12">
                                        <nights-select-menu
                                            :_nights-from.sync="nightsFrom"
                                            :_nights-to.sync="nightsTo"
                                        />
                                    </v-col>
                                    <v-col md="6" cols="12">
                                        <tourists-select-menu
                                            :_adults.sync="adults"
                                            :_children-ages.sync="childrenAges"
                                            token="guest"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['citizenship', 'meal', 'rating', 'freeOnly']" />
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {authStore, persistentStore, toursRuntimeStore, toursStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '@/components/search/forms/TouristsSelectMenu'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import DaterangeM from '~/components/search/forms/DaterangeM'
    import NightsSelectMenu from '@/components/tours/search/forms/NightsSelectMenu'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            SearchFormLayout,
            DaterangeM,
            NightsSelectMenu,
            AdditionalOptionsWrapper,
        },
    })
    export default class TourSearchForm extends mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup

        valid = false
        departureCity = {}
        arrivalCity = {}
        startDateFrom = null // TODO: rename to 'DateFrom'
        startDateTo = null // TODO: rename to 'DateTo'
        nightsFrom = 2
        nightsTo = 5
        adults = 2
        childrenAges = []

        async mounted() {
            await this.load()
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            if (this.departureCity && this.departureCity.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.departureCity.id, limitCities: 1})
                    this.departureCity = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            if (this.arrivalCity && this.arrivalCity.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.arrivalCity.id, limitCities: 1})
                    this.arrivalCity = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async load() {
            await this.$store.restored
            await this.$nextTick()
            const departureCityId = this.departureCity.id || this.searchRequest.departureCityId
            const arrivalCityId = this.arrivalCity.id || this.searchRequest.arrivalCityId
            if (departureCityId && toursRuntimeStore.departureCity.id !== departureCityId) {
                await toursRuntimeStore.loadDepartureCity(departureCityId)
            }
            if (arrivalCityId && toursRuntimeStore.arrivalCity.id !== arrivalCityId) {
                await toursRuntimeStore.loadArrivalCity(arrivalCityId)
            }
            this.departureCity =
                departureCityId && Object.keys(toursRuntimeStore.departureCity).length
                    ? toursRuntimeStore.departureCity
                    : null
            this.arrivalCity =
                arrivalCityId && Object.keys(toursRuntimeStore.arrivalCity).length
                    ? toursRuntimeStore.arrivalCity
                    : null

            if (this.$dateFns.isAfter(this.$dateFns.parseISO(this.searchRequest.startDateFrom), new Date())) {
                this.startDateFrom = this.searchRequest.startDateFrom
                this.startDateTo = this.searchRequest.startDateTo
            } else {
                this.startDateFrom = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.startDateTo = this.$dateFns.format(this.$dateFns.addDays(new Date(), 30))
            }
            this.nightsFrom = this.$route.query.durationFrom
                ? this.$route.query.durationFrom - 1
                : this.searchRequest.durationFrom - 1
            this.nightsTo = this.$route.query.durationTo
                ? this.$route.query.durationTo - 1
                : this.searchRequest.durationTo - 1
            this.adults = authStore.user.isEmployeeOrExternalCorp
                ? 1
                : this.$route.query.adults
                ? Number(this.$route.query.adults)
                : Number(this.searchRequest.adults)
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp ? [] : [...this.searchRequest.childrenAges]
        }

        async search() {
            this.$emit('close-popup')
            const query = this.newQuery()
            toursRuntimeStore.SET_DEPARTURE_CITY(this.departureCity)
            toursRuntimeStore.SET_ARRIVAL_CITY(this.arrivalCity)
            const name = 'tourSearch'
            if (this.$route.name !== name) {
                toursRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await toursRuntimeStore.search(toursStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        newQuery() {
            const query = {
                arrivalCityId: this.arrivalCity.id,
                startDateFrom: this.startDateFrom,
                startDateTo: this.startDateTo,
                durationFrom: this.nightsFrom + 1,
                durationTo: this.nightsTo + 1,
                // TODO: rewrite for nights
                // nightsFrom: this.nightsFrom,
                // nightsTo: this.nightsTo,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }
            if (this.departureCity) query.departureCityId = this.departureCity.id

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        get formattedDate() {
            return this.startDateFrom !== null ? this.$options.filters.dateFormat(this.startDateFrom) : ''
        }

        get searchRequest() {
            return toursStore.searchRequest
        }
    }
</script>
