<template>
    <div
        class="d-flex justify-center align-center px-5 rounded-lg"
        style="padding-bottom: 84px !important; background: #f5f8f8; padding-top: 90px"
    >
        <div class="full-width">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                        <span>{{ $t('could_not_find') }}</span>
                        <span class="primary--text">{{ $t('answer_to_question') }}</span>
                    </div>
                    <div v-if="contacts && contacts[0]" class="d-flex flex-column mt-8">
                        <div class="d-flex align-center my-2">
                            <v-sheet
                                color="primary"
                                width="40"
                                height="40"
                                class="d-flex align-center justify-center rounded-lg me-3"
                            >
                                <v-icon color="white">mdi-map-marker</v-icon>
                            </v-sheet>
                            <span>{{ contacts[0].description }} </span>
                        </div>
                        <a class="text-decoration-none" :href="`mailto:${contacts[0].email}`">
                            <div class="d-flex align-center my-2">
                                <v-sheet
                                    color="yellowlime"
                                    width="40"
                                    height="40"
                                    class="d-flex align-center justify-center rounded-lg me-3"
                                >
                                    <v-icon color="white">mdi-email</v-icon>
                                </v-sheet>
                                <span class="black--text">{{ contacts[0].email }}</span>
                            </div>
                        </a>
                        <a :href="`tel:${contacts[0].phone}`" class="text-decoration-none">
                            <div class="d-flex align-center my-2">
                                <v-sheet
                                    color="warning"
                                    width="40"
                                    height="40"
                                    class="d-flex align-center justify-center rounded-lg me-3"
                                >
                                    <v-icon color="white">mdi-cellphone</v-icon>
                                </v-sheet>
                                <span class="black--text">{{ contacts[0].phone }} </span>
                            </div>
                        </a>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <questions-block-form class="mt-1" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {cmsStore} from '@/utils/store-accessor'
    import QuestionsBlockForm from '~~/mods/ajbooking/components/parts/QuestionsBlockForm'

    @Component({
        components: {QuestionsBlockForm},
    })
    export default class QuestionsBlock extends Vue {
        get contacts() {
            return cmsStore.layoutSettings.contacts
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
