<template>
    <div style="padding-bottom: 60px">
        <v-container style="max-width: none" class="full-width">
            <slot v-if="!isMobile" name="breadcrumbs">
                <breadcrumbs />
            </slot>
            <v-row>
                <v-col cols="12" :md="12">
                    <v-row class="pt-0">
                        <slot v-if="!isMobile || (isMobile && mobileTabs === 3)" name="preinfo" />

                        <v-col
                            v-show="!isMobile || (isMobile && mobileTabs === 0)"
                            :md="!mapExpanded ? 3 : 12"
                            :xl="!mapExpanded ? 3 : 12"
                        >
                            <slot name="left-sidebar" />
                        </v-col>
                        <v-col v-show="(!mapExpanded && !isMobile) || (isMobile && mobileTabs === 1)" :md="9" xl="9">
                            <slot name="main-content" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="isMobile && mobileTabs === 2">
                    <slot name="right-sidebar" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import SearchLayout from '~/components/parts/SearchLayout'

    @Component({})
    export default class SearchLayoutMod extends SearchLayout {
        @Prop() mobileTabs
    }
</script>
