<template>
    <search-form-layout :popup="popup" :title="productName" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="3" cols="12">
                        <country-autocomplete
                            v-model="location"
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            label="destination"
                            single-line
                            filled
                        />
                    </v-col>
                    <v-col md="3" cols="12">
                        <daterange-m :start-date.sync="startDate" :end-date.sync="endDate" />
                    </v-col>
                    <v-col md="2" cols="12">
                        <days-select :_value.sync="daysCount" :max="maxDaysCount" name="daysCount" />
                    </v-col>
                    <v-col md="2" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="person"
                        />
                    </v-col>
                    <v-col md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :suppliers="suppliers"
                :options="['citizenship', 'internalSuppliers', 'externalSuppliers', 'supplierId']"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import SearchFormLayout from '~src/components/parts/searchFormLayout.src'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import {authStore, insuranceRuntimeStore, insuranceStore, persistentStore} from '@/store'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'
    import CountryAutocomplete from '~/components/search/forms/CountryAutocomplete'
    import DaterangeM from '~/components/search/forms/DaterangeM'
    import DaysSelect from '@/components/snippets/AdditionalSearchOptions/DaysSelect'
    import TouristsSelectMenu from '@/components/search/forms/TouristsSelectMenu'

    @Component({
        components: {
            SearchFormLayout,
            AdditionalOptionsWrapper,
            CountryAutocomplete,
            DaterangeM,
            DaysSelect,
            TouristsSelectMenu,
        },
    })
    export default class InsuranceSearchForm extends Vue {
        @Prop({default: false}) popup
        @Prop() productName

        valid = false
        location = {}
        startDate = null
        endDate = null
        daysCount = 3
        adults = 2
        childrenAges = []
        suppliers = []

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        async mounted() {
            await this.load()
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async load() {
            await this.$store.restored
            await this.$nextTick()
            const locationId = this.location.id || this.searchRequest.locationId
            if (locationId && insuranceRuntimeStore.location.id !== locationId) {
                await insuranceRuntimeStore.loadLocation(locationId)
            }
            this.location =
                locationId && Object.keys(insuranceRuntimeStore.location).length ? insuranceRuntimeStore.location : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(this.searchRequest.startDate), new Date())) {
                this.startDate = this.searchRequest.startDate
                this.endDate = this.searchRequest.endDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 10))
            }
            this.daysCount = this.searchRequest.daysCount
            this.adults = authStore.user.isEmployeeOrExternalCorp ? 1 : this.searchRequest.adults
            this.childrenAges = authStore.user.isEmployeeOrExternalCorp ? [] : [...this.searchRequest.childrenAges]
            if (this.isTO1) {
                this.suppliers = (await this.$api.suppliers.get({productType: 'insurance'}))?.suppliers
            }
        }

        async reload() {
            if (this.location && this.location.id) {
                try {
                    const {
                        countries: [country],
                    } = await this.$api.locations.get(this.location.id)
                    this.location = country
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async search() {
            this.$emit('close-popup')
            const query = this.newQuery()
            insuranceRuntimeStore.SET_DESTINATION_COUNTRY(this.location)
            const name = 'insuranceSearch'
            if (this.$route.name !== name) {
                insuranceRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await insuranceRuntimeStore.search(insuranceStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        newQuery() {
            const query = {
                locationId: this.location.id,
                startDate: this.startDate,
                endDate: this.endDate,
                daysCount: this.daysCount,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }

            if (persistentStore.selectedAgent) {
                query.clientId = persistentStore.selectedAgent.companyId
                query.agentId = persistentStore.selectedAgent.personId
            }

            return query
        }

        get isTO1() {
            return authStore.isTO1
        }

        get searchRequest() {
            return insuranceStore.searchRequest
        }

        get maxDaysCount() {
            return (
                this.$dateFns.differenceInDays(
                    this.$dateFns.parseISO(this.endDate),
                    this.$dateFns.parseISO(this.startDate)
                ) + 1
            )
        }
    }
</script>
