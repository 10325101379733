<template>
    <v-dialog :value="show && messages.length > 0" hide-overlay width="100%" max-width="600" persistent>
        <v-card>
            <v-card-text class="text-center pt-8">
                <div class="warning--text text-h5 mb-2">
                    <v-icon large color="warning">mdi-alert-outline</v-icon>
                    {{ $t('important_information') }}
                </div>
                <div class="mb-2">
                    {{ $t('vendor_message_note') }}
                </div>
                <div
                    v-for="({name, vendorMessages, tips, warningMessages, errorMessages}, index) in messages"
                    :key="index"
                >
                    <span v-if="prepareBookResponse.prepareBookResponses" class="text-subtitle-1 font-weight-bold">
                        {{ name }}
                    </span>
                    <div
                        v-for="(message, messageIndex) in errorMessages"
                        :key="messageIndex"
                        class="error--text font-weight-bold"
                        v-text="message"
                    />
                    <div
                        v-for="(message, messageIndex) in warningMessages"
                        :key="messageIndex"
                        class="warning--text font-weight-bold"
                        v-text="message"
                    />
                    <div
                        v-for="(message, messageIndex) in vendorMessages"
                        :key="messageIndex"
                        class="font-weight-bold"
                        v-html="message"
                    />
                    <order-details-tips class="mb-2" :outlined="false" :tips="tips" :loaded="true" center />
                </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-center">
                <v-btn v-if="availableForBooking" color="primary" text @click="approve">
                    {{ $t('continue') }}
                </v-btn>
                <v-btn text @click="cancel">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import OrderDetailsTips from '@/components/account/OrderDetailsTips.vue'
    import {prepareBookResult, STANDARD_ERROR_MESSAGES, WARNING_MESSAGE_PRICE_CHANGED} from '@/utils/PrepareBookAdapter'

    @Component({
        components: {OrderDetailsTips},
    })
    export default class PrepareBookMessagesModal extends Vue {
        @Prop({required: true, type: Object}) prepareBookResponse
        @Prop({required: true, type: Array}) offersData

        show = true

        @Emit()
        approve() {
            this.show = false
        }

        @Emit()
        cancel() {
            this.show = false
        }

        get messages() {
            return prepareBookResult(this.prepareBookResponse, this.offersData).reduce(
                (
                    messages,
                    {name, vendorMessages, tips, warningMessages, errorMessages, price, availableForBooking}
                ) => {
                    if (
                        vendorMessages.length > 0 ||
                        tips.length > 0 ||
                        warningMessages.length > 0 ||
                        errorMessages.length > 0 ||
                        !availableForBooking
                    ) {
                        messages.push({
                            name,
                            vendorMessages,
                            tips,
                            warningMessages: warningMessages.map(message =>
                                WARNING_MESSAGE_PRICE_CHANGED === message
                                    ? this.$t(message, {price: this.$options.filters.formatPrice(price)})
                                    : message
                            ),
                            errorMessages: errorMessages.map(message =>
                                STANDARD_ERROR_MESSAGES.includes(message) ? this.$t(message) : message
                            ),
                            availableForBooking,
                        })
                    }
                    return messages
                },
                []
            )
        }

        get availableForBooking() {
            return this.messages.every(({availableForBooking}) => availableForBooking)
        }
    }
</script>
