<template>
    <v-snackbar
        v-model="$attrs.value"
        :timeout="-1"
        bottom
        primary
        content-class="compare-content"
        width="900"
        color="rgba(51, 51, 51, 0.9)"
        :vertical="isMobile"
    >
        <v-row no-gutters>
            <v-col :cols="12" class="px-2 py-1">
                <div class="d-flex align-center full-width" :class="{'justify-center': isMobile}">
                    <span class="text-subtitle-1 font-weight-regular">
                        {{
                            offersToCompareCount
                                ? `${$t('selected_for_compare_count')}: ${offersToCompareCount}`
                                : $t('select_to_compare_text')
                        }}
                    </span>
                </div>
            </v-col>
        </v-row>
        <template v-slot:action>
            <div class="px-2">
                <v-btn
                    v-show="offersToCompareCount > 0 && isMobile"
                    class="ms-2"
                    style="background-color: transparent"
                    elevation="0"
                    @click="clearOffersToCompare"
                >
                    <span class="font-weight-light caption">
                        {{ $t('clear_compare') }}
                    </span>
                </v-btn>
                <v-btn v-show="offersToCompareCount > 1" elevation="0" color="primary" @click="redirectToCompare">
                    <span class="font-weight-light caption">
                        {{ $t('compare_page') }}
                    </span>
                </v-btn>
                <v-btn
                    v-show="offersToCompareCount > 0 && !isMobile"
                    class="ms-2"
                    style="background-color: transparent"
                    elevation="0"
                    @click="clearOffersToCompare"
                >
                    <span class="font-weight-light caption">
                        {{ $t('clear_compare') }}
                    </span>
                </v-btn>
            </div>
        </template>
    </v-snackbar>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CompareSnackbar from '@/components/snippets/CompareSnackbar'

    @Component
    export default class CompareSnackbarMod extends CompareSnackbar {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .compare-content {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
</style>
