<template>
    <v-main>
        <v-app-bar app dense flat class="header" height="70">
            <header-block />
            <div class="flex-grow-1" />
            <div>
                <slot name="appBar" :isNewTemplate="isNewTemplate" :newTemplateDialog="newTemplateDialog">
                    <v-btn depressed color="primary" @click="isNewTemplate ? (newTemplateDialog = true) : save()">
                        {{ isNewTemplate ? $t('save') : $t('save_changes') }}
                    </v-btn>
                    <v-btn depressed color="secondary" nuxt :to="{name: 'emailTemplates'}">
                        {{ $t('cancel') }}
                    </v-btn>
                    <email-editor-placeholders-dialog
                        :type="template.notificationType"
                        :recipient-type="template.recipientType"
                    />
                </slot>
            </div>
        </v-app-bar>
        <client-only>
            <div class="d-flex full-height wrapper" :class="contentClass">
                <slot />
            </div>
        </client-only>
        <new-template-dialog v-model="newTemplateDialog" :template="template" @save="saveNew" />
    </v-main>
</template>

<script>
    import {Component, Emit, Prop, Vue} from 'nuxt-property-decorator'
    import NewTemplateDialog from '@/components/admin/modals/NewTemplateDialog'
    import EmailEditorPlaceholdersDialog from '@/pages/admin/EmailEditorPlaceholdersDialog'
    import HeaderBlock from '~src/components/snippets/headerBlock.src'

    @Component({
        components: {
            HeaderBlock,
            NewTemplateDialog,
            EmailEditorPlaceholdersDialog,
        },
    })
    export default class TemplateEditorPageLayout extends Vue {
        @Prop() contentClass

        template = {
            info: '',
            notificationType: '',
        }
        newTemplateDialog = false

        @Emit()
        save() {}

        @Emit()
        saveNew(template) {
            return template
        }

        get isNewTemplate() {
            return !this.$route.params.id
        }
    }
</script>

<style scoped lang="scss">
    .header {
        border-bottom: 1px #eeeeee solid;
        z-index: 100;
        background: white !important;
    }

    .wrapper {
        line-height: 0;
    }
</style>
