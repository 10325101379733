<template>
    <div dir="ltr" style="padding-top: 60px">
        <div class="full-width text-center mb-6">
            <div class="d-flex flex-column caption">
                <span class="text-uppercase black--text font-weight-bold mb-1">
                    {{ $t('testimonials') }}
                </span>
                <span class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                    <span> {{ $t('clients') }} </span>
                    <span class="primary--text">
                        {{ $t('about_us') }}
                    </span>
                </span>
            </div>
        </div>
        <swiper :options="swiperOptions" class="swiper pa-6 pb-8">
            <swiper-slide v-for="(review, index) in reviews" :key="index" :class="{'swiper-slide-ssr': swiperSlideSsr}">
                <v-card class="px-4 py-5 align-center justify-center d-flex swiper-card" hover>
                    <v-card-text>
                        <div class="d-flex full-width align-center">
                            <v-avatar size="70">
                                <img :alt="review.personName" :src="review.avatar" style="object-fit: cover" />
                            </v-avatar>
                            <div class="d-flex flex-column ms-6">
                                <span class="black--text font-weight-medium text-subtitle-1 text-no-wrap">
                                    {{ review.personName }}
                                </span>
                                <span class="caption text-subtitle-2 font-weight-light text-no-wrap">
                                    {{ review.location }}
                                </span>
                            </div>
                            <v-img max-width="80px" class="ms-auto me-4" src="/review-quotes.png" />
                        </div>
                        <div class="black--text mt-4 text-subtitle-2 font-weight-light">
                            {{ review.text }}
                        </div>
                    </v-card-text>
                </v-card>
            </swiper-slide>
            <div v-if="reviews.length > showCount" slot="pagination" class="swiper-pagination" style="bottom: 0" />
        </swiper>
        <div v-if="!isMobile" class="full-width d-flex justify-center mt-6">
            <v-btn v-if="reviews.length > showCount" id="hotelsSwiperButtonPrev" icon elevation="1" class="mx-2">
                <v-icon color="primary"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn v-if="reviews.length > showCount" id="hotelsSwiperButtonNext" icon elevation="1" class="mx-2">
                <v-icon color="primary"> mdi-chevron-right </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import PopularHotel from '@/components/parts/popularHotel.src'
    import MainHeader from '@/components/snippets/mainHeader.src'
    import {Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm'

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    Vue.use(getAwesomeSwiper(SwiperClass))
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    @Component({
        components: {
            PopularHotel,
            Swiper,
            SwiperSlide,
            MainHeader,
        },
    })
    export default class Reviews extends Vue {
        @Prop() reviews

        swiperSlideSsr = false

        created() {
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 2 : 3
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                centerInsufficientSlides: true,
                spaceBetween: 48,
                breakpoints: {
                    960: {
                        slidesPerView: 2,
                    },
                    1264: {
                        slidesPerView: 3,
                    },
                },
                navigation: {
                    nextEl: '#hotelsSwiperButtonNext',
                    prevEl: '#hotelsSwiperButtonPrev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true,
                },
                observer: true,
                observeParents: true,
            }
        }
    }
</script>

<style scoped lang="scss">
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
        bottom: 0 !important;
        top: unset;
    }

    ::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #d8e562;
    }
</style>
