<template>
    <div dir="ltr">
        <div class="full-width text-center mb-6">
            <span class="font-weight-bold" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
                <span> {{ $t('hot') }} </span>
                <span class="primary--text">
                    {{ $t('deals') }}
                </span>
            </span>
        </div>

        <swiper :options="swiperOptions" class="swiper pa-6 pb-8">
            <swiper-slide
                v-for="(hotel, index) in hotels"
                :key="index"
                class="full-height"
                :class="{'swiper-slide-ssr': swiperSlideSsr}"
            >
                <popular-hotel
                    class="full-height"
                    :hotel="hotel"
                    :dir="$vuetify.rtl ? 'rtl' : 'ltr'"
                    @selected="search($event)"
                />
            </swiper-slide>
            <div v-if="hotels.length > showCount" slot="pagination" class="swiper-pagination" style="bottom: 0" />
        </swiper>
        <div v-if="!isMobile" class="full-width d-flex justify-center mt-6">
            <v-btn v-if="hotels.length > showCount" id="hotelsSwiperButtonPrev" icon elevation="1" class="mx-2">
                <v-icon color="primary"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn v-if="hotels.length > showCount" id="hotelsSwiperButtonNext" icon elevation="1" class="mx-2">
                <v-icon color="primary"> mdi-chevron-right </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import PopularHotels from '~/components/parts/PopularHotels'

    @Component
    export default class PopularHotelsMod extends PopularHotels {
        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 3 : 4
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                centerInsufficientSlides: true,
                spaceBetween: 48,
                breakpoints: {
                    960: {
                        slidesPerView: 3,
                    },
                    1264: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: '#hotelsSwiperButtonNext',
                    prevEl: '#hotelsSwiperButtonPrev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true,
                },
                observer: true,
                observeParents: true,
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
        bottom: 0 !important;
        top: unset;
    }

    ::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #d8e562;
    }

    ::v-deep .swiper-slide {
        height: auto;
    }
</style>
