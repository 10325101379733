<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card class="pb-4">
            <v-card-title class="d-flex justify-space-between">
                <span>
                    {{ $t('attachments') }}
                </span>
                <v-icon @click="modal = false">mdi-close</v-icon>
            </v-card-title>
            <v-list>
                <template v-if="documents.length">
                    <v-list-item
                        v-for="(doc, index) in documents"
                        :key="doc.id"
                        :class="{'mb-5': index === documents.length - 1}"
                        @click="downloadDocument(doc)"
                    >
                        <v-list-item-icon class="mr-0">
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-icon small :color="doc.visibleForAll ? 'success' : ''" v-on="on">{{
                                        doc.visibleForAll ? 'mdi-eye' : 'mdi-eye-remove-outline'
                                    }}</v-icon>
                                </template>
                                <span>{{
                                    `${$t('visible_for')} ${doc.visibleForAll ? $t('for_all') : $t('for_to1')}`
                                }}</span>
                            </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-icon class="mr-0">
                            <v-icon small color="blue darken-1">mdi-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <div class="d-flex flex-column">
                                <a>{{ doc.name }}</a>
                                <span class="caption text--secondary">
                                    {{ getServiceName(doc.processId) }}
                                </span>
                            </div>
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-else class="mb-5">{{ $t('no_uploaded_documents') }}</v-list-item>

                <v-list-item v-if="filteredServices.length" dense>
                    <v-checkbox
                        v-model="hideCancelledServices"
                        :label="$t('hide_cancelled_services')"
                        hide-details
                        @change="processId = null"
                    />
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-select
                            v-model="processId"
                            :menu-props="{bottom: true, offsetY: true}"
                            :items="hideCancelledServices ? filteredServices : services"
                            :label="`${$t('relates_to')}*`"
                            item-text="name"
                            item-value="value"
                            dense
                            hide-details
                            outlined
                        />
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isTO1">
                    <v-list-item-content>
                        <div class="mb-n3">{{ $t('visible_for') }}</div>
                        <v-radio-group v-model="visibleForAll" row>
                            <v-radio :label="$t('for_all')" :value="true" />
                            <v-radio :label="$t('for_to1')" :value="false" />
                        </v-radio-group>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <div class="mb-4">{{ $t('select_the_document_you_want_to_upload') }}</div>
                        <div class="d-md-flex align-md-center">
                            <v-file-input
                                v-model="fileObject"
                                label="File input"
                                truncate-length="20"
                                show-size
                                dense
                                class="mr-5"
                            />
                            <v-btn
                                :block="$breakpoint.smAndDown"
                                :loading="isLoading"
                                :disabled="!fileObject || isLoading"
                                color="primary"
                                depressed
                                small
                                @click="uploadDocument"
                            >
                                {{ $t('upload') }}
                            </v-btn>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {EventBus, SHOW_SERVICE_DOCUMENTS_MODAL} from '@/utils/event-bus'
    import {blobToBase64, downloadData} from '@/utils/helpers'
    import {authStore} from '@/utils/store-accessor'

    @Component
    export default class ServiceDocumentsModal extends Vue {
        modal = false
        isLoading = false
        documents = []
        orderId = null
        companyId = null
        fileObject = null
        services = null
        filteredServices = []
        hideCancelledServices = true
        processId = null
        visibleForAll = false

        created() {
            EventBus.$on(SHOW_SERVICE_DOCUMENTS_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_SERVICE_DOCUMENTS_MODAL, this.showModal)
        }

        async showModal({orderId, companyId, services}) {
            this.orderId = orderId
            this.companyId = companyId
            this.documents = await this.$api.documents.get({orderId})

            const itemsByServices = services.map(e => ({name: e.serviceName, value: e.processId, status: e.status}))
            this.services = [{name: this.$t('reservation'), value: null, status: null}, ...itemsByServices]
            this.filteredServices = this.services.filter(
                service => service.status && service.status.toLowerCase() !== 'canceled'
            )
            this.modal = true
        }

        async downloadDocument(doc) {
            const rs = await this.$api.documents.get({id: doc.id})
            const data = new Buffer(rs[0].encodedData, 'base64')

            downloadData(data, doc.name)
        }

        async uploadDocument() {
            this.isLoading = true
            const base64 = await blobToBase64(this.fileObject)
            const encodedData = base64.split('base64,')[1]
            const contentType = base64.split(';base64,')[0].split('data:')[1]
            const data = {
                orderId: this.orderId,
                companyId: this.companyId,
                creator: {
                    id: authStore.user.person.personId,
                    firstName: authStore.user.person.firstName,
                    lastName: authStore.user.person.lastName,
                },
                name: this.fileObject.name,
                encodedData,
                contentType,
                documentType: 'OTHER',
                processId: this.processId,
                visibleForAll: this.isTO1 ? this.visibleForAll : true,
            }

            try {
                await this.$api.documents.post(data)
                this.documents = await this.$api.documents.get({orderId: this.orderId, active: true})
                this.fileObject = null
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.isLoading = false
        }

        getServiceName(processId) {
            return this.services.find(s => s.value === processId)?.name
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
