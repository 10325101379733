<template>
    <div>
        <div class="d-flex">
            <v-img
                :aspect-ratio="4 / 3"
                :src="mainImageReturnTransfer.url | imageUrl(250)"
                :lazy-src="'/placeholder.png' | srcHost"
                max-width="150"
                max-height="112.5"
                tile
                class="me-3"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && mainImageReturnTransfer.url"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="text-truncate">
                <div
                    :title="infoReturnTransfer.name"
                    class="primary--text font-weight-medium text-subtitle-1 text-truncate"
                >
                    {{ infoReturnTransfer.name }}
                </div>
                <v-chip v-if="returnTransferOffer.transferType" label x-small>
                    {{ $t(`filters_transferType.${returnTransferOffer.transferType}`) }}
                </v-chip>
                <div v-if="infoReturnTransfer.shortDescription" class="mb-4 text-body-2 text-wrap">
                    {{ infoReturnTransfer.shortDescription }}
                </div>
            </div>
        </div>
        <v-divider class="mt-4 mb-2" />
        <div>
            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="offerInfo()">
                <v-icon small left>mdi-information-outline</v-icon>
                <span>
                    {{ returnTransferOffer.info.description }}
                </span>
            </div>
            <div v-if="returnTransferOffer.available" class="caption d-flex align-center">
                <v-icon :title="$t('available')" left small class="success--text"> mdi-checkbox-marked-circle </v-icon>
                <span class="success--text" v-text="$t('available')" />
            </div>
            <div v-else class="caption d-flex align-center">
                <v-icon :title="$t('on_request')" left small class="warning--text">mdi-alert-circle</v-icon>
                <span class="warning--text" v-text="$t('on_request')" />
            </div>
            <conditions-label
                :offer-key="returnTransferOffer.offerKey"
                :price="returnTransferOffer.price"
                class="text-caption"
                @conditions="showConditionsInfoReturnTransfer"
            />
            <div v-if="returnTransferOffer.info.tariffName" class="caption d-flex align-center">
                <v-icon left small class="info--text">mdi-tag-text</v-icon>
                {{ returnTransferOffer.info.tariffName }}
            </div>
            <transfer-driver-languages :lang-codes="returnTransferOffer.langCodes" />
            <div class="mt-1 font-weight-bold">
                <span>
                    {{ returnTransferOffer.price | price }}
                </span>
            </div>
        </div>
        <extra-services-summary :services="bookingExtraServicesReturnTransfer" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import ConditionsLabel from '@/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'
    import ExtraServicesSummary from '@/components/booking/snippets/ExtraServicesSummary'
    import {transfersStore} from '@/utils/store-accessor'
    import TotalPriceLabel from '@/components/booking/snippets/TotalPriceLabel'

    @Component({
        components: {TotalPriceLabel, ExtraServicesSummary, TransferDriverLanguages, ConditionsLabel},
    })
    export default class TransferReturnOrderSummaryCard extends Vue {
        @Prop() infoReturnTransfer
        @Prop() returnTransferOffer
        @Prop() searchRequestReturnTransfer
        @Prop() totalPriceReturnTransfer

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfoReturnTransfer() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequestReturnTransfer, this.returnTransferOffer)
        }

        offerInfo() {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {
                offer: this.returnTransferOffer,
                info: this.returnTransferOffer.info,
            })
        }

        get mainImageReturnTransfer() {
            return this.infoReturnTransfer.images?.find(image => image.mainImage) || {}
        }

        get bookingExtraServicesReturnTransfer() {
            return transfersStore.bookingExtraServicesReturnTransfer
        }
    }
</script>
