<template>
    <div v-if="address" class="text-truncate" :class="{'cursor-pointer': !!offer}" @click.stop="showOnMap">
        <small class="d-flex align-center">
            <v-icon class="me-2" small color="accent">mdi-map-marker</v-icon>
            <span class="text--secondary">
                {{ address }}
            </span>
        </small>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelAddressLabel from '@/components/hotels/snippets/HotelAddressLabel'

    @Component
    export default class HotelAddressLabelMod extends HotelAddressLabel {}
</script>
