<template>
    <v-text-field
        v-model="phone"
        :dense="dense"
        :label="label && required ? `${label}*` : label"
        :outlined="outlined"
        :placeholder="placeholder && required ? `${placeholder}*` : placeholder"
        :prepend-inner-icon="prependInnerIcon"
        :required="required"
        :rules="rules"
        :persistent-placeholder="persistentPlaceholder"
        :validate-on-blur="validateOnBlur"
        type="tel"
        @input="input"
        @blur="blur"
    />
</template>

<script>
    import {Component, Emit, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {AsYouType} from 'libphonenumber-js'

    @Component
    export default class PhoneField extends Vue {
        @VModel() phone
        @Prop({default: false, type: Boolean}) required
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false, type: Boolean}) dense
        @Prop({default: false, type: Boolean}) persistentPlaceholder
        @Prop({default: false, type: Boolean}) validateOnBlur
        @Prop() label
        @Prop() placeholder
        @Prop() prependInnerIcon

        @Emit()
        blur() {}

        get rules() {
            return [
                v => !this.required || !!v || `${this.label || this.placeholder} ${this.$t('validation.required')}`,
                v => {
                    const asYouType = new AsYouType()
                    if (v) {
                        asYouType.input(`${v[0] !== '+' ? '+' : ''}${v}`)
                    }
                    return (
                        !v || asYouType.isValid() || `${this.label || this.placeholder} ${this.$t('validation.valid')}`
                    )
                },
            ]
        }

        async input(val) {
            await this.$nextTick
            this.phone = new AsYouType().input(`${val[0] && val[0] !== '+' ? '+' : ''}${val}`)
        }
    }
</script>
