<template>
    <v-sheet
        color="primary"
        min-height="168"
        class="full-width d-flex flex-column flex-md-row align-md-center justify-md-space-around text-uppercase pa-4"
        dark
    >
        <div class="d-flex flex-column my-4">
            <!--<span class="text-h4 yellowlime&#45;&#45;text font-weight-medium"> {{ $t('reasons_to') }} </span>-->
            <span :class="$i18n.locale === 'ar' ? 'text-h4' : 'text-h6'" class="font-weight-light">
                {{ $t('book_with_us') }}
            </span>
        </div>
        <div class="d-flex align-center my-4">
            <v-icon class="me-4" color="yellowlime" x-large>mdi-hand-coin-outline</v-icon>
            <span :class="$i18n.locale === 'ar' ? 'text-h4' : 'text-h6'" class="font-weight-light">
                {{ $t('best_price') }}
            </span>
        </div>
        <div class="d-flex align-center my-4">
            <v-icon class="me-4" color="yellowlime" x-large>mdi-clock-check-outline</v-icon>
            <span :class="$i18n.locale === 'ar' ? 'text-h4' : 'text-h6'" class="font-weight-light">
                {{ $t('support_24') }}
            </span>
        </div>
        <div class="d-flex align-center my-4">
            <v-icon class="me-4" color="yellowlime" x-large>mdi-credit-card-multiple-outline </v-icon>
            <span :class="$i18n.locale === 'ar' ? 'text-h4' : 'text-h6'" class="font-weight-light">
                {{ $t('easy_payment') }}
            </span>
        </div>
    </v-sheet>
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'

    @Component
    export default class ReasonsToBook extends Vue {
        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
