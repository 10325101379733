<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class FillServiceBookingDataMixin extends Vue {
        bookingFieldsLoading = false

        async loadTouristBookingFields() {
            this.bookingFieldsLoading = true
            try {
                await runtimeStore.loadTouristBookingFields({processId: this.service.processId})

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.bookingFieldsLoading = false
            }
        }

        async loadProductBookingFields() {
            this.bookingFieldsLoading = true
            try {
                await runtimeStore.loadProductBookingFields({processId: this.service.processId})

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.bookingFieldsLoading = false
            }
        }

        setTourists() {
            const tourists = this.initTourists(this.searchRequest, clone(persistentStore.tourists))
            const travelers = clone(this.service.travelers)

            tourists.forEach((tourist, index) => {
                for (let prop in tourist) {
                    if (prop === 'type' || prop === 'passports') continue
                    if (prop === 'birthdate' && travelers[index]?.dateOfBirth) {
                        tourist[prop] = travelers[index].dateOfBirth
                        continue
                    }
                    if (prop === 'firstName' && travelers[index]?.name[0]?.firstName) {
                        tourist[prop] = travelers[index].name[0][prop]
                        continue
                    }
                    if (prop === 'lastName' && travelers[index]?.name[0].lastName) {
                        tourist[prop] = travelers[index].name[0][prop]
                        continue
                    }
                    if (travelers[index] && travelers[index][prop]) {
                        tourist[prop] = travelers[index][prop]
                    }
                }
            })

            persistentStore.SET_TOURISTS(tourists)
        }
    }
</script>
