<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-lightning-bolt-outline"
        :is-first="isFirst"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-image="mainImage"
                :service-check-in="serviceCheckIn"
                :is-package="isPackage"
                :service-label="activityServiceName"
                :order="order"
                :order-end-date="orderEndDate"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-if="info.location" v-slot:additionalInfo>
                    <small
                        class="d-block hotel-address mt-2"
                        :style="{
                            cursor:
                                departures.length && departures[0].latitude && departures[0].longitude
                                    ? 'pointer'
                                    : 'inherit',
                        }"
                        @click="mapModal = true"
                    >
                        <v-icon color="accent" small>mdi-map-marker</v-icon>
                        {{ info.location }}
                    </small>
                </template>
                <div v-if="transport" class="d-flex">
                    <v-icon left small>mdi-bus</v-icon>
                    {{ transport }}
                </div>
                <div v-if="pickupLocation" class="d-flex">
                    <v-icon left small color="accent">mdi-map-marker</v-icon>
                    {{ pickupLocation }}
                </div>
                <activity-duration :time="info.durationTime" :days="info.durationDays" />
                <div v-if="info.themes && info.themes.length" class="d-flex align-center">
                    <v-icon left small>mdi-star-circle</v-icon>
                    {{ info.themes.join(', ') }}
                </div>
                <div v-if="activityCategory" class="d-flex">
                    <v-icon left small>mdi-star-circle</v-icon>
                    {{ activityCategory }}
                </div>
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillActivityBookingDataModal = true">mdi-pencil</v-icon>
                    <span
                        class="text-caption cursor-pointer primary--text"
                        @click="fillActivityBookingDataModal = true"
                    >
                        {{ $t('enter_details') }}
                    </span>
                    <fill-activity-booking-data-modal
                        v-model="fillActivityBookingDataModal"
                        :service="service"
                        :order="order"
                        :info="info"
                    />
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base
                    :service="service"
                    :service-type="$t(`filters_activityType.${service.serviceDetails[0].type}`)"
                    :info="info"
                    :image="mainImage"
                >
                    <template v-if="info" v-slot:serviceInfo>
                        <div v-if="language">
                            <strong>{{ $t('language') }}: {{ language }}</strong>
                        </div>
                        <div v-if="info.shortDescription" class="d-flex align-center mt-2">
                            {{ info.shortDescription }}
                        </div>
                        <template v-if="info.descriptions && info.descriptions.length">
                            <div v-for="(desc, index) in info.descriptions" :key="index">
                                {{ desc }}
                            </div>
                        </template>
                        <template v-if="info.comments && info.comments.length">
                            <div v-for="(comm, index) in info.comments" :key="index">
                                {{ comm }}
                            </div>
                        </template>
                    </template>
                </service-info-base>
            </v-dialog>
            <client-only>
                <v-dialog
                    v-if="departures.length && departures[0].latitude && departures[0].longitude"
                    v-model="mapModal"
                    :max-width="500"
                >
                    <map-location
                        v-if="mapModal"
                        :latitude="departures[0].latitude"
                        :longitude="departures[0].longitude"
                        :height="`500px`"
                    />
                </v-dialog>
            </client-only>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import ServiceInfoBase from '~/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import ActivityDuration from '@/components/activities/search/offers/ActivityDuration'
    import {Emit} from 'nuxt-property-decorator'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FillActivityBookingDataModal from '@/components/account/modals/newOrderRequest/FillActivityBookingDataModal'
    import MapLocation from '~src/components/info/mapLocation.src'

    @Component({
        components: {
            FillActivityBookingDataModal,
            ActivityDuration,
            ServiceCard,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceInfoBase,
            MapLocation,
        },
    })
    export default class ActivityService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() itineraryService
        @Prop() isFirst

        activityInfo = null
        modal = false
        mapModal = false
        fillActivityBookingDataModal = false

        showInfo() {
            this.modal = true
        }

        async mounted() {
            await this.getAdditionalInfo()
            this.activityInfo.departures.forEach(e => {
                if (e.latitude && e.longitude) {
                    this.addMarker(e.latitude, e.longitude)
                }
            })
        }

        @Emit()
        addMarker(latitude, longitude) {
            return {lat: latitude, lng: longitude, type: 'activity'}
        }

        async getAdditionalInfo() {
            this.activityInfo = await this.getActivityInfo()
        }

        async getActivityInfo() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            return await this.$api.activityInfo.get({processId})
        }

        get serviceCheckIn() {
            const time = this.service.serviceDetails[0].pickupTime
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return time
                ? `<span>${date}</span> <small>${this.$t('time_from')} ${time}</small>`
                : `<span >${date}</span>`
        }

        get mainImage() {
            if (this.activityInfo) {
                if (this.activityInfo.images && this.activityInfo.images.length) {
                    let image = this.activityInfo.images.find(el => el.mainImage)
                    if (!image) {
                        image = this.activityInfo.images[0]
                    }
                    return image.url
                }
                return null
            }
            return null
        }

        get info() {
            return {...this.activityInfo, ...{mainImage: this.mainImage}}
        }

        get departures() {
            return this.info.departures || []
        }

        get activityType() {
            return this.$t(`activities.type.${this.service.serviceDetails[0].type.toLowerCase()}`)
        }

        get activityCategory() {
            if (this.service.serviceDetails[0].activityCategories.length) {
                return this.service.serviceDetails[0].activityCategories[0].name
            }

            return null
        }

        get location() {
            if (this.service.serviceDetails[0].location) {
                return this.service.serviceDetails[0].location
            }

            return null
        }

        get activityServiceName() {
            if (this.service.serviceDetails[0].activityServiceName) {
                return this.service.serviceDetails[0].activityServiceName
            }

            return null
        }

        get transport() {
            if (this.service.serviceDetails[0].transport) {
                return this.service.serviceDetails[0].transport
            }

            return null
        }

        get pickupLocation() {
            if (this.service.serviceDetails[0].pickupLocation) {
                return this.service.serviceDetails[0].pickupLocation.name
            }

            return null
        }

        get language() {
            return this.service.serviceDetails[0].language
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }

    .activity-type {
        text-transform: uppercase;
    }

    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
