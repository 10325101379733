<template>
    <v-row>
        <v-col cols="12" md="4" class="d-flex align-center">
            <div class="d-flex align-center ml-2">
                <v-checkbox
                    v-model="checked"
                    :disabled="hideAction"
                    @change="$emit('update', {service: extraService, qty: qty, operation: $event})"
                />
                <div class="h1 text-wrap">
                    {{ extraService.name }}
                    <v-icon v-if="extraService.description" small @click="showDescription = true">
                        mdi-information-outline
                    </v-icon>
                </div>
            </div>
            <v-dialog v-model="showDescription" width="500" class="description-modal">
                <v-card class="pa-4" v-html="extraService.description" />
            </v-dialog>
        </v-col>
        <v-col v-if="showPrice" cols="3" md="2">
            <span class="caption">{{ $t('price') }}</span>
            <div>
                {{ extraServicePrice | price }}
            </div>
        </v-col>
        <v-col cols="3" md="2">
            <span class="caption">{{ $t('price_per') }}</span>
            <div>
                {{ extraService.perPax ? $t('for_guest') : $t('order_service') }}
                {{
                    extraService.calculationType !== 'BY_SERVICE' || extraService.perPax
                        ? $t(`extra_services_details.type.${extraService.calculationType}`)
                        : ''
                }}
            </div>
        </v-col>
        <v-col cols="3" md="2">
            <v-text-field
                v-model="qty"
                :label="$t('quantity')"
                type="number"
                :min="1"
                :max="100"
                :disabled="hideAction"
                @keyup="validateMaxQty"
            />
        </v-col>
        <v-col v-if="showPrice" cols="3" md="2">
            <span class="caption">{{ $t('total') }}</span>
            <div>
                {{ totalPrice | price }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {Vue, Component, Prop, Watch} from 'nuxt-property-decorator'

    @Component
    export default class ExtraService extends Vue {
        @Prop() extraService
        @Prop({default: false}) hideAction

        qty = 1
        checked = false
        showDescription = false

        @Watch('qty')
        onQtyChange() {
            if (this.checked) {
                this.$emit('update', {service: this.extraService, qty: this.qty, operation: true})
            }
        }

        mounted() {
            if (this.hideAction) {
                this.checked = true
                this.$emit('update', {service: this.extraService, qty: this.qty, operation: true})
            } else {
                this.$emit('update', {service: this.extraService, qty: this.qty, operation: false})
            }
        }

        validateMaxQty(event) {
            if (event.target.value.length > 2) {
                event.preventDefault()
            }
            this.qty = Number(event.target.value) > 100 ? 100 : event.target.value
        }

        get extraServicePrice() {
            return this.extraService.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT')
        }

        get priceType() {
            return this.extraService.perPax ? this.$t('tourist') : this.$t('by_service')
        }

        get totalPrice() {
            return {
                amount: this.extraServicePrice.originalAmount * this.qty,
                currency: this.extraServicePrice.originalCurrency,
            }
        }

        get showPrice() {
            return true
        }
    }
</script>

<style scoped lang="scss">
    .without-line::v-deep {
        .v-input__slot::before {
            border: none;
            display: none;
        }
    }

    ::v-deep input[type='number']::-webkit-inner-spin-button {
        opacity: 1;
    }

    .description-modal {
        p {
            margin-bottom: 0;
        }
    }
</style>
