<template>
    <v-dialog v-model="dialog" :disabled="disabled" transition="dialog-top-transition" max-width="600">
        <template v-slot:activator="{on, attrs}">
            <span
                :class="disabled ? 'grey--text' : 'primary--text'"
                class="text-body-2 text-lowercase cursor-pointer"
                v-bind="attrs"
                v-on="on"
            >
                {{ $t('save_this_filter') }}
            </span>
        </template>
        <v-card>
            <v-card-title class="text-h6">
                {{ $t('save_filter') }}
            </v-card-title>
            <v-card-text>
                {{ $t('save_the_current_settings_of_the_filter_as') }}
                <v-text-field
                    v-model="customFilterModel.text"
                    :label="$t('name')"
                    :rules="rules"
                    counter="25"
                    single-line
                    dense
                />
                <v-radio-group v-model="customFilterModel.type" row>
                    <v-radio :label="$t('just_for_me')" value="single" />
                    <v-radio :label="$t('for_all_users_of_my_company')" value="all" />
                </v-radio-group>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    :loading="isLoading"
                    :disabled="isLoading || !customFilterModel.text"
                    color="primary"
                    @click="save"
                >
                    {{ $t('save') }}
                </v-btn>
                <v-btn text @click="close">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import {clean, clone} from '@/utils/helpers'

    @Component
    export default class CustomFilterModal extends Vue {
        @Prop() filters
        @Prop() person
        @Prop({type: Boolean}) disabled

        dialog = false
        isLoading = false
        customFilterModel = {
            text: '',
            type: 'single',
        }

        async save() {
            try {
                this.isLoading = true
                const customFilter = this.customFilterModel
                const creatorId = this.person.personId
                const companyId = this.person.companyId
                const filters = clone(this.filters)
                const id = `${creatorId}-${Date.parse(new Date())}`

                clean(filters)
                customFilter.filters = filters
                customFilter.creatorId = creatorId
                customFilter.companyId = companyId

                await this.$api.customFilters.post({id, customFilter})
                this.$emit('create-custom-filter')
                this.close()
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isLoading = false
            }
        }

        close() {
            this.customFilterModel.text = ''
            this.customFilterModel.type = 'single'
            this.dialog = false
        }

        get rules() {
            return [v => v.length <= 25 || this.$t('validation.max_characters', {prop: 25})]
        }
    }
</script>
