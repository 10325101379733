var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v("\n            "+_vm._s(_vm.$t('fxGainLoss'))+"\n            "),_c('v-icon',{staticClass:"v-card-close mt-3 me-3",on:{"click":function($event){_vm.modal = false}}},[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-card-text',[(_vm.info)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.info.items,"sort-by":'supplierName',"hide-default-header":_vm.isMobile,"single-expand":_vm.isMobile},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[(item.name === 'Payment')?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-cash ")]):_vm._e(),_vm._v("\n                        "+_vm._s(item.name)+"\n                    ")],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("dateShortFormat")(item.date))+"\n                    ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("priceFormat")(item.amount,item.currency))+"\n                    ")])]}},{key:"item.convertedAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'red--text': item.name !== 'Payment', 'success--text': item.name === 'Payment'}},[_vm._v("\n                        "+_vm._s(_vm._f("priceFormat")(item.convertedAmount,item.convertedCurrency))+"\n                    ")])]}},{key:"foot",fn:function(){return [(_vm.totalFxGainLoss && _vm.totalFxGainLoss.amount > 0)?_c('tr',[_c('td',{staticClass:"px-4 py-4 text-subtitle-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('total_fx')))])]),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td',{staticClass:"px-4 py-4 text-subtitle-1",staticStyle:{"text-align":"end"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n                                "+_vm._s(_vm._f("price")(_vm.totalFxGainLoss))+"\n                            ")])])]):_vm._e()]},proxy:true}],null,false,1592488816)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }