<template>
    <v-container :fill-height="!loaded" column fluid :style="{width: isMobile ? '100%' : '80%'}">
        <template v-if="loaded">
            <breadcrumbs id="breadcrumbs" class="mb-4" :text="$route.params.id" />
            <order-details
                ref="order"
                @cancelService="loadOrder"
                @modifyService="loadOrder"
                @issuedInvoice="issuedInvoice"
                @issuedTicket="loadOrder"
            />
        </template>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <v-dialog v-if="isShowConfirmationDialog" v-model="confirmationStatusModal" hide-overlay width="500px">
            <v-card>
                <v-card-text class="pt-5">
                    <confirmation-status v-if="bookingConfirmation === 'order'" :company="company" />
                    <invoice-payment-status v-if="bookingConfirmation === 'invoice'" :order-id="$route.params.id" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn color="primary" class="mx-auto" text @click="closeConfirmationModal">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <order-service-book-modal />
        <send-email-modal />
    </v-container>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import OrderDetailsPage from '~/pages/account/OrderDetailsPage'

    @Component
    export default class OrderDetailsPageMod extends OrderDetailsPage {}
</script>
