<template>
    <v-card :outlined="outlined" :elevation="elevation" tile>
        <v-card-title class="title">
            {{ $t('carsRent.pick-up_and_drop-off_points') }}
        </v-card-title>
        <v-card-text class="white--text">
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="pickUpList"
                            :label="$t('carsRent.pick-up_point')"
                            :rules="[v => !!v || `${$t('carsRent.pick-up_point')} ${$t('validation.required')}`]"
                            :disabled="disabled"
                            dense
                            @input="setPickUpLocation"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            :items="dropOffList"
                            :label="$t('carsRent.drop-off_point')"
                            :rules="[v => !!v || `${$t('carsRent.drop-off_point')} ${$t('validation.required')}`]"
                            :disabled="disabled"
                            dense
                            @input="setDropOffLocation"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'nuxt-property-decorator'
    import {carsRentStore} from '@/utils/store-accessor'

    @Component
    export default class CarDetailsForm extends Vue {
        @Prop({required: true}) offer
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: 0}) elevation
        @PropSync('_valid', {default: false}) valid

        beforeDestroy() {
            carsRentStore.SET_PICK_UP_LOCATION({})
            carsRentStore.SET_DROP_OFF_LOCATION({})
        }

        setPickUpLocation(e) {
            carsRentStore.SET_PICK_UP_LOCATION(e)
        }

        setDropOffLocation(e) {
            carsRentStore.SET_DROP_OFF_LOCATION(e)
        }

        get pickUpList() {
            const arr = []
            this.offer?.pickUpPoints?.map(el => {
                if (el.address) arr.push({text: el.address, value: el})
                else arr.push({text: el.name, value: el})
            })
            return arr
        }

        get dropOffList() {
            const arr = []
            this.offer?.returnPoints?.map(el => {
                if (el.address) arr.push({text: el.address, value: el})
                else arr.push({text: el.name, value: el})
            })
            return arr
        }
    }
</script>
