<template>
    <confirmation-page-layout :company="company" :loaded="loaded" :tourlead="tourlead">
        <template v-slot:breadcrumbs>
            <transfer-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <transfer-search-summary search-disabled :search-request="searchRequest" />
            <transfer-order-summary
                :offer="offer"
                :search-request="searchRequest"
                :info="info"
                :product="product"
                :info-return-transfer="infoReturnTransfer"
                :return-transfer-offer="offerReturnTransfer"
                :search-request-return-transfer="searchRequestReturnTransfer"
            />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import ConfirmationStatus from '~src/components/booking/confirmationStatus.src'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferOrderSummary from '~src/components/transfers/booking/transferOrderSummary.src'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'

    @Component({
        components: {
            TransferBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            Breadcrumbs,
            TransferSearchSummary,
            TransferOrderSummary,
        },
        layout: 'blank',
    })
    export default class TransferConfirmationPage extends mixins(
        TransferPageMixin,
        ConfirmationPageMixin,
        TransferStoreMixin
    ) {
        info = {
            images: [],
        }

        infoReturnTransfer = {
            images: [],
        }

        //TODO Save info in basket
        async mounted() {
            await this.$store.restored
            try {
                this.info = await this.$api.transferInfo.get({offerKey: this.productStore.prepareBookRequest.offerKey})
                if (this.isReturnTransfer) {
                    this.infoReturnTransfer = await this.$api.transferInfo.get({
                        offerKey: this.productStore.prepareBookReturnTransferRequest.offerKey,
                    })
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get isReturnTransfer() {
            return this.$route.query.returnTransfer
        }

        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get basketItemReturnTransfer() {
            return this.isReturnTransfer
                ? this.productStore.basketItem(this.productStore.prepareBookReturnTransferRequest.offerKey)
                : null
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get searchRequestReturnTransfer() {
            return this.isReturnTransfer ? this.basketItemReturnTransfer.searchRequest : null
        }

        get product() {
            return this.basketItem.product
        }

        get productReturnTransfer() {
            return this.basketItemReturnTransfer.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }

        get offerReturnTransfer() {
            return this.isReturnTransfer
                ? this.productReturnTransfer.offers.find(
                      offer => offer.offerKey === this.basketItemReturnTransfer.offerKey
                  )
                : null
        }
    }
</script>
