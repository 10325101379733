<template>
    <div>
        <mobile-booking-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <slot name="breadcrumbs" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <client-only>
                        <v-card v-if="showTouristSelector" outlined class="mb-4">
                            <v-card-text>
                                <tourist-selector @selected="setTourLead($event, true)" />
                            </v-card-text>
                        </v-card>
                        <v-form v-if="hasBookingFields" ref="mainForm">
                            <div
                                class="d-flex align-center full-width justify-space-between px-md-8 py-8"
                                style="position: relative"
                            >
                                <div
                                    style="
                                        position: absolute;
                                        height: 1px;
                                        background: #e9e9e9;
                                        top: 51px;
                                        left: 0;
                                        right: 0;
                                        margin-left: auto;
                                        margin-right: auto;
                                    "
                                    :style="{width: isMobile ? 'calc(100% - 104px)' : 'calc(100% - 204px)'}"
                                />
                                <div style="position: relative" class="d-flex flex-column align-center ms-2">
                                    <v-btn icon color="warning" style="background: #e7ba74" @click.prevent>
                                        <v-icon color="white"> mdi-check</v-icon>
                                    </v-btn>
                                    <span>
                                        <span style="color: #9b9b9b" class="caption"> {{ $t('choose_room') }} </span>
                                    </span>
                                </div>
                                <div style="position: relative" class="d-flex flex-column align-center">
                                    <v-btn icon color="warning" style="background: #e7ba74" @click.prevent>
                                        <span class="white--text"> 2 </span>
                                    </v-btn>
                                    <span>
                                        <span class="caption"> {{ $t('guest_details') }} </span>
                                    </span>
                                </div>
                                <div style="position: relative" class="d-flex flex-column align-center">
                                    <v-btn
                                        icon
                                        color="warning"
                                        :style="{background: bookingKey ? ' #e7ba74' : 'white'}"
                                        :outlined="!bookingKey"
                                        @click.prevent
                                    >
                                        <span :class="{'white--text': bookingKey, 'black--text': !bookingKey}">
                                            3
                                        </span>
                                    </v-btn>
                                    <span>
                                        <span class="caption"> {{ $t('payment') }} </span>
                                    </span>
                                </div>
                                <div style="position: relative" class="d-flex flex-column align-center">
                                    <v-btn :style="{background: 'white'}" icon color="warning" outlined @click.prevent>
                                        <span class="black--text"> 4 </span>
                                    </v-btn>
                                    <span>
                                        <span class="caption"> {{ $t('confirm_booking') }} </span>
                                    </span>
                                </div>
                            </div>
                            <account-booking-info
                                v-if="isShowAccountBookingInfo"
                                class="mb-8"
                                :disabled="!!bookingKey || !!basketKey"
                            />
                            <template v-if="memberLevel !== 'AUTH' || person">
                                <div v-if="formTitle" class="text-subtitle-2 accent--text mb-4">
                                    {{ formTitle }}
                                </div>
                                <div style="position: relative">
                                    <slot
                                        name="touristsCard"
                                        :tourists="tourists"
                                        :bookingKey="bookingKey"
                                        :basketKey="basketKey"
                                        :pageLock="pageLock"
                                    >
                                        <v-card outlined class="mb-5">
                                            <v-card-title class="text-capitalize">
                                                <v-icon left color="primary">mdi-account</v-icon>
                                                {{ $t(`${touristToken}s`) }}
                                            </v-card-title>
                                            <v-divider />
                                            <v-card-text>
                                                <template v-for="(tourist, index) in tourists">
                                                    <slot
                                                        name="tourists"
                                                        :disabled="!!bookingKey || !!basketKey"
                                                        :index="index"
                                                        :tourists="tourists"
                                                        :personSelected="personSelected"
                                                    >
                                                        <tourist-form
                                                            :key="index"
                                                            v-model="tourists[index]"
                                                            :index="index"
                                                            :label="touristToken"
                                                            :disabled="!!bookingKey || !!basketKey"
                                                            :corporate-policy-violation="
                                                                offer ? offer.corporatePolicyViolation : undefined
                                                            "
                                                            :hide-index="tourists.length === 1"
                                                            :selected-tourists="tourists"
                                                            :start-date="startDate"
                                                            :data-cy="`tourist-${index}`"
                                                            @person-selected="personSelected($event)"
                                                        />
                                                    </slot>
                                                </template>
                                            </v-card-text>
                                        </v-card>
                                    </slot>
                                    <slot name="forms" :bookingKey="bookingKey" :basketKey="basketKey" />
                                    <extra-services-form
                                        v-if="hasExtraServices"
                                        :p-store="productStore"
                                        :is-disabled="!!bookingKey || !!basketKey"
                                        @updateExtraServices="
                                            bookingKey = null
                                            basketKey = null
                                        "
                                    />
                                    <corporate-violation-reason
                                        v-if="corporatePolicyViolated && corporatePolicyType"
                                        v-model="corporatePolicyViolationCodeId"
                                        :type="corporatePolicyType"
                                        :policies="violatedPolicies"
                                        :disabled="!!bookingKey || !!basketKey"
                                        class="mb-2"
                                    />
                                    <add-on-booking-forms
                                        :selected-add-ons="selectedAddOns"
                                        :disabled="isLoading || pageLock || !!bookingKey || !!basketKey"
                                    />
                                    <add-ons
                                        v-if="showAddOns"
                                        :offer-key="offerKeys ? offerKeys[0] : $route.query.offerKey"
                                        :initial-tourist-booking-fields="touristBookingFields"
                                        class="pb-5"
                                        :disabled="
                                            isLoading || pageLock || !!bookingKey || !!basketKey || !resolveLoyalty
                                        "
                                        :product-type="productType"
                                        :offer="offer"
                                        @select="selectAddOn"
                                    />
                                    <loyalty-points
                                        v-if="isB2C"
                                        ref="loyaltyPoints"
                                        :is-loading="isLoading"
                                        :disabled="isLoading || pageLock || !!bookingKey || !!basketKey"
                                        :resolve-loyalty.sync="resolveLoyalty"
                                    />
                                    <slot name="orderCommentsForm" :bookingKey="bookingKey" :basketKey="basketKey" />
                                    <v-btn
                                        v-if="(bookingKey || basketKey) && !pageLock && !isSavingWithoutDetails"
                                        absolute
                                        dark
                                        fab
                                        bottom
                                        right
                                        small
                                        color="accent"
                                        class="mb-8"
                                        @click="resetBookResponse"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                                <template v-if="!isSavingWithoutDetails">
                                    <payment-options
                                        v-if="(bookingKey || basketKey) && canPay"
                                        v-model="paymentMethodId"
                                        :disabled="isLoading || pageLock"
                                        :_number-of-payments.sync="numberOfPayments"
                                        outlined
                                        class="mb-5 mt-8"
                                    />
                                    <div id="dropin-container" />
                                    <template v-if="hyperPaycheckoutId && hyperPayShopperResultUrl && showHPayIframe">
                                        <iframe
                                            style="width: 100%; border: none"
                                            :height="isMobile ? '770px' : '640px'"
                                            src="/hpay_iframe_template.html"
                                        />
                                    </template>
                                    <booking-agreement-form
                                        v-if="bookingKey || basketKey"
                                        v-model="isAgreementsConfirmed"
                                        :country-id="searchRequestCountryId"
                                        :due-to-confirm-date.sync="dueToConfirmDate"
                                        :due-to-confirm-date-active.sync="dueToConfirmDateActive"
                                        :start-date="startDate"
                                        :selected-add-ons="selectedAddOns"
                                    />
                                </template>
                                <v-row v-if="(!bookingKey && !basketKey) || isSavingWithoutDetails" class="my-8">
                                    <v-col cols="6" md="2">
                                        <v-btn
                                            :loading="isLoading"
                                            :disabled="isLoading || pageLock"
                                            depressed
                                            style="align-self: start"
                                            class="px-8"
                                            large
                                            block
                                            color="primary"
                                            text
                                            @click="$router.go(-1)"
                                        >
                                            {{ $t('previous_step') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="!isMobile" cols="3" />
                                    <v-col md="2" cols="6">
                                        <v-btn
                                            v-if="!bookingKey"
                                            :loading="isLoading"
                                            :disabled="isLoading || pageLock"
                                            depressed
                                            large
                                            block
                                            class="px-8 ms-auto"
                                            color="primary"
                                            @click="prepareBook"
                                        >
                                            {{ $t('payment') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="(bookingKey || basketKey) && !isSavingWithoutDetails" dense>
                                    <v-col v-if="canPay" :cols="saveMethod && !$breakpoint.smAndDown ? 6 : 12">
                                        <v-btn
                                            :loading="isLoading"
                                            :disabled="
                                                isLoading || !paymentMethodId || pageLock || !isAgreementsConfirmed
                                            "
                                            block
                                            depressed
                                            color="primary"
                                            class="main-booking-button"
                                            @click="book"
                                        >
                                            {{ $t('book') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="saveMethod" cols="12" md="6">
                                        <v-btn
                                            :disabled="
                                                isLoading ||
                                                pageLock ||
                                                !isAgreementsConfirmed ||
                                                dueToConfirmDateActive
                                            "
                                            :text="isSaveMethodButtonTextStyle"
                                            color="primary"
                                            block
                                            class="save-method-book-button"
                                            @click="save"
                                        >
                                            <v-icon>mdi-basket-fill</v-icon>
                                            {{ saveMethod.name }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-form>
                    </client-only>
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <slot
                        name="sidebar"
                        :booking-key="bookingKey"
                        :basket-key="basketKey"
                        :selected-add-ons="selectedAddOns"
                    />
                </v-col>
            </v-row>
        </v-container>
        <payment-redirect-form ref="paymentRedirectForm" />
        <prepare-book-messages-modal
            v-if="isPrepareBookResponseExist"
            ref="prepareBookMessagesModal"
            :prepare-book-response="prepareBookResponse"
            :offers-data="offersData"
            @approve="isSavingWithoutDetails ? bookWithoutDetails() : paymentStepInit()"
            @cancel="isSavingWithoutDetails ? (isSavingWithoutDetails = false) : cancelFlow()"
        />
        <script src="https://code.jquery.com/jquery.js" type="text/javascript" />
    </div>
</template>

<script>
    import {Component, Watch} from 'nuxt-property-decorator'
    import BookingPageBase from '@/components/parts/BookingPageBase'
    import {authStore, hotelsStore, persistentStore} from '@/utils/store-accessor'

    @Component
    export default class BookingPageBaseMod extends BookingPageBase {
        showHPayIframe = true

        @Watch('locale', {immediate: true})
        async onLocaleChange() {
            sessionStorage.setItem('locale', this.locale)
            this.showHPayIframe = false
            await this.$nextTick(() => {})
            this.showHPayIframe = true
        }

        @Watch('selectedPaymentMethod', {deep: true, immediate: true})
        async onSelectedPaymentMethodChange() {
            const tourLead = this.productStore.tourists[0] ? hotelsStore.tourists[0][0] : undefined
            const email = tourLead
                ? tourLead.email
                : authStore.person
                ? authStore.person.email || ''
                : authStore.registeredPerson
                ? authStore.registeredPerson.email || ''
                : ''
            sessionStorage.setItem('selectedPaymentMethod', JSON.stringify(this.selectedPaymentMethod))
            sessionStorage.setItem('customerEmail', email)
            this.showHPayIframe = false
            await this.$nextTick(() => {})
            this.showHPayIframe = true
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get selectedPaymentMethod() {
            return persistentStore.paymentMethods.find(paymentMethod => paymentMethod.id === this.paymentMethodId)
        }

        get locale() {
            return this.$i18n.locale
        }
    }
</script>
