<template>
    <div>
        <tourist-form
            ref="form"
            v-model="tourist"
            :index="index"
            :disabled="disabled"
            :corporate-policy-violation="corporatePolicyViolation"
            :hide-index="hideIndex"
            :disable-private-client-name="lockEditPrivateClientName"
            :hide-contact-data="roomLength !== 1 && !isTourLead && sameContactDataForAll"
            :selected-tourists="selectedTourists"
            :disable-citizenship="disableCitizenship"
            :start-date="startDate"
            :country-id="countryId"
            @person-selected="personSelected"
        >
            <template v-slot:label>
                <div class="d-flex">
                    <div v-if="!hideIndex" class="text-subtitle-1 text-capitalize font-weight-bold">
                        {{ `${$tc('guest', 1)} ${index + 1} ${isChild ? `(${$tc('child')})` : ''}` }}
                    </div>
                    <div v-if="bedType" class="ms-4 d-flex align-center">
                        <div v-if="bedType === 'onExtraBed'" class="font-weight-bold grey--text">
                            <v-icon color="info" left>mdi-bed-outline</v-icon>
                            {{ $t('extra_bed') }}
                        </div>
                        <div v-if="bedType === 'onWithoutPlace'" class="font-weight-bold grey--text">
                            <v-icon color="warning" left>mdi-bed-empty</v-icon>
                            {{ $t('without_bed') }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:beforeContacts>
                <v-col v-if="showMealTypes" cols="12" md="4" lg="2" class="hideEmpty">
                    <meal-type-selector
                        v-model="alternativeMealTypeId"
                        :room-index="roomIndex"
                        :standard-meal-name="standardMealName"
                        :disabled="disabled"
                        :is-tour-lead="isTourLead"
                        :tourist="tourist"
                        data-cy="tourist-field-meal-type"
                        @update="setExtraMealType"
                    />
                </v-col>
            </template>
            <v-col v-if="showSameContactDataCheckbox" cols="12">
                <v-checkbox
                    :input-value="sameContactDataForAll"
                    :label="`${$t('same_contact_data')}`"
                    @change="changeSameContactData"
                />
            </v-col>
        </tourist-form>
        <hotel-tourist-passport-form
            :room-index="roomIndex"
            :index="index"
            :disabled="disabled"
            :corporate-policy-violation="corporatePolicyViolation"
            @set-prop="$emit('change-passport')"
        />
    </div>
</template>

<script>
    import {Component, mixins, Prop, PropSync, VModel, Watch} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore, runtimeStore} from '@/store'
    import MealTypeSelector from '@/components/booking/forms/fields/MealTypeSelector'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TouristSelector from '@/components/parts/Booking/TouristSelector'
    import HotelTouristPassportForm from '@/components/hotels/booking/form/HotelTouristPassportForm'
    import {EventBus, SAME_CONTACT_DATA_FOR_ALL} from '@/utils/event-bus'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import TouristForm from '~src/components/booking/forms/touristForm.src'

    @Component({
        components: {
            TouristForm,
            HotelTouristPassportForm,
            TouristSelector,
            MealTypeSelector,
            DatePicker,
        },
    })
    export default class HotelTouristForm extends mixins(FormValidationRulesMixin, BookingFieldsMixin) {
        @VModel() tourist
        @Prop() roomIndex
        @Prop() index
        @Prop() disabled
        @Prop({default: false}) showMealTypes
        @Prop() standardMealName
        @PropSync('_valid', {default: false}) valid
        @Prop() corporatePolicyViolation
        @Prop({default: false, type: Boolean}) hideIndex
        @Prop({required: true}) startDate
        @Prop() hotel

        sameContactDataForAll = true

        @Watch('tourist.email', {immediate: true})
        onEmailChange(val) {
            if (this.index === 0 && this.roomLength > 1 && this.sameContactDataForAll) {
                this.setSameContactDataForAll('email', val)
            }
        }

        @Watch('tourist.phone', {immediate: true})
        onPhoneChange(val) {
            if (this.index === 0 && this.roomLength > 1 && this.sameContactDataForAll) {
                this.setSameContactDataForAll('phone', val)
            }
        }

        mounted() {
            EventBus.$on(SAME_CONTACT_DATA_FOR_ALL, this.setSameContactData)
        }

        beforeDestroy() {
            EventBus.$off(SAME_CONTACT_DATA_FOR_ALL, this.setSameContactData)

            this.setProp('alternativeMealTypeId', null)
        }

        personSelected({person}) {
            const index = this.index,
                roomIndex = this.roomIndex
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                hotelsStore.SET_TOURIST({roomIndex, index, val})
            } else {
                hotelsStore.SET_TOURIST_PROP({roomIndex, index, prop: 'personId', val: null})
            }
        }

        setSameContactDataForAll(prop, val) {
            const allRooms = hotelsStore.tourists.length
            for (let i = 0; i < allRooms; i++) {
                hotelsStore.SET_TOURIST_PROP({prop, val, roomIndex: i, index: 0})
            }
        }

        changeSameContactData(value) {
            EventBus.$emit(SAME_CONTACT_DATA_FOR_ALL, value)
            this.setSameContactDataForAll('email', hotelsStore.tourists[0][0].email)
            this.setSameContactDataForAll('phone', hotelsStore.tourists[0][0].phone)
        }

        setSameContactData(value) {
            this.sameContactDataForAll = value
        }

        setExtraMealType(value) {
            const type = `mealType-tourist-${this.roomIndex}-${this.index}`
            if (!value) {
                hotelsStore.DELETE_EXTRA_SERVICE(type)
            } else {
                const defaultMealTypePrice = hotelsRuntimeStore.mealTypePrice(this.defaultMealType.prices, this.tourist)
                hotelsStore.SET_BOOKING_EXTRA_SERVICE({
                    type,
                    roomIndex: this.roomIndex,
                    name: value.code,
                    price: {
                        currency: defaultMealTypePrice.currency,
                        amount:
                            hotelsRuntimeStore.mealTypePrice(value.prices, this.tourist).amount -
                            defaultMealTypePrice.amount,
                    },
                    quantity: 1,
                    isMealType: true,
                    mealTypeName: value.name,
                })
            }
        }

        getProp(prop) {
            return hotelsStore.tourists[this.roomIndex][this.index][prop]
        }

        setProp(prop, val) {
            const roomIndex = this.roomIndex,
                index = this.index
            hotelsStore.SET_TOURIST_PROP({prop, val, roomIndex, index})
        }

        get roomLength() {
            return hotelsStore.tourists.length
        }

        get selectedTourists() {
            return hotelsStore.tourists.reduce(
                (selectedTourists, roomTourists) => [...selectedTourists, ...roomTourists],
                []
            )
        }

        get isTourLead() {
            return this.roomIndex === 0 && this.index === 0
        }

        get defaultMealType() {
            return hotelsStore.extraMealTypes[this.roomIndex]?.find(({name}) => name === this.standardMealName)
        }

        get alternativeMealTypeId() {
            return this.getProp('alternativeMealTypeId') || this.defaultMealType?.id
        }

        set alternativeMealTypeId(value) {
            this.setProp('alternativeMealTypeId', value)
        }

        get lockEditPrivateClientName() {
            return this.$config.lockEditPrivateClientName && this.index === 0 && this.roomIndex === 0
        }

        get showSameContactDataCheckbox() {
            return (
                this.isTourLead &&
                this.roomLength > 1 &&
                (!this.isFieldDisabled('PHONE') || !this.isFieldDisabled('EMAIL'))
            )
        }

        get disableCitizenship() {
            return false
        }

        get countryId() {
            return hotelsRuntimeStore.city.countryId
        }

        get bedType() {
            if (this.tourist.onExtrabed) return 'onExtraBed'
            else if (this.tourist.onWithoutPlace) return 'onWithoutPlace'

            return null
        }
    }
</script>
