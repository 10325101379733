<template>
    <div class="full-width">
        <div class="d-flex flex-column flex-md-row">
            <div class="d-flex flex-column flex-md-row">
                <v-img
                    v-if="groupBy !== 'name' && imgSrc"
                    tile
                    class="me-md-2 cursor-pointer"
                    :src="imgSrc | imageUrl(300)"
                    :alt="room.name"
                    eager
                    :width="150"
                    :max-width="150"
                    height="100"
                    :lazy-src="'/placeholder.png' | srcHost"
                    @click="roomInfo"
                />
                <div class="caption">
                    <hotel-room-name-label
                        v-if="groupBy !== 'name'"
                        :hotel="hotel"
                        :room="room"
                        :compare-mode="compareMode"
                    >
                        <template v-slot>
                            <div class="d-flex align-center font-weight-bold cursor-pointer mb-1" @click="roomInfo">
                                <v-icon small left>mdi-information-outline</v-icon>
                                {{ room.name }}
                            </div>
                            <supplier-name-label
                                v-if="isMultipleSuppliers && isTO1"
                                :supplier-name="room.hotelSupplierName"
                                class="ml-6"
                            />
                        </template>
                    </hotel-room-name-label>
                    <special-offer-label v-if="showSpecialOfferLabel" :details="room.price.discountDetails" />
                    <hotel-available-label v-if="!room.soldOut" :room="room" />
                    <hotel-meal-type-label v-if="groupBy !== 'mealTypeStandardCode'" :hotel="hotel" :room="room" />
                    <tariff-label :hotel="hotel" :room="room" />
                    <conditions-label
                        v-if="(room && room.price) || ($config.packagesNotDeltaPrice && room.notDeltaPrice)"
                        :offer-key="room.groupedOffers[0].offerKey"
                        :price="room.price || room.notDeltaPrice"
                        :offer="hotel"
                        @conditions="showConditionsInfo"
                    />
                    <div class="d-flex">
                        <div v-if="room.bedroomsQuantity" class="me-3">
                            <v-icon color="info" :title="$t('bedrooms_quantity')">mdi-bed-outline</v-icon>
                            {{ room.bedroomsQuantity }}
                        </div>
                        <div v-if="room.bathroomsQuantity" class="me-3">
                            <v-icon color="info" :title="$t('bathrooms_quantity')">mdi-shower</v-icon>
                            {{ room.bathroomsQuantity }}
                        </div>
                        <hotel-bed-type-label v-if="room.bedTypes && room.bedTypes.length" :bed-types="room.bedTypes" />
                    </div>
                    <div v-if="accommodationText" class="font-italic">
                        {{ accommodationText }}
                    </div>
                </div>
            </div>
            <div v-if="!room.soldOut" class="price-col ms-auto">
                <template v-if="!isMultiRoom">
                    <slot name="price" :room="room">
                        <hotel-room-offer-price-block :offer="hotel" :room="room" :search-request="searchRequest" />
                    </slot>
                </template>
                <template v-else>
                    <hotel-room-offer-price-block
                        v-if="!isPackage"
                        :offer="hotel"
                        :room="room"
                        :search-request="searchRequest"
                        :rph="rph"
                        class="me-4"
                    />
                    <slot v-else name="price" :room="room" />
                </template>
            </div>
            <div v-else class="price-col ms-auto">
                <sold-out-modal small :reason="room.soldOutReason" />
            </div>
            <span
                v-if="room.price && room.price.discountDetails && room.specialOffer && isMobile"
                class="success--text caption font-weight-medium"
            >
                {{ room.price.discountDetails }}
            </span>
        </div>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'nuxt-property-decorator'
    import HotelRoomNameLabel from '@/components/hotels/snippets/HotelRoomNameLabel'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_HOTEL_CONDITIONS_INFO, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import HotelRoomOfferPriceBlock from '~src/components/search/offers/hotelRoomOfferPriceBlock.src'
    import HotelMealTypeLabel from '~src/components/hotels/snippets/hotelMealTypeLabel.src'
    import HotelBedTypeLabel from '@/components/hotels/snippets/HotelBedTypeLabel'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'
    import HotelAvailableLabel from '~src/components/hotels/snippets/hotelAvailableLabel.src'
    import SpecialOfferLabel from '~src/components/hotels/search/offers/specialOfferLabel.src'
    import SupplierNameLabel from '@/components/hotels/snippets/SupplierNameLabel'
    import {authStore} from '@/utils/store-accessor'
    import SoldOutModal from '~src/components/snippets/soldOutModal.src'

    @Component({
        components: {
            SpecialOfferLabel,
            HotelRoomNameLabel,
            HotelAvailableLabel,
            HotelMealTypeLabel,
            HotelBedTypeLabel,
            ConditionsLabel,
            HotelRoomOfferPriceBlock,
            TariffLabel,
            SupplierNameLabel,
            SoldOutModal,
        },
    })
    export default class HotelRoom extends Vue {
        @Prop({default: false, type: Boolean}) compareMode
        @Prop({required: true}) hotel
        @Prop({required: true}) room
        @Prop({required: true}) searchRequest
        @Prop({required: true}) hotelInfo
        @Prop({default: 'none'}) groupBy
        @Prop() rph
        @Prop({default: false, type: Boolean}) isPackage

        showConditionsInfo() {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.hotel, this.room)
        }

        roomInfo() {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, this.hotel, this.room.groupedOffers[0].offerKey)
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get imgSrc() {
            const roomType = this.hotelInfo.hotelDescriptionInfo.roomTypes?.roomType.find(
                roomType => roomType.code === this.room.code
            )
            return roomType?.images.image[0]?.url
        }

        get isMultiRoom() {
            return this.searchRequest.rooms?.length > 1
        }

        get accommodationText() {
            if (this.room.onExtraBed && this.room.onWithoutPlace) {
                return this.$t('accommodated_on_extra_bed_or_without_bed')
            } else if (this.room.onExtraBed && !this.room.onWithoutPlace) {
                return this.$t('accommodated_on_extra_bed')
            } else if (!this.room.onExtraBed && this.room.onWithoutPlace) {
                return this.$t('accommodated_without_bed')
            }

            return ''
        }

        get isMultipleSuppliers() {
            return this.hotel.rooms?.some(room => room.hotelSupplierName !== this.hotel.supplierName)
        }

        get isTO1() {
            return authStore.isTO1
        }

        get showSpecialOfferLabel() {
            return (
                (this.isPackage && this.room.price?.discountDetails) ||
                (!this.room.soldOut && (this.room.specialOffer || this.room.price?.discountDetails))
            )
        }
    }
</script>

<style scoped lang="scss">
    .price-col {
        width: 180px;

        .show-rooms-label__short {
            display: none;
        }
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;

            .show-rooms-label {
                display: none;

                &__short {
                    display: inline;
                }
            }
        }
    }
</style>
