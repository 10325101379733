<template>
    <div class="d-flex flex-column">
        <v-row v-if="!isMobile" class="py-4">
            <v-col cols="12" md="4" class="d-flex align-center">
                <span class="caption font-weight-medium">
                    {{ $t('order_service') }}
                </span>
            </v-col>
            <v-col v-if="showPrice" cols="3" md="2">
                <span class="caption font-weight-medium">{{ $t('price') }}</span>
            </v-col>
            <v-col cols="3" md="2">
                <span class="caption font-weight-medium">{{ $t('price_per') }}</span>
            </v-col>
            <v-col cols="3" md="2">
                <span class="caption font-weight-medium">{{ $t('quantity') }}</span>
            </v-col>
            <v-col v-if="showPrice" cols="3" md="2">
                <span class="caption font-weight-medium">{{ $t('total') }}</span>
            </v-col>
        </v-row>
        <v-divider v-if="!isMobile" />
        <v-row class="py-4">
            <v-col cols="12" md="4" class="d-flex flex-column">
                <div v-if="isMobile" class="caption font-weight-medium mb-2">
                    {{ $t('order_service') }}
                </div>
                <div class="d-flex align-start ms-md-2">
                    <v-checkbox
                        v-model="checked"
                        style="margin-top: -5px"
                        :disabled="hideAction"
                        @change="$emit('update', {service: extraService, qty: qty, operation: $event})"
                    />
                    <div class="h1 text-wrap">
                        {{ extraService.name }}
                        <v-icon v-if="extraService.description" small @click="showDescription = true">
                            mdi-information-outline
                        </v-icon>
                    </div>
                </div>
                <v-dialog v-model="showDescription" width="500" class="description-modal">
                    <v-card class="pa-4" v-html="extraService.description" />
                </v-dialog>
            </v-col>
            <v-col v-if="showPrice" cols="6" md="2">
                <div v-if="isMobile" class="caption font-weight-medium mb-2">{{ $t('price') }}</div>
                <div>
                    {{ extraServicePrice | price }}
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div v-if="isMobile" class="caption font-weight-medium mb-2">{{ $t('price_per') }}</div>
                <div>
                    {{ extraService.perPax ? $t('for_guest') : $t('order_service') }}
                    {{
                        extraService.calculationType !== 'BY_SERVICE' || extraService.perPax
                            ? $t(`extra_services_details.type.${extraService.calculationType}`)
                            : ''
                    }}
                </div>
            </v-col>
            <v-col cols="6" md="2">
                <div v-if="isMobile" class="caption font-weight-medium mb-2">{{ $t('quantity') }}</div>
                <div class="d-flex align-center">
                    <v-btn
                        :disabled="hideAction"
                        tile
                        dark
                        x-small
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="minus"
                    >
                        <v-icon x-small dark> mdi-minus </v-icon>
                    </v-btn>
                    <span class="mx-4"> {{ qty }} </span>
                    <v-btn
                        :disabled="hideAction"
                        tile
                        dark
                        x-small
                        icon
                        style="background-color: #62b7e5"
                        elevation="0"
                        @click="plus"
                    >
                        <v-icon x-small dark> mdi-plus </v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col v-if="showPrice" cols="6" md="2">
                <div v-if="isMobile" class="caption font-weight-medium mb-2">{{ $t('total') }}</div>
                <div>
                    {{ totalPrice | price }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import ExtraService from '@/components/booking/forms/fields/ExtraService'

    @Component
    export default class ExtraServiceMod extends ExtraService {
        plus() {
            this.qty++
            this.validateMaxQty()
        }

        minus() {
            this.qty--
            this.validateMaxQty()
        }

        validateMaxQty() {
            this.qty = this.qty > 100 ? 100 : this.qty < 0 ? 0 : this.qty
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>
