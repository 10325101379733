<template>
    <v-form ref="form" v-model="valid" :disabled="loading">
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="formData.name"
                    class="text-subtitle-2 font-weight-regular px-1 rounded-sm mt-0 white-slot py-0"
                    :placeholder="$t('name')"
                    :rules="[requiredRule('name')]"
                    dense
                    filled
                    single-line
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="formData.clientEmail"
                    class="text-subtitle-2 font-weight-regular px-1 rounded-sm mt-0 white-slot py-0"
                    :placeholder="$t('enter_email')"
                    :rules="validationRules('email', [emailRule, requiredRule])"
                    dense
                    filled
                    single-line
                />
            </v-col>
            <v-col cols="12">
                <v-textarea
                    v-model="formData.text"
                    rows="3"
                    dense
                    class="text-subtitle-2 font-weight-regular px-1 rounded-sm mt-0 white-slot py-0"
                    :placeholder="$t('your_question')"
                    :rules="[requiredRule('your_question')]"
                    filled
                    single-line
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class="d-flex align-center">
                    <v-checkbox
                        v-model="checked"
                        class="privacy-checkbox"
                        :label="$t('privacy_policy_agree')"
                        :rules="[v => !!v || `${$t('privacy_policy')} ${$t('validation.required')}`]"
                    />
                </div>
            </v-col>
        </v-row>
        <v-btn
            elevation="0"
            :disabled="!valid"
            :block="isMobile"
            :large="isMobile"
            class="primary text-subtitle-2 font-weight-regular font-weight-regular px-8 mt-4"
            @click="send"
        >
            {{ $t('send') }}
        </v-btn>
    </v-form>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    const resetFormData = () => ({
        clientEmail: '',
        text: '',
        name: '',
    })

    @Component
    export default class QuestionsBlockForm extends mixins(FormValidationRulesMixin) {
        checked = false
        formData = resetFormData()
        loading = false
        valid = false

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        async send() {
            if (!this.valid || this.loading) return
            try {
                this.loading = true
                await this.$api.mail.post({
                    message: {
                        from: this.$config.nodemailerAddressFrom,
                        to: this.$config.nodemailerAddressTo,
                        subject: `Questions from ${location.hostname}`,
                        text: `Dear Ard Aljuzur Tourism Company,\nYour client ${this.formData.name} has the following question:\n${this.formData.text}\nPlease, kindly reply on the following email: ${this.formData.clientEmail}`,
                    },
                })
                await this.$api.mail.post({
                    message: {
                        from: this.$config.nodemailerAddressFrom,
                        to: this.formData.clientEmail,
                        subject: `Questions from ${location.hostname}`,
                        text: `Dear ${this.formData.name},\nYour question has been received and we will reply to you soon.\nThank you.`,
                    },
                })
                this.$toast.success(this.$t('success_message.send_email'))
                this.formData = resetFormData()
                this.$refs.form.reset()
            } catch (e) {
                this.$toast.error(this.$t('error_message.send_email_error'))
            } finally {
                this.loading = false
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .privacy-checkbox {
            label {
                font-weight: 400;
                font-size: 14px;
                color: #555555;
            }
        }
    }
</style>
