<template>
    <service-card
        :service-tips="serviceTips"
        :service="service"
        icon="mdi-airplane"
        :is-first="isFirst"
        :order="order"
        @showInfo="showInfo"
        @modifyService="$emit('modifyService', $event)"
    >
        <template v-slot:checkbox>
            <slot name="checkbox" />
        </template>
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :is-package="isPackage"
                :service-image="mainImage"
                :order="order"
                :not-itinerary-start="notItineraryStart"
                @cancelService="$emit('cancelService', $event)"
                @issueTicket="$emit('issueTicket', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div v-if="service.status === 'Quote'" class="d-flex align-center">
                    <v-icon left small color="primary" @click="fillFlightBookingDataModal = true">mdi-pencil</v-icon>
                    <span class="text-caption cursor-pointer primary--text" @click="fillFlightBookingDataModal = true">
                        {{ $t('enter_details') }}
                    </span>
                    <fill-flight-booking-data-modal
                        v-model="fillFlightBookingDataModal"
                        :service="service"
                        :order="order"
                    />
                </div>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">mdi-close</v-icon>
                </div>
                <service-info-base :service="service">
                    <template v-slot:serviceInfo>
                        <flight-itinerary
                            v-for="(itinerary, index) in itineraries"
                            :key="`itinerary-${index}`"
                            :itinerary="itinerary"
                        />
                        <detail-modal :offer="{processId, itinerary: itineraries}" />
                        <fare-rules :process-id="processId" />
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop} from 'nuxt-property-decorator'
    import ServiceInfoBase from '~/components/account/orderServices/ServiceInfoBase'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import DetailModal from '~/components/flights/DetailModal'
    import FareRules from '~/components/flights/FareRules'
    import ServiceCard from '~src/components/account/orderServices/serviceCard.src'
    import OrderInfoBase from '~src/components/account/orderServices/orderInfoBase.src'
    import {fullUrl} from '~src/utils/host-adapter'
    import ServiceBase from '@/components/account/orderServices/ServiceBase'
    import FillFlightBookingDataModal from '@/components/account/modals/newOrderRequest/FillFlightBookingDataModal'

    @Component({
        components: {
            FillFlightBookingDataModal,
            ServiceCard,
            FlightItinerary,
            OrderInfoBase,
            ServiceInfoBase,
            DetailModal,
            FareRules,
        },
    })
    export default class FlightService extends ServiceBase {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop() itineraryService
        @Prop() isFirst

        airports = {}
        itineraries = []
        modal = false
        fillFlightBookingDataModal = false

        showInfo() {
            this.modal = true
        }

        async mounted() {
            await this.getAirportsData()
        }

        async getAirportsData() {
            let codes = new Set()
            this.service.serviceDetails[0].itineraries.forEach(itinerary => {
                itinerary.flightSegments.forEach(segment => {
                    codes.add(segment.arrivalAirportCode)
                    codes.add(segment.departureAirportCode)
                })
            })
            codes = Array.from(codes)
            const promises = []
            codes.forEach(code => {
                let rq = {
                    pattern: code,
                }
                promises.push(this.$api.airports.get(rq))
            })
            await Promise.all(promises).then(data => {
                data.forEach((response, index) => {
                    let airport = response.find(el => el.iataCode === codes[index] && el.type === 'airport')
                    if (!airport) {
                        airport = response.find(el => el.iataCode === codes[index])
                    }
                    this.$set(this.airports, codes[index], airport)
                })
            })
            this.getItineraries()
        }

        getItineraries() {
            const result = this.service.serviceDetails[0].itineraries.map(({duration, flightSegments}) => {
                const segments = flightSegments.map(
                    ({
                        flightSegmentName,
                        marketingAirlineName,
                        mealCode,
                        rph,
                        baggageInfo,
                        aircraftCode,
                        stopLocations,
                        departureAirportCode,
                        arrivalAirportCode,
                        categoryClass,
                        flightNumber,
                        stopQuantity,
                        arrivalDate,
                        departureDate,
                        aircraftName,
                        brandedFareName,
                        marketingAirline,
                    }) => {
                        const departure = this.airports[departureAirportCode] || {}
                        departure['airportName'] = departure['name']
                        const arrival = this.airports[arrivalAirportCode] || {}
                        arrival['airportName'] = arrival['name']

                        return {
                            name: flightSegmentName,
                            marketingAirlineName,
                            duration,
                            mealCode,
                            rph,
                            baggageInfo,
                            departure: {
                                airportDescription: this.airports[departureAirportCode],
                                airportCode: departureAirportCode,
                                date: departureDate,
                            },
                            arrival: {
                                airportDescription: this.airports[arrivalAirportCode],
                                airportCode: arrivalAirportCode,
                                date: arrivalDate,
                            },
                            aircraft: {
                                code: aircraftCode,
                                name: aircraftName,
                            },
                            stops: {
                                locations: stopLocations,
                                quantity: stopQuantity,
                            },
                            class: categoryClass,
                            flightNumber,
                            marketingAirline,
                            brandedFareName,
                        }
                    }
                )

                return {
                    duration,
                    segments,
                }
            })
            this.itineraries = result
        }

        get name() {
            return this.service.serviceDetails[0].flightName
        }

        get airportsLoaded() {
            return Object.keys(this.airports).length > 0
        }

        get processId() {
            return this.service.processId.replace(/^.{6}/, '')
        }

        get serviceCheckIn() {
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `${this.$t('flights.departure')}: ${date}`
        }

        get serviceCheckOut() {
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `${this.$t('flights.arrival')}: ${date}`
        }

        get mainImage() {
            return fullUrl(
                `/stat/images/airlines/${this.service.serviceDetails[0].itineraries[0].flightSegments[0].marketingAirline}.gif`
            )
        }

        get notItineraryStart() {
            return this.itineraryService?.type?.start === false
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
