<script>
    import {Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'

    @Component
    export default class BreadcrumbsMod extends Breadcrumbs {
        get items() {
            const items = Breadcrumbs.options.computed.items.get.call(this)
            if (this.$route.name === 'order-details') {
                items.pop()
                items.push({
                    text: this.text,
                    disabled: true,
                })
            }
            return items
        }
    }
</script>
