<template>
    <v-card outlined>
        <nuxt-link no-prefetch :to="productLink" class="title text-decoration-none">
            <v-img
                tile
                class="white--text align-end"
                height="200px"
                :src="product.info.imageUrl | imageUrl(200)"
                :alt="product.info.name"
                :lazy-src="'/placeholder.png' | srcHost"
                @error="imgError = true"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.info.imageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    <div class="d-flex flex-column">
                        <span class="subtitle-2">
                            {{ product.info.name }}
                        </span>
                        <span v-if="minPriceOffer.transferType" class="grey--text text--lighten-1 caption mt-1">
                            {{ $t(`filters_transferType.${minPriceOffer.transferType}`) }}
                        </span>
                    </div>
                </v-card-title>
            </v-img>
        </nuxt-link>
        <v-card-text>
            <template v-if="product.offers[0].price && !hidePrice">
                <nuxt-link no-prefetch :to="productLink" class="title text-decoration-none">
                    <strong data-cy="client-price" class="black--text">
                        {{ $t('from') }} {{ minPriceOffer.price | price }}
                    </strong>
                </nuxt-link>
                <discount-label :price="minPriceOffer.price" />
                <commission-amount :commission="minPriceOffer.price.commission" />
            </template>
            <transfer-driver-languages :lang-codes="langCodes" />
        </v-card-text>
        <v-expand-transition>
            <div v-if="expanded">
                <transfer-offer-card
                    v-for="(offer, offerIndex) in product.offers.slice(0, expanded)"
                    :key="offerIndex"
                    :offer="offer"
                    :full-card="false"
                    :data-cy="`transfer-offer-card-${offerIndex}`"
                >
                    <template v-slot:price>
                        <slot name="price" :offer="offer" />
                    </template>
                </transfer-offer-card>
            </div>
        </v-expand-transition>
        <v-card-actions>
            <v-list-item dense class="d-flex justify-center" @click.stop="open">
                <v-icon v-text="`mdi-chevron-${!expanded || expanded < product.offers.length ? 'down' : 'up'}`" />
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import TransferCard from '~src/components/transfers/search/offers/transferCard.src'
    import TransferOfferCard from '~src/components/transfers/info/transferOfferCard.src'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import TransferDriverLanguages from '@/components/snippets/TransferDriverLanguages'

    @Component({
        components: {
            TransferDriverLanguages,
            DiscountLabel,
            CommissionAmount,
            TransferOfferCard,
        },
    })
    export default class TransferCardMobile extends TransferCard {
        open() {
            if (!this.expanded || this.expanded >= this.product.offers.length) {
                this.toggleOffers()
            } else {
                this.showAllOffers()
            }
        }

        showAllOffers() {
            this.expanded = this.product.offers.length
        }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
</style>
