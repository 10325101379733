var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_vm._l((_vm.routes.length),function(route,index){return _c('v-col',{key:("selected-route-" + route),class:{'col-12': _vm.$breakpoint.smAndDown}},[_c('v-card',{attrs:{"elevation":_vm.activeRoute === index ? 12 : 2,"min-height":"100%"}},[_c('v-card-title',[_vm._v("\n                "+_vm._s(_vm.routes[index].departure.cityName)+" - "+_vm._s(_vm.routes[index].arrival.cityName)+"\n            ")]),_vm._v(" "),_c('v-card-text',[(_vm.offers[index])?_c('div',[_vm._l((_vm.offers[index].itinerary),function(itinerary,itineraryIndex){return _c('flight-itinerary',{key:itineraryIndex,attrs:{"itinerary":itinerary,"minify":false}})}),_vm._v(" "),(_vm.$route.name !== 'packages')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('detail-modal',{attrs:{"offer":{
                                        unitKey: _vm.offers[index].unitKey,
                                        itinerary: _vm.offers[index].itineraries,
                                    }}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('flight-class-selector',{attrs:{"offer":_vm.offers[index],"value":_vm.selectedScheduleItems[index].categoryClass},on:{"select-class":function($event){return _vm.setSelectedScheduleItem({
                                            index: index,
                                            offer: _vm.offers[index],
                                            categoryClass: $event,
                                        })}}})],1)],1)],1):_vm._e()],2):_c('div',[(index === _vm.activeRoute)?_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t('flights.select_offer'))+"\n                    ")]):_vm._e()])]),_vm._v(" "),_c('v-card-actions',[(index !== _vm.activeRoute && _vm.offers[index])?_c('v-btn',{on:{"click":function($event){return _vm.$emit('change-route', index)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('change'))+"\n                ")]):_vm._e()],1)],1)],1)}),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","disabled":_vm.offers.length !== _vm.routes.length},on:{"click":_vm.checkAvailability}},[_vm._v("\n            "+_vm._s(_vm.$t('flights.continue'))+"\n        ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }