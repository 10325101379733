<template>
    <v-expand-transition>
        <v-data-table
            v-if="groupItems"
            :headers="headers"
            :items="groupItems"
            group-by="date"
            :loading="detailsLoading"
            class="elevation-1"
            :sort-by="'time'"
            :items-per-page="15"
            mobile-breakpoint="0"
            :hide-default-header="isMobile"
        >
            <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="12" class="cursor-pointer" @click="toggle">
                    <v-icon>{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }} </v-icon>
                    <span>
                        {{ `${items[0].date} ( ${getDayOfWeek(items[0].date)} )` }}
                    </span>
                </th>
            </template>
            <template v-slot:item.service="{item}">
                <div class="d-flex flex-column py-3 align-start">
                    <span class="d-flex justify-end justify-md-start font-weight-medium">
                        <v-icon small class="mr-1 ml-n1 d-inline-block">{{
                            getServiceTypeIcon(item.serviceType)
                        }}</v-icon>
                        <div class="text-subtitle-2" v-html="item.serviceName" />
                    </span>
                    <span class="text-caption text--secondary">
                        {{ `${item.city}, ${item.country}` }}
                    </span>
                    <span v-if="isMobile">
                        {{ `${item.touristsQuantity}  ${$tc('guest', item.touristsQuantity)}` }}
                    </span>
                </div>
            </template>

            <template v-slot:item.status="{item}">
                <span :class="item.statusColor">{{ $t(`extranetGroupStatus.${item.status}`) }}</span>
            </template>
            <template v-slot:item.details="{item}">
                <div class="d-flex flex-column align-end py-3">
                    <span v-if="isMobile" :class="item.statusColor" class="pb-1">{{
                        $t(`extranetGroupStatus.${item.status}`)
                    }}</span>
                    <v-btn :color="'primary'" small :disabled="detailsLoading" @click="showDetails(item)">
                        {{ $t('details') }}
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-expand-transition>
</template>

<script>
    import {Component, Prop, VModel, Vue} from 'nuxt-property-decorator'
    import {getServiceTypeIcon} from '@/utils/helpers'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class GroupsTable extends Vue {
        @VModel() selectedGroup
        @Prop() groups
        @Prop() detailsLoading

        getDayOfWeek(dateString) {
            const date = new Date(dateString)

            return new Intl.DateTimeFormat(this.$i18n.locale, {weekday: 'short'}).format(date)
        }

        getServiceTypeIcon(serviceType) {
            return getServiceTypeIcon(serviceType)
        }

        showDetails(group) {
            this.selectedGroup = group
        }

        get headers() {
            return this.isMobile
                ? [
                      {text: this.$t('time'), value: 'time'},
                      {text: this.$t('service'), value: 'service'},
                      {text: this.$t('details'), value: 'details', align: 'center'},
                  ]
                : [
                      {text: 'Id', value: 'groupId', align: 'center'},
                      {text: this.$t('time'), value: 'time'},
                      {text: this.$t('status'), value: 'status'},
                      {text: this.$t('order_service'), value: 'service'},
                      {text: this.$t('guests'), value: 'touristsQuantity', align: 'center'},
                      {text: this.$t('type'), value: 'programType'},
                      {text: this.$t('details'), value: 'details', align: 'center'},
                  ]
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get groupItems() {
            return this.groups?.map(e => {
                return {
                    ...e,
                    time: e.dateTime.split(' ')[1],
                    date: e.dateTime.split(' ')[0],
                    service: {
                        serviceName: e.serviceName,
                        serviceType: e.serviceType,
                        country: e.country,
                        city: e.city,
                    },
                    statusColor: runtimeStore.orderStatusColor(e.status),
                }
            })
        }
    }
</script>
