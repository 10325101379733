var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.isLoading ? [] : _vm.services,"items-per-page":_vm.ordersOnPage,"server-items-length":_vm.ordersCount,"loading":_vm.isLoading,"hide-default-footer":"","options":_vm.options,"footer-props":{'items-per-page-options': [_vm.ordersOnPage, 100]},"hide-default-header":_vm.isMobile,"no-data-text":_vm.noDataText,"mobile-breakpoint":0,"data-cy":"orders-table","group-by":"orderId","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.selectable)?{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('v-checkbox',{attrs:{"ripple":false,"value":false,"dense":"","hide-details":""},on:{"change":_vm.changeSelectAll},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(header.text))])]},proxy:true}],null,true),model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]}}:null,{key:"header.date",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.toggleSortOrder}},[_vm._v("\n                    "+_vm._s(header.text)+"\n                    "),(_vm.sortOrder !== 'none')?_c('v-icon',{staticClass:"cursor-pointer hidden-sm-and-down me-1"},[_vm._v("\n                        "+_vm._s(_vm.sortOrder === 'ASCENDING' ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n                    ")]):_vm._e()],1)]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"text-start cursor-pointer toggle-expanded",class:{open: isOpen},on:{"click":function($event){return _vm.toggleExpanded(toggle)}}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.isB2B)?_c('v-icon',{staticClass:"cursor-pointer hidden-sm-and-down me-1"},[_vm._v("\n                            "+_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n                        ")]):_vm._e(),_vm._v(" "),(_vm.selectable && !_vm.isHideCheckbox && !_vm.update)?_c('v-checkbox',{key:group,attrs:{"ripple":false,"value":_vm.getOrder(group),"value-comparator":function (orderA, orderB) { return orderA.orderId === orderB.orderId; },"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_vm._v(" "),(_vm.selectable)?[_c('nuxt-link',{attrs:{"no-prefetch":"","to":{name: 'order-details', params: {id: group}}}},[_c('strong',[_vm._v(_vm._s(group))])]),_vm._v(" "),(!_vm.orderTitleEdit[group])?_c('span',{staticClass:"ml-2"},[_vm._v("\n                                "+_vm._s(_vm.getOrder(group).title)+"\n                                "),_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.$set(_vm.orderTitleEdit, group, true)
                                        _vm.$set(_vm.orderTitle, group, _vm.getOrder(group).title)}}},[_vm._v("\n                                    mdi-pencil\n                                ")])],1):_c('div',{staticClass:"d-flex align-center ml-4"},[_c('v-text-field',{staticClass:"order-title-edit",attrs:{"dense":"","hide-details":""},model:{value:(_vm.orderTitle[group]),callback:function ($$v) {_vm.$set(_vm.orderTitle, group, $$v)},expression:"orderTitle[group]"}}),_vm._v(" "),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.saveOrderTitle(group)}}},[_vm._v("\n                                    "+_vm._s(_vm.$t('save'))+"\n                                ")])],1)]:_c('nuxt-link',{attrs:{"no-prefetch":"","to":{name: 'order-details', params: {id: group}}}},[_c('strong',[_vm._v(_vm._s(group))])])],2)]),_vm._v(" "),(_vm.isB2C && !_vm.isMobile)?[_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td'),_vm._v(" "),_c('td',{staticClass:"cursor-pointer py-1 text-right",on:{"click":function($event){return _vm.toggleExpanded(toggle)}}},[(!_vm.groupedOrder(group).isEmptyOrder)?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.groupedOrder(group).brutto.title)}}),_vm._v(" "),(
                                    (_vm.isAgency || _vm.isTourOperator) &&
                                    _vm.groupedOrder(group).brutto.commissionAmount &&
                                    _vm.commissionAndNettoCheckbox
                                )?_c('div',[_c('div',{staticClass:"commission text-no-wrap"},[_vm._v("\n                                    "+_vm._s(_vm.$t('commission'))+":\n                                    "+_vm._s(_vm._f("priceFormat")(_vm.groupedOrder(group).brutto.commissionAmount,_vm.groupedOrder(group).brutto.currency))+"\n                                ")]),_vm._v(" "),_c('div',{staticClass:"commission text-no-wrap"},[_vm._v("\n                                    "+_vm._s(_vm.$t('net_amount'))+":\n                                    "+_vm._s(_vm._f("priceFormat")((_vm.groupedOrder(group).brutto.amount -
                                            _vm.groupedOrder(group).brutto.commissionAmount),_vm.groupedOrder(group).brutto.currency))+"\n                                ")])]):_vm._e(),_vm._v(" "),_c('payment-status-label',{attrs:{"status":_vm.groupedOrder(group).brutto.status}})]:_vm._e()],2),_vm._v(" "),_c('td')]:_vm._e()]}},{key:"item.mobile",fn:function(ref){
                                            var item = ref.item;
return [_c('orders-service-mobile',{attrs:{"service":item},on:{"open-voucher-dialog":_vm.openVoucherDialog,"show-add-service-modal":_vm.showAddServiceModal}})]}},{key:"item.created",fn:function(ref){
                                            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.created)}})]}},{key:"item.modified",fn:function(ref){
                                            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.modified)}})]}},{key:"item.title",fn:function(ref){
                                            var item = ref.item;
return [(!item.isEmptyOrder)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-flex justify-end justify-md-start"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(_vm.getServiceTypeIcon(item.serviceType)))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(item.pureTitle)}})],1),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(item.title + item.location)}})]):_vm._e(),_vm._v(" "),(!_vm.$config.account.hideAddServiceBtn && item.isEmptyOrder)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":'primary',"small":""},domProps:{"textContent":_vm._s(_vm.$t('add_service'))},on:{"click":function($event){return _vm.showAddServiceModal(item.orderId)}}}):_vm._e()]}},{key:"item.date",fn:function(ref){
                                            var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.date)}})]}},{key:"item.travellers",fn:function(ref){
                                            var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.travellers)}})]}},{key:"item.status",fn:function(ref){
                                            var item = ref.item;
return [_c('span',{class:((item.statusColor) + "--text")},[_vm._v(_vm._s(item.status))]),_vm._v(" "),(_vm.isTO1)?_c('div',[_c('span',{staticClass:"commission"},[_vm._v("\n                        "+_vm._s(item.supplierCompanyName)+"\n                    ")])]):_vm._e()]}},{key:"item.statusByServices",fn:function(ref){
                                            var item = ref.item;
return [_c('span',{class:((item.statusColor) + "--text")},[_vm._v(_vm._s(item.status))]),_vm._v(" "),(_vm.isTO1)?_c('div',[_c('span',{staticClass:"commission"},[_vm._v("\n                        "+_vm._s(item.supplierCompanyName)+"\n                    ")])]):_vm._e()]}},{key:"item.supplierPrice",fn:function(ref){
                                            var item = ref.item;
return [(!item.isEmptyOrder && item.showAllServicesPrice)?_c('orders-supplier-price-label',{attrs:{"item":item}}):_vm._e()]}},{key:"item.price",fn:function(ref){
                                            var item = ref.item;
return [(!item.isEmptyOrder && item.showAllServicesPrice)?_c('orders-price-label',{attrs:{"item":item}}):_vm._e()]}},(_vm.isShowDueToPay)?{key:"item.dueToPay",fn:function(ref){
                                            var item = ref.item;
return [(!item.isEmptyOrder && item.status !== 'Quote' && item.showAllServicesPrice)?_c('div',[_vm._v("\n                    "+_vm._s(_vm._f("priceFormat")(_vm.isAgency
                            ? item.supplier.price.amountDue
                            : item.customer.price.amountDue,item.supplier.price.currency))+"\n                ")]):_vm._e()]}}:null,{key:"item.income",fn:function(ref){
                            var item = ref.item;
return [(!item.isEmptyOrder && item.income.amount && item.showAllServicesPrice)?_c('div',[_vm._v("\n                    "+_vm._s(_vm._f("priceFormat")(item.income.amount,item.income.currency))+"\n                    "),(item.income.percent)?_c('div',{staticClass:"grey--text"},[_vm._v("\n                        "+_vm._s(((item.income.percent) + "%"))+"\n                    ")]):_vm._e()]):_vm._e()]}},{key:"item.clientCompanyName",fn:function(ref){
                            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.clientCompanyName))]),_vm._v(" "),_c('small',[_vm._v(_vm._s(item.agent))])]}},{key:"item.voucher",fn:function(ref){
                            var item = ref.item;
return [(!item.isEmptyOrder && _vm.voucherAvailable(item))?_c('v-btn',{staticClass:"cursor-pointer",staticStyle:{"text-transform":"unset !important"},attrs:{"text":""},on:{"click":function($event){return _vm.openVoucherDialog(item)}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-icon',{staticClass:"mt-1",attrs:{"dense":""}},[_vm._v("mdi-tray-arrow-down")])],1)]):_vm._e()]}}],null,true)}),_vm._v(" "),(_vm.pagingLength)?_c('v-pagination',{staticClass:"py-8",attrs:{"light":"","length":_vm.pagingLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_vm._v(" "),_c('voucher-dialog',{attrs:{"is-loading":_vm.voucherLoading,"title":_vm.$t('voucher')},on:{"download-voucher":function($event){return _vm.downloadVoucher(_vm.voucherService)},"send-on-email":function($event){return _vm.sendVoucherOnEmail(_vm.voucherService, $event)}},model:{value:(_vm.isShowVoucherDialog),callback:function ($$v) {_vm.isShowVoucherDialog=$$v},expression:"isShowVoucherDialog"}}),_vm._v(" "),(_vm.addServiceOrder)?_c('order-add-service-modal',{attrs:{"order":_vm.addServiceOrder},model:{value:(_vm.isShowOrderAddServiceModal),callback:function ($$v) {_vm.isShowOrderAddServiceModal=$$v},expression:"isShowOrderAddServiceModal"}}):_vm._e(),_vm._v(" "),_c('invoices-modal',{on:{"issuedInvoice":_vm.onIssuedInvoice}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }