<template>
    <v-checkbox
        v-model="showCancelledServices"
        :label="$t('show_cancelled_services')"
        hide-details
        class="mt-0 cancelled-services-checkbox"
    />
</template>

<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class ShowCancelledServicesCheckbox extends Vue {
        get showCancelledServices() {
            return persistentStore.showCancelledServices
        }

        set showCancelledServices(val) {
            persistentStore.SET_SHOW_CANCELLED_SERVICES(val)
        }
    }
</script>

<style lang="scss" scoped>
    .cancelled-services-checkbox::v-deep {
        i {
            font-size: 20px;
        }

        label {
            font-size: 13px;
        }
    }
</style>
