<template>
    <v-text-field
        v-model="phone"
        :dense="dense"
        :label="label && required ? `${label}*` : label"
        :outlined="outlined"
        :placeholder="placeholder && required ? `${placeholder}*` : placeholder"
        :prepend-inner-icon="prependInnerIcon"
        :required="required"
        :rules="rules"
        :persistent-placeholder="persistentPlaceholder"
        :validate-on-blur="validateOnBlur"
        type="tel"
        dir="ltr"
        @input="input"
        @blur="blur"
    />
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import PhoneField from '@/components/snippets/forms/PhoneField.vue'

    @Component
    export default class PhoneFieldMod extends PhoneField {}
</script>
