<template>
    <search-form-layout
        data-cy="hotel-search-form"
        :popup="popup"
        title="hotels"
        :row="row"
        :width="'auto'"
        :class="{dark: dark}"
        :style="{maxWidth: row ? 'auto' : '463px'}"
        @close-popup="$emit('close-popup')"
    >
        <template v-slot:form>
            <v-form ref="form" v-model="valid" data-cy="hotels-form">
                <v-row :no-gutters="row">
                    <v-col v-if="!isSingleHotelSearch" cols="12" :md="row ? 3 : 12" :class="{'pe-2': row}">
                        <city-hotel-autocomplete
                            ref="locationAutocomplete"
                            v-model="city"
                            single-line
                            filled
                            :prepend-inner-icon="'mdi-map-marker-outline'"
                            dense
                            outlined
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                        />
                    </v-col>
                    <v-col cols="12" :md="row ? 3 : 12" :class="{'pe-2': row}">
                        <daterange-m :start-date.sync="startDate" outlined :end-date.sync="endDate" dense />
                    </v-col>
                    <v-col cols="12" :md="row ? 2 : 6" :class="{'pe-2': row}">
                        <nights-select :_value.sync="nightsNumber" outlined dense />
                    </v-col>
                    <v-col cols="12" :md="row ? 2 : 6" :class="{'pe-2': row}">
                        <rooms-select
                            v-model="rooms"
                            dense
                            outlined
                            :max-width="300"
                            :prepend-inner-icon="'mdi-account-plus-outline'"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-down" cols="12" :md="row ? 2 : 12">
                        <v-btn
                            :large="!row"
                            block
                            elevation="0"
                            color="primary"
                            :disabled="!valid"
                            :min-height="40"
                            @click="search"
                        >
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                v-show="!hideOptions"
                ref="addForm"
                :dark="dark"
                outlined
                :options="searchAdditionalOptions"
                @change-citizenship="changeCitizenship"
            >
                <template v-slot:header>
                    <div class="d-flex align-center">
                        <v-expansion-panel-header class="pl-1 width-auto">
                            <span class="subtitle-2 font-weight-medium">
                                {{ $t('extra_options') }}
                            </span>
                        </v-expansion-panel-header>
                    </div>
                </template>
            </additional-options-wrapper>
            <v-row class="hidden-md-and-up">
                <v-col cols="12">
                    <v-btn block elevation="0" :min-height="40" color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Watch} from 'nuxt-property-decorator'
    import HotelSearchForm from '~/components/search/forms/HotelSearchForm'
    import NightsSelect from '~~/mods/ajbooking/components/search/forms/NightsSelect'

    @Component({
        components: {NightsSelect},
    })
    export default class HotelSearchFormMod extends HotelSearchForm {
        @Prop({default: false, type: Boolean}) row
        @Prop({default: false, type: Boolean}) dark
        @Prop({default: false, type: Boolean}) hideOptions

        nightsNumber = 0

        calculateNumberOfNights(d1, d2) {
            const date1 = new Date(d1)
            const date2 = new Date(d2)
            const timeDiff = Math.abs(date2.getTime() - date1.getTime())

            return Math.ceil(timeDiff / (1000 * 3600 * 24))
        }

        @Watch('nightsNumber')
        changeDateRange() {
            this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(this.startDate), this.nightsNumber))
        }

        @Watch('endDate')
        endDateChanged() {
            this.nightsNumber = this.calculateNumberOfNights(this.startDate, this.endDate)
        }
    }
</script>
