<template>
    <v-list dense class="d-flex account-menu" :class="$breakpoint.smAndDown ? 'flex-column full-width' : ''">
        <template v-if="!isShowForm">
            <v-btn
                v-if="!person"
                block
                data-cy="login-menu-button"
                :class="$breakpoint.smAndDown ? 'py-0' : ''"
                :color="'primary'"
                class="caption px-8"
                elevation="0"
                @click="isShowForm = true"
            >
                <span>{{ $t('log_in') }}</span>
            </v-btn>
            <person-label v-else class="ps-2 mb-2" :person="person" />
        </template>

        <v-list v-if="person" dense>
            <v-list-item
                :to="isB2B ? {name: 'companyPerson', params: {id: person.personId}} : {name: 'user-settings'}"
                @click="
                    menu = false
                    menuCompany = false
                "
            >
                <v-list-item-content>
                    <v-list-item-title>{{ $t('account') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="person"
                :to="{name: 'orders'}"
                data-cy="account-orders-button"
                @click="
                    menu = false
                    menuCompany = false
                "
            >
                <v-list-item-content>
                    <v-list-item-title>{{ $t('orders') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="hasEmailTemplatesAccess"
                :to="{name: 'emailTemplates'}"
                @click="
                    menu = false
                    menuCompany = false
                "
            >
                <v-list-item-content>
                    <v-list-item-title>{{ $t('admin.settings') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasCMSAccess" @click="cmsLogin">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('admin.cms') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('log_out') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-card v-else-if="!forgotPassword && isShowForm" width="410">
            <v-card-title class="title font-weight-light">
                <div class="d-flex align-center justify-center full-width mb-4">
                    <v-btn
                        x-large
                        nuxt
                        text
                        :rounded="false"
                        class="v-btn--router footer-link me-4 py-8 px-4"
                        :class="{'v-btn--active': isSignInMode}"
                        @click="isSignInMode = true"
                    >
                        <span class="text-h6">
                            {{ $t('sign_in') }}
                        </span>
                    </v-btn>
                    <v-divider vertical class="mx-4 mb-2" inset />
                    <v-btn
                        x-large
                        nuxt
                        text
                        :rounded="false"
                        :class="{'v-btn--active': !isSignInMode}"
                        class="v-btn--router footer-link me-4 py-8 px-4"
                        @click="isSignInMode = false"
                    >
                        <span class="text-h6">
                            {{ $t('register') }}
                        </span>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <authorization-form v-if="isSignInMode" @switch-forgot-password="forgotPassword = true" />
                <registration-form v-else @registered="onRegistered" />
            </v-card-text>
        </v-card>
        <v-card v-else-if="isShowForm" width="350">
            <v-card-title class="title font-weight-light">
                {{ $t('forgot_your_password') }}
            </v-card-title>
            <v-card-text>
                <forgot-password-form
                    @switch-login="forgotPassword = false"
                    @forgot-password-success="onForgotPassword"
                />
            </v-card-text>
        </v-card>
    </v-list>
</template>

<script>
    import {Component, Emit, VModel} from 'nuxt-property-decorator'
    import AccountMenu from '~src/components/account/accountMenu.src'
    import RegistrationForm from '~src/components/account/forms/registrationForm.src'

    @Component({
        components: {RegistrationForm},
    })
    export default class AccountMenuMobileMod extends AccountMenu {
        @VModel() isShowForm

        @Emit()
        showForm() {
            this.isShowForm = true
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-list-item:hover::before,
        .v-list-item:active::before,
        .v-list-item:focus::before,
        .v-list-item--active::before {
            opacity: 0 !important;
        }

        .v-list-item--active .v-btn::before {
            opacity: 0.04 !important;
        }

        .theme--light.v-btn--active:hover::before,
        .theme--light.v-btn--active::before,
        .theme--dark.v-btn--active:hover::before,
        .theme--dark.v-btn--active::before {
            opacity: 1;
            border-bottom: 7px solid var(--v-yellowlime-base) !important;
            background-color: transparent;
            border-radius: 0;
        }
    }
</style>
