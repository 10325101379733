<template>
    <div class="checkbox-wrapper">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="checkbox-container pb-3">
                        <v-col v-for="(item, index) in values" :key="item.value" :cols="12" class="py-1">
                            <v-checkbox
                                v-model="checked"
                                small
                                :hide-details="true"
                                :class="{'pb-3': index + 1 === values.length}"
                                class="py-0 my-0"
                                :value="item.value"
                            >
                                <template v-slot:label>
                                    <v-rating
                                        v-if="item.value !== '0'"
                                        color="warning"
                                        background-color="grey lighten-2"
                                        :empty-icon="false"
                                        dense
                                        readonly
                                        :size="16"
                                        :value="item.value"
                                        :length="item.value"
                                    />
                                    <span v-else class="black--text caption ms-1">
                                        {{ $t('unknown_rating') }}
                                    </span>
                                    <div class="ml-auto mr-1 filter-qty font-weight-bold">
                                        {{ item.count }}
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import {Component} from 'nuxt-property-decorator'

    @Component
    export default class CategoryFilter extends CheckboxFilter {}
</script>
