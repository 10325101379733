<template>
    <div class="d-flex flex-column align-center justify-center">
        <span class="font-weight-bold pb-4 pb-md-8" :class="{'text-h5': isMobile, 'text-h3': !isMobile}">
            <!--<span> {{ $t('our') }} </span>-->
            <!--<span class="primary&#45;&#45;text"> {{ $t('partners') }} </span>-->
            <span v-html="$t('our_partners')" />
        </span>
        <swiper :options="swiperOptions" class="swiper pa-4 pb-8 full-width pt-8">
            <swiper-slide
                v-for="index in 4"
                :key="index"
                :class="{'swiper-slide-ssr': swiperSlideSsr}"
                style="height: 56px"
                class="d-flex align-center justify-center"
            >
                <v-img :src="`/new-partner-${index}.jpg`" max-width="100" />
            </swiper-slide>
            <div v-if="6 > showCount" slot="pagination" class="swiper-pagination hidden-md-and-up" style="bottom: 0" />
        </swiper>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    import PopularHotel from '@/components/parts/popularHotel.src'
    import MainHeader from '@/components/snippets/mainHeader.src'
    import {Autoplay, Mousewheel, Navigation, Pagination, Swiper as SwiperClass} from 'swiper/swiper.esm'

    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    @Component({
        components: {
            PopularHotel,
            Swiper,
            SwiperSlide,
            MainHeader,
        },
    })
    export default class Partners extends Vue {
        created() {
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : this.$breakpoint.mdAndDown ? 6 : 6
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                centerInsufficientSlides: true,
                spaceBetween: 80,
                breakpoints: {
                    960: {
                        slidesPerView: 6,
                    },
                    1264: {
                        slidesPerView: 6,
                    },
                },
                navigation: {
                    nextEl: '#hotelsSwiperButtonNext',
                    prevEl: '#hotelsSwiperButtonPrev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar',
                    clickable: true,
                },
                observer: true,
                observeParents: true,
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }
    }
</script>

<style scoped lang="scss">
    .swiper-container-horizontal > .swiper-pagination-progressbar,
    .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
        bottom: 0 !important;
        top: unset;
    }

    ::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #d8e562;
    }
</style>
